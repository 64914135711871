export type UnqEscrow = {
  version: "0.1.0";
  name: "unq_escrow";
  docs: [
    "# UNQ Escrow",
    "Facilitates the exchange of any tokens between maker and taker,",
    "with the optional offer dedication - locks the offer so that",
    "only the specified taker can accept it.",
    "",
    "The flow for using the program is as follows:",
    "- Maker initializes the escrow by transfering tokens to the PDA",
    "- Taker accepts the offer by transfering wanted tokens to the maker",
    "and the program transfers escrowed offered tokens to the taker, or",
    "- Maker cancels the escrow and the funds are returned to his",
    "token account."
  ];
  instructions: [
    {
      name: "accept";
      accounts: [
        {
          name: "offer";
          isMut: true;
          isSigner: false;
        },
        {
          name: "proposal";
          isMut: false;
          isSigner: false;
        },
        {
          name: "escrowedOfferedToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "taker";
          isMut: true;
          isSigner: true;
        },
        {
          name: "treasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "takerOfferedToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "takerWantedToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "wantedTokenMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "escrowedWantedToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "cancel";
      accounts: [
        {
          name: "offer";
          isMut: true;
          isSigner: false;
        },
        {
          name: "maker";
          isMut: false;
          isSigner: true;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        }
      ];
      args: [];
    },
    {
      name: "initializeWithdrawal";
      accounts: [
        {
          name: "realm";
          isMut: false;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: true;
          isSigner: true;
        },
        {
          name: "treasuryToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "withdrawal";
          isMut: true;
          isSigner: false;
        },
        {
          name: "withdrawalData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "withdrawalMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "proposalMetadata";
          isMut: false;
          isSigner: false;
        },
        {
          name: "clubData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "withdrawalAmount";
          type: "u64";
        }
      ];
    },
    {
      name: "withdraw";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "memberTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "financialRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "withdrawal";
          isMut: true;
          isSigner: false;
        },
        {
          name: "withdrawalData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "withdrawalRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "distribute";
      accounts: [
        {
          name: "proposal";
          isMut: false;
          isSigner: false;
        },
        {
          name: "offer";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenLedger";
          isMut: true;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "profit";
          isMut: true;
          isSigner: false;
        },
        {
          name: "profitToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "escrowedWantedToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "makerWantedToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "transferFunds";
      accounts: [
        {
          name: "proposal";
          isMut: false;
          isSigner: false;
        },
        {
          name: "offer";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "treasuryToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenLedger";
          isMut: true;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "destination";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "transferAmount";
          type: "u64";
        }
      ];
    },
    {
      name: "initializeOffer";
      accounts: [
        {
          name: "offer";
          isMut: true;
          isSigner: false;
        },
        {
          name: "proposal";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "wantedTokenMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "escrowedWantedToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "initType";
          type: {
            defined: "InitializeType";
          };
        }
      ];
    }
  ];
  accounts: [
    {
      name: "tokenLedger";
      type: {
        kind: "struct";
        fields: [
          {
            name: "ledgerEntriesCount";
            type: "u32";
          },
          {
            name: "denominatedCurrency";
            type: "publicKey";
          },
          {
            name: "tokenLedgerMint";
            type: "publicKey";
          },
          {
            name: "treasury";
            type: "publicKey";
          },
          {
            name: "ledgerEntries";
            type: {
              vec: {
                defined: "LedgerEntry";
              };
            };
          }
        ];
      };
    },
    {
      name: "offer";
      docs: [
        "# Escrow State Account",
        "",
        "Stores information about the escrow"
      ];
      type: {
        kind: "struct";
        fields: [
          {
            name: "maker";
            docs: ["The one who initialized the Offer (treasuy/vault)"];
            type: "publicKey";
          },
          {
            name: "treasury";
            docs: ["Treasury of the club which signed the initialization"];
            type: "publicKey";
          },
          {
            name: "offeredAmount";
            docs: ["Offered amount of tokens"];
            type: "u64";
          },
          {
            name: "offeredTokenMint";
            docs: ["Mint of the offer tokens"];
            type: "publicKey";
          },
          {
            name: "wantedAmount";
            docs: [
              "Amount of tokens the maker wants in return for offered ones"
            ];
            type: "u64";
          },
          {
            name: "wantedTokenMint";
            docs: ["Mint of the wanted tokens"];
            type: "publicKey";
          },
          {
            name: "tokenLedgerMint";
            docs: [
              "Mint of the NFT used to create TokenLedger when bought for the first time"
            ];
            type: "publicKey";
          },
          {
            name: "makerWantedToken";
            docs: [
              "Token account of the club to which the assest should be transfered after distribution"
            ];
            type: "publicKey";
          },
          {
            name: "escrowedWantedToken";
            docs: [
              "Token account to which the tokens will be deposited once the Taker accepts the offer"
            ];
            type: "publicKey";
          },
          {
            name: "status";
            docs: [
              "Current status of the offer: Initialised, Accepted, Canceled"
            ];
            type: {
              defined: "OfferStatus";
            };
          },
          {
            name: "offerType";
            docs: ["Buy/Sell NFT"];
            type: {
              defined: "OfferType";
            };
          },
          {
            name: "dedicatedTaker";
            docs: ["Optional dedicated Taker"];
            type: {
              option: "publicKey";
            };
          },
          {
            name: "proposal";
            docs: ["Proposal the offer is derived from"];
            type: "publicKey";
          },
          {
            name: "sellerFeeBps";
            docs: ["Fee"];
            type: "u16";
          }
        ];
      };
    },
    {
      name: "withdrawal";
      type: {
        kind: "struct";
        fields: [];
      };
    },
    {
      name: "withdrawalData";
      type: {
        kind: "struct";
        fields: [
          {
            name: "realm";
            type: "publicKey";
          },
          {
            name: "clubData";
            type: "publicKey";
          },
          {
            name: "treasury";
            type: "publicKey";
          },
          {
            name: "proposal";
            type: "publicKey";
          },
          {
            name: "fundraiseNumber";
            type: "u32";
          },
          {
            name: "withdrawalAmount";
            type: "u64";
          },
          {
            name: "amountWithdrawn";
            type: "u64";
          },
          {
            name: "totalFinancialPower";
            type: "u64";
          },
          {
            name: "withdrawalMint";
            type: "publicKey";
          },
          {
            name: "proposalCreatedAt";
            type: "i64";
          }
        ];
      };
    },
    {
      name: "withdrawalRecord";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "hasWithdrawn";
            type: "bool";
          },
          {
            name: "withdrawnAmount";
            type: "u64";
          }
        ];
      };
    }
  ];
  types: [
    {
      name: "AllowedMemberData";
      type: {
        kind: "struct";
        fields: [
          {
            name: "clubData";
            type: "publicKey";
          },
          {
            name: "memberPubkey";
            type: "publicKey";
          },
          {
            name: "isMember";
            type: "bool";
          },
          {
            name: "status";
            type: "u8";
          },
          {
            name: "role";
            type: "string";
          },
          {
            name: "joinedAt";
            type: "i64";
          },
          {
            name: "joinedAtSlot";
            type: "u64";
          }
        ];
      };
    },
    {
      name: "UpdateRoleData";
      type: {
        kind: "struct";
        fields: [
          {
            name: "role";
            type: "string";
          },
          {
            name: "currentWeight";
            type: "u64";
          },
          {
            name: "updateWeight";
            type: "u64";
          }
        ];
      };
    },
    {
      name: "FinancialRecord";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "treasury";
            type: "publicKey";
          },
          {
            name: "sellOffersCount";
            type: "u32";
          },
          {
            name: "listedFinancialRights";
            type: "u64";
          },
          {
            name: "depositRecords";
            type: {
              vec: {
                defined: "DepositRecord";
              };
            };
          },
          {
            name: "treasuryRole";
            type: "string";
          },
          {
            name: "createdAt";
            type: "i64";
          },
          {
            name: "createdAtSlot";
            type: "u64";
          },
          {
            name: "tokenOwnerRecord";
            type: "publicKey";
          }
        ];
      };
    },
    {
      name: "DepositRecord";
      type: {
        kind: "struct";
        fields: [
          {
            name: "accumulatedAmount";
            type: "u64";
          },
          {
            name: "fundraiseIndex";
            type: "u32";
          },
          {
            name: "depositedAt";
            type: "i64";
          },
          {
            name: "currentFundraiseDeposit";
            type: "u64";
          },
          {
            name: "tradedRight";
            type: {
              option: {
                vec: {
                  defined: "TradedRight";
                };
              };
            };
          }
        ];
      };
    },
    {
      name: "TradedRight";
      type: {
        kind: "struct";
        fields: [
          {
            name: "amount";
            type: "u64";
          },
          {
            name: "timestamp";
            type: "i64";
          },
          {
            name: "tradeType";
            type: {
              defined: "TradedRightType";
            };
          }
        ];
      };
    },
    {
      name: "LedgerEntry";
      type: {
        kind: "struct";
        fields: [
          {
            name: "buy";
            type: {
              defined: "Order";
            };
          },
          {
            name: "sell";
            type: {
              defined: "Order";
            };
          },
          {
            name: "profit";
            type: "u64";
          },
          {
            name: "profitType";
            type: {
              defined: "ProfitType";
            };
          }
        ];
      };
    },
    {
      name: "Order";
      type: {
        kind: "struct";
        fields: [
          {
            name: "amount";
            type: "u64";
          },
          {
            name: "mint";
            type: "publicKey";
          },
          {
            name: "slot";
            type: "u64";
          },
          {
            name: "createdAt";
            type: "i64";
          }
        ];
      };
    },
    {
      name: "ProposalMetadata";
      type: {
        kind: "struct";
        fields: [
          {
            name: "name";
            type: "string";
          },
          {
            name: "options";
            type: {
              vec: "string";
            };
          },
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "discussionLink";
            type: "string";
          },
          {
            name: "treasury";
            type: "publicKey";
          },
          {
            name: "currentFundraiseIndex";
            type: "u32";
          },
          {
            name: "totalFinancialPower";
            type: "u64";
          },
          {
            name: "proposal";
            type: "publicKey";
          },
          {
            name: "proposalIndex";
            type: "u32";
          },
          {
            name: "realm";
            type: "publicKey";
          },
          {
            name: "clubData";
            type: "publicKey";
          },
          {
            name: "governance";
            type: "publicKey";
          },
          {
            name: "isCreated";
            type: "bool";
          },
          {
            name: "description";
            type: "string";
          },
          {
            name: "authorityRole";
            type: "string";
          },
          {
            name: "dataPda";
            type: {
              option: "publicKey";
            };
          },
          {
            name: "proposalType";
            type: {
              defined: "ProposalType";
            };
          },
          {
            name: "proposalStatus";
            type: {
              defined: "ProposalStatus";
            };
          },
          {
            name: "approvalQuorumPercentage";
            type: "u8";
          },
          {
            name: "maxVoterWeight";
            type: {
              option: "u64";
            };
          },
          {
            name: "createdAt";
            type: "i64";
          }
        ];
      };
    },
    {
      name: "TreasuryData";
      type: {
        kind: "struct";
        fields: [
          {
            name: "clubData";
            docs: ["Club the treasury belongs to"];
            type: "publicKey";
          },
          {
            name: "realm";
            docs: ["Realm used to derive governances for this treasury"];
            type: "publicKey";
          },
          {
            name: "fundraiseCount";
            docs: ["The total number of fundraises within a treasury"];
            type: "u32";
          },
          {
            name: "treasury";
            docs: [
              "The address of the treasury that this TreasuryData expands upon"
            ];
            type: "publicKey";
          },
          {
            name: "hasActiveFundraise";
            docs: [
              "Flag for active fundraises used to permit or forbid actions"
            ];
            type: "bool";
          },
          {
            name: "governance";
            docs: ["The default/mandatory governance of the treasury"];
            type: "publicKey";
          },
          {
            name: "maxVoterWeight";
            docs: ["max_voter_weight"];
            type: "u64";
          },
          {
            name: "totalFinancialPower";
            docs: [
              "The total financial power within the treasury; Also max_voter_weight for Token clubs"
            ];
            type: "u64";
          },
          {
            name: "denominatedCurrency";
            docs: ["Mint of the Token used to determine financial rights"];
            type: {
              option: "publicKey";
            };
          },
          {
            name: "financialTokenAccount";
            docs: [
              "Token Account holding tokens that determine financial rights"
            ];
            type: {
              option: "publicKey";
            };
          },
          {
            name: "sellPermission";
            docs: [
              "SellPermission Data use for additional governance configurations",
              "TODO: Change this to vec"
            ];
            type: {
              array: [
                {
                  defined: "SellPermission";
                },
                10
              ];
            };
          },
          {
            name: "withdrawalGovernance";
            docs: ["An optional withdrawal governance account"];
            type: {
              option: "publicKey";
            };
          },
          {
            name: "financialRecordCount";
            docs: ["Number of member who supported the treasury"];
            type: "u32";
          },
          {
            name: "treasuryIndex";
            docs: ["The index of the treasury"];
            type: "u32";
          },
          {
            name: "financialOffersCount";
            docs: ["Number of active financial offers"];
            type: "u32";
          },
          {
            name: "transferGovernance";
            docs: ["Optional Governance account used for transfer proposals"];
            type: {
              option: "publicKey";
            };
          },
          {
            name: "changeClubConfigGovernance";
            docs: [
              "Optional Governance account used for governance config changes proposals"
            ];
            type: {
              option: "publicKey";
            };
          },
          {
            name: "hasActiveUpdateGovernanceConfig";
            docs: [
              "Flag for active update governance config changes proposals"
            ];
            type: "bool";
          },
          {
            name: "reservedRights";
            docs: [
              "Optional reserved rights parameter storing ownership over tresury by specific members"
            ];
            type: {
              option: {
                defined: "ReservedRights";
              };
            };
          },
          {
            name: "treasuryRoleConfig";
            docs: ["Treasury roles"];
            type: {
              vec: {
                defined: "TreasuryRoleConfig";
              };
            };
          },
          {
            name: "defaultTreasuryRole";
            docs: ["Default treasury role for assigning"];
            type: "string";
          },
          {
            name: "name";
            docs: ["Name of the treasury"];
            type: "string";
          }
        ];
      };
    },
    {
      name: "TreasuryRoleConfig";
      type: {
        kind: "struct";
        fields: [
          {
            name: "name";
            type: "string";
          },
          {
            name: "roleWeight";
            type: "u64";
          },
          {
            name: "treasuryActions";
            type: {
              vec: {
                defined: "TreasuryAction";
              };
            };
          },
          {
            name: "membersCount";
            type: "u32";
          },
          {
            name: "isDefault";
            type: "bool";
          }
        ];
      };
    },
    {
      name: "ReservedRights";
      type: {
        kind: "struct";
        fields: [
          {
            name: "totalReservedRights";
            type: "u64";
          },
          {
            name: "totalReservedPercentage";
            type: "u32";
          },
          {
            name: "individualRights";
            type: {
              vec: {
                defined: "IndividualRight";
              };
            };
          }
        ];
      };
    },
    {
      name: "IndividualRight";
      type: {
        kind: "struct";
        fields: [
          {
            name: "memberPubkey";
            type: "publicKey";
          },
          {
            name: "rightPercentage";
            type: "u32";
          },
          {
            name: "amountOfRights";
            type: "u64";
          }
        ];
      };
    },
    {
      name: "SellPermission";
      type: {
        kind: "struct";
        fields: [
          {
            name: "from";
            type: "u64";
          },
          {
            name: "to";
            type: "u64";
          },
          {
            name: "quorumMinimum";
            type: "u8";
          },
          {
            name: "decimal";
            type: "u16";
          },
          {
            name: "governance";
            type: "publicKey";
          }
        ];
      };
    },
    {
      name: "TradedRightType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Buy";
          },
          {
            name: "Sell";
          },
          {
            name: "BuyReserved";
          },
          {
            name: "SellReserved";
          }
        ];
      };
    },
    {
      name: "ProfitType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Gain";
          },
          {
            name: "Loss";
          },
          {
            name: "Transfer";
          }
        ];
      };
    },
    {
      name: "OfferStatus";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Initialised";
          },
          {
            name: "Accepted";
          },
          {
            name: "Canceled";
          },
          {
            name: "PendingBuyNowExecutionOnMagicEden";
          },
          {
            name: "ExecutedBuyNowOnMagicEden";
          },
          {
            name: "CanceledOnMagic";
          },
          {
            name: "PendingSellExecutionOnMagicEden";
          },
          {
            name: "ExecutedSellOnMagicEden";
          },
          {
            name: "FundsTransfered";
          },
          {
            name: "ListedOnSolsea";
          },
          {
            name: "ExecutedBuyNowOnSolsea";
          },
          {
            name: "CanceledOnSolsea";
          }
        ];
      };
    },
    {
      name: "OfferType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Buy";
          },
          {
            name: "Sell";
          }
        ];
      };
    },
    {
      name: "InitializeType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "MagicEdenSell";
            fields: ["bytes"];
          },
          {
            name: "MagicEdenBuy";
            fields: [
              {
                vec: "bytes";
              }
            ];
          },
          {
            name: "RegularBuySell";
            fields: [
              "u64",
              "u64",
              {
                option: "publicKey";
              }
            ];
          },
          {
            name: "Solsea";
            fields: ["u64", "u64", "u16"];
          }
        ];
      };
    },
    {
      name: "ProposalAction";
      type: {
        kind: "enum";
        variants: [
          {
            name: "BuyNFT";
          },
          {
            name: "SellNFT";
          }
        ];
      };
    },
    {
      name: "ProposalType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "BuyP2P";
          },
          {
            name: "SellP2P";
          },
          {
            name: "BuyNowMagicEden";
          },
          {
            name: "SellMagicEden";
          },
          {
            name: "TransferFunds";
          },
          {
            name: "Discussion";
          },
          {
            name: "Withdrawal";
          },
          {
            name: "SellSolsea";
          },
          {
            name: "BuySolsea";
          },
          {
            name: "UpdateGovernanceConfig";
          },
          {
            name: "UpdateRoleConfig";
          }
        ];
      };
    },
    {
      name: "ProposalStatus";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Pending";
          },
          {
            name: "CreatedBuyP2P";
          },
          {
            name: "CreatedSellP2P";
          },
          {
            name: "ExecutedBuyP2P";
          },
          {
            name: "ExecutedSellP2P";
          },
          {
            name: "CreatedBuyNowMagicEden";
          },
          {
            name: "ExecutedBuyNowMagicEden";
          },
          {
            name: "FinishedBuyNowMagicEden";
          },
          {
            name: "CreatedSellMagicEden";
          },
          {
            name: "ExecutedSellMagicEden";
          },
          {
            name: "FinishedSellMagicEden";
          },
          {
            name: "CreatedTransferFunds";
          },
          {
            name: "ExecutedTransferFunds";
          },
          {
            name: "CreatedDiscussion";
          },
          {
            name: "CreatedWithdrawal";
          },
          {
            name: "ExecutedWithdrawal";
          },
          {
            name: "CanceledByOwner";
          },
          {
            name: "CreatedUpdateGovernanceConfig";
          },
          {
            name: "ExecutedUpdateGovernanceConfig";
          },
          {
            name: "CreatedUpdateRoleConfig";
          },
          {
            name: "ExecutedUpdateRoleConfig";
          },
          {
            name: "ExecutedSolseaBuy";
          },
          {
            name: "ExecutedSolseaSell";
          },
          {
            name: "CreatedMetadata";
          },
          {
            name: "MetadataCreated";
          },
          {
            name: "ProposalCreated";
          },
          {
            name: "ProposalReady";
          },
          {
            name: "ProposalExecuted";
          }
        ];
      };
    },
    {
      name: "TreasuryAction";
      type: {
        kind: "enum";
        variants: [
          {
            name: "CreateDiscussionProposal";
          },
          {
            name: "CreateP2PProposal";
          },
          {
            name: "CreateWithdrawalProposal";
          },
          {
            name: "CreateTransferProposal";
          },
          {
            name: "CreateMeProposal";
          },
          {
            name: "SignOffProposal";
          },
          {
            name: "CancelProposal";
          },
          {
            name: "CancelP2POffer";
          }
        ];
      };
    }
  ];
  errors: [
    {
      code: 6000;
      name: "MintError";
      msg: "Wrong mint found!";
    },
    {
      code: 6001;
      name: "DedicationError";
      msg: "You are not authorized to accept this escrow!";
    },
    {
      code: 6002;
      name: "NotEnoughTokenToOffer";
      msg: "Less amount of Token than required to initialize!";
    },
    {
      code: 6003;
      name: "NoAuthorityOverOfferAcc";
      msg: "Wrong authority over the offered Tokens account!";
    },
    {
      code: 6004;
      name: "NoAuthorityOverWantedAcc";
      msg: "Wrong authority over the wanted Tokens account!";
    },
    {
      code: 6005;
      name: "NotEnoughTokenToExchange";
      msg: "Less amount of Token than required to accept escrow!";
    },
    {
      code: 6006;
      name: "OfferedTokensTransferToEscrow";
      msg: "Offered Tokens transfer to escrow failed!";
    },
    {
      code: 6007;
      name: "WantedTokensTransferToEscrow";
      msg: "Wanted Tokens transfer failed!";
    },
    {
      code: 6008;
      name: "FromPdaToTakerErr";
      msg: "Failed to transfer Tokens from PDA account to Taker's token account!";
    },
    {
      code: 6009;
      name: "AccountClosingErr";
      msg: "Failed to close PDA account!";
    },
    {
      code: 6010;
      name: "FromPdaToMakerErr";
      msg: "Failed to transfer Tokens from PDA account to Makers's token account!";
    },
    {
      code: 6011;
      name: "OfferClosureErr";
      msg: "Failed to close offer account!";
    },
    {
      code: 6012;
      name: "WrongMakerWantedTokenAccount";
      msg: "Wrong maker wanted token account";
    },
    {
      code: 6013;
      name: "WrongPayer";
      msg: "Signer is not the treasury that initialized the Escrow!";
    },
    {
      code: 6014;
      name: "AlreadyAcceptedOrCanceled";
      msg: "The offer was already accepted or was canceled!";
    },
    {
      code: 6015;
      name: "WrongFundraiseConfig";
      msg: "The offer was already accepted or was canceled!";
    },
    {
      code: 6016;
      name: "WrongSplGovProgram";
      msg: "Wrong spl gov id!";
    },
    {
      code: 6017;
      name: "WrongProposalAddress";
      msg: "Wrong proposal address!";
    },
    {
      code: 6018;
      name: "FundraiseStillInProgress";
      msg: "Fundraise still in progress!";
    },
    {
      code: 6019;
      name: "InvalidDepositRecord";
      msg: "The deposit record provided is not valid for this withdrawal";
    },
    {
      code: 6020;
      name: "NotAMemberOfTheClub";
      msg: "The deposit record provided is not valid for this withdrawal";
    },
    {
      code: 6021;
      name: "WrongLedgerEntry";
      msg: "Wrong ledger entry address";
    },
    {
      code: 6022;
      name: "LedgerEntryAlreadyInitialized";
      msg: "Ledger entry already initialized";
    },
    {
      code: 6023;
      name: "BuySellMintMissmatch";
      msg: "Buy-Sell mint missmatch";
    },
    {
      code: 6024;
      name: "WrongTreasury";
      msg: "This is not the treasury that initialized the escrow";
    },
    {
      code: 6025;
      name: "WrongDepositRecord";
      msg: "Invalid DepositRecord provided";
    },
    {
      code: 6026;
      name: "WrongDepositRecordOwner";
      msg: "Payer is not the owner of the DepositRecord";
    },
    {
      code: 6027;
      name: "TokenMetadataDoesNotMatch";
      msg: "Token metadata does not match";
    },
    {
      code: 6028;
      name: "InvalidAccountOwner";
      msg: "Invalid account owner";
    },
    {
      code: 6029;
      name: "InvalidTokenMetadataAccount";
      msg: "Invalid token metadata account";
    },
    {
      code: 6030;
      name: "SpaceForSellerFeeAlreadyAllocated";
      msg: "Space for seller fee already reallocated";
    },
    {
      code: 6031;
      name: "InvalidStatusToCancelOffer";
      msg: "Invalid status to cancel offer";
    },
    {
      code: 6032;
      name: "InvalidStatusToAddLedgerEntry";
      msg: "Invalid status of offer to add LedgerEntry";
    },
    {
      code: 6033;
      name: "WrongWithdrawalAccount";
      msg: "Withdrawal account with wrong address passed";
    },
    {
      code: 6034;
      name: "WrongOwnerOfMemberTokens";
      msg: "Wrong owner of member tokens";
    },
    {
      code: 6035;
      name: "NoFinancialRights";
      msg: "No available financial rights";
    },
    {
      code: 6036;
      name: "MissingRemainingAccounts";
      msg: "Missing Remaining Accounts";
    },
    {
      code: 6037;
      name: "SignerRequired";
      msg: "Signer required";
    },
    {
      code: 6038;
      name: "WrongEscrowedOfferedTokensKey";
      msg: "Wrong escrowed offered token sent";
    },
    {
      code: 6039;
      name: "WrongEscrowedWantedTokensKey";
      msg: "Wrong escrowed wanted token sent";
    }
  ];
};

export const IDL: UnqEscrow = {
  version: "0.1.0",
  name: "unq_escrow",
  docs: [
    "# UNQ Escrow",
    "Facilitates the exchange of any tokens between maker and taker,",
    "with the optional offer dedication - locks the offer so that",
    "only the specified taker can accept it.",
    "",
    "The flow for using the program is as follows:",
    "- Maker initializes the escrow by transfering tokens to the PDA",
    "- Taker accepts the offer by transfering wanted tokens to the maker",
    "and the program transfers escrowed offered tokens to the taker, or",
    "- Maker cancels the escrow and the funds are returned to his",
    "token account.",
  ],
  instructions: [
    {
      name: "accept",
      accounts: [
        {
          name: "offer",
          isMut: true,
          isSigner: false,
        },
        {
          name: "proposal",
          isMut: false,
          isSigner: false,
        },
        {
          name: "escrowedOfferedToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "taker",
          isMut: true,
          isSigner: true,
        },
        {
          name: "treasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "takerOfferedToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "takerWantedToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "wantedTokenMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "escrowedWantedToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "cancel",
      accounts: [
        {
          name: "offer",
          isMut: true,
          isSigner: false,
        },
        {
          name: "maker",
          isMut: false,
          isSigner: true,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
      ],
      args: [],
    },
    {
      name: "initializeWithdrawal",
      accounts: [
        {
          name: "realm",
          isMut: false,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: true,
          isSigner: true,
        },
        {
          name: "treasuryToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "withdrawal",
          isMut: true,
          isSigner: false,
        },
        {
          name: "withdrawalData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "withdrawalMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "proposalMetadata",
          isMut: false,
          isSigner: false,
        },
        {
          name: "clubData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "withdrawalAmount",
          type: "u64",
        },
      ],
    },
    {
      name: "withdraw",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "memberTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "financialRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "withdrawal",
          isMut: true,
          isSigner: false,
        },
        {
          name: "withdrawalData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "withdrawalRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "distribute",
      accounts: [
        {
          name: "proposal",
          isMut: false,
          isSigner: false,
        },
        {
          name: "offer",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenLedger",
          isMut: true,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "profit",
          isMut: true,
          isSigner: false,
        },
        {
          name: "profitToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "escrowedWantedToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "makerWantedToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "transferFunds",
      accounts: [
        {
          name: "proposal",
          isMut: false,
          isSigner: false,
        },
        {
          name: "offer",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "treasuryToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenLedger",
          isMut: true,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "destination",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "transferAmount",
          type: "u64",
        },
      ],
    },
    {
      name: "initializeOffer",
      accounts: [
        {
          name: "offer",
          isMut: true,
          isSigner: false,
        },
        {
          name: "proposal",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "wantedTokenMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "escrowedWantedToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "initType",
          type: {
            defined: "InitializeType",
          },
        },
      ],
    },
  ],
  accounts: [
    {
      name: "tokenLedger",
      type: {
        kind: "struct",
        fields: [
          {
            name: "ledgerEntriesCount",
            type: "u32",
          },
          {
            name: "denominatedCurrency",
            type: "publicKey",
          },
          {
            name: "tokenLedgerMint",
            type: "publicKey",
          },
          {
            name: "treasury",
            type: "publicKey",
          },
          {
            name: "ledgerEntries",
            type: {
              vec: {
                defined: "LedgerEntry",
              },
            },
          },
        ],
      },
    },
    {
      name: "offer",
      docs: [
        "# Escrow State Account",
        "",
        "Stores information about the escrow",
      ],
      type: {
        kind: "struct",
        fields: [
          {
            name: "maker",
            docs: ["The one who initialized the Offer (treasuy/vault)"],
            type: "publicKey",
          },
          {
            name: "treasury",
            docs: ["Treasury of the club which signed the initialization"],
            type: "publicKey",
          },
          {
            name: "offeredAmount",
            docs: ["Offered amount of tokens"],
            type: "u64",
          },
          {
            name: "offeredTokenMint",
            docs: ["Mint of the offer tokens"],
            type: "publicKey",
          },
          {
            name: "wantedAmount",
            docs: [
              "Amount of tokens the maker wants in return for offered ones",
            ],
            type: "u64",
          },
          {
            name: "wantedTokenMint",
            docs: ["Mint of the wanted tokens"],
            type: "publicKey",
          },
          {
            name: "tokenLedgerMint",
            docs: [
              "Mint of the NFT used to create TokenLedger when bought for the first time",
            ],
            type: "publicKey",
          },
          {
            name: "makerWantedToken",
            docs: [
              "Token account of the club to which the assest should be transfered after distribution",
            ],
            type: "publicKey",
          },
          {
            name: "escrowedWantedToken",
            docs: [
              "Token account to which the tokens will be deposited once the Taker accepts the offer",
            ],
            type: "publicKey",
          },
          {
            name: "status",
            docs: [
              "Current status of the offer: Initialised, Accepted, Canceled",
            ],
            type: {
              defined: "OfferStatus",
            },
          },
          {
            name: "offerType",
            docs: ["Buy/Sell NFT"],
            type: {
              defined: "OfferType",
            },
          },
          {
            name: "dedicatedTaker",
            docs: ["Optional dedicated Taker"],
            type: {
              option: "publicKey",
            },
          },
          {
            name: "proposal",
            docs: ["Proposal the offer is derived from"],
            type: "publicKey",
          },
          {
            name: "sellerFeeBps",
            docs: ["Fee"],
            type: "u16",
          },
        ],
      },
    },
    {
      name: "withdrawal",
      type: {
        kind: "struct",
        fields: [],
      },
    },
    {
      name: "withdrawalData",
      type: {
        kind: "struct",
        fields: [
          {
            name: "realm",
            type: "publicKey",
          },
          {
            name: "clubData",
            type: "publicKey",
          },
          {
            name: "treasury",
            type: "publicKey",
          },
          {
            name: "proposal",
            type: "publicKey",
          },
          {
            name: "fundraiseNumber",
            type: "u32",
          },
          {
            name: "withdrawalAmount",
            type: "u64",
          },
          {
            name: "amountWithdrawn",
            type: "u64",
          },
          {
            name: "totalFinancialPower",
            type: "u64",
          },
          {
            name: "withdrawalMint",
            type: "publicKey",
          },
          {
            name: "proposalCreatedAt",
            type: "i64",
          },
        ],
      },
    },
    {
      name: "withdrawalRecord",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            type: "publicKey",
          },
          {
            name: "hasWithdrawn",
            type: "bool",
          },
          {
            name: "withdrawnAmount",
            type: "u64",
          },
        ],
      },
    },
  ],
  types: [
    {
      name: "AllowedMemberData",
      type: {
        kind: "struct",
        fields: [
          {
            name: "clubData",
            type: "publicKey",
          },
          {
            name: "memberPubkey",
            type: "publicKey",
          },
          {
            name: "isMember",
            type: "bool",
          },
          {
            name: "status",
            type: "u8",
          },
          {
            name: "role",
            type: "string",
          },
          {
            name: "joinedAt",
            type: "i64",
          },
          {
            name: "joinedAtSlot",
            type: "u64",
          },
        ],
      },
    },
    {
      name: "UpdateRoleData",
      type: {
        kind: "struct",
        fields: [
          {
            name: "role",
            type: "string",
          },
          {
            name: "currentWeight",
            type: "u64",
          },
          {
            name: "updateWeight",
            type: "u64",
          },
        ],
      },
    },
    {
      name: "FinancialRecord",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            type: "publicKey",
          },
          {
            name: "treasury",
            type: "publicKey",
          },
          {
            name: "sellOffersCount",
            type: "u32",
          },
          {
            name: "listedFinancialRights",
            type: "u64",
          },
          {
            name: "depositRecords",
            type: {
              vec: {
                defined: "DepositRecord",
              },
            },
          },
          {
            name: "treasuryRole",
            type: "string",
          },
          {
            name: "createdAt",
            type: "i64",
          },
          {
            name: "createdAtSlot",
            type: "u64",
          },
          {
            name: "tokenOwnerRecord",
            type: "publicKey",
          },
        ],
      },
    },
    {
      name: "DepositRecord",
      type: {
        kind: "struct",
        fields: [
          {
            name: "accumulatedAmount",
            type: "u64",
          },
          {
            name: "fundraiseIndex",
            type: "u32",
          },
          {
            name: "depositedAt",
            type: "i64",
          },
          {
            name: "currentFundraiseDeposit",
            type: "u64",
          },
          {
            name: "tradedRight",
            type: {
              option: {
                vec: {
                  defined: "TradedRight",
                },
              },
            },
          },
        ],
      },
    },
    {
      name: "TradedRight",
      type: {
        kind: "struct",
        fields: [
          {
            name: "amount",
            type: "u64",
          },
          {
            name: "timestamp",
            type: "i64",
          },
          {
            name: "tradeType",
            type: {
              defined: "TradedRightType",
            },
          },
        ],
      },
    },
    {
      name: "LedgerEntry",
      type: {
        kind: "struct",
        fields: [
          {
            name: "buy",
            type: {
              defined: "Order",
            },
          },
          {
            name: "sell",
            type: {
              defined: "Order",
            },
          },
          {
            name: "profit",
            type: "u64",
          },
          {
            name: "profitType",
            type: {
              defined: "ProfitType",
            },
          },
        ],
      },
    },
    {
      name: "Order",
      type: {
        kind: "struct",
        fields: [
          {
            name: "amount",
            type: "u64",
          },
          {
            name: "mint",
            type: "publicKey",
          },
          {
            name: "slot",
            type: "u64",
          },
          {
            name: "createdAt",
            type: "i64",
          },
        ],
      },
    },
    {
      name: "ProposalMetadata",
      type: {
        kind: "struct",
        fields: [
          {
            name: "name",
            type: "string",
          },
          {
            name: "options",
            type: {
              vec: "string",
            },
          },
          {
            name: "authority",
            type: "publicKey",
          },
          {
            name: "discussionLink",
            type: "string",
          },
          {
            name: "treasury",
            type: "publicKey",
          },
          {
            name: "currentFundraiseIndex",
            type: "u32",
          },
          {
            name: "totalFinancialPower",
            type: "u64",
          },
          {
            name: "proposal",
            type: "publicKey",
          },
          {
            name: "proposalIndex",
            type: "u32",
          },
          {
            name: "realm",
            type: "publicKey",
          },
          {
            name: "clubData",
            type: "publicKey",
          },
          {
            name: "governance",
            type: "publicKey",
          },
          {
            name: "isCreated",
            type: "bool",
          },
          {
            name: "description",
            type: "string",
          },
          {
            name: "authorityRole",
            type: "string",
          },
          {
            name: "dataPda",
            type: {
              option: "publicKey",
            },
          },
          {
            name: "proposalType",
            type: {
              defined: "ProposalType",
            },
          },
          {
            name: "proposalStatus",
            type: {
              defined: "ProposalStatus",
            },
          },
          {
            name: "approvalQuorumPercentage",
            type: "u8",
          },
          {
            name: "maxVoterWeight",
            type: {
              option: "u64",
            },
          },
          {
            name: "createdAt",
            type: "i64",
          },
        ],
      },
    },
    {
      name: "TreasuryData",
      type: {
        kind: "struct",
        fields: [
          {
            name: "clubData",
            docs: ["Club the treasury belongs to"],
            type: "publicKey",
          },
          {
            name: "realm",
            docs: ["Realm used to derive governances for this treasury"],
            type: "publicKey",
          },
          {
            name: "fundraiseCount",
            docs: ["The total number of fundraises within a treasury"],
            type: "u32",
          },
          {
            name: "treasury",
            docs: [
              "The address of the treasury that this TreasuryData expands upon",
            ],
            type: "publicKey",
          },
          {
            name: "hasActiveFundraise",
            docs: [
              "Flag for active fundraises used to permit or forbid actions",
            ],
            type: "bool",
          },
          {
            name: "governance",
            docs: ["The default/mandatory governance of the treasury"],
            type: "publicKey",
          },
          {
            name: "maxVoterWeight",
            docs: ["max_voter_weight"],
            type: "u64",
          },
          {
            name: "totalFinancialPower",
            docs: [
              "The total financial power within the treasury; Also max_voter_weight for Token clubs",
            ],
            type: "u64",
          },
          {
            name: "denominatedCurrency",
            docs: ["Mint of the Token used to determine financial rights"],
            type: {
              option: "publicKey",
            },
          },
          {
            name: "financialTokenAccount",
            docs: [
              "Token Account holding tokens that determine financial rights",
            ],
            type: {
              option: "publicKey",
            },
          },
          {
            name: "sellPermission",
            docs: [
              "SellPermission Data use for additional governance configurations",
              "TODO: Change this to vec",
            ],
            type: {
              array: [
                {
                  defined: "SellPermission",
                },
                10,
              ],
            },
          },
          {
            name: "withdrawalGovernance",
            docs: ["An optional withdrawal governance account"],
            type: {
              option: "publicKey",
            },
          },
          {
            name: "financialRecordCount",
            docs: ["Number of member who supported the treasury"],
            type: "u32",
          },
          {
            name: "treasuryIndex",
            docs: ["The index of the treasury"],
            type: "u32",
          },
          {
            name: "financialOffersCount",
            docs: ["Number of active financial offers"],
            type: "u32",
          },
          {
            name: "transferGovernance",
            docs: ["Optional Governance account used for transfer proposals"],
            type: {
              option: "publicKey",
            },
          },
          {
            name: "changeClubConfigGovernance",
            docs: [
              "Optional Governance account used for governance config changes proposals",
            ],
            type: {
              option: "publicKey",
            },
          },
          {
            name: "hasActiveUpdateGovernanceConfig",
            docs: [
              "Flag for active update governance config changes proposals",
            ],
            type: "bool",
          },
          {
            name: "reservedRights",
            docs: [
              "Optional reserved rights parameter storing ownership over tresury by specific members",
            ],
            type: {
              option: {
                defined: "ReservedRights",
              },
            },
          },
          {
            name: "treasuryRoleConfig",
            docs: ["Treasury roles"],
            type: {
              vec: {
                defined: "TreasuryRoleConfig",
              },
            },
          },
          {
            name: "defaultTreasuryRole",
            docs: ["Default treasury role for assigning"],
            type: "string",
          },
          {
            name: "name",
            docs: ["Name of the treasury"],
            type: "string",
          },
        ],
      },
    },
    {
      name: "TreasuryRoleConfig",
      type: {
        kind: "struct",
        fields: [
          {
            name: "name",
            type: "string",
          },
          {
            name: "roleWeight",
            type: "u64",
          },
          {
            name: "treasuryActions",
            type: {
              vec: {
                defined: "TreasuryAction",
              },
            },
          },
          {
            name: "membersCount",
            type: "u32",
          },
          {
            name: "isDefault",
            type: "bool",
          },
        ],
      },
    },
    {
      name: "ReservedRights",
      type: {
        kind: "struct",
        fields: [
          {
            name: "totalReservedRights",
            type: "u64",
          },
          {
            name: "totalReservedPercentage",
            type: "u32",
          },
          {
            name: "individualRights",
            type: {
              vec: {
                defined: "IndividualRight",
              },
            },
          },
        ],
      },
    },
    {
      name: "IndividualRight",
      type: {
        kind: "struct",
        fields: [
          {
            name: "memberPubkey",
            type: "publicKey",
          },
          {
            name: "rightPercentage",
            type: "u32",
          },
          {
            name: "amountOfRights",
            type: "u64",
          },
        ],
      },
    },
    {
      name: "SellPermission",
      type: {
        kind: "struct",
        fields: [
          {
            name: "from",
            type: "u64",
          },
          {
            name: "to",
            type: "u64",
          },
          {
            name: "quorumMinimum",
            type: "u8",
          },
          {
            name: "decimal",
            type: "u16",
          },
          {
            name: "governance",
            type: "publicKey",
          },
        ],
      },
    },
    {
      name: "TradedRightType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Buy",
          },
          {
            name: "Sell",
          },
          {
            name: "BuyReserved",
          },
          {
            name: "SellReserved",
          },
        ],
      },
    },
    {
      name: "ProfitType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Gain",
          },
          {
            name: "Loss",
          },
          {
            name: "Transfer",
          },
        ],
      },
    },
    {
      name: "OfferStatus",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Initialised",
          },
          {
            name: "Accepted",
          },
          {
            name: "Canceled",
          },
          {
            name: "PendingBuyNowExecutionOnMagicEden",
          },
          {
            name: "ExecutedBuyNowOnMagicEden",
          },
          {
            name: "CanceledOnMagic",
          },
          {
            name: "PendingSellExecutionOnMagicEden",
          },
          {
            name: "ExecutedSellOnMagicEden",
          },
          {
            name: "FundsTransfered",
          },
          {
            name: "ListedOnSolsea",
          },
          {
            name: "ExecutedBuyNowOnSolsea",
          },
          {
            name: "CanceledOnSolsea",
          },
        ],
      },
    },
    {
      name: "OfferType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Buy",
          },
          {
            name: "Sell",
          },
        ],
      },
    },
    {
      name: "InitializeType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "MagicEdenSell",
            fields: ["bytes"],
          },
          {
            name: "MagicEdenBuy",
            fields: [
              {
                vec: "bytes",
              },
            ],
          },
          {
            name: "RegularBuySell",
            fields: [
              "u64",
              "u64",
              {
                option: "publicKey",
              },
            ],
          },
          {
            name: "Solsea",
            fields: ["u64", "u64", "u16"],
          },
        ],
      },
    },
    {
      name: "ProposalAction",
      type: {
        kind: "enum",
        variants: [
          {
            name: "BuyNFT",
          },
          {
            name: "SellNFT",
          },
        ],
      },
    },
    {
      name: "ProposalType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "BuyP2P",
          },
          {
            name: "SellP2P",
          },
          {
            name: "BuyNowMagicEden",
          },
          {
            name: "SellMagicEden",
          },
          {
            name: "TransferFunds",
          },
          {
            name: "Discussion",
          },
          {
            name: "Withdrawal",
          },
          {
            name: "SellSolsea",
          },
          {
            name: "BuySolsea",
          },
          {
            name: "UpdateGovernanceConfig",
          },
          {
            name: "UpdateRoleConfig",
          },
        ],
      },
    },
    {
      name: "ProposalStatus",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Pending",
          },
          {
            name: "CreatedBuyP2P",
          },
          {
            name: "CreatedSellP2P",
          },
          {
            name: "ExecutedBuyP2P",
          },
          {
            name: "ExecutedSellP2P",
          },
          {
            name: "CreatedBuyNowMagicEden",
          },
          {
            name: "ExecutedBuyNowMagicEden",
          },
          {
            name: "FinishedBuyNowMagicEden",
          },
          {
            name: "CreatedSellMagicEden",
          },
          {
            name: "ExecutedSellMagicEden",
          },
          {
            name: "FinishedSellMagicEden",
          },
          {
            name: "CreatedTransferFunds",
          },
          {
            name: "ExecutedTransferFunds",
          },
          {
            name: "CreatedDiscussion",
          },
          {
            name: "CreatedWithdrawal",
          },
          {
            name: "ExecutedWithdrawal",
          },
          {
            name: "CanceledByOwner",
          },
          {
            name: "CreatedUpdateGovernanceConfig",
          },
          {
            name: "ExecutedUpdateGovernanceConfig",
          },
          {
            name: "CreatedUpdateRoleConfig",
          },
          {
            name: "ExecutedUpdateRoleConfig",
          },
          {
            name: "ExecutedSolseaBuy",
          },
          {
            name: "ExecutedSolseaSell",
          },
          {
            name: "CreatedMetadata",
          },
          {
            name: "MetadataCreated",
          },
          {
            name: "ProposalCreated",
          },
          {
            name: "ProposalReady",
          },
          {
            name: "ProposalExecuted",
          },
        ],
      },
    },
    {
      name: "TreasuryAction",
      type: {
        kind: "enum",
        variants: [
          {
            name: "CreateDiscussionProposal",
          },
          {
            name: "CreateP2PProposal",
          },
          {
            name: "CreateWithdrawalProposal",
          },
          {
            name: "CreateTransferProposal",
          },
          {
            name: "CreateMeProposal",
          },
          {
            name: "SignOffProposal",
          },
          {
            name: "CancelProposal",
          },
          {
            name: "CancelP2POffer",
          },
        ],
      },
    },
  ],
  errors: [
    {
      code: 6000,
      name: "MintError",
      msg: "Wrong mint found!",
    },
    {
      code: 6001,
      name: "DedicationError",
      msg: "You are not authorized to accept this escrow!",
    },
    {
      code: 6002,
      name: "NotEnoughTokenToOffer",
      msg: "Less amount of Token than required to initialize!",
    },
    {
      code: 6003,
      name: "NoAuthorityOverOfferAcc",
      msg: "Wrong authority over the offered Tokens account!",
    },
    {
      code: 6004,
      name: "NoAuthorityOverWantedAcc",
      msg: "Wrong authority over the wanted Tokens account!",
    },
    {
      code: 6005,
      name: "NotEnoughTokenToExchange",
      msg: "Less amount of Token than required to accept escrow!",
    },
    {
      code: 6006,
      name: "OfferedTokensTransferToEscrow",
      msg: "Offered Tokens transfer to escrow failed!",
    },
    {
      code: 6007,
      name: "WantedTokensTransferToEscrow",
      msg: "Wanted Tokens transfer failed!",
    },
    {
      code: 6008,
      name: "FromPdaToTakerErr",
      msg: "Failed to transfer Tokens from PDA account to Taker's token account!",
    },
    {
      code: 6009,
      name: "AccountClosingErr",
      msg: "Failed to close PDA account!",
    },
    {
      code: 6010,
      name: "FromPdaToMakerErr",
      msg: "Failed to transfer Tokens from PDA account to Makers's token account!",
    },
    {
      code: 6011,
      name: "OfferClosureErr",
      msg: "Failed to close offer account!",
    },
    {
      code: 6012,
      name: "WrongMakerWantedTokenAccount",
      msg: "Wrong maker wanted token account",
    },
    {
      code: 6013,
      name: "WrongPayer",
      msg: "Signer is not the treasury that initialized the Escrow!",
    },
    {
      code: 6014,
      name: "AlreadyAcceptedOrCanceled",
      msg: "The offer was already accepted or was canceled!",
    },
    {
      code: 6015,
      name: "WrongFundraiseConfig",
      msg: "The offer was already accepted or was canceled!",
    },
    {
      code: 6016,
      name: "WrongSplGovProgram",
      msg: "Wrong spl gov id!",
    },
    {
      code: 6017,
      name: "WrongProposalAddress",
      msg: "Wrong proposal address!",
    },
    {
      code: 6018,
      name: "FundraiseStillInProgress",
      msg: "Fundraise still in progress!",
    },
    {
      code: 6019,
      name: "InvalidDepositRecord",
      msg: "The deposit record provided is not valid for this withdrawal",
    },
    {
      code: 6020,
      name: "NotAMemberOfTheClub",
      msg: "The deposit record provided is not valid for this withdrawal",
    },
    {
      code: 6021,
      name: "WrongLedgerEntry",
      msg: "Wrong ledger entry address",
    },
    {
      code: 6022,
      name: "LedgerEntryAlreadyInitialized",
      msg: "Ledger entry already initialized",
    },
    {
      code: 6023,
      name: "BuySellMintMissmatch",
      msg: "Buy-Sell mint missmatch",
    },
    {
      code: 6024,
      name: "WrongTreasury",
      msg: "This is not the treasury that initialized the escrow",
    },
    {
      code: 6025,
      name: "WrongDepositRecord",
      msg: "Invalid DepositRecord provided",
    },
    {
      code: 6026,
      name: "WrongDepositRecordOwner",
      msg: "Payer is not the owner of the DepositRecord",
    },
    {
      code: 6027,
      name: "TokenMetadataDoesNotMatch",
      msg: "Token metadata does not match",
    },
    {
      code: 6028,
      name: "InvalidAccountOwner",
      msg: "Invalid account owner",
    },
    {
      code: 6029,
      name: "InvalidTokenMetadataAccount",
      msg: "Invalid token metadata account",
    },
    {
      code: 6030,
      name: "SpaceForSellerFeeAlreadyAllocated",
      msg: "Space for seller fee already reallocated",
    },
    {
      code: 6031,
      name: "InvalidStatusToCancelOffer",
      msg: "Invalid status to cancel offer",
    },
    {
      code: 6032,
      name: "InvalidStatusToAddLedgerEntry",
      msg: "Invalid status of offer to add LedgerEntry",
    },
    {
      code: 6033,
      name: "WrongWithdrawalAccount",
      msg: "Withdrawal account with wrong address passed",
    },
    {
      code: 6034,
      name: "WrongOwnerOfMemberTokens",
      msg: "Wrong owner of member tokens",
    },
    {
      code: 6035,
      name: "NoFinancialRights",
      msg: "No available financial rights",
    },
    {
      code: 6036,
      name: "MissingRemainingAccounts",
      msg: "Missing Remaining Accounts",
    },
    {
      code: 6037,
      name: "SignerRequired",
      msg: "Signer required",
    },
    {
      code: 6038,
      name: "WrongEscrowedOfferedTokensKey",
      msg: "Wrong escrowed offered token sent",
    },
    {
      code: 6039,
      name: "WrongEscrowedWantedTokensKey",
      msg: "Wrong escrowed wanted token sent",
    },
  ],
};
