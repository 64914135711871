import { FC } from "react";
import "./ProgressBar.scss";
import { getPercentage } from "../../utilities/helpers";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import { RED500 } from "../../common/constants/layout.constants";

const ProgressBar: FC<{
  numerator: number;
  denominator: number;
  red?: boolean;
  active?: boolean;
  width?: number;
}> = ({ numerator, denominator, red, active, width }) => {
  return (
    <div
      className="progress-bar"
      style={{ width: (width ? width : 100) + "%" }}
    >
      <span
        style={{
          width: active ? getPercentage(numerator, denominator) + "%" : "0%",
          backgroundColor: red ? RED500 : EMPTY_STRING,
        }}
      />
    </div>
  );
};

export default ProgressBar;
