import { FC, useMemo } from "react";
import Modal from "../../../components/Modal/Modal";
import { Form, Formik } from "formik";
import sellOfferFormModel from "./sellOfferFormModel";
import SharesForm from "./SharesForm";
import FormActions from "../../../components/FormActions/FormActions";
import { validateSharesForm } from "./sharesFormValidator";
import { IOTCDeal } from "../../../common/interfaces/otc.interface";
import { PublicKey } from "@solana/web3.js";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { clubStore } from "../../../state/clubStore";
import { createNotification } from "../../../utilities/notifications";
import { MESSAGE_TYPE } from "../../../common/constants/common.constants";
import {
  acceptFinancialOffer,
  createFinancialOffer,
} from "../../../program/methods/clubs";
import { ISharesOffer } from "../../../common/interfaces/form.interface";
import { getAmountWithDecimalsForCurrency } from "../../../utilities/helpers";
import { pow } from "mathjs";

const SharesModal: FC<{
  closeModal: () => void;
  selling?: boolean;
  otc?: IOTCDeal;
}> = ({ closeModal, selling, otc }) => {
  const wallet = useAnchorWallet();
  const { activeTreasury, clubBasicInfo, memberData, memberTreasuryInfo } =
    clubStore();

  const initialValues = useMemo(
    () =>
      (() => {
        const values: any = {};
        Object.keys(sellOfferFormModel.formFields).map(
          (key) =>
            (values[key] = (sellOfferFormModel.formFields as any)[key].value)
        );
        values.totalSharesOrOfferedShares = getAmountWithDecimalsForCurrency(
          activeTreasury?.currencyDecimals,
          selling ? memberTreasuryInfo?.totalShares : otc?.amountOfRights
        );
        values.availableSharesOrTotalPrice = getAmountWithDecimalsForCurrency(
          activeTreasury?.currencyDecimals,
          selling ? memberTreasuryInfo?.availableShares : otc?.price
        );
        if (!selling) {
          values.price = 0;
        }
        return values;
      })(),
    []
  );

  const handleSellOffer = async (values: ISharesOffer) => {
    try {
      if (
        !clubBasicInfo ||
        !wallet ||
        !memberData ||
        !activeTreasury ||
        !memberTreasuryInfo
      ) {
        createNotification(MESSAGE_TYPE.ERROR, "Missing data");
        return;
      }
      await createFinancialOffer(
        Number(values.sharesAmount) *
          Number(pow(10, activeTreasury.currencyDecimals)),
        Number(values.price) * Number(pow(10, activeTreasury.currencyDecimals)),
        new PublicKey(clubBasicInfo?.address),
        wallet,
        new PublicKey(activeTreasury?.treasuryAddress),
        new PublicKey(activeTreasury?.treasuryDataAddress),
        new PublicKey(memberData?.address),
        memberTreasuryInfo?.sellOffersCount,
        activeTreasury?.denominatedCurrency
          ? new PublicKey(activeTreasury?.denominatedCurrency)
          : undefined,
        values.buyer ? new PublicKey(values.buyer) : undefined
      );
    } catch (error) {
      console.log(error);
      createNotification(MESSAGE_TYPE.ERROR, "Failed to sell shares");
    }
  };

  const handleAcceptOtcDeal = async (values: ISharesOffer) => {
    try {
      if (!clubBasicInfo || !memberData || !activeTreasury || !wallet || !otc) {
        createNotification(MESSAGE_TYPE.ERROR, "Missing data");
        return;
      }

      await acceptFinancialOffer(
        new PublicKey(memberData?.address),
        wallet,
        new PublicKey(activeTreasury?.treasuryAddress),
        new PublicKey(activeTreasury?.treasuryDataAddress),
        new PublicKey(activeTreasury.realmAddress),
        new PublicKey(otc.seller),
        new PublicKey(otc.address),
        new PublicKey(otc.financialRecord),
        clubBasicInfo,
        Number(values.sharesAmount) *
          Number(pow(10, activeTreasury.currencyDecimals)),
        otc.wantedTokenMint ? new PublicKey(otc.wantedTokenMint) : undefined
      );
    } catch (error) {
      console.log(error);
      createNotification(MESSAGE_TYPE.ERROR, "Failed to accept otc deal");
    }
  };

  const handleSubmit = async (values: ISharesOffer) => {
    try {
      if (selling) {
        handleSellOffer(values);
        return;
      }
      handleAcceptOtcDeal(values);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      closeModal={closeModal}
      title={selling ? "Sell offer" : "Buy offer"}
      width="640px"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur
        validate={(values) =>
          validateSharesForm(
            values,
            otc,
            memberTreasuryInfo,
            activeTreasury,
            selling
          )
        }
      >
        <Form id={sellOfferFormModel.formId}>
          <SharesForm selling={selling} otc={otc} />
          <FormActions
            buttonText={selling ? "Sell now" : "Buy now"}
            cancelAction={closeModal}
            buttonAction={() => {}}
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default SharesModal;
