import { CLIENT_CONFIGURATION } from "../../../client/configuration";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import { IAddMembersFormFields } from "../../../common/interfaces/form.interface";

export const validateMemberForm = (values: IAddMembersFormFields) => {
  const errors: any = {};
  const roleNames = CLIENT_CONFIGURATION.clubRoles.map((role) => role.name);

  if (values.members.length <= 0) {
    errors.members = "Members length can not be zero";
  }

  values.members.forEach((item, index) => {
    if (item.wallet === EMPTY_STRING) {
      errors[`members.${index}.wallet`] = "Wallet can not be empty";
    }
    if (
      values.members.filter((checkItem) => checkItem.wallet === item.wallet)
        .length > 1 &&
      item.wallet !== EMPTY_STRING
    ) {
      errors[`members.${index}.wallet`] = "This member is already in this deal";
    }

    if (item.role === EMPTY_STRING) {
      errors[`members.${index}.role`] = "Role can not be empty";
    }

    if (!roleNames.includes(item.role)) {
      errors[`members.${index}.role`] = "Invalid role input";
    }
  });

  return errors;
};
