import { FC } from "react";
import { TableCell, TableRow } from "@mui/material";
import BasicDetailsCard from "../../../components/BasicDetailsCard/BasicDetailsCard";
import Chip from "../../../components/Chip/Chip";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { getProposalChipColor } from "../../../utilities/chipColor";
import { IDashboardProposalItem } from "../../../common/interfaces/dashboard.interface";
import { WHITE300 } from "../../../common/constants/layout.constants";
import acceptIcon from "../../../assets/thumb_up.png";
import rejectIcon from "../../../assets/thumb_down.png";
import "./ProposalTableRow.scss";
import {
  calculateVotePercentage,
  checkIfUserHasTreasuryPermissionForAction,
  getMemberDataPda,
  getPercentage,
  getProposalTypeTitle,
  getTrimmedPublicKey,
} from "../../../utilities/helpers";
import { UserProposalVoting } from "../../../common/enums/proposal.enum";
import { ProposalState, Vote, YesNoVote } from "@solana/spl-governance";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { createNotification } from "../../../utilities/notifications";
import { MESSAGE_TYPE } from "../../../common/constants/common.constants";
import { voteToProposal } from "../../../program/methods/proposals";
import MainButton from "../../../components/MainButton/MainButton";
import { TreasuryAction } from "../../../common/enums/clubs.enum";

const ProposalTableRow: FC<{
  proposalData: IDashboardProposalItem;
}> = ({ proposalData }) => {
  const wallet = useAnchorWallet();

  const voteToProposalHanlder = async (choice: Vote) => {
    try {
      if (wallet) {
        const memberData = getMemberDataPda(
          proposalData.treasuryData.clubDataAddress,
          wallet
        );

        await voteToProposal(
          proposalData.proposal,
          proposalData.treasuryData.realmAddress,
          proposalData.treasuryData.treasuryDataAddress,
          wallet,
          memberData.toString(),
          proposalData.treasuryData.clubDataAddress,
          choice
        );
        createNotification(MESSAGE_TYPE.SUCCESS, "Succesfully voted");
      }
    } catch (error) {
      console.log(error);
      createNotification(MESSAGE_TYPE.ERROR, "Failed to vote");
    }
  };

  const checkIfAllowedToVote = () => {
    const { proposalMetadata, proposalAccount } = proposalData.proposal;

    return (
      proposalMetadata.userVote !== UserProposalVoting.DidNotVote ||
      proposalAccount.state !== ProposalState.Voting
    );
  };

  const alreadyVoted = (choice: UserProposalVoting) => {
    return proposalData.proposal.proposalMetadata.userVote === choice;
  };

  return (
    <TableRow>
      <TableCell style={{ fontSize: "16px" }} width={"35%"}>
        <BasicDetailsCard
          alt={proposalData.treasuryData.treasuryName}
          title={proposalData.treasuryData.treasuryName}
          id={getTrimmedPublicKey(proposalData.treasuryData.treasuryAddress)}
        />
      </TableCell>
      <TableCell width={"15%"}>
        <p className="home-tables__small-p">
          {proposalData.proposal.proposalAccount.name.substring(0, 25)}
          {proposalData.proposal.proposalAccount.name.length > 25 && "..."}
        </p>
      </TableCell>
      <TableCell>
        <Chip
          text={getProposalTypeTitle(
            proposalData.proposal.proposalMetadata.proposalType
          )}
          backgroundColor={WHITE300}
          fontSize="0.85"
        />
      </TableCell>
      <TableCell>
        <Chip
          text={proposalData.proposal.proposalMetadata.proposalStatus}
          backgroundColor={getProposalChipColor(
            proposalData.proposal.proposalAccount.state
          )}
          fontSize="0.85"
        />
      </TableCell>
      <TableCell style={{ paddingRight: "1em" }}>
        <p className="progress-bar__text home-tables__small-p">
          {getPercentage(
            calculateVotePercentage(
              proposalData.proposal.proposalAccount.yesVotesCount ?? 0,
              proposalData.proposal.proposalAccount.maxVoteWeight
            ),
            100
          ).toFixed(2)}
          %
        </p>
        <ProgressBar
          numerator={getPercentage(
            calculateVotePercentage(
              proposalData.proposal.proposalAccount.yesVotesCount ?? 0,
              proposalData.proposal.proposalAccount.maxVoteWeight
            ),
            100
          )}
          denominator={100}
          active
        />
      </TableCell>
      <TableCell style={{ paddingRight: "1em" }}>
        <p className="progress-bar__text home-tables__small-p">
          {getPercentage(
            calculateVotePercentage(
              proposalData.proposal.proposalAccount.noVotesCount ?? 0,
              proposalData.proposal.proposalAccount.maxVoteWeight
            ),
            100
          ).toFixed(2)}
          %
        </p>
        <ProgressBar
          numerator={getPercentage(
            calculateVotePercentage(
              proposalData.proposal.proposalAccount.noVotesCount ?? 0,
              proposalData.proposal.proposalAccount.maxVoteWeight
            ),
            100
          )}
          denominator={100}
          active
          red
        />
      </TableCell>
      <TableCell
        className="proposal_table_row"
        style={{ display: "flex", gap: "1em", justifyContent: "flex-end" }}
      >
        <MainButton
          type="button"
          light
          onClick={() =>
            voteToProposalHanlder(Vote.fromYesNoVote(YesNoVote.Yes))
          }
          disabled={checkIfAllowedToVote()}
          disabledBold={alreadyVoted(UserProposalVoting.VotedYes)}
        >
          <img src={acceptIcon} alt="Thumb up" />
        </MainButton>
        <MainButton
          type="button"
          light
          onClick={() =>
            voteToProposalHanlder(Vote.fromYesNoVote(YesNoVote.No))
          }
          disabled={checkIfAllowedToVote()}
          disabledBold={alreadyVoted(UserProposalVoting.VotedNo)}
        >
          <img src={rejectIcon} alt="Thumb down" />
        </MainButton>
      </TableCell>
    </TableRow>
  );
};

export default ProposalTableRow;
