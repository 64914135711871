import React, { FC, useEffect, useMemo, useState } from "react";
import "./Proposals.scss";
import SearchBar from "../../components/SearchBar/SearchBar";
import { Sort, TabLabel } from "../../common/enums/common.enum";
import NoData from "../../components/NoData/NoData";
import Pagination from "../../components/Pagination/Pagination";
import {
  checkIfUserCanCreateAtLeastOneTypeOfProposal,
  checkIfUserHasTreasuryPermissionForAction,
  generateSkeletonArrays,
  getInitialValuesForFilters,
} from "../../utilities/helpers";
import TabHeader from "../../components/TabHeader/TabHeader";
import SortDropdown from "../../components/SortDropdown/SortDropdown";
import { PROPOSAL_TABS } from "../../common/constants/navigation.constants";
import ProposalCard from "../../components/ProposalCard/ProposalCard";
import ClubDetailsLayout from "../../components/ClubDetailsWrapper/ClubDetailsWrapper";
import { clubStore } from "../../state/clubStore";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_TREASURY_PROPOSALS } from "../../api/club.api";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { IBasicFilters } from "../../common/interfaces/common.interface";
import {
  EMPTY_STRING,
  ITEMS_PER_PAGE,
} from "../../common/constants/common.constants";
import ProposalsItemSkeleton from "../../components/ProposalsItemSkeleton/ProposalsItemSkeleton";
import MainButton from "../../components/MainButton/MainButton";
import CreateProposalModal from "../../components/CreateProposal/CreateProposalModal";
import { sortProposalsOptions } from "../../common/constants/sort.constants";
import { useSearchParams } from "react-router-dom";

const Proposals: FC<{}> = ({}) => {
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState<IBasicFilters>({
    ...getInitialValuesForFilters(),
    filter: searchParams.get("proposal") ? undefined : TabLabel.Active,
    search: searchParams.get("proposal") ?? EMPTY_STRING,
  });
  const [totalItems, setTotalItems] = useState();
  const { activeTreasury, proposals, setProposals, memberTreasuryInfo } =
    clubStore();
  const [createProposal, toggleCreateProposal] = useState(false);

  const wallet = useAnchorWallet();

  const [getProposals, proposalsInfo] = useLazyQuery(
    GET_ALL_TREASURY_PROPOSALS,
    {
      onCompleted: (data) => {
        setProposals(data.getProposalsForTreasury.proposals);
        setTotalItems(data.getProposalsForTreasury.count);
      },
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    if (searchParams.get("proposal")) {
      setFilters({
        ...filters,
        search: searchParams.get("proposal") ?? EMPTY_STRING,
      });
    }
  }, [searchParams]);

  useEffect(() => {
    if (activeTreasury && filters) {
      void getAllProposalsHandler();
    }
  }, [
    activeTreasury?.treasuryAddress,
    wallet?.publicKey,
    filters.filter,
    filters.page,
    filters.search,
    filters.sort,
  ]);

  const getAllProposalsHandler = async () => {
    try {
      getProposals({
        variables: {
          treasuryAddress: activeTreasury?.treasuryAddress,
          proposalFilters: {
            ...filters,
            page: filters.page ? filters.page + 1 : 1,
          },
          userAddress: wallet?.publicKey.toString(),
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const canUserCreateProposal = useMemo(() => {
    return checkIfUserCanCreateAtLeastOneTypeOfProposal(
      memberTreasuryInfo,
      activeTreasury?.treasuryRoleConfig
    );
  }, [memberTreasuryInfo, activeTreasury]);

  return (
    <ClubDetailsLayout>
      {createProposal && (
        <CreateProposalModal closeModal={() => toggleCreateProposal(false)} />
      )}
      <div className="proposals">
        <TabHeader
          tabs={PROPOSAL_TABS}
          onClick={(tab: TabLabel) => {
            setFilters({
              ...filters,
              filter: tab,
              page: 0,
            });
          }}
          activeTab={filters.filter ?? TabLabel.Active}
        >
          <div className="proposals-bar-actions">
            <SearchBar
              setSearchValue={(value) => {
                setFilters({
                  ...filters,
                  search: value,
                  page: 0,
                });
              }}
              initialValue={searchParams.get("proposal") ?? EMPTY_STRING}
            />
            <SortDropdown
              sortOptions={sortProposalsOptions}
              sortDeals={(sort) => {
                setFilters({
                  ...filters,
                  sort: sort,
                  page: 0,
                });
              }}
            />
            <MainButton
              onClick={() => toggleCreateProposal(true)}
              light
              type="button"
              disabled={!canUserCreateProposal}
            >
              {" "}
              Create proposal
            </MainButton>
          </div>
        </TabHeader>
        <div className="proposals-cards">
          {proposalsInfo.loading ? (
            generateSkeletonArrays(5).map((item) => {
              return <ProposalsItemSkeleton key={item.toString()} />;
            })
          ) : proposals && proposals?.length < 1 ? (
            <NoData
              message={"No Active Proposals"}
              mainButtonText={"Create a Proposal"}
              mainButtonOnClick={() => toggleCreateProposal(true)}
              disabled={!canUserCreateProposal}
            />
          ) : (
            proposals?.map((p, index) => <ProposalCard proposal={p} />)
          )}
        </div>
      </div>
      {totalItems ? (
        <Pagination
          totalPages={Math.ceil(totalItems / ITEMS_PER_PAGE)}
          handlePageChange={({ selected }) => {
            setFilters({ ...filters, page: selected });
          }}
          currentPage={filters.page ?? 0}
        />
      ) : (
        EMPTY_STRING
      )}
    </ClubDetailsLayout>
  );
};

export default Proposals;
