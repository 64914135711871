export enum Sort {
  Name = "Name",
  ActiveFundraise = "ActiveFundraise",
  TotalRaisedAsc = "TotalRaisedAsc",
  TotalRaisedDesc = "TotalRaisedDesc",
  Type = "Type",
  Status = "Status",
  DateAdded = "DateAddedEarliest",
  Role = "Role",
  JoinDateLatest = "JoinDateLatest",
  JoinDateEarliest = "JoinDateEarliest",
  LastActive = "Last active",
  LastDeposit = "Last deposit",
  AmountOfSharesAsc = "AmountOfSharesAsc",
  AmountOfSharesDesc = "AmountOfSharesDesc",
  TotalPriceAsc = "TotalPriceAsc",
  TotalPriceDesc = "TotalPriceDesc",
  PricePerPctAsc = "PricePerPctAsc",
  PricePerPctDesc = "PricePerPctDesc",
  DateCreated = "Date created",
  StakingAmount = "Staking amount",
  Collection = "Collection",
  PurchasePrice = "Purchase Price",
  AppliedAtLatest = "AppliedAtLatest",
  AppliedAtEarliest = "AppliedAtEarliest",
  NumberOfTreasuries = "Number of treasury memberhsips",
  DataAddedLatest = "DateAddedLatest",
  DateAddedAsc = "DateAddedAsc",
  DateAddedDesc = "DateAddedDesc",
}

export enum SortLabel {
  Name = "Name",
  ActiveFundraise = " Active fundraise",
  TotalRaisedAsc = "Total raised-ascending",
  TotalRaisedDesc = "Total raised-descending",
  Type = "Type",
  Status = "Status",
  DateAdded = "Date added-earliest",
  Role = "Roles",
  JoinDateLatest = "Join date-latest",
  JoinDateEarliest = "Join date-earliest",
  LastActive = "Last active",
  LastDeposit = "Last deposit",
  AmountOfSharesAsc = "Amount of shares-ascending",
  AmountOfSharesDesc = "Amount of shares-descending",
  TotalPriceAsc = "Total price-ascending",
  TotalPriceDesc = "Total price-descending",
  PricePerPctAsc = "Price per percentage-ascending",
  PricePerPctDesc = "Price per percentage-descending",
  TotalPrice = "Total price",
  DateCreated = "Date created",
  StakingAmount = "Staking amount",
  Collection = "Collection",
  PurchasePrice = "Purchase price",
  AppliedAtLatest = "Applied at-latest",
  AppliedAtEarliest = "Applied at-earliest",
  NumberOfTreasuries = "Number of treasury memberhsips",
  DataAddedLatest = "Data added-latest",
  DateAddedAsc = "Date added-ascending",
  DateAddedDesc = "Date added-descending",
}

export enum TabLabel {
  AllDeals = "All Deals",
  MyDeals = "My Deals",
  AllOffers = "AllOffers",
  MyOffers = "MyOffers",
  History = "History",
  Funding = "Funding",
  Locked = "Locked",
  Members = "All Members",
  Invitations = "Invitations",
  Applications = "Applications",
  Clubs = "Clubs",
  Treasuries = "Treasuries",
  Proposals = "Proposals",
  Withdrawals = "Withdrawals",
  Staking = "Staking",
  Membership = "Membership",
  Active = "Active",
  Ended = "Ended",
  ClubNfts = "Club NFTs",
  Buying = "Buying",
  Selling = "Selling",
  PastFundraises = "Past Fundraises",
  HistoricalDeposits = "Historical Deposits",
  Allocation = "Alocation",
  Deposits = "Deposits",
}

export enum Visibility {
  Show = "Show",
  Hide = "Hide",
}

export enum Chain {
  Solana,
  Polygon,
  Ethereum,
}

export enum SequenceType {
  Sequential,
  Parallel,
  StopOnFailure,
}

export enum TransactionState {
  Pending,
  Loading,
  Succeeded,
  Failed,
}

export enum FetchingDataState {
  Pending,
  Loading,
  Succeeded,
}

export enum Rarity {
  Common = "common",
  Rare = "rare",
  Epic = "epic",
  Legendary = "legendary",
}

export enum StakePeriodType {
  OneMonth,
  ThreeMonths,
  SixMonths,
  TwelveMonths,
}

export enum NotificationTab {
  Staking = "Staking",
  Proposal = "Proposal",
  Treasury = "Treasury",
  Vault = "Vault",
  ApplicationForClub = "ApplicationForClub",
  Invitations = "Invitations",
  Otc = "Otc",
  Overview = "Overview",
}

export enum NotificationType {
  ClubInvitation,
  ApplicationForClub,
  FundraiseInProgress,
  StakingOpened,
  StakingOver,
  DiscussionProposalCreated,
  TradingProposalCreated,
  WithdrawalProposalCreated,
  TradeExecutedBuy,
  TradeExecutedSell,
  TradingProposalSucceeded,
  DiscussionProposalSucceeded,
  WithdrawalProposalSucceeded,
  TradingProposalDefeated,
  DiscussionProposalDefeated,
  WithdrawalProposalDefeated,
  ProposalVetoed,
  SharesToBuyAllMembers,
  SharesToBuySpecificMember,
  SharesBought,
  TransferProposalCreated,
  TransferProposalSucceeded,
  TransferProposalDefeated,
  MemberExit,
  VotingPowerProposalCreated,
  VotingPowerProposalSucceeded,
  VotingPowerProposalDefeated,
  ApprovalPercentageProposalCreated,
  ApprovalPercentageProposalSucceeded,
  ApprovalPercentageProposalDefeated,
}

export enum NotificationActiveTab {
  All,
  Unread,
}
