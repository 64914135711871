import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import InputField from "../../../components/InputFields/InputField";
import Chip from "../../../components/Chip/Chip";
import { YELLOW100 } from "../../../common/constants/layout.constants";
import errorIcon from "../../../assets/error.svg";
import { ISharesOffer } from "../../../common/interfaces/form.interface";
import profile from "../../../assets/Profilepicture.png";
import "./SharesForm.scss";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import {
  getAmountWithDecimalsForCurrency,
  getTrimmedPublicKey,
} from "../../../utilities/helpers";
import sellOfferFormModel from "./sellOfferFormModel";
import { clubStore } from "../../../state/clubStore";
import { IOTCDeal } from "../../../common/interfaces/otc.interface";
import { getAdminConfigInfo } from "../../../program/program-helpers";
import { IFeeConfig } from "../../../common/interfaces/treasury.interface";
import FeeCard from "../../../components/FeeCard/FeeCard";

const SharesForm: FC<{
  selling?: boolean;
  otc?: IOTCDeal;
}> = ({ selling, otc }) => {
  const [specificBuyer, toggleSpecificBuyer] = useState(false);
  const [otcFeeConfig, setOtcFeeConfig] = useState<IFeeConfig[]>();
  const { values, setFieldValue } = useFormikContext<ISharesOffer>();
  const formFields = sellOfferFormModel.formFields;
  const wallet = useAnchorWallet();
  const { activeTreasury, memberData } = clubStore();

  useEffect(() => {
    getAdminFeeInfo();
  }, []);

  const getAdminFeeInfo = async () => {
    try {
      const admin = await getAdminConfigInfo();
      setOtcFeeConfig(admin.otcFeeConfigs);
    } catch (error) {
      console.log(error);
    }
  };

  const calculatePrice = useCallback(
    (percentage: number) => {
      if (otc) {
        const price = percentage * otc.pricePerPct;
        setFieldValue(formFields.price.name, price.toFixed(3));
      }
    },
    [otc]
  );

  const calculatePercentageFromAmount = useCallback(
    (e: any) => {
      if (activeTreasury) {
        const totalPower = getAmountWithDecimalsForCurrency(
          activeTreasury.currencyDecimals,
          activeTreasury.totalFinancialPower
        );
        if (totalPower) {
          const percentage = (e.target.value / totalPower) * 100;
          setFieldValue(
            formFields.sharesPercentage.name,
            percentage.toFixed(2)
          );
          if (!selling) {
            calculatePrice(percentage);
          }
        }
      }
    },
    [
      activeTreasury,
      getAmountWithDecimalsForCurrency,
      setFieldValue,
      selling,
      calculatePrice,
    ]
  );

  const calculateAmountFromPercentage = useCallback(
    (e: any) => {
      if (activeTreasury) {
        const totalPower = getAmountWithDecimalsForCurrency(
          activeTreasury.currencyDecimals,
          activeTreasury.totalFinancialPower
        );
        const amount =
          Number(values.totalSharesOrOfferedShares) * (e.target.value / 100);
        setFieldValue(formFields.sharesAmount.name, amount);
        if (!selling) {
          const amount = Number(totalPower) * (e.target.value / 100);
          setFieldValue(formFields.sharesAmount.name, amount);
          calculatePrice(Number(e.target.value));
        }
      }
    },
    [
      activeTreasury,
      values.totalSharesOrOfferedShares,
      getAmountWithDecimalsForCurrency,
      setFieldValue,
      selling,
      calculatePrice,
    ]
  );

  const calculateInfoPercentages = useCallback(() => {
    let percentageOfTotalShares;
    let percentageOfAvailableShares;
    if (activeTreasury) {
      const totalPower = getAmountWithDecimalsForCurrency(
        activeTreasury.currencyDecimals,
        activeTreasury.totalFinancialPower
      );
      if (totalPower) {
        percentageOfTotalShares = (
          (Number(values.totalSharesOrOfferedShares) / totalPower) *
          100
        ).toFixed(2);
        percentageOfAvailableShares = (
          (Number(values.availableSharesOrTotalPrice) / totalPower) *
          100
        ).toFixed(2);
      }
    }

    return {
      percentageOfTotalShares,
      percentageOfAvailableShares,
    };
  }, [
    activeTreasury,
    values.totalSharesOrOfferedShares,
    values.availableSharesOrTotalPrice,
    getAmountWithDecimalsForCurrency,
  ]);

  const { percentageOfTotalShares, percentageOfAvailableShares } =
    calculateInfoPercentages();

  const getFeesAmount = useMemo(() => {
    return (
      (otcFeeConfig?.reduce((acc, val) => acc + val.feePercentage, 0) ?? 0) /
      100
    );
  }, [otcFeeConfig]);

  return (
    <div className="add-member-form">
      <div className="add-member-form__info">
        <img src={memberData?.imageUrl ?? profile} alt="Profile" />
        <h2>Username</h2>
        <h3>{wallet ? getTrimmedPublicKey(wallet.publicKey) : "..."}</h3>
      </div>
      <div className="add-member-form__fields">
        <InputField
          type="number"
          disabled
          labelTitle={selling ? "Total shares" : "Offered shares"}
          name={formFields.totalSharesOrOfferedShares.name}
          textProp={{
            color: "#06D7A0",
            text: `${selling ? `≈${percentageOfTotalShares}%` : ""}`,
          }}
        />
        <InputField
          type="number"
          disabled
          labelTitle={selling ? "Available" : "Total price"}
          name={formFields.availableSharesOrTotalPrice.name}
          textProp={{
            color: "#06D7A0",
            text: `${selling ? `≈${percentageOfAvailableShares}%` : ""}`,
          }}
        />
      </div>
      <div className="add-member-form__description">
        <p>
          To be able to perform the trade of financial rights, both seller and
          buyer need to be members of the same Club and have their identities
          verified.
        </p>
      </div>
      <div className="add-member-form__inputs">
        <h2>{selling ? "Shares to sell" : "Shares to buy"}</h2>
        <div className="add-member-form__row">
          <InputField
            name={formFields.sharesAmount.name}
            type="number"
            labelTitle="Amount"
            onChange={(e: any) => calculatePercentageFromAmount(e)}
          />
          <InputField
            name={formFields.sharesPercentage.name}
            type="number"
            labelTitle="Percentage"
            onChange={(e: any) => calculateAmountFromPercentage(e)}
            textProp={{ text: "%" }}
          />
          <InputField
            name={formFields.price.name}
            type="number"
            labelTitle="Price"
            disabled={!selling}
            textProp={{ text: "SOL" }}
          />
        </div>
        {selling && (
          <>
            <InputField
              name="1"
              type="toggle"
              labelTitle="Offer to specific buyer"
              onChange={() => toggleSpecificBuyer(!specificBuyer)}
            />
            {specificBuyer && (
              <InputField
                name={formFields.buyer.name}
                type="text"
                labelTitle="Wallet address of specified buyer"
              />
            )}
          </>
        )}
      </div>
      {selling && (
        <FeeCard>
          <p>
            Platform fee is <b>{getFeesAmount} %</b> of your Selling shares
            amount.
          </p>
        </FeeCard>
      )}
      <div className="add-member-form__chip">
        <Chip
          text={
            selling
              ? "Sell shares fee = 0.0215 SOL"
              : "Buy shares fee = 0.0215 SOL"
          }
          backgroundColor={YELLOW100}
          stretch
          fontSize="0.8"
          icon
          image={errorIcon}
        />
      </div>
    </div>
  );
};

export default SharesForm;
