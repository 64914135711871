import create from "zustand";
import { IDetailNftData } from "../common/interfaces/common.interface";
import {
  FetchingDataState,
  TransactionState,
} from "../common/enums/common.enum";

export interface ITransactionInfo {
  txid: string | null;
  number: number;
  transactionState: TransactionState;
  description: string;
}

interface ITransactionStore {
  isProcessing: boolean;
  transactions: ITransactionInfo[];
  addTransactions: (transaction: ITransactionInfo[]) => void;
  processedTransactions: ITransactionInfo[];
  retryCallback: (() => Promise<void>) | null;
  updateCurrentTransaction: (
    transaction: ITransactionInfo,
    retryCallback?: () => Promise<void>
  ) => void;
  closeTransactionProcess: () => void;
  startProcessing: (
    transactions: ITransactionInfo[],
    isFetchingData?: boolean
  ) => void;
  updateProcessedTransactions: (transaction: ITransactionInfo) => void;
  warningDescription?: string;
  customTitle?: string;
  nftData?: IDetailNftData;
  setWarningDescription: (description: string) => void;
  setCustomTitle: (title: string) => void;
  setNftData: (data: IDetailNftData) => void;
  isFetchingData: boolean;
  updateFetchingData: (fetchingStatus: FetchingDataState) => void;
  fetchingStatus: FetchingDataState;
}

const defaultState = {
  isProcessing: false,
  transactions: [],
  processedTransactions: [],
  retryCallback: null,
  nftData: undefined,
  customTitle: undefined,
  warningDescription: undefined,
  isFetchingData: false,
  fetchingStatus: FetchingDataState.Pending,
};

const useTransactionStore = create<ITransactionStore>((set, get) => ({
  ...defaultState,
  startProcessing: (
    transactions: ITransactionInfo[],
    isFetchingData?: boolean
  ) => {
    if (isFetchingData) {
      set({
        fetchingStatus: FetchingDataState.Pending,
      });
    }
    set({
      isProcessing: true,
      transactions: transactions,
      isFetchingData,
    });
  },
  closeTransactionProcess: () => {
    set({
      ...defaultState,
    });
  },
  updateCurrentTransaction: (
    transaction: ITransactionInfo,
    retryCallback?: () => Promise<void>
  ) => {
    const transactions = [...get().transactions];
    const txIndex = transactions.findIndex(
      (item) => item.number === transaction.number
    );
    transactions[txIndex] = {
      number: transactions[txIndex].number,
      transactionState: transaction.transactionState,
      txid: transaction.txid,
      description: transaction.description,
    };

    set({
      transactions: transactions,
      retryCallback: retryCallback ?? null,
    });
  },
  updateProcessedTransactions: (transaction: ITransactionInfo) => {
    const processedTx = get().processedTransactions;
    set({
      processedTransactions: [...processedTx, transaction],
    });
  },
  addTransactions: (transactions: ITransactionInfo[]) =>
    set(() => ({ transactions: [...get().transactions, ...transactions] })),
  setWarningDescription: (description: string) =>
    set(() => ({ warningDescription: description })),
  setCustomTitle: (title: string) => set(() => ({ customTitle: title })),
  setNftData: (data: IDetailNftData | undefined) =>
    set(() => ({ nftData: data })),
  updateFetchingData: (fetchingStatus: FetchingDataState) =>
    set({
      fetchingStatus: fetchingStatus,
      isFetchingData: fetchingStatus !== FetchingDataState.Succeeded,
    }),
}));

export default useTransactionStore;
