import { AnchorWallet } from "@solana/wallet-adapter-react";
import { Connection, Keypair, PublicKey } from "@solana/web3.js";
import { Program, AnchorProvider } from "@project-serum/anchor";
import { IDL } from "./program-idl";
import { IDL as ESCROW_IDL } from "./escrow-program-idl";

import { emptyWallet } from "./program-helpers";
// import NodeWallet from "@project-serum/anchor/dist/cjs/nodewallet";

//Program IDS from env
export const CLUB_PROGRAM_ID = new PublicKey(
  process.env.REACT_APP_BLOCKCHAIN_CLUB_PROGRAM_ID as string
);
export const UNQ_SPL_GOVERNANCE_PROGRAM_ID = new PublicKey(
  process.env.REACT_APP_BLOCKCHAIN_GOVERNANCE_PROGRAM_ID as string
);

export const ESCROW_PROGRAM_ID = new PublicKey(
  process.env.REACT_APP_BLOCKCHAIN_ESCROW_PROGRAM_ID as string
);

export const SOLANA_ENDPOINT = process.env
  .REACT_APP_BLOCKCHAIN_NETWORK_URL as string;

//Connection
export const RPC_CONNECTION = new Connection(
  SOLANA_ENDPOINT.toString(),
  "confirmed"
);

//Club program
export const programFactory = (wallet?: AnchorWallet) => {
  return new Program(
    IDL,
    CLUB_PROGRAM_ID,
    new AnchorProvider(
      RPC_CONNECTION,
      wallet ?? emptyWallet(Keypair.generate().publicKey),
      {}
    )
  );
};

//Escrow program
export const escrowProgramFactory = (wallet?: AnchorWallet) => {
  return new Program(
    ESCROW_IDL,
    ESCROW_PROGRAM_ID,
    new AnchorProvider(
      RPC_CONNECTION,
      wallet ?? emptyWallet(Keypair.generate().publicKey),
      {}
    )
  );
};

//Community mint
export const MINT_KEY = "UNQtEecZ5Zb4gSSVHCAWUQEoNnSVEbWiKCi1v9kdUJJ";
export const USDC_MINT = "4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU";
export const USDT_MINT = "HY6uvCfBQhKANRxBcYLBK7aUva8mT7mLP2SjrLLmipza";
export const mint: PublicKey = new PublicKey(MINT_KEY);
export const usdcMint: PublicKey = new PublicKey(USDC_MINT);
export const usdtMint: PublicKey = new PublicKey(USDT_MINT);
export const METADATA_PROGRAM_ID = new PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);
