import { ISetReservedRights } from "../../common/interfaces/form.interface";
import { validateMultipleMembersField } from "../../utilities/helpers";

export const setReservedRightsValidation = (values: ISetReservedRights) => {
  const errors: any = {};

  validateMultipleMembersField(values.members, "members", errors);
  values.members.forEach((item, index) => {
    if (Number(item.membersAmount) <= 0 || Number(item.membersAmount) > 100) {
      errors[`members.${index}.membersAmount`] =
        "Reserved rights percentage must be between 0 and 100";
    }
  });

  return errors;
};
