import { FC } from "react";
import { IDashboardClub } from "../../../common/interfaces/dashboard.interface";
import "./ClubsDropdown.scss";
import HoverIcon from "../../../components/HoverIcon/HoverIcon";
import arrowdown from "../../../assets/keyboard_arrow_down.png";
import avatar from "../../../assets/unique_avatar.png";
import { getTrimmedPublicKey } from "../../../utilities/helpers";

const ClubsDropdown: FC<{
  clubs: IDashboardClub[];
  selectedClub: IDashboardClub | undefined;
  dropdown: boolean;
  toggleDropdown: (isOpened: boolean) => void;
  handleCardChange: (c: IDashboardClub) => void;
}> = ({ clubs, selectedClub, dropdown, handleCardChange, toggleDropdown }) => {
  return (
    <div className="clubs-dropdown">
      <div
        className="clubs-dropdown__menu"
        onClick={() => toggleDropdown(!dropdown)}
      >
        {selectedClub && (
          <div className="clubs-dropdown__active">
            <img src={selectedClub.clubImage || avatar} />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h6>{selectedClub.clubName}</h6>
              <div>
                <p>{getTrimmedPublicKey(selectedClub.clubAddress)}</p>
              </div>
            </div>
          </div>
        )}
        <div className="clubs-dropdown__options">
          <HoverIcon
            src={arrowdown}
            alt="Dropdown arrow"
            onClick={() => toggleDropdown(!dropdown)}
          />
        </div>
        {dropdown && (
          <>
            <div className="clubs-dropdown__list">
              {clubs.map((c, index) => (
                <div
                  className="clubs-dropdown__details"
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleCardChange(c)}
                >
                  <img src={c.clubImage || avatar} />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h6>{c.clubName}</h6>
                    <div>
                      <p>{getTrimmedPublicKey(c.clubAddress)}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className="backdrop"
              onClick={() => toggleDropdown(false)}
            ></div>
          </>
        )}
      </div>
    </div>
  );
};

export default ClubsDropdown;
