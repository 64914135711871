import React, { useMemo } from "react";
import InputField from "../../InputFields/InputField";
import proposalFormConfig from "../proposalFormConfig";
import "./TransferFundsProposal.scss";
import AmountWithMint from "../AmountWithMint/AmountWithMint";

const TransferFundsProposal = () => {
  return (
    <div className="transfer-funds-proposal">
      <div>
        <p>Funds</p>
        <AmountWithMint />
        <InputField
          name={proposalFormConfig.formFields.transferAddress.name}
          type="text"
          labelTitle={proposalFormConfig.formFields.transferAddress.label}
          placeholder={proposalFormConfig.formFields.transferAddress.label}
        />
      </div>
    </div>
  );
};

export default TransferFundsProposal;
