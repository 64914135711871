import React, { FC, useMemo } from "react";
import { getAmountWithDecimalsForCurrency } from "../../../utilities/helpers";
import InputField from "../../InputFields/InputField";
import proposalFormConfig from "../proposalFormConfig";
import { useFormikContext } from "formik";
import { IOption } from "../../../common/interfaces/common.interface";
import { IProposalFormFields } from "../../../common/interfaces/form.interface";
import { clubStore } from "../../../state/clubStore";
import "./AmountWithMint.scss";

const AmountWithMint: FC<{ withdrawal?: boolean }> = ({ withdrawal }) => {
  const { activeTreasury } = clubStore();
  const { values } = useFormikContext<IProposalFormFields>();

  const options: IOption[] = activeTreasury
    ? activeTreasury?.treasuryTokens.map((token) => {
        return {
          label: token.name,
          value:
            token.tokenAddress ?? activeTreasury.treasuryAddress.toString(),
        };
      })
    : [];

  const selectedTreasuryTokenInfo = useMemo(() => {
    return (
      activeTreasury?.treasuryTokens.find(
        (item) => item.tokenAddress === values.tokenAddress
      ) ?? activeTreasury?.treasuryTokens.find((item) => !item.tokenAddress)
    );
  }, [activeTreasury, values]);

  const amountFormField = useMemo(() => {
    return withdrawal
      ? proposalFormConfig.formFields.withdrawalAmount
      : proposalFormConfig.formFields.transferAmount;
  }, [withdrawal]);

  return (
    <div className="amount-with-mint">
      <div className="amount-with-mint__fields">
        <InputField
          name={amountFormField.name}
          type="text"
          labelTitle={amountFormField.label}
          placeholder={amountFormField.label}
        />
        <InputField
          name={proposalFormConfig.formFields.tokenAddress.name}
          type="select"
          options={options}
          labelTitle={proposalFormConfig.formFields.tokenAddress.label}
        />
      </div>
      <p className="amount-with-mint__subtext">
        Total {withdrawal ? "withdrawable" : "transferable"} amount:
        <span>
          {getAmountWithDecimalsForCurrency(
            selectedTreasuryTokenInfo?.decimals,
            selectedTreasuryTokenInfo?.balance
          )}
        </span>{" "}
        {selectedTreasuryTokenInfo?.symbol}
      </p>
    </div>
  );
};

export default AmountWithMint;
