import { FC, useEffect, useMemo, useState } from "react";
import "./Members.scss";
import MainButton from "../../components/MainButton/MainButton";
import Pagination from "../../components/Pagination/Pagination";
import MemberCard from "./MemberCard/MemberCard";
import {
  checkIfUserHasClubPermissionForAction,
  getInitialValuesForFilters,
} from "../../utilities/helpers";
import NoData from "../../components/NoData/NoData";
import SearchBar from "../../components/SearchBar/SearchBar";
import { FetchingDataState, TabLabel } from "../../common/enums/common.enum";
import TabHeader from "../../components/TabHeader/TabHeader";
import SortDropdown from "../../components/SortDropdown/SortDropdown";
import { MEMBERS_TAB } from "../../common/constants/navigation.constants";
import AddNewMemberModal from "./AddNewMemberModal/AddNewMemberModal";
import ClubDetailsLayout from "../../components/ClubDetailsWrapper/ClubDetailsWrapper";
import {
  sortApplicationOptions,
  sortMembersOptions,
} from "../../common/constants/sort.constants";
import {
  GET_ALL_MEMBERS_FOR_CLUB,
  GET_APPLICATIONS_FOR_CLUB,
  GET_INITIAL_CLUB_DETAILS,
} from "../../api/club.api";
import { clubStore } from "../../state/clubStore";
import {
  IApplicationInfo,
  IMemberData,
} from "../../common/interfaces/club.interface";
import {
  ApplicationStatus,
  ClubAction,
  MemberStatus,
} from "../../common/enums/clubs.enum";
import { useLazyQuery, useSubscription } from "@apollo/client";
import { IBasicFilters } from "../../common/interfaces/common.interface";
import {
  MEMBER_DATA_CHANGED_SUBSCRIPTION,
  MEMBER_DATA_DELETED_SUBSCRIPTION,
} from "../../api/club-subscriptions.api";
import { apolloClient } from "../../api/config.api";
import useTransactionStore from "../../state/transactionStore";
import { ITEMS_PER_PAGE } from "../../common/constants/common.constants";
import { useSearchParams } from "react-router-dom";

const Members: FC = () => {
  const [inviteMembers, toggleInviteMembers] = useState(false);
  const [totalItems, setTotalItems] = useState();
  const [members, setMembers] = useState<IMemberData[]>();
  const [applications, setApplications] = useState<IApplicationInfo[]>();
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState<IBasicFilters>({
    ...getInitialValuesForFilters(),
    filter: searchParams.get("filter") ?? TabLabel.Members,
  });

  useEffect(() => {
    if (searchParams.get("filter")) {
      setFilters({
        ...filters,
        filter: searchParams.get("filter") ?? TabLabel.Members,
      });
    }
  }, [searchParams]);

  const { clubBasicInfo, memberData } = clubStore();
  const { updateFetchingData } = useTransactionStore();

  const [getMembers] = useLazyQuery(GET_ALL_MEMBERS_FOR_CLUB, {
    onCompleted: (data) => {
      setMembers(data?.getAllClubMembers.memberData);
      setTotalItems(data?.getAllClubMembers.count);
    },
    fetchPolicy: "cache-and-network",
  });

  const [getApplications] = useLazyQuery(GET_APPLICATIONS_FOR_CLUB, {
    onCompleted: (data) => {
      setApplications(data?.getApplicationsForClub.applications);
      setTotalItems(data?.getApplicationsForClub.count);
    },
  });

  useSubscription(MEMBER_DATA_CHANGED_SUBSCRIPTION, {
    onData: ({ data }) => {
      console.log("MEMBER DATA SUB");
      apolloClient.refetchQueries({
        include: [GET_INITIAL_CLUB_DETAILS],
      });
      data.data.memberDataChanged.forEach((item: IMemberData) => {
        updateMember(item);
      });
      updateFetchingData(FetchingDataState.Succeeded);
    },
    variables: {
      clubAddress: clubBasicInfo?.address,
    },
  });

  useSubscription(MEMBER_DATA_DELETED_SUBSCRIPTION, {
    onData: ({ data }) => {
      console.log("MEMBER DATA DELETED SUB", data);
      const filteredMembers = members?.filter(
        (item) =>
          !data.data?.memberDataDeleted.deletedMembers.includes(item.address)
      );
      setMembers(filteredMembers);
      updateFetchingData(FetchingDataState.Succeeded);
    },
    variables: {
      clubAddress: clubBasicInfo?.address,
    },
  });

  useEffect(() => {
    void getMemberPageData();
  }, [clubBasicInfo, filters]);

  const updateMember = (memberInfo: IMemberData) => {
    if (members) {
      const updatedMembers = [...members];
      const memberIndex = updatedMembers?.findIndex(
        (item) => item.memberPubkey === memberInfo.memberPubkey
      );
      if (memberIndex < 0) {
        updatedMembers.unshift(memberInfo);
      } else {
        updatedMembers[memberIndex] = memberInfo;
      }
      setMembers(updatedMembers);
    }
  };

  const getMemberPageData = async () => {
    try {
      if (!clubBasicInfo) return;
      switch (filters.filter) {
        case TabLabel.Members:
          getMembers({
            variables: {
              clubAddress: clubBasicInfo?.address,
              memberDataFilters: {
                search: filters.search,
                sort: filters.sort,
                page: filters.page ? filters.page + 1 : 1,
                status: MemberStatus.Accepted,
              },
            },
          });
          break;
        case TabLabel.Invitations:
          getMembers({
            variables: {
              clubAddress: clubBasicInfo?.address,
              memberDataFilters: {
                search: filters.search,
                sort: filters.sort,
                page: filters.page ? filters.page + 1 : 1,
                status: MemberStatus.Pending,
              },
            },
          });
          break;
        case TabLabel.Applications:
          getApplications({
            variables: {
              clubAddress: clubBasicInfo?.address,
              applicationFilters: {
                search: filters.search,
                sort: filters.sort,
                page: filters.page ? filters.page + 1 : 1,
                filter: ApplicationStatus.Pending,
              },
            },
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sortOptions = useMemo(() => {
    switch (filters.filter) {
      case TabLabel.Members:
        return sortMembersOptions;
      case TabLabel.Invitations:
        return [];
      case TabLabel.Applications:
        return sortApplicationOptions;
      default:
        return [];
    }
  }, [filters.filter]);

  const renderMemberItems = useMemo(() => {
    switch (filters.filter) {
      case TabLabel.Members:
      case TabLabel.Invitations:
        return (
          members &&
          members.map((m: IMemberData, index) => (
            <MemberCard
              publicKey={m.memberPubkey}
              joinedAt={m.joinedAt}
              imageUrl={m.imageUrl}
              memberName={m.userName}
              role={m.role}
              tab={filters.filter as TabLabel}
              key={index}
            />
          ))
        );
      case TabLabel.Applications:
        return (
          applications &&
          applications.map((m: IApplicationInfo, index) => (
            <MemberCard
              publicKey={m.memberAddress}
              imageUrl={m.imageUrl}
              memberName={m.userName}
              appliedAt={m.appliedAt}
              tab={filters.filter as TabLabel}
              key={index}
              motivationLetter={m.motivationLetter}
              networks={m.networks}
            />
          ))
        );
      default:
        return [];
    }
  }, [applications, members, filters]);

  return (
    <ClubDetailsLayout>
      <div className="members">
        {inviteMembers && (
          <AddNewMemberModal closeModal={() => toggleInviteMembers(false)} />
        )}
        <TabHeader
          tabs={MEMBERS_TAB}
          onClick={(tab: TabLabel) => {
            setFilters({
              search: filters.search,
              filter: tab,
              page: 0,
            });
          }}
          activeTab={filters.filter ?? TabLabel.Members}
        >
          <div className="members-actions">
            <MainButton
              light
              type="button"
              onClick={() => toggleInviteMembers(true)}
              disabled={
                !checkIfUserHasClubPermissionForAction(
                  memberData,
                  clubBasicInfo?.roleConfig,
                  ClubAction.AllowMember
                )
              }
            >
              Invite Members
            </MainButton>
            <SearchBar
              setSearchValue={(value) => {
                setFilters({
                  ...filters,
                  search: value,
                  page: 0,
                });
              }}
            />
            {sortOptions.length > 0 && (
              <SortDropdown
                sortOptions={sortOptions}
                sortDeals={(sort) => {
                  setFilters({
                    ...filters,
                    sort: sort,
                    page: 0,
                  });
                }}
              />
            )}
          </div>
        </TabHeader>
        {filters.filter === TabLabel.Members && memberData && (
          <MemberCard
            tab={TabLabel.Members}
            publicKey={memberData?.memberPubkey}
            imageUrl={memberData.imageUrl}
            joinedAt={memberData.joinedAt}
            memberName={memberData.userName}
            role={memberData.role}
          />
        )}
        <h3>{filters.filter}</h3>
        {totalItems && renderMemberItems && renderMemberItems.length > 0 ? (
          <div className="members__cards">
            {renderMemberItems}
            <Pagination
              totalPages={Math.ceil(totalItems / ITEMS_PER_PAGE)}
              handlePageChange={({ selected }) => {
                setFilters({ ...filters, page: selected });
              }}
              currentPage={filters.page ?? 0}
            />
          </div>
        ) : (
          <NoData
            message={`This club has no active ${
              filters.filter === TabLabel.Members
                ? "members"
                : filters.filter === TabLabel.Invitations
                ? "invitations"
                : "applications"
            }`}
            mainButtonText={"Invite Members"}
            mainButtonOnClick={() => {
              toggleInviteMembers(true);
            }}
            disabled={
              !checkIfUserHasClubPermissionForAction(
                memberData,
                clubBasicInfo?.roleConfig,
                ClubAction.AllowMember
              )
            }
          />
        )}
      </div>
    </ClubDetailsLayout>
  );
};

export default Members;
