import "./FundraiseInfo.scss";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import FundraiseActions from "../FundraiseActions/FundraiseActions";
import { IFundraiseConfig } from "../../../common/interfaces/club.interface";
import { clubStore } from "../../../state/clubStore";
import { getAmountWithDecimalsForCurrency } from "../../../utilities/helpers";
import React, { FC } from "react";
import TokenUnlockingDate from "../../../components/TokenUnlockingDate/TokenUnlockingDate";

const FundraiseInfo: FC<{
  fundraise: IFundraiseConfig;
  expanded: boolean;
}> = ({ fundraise }) => {
  const { activeTreasury, memberTreasuryInfo } = clubStore();

  return (
    <>
      <div className="fundraise-info">
        <div>
          <label className="fundraise-info__totaly">Totaly raised </label>
          <label className="fundraise-info__totaly-amount">
            {getAmountWithDecimalsForCurrency(
              activeTreasury?.currencyDecimals,
              fundraise.raisedAmount
            )}
            <span className="fundraise-info__totaly-currency">
              {" "}
              {activeTreasury?.currencySymbol}
            </span>
          </label>
        </div>
        <div>
          <label className="fundraise-info__goal">Goal </label>
          <label className="fundraise-info__goal-amount">
            {getAmountWithDecimalsForCurrency(
              activeTreasury?.currencyDecimals,
              fundraise?.capAmount
            )}
            <span className="fundraise-info__goal-currency">
              {" "}
              {activeTreasury?.currencySymbol}
            </span>
          </label>
        </div>
      </div>
      <ProgressBar
        numerator={fundraise.raisedAmount}
        denominator={fundraise.capAmount}
        active={true}
      />
      <div className="fundraise-info">
        <div className="fundraise-info__deposit-info">
          <label className="fundraise-info__deposit-info-descrption">
            You deposited{" "}
          </label>
          <label className="fundraise-info__deposit-info-amount">
            {memberTreasuryInfo?.depositRecords &&
            memberTreasuryInfo?.depositRecords[
              memberTreasuryInfo?.depositRecords.length - 1
            ]?.fundraiseIndex === activeTreasury?.fundraiseCount
              ? getAmountWithDecimalsForCurrency(
                  activeTreasury?.currencyDecimals,
                  memberTreasuryInfo?.depositRecords[
                    memberTreasuryInfo?.depositRecords.length - 1
                  ]?.currentFundraiseDeposit
                )
              : 0}
            <span className="fundraise-info__deposit-info-amount-currency">
              {" "}
              {activeTreasury?.currencySymbol}
            </span>
          </label>
        </div>
        <TokenUnlockingDate />
      </div>
      <FundraiseActions />
    </>
  );
};

export default FundraiseInfo;
