import React, { FC } from "react";
import "./CurrencyChip.scss";

const CurrencyChip: FC<{ currency: string }> = ({ currency }) => {
  return (
    <div className="currency-chip">
      <p>{currency}</p>
    </div>
  );
};

export default CurrencyChip;
