import { EMPTY_STRING } from "../../../common/constants/common.constants";

export default {
  formId: "sell-offer",
  formFields: {
    totalSharesOrOfferedShares: {
      name: "totalSharesOrOfferedShares",
      value: EMPTY_STRING,
    },
    availableSharesOrTotalPrice: {
      name: "availableSharesOrTotalPrice",
      value: EMPTY_STRING,
    },
    sharesAmount: {
      name: "sharesAmount",
      value: EMPTY_STRING,
      label: "Amount",
    },
    sharesPercentage: {
      name: "sharesPercentage",
      value: EMPTY_STRING,
      label: "Percentage",
    },
    price: {
      name: "price",
      value: EMPTY_STRING,
      label: "Price",
    },
    buyer: {
      name: "buyer",
      value: EMPTY_STRING,
    },
  },
};
