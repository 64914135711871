import { FC, useState } from "react";
import "./SidebarLink.scss";
import { ISidebarItem } from "../../common/interfaces/navigation.interface";
import { useLocation, useParams } from "react-router";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import { Link } from "react-router-dom";
import { Visibility } from "../../common/enums/common.enum";

interface ISidebarLink {
  item: ISidebarItem;
  expand: boolean;
  toggleExpand: (expand: boolean) => void;
}

const SidebarLink: FC<ISidebarLink> = ({ item, toggleExpand, expand }) => {
  const [showLabel, setShowLabel] = useState(false);
  const location = useLocation();
  const params = useParams();

  const expandSidebar = (e: any) => {
    if (item.label === Visibility.Show) {
      e.preventDefault();
      toggleExpand(!expand);
      setShowLabel(false);
    }
  };

  return (
    <Link
      to={`${item.route}/${params.dealAddress}/${params.treasuryAddress}`}
      className={
        location.pathname.includes(item.route) && item.route !== EMPTY_STRING
          ? "active-sidebar-link"
          : EMPTY_STRING
      }
      onMouseEnter={() => !expand && setShowLabel(true)}
      onMouseLeave={() => !expand && setShowLabel(false)}
    >
      <div
        className={expand ? "sidebar-link--expanded" : "sidebar-link"}
        onClick={(e) => expandSidebar(e)}
      >
        <img
          src={item.icon}
          alt={item.alt}
          className={
            expand && item.label === Visibility.Show
              ? Visibility.Hide
              : EMPTY_STRING
          }
        />
        {expand && (
          <p
            className={
              location.pathname === item.route
                ? expand
                  ? "active-sidebar-link-label"
                  : EMPTY_STRING
                : EMPTY_STRING
            }
          >
            {item.label !== Visibility.Show ? item.label : Visibility.Hide}
          </p>
        )}
        {showLabel && <p>{item.label}</p>}
      </div>
    </Link>
  );
};

export default SidebarLink;
