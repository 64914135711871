import { ProposalState } from "@solana/spl-governance";
import {
  YELLOW100,
  GREEN100,
  BLUE100,
  RED100,
  WHITE300,
} from "../common/constants/layout.constants";
import { ProposalType } from "../common/enums/proposal.enum";

export const getProposalChipColor = (value: ProposalState | ProposalType) => {
  switch (value) {
    case ProposalState.Voting:
      return YELLOW100;
    case ProposalState.Succeeded || ProposalState.Executing:
      return GREEN100;
    case ProposalState.Cancelled:
      return BLUE100;
    case ProposalState.Defeated:
      return RED100;
    case ProposalType.Discussion:
      return WHITE300;
  }
};
