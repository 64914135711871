import React, { FC } from "react";
import "./LabelCard.scss";
import { EMPTY_STRING } from "../../common/constants/common.constants";

interface ILabelCardProps {
  label: string;
  description?: string;
  img?: string;
  fontWeight?: number;
  backgroundColor?: string;
  fontSize?: string;
  width?: string;
}

const LabelCard: FC<ILabelCardProps> = ({
  label,
  description,
  img,
  fontWeight,
  backgroundColor,
  fontSize,
  width,
}) => {
  return (
    <div
      className="label-card"
      style={{
        backgroundColor: backgroundColor ?? EMPTY_STRING,
        width: width ?? EMPTY_STRING,
      }}
    >
      <div>
        <p className="label-card__label">{label}</p>
        {description && (
          <p
            className="label-card__description"
            style={{
              fontWeight: fontWeight ? fontWeight : EMPTY_STRING,
              fontSize: `${fontSize}em` ?? EMPTY_STRING,
            }}
          >
            {description}
          </p>
        )}
      </div>
      {img && <img src={img} alt="Field icon" />}
    </div>
  );
};

export default LabelCard;
