import React, { FC } from "react";
import "./CreateDealPanel.scss";

const CreateDealPanel: FC<{
  dealPanelTitle: string;
  dealPanelDescription: string;
}> = ({ dealPanelDescription, dealPanelTitle }) => {
  return (
    <div className="create-deal-panel">
      <div className="create-deal-panel__content">
        <h3 className="create-deal-panel__title">{dealPanelTitle}</h3>
        <p className="create-deal-panel__description">{dealPanelDescription}</p>
      </div>
    </div>
  );
};

export default CreateDealPanel;
