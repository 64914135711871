import { FC } from "react";
import "./HomeSummary.scss";
import DetailsCard from "../../../components/DetailsCard/DetailsCard";
import BasicDetailsCard from "../../../components/BasicDetailsCard/BasicDetailsCard";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import avatar from "../../../assets/unique_avatar.png";
import Chip from "../../../components/Chip/Chip";
import {
  getTrimmedPublicKey,
  handleCopyToClipboard,
} from "../../../utilities/helpers";
import link from "../../../assets/link.png";
import { WHITE300 } from "../../../common/constants/layout.constants";
import HoverIcon from "../../../components/HoverIcon/HoverIcon";
import { IDashboardClub } from "../../../common/interfaces/dashboard.interface";
import { useNavigate } from "react-router";
import { findTreasuryAddressByIndex } from "../../../program/program-helpers";

const ClubsSummary: FC<{
  clubs: IDashboardClub[];
}> = ({ clubs }) => {
  const navigate = useNavigate();

  const handleClubClick = (clubAddress: string) => {
    const treasuryAddress = findTreasuryAddressByIndex(clubAddress, 1);
    navigate(`/deal/${clubAddress}/${treasuryAddress}`);
  };

  return (
    <DetailsCard title="My Clubs">
      {clubs.map((club: IDashboardClub) => (
        <div
          className="home-summary__cards-club"
          key={club.clubAddress}
          onClick={() => handleClubClick(club.clubAddress)}
        >
          <BasicDetailsCard
            image={
              club.clubImage && club.clubImage !== EMPTY_STRING
                ? club.clubImage
                : avatar
            }
            alt={EMPTY_STRING}
            title={club.clubName}
            id={getTrimmedPublicKey(club.clubAddress)}
          />
          <div className="home-summary__cards-actions">
            <Chip
              text={club.role}
              backgroundColor={
                club.role === "founder" ? WHITE300 : "transparent"
              }
              fontSize="0.75"
            />
            <HoverIcon
              src={link}
              alt="Copy"
              onClick={(e) => {
                e.stopPropagation();
                handleCopyToClipboard(club.clubAddress);
              }}
            />
          </div>
        </div>
      ))}
    </DetailsCard>
  );
};

export default ClubsSummary;
