import { FC } from "react";
import { IBasicClubDataForm } from "../../../common/interfaces/form.interface";
import "./DealConfiguration.scss";
import { useFormikContext } from "formik";
import InputField from "../../../components/InputFields/InputField";
import createDealFormModel from "../createDealFormModel";

const DealConfiguration: FC<{ edit?: boolean; disabled?: boolean }> = ({
  edit,
  disabled,
}) => {
  const { values, setFieldValue } = useFormikContext<IBasicClubDataForm>();
  const formFields = createDealFormModel.formFields;

  /* KYC not in current version of the app */
  // const toggleExpanded = [
  //   {
  //     isSelected: values.kycType === KycType.All,
  //     label: "All countries",
  //     onClick: () => {
  //       setFieldValue(formFields.kycType.name, KycType.All);
  //     },
  //   },
  //   {
  //     isSelected: values.kycType === KycType.OnlyUS,
  //     label: "Only US citizens",
  //     onClick: () => {
  //       setFieldValue(formFields.kycType.name, KycType.OnlyUS);
  //     },
  //   },
  //   {
  //     isSelected: values.kycType === KycType.ExceptUS,
  //     label: "Non US citizens",
  //     onClick: () => {
  //       setFieldValue(formFields.kycType.name, KycType.ExceptUS);
  //     },
  //   },
  // ];

  return (
    <div
      className="deal-configuration"
      style={edit ? { marginTop: 0 } : undefined}
    >
      {/* KYC not in current version of the app */}
      {/* <InputField
        label={!edit ? "KYC" : undefined}
        onChange={() =>
          setFieldValue(formFields.requireKyc.name, !values.requireKyc)
        }
        toggleExpanded={toggleExpanded}
        labelTitle="Require KYC"
        checked={values.requireKyc}
        name="kyc"
        type="toggle"
        disabled={disabled}
      /> */}
      <InputField
        label={!edit ? "OTC" : undefined}
        onChange={() =>
          setFieldValue(
            formFields.allowTradingShares.name,
            !values.allowTradingShares
          )
        }
        labelTitle="Allow trading shares"
        type="toggle"
        checked={values.allowTradingShares}
        name="kyc"
        disabled={disabled}
      />
    </div>
  );
};

export default DealConfiguration;
