import React, { FC } from "react";
import "./StakingItem.scss";
import MainButton from "../../../components/MainButton/MainButton";
import Chip from "../../../components/Chip/Chip";
import { GREEN100, WHITE300 } from "../../../common/constants/layout.constants";

const StakingItem: FC<{
  deposit: number;
  award: number;
  total: number;
  currency: string;
}> = ({ deposit, award, total, currency }) => {
  return (
    <div className="staking-item">
      <div className="staking-item__deposit">
        {/* {image && <img src=EMPTY_STRING alt=EMPTY_STRING />} */}
        <p>{deposit}</p>
        <MainButton type="button" light onClick={() => console.log("click")}>
          Withdraw
        </MainButton>
      </div>
      <div className="staking-item__reward">
        <Chip text={award + " " + currency} backgroundColor={WHITE300} />
        <Chip text={total + " " + currency} backgroundColor={GREEN100} />
      </div>
    </div>
  );
};

export default StakingItem;
