import { useAnchorWallet } from "@solana/wallet-adapter-react";
import InputField from "../../../../components/InputFields/InputField";
import ClubApplicationFormConfig from "../ClubApplicationFormConfig";
import { useFormikContext } from "formik";
import { IClubApplicationFormFields } from "../../../../common/interfaces/form.interface";
import "./ClubApplicationForm.scss";
import LabelCard from "../../../../components/LabelCard/LabelCard";
import SocialNetworksFields from "../../../../components/SocialNetworksFields/SocialNetworksFields";

const ClubApplicationForm = () => {
  const { values, errors, setFieldValue } =
    useFormikContext<IClubApplicationFormFields>();
  const wallet = useAnchorWallet();

  const formFields = ClubApplicationFormConfig.formFields;

  return (
    <div className="club-applilcation-form">
      <div className="club-applilcation-form__basic-info">
        <h5>When applying, you need to fill in the following data:</h5>
        <LabelCard label="Wallet" description={wallet?.publicKey.toString()} />
        <InputField
          name={formFields.motivationLetter.name}
          type="text"
          labelTitle={formFields.motivationLetter.label}
          placeholder={formFields.motivationLetter.label}
        />
      </div>
      <h2 className="club-applilcation-form__subtitle">Social networks</h2>
      <SocialNetworksFields formFields={formFields} />
    </div>
  );
};

export default ClubApplicationForm;
