import { BackpackWalletAdapter } from "@solana/wallet-adapter-backpack";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { Coin98WalletAdapter } from "@solana/wallet-adapter-coin98";
import { ExodusWalletAdapter } from "@solana/wallet-adapter-exodus";
import { LedgerWalletAdapter } from "@solana/wallet-adapter-ledger";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { SlopeWalletAdapter } from "@solana/wallet-adapter-slope";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import { clusterApiUrl } from "@solana/web3.js";
import { useMemo } from "react";
import { Buffer } from "buffer";
require("@solana/wallet-adapter-react-ui/styles.css");
window.Buffer = Buffer;

interface IWalletWrapperProps {
  children?: React.ReactNode;
}

export const WalletWrapper: React.FC<IWalletWrapperProps> = (props) => {
  const network = WalletAdapterNetwork.Devnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      new SolflareWalletAdapter(),
      new PhantomWalletAdapter(),
      new BackpackWalletAdapter(),
      new LedgerWalletAdapter(),
      new SlopeWalletAdapter(),
      new Coin98WalletAdapter(),
      new ExodusWalletAdapter(),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider
        wallets={wallets}
        autoConnect
        onError={(e) => console.log(e)}
      >
        {props.children}
      </WalletProvider>
    </ConnectionProvider>
  );
};
