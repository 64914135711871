import React, { useCallback, useMemo, useState } from "react";
import green_dot from "../../../assets/greenDot.png";
import "./FundraiseHeader.scss";
import MainButton from "../../../components/MainButton/MainButton";
import { FC } from "react";
import { createOrEndFundraise } from "../../../program/methods/clubs";
import { clubStore } from "../../../state/clubStore";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { createNotification } from "../../../utilities/notifications";
import {
  EMPTY_STRING,
  MESSAGE_TYPE,
} from "../../../common/constants/common.constants";
import { FundraiseAction } from "../../../common/enums/fundraise.enum";
import editIcon from "../../../assets/edit.svg";
import CreateFundraiseModal from "../../../components/CreateFundraiseFormModal/CreateFundraiseModal";
import {
  findFundraiseInProgress,
  getAmountWithDecimalsForCurrency,
} from "../../../utilities/helpers";
import { IFundraiseConfig } from "../../../common/interfaces/club.interface";

const FundraiseHeader: FC = () => {
  const [editFundraise, toggleEditFundraise] = useState(false);
  const { clubBasicInfo, activeTreasury, fundraises } = clubStore();
  const wallet = useAnchorWallet();

  const findCustomAllocation = useCallback(
    (fundraise: IFundraiseConfig) => {
      const walletItem = fundraise.allocation?.custom?.find(
        (walletItem) => walletItem.authority === wallet?.publicKey.toString()
      );

      return (
        getAmountWithDecimalsForCurrency(
          activeTreasury?.currencyDecimals,
          walletItem?.amount
        ) ?? 0
      );
    },
    [fundraises, activeTreasury, wallet]
  );

  const optionalTextAmountForCustomAll = useMemo(() => {
    if (fundraises) {
      const fundraise = findFundraiseInProgress(fundraises);
      const customAll = fundraise && findCustomAllocation(fundraise);
      if (customAll && customAll !== 0) {
        return {
          amount: customAll,
          text: "Custom allocation: ",
          currency: activeTreasury?.currencySymbol,
        };
      }

      if (!customAll && fundraise?.allocation?.equal) {
        return {
          amount:
            getAmountWithDecimalsForCurrency(
              activeTreasury?.currencyDecimals,
              fundraise?.allocation?.equal
            ) ?? 0,
          text: "Limited allocation: ",
          currency: activeTreasury?.currencySymbol,
        };
      } else {
        return {
          amount: EMPTY_STRING,
          text: "Unlimited allocation",
          currency: EMPTY_STRING,
        };
      }
    }
  }, [fundraises, activeTreasury, wallet]);

  const handleEndFundraise = async () => {
    try {
      if (!clubBasicInfo || !activeTreasury || !wallet) {
        createNotification(MESSAGE_TYPE.ERROR, "Missing data");
        return;
      }
      await createOrEndFundraise(
        activeTreasury?.treasuryAddress,
        activeTreasury.treasuryDataAddress,
        clubBasicInfo.address,
        wallet,
        activeTreasury.fundraiseCount,
        clubBasicInfo.clubType,
        FundraiseAction.Finish,
        undefined,
        undefined,
        undefined,
        activeTreasury.financialTokenAccount,
        activeTreasury.denominatedCurrency
      );

      createNotification(MESSAGE_TYPE.SUCCESS, "Successfully ended fundraise");
    } catch (error) {
      createNotification(MESSAGE_TYPE.ERROR, "Failed ending fundraise");
    }
  };

  return (
    <div className="fundraise-header">
      {editFundraise && (
        <CreateFundraiseModal
          onClose={() => toggleEditFundraise(false)}
          edit={
            fundraises && findFundraiseInProgress(fundraises) ? true : false
          }
        />
      )}
      <div className="fundraise-header__info">
        <h2 className="fundraise-header__headline">
          Fundraise
          <img src={green_dot} alt="green-dot" />
        </h2>
        <div className="fundraise-header__options">
          <MainButton
            backgroundColor="#EFF9F6"
            color="var(--grey-500)"
            type="button"
            onClick={handleEndFundraise}
          >
            End fundraise
          </MainButton>
        </div>
      </div>
      <div className="fundraise-header__custom-allocation">
        <label>
          {" "}
          {optionalTextAmountForCustomAll?.text}
          <span>
            {optionalTextAmountForCustomAll?.amount}{" "}
            {optionalTextAmountForCustomAll?.currency}
          </span>
        </label>
        <img src={editIcon} onClick={() => toggleEditFundraise(true)} />
      </div>
    </div>
  );
};
export default FundraiseHeader;
