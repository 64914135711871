import React, { FC, ReactNode } from "react";
import "./MainButton.scss";
import { EMPTY_STRING } from "../../common/constants/common.constants";

const MainButton: FC<{
  onClick: (e?: any) => void;
  light?: boolean;
  squared?: boolean;
  disabled?: boolean;
  type: "button" | "submit" | "reset" | undefined;
  children?: ReactNode;
  green?: boolean;
  red?: boolean;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  padding?: string;
  disabledBold?: boolean;
}> = ({
  onClick,
  light,
  squared,
  children,
  type,
  disabled,
  borderColor,
  color,
  backgroundColor,
  padding,
  disabledBold,
}) => {
  return (
    <button
      className={`main-button ${light && "main-button--light"} ${
        disabledBold && "main-button--bold-disabled"
      }`}
      onClick={onClick}
      disabled={disabled}
      type={type}
      style={{
        borderRadius: squared ? "12px" : "100px",
        borderColor: borderColor ? borderColor : EMPTY_STRING,
        color: color ? color : EMPTY_STRING,
        backgroundColor: backgroundColor ? backgroundColor : EMPTY_STRING,
        padding: padding ? padding : EMPTY_STRING,
      }}
    >
      {children}
    </button>
  );
};

export default MainButton;
