import { FC, useEffect, useState } from "react";
import "./TreasuryDropdown.scss";
import arrowdown from "../../assets/keyboard_arrow_down.png";
import TreasuryDetails from "../TreasuryDetails/TreasuryDetails";
import MainButton from "../MainButton/MainButton";
import HoverIcon from "../HoverIcon/HoverIcon";
import TreasuryDropdownCard from "../TreasuryDropdownCard/TreasuryDropdownCard";
import TreasuryModal from "../CreateTreasuryModal/TreasuryModal";
import { clubStore } from "../../state/clubStore";
import { ITreasuryData } from "../../common/interfaces/club.interface";
import { useLazyQuery } from "@apollo/client";
import {
  ACTIVE_TREASURY_INFO,
  mapActiveTreasuryInfo,
  GET_ALL_TREASURIES_FOR_CLUB,
  GET_FUNDRAISES_FOR_TREASURY,
  GET_MEMBER_ACTIVE_TREASURY_INFO,
  GET_TREASURY_MEMBERS,
} from "../../api/club.api";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import {
  findTreasuryAddressByIndex,
  getTreasuryByAddressOrReturnDefault,
} from "../../program/program-helpers";
import { useParams, useNavigate, useLocation } from "react-router";
import { OVERVIEW, PROPOSALS } from "../../common/constants/routes.constants";
import { TREASURY_RELATED_PAGES } from "../../common/constants/layout.constants";

import { checkIfUserHasClubPermissionForAction } from "../../utilities/helpers";
import { ClubAction } from "../../common/enums/clubs.enum";
import { PublicKey } from "@metaplex-foundation/js";

const TreasuryDropdown: FC = () => {
  const [dropdown, toggleDropdown] = useState(false);
  const [isTreasuryModalActive, toggleTreasuryModal] = useState(false);
  const {
    activeTreasury,
    clubBasicInfo,
    setActiveTreasury,
    setFundraises,
    setMemberTreasuryInfo,
    setVault,
    resetStore,
    setTreasuryMembers,
    memberData,
    setAllTreasuryDataAddresses,
  } = clubStore();

  const wallet = useAnchorWallet();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [getAllTreasuriesForClub, getAllTreasuriesForClubInfo] = useLazyQuery(
    GET_ALL_TREASURIES_FOR_CLUB,
    {
      onCompleted: (data) => {
        setAllTreasuryDataAddresses(
          data.getTreasuriesForClub.map(
            (item: ITreasuryData) => new PublicKey(item.treasuryDataAddress)
          )
        );
      },
    }
  );

  const [getActiveTreasuryInfo, activeTreasuryInfo] = useLazyQuery(
    ACTIVE_TREASURY_INFO,
    {
      onCompleted: (data) => {
        const { activeTreasury, vault } = mapActiveTreasuryInfo(data);
        setActiveTreasury(activeTreasury);
        setVault(vault);
      },
    }
  );

  const [getActiveFundraises, activeFundraisesInfo] = useLazyQuery(
    GET_FUNDRAISES_FOR_TREASURY,
    {
      onCompleted: (data) => {
        setFundraises(data.getFundraisesForTreasury);
      },
    }
  );

  const [getTreasuryMembers, treasuryMembersInfo] = useLazyQuery(
    GET_TREASURY_MEMBERS,
    {
      onCompleted: (data) => {
        setTreasuryMembers(data.getAllFinancialRecordsForTreasury);
      },
    }
  );

  const [getMemberTreasury, memberTreasuryInfo] = useLazyQuery(
    GET_MEMBER_ACTIVE_TREASURY_INFO,
    {
      onCompleted: (data) => {
        setMemberTreasuryInfo(data.getSingleFinancialRecord);
      },
    }
  );

  useEffect(() => {
    getMemberTreasuryInfo();
    if (!wallet) {
      setMemberTreasuryInfo(undefined);
    }
  }, [params.dealAddress, wallet?.publicKey, activeTreasury, clubBasicInfo]);

  useEffect(() => {
    getInitialTreasurySetup();
    return () => {
      resetStore();
    };
  }, [params.dealAddress]);

  //TODO@milica: check if this useEffect is necessary
  useEffect(() => {
    if (activeTreasury) {
      getActiveFundraises({
        variables: {
          treasuryAddress: activeTreasury.treasuryAddress,
        },
      });

      getTreasuryMembers({
        variables: {
          treasuryAddress: activeTreasury.treasuryAddress,
        },
      });
    }
  }, [activeTreasury]);

  const getMemberTreasuryInfo = async () => {
    try {
      if (wallet && params.dealAddress && clubBasicInfo && activeTreasury) {
        getMemberTreasury({
          variables: {
            treasuryAddress: findTreasuryAddressByIndex(
              clubBasicInfo?.address,
              activeTreasury?.treasuryIndex
            ).toString(),
            memberAddress: wallet.publicKey.toString(),
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialTreasurySetup = async () => {
    try {
      const clubAddress = params.dealAddress;
      if (!clubAddress) return;

      const treasuryAddress = await getTreasuryByAddressOrReturnDefault(
        clubAddress,
        params.treasuryAddress
      );
      getActiveTreasuryInfo({
        variables: {
          treasuryAddress,
        },
      });
      getAllTreasuriesForClub({
        variables: {
          clubAddress,
        },
      });
      getActiveFundraises({
        variables: {
          treasuryAddress,
        },
      });

      getTreasuryMembers({
        variables: {
          treasuryAddress,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCardChange = (treasuryCard: ITreasuryData) => {
    setActiveTreasury(treasuryCard);
    toggleDropdown(false);
    const basePath = TREASURY_RELATED_PAGES.find((item) =>
      location.pathname.includes(item)
    );
    navigate(
      `${basePath}/${params.dealAddress}/${treasuryCard.treasuryAddress}`,
      {
        replace: true,
      }
    );
  };

  return (
    <>
      {isTreasuryModalActive && (
        <TreasuryModal closeModal={() => toggleTreasuryModal(false)} />
      )}
      <div className="treasury-dropdown">
        <div
          className="multiple-tresuries-dropdown"
          onClick={() => toggleDropdown(!dropdown)}
        >
          {activeTreasury && <TreasuryDetails treasuryData={activeTreasury} />}
          <div className="multiple-tresuries-dropdown__options">
            <MainButton
              onClick={(e) => {
                e.stopPropagation();
                toggleTreasuryModal(true);
              }}
              type="button"
              squared
              light
              disabled={memberData?.memberPubkey !== clubBasicInfo?.authority}
            >
              Add New Treasury
            </MainButton>
            <HoverIcon
              src={arrowdown}
              alt="Dropdown arrow"
              onClick={() => toggleDropdown(!dropdown)}
            />
          </div>
          {dropdown && (
            <>
              <div className="multiple-treasuries-dropdown-list">
                {getAllTreasuriesForClubInfo.data.getTreasuriesForClub?.map(
                  (t: ITreasuryData, index: number) => (
                    <TreasuryDropdownCard
                      treasury={t}
                      key={index}
                      onChange={handleCardChange}
                    />
                  )
                )}
              </div>
              <div
                className="backdrop"
                onClick={() => toggleDropdown(false)}
              ></div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TreasuryDropdown;
