import CardWrapper from "../../../components/CardWrapper/CardWrapper";
import "./TreasuryInfo.scss";
import { clubStore } from "../../../state/clubStore";
import MainButton from "../../../components/MainButton/MainButton";
import {
  checkIfUserHasTreasuryPermissionForAction,
  getAmountWithDecimalsForCurrency,
} from "../../../utilities/helpers";
import React, { FC, useState } from "react";
import CreateFundraiseModal from "../../../components/CreateFundraiseFormModal/CreateFundraiseModal";
import { TreasuryAction } from "../../../common/enums/clubs.enum";

const TreasuryInfo: FC = () => {
  const [createFundraise, toggleCreateFundraise] = useState(false);
  const { activeTreasury, memberTreasuryInfo } = clubStore();

  const depositArray = memberTreasuryInfo?.depositRecords;

  return (
    <CardWrapper backgroundColor="white">
      <div className="treasury-info">
        {createFundraise && (
          <CreateFundraiseModal
            onClose={() => toggleCreateFundraise(false)}
            edit={false}
          />
        )}
        <div className="treasury-info__header">
          <label className="treasury-info__balance">Balance</label>
          {!activeTreasury?.hasActiveFundraise && (
            <MainButton
              onClick={() => toggleCreateFundraise(true)}
              type="button"
              light
              squared
              disabled={
                !checkIfUserHasTreasuryPermissionForAction(
                  memberTreasuryInfo,
                  activeTreasury?.treasuryRoleConfig,
                  TreasuryAction.Fundraise
                )
              }
            >
              Create fundraise
            </MainButton>
          )}
        </div>

        <div className="treasury-info__description">
          <label className="treasury-info__balance-amount">
            <span>{activeTreasury?.currencySymbol}</span>{" "}
            {activeTreasury
              ? getAmountWithDecimalsForCurrency(
                  activeTreasury?.currencyDecimals,
                  activeTreasury?.balance
                )
              : 0}
          </label>
          <div className="treasury-info__actions">
            <label className="treasury-info__deposit">
              Your deposit
              <span className="treasury-info__deposit-amount">
                {depositArray && depositArray.length > 0
                  ? getAmountWithDecimalsForCurrency(
                      activeTreasury?.currencyDecimals,
                      depositArray[depositArray.length - 1].accumulatedAmount
                    )
                  : 0}
                <span className="treasury-info__currency">
                  {activeTreasury?.currencySymbol}
                </span>
              </span>
            </label>
            <label className="treasury-info__ownership">
              Ownership{" "}
              <span className="treasury-info__ownership-amount">
                {memberTreasuryInfo
                  ? memberTreasuryInfo?.ownership.toFixed(2)
                  : 0}
                %
              </span>
            </label>
            <label className="treasury-info__voting-power">
              Voting power
              <span className="treasury-info__voting-power-amount">
                {memberTreasuryInfo
                  ? memberTreasuryInfo?.votingPower.toFixed(2)
                  : 0}
              </span>
            </label>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

export default TreasuryInfo;
