import React, { useCallback, useEffect, useMemo, useState } from "react";
import dropdownIcon from "../../../../assets/arrow_down.svg";
import {
  ChangeConfigurationTypeEnum,
  ChangeGovernanceTypeEnum,
} from "../../../../common/enums/proposal.enum";
import { governanceTypeOptions } from "../../../../common/constants/proposal.constants";
import proposalFormConfig from "../../proposalFormConfig";
import SelectFieldWithDescription from "../../../SelectFieldWithDescription/SelectFieldWithDescription";
import { useFormikContext } from "formik";
import { IProposalFormFields } from "../../../../common/interfaces/form.interface";
import ChangeGovernanceConfiguration from "../../ChangeGovernanceConfiguration/ChangeGovernanceConfiguration";
import TradeApprovalConfiguration from "../../TradeApprovalConfiguration/TradeApprovalConfiguration";
import { clubStore } from "../../../../state/clubStore";
import { parseSecondsToDays } from "../../../../utilities/helpers";

export const ChangeGovernanceType = () => {
  const [activeDropdownItem, setActiveDropdownItem] = useState(
    ChangeGovernanceTypeEnum.DefaultConfiguration
  );

  const { values, setFieldValue } = useFormikContext<IProposalFormFields>();
  const { activeTreasury } = clubStore();

  const setGovernanceBasedOnSelectedGovType = useCallback(
    (changeGovType: string) => {
      switch (changeGovType) {
        case ChangeGovernanceTypeEnum.ChangeClubConfiguration:
          setFieldValue(
            proposalFormConfig.formFields.maxVotingTime.name,
            parseSecondsToDays(
              activeTreasury?.changeConfigGovernance.maxVotingTime
            )?.toFixed(0)
          );
          setFieldValue(
            proposalFormConfig.formFields.approvalVotePercentage.name,
            activeTreasury?.changeConfigGovernance.voteThreshold
          );

          setFieldValue(
            proposalFormConfig.formFields.selectedGovernance.name,
            activeTreasury?.changeConfigGovernance
          );
          break;
        case ChangeGovernanceTypeEnum.DefaultConfiguration:
          setFieldValue(
            proposalFormConfig.formFields.maxVotingTime.name,
            parseSecondsToDays(
              activeTreasury?.treasuryGovernance.maxVotingTime
            )?.toFixed(0)
          );
          setFieldValue(
            proposalFormConfig.formFields.approvalVotePercentage.name,
            activeTreasury?.treasuryGovernance.voteThreshold
          );
          setFieldValue(
            proposalFormConfig.formFields.selectedGovernance.name,
            activeTreasury?.treasuryGovernance
          );
          break;
        case ChangeGovernanceTypeEnum.TransferConfiguration:
          setFieldValue(
            proposalFormConfig.formFields.maxVotingTime.name,
            parseSecondsToDays(
              activeTreasury?.transferGovernance.maxVotingTime
            )?.toFixed(0)
          );
          setFieldValue(
            proposalFormConfig.formFields.approvalVotePercentage.name,
            activeTreasury?.transferGovernance.voteThreshold
          );
          setFieldValue(
            proposalFormConfig.formFields.selectedGovernance.name,
            activeTreasury?.transferGovernance
          );
          break;
        case ChangeGovernanceTypeEnum.WithdrawalConfiguration:
          setFieldValue(
            proposalFormConfig.formFields.maxVotingTime.name,
            parseSecondsToDays(
              activeTreasury?.withdrawalGovernance.maxVotingTime
            )?.toFixed(0)
          );
          setFieldValue(
            proposalFormConfig.formFields.approvalVotePercentage.name,
            activeTreasury?.withdrawalGovernance.voteThreshold
          );
          setFieldValue(
            proposalFormConfig.formFields.selectedGovernance.name,
            activeTreasury?.withdrawalGovernance
          );
          break;
      }
    },
    [activeTreasury, values.changeGovernanceType]
  );

  return (
    <div>
      <div>
        <SelectFieldWithDescription
          chosenOption={
            governanceTypeOptions.find(
              (item) => item.value === values.changeGovernanceType
            ) ?? governanceTypeOptions[0]
          }
          onSelect={(value) => {
            setFieldValue(
              proposalFormConfig.formFields.changeGovernanceType.name,
              value
            );
            setGovernanceBasedOnSelectedGovType(value.toString());
          }}
          options={governanceTypeOptions}
        />
        {values.changeGovernanceType !==
        ChangeGovernanceTypeEnum.TradeApprovalConfiguration ? (
          <ChangeGovernanceConfiguration />
        ) : (
          <TradeApprovalConfiguration />
        )}
      </div>
    </div>
  );
};

export default ChangeGovernanceType;
