import { Fragment, useState } from "react";
import "./OverviewMembersCard.scss";
import members from "../../../assets/membersoverview.png";
import MainButton from "../../../components/MainButton/MainButton";
import { PROFILE_IMAGES } from "../../../dummy-data/members";
import ManageTreasuryRolesModal from "../../Treasury/ManageTreasuryRolesModal/ManageTreasuryRolesModal";
import { clubStore } from "../../../state/clubStore";
import { checkIfUserHasClubPermissionForAction } from "../../../utilities/helpers";
import { ClubAction } from "../../../common/enums/clubs.enum";

const OverviewMembersCard = () => {
  const [manageTreasuryRoles, toggleManageTreasuryRoles] = useState(false);

  const { treasuryMembers, memberData, clubBasicInfo } = clubStore();
  const getImageClass = (index: number) => {
    switch (index) {
      case 0:
        return "member-circular-list__small--left";
      case 1:
        return "member-circular-list__medium--left";
      case 2:
        return "member-circular-list__large--left";
      case 3:
        return "member-circular-list__main";
      case 4:
        return "member-circular-list__large--right";
      case 5:
        return "member-circular-list__medium--right";
      case 6:
        return "member-circular-list__small--right";
    }
  };
  return (
    <Fragment>
      {manageTreasuryRoles && (
        <ManageTreasuryRolesModal
          closeModal={() => toggleManageTreasuryRoles(false)}
        />
      )}
      <div className="overview-members">
        <div className="overview-members__header">
          <img src={members} alt="Members" />
          <h3>Members</h3>
        </div>
        <div className="member-circular-list">
          {PROFILE_IMAGES.map((icon, index) => (
            <img
              src={icon}
              alt="Profile"
              className={getImageClass(index)}
              key={index}
            />
          ))}
        </div>
        <div className="overview-members__stats">
          <div>
            <h6>Total Members</h6>
            <p>{treasuryMembers ? treasuryMembers?.length : 0}</p>
          </div>
        </div>
        <div>
          <MainButton
            type="button"
            light
            onClick={() => {
              toggleManageTreasuryRoles(true);
            }}
            disabled={
              !checkIfUserHasClubPermissionForAction(
                memberData,
                clubBasicInfo?.roleConfig,
                ClubAction.AllowMember
              )
            }
          >
            Manage Treasury Roles
          </MainButton>
        </div>
      </div>
    </Fragment>
  );
};

export default OverviewMembersCard;
