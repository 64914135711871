import React from "react";
import "./CardWrapper.scss";

interface ICardProps {
  children?: React.ReactNode;
  backgroundColor?: string;
  padding?: string;
  width?: string;
}
const CardWrapper: React.FC<ICardProps> = (props) => {
  return (
    <div
      className="card-wrapper"
      style={{
        background: `${props.backgroundColor}`,
        padding: `${props.padding}`,
        width: `${props.width}`,
      }}
    >
      {props.children}
    </div>
  );
};
export default CardWrapper;
