import React, { FC, Fragment, useMemo } from "react";
import { useField, useFormikContext } from "formik";
import "./InputField.scss";
import "react-toggle/style.css";
import MultiToggle from "../MultiToggle/MultiToggle";
import {
  MaterialUIFormControl,
  MaterialUIInputLabel,
  MaterialUIMenuItem,
  MaterialUISelect,
  MaterialUISwitch,
  MaterialUITextField,
  MaterialUIDatePicker,
} from "../../common/constants/mui.constants";
import {
  IInputTextProp,
  IOption,
} from "../../common/interfaces/common.interface";
import { Autocomplete } from "@mui/material";
import CurrencyChip from "../CurrencyChip/CurrencyChip";

type InputFieldType =
  | "text"
  | "number"
  | "toggle"
  | "select"
  | "multiple-text-input"
  | "date-picker";

const InputField: FC<{
  type: InputFieldType;
  name: string;
  label?: string;
  labelTitle?: string;
  placeholder?: string;
  icon?: string;
  value?: string;
  checked?: boolean;
  onChange?: (e?: any) => void;
  toggleExpanded?: {
    label: string;
    onClick: () => void;
    isSelected: boolean;
  }[];
  options?: IOption[];
  disabled?: boolean;
  switchName?: string;
  badge?: string;
  textProp?: IInputTextProp;
  subtext?: string;
  multiple?: boolean;
}> = ({
  type,
  name,
  placeholder,
  label,
  labelTitle,
  icon,
  value,
  checked,
  onChange,
  toggleExpanded,
  options,
  disabled,
  switchName,
  badge,
  textProp,
  subtext,
  multiple,
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue, errors, values } = useFormikContext();
  const fieldError =
    errors[field.name as keyof typeof values] && errors && meta.touched;

  const getInputField = useMemo(() => {
    switch (type) {
      case "text":
      case "number": {
        return (
          <div className="input-field__wrapper">
            <MaterialUITextField
              {...field}
              id="standard-textarea"
              label={labelTitle}
              placeholder={placeholder}
              type={type}
              variant="filled"
              disabled={disabled}
              value={field.value}
              sx={
                fieldError && {
                  "& .MuiInputBase-root": {
                    borderColor: "red",
                  },
                }
              }
              onInput={onChange}
            />
            {switchName && (
              <div className="input-field__toggle-text">
                <MaterialUISwitch
                  checked={checked}
                  onChange={() => setFieldValue(switchName, !checked)}
                />
              </div>
            )}
          </div>
        );
      }
      case "toggle": {
        return (
          <div className="input-field__toggle">
            <div
              className={`input-field__toggle-content ${
                disabled && "input-field__toggle-content--disabled"
              }`}
            >
              <div className="input-field__toggle-heading">
                <p
                  className={`input-field__toggle-label ${
                    disabled && "input-field__toggle-label--disabled"
                  }`}
                >
                  {labelTitle}
                </p>
                <MaterialUISwitch
                  {...field}
                  checked={checked}
                  disabled={disabled}
                  onChange={(e) => {
                    field.onChange(e);
                    onChange && onChange();
                  }}
                />
              </div>
              <div className="input-field__toggle-expanded">
                {checked &&
                  toggleExpanded &&
                  toggleExpanded.map((tg, index) => {
                    return (
                      <MultiToggle
                        isSelected={tg.isSelected}
                        label={tg.label}
                        onClick={tg.onClick}
                        first={index === 0}
                        last={index === toggleExpanded.length - 1}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        );
      }
      case "select": {
        return (
          <MaterialUIFormControl fullWidth>
            <MaterialUIInputLabel id="input-select" variant="filled">
              {labelTitle}
            </MaterialUIInputLabel>
            <MaterialUISelect
              labelId="input-select"
              id="input-select"
              multiple={multiple}
              value={
                multiple
                  ? Array.isArray(field.value)
                    ? field.value
                    : []
                  : options
                  ? options.find((option) => option.value === field.value)
                      ?.value
                  : undefined
              }
              disabled={disabled}
              label="select"
              onChange={(e) => setFieldValue(field.name, e.target.value)}
              variant="filled"
              sx={
                fieldError && {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid red",
                  },
                }
              }
            >
              {options?.map((item) => (
                <MaterialUIMenuItem value={item.value} key={item.value}>
                  {item.label}
                </MaterialUIMenuItem>
              ))}
            </MaterialUISelect>
          </MaterialUIFormControl>
        );
      }
      case "multiple-text-input":
        return (
          <Fragment>
            <div className="input-field__wrapper">
              <Autocomplete
                multiple
                id="autocomplete"
                options={[]}
                freeSolo
                clearIcon={false}
                disabled={disabled}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <p> {option}, </p>
                  ))
                }
                onChange={(event, value) => setFieldValue(field.name, value)}
                value={field.value}
                renderInput={(params) => {
                  return (
                    <MaterialUITextField
                      {...params}
                      id="standard-textarea"
                      label={labelTitle}
                      error={meta.touched && !!meta.error}
                      placeholder={placeholder}
                      multiline
                      variant="filled"
                      disabled={disabled}
                    />
                  );
                }}
              />
              {switchName && (
                <div className="input-field__toggle-text">
                  <MaterialUISwitch
                    checked={checked}
                    onChange={() => {
                      setFieldValue(field.name, []);
                      setFieldValue(switchName, !checked);
                    }}
                  />
                </div>
              )}
            </div>
            {meta.touched && !!meta.error && (
              <p className="input-field__error">{meta.error}</p>
            )}
          </Fragment>
        );
      case "date-picker":
        return (
          <Fragment>
            <div className="input-field__wrapper">
              <MaterialUIDatePicker
                label={labelTitle}
                disabled={disabled}
                value={field.value}
                disablePast
                onChange={(value: any) => {
                  setFieldValue(field.name, value.$d);
                }}
              />
            </div>
          </Fragment>
        );
    }
  }, [
    type,
    name,
    field,
    meta,
    labelTitle,
    label,
    toggleExpanded,
    checked,
    setFieldValue,
  ]);

  return (
    <>
      <div className="input-field">
        {label && <p className="input-field__label">{label}</p>}
        {getInputField}{" "}
        {fieldError && (
          <p className="input-field__error">
            {errors[field.name as keyof typeof values]}
          </p>
        )}
        {badge && (
          <div className="input-field__badge">
            <CurrencyChip currency={badge} />
          </div>
        )}
        {textProp && (
          <div
            className="input-field__badge"
            style={{ color: textProp.color ? `${textProp.color}` : "black" }}
          >
            {textProp.text}
          </div>
        )}
      </div>
      {subtext && <p className="input-field__subtext">{subtext}</p>}
    </>
  );
};

export default InputField;
