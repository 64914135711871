import React from "react";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import solana from "../../../assets/solana-profile.png";
import ivc from "../../../assets/ivc-profile.png";
import ww from "../../../assets/ww-profile.png";
import Chip from "../../../components/Chip/Chip";
import DetailsCard from "../../../components/DetailsCard/DetailsCard";
import BasicDetailsCard from "../../../components/BasicDetailsCard/BasicDetailsCard";
import { WHITE300 } from "../../../common/constants/layout.constants";

const InvestorsCard = () => {
  const INVESTORS = [
    {
      image: solana,
      title: "Solana",
      info: EMPTY_STRING,
    },
    {
      image: ww,
      title: "WW Ventures",
      info: EMPTY_STRING,
    },
    {
      image: ivc,
      title: "IVC",
      info: EMPTY_STRING,
    },
  ];
  return (
    <DetailsCard title="Investors">
      <div className="intro-deck__details-card">
        {INVESTORS.map((i, index) => (
          <BasicDetailsCard
            image={i.image}
            alt="Profile"
            title={i.title}
            id={i.info}
            key={index}
          />
        ))}
      </div>
      <div className="intro-deck__details-card-chip">
        <Chip text={"+2 more investors"} backgroundColor={WHITE300} />
      </div>
    </DetailsCard>
  );
};

export default InvestorsCard;
