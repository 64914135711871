import React, { memo, useCallback, useEffect, useMemo } from "react";
import Modal from "../Modal/Modal";
import { Form, Formik, useFormikContext } from "formik";
import FormActions from "../FormActions/FormActions";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import userAccountSettingsFormConfig from "./userAccountSettingsFormConfig";
import UserAccountSettingsForm from "./UserAccountSettingsForm";
import { IUserAccountSettingsFormFields } from "../../common/interfaces/form.interface";
import {
  DefaultExplorer,
  SocialNetworkType,
} from "../../common/enums/clubs.enum";
import {
  IMemberInfo,
  ISocialNetwork,
} from "../../common/interfaces/club.interface";
import { saveMemberInfo } from "../../api/club.api";
import {
  getSignedMessage,
  updateSocialNetworkApiArray,
} from "../../utilities/helpers";
import { createNotification } from "../../utilities/notifications";
import { MessageType } from "graphql-ws";
import { validateUserAccountSettings } from "./userAccountSettingsFormValidate";
import {
  MESSAGE_TYPE,
  allSocials,
} from "../../common/constants/common.constants";
import { apolloClient } from "../../api/config.api";
import { GET_MY_MEMBER_INFO } from "../../api/dashboard.api";

const UserAccountSettingsModal: React.FC<{
  onClose: () => void;
  member: IMemberInfo | undefined;
}> = ({ onClose, member }) => {
  const { signMessage } = useWallet();
  const wallet = useAnchorWallet();
  const formFields = userAccountSettingsFormConfig.formFields;

  const initialValues: IUserAccountSettingsFormFields = useMemo(() => {
    if (member) {
      return {
        wallet: member.userAddress,
        name: member?.name,
        imageUrl: member.imageUrl,
        discord:
          member?.socialNetworks.find(
            (item) => item.type === SocialNetworkType.Discord
          )?.value ?? formFields.discord.value,
        linkedin:
          member?.socialNetworks.find(
            (item) => item.type === SocialNetworkType.LinkedIn
          )?.value ?? formFields.linkedin.value,
        telegram:
          member?.socialNetworks.find(
            (item) => item.type === SocialNetworkType.Telegram
          )?.value ?? formFields.telegram.value,
        twitter:
          member?.socialNetworks.find(
            (item) => item.type === SocialNetworkType.Twitter
          )?.value ?? formFields.twitter.value,
        mail:
          member?.socialNetworks.find(
            (item) => item.type === SocialNetworkType.Mail
          )?.value ?? formFields.mail.value,
      };
    } else
      return {
        wallet: formFields.wallet.value,
        name: formFields.name.value,
        imageUrl: formFields.imageUrl.value,
        telegram: formFields.telegram.value,
        mail: formFields.mail.value,
        discord: formFields.discord.value,
        twitter: formFields.twitter.value,
        linkedin: formFields.linkedin.value,
      };
  }, [member]);

  const compareWithInitialValues = (values: IUserAccountSettingsFormFields) => {
    const propertiesToCheck: (keyof IUserAccountSettingsFormFields)[] = [
      "discord",
      "mail",
      "imageUrl",
      "linkedin",
      "name",
      "telegram",
      "twitter",
    ];

    for (const property of propertiesToCheck) {
      if (initialValues[property] !== values[property]) {
        return true;
      }
    }

    return false;
  };

  const handleSubmitForm = async (values: IUserAccountSettingsFormFields) => {
    try {
      const networks: ISocialNetwork[] = [];
      if (!wallet) {
        return;
      }

      allSocials.forEach((item) => {
        console.log(
          values[item.toLowerCase() as keyof IUserAccountSettingsFormFields]
        );
        updateSocialNetworkApiArray(
          values[item.toLowerCase() as keyof IUserAccountSettingsFormFields],
          item,
          networks
        );
      });

      const memberInfo: IMemberInfo = {
        userAddress: wallet?.publicKey.toString(),
        explorer: member?.explorer ?? DefaultExplorer.SolanaExpolorer,
        imageUrl: values.imageUrl,
        name: values.name,
        socialNetworks: networks,
      };

      if (compareWithInitialValues(values)) {
        if (wallet && signMessage) {
          const signature = await getSignedMessage("s", signMessage);
          /*  Real message will be added later */
          await saveMemberInfo(memberInfo, signature);
          apolloClient.refetchQueries({ include: [GET_MY_MEMBER_INFO] });
          createNotification(MESSAGE_TYPE.SUCCESS, "Successfully updated!");
          onClose();
        }
      } else {
        createNotification(MessageType.Error, "Nothing changed");
      }
    } catch (error) {
      console.log(error);
      createNotification(MessageType.Error, "Failed to update information");
    }
  };

  return (
    <Modal title="User Account" closeModal={onClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validate={(values) => validateUserAccountSettings(values, {})}
        validateOnBlur
      >
        <Form id={userAccountSettingsFormConfig.formId}>
          <UserAccountSettingsForm member={member} />
          <FormActions
            buttonText="Save changes"
            buttonAction={() => handleSubmitForm}
            cancelAction={onClose}
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default UserAccountSettingsModal;
