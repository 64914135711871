import { FC } from "react";
import { useFormikContext } from "formik";
import {
  IBasicClubDataForm,
  IRoleConfig,
} from "../../common/interfaces/form.interface";
import "./RolesConfiguration.scss";
import "./RolesConfiguration.scss";
import RequiredField from "../RequiredField/RequiredField";
import InputField from "../InputFields/InputField";
import React from "react";

const RolesConfiguration: FC<{
  edit?: boolean;
  disabled?: boolean;
  rolesArray: string;
  rolesLabel: string;
}> = ({ edit, disabled, rolesArray, rolesLabel }) => {
  const { values } = useFormikContext<IBasicClubDataForm>();

  const valuesRoles = values[
    rolesArray as keyof typeof values
  ] as IRoleConfig[];

  const requiredRoles = valuesRoles.filter((item) => item.required);
  const notRequiredRoles = valuesRoles.filter((item) => !item.required);

  return (
    <div className="roles-config" style={edit ? { marginTop: 0 } : undefined}>
      <div className="roles-config__section">
        {!edit && <p className="roles-config__title">{rolesLabel}</p>}
        {requiredRoles.map((item) => (
          <RequiredField label={item.name} disabled={disabled} />
        ))}
        {notRequiredRoles.map((item) => (
          <InputField
            type="toggle"
            name={`${rolesArray}.${valuesRoles.findIndex(
              (role) => role.name === item.name
            )}.chosen`}
            labelTitle={item.name}
            checked={item.chosen}
            disabled={disabled}
          />
        ))}
      </div>
      <div className="roles-config__section">
        <p className="roles-config__title">Roles voting power</p>
        {valuesRoles
          .filter((item) => item.chosen)
          .map((item) => (
            <div className="roles-config__voting-power">
              <div className="roles-config__role">
                <InputField
                  disabled
                  name={`${rolesArray}.${valuesRoles.findIndex(
                    (role) => role.name === item.name
                  )}.name`}
                  type="text"
                  value={item.name}
                  labelTitle={item.name}
                />
              </div>
              <div className="roles-config__power">
                <InputField
                  type="text"
                  name={`${rolesArray}.${valuesRoles.findIndex(
                    (role) => role.name === item.name
                  )}.votingPower`}
                  labelTitle={"Voting power"}
                  disabled={disabled}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default RolesConfiguration;
