import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";
import proposalFormConfig from "../proposalFormConfig";
import { IProposalFormFields } from "../../../common/interfaces/form.interface";
import InputField from "../../InputFields/InputField";
import { clubStore } from "../../../state/clubStore";
import "./TradeApprovalConfiguration.scss";
import SelectFieldWithDescription from "../../SelectFieldWithDescription/SelectFieldWithDescription";
import { ISelectOption } from "../../../common/interfaces/common.interface";
import { ADD_NEW_SELL_PERMISION } from "../../../common/constants/proposal.constants";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import { getAmountWithDecimalsForCurrency } from "../../../utilities/helpers";

const TradeApprovalConfiguration = () => {
  const { values, setFieldValue } = useFormikContext<IProposalFormFields>();
  const { activeTreasury } = clubStore();

  const tradeOptions = useMemo(() => {
    const sellPermissionArray: ISelectOption[] = [];
    activeTreasury?.sellPermission.forEach((item) => {
      sellPermissionArray.push({
        description: "",
        title: `From: ${getAmountWithDecimalsForCurrency(
          activeTreasury?.currencyDecimals,
          item.from
        )} To:${getAmountWithDecimalsForCurrency(
          activeTreasury?.currencyDecimals,
          item.to
        )}, Percentage: ${item.quorumMinimum}%`,
        value: item.governance.address,
      });
    });

    sellPermissionArray.push({
      description: "",
      title: "Add new",
      value: ADD_NEW_SELL_PERMISION,
    });
    return sellPermissionArray;
  }, [activeTreasury]);

  const getChosenOption = useMemo(() => {
    if (values.sellPermision) {
      return tradeOptions.find(
        (item) => item.value === values.sellPermision?.governance.address
      );
    }
    return tradeOptions.find((item) => item.value === ADD_NEW_SELL_PERMISION);
  }, [values.sellPermision, tradeOptions]);

  const disableInput = useMemo(() => {
    return values.sellPermision != undefined;
  }, [values.sellPermision]);

  return (
    <div className="trade-approval-config">
      <SelectFieldWithDescription
        chosenOption={getChosenOption ?? tradeOptions[0]}
        onSelect={(value) => {
          if (value !== ADD_NEW_SELL_PERMISION) {
            setFieldValue(
              proposalFormConfig.formFields.sellPermision.name,
              activeTreasury?.sellPermission.find(
                (item) => item.governance.address === value
              )
            );

            setFieldValue(
              proposalFormConfig.formFields.from.name,
              getAmountWithDecimalsForCurrency(
                activeTreasury?.currencyDecimals,
                activeTreasury?.sellPermission.find(
                  (item) => item.governance.address === value
                )?.from
              )
            );
            setFieldValue(
              proposalFormConfig.formFields.to.name,
              getAmountWithDecimalsForCurrency(
                activeTreasury?.currencyDecimals,
                activeTreasury?.sellPermission.find(
                  (item) => item.governance.address === value
                )?.to
              )
            );

            setFieldValue(
              proposalFormConfig.formFields.quorum.name,
              activeTreasury?.sellPermission.find(
                (item) => item.governance.address === value
              )?.quorumMinimum ?? 0
            );
          } else {
            setFieldValue(
              proposalFormConfig.formFields.sellPermision.name,
              undefined
            );

            setFieldValue(
              proposalFormConfig.formFields.from.name,
              EMPTY_STRING
            );
            setFieldValue(proposalFormConfig.formFields.to.name, EMPTY_STRING);

            setFieldValue(
              proposalFormConfig.formFields.quorum.name,
              EMPTY_STRING
            );
          }
        }}
        options={tradeOptions}
      />

      <div className="trade-approval-config__fields">
        <InputField
          name={proposalFormConfig.formFields.from.name}
          type="text"
          labelTitle="From"
          disabled={disableInput}
        />
        <InputField
          name={proposalFormConfig.formFields.to.name}
          type="number"
          labelTitle="To"
          disabled={disableInput}
        />
        <InputField
          name={proposalFormConfig.formFields.quorum.name}
          type="number"
          labelTitle="Quorum"
        />
      </div>
    </div>
  );
};

export default TradeApprovalConfiguration;
