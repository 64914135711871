import { FC, useCallback, useEffect } from "react";
import "./HomeSummary.scss";
import DetailsCard from "../../../components/DetailsCard/DetailsCard";
import BasicDetailsCard from "../../../components/BasicDetailsCard/BasicDetailsCard";
import {
  EMPTY_STRING,
  MESSAGE_TYPE,
} from "../../../common/constants/common.constants";
import avatar from "../../../assets/unique_avatar.png";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { useLazyQuery, useSubscription } from "@apollo/client";
import { GET_DASHBOARD_CLAIMS } from "../../../api/dashboard.api";
import { useDashboardStore } from "../../../state/dashboardStore";
import useTransactionStore from "../../../state/transactionStore";
import { IDashboardAvailableClaim } from "../../../common/interfaces/dashboard.interface";
import MainButton from "../../../components/MainButton/MainButton";
import { createNotification } from "../../../utilities/notifications";
import { USER_WITHDRAWN_SUBSCRIPTION } from "../../../api/club-subscriptions.api";
import { IWithdrawalMembersInfo } from "../../../common/interfaces/proposal.interface";
import { FetchingDataState } from "../../../common/enums/common.enum";

const ClaimsSummary: FC<{ refetchStats: () => void }> = ({ refetchStats }) => {
  const wallet = useAnchorWallet();

  const {
    availableClaims,
    setAvailableClaims,
    claimDashboard,
    dashboardWithdrawals,
    setDashboardWithdrawals,
  } = useDashboardStore();

  const { updateFetchingData } = useTransactionStore();

  useEffect(() => {
    if (wallet) {
      getAvailableClaims();
    }
  }, [wallet]);

  const [getAvailableClaims] = useLazyQuery(GET_DASHBOARD_CLAIMS, {
    onCompleted: (data) => {
      setAvailableClaims([
        ...data.getAvailableWithdrawals.filter(
          (w: IDashboardAvailableClaim) => !w.userWithdrawal.hasUserClaimed
        ),
      ]);
    },
    fetchPolicy: "no-cache",
    variables: {
      memberAddress: wallet?.publicKey.toString(),
    },
  });

  useSubscription(USER_WITHDRAWN_SUBSCRIPTION, {
    onData: ({ data }) => {
      console.log("USER WITHDRAWN SUB", data);
      updateWithdrawalsAfterSubscription(data.data.userWithdrawn);
      updateFetchingData(FetchingDataState.Succeeded);
      refetchStats();
    },
    variables: {
      userAddress: wallet?.publicKey.toString(),
    },
  });

  const handleClaim = useCallback(
    async (availableClaim: IDashboardAvailableClaim) => {
      try {
        if (wallet) {
          claimDashboard(wallet, availableClaim);
          createNotification(MESSAGE_TYPE.SUCCESS, "Succesfully claimed");
        }
      } catch (error: any) {
        createNotification(MESSAGE_TYPE.ERROR, error.message);
        console.log(error);
      }
    },
    [wallet]
  );

  const updateWithdrawalsAfterSubscription = (data: IWithdrawalMembersInfo) => {
    setAvailableClaims([
      ...availableClaims.filter(
        (value) => value.address !== data.withdrawalData
      ),
    ]);
    const relatedDashboardWithdrawal = dashboardWithdrawals.findIndex(
      (w) => w.address === data.withdrawalData
    );
    if (relatedDashboardWithdrawal >= 0) {
      const storedWithdrawals = [...dashboardWithdrawals];
      storedWithdrawals[relatedDashboardWithdrawal] = {
        ...storedWithdrawals[relatedDashboardWithdrawal],
        withdrawalRecord: {
          ...storedWithdrawals[relatedDashboardWithdrawal].withdrawalRecord,
          hasWithdrawn: data.hasUserClaimed,
          withdrawAmount: data.withdrawAmount,
        },
      };
      setDashboardWithdrawals(storedWithdrawals);
    }
  };

  return (
    <DetailsCard title="Claims Available">
      {availableClaims.length < 1 ? (
        <p>No claims available</p>
      ) : (
        availableClaims.map((c: IDashboardAvailableClaim) => (
          <div className="home-summary__cards-claim" key={c.address}>
            <BasicDetailsCard
              image={
                c.clubDataInfo?.imageUrl !== EMPTY_STRING
                  ? c.clubDataInfo?.imageUrl
                  : avatar
              }
              alt={EMPTY_STRING}
              title={c.clubDataInfo?.clubName}
              id={c.treasuryName}
            />
            <MainButton type="button" light onClick={() => handleClaim(c)}>
              Claim
            </MainButton>
          </div>
        ))
      )}
    </DetailsCard>
  );
};

export default ClaimsSummary;
