import { gql } from "@apollo/client";

export const GET_DASHBOARD_CLUBS = gql`
  query getMemberDashboardClubs($memberAddress: String!) {
    getMemberDashboardClubs(memberAddress: $memberAddress) {
      clubAddress
      clubName
      clubImage
      role
    }
  }
`;

export const GET_DASHBOARD_CLAIMS = gql`
  query getAvailableWithdrawals($memberAddress: String!) {
    getAvailableWithdrawals(memberAddress: $memberAddress) {
      treasuryName
      userWithdrawal {
        proposal
        withdrawAmount
        withdrawal
        withdrawalData
        hasUserClaimed
        withdrawalMint
        withdrawalMintDecimals
        withdrawalMintSymbol
        userAddress
      }
      address
      clubData
      withdrawalAddress
      treasury
      clubDataInfo {
        address
        imageUrl
      }
    }
  }
`;

export const GET_DASHBOARD_MEMBERSHIPS = gql`
  query getDashboardMemberships($userWallet: String!) {
    getDashboardMemberships(userWallet: $userWallet) {
      clubInfo {
        address
        name
        imgUrl
        authority
        clubType
        communityMint
      }
      memberData
      isApplication
    }
  }
`;

export const GET_DASHBOARD_PROPOSALS = gql`
  query getDashboardProposals(
    $page: Float!
    $clubAddress: String!
    $userAddress: String!
  ) {
    getDashboardProposals(
      page: $page
      clubAddress: $clubAddress
      userAddress: $userAddress
    ) {
      proposals {
        proposal {
          proposalAccount {
            tokenOwnerRecord
            yesVotesCount
            governance {
              address
            }
            noVotesCount
            maxVoteWeight
            governingTokenMint
            state
            name
          }
          proposalMetadata {
            proposalType
            proposalMetadataAddress
            userVote
            proposalStatus
            proposal
          }
        }
        treasuryData {
          realmAddress
          treasuryDataAddress
          treasuryName
          treasuryAddress
          clubDataAddress
        }
      }
      count
    }
  }
`;

export const GET_DASHBOARD_TREASURIES = gql`
  query getDashboardTreasuries(
    $clubAddress: String!
    $memberAddress: String!
    $page: Float!
  ) {
    getDashboardTreasuries(
      clubAddress: $clubAddress
      memberAddress: $memberAddress
      page: $page
    ) {
      treasuries {
        treasuryName
        treasuryAddress
        ownership
        userDeposit
        balance
        activeFundraise
        treasuryToken {
          symbol
          name
          decimals
          balance
        }
        treasuryRole
      }
      count
    }
  }
`;

export const GET_DASHBOARD_WITHDRAWALS = gql`
  query getDashboardWithdrawals(
    $clubAddress: String!
    $userAddress: String!
    $page: Float!
  ) {
    getDashboardWithdrawals(
      clubAddress: $clubAddress
      userAddress: $userAddress
      page: $page
    ) {
      withdrawals {
        address
        clubData
        realm
        treasury
        withdrawalAddress
        withdrawAmount
        fundraiseNumber
        amountWithdrawn
        proposalAddress
        proposalName
        totalFinancialPower
        withdrawalToken {
          symbol
          name
          decimals
          mint
          balance
        }
        createdAt
        withdrawalRecord {
          address
          memberPubkey
          hasWithdrawn
          withdrawAmount
          withdrawalData
        }
      }
      count
    }
  }
`;

export const GET_DASHBOARD_STAKINGS = gql`
  query getDashboardStakings(
    $clubAddress: String!
    $userAddress: String!
    $page: Float!
  ) {
    getDashboardStakings(
      clubAddress: $clubAddress
      userAddress: $userAddress
      page: $page
    ) {
      stakings {
        address
        clubData
        capAmount
        raisedAmount
        stakePeriodType
        stakePeriodEnd
        name
        rewardPercentage
        status
        nftStakeRecord {
          nftOwner
          tokenAccount
          tokenMint
          stakedNft
          rarity
          hasClaimed
          unstakePeriodEnd
        }
        stakeIndex
        stakeRecord {
          address
          stakeOwner
          amount
          hasClaimed
          claimAmount
          hasUnstaked
          unstakePeriodEnd
          stakeConfig
        }
      }
      count
    }
  }
`;

export const GET_MY_MEMBER_INFO = gql`
  query getMyMemberInfo($address: String!) {
    getMyMemberInfo(address: $address) {
      userAddress
      imageUrl
      name
      socialNetworks {
        type
        value
      }
      explorer
    }
  }
`;

export const GET_USER_DEPOSIT_WITHDRAW_STATS = gql`
  query getAllDepositsAndWithdrawals($memberAddress: String!) {
    getAllDepositsAndWithdrawals(memberAddress: $memberAddress) {
      totalDeposit
      totalWithdrawn
      projects
      invitations
    }
  }
`;

export const CANCEL_CLUB_APPLICATION = gql`
  mutation cancelApplication(
    $signedMessage: String!
    $userAddress: String!
    $clubAddress: String!
  ) {
    cancelApplication(
      signedMessage: $signedMessage
      userAddress: $userAddress
      clubAddress: $clubAddress
    )
  }
`;
