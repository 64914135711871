import { FC } from "react";
import "./HomeSummary.scss";
import { IDashboardClub } from "../../../common/interfaces/dashboard.interface";
import ClubsSummary from "./ClubsSummary";
import ClaimsSummary from "./ClaimsSummary";
import MembershipsSummary from "./MembershipsSummary";

const HomeSummary: FC<{
  refetchStats: () => void;
  clubs: IDashboardClub[];
}> = ({ refetchStats, clubs }) => {
  return (
    <div className="home-summary">
      <h3>Summary</h3>
      <div className="home-summary__cards">
        <ClubsSummary clubs={clubs} />
        <ClaimsSummary refetchStats={refetchStats} />
        <MembershipsSummary />
      </div>
    </div>
  );
};

export default HomeSummary;
