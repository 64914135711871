export enum DealType {
  TokenProportional,
  TokenEqual,
  RoleBased,
  NftBased,
  Syndicate,
}

export enum FundraiseModel {
  Token = "Token",
  Equity = "Equity",
  Both = "Both",
}

export enum KycType {
  All,
  OnlyUS,
  ExceptUS,
}
