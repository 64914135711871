import { FC, useEffect, useMemo, useState } from "react";
import "./Overview.scss";
import add from "../../assets/add_circle_full.png";
import NoData from "../../components/NoData/NoData";
import MainButton from "../../components/MainButton/MainButton";
import OverviewFundraiseCard from "./OverviewFundraiseCard/OverviewFundraiseCard";
import OverviewHeader from "./OverviewHeader/OverviewHeader";
import OverviewTreasuryCard from "./OverviewTreasuryCard/OverviewTreasuryCard";
import OverviewVaultCard from "./OverviewVaultCard/OverviewVaultCard";
import ClubDetailsLayout from "../../components/ClubDetailsWrapper/ClubDetailsWrapper";
import OverviewMembersCard from "./OverviewMembersCard/OverviewMembersCard";
import InviteBar from "./InviteBar/InviteBar";
import { useNavigate, useParams } from "react-router";
import { clubStore } from "../../state/clubStore";
import { MemberStatus } from "../../common/enums/clubs.enum";
import ProposalCard from "../../components/ProposalCard/ProposalCard";
import { VAULT } from "../../common/constants/routes.constants";
import { MAX_VAULT_NFTS_FOR_OVERVIEW_PAGE } from "../../common/constants/club.constants";
import CreateProposalModal from "../../components/CreateProposal/CreateProposalModal";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_TREASURY_PROPOSALS } from "../../api/club.api";
import { IProposal } from "../../common/interfaces/proposal.interface";
import { Sort, TabLabel } from "../../common/enums/common.enum";
import { ProposalType } from "../../common/enums/proposal.enum";
import { checkIfUserCanCreateAtLeastOneTypeOfProposal } from "../../utilities/helpers";
import { CLIENT_CONFIGURATION } from "../../client/configuration";

const Overview: FC = () => {
  const [isCreateProposalModalActive, toggleCreateProposal] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const {
    memberData,
    vault,
    activeTreasury,
    setProposals,
    proposals,
    memberTreasuryInfo,
  } = clubStore();

  const [getProposals] = useLazyQuery(GET_ALL_TREASURY_PROPOSALS, {
    onCompleted: (data) => {
      setProposals(data.getProposalsForTreasury.proposals);
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (activeTreasury)
      getProposals({
        variables: {
          treasuryAddress: activeTreasury?.treasuryAddress,
          proposalFilters: {
            page: 1,
            sort: Sort.DataAddedLatest,
            filter: TabLabel.Active,
          },
          userAddress: memberData?.memberPubkey,
        },
      });
  }, [memberData, activeTreasury?.treasuryAddress]);

  const canUserCreateProposal = useMemo(() => {
    return checkIfUserCanCreateAtLeastOneTypeOfProposal(
      memberTreasuryInfo,
      activeTreasury?.treasuryRoleConfig
    );
  }, [memberTreasuryInfo, activeTreasury]);

  const clientHasTradingProposal = useMemo(() => {
    return CLIENT_CONFIGURATION.proposalTypes.find(
      (item) => item === ProposalType.Trading
    );
  }, []);

  return (
    <>
      <ClubDetailsLayout>
        {isCreateProposalModalActive && (
          <CreateProposalModal
            closeModal={() => toggleCreateProposal(false)}
            proposalType={ProposalType.Discussion}
          />
        )}
        {memberData?.status === MemberStatus.Pending && <InviteBar />}
        <OverviewHeader />
        <div className="overview">
          <h2 className="overview__title">Overview</h2>
          <div className="overview-cards">
            <OverviewTreasuryCard />
            <OverviewFundraiseCard />
            <OverviewMembersCard />
          </div>
          <div className="overview-proposal-header">
            <h2 className="overview__title">Proposals</h2>
            <MainButton
              type="button"
              onClick={() => toggleCreateProposal(true)}
              light
              disabled={!canUserCreateProposal}
            >
              <img src={add} alt="Add" /> Create a Proposal
            </MainButton>
          </div>
          {proposals && proposals?.length < 1 ? (
            <NoData
              message="This deal has no active Proposals"
              mainButtonText="Create a Proposal"
              mainButtonOnClick={() => toggleCreateProposal(true)}
              disabled={!canUserCreateProposal}
            />
          ) : (
            <div className="overview-proposals">
              {proposals?.map((i: IProposal, index: number) => (
                <ProposalCard proposal={i} key={index} />
              ))}
            </div>
          )}
          <h2 className="overview__title">Vault</h2>
          {vault?.nfts && vault?.nfts.length < 1 ? (
            <NoData
              message="This deal has no NFTS"
              mainButtonText={
                clientHasTradingProposal && "Create a Trade Proposal"
              }
              mainButtonOnClick={
                clientHasTradingProposal
                  ? () => toggleCreateProposal(true)
                  : undefined
              }
              disabled={!canUserCreateProposal}
            />
          ) : (
            <div className="overview-vault">
              <div className="overview-vault-cards">
                {vault?.nfts &&
                  vault?.nfts
                    .map((i, index) => (
                      <OverviewVaultCard card={i} key={index} />
                    ))
                    .slice(0, MAX_VAULT_NFTS_FOR_OVERVIEW_PAGE)}
              </div>
              <div className="overview-vault__button">
                <MainButton
                  type="button"
                  light
                  onClick={() =>
                    navigate(
                      `${VAULT}/${params.dealAddress}/${activeTreasury?.treasuryAddress}`
                    )
                  }
                >
                  View All
                </MainButton>
              </div>
            </div>
          )}
        </div>
      </ClubDetailsLayout>
    </>
  );
};

export default Overview;
