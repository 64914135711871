import {
  BROWSE_DEALS,
  CREATE_A_DEAL,
  HOME,
  INTRO_DECK,
  OTC_DEAL,
  OVERVIEW,
  PROPOSALS,
  TREASURY,
  VAULT,
} from "./routes.constants";

export const NO_STICKY_HEADER = [OVERVIEW, INTRO_DECK];
export const NO_SIDEBAR = [HOME, BROWSE_DEALS, CREATE_A_DEAL];
export const STICKY_TREASURY = [PROPOSALS, TREASURY, VAULT, OTC_DEAL];

export const TREASURY_RELATED_PAGES = [
  OVERVIEW,
  PROPOSALS,
  TREASURY,
  VAULT,
  OTC_DEAL,
];

export const EMPTY_IMAGE_BG_COLORS = [
  "var(--yellow-100)",
  "var(--blue-300)",
  "var(--green-100)",
  "var(--purple-100)",
  "var(--red-100)",
];

export const RED100 = "var(--red-100)";
export const RED500 = "var(--red-500)";
export const GREEN500 = "var(--primary-color)";
export const GREEN400 = "var(--green-400)";
export const GREEN100 = "var(--green-100)";
export const BLUE500 = "var(--blue-500)";
export const BLUE400 = "var(--blue-400)";
export const BLUE300 = "var(--blue-300)";
export const BLUE100 = "var(--blue-100)";
export const PURPLE100 = "var(--purple-100)";
export const PURPLE500 = "var(--purple-500)";
export const YELLOW100 = "var(--yellow-100)";
export const WHITE100 = "var(--white-100";
export const WHITE200 = "var(--white-200";
export const WHITE300 = "var(--white-300";
export const GREY500 = "var(--grey-500)";
export const GREY300 = "var(--grey-300)";
export const GREY200 = "var(--grey-200)";
export const GREY100 = "var(--grey-100)";
export const GREY000 = "var(--grey-008)";
