import React, { FC, useMemo } from "react";
import { INotification } from "../../../common/interfaces/common.interface";
import clubAvatar from "../../../assets/unique_avatar.png";
import {
  EMPTY_STRING,
  MESSAGE_TYPE,
} from "../../../common/constants/common.constants";
import { parseDateToRelativeTime } from "../../../utilities/helpers";
import "./NotificationItem.scss";
import { useMutation } from "@apollo/client";
import { TOGGLE_NOTIFICATION } from "../../../api/club.api";
import { userStore } from "../../../state/userStore";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { createNotification } from "../../../utilities/notifications";
import { NotificationTab } from "../../../common/enums/common.enum";
import {
  OTC_DEAL,
  OVERVIEW,
  PROPOSALS,
  STAKING,
  TREASURY,
  MEMBERS,
} from "../../../common/constants/routes.constants";
import { useNavigate } from "react-router";
import { findTreasuryAddressByIndex } from "../../../program/program-helpers";

const NotificationItem: FC<{ notification: INotification }> = ({
  notification,
}) => {
  const { updateNotification } = userStore();
  const [toggleNotification] = useMutation(TOGGLE_NOTIFICATION);
  const navigate = useNavigate();

  const wallet = useAnchorWallet();

  const markAsRead = async () => {
    try {
      await toggleNotification({
        variables: {
          userWallet: wallet?.publicKey,
          notificationId: notification.userNotificationId,
        },
      });
      const newNotification: INotification = {
        ...notification,
        hasRead: !notification.hasRead,
      };
      updateNotification(newNotification);
    } catch (error) {
      console.log(error);
      createNotification(MESSAGE_TYPE.ERROR, "Failed to read notificaiton");
    }
  };

  const mapTabRoute = useMemo(() => {
    switch (notification.tab) {
      case NotificationTab.ApplicationForClub:
      case NotificationTab.Invitations:
        return MEMBERS;
      case NotificationTab.Otc:
        return OTC_DEAL;
      case NotificationTab.Proposal:
        return PROPOSALS;
      case NotificationTab.Staking:
        return STAKING;
      case NotificationTab.Treasury:
        return TREASURY;
      default:
        return OVERVIEW;
    }
  }, [notification.tab]);

  const goToNotificationDetails = async () => {
    if (!notification.hasRead) await markAsRead();
    const treasuryAddress =
      notification.treasuryAddress ??
      findTreasuryAddressByIndex(notification.clubAddress, 1);
    let tabRoute = `${mapTabRoute}/${notification.clubAddress}/${treasuryAddress}`;
    if (notification.tab === NotificationTab.Proposal) {
      navigate(`${tabRoute}?proposal=${notification.proposalAddress}`);
      return;
    }
    if (notification.tab === NotificationTab.ApplicationForClub) {
      navigate(`${tabRoute}?filter=Applications`);
      return;
    }
    navigate(tabRoute);
  };

  return (
    <div className="notification-item" onClick={goToNotificationDetails}>
      <div className="notification-item__info">
        <img
          src={
            notification.imageUrl && notification.imageUrl !== EMPTY_STRING
              ? notification.imageUrl
              : clubAvatar
          }
          alt="Notification club img"
        />
        <div className="notification-item__text">
          <p
            className="notification-item__message"
            dangerouslySetInnerHTML={{ __html: notification.message }}
          ></p>
          <p className="notification-item__time">
            {parseDateToRelativeTime(notification.createdAt)}
          </p>
        </div>
        <input
          type="radio"
          value={String(notification.userNotificationId)}
          checked={!notification.hasRead}
          onClick={async (e) => {
            e.stopPropagation();
            await markAsRead();
          }}
        />
      </div>
    </div>
  );
};

export default NotificationItem;
