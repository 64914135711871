import { Route, Routes as Router } from "react-router";
import {
  BROWSE_DEALS,
  CREATE_A_DEAL,
  HOME,
  INTRO_DECK,
  MEMBERS,
  OTC_DEAL,
  OVERVIEW,
  PROPOSALS,
  STAKING,
  TREASURY,
  VAULT,
} from "./common/constants/routes.constants";
import CreateDeal from "./pages/CreateDeal/CreateDeal";
import BrowseDeals from "./pages/BrowseDeals/BrowseDeals";
import Overview from "./pages/Overview/Overview";
import Home from "./pages/Home/Home";
import Members from "./pages/Members/Members";
import Staking from "./pages/Staking/Staking";
import OTCDeal from "./pages/OTCDeal/OTCDeal";
import IntroDeck from "./pages/IntroDeck/IntroDeck";
import Proposals from "./pages/Proposals/Proposals";
import Vault from "./pages/Vault/Vault";
import Treasury from "./pages/Treasury/Treasury";
import { CLIENT_CONFIGURATION } from "./client/configuration";

const Routes = () => {
  return (
    <Router>
      <Route element={<Home />} path={HOME} />
      {!CLIENT_CONFIGURATION.hideBrowseClubs && (
        <Route element={<BrowseDeals />} path={BROWSE_DEALS} />
      )}
      <Route element={<CreateDeal />} path={CREATE_A_DEAL} />
      <Route
        element={<Overview />}
        path={`${OVERVIEW}/:dealAddress/:treasuryAddress`}
      />
      <Route
        element={<IntroDeck />}
        path={`${INTRO_DECK}/:dealAddress/:treasuryAddress`}
      />
      <Route
        element={<Proposals />}
        path={`${PROPOSALS}/:dealAddress/:treasuryAddress`}
      />
      <Route
        element={<Treasury />}
        path={`${TREASURY}/:dealAddress/:treasuryAddress`}
      />
      <Route
        element={<Vault />}
        path={`${VAULT}/:dealAddress/:treasuryAddress`}
      />
      <Route
        element={<Members />}
        path={`${MEMBERS}/:dealAddress/:treasuryAddress`}
      />
      <Route
        element={<OTCDeal />}
        path={`${OTC_DEAL}/:dealAddress/:treasuryAddress`}
      />
      <Route
        element={<Staking />}
        path={`${STAKING}/:dealAddress/:treasuryAddress`}
      />
    </Router>
  );
};

export default Routes;
