import React from "react";
import { MaterialUITextField } from "../../common/constants/mui.constants";

const TextInputField: React.FC<{
  value: string;
  label?: string;
  fieldError?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}> = ({ value, label, fieldError, disabled, onChange }) => {
  return (
    <div className="input-field__wrapper">
      <MaterialUITextField
        id="standard-textarea"
        label={label}
        variant="filled"
        disabled={disabled}
        value={value}
        sx={
          fieldError
            ? {
                "& .MuiInputBase-root": {
                  borderColor: "red",
                },
              }
            : undefined
        }
        onInput={(e: any) => onChange(e.target.value)}
      />
    </div>
  );
};

export default TextInputField;
