import React from "react";
import "./IntroDeckHeader.scss";
import MainButton from "../../../components/MainButton/MainButton";
import Chip from "../../../components/Chip/Chip";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import UserImage from "../../../components/UserImage/UserImage";
import profile from "../../../assets/Profilepicture.png";
import { WHITE300 } from "../../../common/constants/layout.constants";

const IntroDeckHeader = () => {
  return (
    <div className="intro-deck-header">
      <div>
        <UserImage image={profile} name={"A"} width="144" />
      </div>
      <div className="intro-deck-header__info">
        <div className="intro-deck-header__actions">
          <h2>Unique.VC</h2>
          <div className="intro-deck-header__actions-buttons">
            <MainButton
              type="button"
              light
              squared
              onClick={() => console.log("click")}
            >
              Edit Deal
            </MainButton>
            <Chip text="Founder" backgroundColor={WHITE300} />
            <MainButton
              type="button"
              onClick={() => console.log("click")}
              squared
            >
              Presentation
            </MainButton>
          </div>
        </div>
        <div className="intro-deck-header__main">
          <div className="intro-deck-header__list">
            <div className="intro-deck-header__list-item">
              <p>Total Raised</p>
              <div className="intro-deck-header__flex">
                <ProgressBar numerator={10} denominator={45} active />
                <p>
                  10k/<span>45M</span>
                </p>
              </div>
            </div>
            <div className="intro-deck-header__list-items">
              <div className="intro-deck-header__list-item">
                <p>Round</p>
                <span>Seed</span>
              </div>
              <div className="intro-deck-header__list-item">
                <p>Model</p>
                <span>SAFE</span>
              </div>
            </div>
            <div className="intro-deck-header__list-items">
              <div className="intro-deck-header__list-item">
                <p>Valuation</p>
                <span>20M Post</span>
              </div>
              <div className="intro-deck-header__list-item">
                <p>Lead Investor</p>
                <span>Solana</span>
              </div>
            </div>
          </div>
          <div>
            <p className="intro-deck-header__unique">
              Unique.vc is an on-chain investment management platform empowering
              Investors and startup founders, running cross-chain. They’ve
              already launched their show more
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroDeckHeader;
