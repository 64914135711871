import { FC, useState } from "react";
import "./ProposalModal.scss";
import Modal from "../Modal/Modal";
import MainButton from "../MainButton/MainButton";
import ProposalModalHeader from "./ProposalModalHeader/ProposalModalHeader";
import ProposalModalProgress from "./ProposalModalProgress/ProposalModalProgress";
import ProposalModalDetails from "./ProposalModalDetails/ProposalModalDetails";
import { IProposal } from "../../common/interfaces/proposal.interface";
import { ProposalState, Vote } from "@solana/spl-governance";
import ProposalCompletedActions from "../ProposalActions/ProposalCompletedActions/ProposalCompletedActions";
import { ProposalTypeProgram } from "../../common/enums/proposal.enum";
import {
  checkIfUserCanCreateAtLeastOneTypeOfProposal,
  checkIfUserHasTreasuryPermissionForAction,
} from "../../utilities/helpers";
import { clubStore } from "../../state/clubStore";
import { TreasuryAction } from "../../common/enums/clubs.enum";
import { useAnchorWallet } from "@solana/wallet-adapter-react";

const ProposalModal: FC<{
  closeModal: () => void;
  proposal: IProposal;
  voteForProposal: (choice: Vote) => void;
  executeProposal: () => void;
}> = ({ closeModal, proposal, voteForProposal, executeProposal }) => {
  const [cancelling, toggleCancelling] = useState(false);
  const wallet = useAnchorWallet();
  const { memberTreasuryInfo, activeTreasury } = clubStore();

  return (
    <Modal closeModal={closeModal}>
      <ProposalModalHeader
        proposal={proposal}
        cancelling={cancelling}
        toggleCancelling={toggleCancelling}
      />
      <ProposalModalProgress
        proposal={proposal}
        executeProposal={executeProposal}
        voteForProposal={voteForProposal}
      />
      <ProposalModalDetails
        proposalType={proposal.proposalMetadata.proposalType}
        approvalVotePercentage={
          proposal.proposalAccount.governance.voteThreshold
        }
        maxVotingTime={proposal.proposalAccount.governance.maxVotingTime}
        proposalDetails={proposal.proposalDetails}
        link={proposal.proposalMetadata.discussionLink}
      />
      <div className="proposal-modal-button">
        {(proposal.proposalAccount.state === ProposalState.Voting ||
          proposal.proposalAccount.state === ProposalState.Draft) && (
          <MainButton
            onClick={() => toggleCancelling(true)}
            type="button"
            light
            disabled={
              proposal.proposalMetadata.authority !==
                wallet?.publicKey.toString() &&
              !checkIfUserHasTreasuryPermissionForAction(
                memberTreasuryInfo,
                activeTreasury?.treasuryRoleConfig,
                TreasuryAction.CancelProposal
              )
            }
          >
            Cancel Proposal
          </MainButton>
        )}
        {proposal.proposalAccount.state === ProposalState.Completed && (
          <ProposalCompletedActions proposal={proposal} />
        )}
        {proposal.proposalAccount.state === ProposalState.Succeeded &&
          proposal.proposalMetadata.proposalType !==
            ProposalTypeProgram.Discussion && (
            <MainButton onClick={executeProposal} type="button">
              Execute
            </MainButton>
          )}
      </div>
    </Modal>
  );
};

export default ProposalModal;
