import React from "react";
import { isMobile } from "react-device-detect";
import "./ProposalsItemSkeleton.scss";
import SkeletonItem from "../SkeletonItem/SkeletonItem";

const ProposalsItemSkeleton = () => {
  return (
    <div className="proposals-item-skeleton">
      <div className="proposals-item-skeleton__header">
        <div className="proposals-item-skeleton__status-and-link">
          <SkeletonItem width={80} height={25} borderRadius={8} />
          <SkeletonItem width={80} height={20} borderRadius={8} />
        </div>
        <div className="proposals-item-skeleton__status-and-link">
          <SkeletonItem width={80} height={25} borderRadius={8} />
          <SkeletonItem width={80} height={20} borderRadius={8} />
        </div>
      </div>

      <div className="proposals-item-skeleton__voting">
        <SkeletonItem height={30} width={60} borderRadius={8} />
        <SkeletonItem height={6} borderRadius={8} />
      </div>
      <div className="proposals-item-skeleton__header">
        <SkeletonItem height={20} width={90} borderRadius={8} />
        <SkeletonItem height={20} width={90} borderRadius={8} />
      </div>
    </div>
  );
};

export default ProposalsItemSkeleton;
