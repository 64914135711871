import { FC, useMemo } from "react";
import "./OverviewTreasuryCard.scss";
import copy from "../../../assets/content_copy.png";
import piggybank from "../../../assets/piggybank.png";
import MainButton from "../../../components/MainButton/MainButton";
import TokenChain from "../../../components/TokenChain/TokenChain";
import { useNavigate, useParams } from "react-router";
import { OTC_DEAL } from "../../../common/constants/routes.constants";
import HoverIcon from "../../../components/HoverIcon/HoverIcon";
import {
  getAmountWithDecimalsForCurrency,
  handleCopyToClipboard,
} from "../../../utilities/helpers";
import { clubStore } from "../../../state/clubStore";

const OverviewTreasuryCard: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { activeTreasury, memberTreasuryInfo } = clubStore();

  const memberDepositedAmount = useMemo(() => {
    return (
      getAmountWithDecimalsForCurrency(
        activeTreasury?.currencyDecimals,
        memberTreasuryInfo?.depositRecords[0]?.accumulatedAmount
      ) ?? 0
    );
  }, [memberTreasuryInfo]);

  const shouldDisplayTreasuryTokens = useMemo(() => {
    return (
      activeTreasury?.treasuryTokens &&
      activeTreasury?.treasuryTokens.length > 0
    );
  }, [activeTreasury]);

  return (
    <div className="overview-treasury">
      <div className="overview-treasury__header">
        <img src={piggybank} alt="Treasury" />
        <h3>Treasury Details</h3>
        <HoverIcon
          src={copy}
          alt="Copy"
          onClick={() => handleCopyToClipboard(activeTreasury?.treasuryAddress)}
        />
      </div>
      <div className="overview-treasury__amount">
        <p>
          {getAmountWithDecimalsForCurrency(
            activeTreasury?.currencyDecimals,
            activeTreasury?.balance
          )}
          <span>{activeTreasury?.currencySymbol}</span>
        </p>
        <p className="overview-treasury__amount--thin">≈$ 27,594,877.04215</p>
      </div>
      <div className="overview-treasury__owned">
        <div>
          <h6>Your Deposit</h6>
          <p>
            {memberDepositedAmount}{" "}
            <span>{activeTreasury?.currencySymbol}</span>
          </p>
        </div>
        <div className="overview-treasury__owned-tokens">
          <div>
            <h6>You Own</h6>
            <p>{memberTreasuryInfo?.ownership.toFixed(2) ?? 0}%</p>
          </div>
          {shouldDisplayTreasuryTokens && (
            <div>
              <h6>Tokens</h6>
              <TokenChain />
            </div>
          )}
        </div>
      </div>
      <div className="overview-treasury__button">
        <MainButton
          light
          onClick={() =>
            navigate(
              `${OTC_DEAL}/${params.dealAddress}/${activeTreasury?.treasuryAddress}`
            )
          }
          type="button"
        >
          OTC Deals
        </MainButton>
      </div>
    </div>
  );
};

export default OverviewTreasuryCard;
