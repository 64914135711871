import { FC, Fragment, useState } from "react";
import "./ProposalCard.scss";
import {
  IProposal,
  IProposalInstructionAccount,
  IWithdrawalProposalMetadata,
} from "../../common/interfaces/proposal.interface";
import ProposalModal from "../ProposalModal/ProposalModal";
import { ProposalCardHeader } from "./ProposalCardHeader/ProposalCardHeader";
import { ProposalVotingActions } from "../ProposalActions/ProposalActions";
import { ProgressBarCircle } from "../ProgressBarCircle/ProgressBarCircle";
import { calculateVotePercentage } from "../../utilities/helpers";
import { ProposalState, Vote } from "@solana/spl-governance";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { MESSAGE_TYPE } from "../../common/constants/common.constants";
import { ProposalTypeProgram } from "../../common/enums/proposal.enum";
import {
  voteToProposal,
  executeProposal,
  cancelProposal,
} from "../../program/methods/proposals";
import { clubStore } from "../../state/clubStore";
import { createNotification } from "../../utilities/notifications";
import MainButton from "../MainButton/MainButton";
import ProposalCompletedActions from "../ProposalActions/ProposalCompletedActions/ProposalCompletedActions";
import { getFundraiseConfigAddress } from "../../program/program-helpers";

const ProposalCard: FC<{
  proposal: IProposal;
}> = ({ proposal }) => {
  const [isProposalDetailsModalActive, toggleProposalModal] = useState(false);
  const { activeTreasury, memberData } = clubStore();
  const wallet = useAnchorWallet();

  const voteForProposalHandler = async (choice: Vote) => {
    try {
      //TODO: add nft votes
      if (!activeTreasury || !memberData || !wallet) {
        createNotification(MESSAGE_TYPE.ERROR, "Missing data for voting");
        return;
      }
      await voteToProposal(
        proposal,
        activeTreasury?.realmAddress,
        activeTreasury?.treasuryDataAddress,
        wallet,
        memberData.address,
        activeTreasury?.clubDataAddress,
        choice
      );
    } catch (error) {
      createNotification(MESSAGE_TYPE.ERROR, "Failed to cast vote");
    }
  };

  const executeProposalHandler = async () => {
    try {
      if (!activeTreasury || !wallet || !memberData) {
        createNotification(
          MESSAGE_TYPE.ERROR,
          "Missing data for executing proposal"
        );
        return;
      }
      let withdrawalMint: PublicKey | undefined = undefined;
      let withdrawalTreasuryToken: PublicKey | undefined = undefined;
      if (
        proposal.proposalMetadata.proposalType ===
        ProposalTypeProgram.Withdrawal
      ) {
        const proposalDetails: IWithdrawalProposalMetadata =
          proposal.proposalDetails as IWithdrawalProposalMetadata;

        withdrawalMint = new PublicKey(proposalDetails.withdrawalMint);
        withdrawalTreasuryToken = new PublicKey(proposalDetails.treasuryToken);
      }

      await executeProposal(
        new PublicKey(proposal.proposalMetadata.proposal),
        new PublicKey(proposal.proposalMetadata.proposalMetadataAddress),
        proposal.proposalMetadata.proposalType,
        wallet,
        new PublicKey(activeTreasury?.treasuryAddress),

        new PublicKey(proposal.proposalAccount.governance.address),
        new PublicKey(activeTreasury?.treasuryDataAddress),
        new PublicKey(activeTreasury?.clubDataAddress),
        await prepareProposalInstructionRemainingAccounts(),
        new PublicKey(memberData?.address),
        new PublicKey(activeTreasury.realmAddress),
        withdrawalMint,
        withdrawalTreasuryToken
      );
      toggleProposalModal(false);
      createNotification(MESSAGE_TYPE.SUCCESS, "Proposal sucessfully executed");
    } catch (error) {
      console.log(error);
      createNotification(MESSAGE_TYPE.ERROR, "Failed to execute proposal");
    }
  };

  const prepareProposalInstructionRemainingAccounts = async () => {
    if (!activeTreasury) {
      throw new Error("Missing data");
    }

    const remainingAccounts: IProposalInstructionAccount[] = [];
    switch (proposal.proposalMetadata.proposalType) {
      case ProposalTypeProgram.TransferFunds:
      case ProposalTypeProgram.Withdrawal:
      case ProposalTypeProgram.UpdateGovernanceConfig:
      case ProposalTypeProgram.AddSellPermission:
        proposal.proposalAccount.proposalInstructionAccounts.forEach((item) =>
          remainingAccounts.push(item)
        );
        break;
      case ProposalTypeProgram.UpdateRoleConfig:
        remainingAccounts.push({
          pubkey: activeTreasury?.realmAddress,
          isWritable: true,
          isSigner: false,
        });
        break;
      case ProposalTypeProgram.CreateFundraise:
        remainingAccounts.push({
          pubkey: getFundraiseConfigAddress(
            activeTreasury.fundraiseCount + 1,
            new PublicKey(activeTreasury.treasuryDataAddress)
          ).toString(),
          isWritable: true,
          isSigner: false,
        });
        break;
    }
    return remainingAccounts;
  };

  return (
    <Fragment>
      {isProposalDetailsModalActive && (
        <ProposalModal
          closeModal={() => toggleProposalModal(false)}
          proposal={proposal}
          executeProposal={executeProposalHandler}
          voteForProposal={voteForProposalHandler}
        />
      )}
      <div
        className="proposal-card"
        onClick={() => toggleProposalModal && toggleProposalModal(true)}
      >
        <ProposalCardHeader
          proposalType={proposal.proposalMetadata.proposalType}
          maxVotingTime={proposal.proposalAccount.governance.maxVotingTime}
          proposalStatus={proposal.proposalAccount.state}
          link={proposal.proposalMetadata.discussionLink}
          votingStartAt={proposal.proposalAccount.startVotingAt}
        />
        <h2>{proposal.proposalMetadata.name}</h2>
        <div className="proposal-card__stats">
          <ProgressBarCircle
            approved={calculateVotePercentage(
              proposal.proposalAccount.yesVotesCount ?? 0,
              proposal.proposalMetadata.maxVoterWeight
            )}
            rejected={calculateVotePercentage(
              proposal.proposalAccount.denyVoteWeight ?? 0,
              proposal.proposalMetadata.maxVoterWeight
            )}
            maxVoterWeight={proposal.proposalMetadata.maxVoterWeight ?? 0}
            stats
          />
          <div onClick={(e) => e.stopPropagation()}>
            {proposal.proposalAccount.state === ProposalState.Voting && (
              <ProposalVotingActions
                proposal={proposal}
                voteForProposal={voteForProposalHandler}
              />
            )}
            {proposal.proposalAccount.state === ProposalState.Completed && (
              <ProposalCompletedActions proposal={proposal} />
            )}
            {proposal.proposalAccount.state === ProposalState.Succeeded &&
              proposal.proposalMetadata.proposalType !==
                ProposalTypeProgram.Discussion && (
                <MainButton onClick={executeProposalHandler} type="button">
                  Execute
                </MainButton>
              )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProposalCard;
