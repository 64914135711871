import { FC, Fragment, useCallback, useEffect, useMemo } from "react";
import { FieldArray, FieldArrayRenderProps } from "formik";
import InputField from "../InputFields/InputField";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import "./Ownership.scss";
import treasuryFormModel from "../CreateTreasuryModal/treasuryFormModel";
import { YELLOW100 } from "../../common/constants/layout.constants";
import errorIcon from "../../assets/error.svg";
import cancelIcon from "../../assets/cancel.svg";
import add from "../../assets/add_circle_full.png";
import Chip from "../Chip/Chip";
import MainButton from "../MainButton/MainButton";
import { IOption } from "../../common/interfaces/common.interface";
import { getTrimmedPublicKey } from "../../utilities/helpers";
import { clubStore } from "../../state/clubStore";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import { IMemberWallets } from "../../common/interfaces/form.interface";
import { useLazyQuery } from "@apollo/client";
import { GET_NON_FILTERED_MEMBERS_FOR_CLUB } from "../../api/club.api";
import { IMemberData } from "../../common/interfaces/club.interface";

const Ownership: FC<{
  edit?: boolean;
  disabled?: boolean;
  members: IMemberWallets[];
  shouldFetchClubMembers?: boolean;
}> = ({ edit, disabled, members, shouldFetchClubMembers }) => {
  const { treasuryMembers, clubBasicInfo } = clubStore();

  const [getClubMembers, clubMembersInfo] = useLazyQuery(
    GET_NON_FILTERED_MEMBERS_FOR_CLUB
  );

  useEffect(() => {
    if (clubBasicInfo && shouldFetchClubMembers) {
      getClubMembers({
        variables: {
          clubAddress: clubBasicInfo?.address,
        },
      });
    }
  }, [clubBasicInfo, shouldFetchClubMembers]);

  const addMemberItem = useCallback((arrayHelper: FieldArrayRenderProps) => {
    arrayHelper.push({
      wallet: [],
      membersAmount: EMPTY_STRING,
    });
  }, []);

  const removeOption = useCallback(
    (index: number, arrayHelpers: FieldArrayRenderProps) => {
      arrayHelpers.remove(index);
    },
    []
  );

  const treasuryMemberOptions: IOption[] = useMemo(
    () =>
      treasuryMembers
        ? treasuryMembers?.map((member) => {
            return {
              label: getTrimmedPublicKey(member.authority),
              value: member.authority,
            };
          })
        : [],
    [treasuryMembers]
  );

  const clubMembers: IMemberData[] = useMemo(() => {
    return clubMembersInfo.data?.getNonFilteredClubMembers;
  }, [clubMembersInfo]);

  const clubMemberOptions: IOption[] = useMemo(
    () =>
      clubMembers
        ? clubMembers.map((member) => {
            return {
              label: getTrimmedPublicKey(member.address),
              value: member.address,
            };
          })
        : [],
    [clubMembers]
  );

  return (
    <div
      className="ownership-config"
      style={edit ? { marginTop: 0 } : undefined}
    >
      <div className="ownership-config__section">
        {!edit && <p className="ownership-config__title">Ownership</p>}
        <p className="ownership-config__description">
          Based on the amount deposited, each investor will get proportional
          Club Ownership. You can also assign Club Ownership to specific members
          without them depositing any funds.
        </p>
      </div>
      <FieldArray
        name={treasuryFormModel.formFields.members.name}
        render={(arrayHelpers) => (
          <Fragment>
            {members.map((item, index) => (
              <div className="ownership-config__voting-power">
                <img
                  src={cancelIcon}
                  alt="Cancel icon"
                  onClick={() => removeOption(index, arrayHelpers)}
                />
                <div className="ownership-config__role">
                  <InputField
                    name={`members.${index}.wallet`}
                    type="select"
                    labelTitle="Member wallet"
                    placeholder="Enter wallet address"
                    multiple
                    options={
                      shouldFetchClubMembers
                        ? clubMemberOptions
                        : treasuryMemberOptions
                    }
                  />
                </div>
                <div className="ownership-config__power">
                  <InputField
                    name={`members.${index}.membersAmount`}
                    type="text"
                    labelTitle={"Percentage %"}
                    disabled={disabled}
                  />
                </div>
              </div>
            ))}
            <div className="add-member-form__actions">
              <MainButton
                onClick={() => {
                  addMemberItem(arrayHelpers);
                }}
                type="button"
                light
              >
                <img src={add} alt="Add circle" /> New rule
              </MainButton>
            </div>
            <div className="add-member-form__chip">
              <Chip
                text="You can set this later, but you can set this only once"
                backgroundColor={YELLOW100}
                stretch
                fontSize="0.8"
                padding="14px"
                icon
                image={errorIcon}
              />
            </div>
          </Fragment>
        )}
      />
    </div>
  );
};

export default Ownership;
