import { FC } from "react";
import "./HomeTables.scss";
import BasicTable from "../../../components/BasicTable/BasicTable";
import {
  ClubsTableTitles,
  MembershipTableTitles,
  ProposalsTableTitles,
  StakingTableTitles,
  TreasuriesTableTitles,
  WithdrawalsTableTitles,
} from "../../../common/constants/table.constants";
import TreasuryTableRow from "../TreasuryTableRow/TreasuryTableRow";
import ProposalTableRow from "../ProposalTableRow/ProposalTableRow";
import WithdrawalTableRow from "../WithdrawalTableRow/WithdrawalTableRow";
import StakingTableRow from "../StakingTableRow/StakingTableRow";
import { TabLabel } from "../../../common/enums/common.enum";
import {
  IDashboardProposalItem,
  IDashboardStaking,
  IDashboardTreasury,
  IDashboardWithdrawal,
} from "../../../common/interfaces/dashboard.interface";

const HomeTables: FC<{
  treasuries: IDashboardTreasury[];
  proposals: IDashboardProposalItem[];
  withdrawals: IDashboardWithdrawal[];
  staking: IDashboardStaking[];
  activeTab: TabLabel;
}> = ({ activeTab, treasuries, proposals, withdrawals, staking }) => {
  const getTableTitles = (activeTab: TabLabel) => {
    switch (activeTab) {
      case TabLabel.Membership:
        return MembershipTableTitles;
      case TabLabel.Proposals:
        return ProposalsTableTitles;
      case TabLabel.Staking:
        return StakingTableTitles;
      case TabLabel.Treasuries:
        return TreasuriesTableTitles;
      case TabLabel.Withdrawals:
        return WithdrawalsTableTitles;
      default:
        return ClubsTableTitles;
    }
  };

  const getTableValues = (activeTab: TabLabel) => {
    switch (activeTab) {
      case TabLabel.Treasuries:
        return treasuries.map((treasury, index) => (
          <TreasuryTableRow treasury={treasury} key={index} />
        ));
      case TabLabel.Proposals:
        return proposals.map((proposal, index) => (
          <ProposalTableRow proposalData={proposal} key={index} />
        ));
      case TabLabel.Withdrawals:
        return withdrawals.map((withdrawal, index) => (
          <WithdrawalTableRow withdrawal={withdrawal} key={index} />
        ));
      case TabLabel.Staking:
        return staking.map((stake, index) => (
          <StakingTableRow stake={stake} key={index} />
        ));
    }
  };

  return (
    <div className="home-tables-box">
      <BasicTable titles={getTableTitles(activeTab)} loader={false}>
        {getTableValues(activeTab)}
      </BasicTable>
    </div>
  );
};

export default HomeTables;
