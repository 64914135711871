import React from "react";
import DetailsCard from "../../../components/DetailsCard/DetailsCard";
import Chip from "../../../components/Chip/Chip";
import { WHITE300 } from "../../../common/constants/layout.constants";

const VestingCard = () => {
  //TODO: fix last border bottom
  const VESTING = [
    {
      date: "May 29, 2023",
      percentage: "15%",
    },
    {
      date: "Jun 29, 2023",
      percentage: "10%",
    },
    {
      date: "July 29, 2023",
      percentage: "20%",
    },
    {
      date: "September 29, 2023",
      percentage: "12%",
    },
  ];
  return (
    <DetailsCard title="Vesting Schedual">
      <div className="intro-deck__details-card">
        {VESTING.map((v, index) => (
          <div className="intro-deck__details-card-date" key={index}>
            <p>{v.date}</p>
            <p className="intro-deck__details-card-date--grey">
              {v.percentage}
            </p>
          </div>
        ))}
        <div className="intro-deck__details-card-chip">
          <Chip text={"+1 more schedule"} backgroundColor={WHITE300} />
        </div>
      </div>
    </DetailsCard>
  );
};

export default VestingCard;
