import React, { FC, useState } from "react";
import "./Staking.scss";
import MainButton from "../../components/MainButton/MainButton";
import StakingCard from "./StakingCard/StakingCard";
import { Sort, TabLabel } from "../../common/enums/common.enum";
import SortDropdown from "../../components/SortDropdown/SortDropdown";
import { STAKING_TABS } from "../../common/constants/navigation.constants";
import TabHeader from "../../components/TabHeader/TabHeader";
import NoData from "../../components/NoData/NoData";
import Pagination from "../../components/Pagination/Pagination";
import { ITEMS_PER_PAGE } from "../../common/constants/common.constants";
import { subsetArrayForPagination } from "../../utilities/helpers";
import ClubDetailsLayout from "../../components/ClubDetailsWrapper/ClubDetailsWrapper";

const Staking: FC<{}> = ({}) => {
  const [activeTab, setActiveTab] = useState(TabLabel.Funding);
  const [sort, toggleSort] = useState(false);
  const sortStakingOptions = [Sort.DateCreated, Sort.StakingAmount];
  const [currentPage, setCurrentPage] = useState(0);
  //ERASE LATER
  const STAKING = [1, 2, 3];

  function handlePageChange(selectedItem: { selected: number }) {
    const selectedPage = selectedItem.selected;
    setCurrentPage(selectedPage);
  }

  const sortStakingArray = (option: Sort) => {
    // switch (option) {
    //   case Sort.DateCreated:
    //     return STAKING.sort((d1, d2) =>
    //       Number(d1.dateCreated) < Number(d2.dateCreated) ? 1 : -1
    //     );
    //   case Sort.StakingAmount:
    //     return STAKING.sort((d1, d2) =>
    //       d1.stakingAmount > d2.stakingAmount ? 1 : -1
    //     );
    // }
  };

  const showStaking = (activeTab: TabLabel) => {
    switch (activeTab) {
      case TabLabel.Funding:
        return <StakingCard />;
      //  return Object.values(
      //    subsetArrayForPagination(STAKING, currentPage, ITEMS_PER_PAGE)
      //  ).map((p, index) => (
      //    <StakingCard
      //      proposal={p}
      //      key={index}
      //      toggleStakingModal={toggleStakingModal}
      //    />
      //  ));
      case TabLabel.Locked:
        return <StakingCard />;
      case TabLabel.History:
        return <StakingCard />;
    }
  };

  return (
    <ClubDetailsLayout>
      <div className="staking">
        <TabHeader
          tabs={STAKING_TABS}
          onClick={(tab: TabLabel) => {
            setActiveTab(tab);
          }}
          activeTab={activeTab}
        >
          <div className="staking-bar-flex">
            <MainButton
              type="button"
              light
              onClick={() => console.log("click")}
            >
              Sell Offer
            </MainButton>
            {/* {sortStakingArray.length > 0 && (
              <SortDropdown
                array={sortStakingOptions}
                sort={sort}
                toggleSort={() => toggleSort(!sort)}
                sortDeals={sortStakingArray}
              />
            )} */}
          </div>
        </TabHeader>
        <div className="staking-cards">
          {STAKING.length < 1 ? (
            <NoData
              message={"No Active Staking"}
              mainButtonText={"Create a Staking"}
              mainButtonOnClick={() => {}}
            />
          ) : (
            <Pagination
              totalPages={Math.ceil(STAKING.length / ITEMS_PER_PAGE)}
              handlePageChange={handlePageChange}
              currentPage={0}
            >
              {showStaking(activeTab)}
            </Pagination>
          )}
        </div>
      </div>
    </ClubDetailsLayout>
  );
};

export default Staking;
