import React, { FC } from "react";
import "./Tag.scss";
const Tag: FC<{
  label: string;
  isActive: boolean;
  value: string;
  handleClick: () => void;
}> = ({ label, isActive, value, handleClick }) => {
  return (
    <div
      onClick={handleClick}
      className={isActive ? "chip-v2 chip-v2--active" : "chip-v2"}
    >
      <p>{label}</p>
    </div>
  );
};

export default Tag;
