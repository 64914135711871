import React, { useEffect, useState } from "react";
import "./WalletDropdown.scss";
import solanaLogo from "../../assets/solanaLogo.svg";
import copyContent from "../../assets/content_copy.png";
import {
  getTrimmedPublicKey,
  handleCopyToClipboard,
} from "../../utilities/helpers";
import UserAccountSettingsModal from "../UserAccountModal/UserAccountSettingsModal";
import {
  useAnchorWallet,
  useConnection,
  useWallet,
} from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import DefaultExplorerMenu from "../DefaultExplorerMenu/DefaultExplorerMenu";
import { useLazyQuery } from "@apollo/client";
import { GET_MY_MEMBER_INFO } from "../../api/dashboard.api";

const WalletDropdown: React.FC<{ closeModal: () => void }> = ({
  closeModal,
}) => {
  const [userSettings, toggleUserSettings] = useState(false);
  const { visible, setVisible } = useWalletModal();
  const { disconnect, wallet } = useWallet();
  const userWallet = useAnchorWallet();

  const [getMemberInfo, memberInfo] = useLazyQuery(GET_MY_MEMBER_INFO, {
    variables: {
      address: userWallet?.publicKey.toString(),
    },
  });

  useEffect(() => {
    if (wallet) {
      getMemberInfo();
    }
  }, [wallet]);

  const handleDisconnect = async () => {
    if (wallet) {
      await disconnect();
      closeModal();
    }
  };

  const handleChangeWallet = async () => {
    if (wallet) {
      setVisible(true);
      closeModal();
    }
  };

  return (
    <div className="walletDropdown" onClick={closeModal}>
      <div
        className="walletDropdown__content"
        onClick={(e) => e.stopPropagation()}
      >
        {userSettings && (
          <UserAccountSettingsModal
            onClose={() => {
              toggleUserSettings(false);
              closeModal();
            }}
            member={memberInfo.data?.getMyMemberInfo}
          />
        )}
        <h2 className="walletDropdown__title">My wallets</h2>
        <div className="walletDropdown__wallets">
          <ul className="walletDropdown__wallets-list">
            <li className="walletDropdown__wallets-item">
              <div className="walletDropdown__wallets-description">
                <img src={solanaLogo} />

                <p className="walletDropdown__wallets-info">
                  <span>Solana</span>
                  {userWallet && getTrimmedPublicKey(userWallet?.publicKey)}
                </p>
              </div>
              <img
                src={copyContent}
                className="walletDropdown__copy-icon"
                onClick={() =>
                  handleCopyToClipboard(userWallet?.publicKey.toString())
                }
              />
            </li>
          </ul>
        </div>
        <div className="walletDropdown__options">
          <ul className="walletDropdown__options-list">
            <li
              className="walletDropdown__options-option"
              onClick={(e) => {
                e.stopPropagation();
                toggleUserSettings(true);
              }}
            >
              Account settings
            </li>
            <li
              className="walletDropdown__options-option"
              onClick={handleDisconnect}
            >
              Disconnect
            </li>
            <li
              className="walletDropdown__options-option"
              onClick={handleChangeWallet}
            >
              Change wallet
            </li>
          </ul>
        </div>
        <DefaultExplorerMenu
          defaultExplorer={memberInfo?.data?.getMyMemberInfo?.explorer}
        />
      </div>
    </div>
  );
};

export default WalletDropdown;
