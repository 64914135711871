import {
  TextField,
  Switch,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-track": {
    backgroundColor: "#9a9a9a",
    borderRadius: 26 / 2,
  },
  "&.Mui-focusVisible .MuiSwitch-thumb": {
    color: "#33cf4d",
    border: "6px solid #fff",
  },
  "& .MuiSwitch-switchBase": {
    margin: 2,
    padding: 0,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "var(--primary-color)",
        opacity: 1,
        border: 0,
      },

      "&.Mui-disabled": {
        color: "white",
        opacity: "0.4",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
}));

export const MaterialUITextField = styled(TextField)(({ theme }) => ({
  color: "#000",
  width: "100%",
  height: "56px",
  overflow: "hidden",
  textOverflow: "ellipsis",

  "& .MuiInputBase-root": {
    fontFamily: ["Manrope", "sans-serif"].join(","),
    backgroundColor: "white",
    border: "1px solid var(--grey-100)",
    borderRadius: "8px",
    fontSize: "16px",
    height: "56px",

    "&:hover": {
      border: "1px solid var(--grey-100)",
    },
    "& .Mui-disabled": {
      color: "black",
      "-webkit-text-fill-color": "black",
    },

    "&.MuiFilledInput-input": {
      backgroundColor: "var(--white-100)",

      "&:focus": {
        backgroundColor: "var(--white-100)",
        border: "1px solid var(--grey-100)",
      },

      "&:visited": {
        backgroundColor: "var(--white-100)",
      },
    },

    "&.MuiFilledInput-root": {
      // paddingLeft: "0px", //TODO@miliac: check if this is ok

      ":before": {
        border: "none !important",
      },
      ":after": {
        border: "none !important",
      },
      "&:hover": {
        border: "1px solid var(--grey-100)",
        backgroundColor: "var(--white-100)",
      },

      "&.Mui-disabled": {
        backgroundColor: "var(--white-100)",
      },
    },
    "&.MuiInput-root": {
      ":after": {
        border: "none",
      },
      ":before": {
        border: "none !important",
      },
    },
  },
  "& .MuiFormLabel-root": {
    fontFamily: ["Manrope", "sans-serif"].join(","),
    color: "var(--grey-200)",
  },

  "& .MuiInputLabel-shrink": {
    "&.Mui-focused": {
      color: "var(--grey-200)",
    },
  },
  "& .MuiInputLabel-filled": {
    "&.Mui-focused": {
      color: "var(--grey-200)",
    },
  },
}));

export const MaterialUIFormControl = styled(FormControl)(({ theme }) => ({
  color: "#000",
  width: "100%",
  "& .MuiFormLabel-root": {
    fontFamily: ["Manrope", "sans-serif"].join(","),
    color: "var(--grey-200)",
  },
  "& .MuiInputBase-root": {
    backgroundColor: "white",
    border: "1px solid var(--grey-100)",
    borderRadius: "8px",
    fontSize: "16px",
    height: "56px",

    "& .MuiFilledInput-input": {
      "&.Mui-disabled": {
        color: "black",
        "-webkit-text-fill-color": "black",
      },
    },
    "&.MuiFilledInput-root": {
      ":before": {
        border: "none !important",
      },
      ":after": {
        border: "none !important",
      },
      "&:hover": {
        border: "none",
        backgroundColor: "var(--white-100)",
      },

      "&.Mui-disabled": {
        backgroundColor: "var(--white-100)",
      },
    },
    "&.MuiInput-root": {
      ":after": {
        border: "none",
      },
      ":before": {
        border: "none !important",
      },
    },
  },

  "& .MuiInputLabel-shrink": {
    "&.Mui-focused": {
      color: "var(--grey-200)",
    },
  },
  "& .MuiInputLabel-filled": {
    "&.Mui-focused": {
      color: "var(--grey-200)",
    },
  },
}));

export const MaterialUIInputLabel = styled(InputLabel)(({ theme }) => ({}));

export const MaterialUISelect = styled(Select)(({ theme }) => ({
  fontFamily: ["Manrope", "sans-serif"].join(","),
  height: "56px",

  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid var(--grey-100) ",
    borderRadius: "8px",
  },

  "&.Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--grey-100)",
      borderRadius: "8px",
    },
  },

  "& .MuiOutlinedInput-input": {
    marginTop: "1em",
  },
}));

export const MaterialUIMenuItem = styled(MenuItem)(({ theme }) => ({
  fontFamily: ["Manrope", "sans-serif"].join(","),
}));

export const MaterialUIDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: "white",
    border: "1px solid var(--grey-100)",
    borderRadius: "8px",
    fontSize: "16px",
    height: "56px",
    color: "var(--grey-500)",
    fontFamily: ["Manrope", "sans-serif"].join(","),
  },

  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    border: "1px solid var(--grey-100)",
    borderRadius: "8px",
    fontSize: "16px",
    height: "56px",
    "&:hover": {
      border: "1px solid var(--grey-100)",
      backgroundColor: "var(--white-100)",
    },
    "&.Mui-focused": {
      backgroundColor: "var(--grey-100)",
    },
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

export const MaterialUIAutocomplete = styled(Autocomplete)(({ theme }) => ({
  color: "#000",
  width: "100%",

  "& .MuiInputBase-root": {
    backgroundColor: "white",
    border: "1px solid var(--grey-100)",
    borderRadius: "8px",
    fontSize: "16px",
    height: "56px",
    color: "var(--grey-500)",
    fontFamily: ["Manrope", "sans-serif"].join(","),
  },

  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    border: "1px solid var(--grey-100)",
    borderRadius: "8px",
    fontSize: "16px",
    height: "56px",
    "&:hover": {
      border: "1px solid var(--grey-100)",
      backgroundColor: "var(--white-100)",
    },
    "&.Mui-focused": {
      backgroundColor: "var(--grey-100)",
    },
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));
