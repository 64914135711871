import React, { FC } from "react";
import "./FormActions.scss";
import MainButton from "../MainButton/MainButton";

const FormActions: FC<{
  buttonText: string;
  cancelAction: () => void;
  buttonAction: () => void;
  cancelBtnText?: string;
  disabled?: boolean;
}> = ({ buttonText, cancelAction, buttonAction, cancelBtnText, disabled }) => {
  return (
    <div className="form-actions">
      <MainButton type="button" light onClick={cancelAction}>
        {cancelBtnText ? cancelBtnText : "Cancel"}
      </MainButton>
      <MainButton type="submit" onClick={buttonAction} disabled={disabled}>
        {buttonText}
      </MainButton>
    </div>
  );
};

export default FormActions;
