import React, { FC } from "react";
import checkCircle from "../../assets/check_circle.svg";
import "./RequiredField.scss";

const RequiredField: FC<{ label: string; disabled?: boolean }> = ({
  label,
  disabled,
}) => {
  return (
    <div className={`required-field ${disabled && "required-field--disabled"}`}>
      <p className="required-field__label">{label}</p>
      <img src={checkCircle} alt="Field icon" />
    </div>
  );
};

export default RequiredField;
