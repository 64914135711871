import { Keypair, PublicKey, TransactionInstruction } from "@solana/web3.js";
import { ClubAction, DefaultExplorer } from "../enums/clubs.enum";
import { NFTMarketOption } from "../enums/vault.enum";
import {
  NotificationTab,
  NotificationType,
  Sort,
  SortLabel,
} from "../enums/common.enum";
import { extend } from "dayjs";
import { ITokenUnlocking } from "./club.interface";

export interface IFormikField {
  name: string;
  value?: any;
  label?: string;
}

export interface ITokenUnlockingInput {
  date: string;
  tokenAmount: number;
  fundraiseAddress: string;
}

export interface IOption {
  value: number | string;
  label: string;
  isFixed?: boolean;
  img?: string;
}

export interface ISelectOption {
  value: string | number;
  title: string;
  description: string;
}

export class TransactionError extends Error {
  public txid: string;
  constructor(message: string, txid: string) {
    super(message);
    this.txid = txid;
  }
}

export interface IInstructionSet {
  instructions: TransactionInstruction[];
  description: string;
  partialSigner?: Keypair;
  makeMagicEdenaApiCall?: boolean;
}

export interface IDetailNftData {
  mintAddress: string;
  name: string;
  image: string;
  symbol: string;
  collection?: {
    family?: string;
    name: string;
    key?: string;
  };
  tokenAccount: string;
  description?: string;
  metadataAddress?: string;
  purchasePrice?: number;
  offerType?: NFTMarketOption;
}

export type ClubActionMap = {
  [key in keyof typeof ClubAction]: {
    [camelCase: string]: {};
  };
};

export interface IBasicNftInfo {
  mint: PublicKey;
  ownertokenAddress: PublicKey;
  metadataAddress: PublicKey;
}

export interface IBasicFilters {
  search?: string;
  sort?: string;
  page?: number;
  filter?: string;
}

export interface IInputTextProp {
  text: string;
  color?: string;
}
export interface ISortOption {
  label: SortLabel;
  value: Sort;
}

export interface INotification {
  userNotificationId: string;
  tab?: NotificationTab;
  proposalAddress?: string;
  hasRead: boolean;
  notificationType: NotificationType;
  message: string;
  clubAddress: string;
  createdAt: Date;
  updatedAt: Date;
  userPubKey: string;
  treasuryAddress: string;
  imageUrl?: string;
}
