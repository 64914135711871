import InputField from "../../../../components/InputFields/InputField";
import "./SocialNetworkInput.scss";
import cancelIcon from "../../../../assets/cancel.svg";

export interface ISocialNetworkProps {
  img: any;
  name: string;
  label: string;
  labelTitle: string;
  type: any;
  placeholder: string;
  onCancel?: () => void;
}

const SocialNetworkInput: React.FC<ISocialNetworkProps> = (props) => {
  return (
    <div className="social-network">
      <div className="social-network__img">
        <img
          src={props.img}
          alt="social network icon"
          className="social-network__icon"
        />
      </div>
      <div>
        {props.onCancel && (
          <img
            src={cancelIcon}
            alt="Cancel icon"
            onClick={props.onCancel}
            className="social-network__cancel-icon"
          />
        )}
      </div>

      <InputField
        name={props.name}
        label={props.label}
        labelTitle={props.label}
        type={props.type}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default SocialNetworkInput;
