import {
  Commitment,
  Connection,
  SignatureStatus,
  SimulatedTransactionResponse,
  TransactionSignature,
  PublicKey,
  VersionedTransaction,
  Keypair,
} from "@solana/web3.js";
import {
  DEFAULT_TIMEOUT,
  INVALID_ACCOUNT_STATE_ERROR,
} from "../common/constants/common.constants";
import { RPC_CONNECTION } from "./utils";
import {
  IInstructionSet,
  TransactionError,
} from "../common/interfaces/common.interface";
import {
  FetchingDataState,
  SequenceType,
  TransactionState,
} from "../common/enums/common.enum";
import useTransactionStore, {
  ITransactionInfo,
} from "../state/transactionStore";
import { AnchorWallet, WalletContextState } from "@solana/wallet-adapter-react";
import { mapSolanaErrors } from "./program-helpers";
import { TransactionMessage } from "@solana/web3.js";

export const sleep = (ttl: number) =>
  new Promise((resolve) => setTimeout(() => resolve(true), ttl));

export function getUnixTs() {
  return new Date().getTime() / 1000;
}

/**
 * Awaits for confirmation of transaction signature
 *
 * @param txid
 * @param timeout
 * @param connection
 * @param commitment
 * @param queryStatus
 * @returns
 */
async function awaitTransactionSignatureConfirmation(
  txid: TransactionSignature,
  timeout: number,
  connection: Connection,
  commitment: Commitment = "confirmed",
  queryStatus = false
) {
  let done = false;
  let status: SignatureStatus | null = {
    slot: 0,
    confirmations: 0,
    err: null,
  };
  let subId = 0;
  await new Promise((resolve, reject) => {
    const fn = async () => {
      setTimeout(() => {
        if (done) {
          return;
        }
        done = true;
        reject({ timeout: true });
      }, timeout);
      try {
        subId = connection.onSignature(
          txid,
          (result, context) => {
            done = true;
            status = {
              err: result.err,
              slot: context.slot,
              confirmations: 0,
            };
            if (result.err) {
              console.log("Rejected via websocket", result.err);
              reject(result.err);
            } else {
              console.log("Resolved via websocket", result);
              resolve(result);
            }
          },
          commitment
        );
      } catch (e) {
        done = true;
        console.error("WS error in setup", txid, e);
      }
      while (!done && queryStatus) {
        // eslint-disable-next-line no-loop-func
        const fn = async () => {
          try {
            const signatureStatuses = await connection.getSignatureStatuses([
              txid,
            ]);
            status = signatureStatuses && signatureStatuses.value[0];
            if (!done) {
              if (!status) {
                console.log("REST null result for", txid, status);
              } else if (status.err) {
                console.log("REST error for", txid, status);
                done = true;
                reject(status.err);
              } else if (!status.confirmations) {
                console.log("REST no confirmations for", txid, status);
              } else {
                console.log("REST confirmation for", txid, status);
                if (status.confirmationStatus === "confirmed") {
                  done = true;
                  resolve(status);
                }
              }
            }
          } catch (e) {
            if (!done) {
              console.log("REST connection error: txid", txid, e);
            }
            throw e;
          }
        };
        await fn();
        await sleep(2000);
      }
    };
    fn();
  })
    .catch((err) => {
      if (err.timeout && status) {
        status.err = { timeout: true };
      }

      connection.removeSignatureListener(subId);
      throw err;
    })
    .then((_) => {
      connection.removeSignatureListener(subId);
    });
  done = true;
  return status;
}

/**
 * Sends signed transaction
 * @param param0
 * @returns
 */
export async function sendSignedTransaction({
  signedTransaction,
  connection,
  timeout = DEFAULT_TIMEOUT,
  errorMessage,
}: {
  signedTransaction: VersionedTransaction;
  connection: Connection;
  sendingMessage?: string;
  sentMessage?: string;
  successMessage?: string;
  errorMessage?: string;
  timeout?: number;
}): Promise<{ txid: string; slot: number }> {
  const rawTransaction = signedTransaction.serialize();
  const startTime = getUnixTs();
  let slot = 0;
  const txid: TransactionSignature = await connection.sendRawTransaction(
    rawTransaction,
    {
      skipPreflight: true,
      preflightCommitment: "confirmed",
    }
  );
  console.log(txid);
  await RPC_CONNECTION.confirmTransaction(txid);
  if (errorMessage?.startsWith(INVALID_ACCOUNT_STATE_ERROR)) {
    errorMessage = "Failed to buy NFT on Magic Eden.Please try again!";
  }

  console.log("Started awaiting confirmation for", txid);

  let done = false;
  (async () => {
    while (!done && getUnixTs() - startTime < timeout) {
      connection.sendRawTransaction(rawTransaction, {
        skipPreflight: true,
      });

      await sleep(500);
    }
  })();
  try {
    const confirmation = await awaitTransactionSignatureConfirmation(
      txid,
      timeout,
      connection,
      "confirmed",
      true
    );

    if (confirmation.err) {
      console.error(confirmation.err);
      throw new Error("Transaction failed: Custom instruction error");
    }

    slot = confirmation?.slot || 0;
  } catch (error) {
    if (error instanceof Object && error.hasOwnProperty("timeout")) {
      throw new Error("Timed out awaiting confirmation on transaction");
    }
    let simulateResult: SimulatedTransactionResponse | null = null;
    try {
      //TODO@milica@guta: check new version with VersionedTransaction
      simulateResult = (
        await RPC_CONNECTION.simulateTransaction(
          signedTransaction as VersionedTransaction
        )
      ).value;
    } catch (e) {
      //
    }
    mapSolanaErrors(simulateResult, errorMessage ?? "Transaction failed", txid);

    throw new TransactionError("Transaction failed", txid);
  } finally {
    done = true;
  }

  console.log("Latency", txid, getUnixTs() - startTime);
  return { txid, slot };
}

/**
 * Sends multiple transactions at once
 *
 * @param connection
 * @param wallet
 * @param instructionSet
 * @param isClubCreation
 * @param clubInfo
 * @param sequenceType
 * @param commitment
 * @param successCallback
 * @param failCallback
 * @param block
 * @returns
 */
export const sendTransactions = async (
  connection: Connection,
  wallet: AnchorWallet,
  instructionSet: IInstructionSet[],
  sequenceType: SequenceType = SequenceType.Sequential,
  isFetchingData?: boolean,
  isCreatingAndExecutingProposal?: boolean,
  isClubCreation?: boolean,
  clubInfo?: { values: any; clubData: PublicKey; realmAddress: PublicKey },
  callBackTransactionNumber?: number,
  goToClubDetails?: (clubAddress: string) => void,
  isRepeating?: boolean,
  commitment: Commitment = "confirmed",
  successCallback: (txid: string, ind: number) => void = (_txid, _ind) => null,
  failCallback: (reason: string, ind: number) => boolean = (_txid, _ind) =>
    false,
  block?: {
    blockhash: string;
    lastValidBlockHeight: number;
  }
) => {
  const {
    startProcessing,
    updateCurrentTransaction,
    updateProcessedTransactions,
    transactions,
    closeTransactionProcess,
    updateFetchingData,
  } = useTransactionStore.getState();
  try {
    let indexOfMagicEdenApiBuyCall = -1;
    let index;
    if (!wallet.publicKey) throw new Error("Wallet not connected!");
    const accountInfo = await RPC_CONNECTION.getParsedAccountInfo(
      wallet.publicKey
    );
    if (!accountInfo.value) throw new Error("You do not have enough SOL.");
    const unsignedTxns: VersionedTransaction[] = [];
    let signedTxns: VersionedTransaction[] = [];
    if (!block) {
      block = await connection.getLatestBlockhash(commitment);
    }

    const transactionsForStore: ITransactionInfo[] = [];
    let transactionCount = callBackTransactionNumber ?? 0;

    for (let i = 0; i < instructionSet.length; i++) {
      const instructions = instructionSet[i];
      if (instructions.makeMagicEdenaApiCall) {
        indexOfMagicEdenApiBuyCall = i;
      }

      if (instructions.instructions.length === 0) {
        transactionsForStore.push({
          number: i + 1,
          transactionState: TransactionState.Pending,
          txid: null,
          description: instructionSet[i].description,
        });
        continue;
      }

      const txMessage = new TransactionMessage({
        instructions: instructions.instructions,
        payerKey: wallet.publicKey!,
        recentBlockhash: (await RPC_CONNECTION.getLatestBlockhash()).blockhash,
      }).compileToV0Message();
      const transaction = new VersionedTransaction(txMessage);

      if (instructions.partialSigner) {
        transaction.sign([instructions.partialSigner]);
      }

      unsignedTxns.push(transaction);

      if (!callBackTransactionNumber) {
        transactionsForStore.push({
          number: i + 1,
          transactionState: TransactionState.Pending,
          txid: null,
          description: instructionSet[i].description,
        });
      } else {
        updateCurrentTransaction({
          number: transactionCount + 1,
          transactionState: TransactionState.Pending,
          txid: null,
          description: instructions.description,
        });
      }
      transactionCount++;
    }

    !callBackTransactionNumber &&
      startProcessing(transactionsForStore, isFetchingData);
    try {
      signedTxns = await wallet.signAllTransactions(unsignedTxns);
    } catch (error) {
      closeTransactionProcess();
      console.log(error);
      throw error;
    }

    //Rewrite this
    // if (isClubCreation) {
    //   const serializedTx = signedTxns[0].serialize();
    //   const clubDto = mapFormValuesToDto(
    //     clubInfo!.values,
    //     clubInfo!.clubData.toString(),
    //     clubInfo!.realmAddress.toString(),
    //     wallet.publicKey.toString(),
    //     encode(serializedTx)
    //   );

    //   await createClub(clubDto);
    // }
    const pendingTxns: { txid: string; slot: number }[] = [];

    const breakEarlyObject = { breakEarly: false };
    transactionCount = callBackTransactionNumber ?? 0;
    for (let i = 0; i < signedTxns.length; i++) {
      try {
        const processedTx = {
          number: transactionCount + 1,
          transactionState: TransactionState.Loading,
          txid: null,
          description: instructionSet[i].description,
        };

        if (signedTxns.length === 0) {
          updateCurrentTransaction(processedTx);
        }

        updateCurrentTransaction(processedTx);

        updateProcessedTransactions(processedTx);

        if (isCreatingAndExecutingProposal && i === signedTxns.length - 1) {
          //Wait 2 seconds before executing proposal because of hold up time
          await sleep(2000);
        }

        const signedTxnPromise = await sendSignedTransaction({
          connection,
          signedTransaction: signedTxns[i],
        });

        const successfullTx = {
          number: transactionCount + 1,
          transactionState: TransactionState.Succeeded,
          txid: signedTxnPromise.txid,
          description: instructionSet[i].description,
        };
        updateCurrentTransaction(successfullTx);

        pendingTxns.push(signedTxnPromise);
        transactionCount++;
      } catch (error: any) {
        console.log(error);
        const instructionsSetNew = instructionSet.slice(
          i,
          instructionSet.length
        );

        updateCurrentTransaction(
          {
            number: transactionCount + 1,
            transactionState: TransactionState.Failed,
            txid: error?.txid,
            description: instructionSet[i].description,
          },
          async () => {
            try {
              await sendTransactions(
                connection,
                wallet,
                instructionsSetNew,
                sequenceType,
                isFetchingData,
                isCreatingAndExecutingProposal,
                isClubCreation,
                clubInfo,
                i,
                goToClubDetails,
                true
              );
            } catch (error) {
              console.log(error);
            }
          }
        );
        throw error;
      }

      // eslint-disable-next-line eqeqeq
    }

    if (
      isClubCreation &&
      clubInfo &&
      callBackTransactionNumber !== undefined &&
      goToClubDetails
    ) {
      goToClubDetails(clubInfo.clubData.toString());
    }

    if (isFetchingData) {
      updateFetchingData(FetchingDataState.Loading);
    }
    return signedTxns;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

/**
 * Sends one transaction
 * @param transaction
 * @param wallet
 * @param signers
 * @param connection
 * @param sendingMessage
 * @param errorMessage
 * @param timeout
 * @returns
 */
export async function sendTransaction({
  transaction,
  wallet,
  signers = [],
  connection,
  sendingMessage = "Sending transaction...",
  errorMessage = "Transaction failed",
  timeout = DEFAULT_TIMEOUT,
}: {
  transaction: VersionedTransaction;
  wallet: AnchorWallet | WalletContextState;
  signers?: Array<Keypair>;
  connection: Connection;
  sendingMessage?: string;
  errorMessage?: string;
  timeout?: number;
}) {
  if (!wallet.publicKey) throw new Error("Wallet not connected!");
  const accountInfo = await RPC_CONNECTION.getParsedAccountInfo(
    wallet.publicKey
  );
  if (!accountInfo.value) throw new Error("You do not have enough SOL.");

  return await sendSignedTransaction({
    signedTransaction: transaction,
    connection,
    sendingMessage,
    errorMessage,
    timeout,
  });
}
