import React, { FC, useMemo } from "react";
import "./ProposalModalDetails.scss";
import linkIcon from "../../../assets/link.png";
import { ProposalTypeProgram } from "../../../common/enums/proposal.enum";
import {
  getAmountWithDecimalsForCurrency,
  getProposalTypeTitle,
} from "../../../utilities/helpers";
import {
  IAddSellPermissionProposal,
  IFundraiseProposalMetadata,
  ITransferProposalMetadata,
  IUpdateGovernanceConfigProposalMetadata,
  IUpdateRoleConfigProposalMetadata,
  IWithdrawalProposalMetadata,
  ProposalDetailsType,
} from "../../../common/interfaces/proposal.interface";
import { clubStore } from "../../../state/clubStore";
import { parseSecondsToDays } from "../../../utilities/helpers";

const ProposalModalDetails: FC<{
  proposalType: ProposalTypeProgram;
  approvalVotePercentage: number;
  maxVotingTime: number;
  link?: string;
  proposalDetails: ProposalDetailsType;
}> = ({
  proposalType,
  approvalVotePercentage,
  maxVotingTime,
  link,
  proposalDetails,
}) => {
  const { activeTreasury } = clubStore();

  const additionalInfo = useMemo(() => {
    switch (proposalType) {
      case ProposalTypeProgram.TransferFunds: {
        const transferDetails: ITransferProposalMetadata =
          proposalDetails as ITransferProposalMetadata;
        return (
          <>
            <div className="proposal-modal-details__additional-info">
              <p className="proposal-modal-details__description">
                Transfer amount
              </p>
              <span>
                {activeTreasury &&
                  getAmountWithDecimalsForCurrency(
                    activeTreasury?.currencyDecimals,
                    transferDetails?.amountToInvest
                  )}{" "}
                {transferDetails?.mintSymbol}
              </span>
            </div>
            <div className="proposal-modal-details__additional-info">
              <p className="proposal-modal-details__description">Destination</p>
              <span>{transferDetails.destination}</span>
            </div>
          </>
        );
      }
      case ProposalTypeProgram.Withdrawal: {
        const withdrawalDetails: IWithdrawalProposalMetadata =
          proposalDetails as IWithdrawalProposalMetadata;
        return (
          <>
            <div className="proposal-modal-details__additional-info">
              <p className="proposal-modal-details__description">
                Withdrawal amount
              </p>
              <span>
                {getAmountWithDecimalsForCurrency(
                  withdrawalDetails?.mintDecimals,
                  withdrawalDetails?.withdrawalAmount
                )}{" "}
                {withdrawalDetails?.mintSymbol}
              </span>
            </div>
            {withdrawalDetails?.withdrawalMembersInfo && (
              <div className="proposal-modal-details__additional-info">
                <p className="proposal-modal-details__description">
                  Your can claim
                </p>
                <span>
                  {getAmountWithDecimalsForCurrency(
                    withdrawalDetails?.mintDecimals,
                    withdrawalDetails?.withdrawalMembersInfo.withdrawAmount
                  )}
                </span>
              </div>
            )}
          </>
        );
      }
      case ProposalTypeProgram.CreateFundraise:
        const fundraiseDetails: IFundraiseProposalMetadata =
          proposalDetails as IFundraiseProposalMetadata;
        return (
          <div className="proposal-modal-details__additional-info">
            <p className="proposal-modal-details__description">
              Fundraise cap amount
            </p>
            <span>
              {getAmountWithDecimalsForCurrency(
                activeTreasury?.currencyDecimals,
                fundraiseDetails?.capAmount
              )}{" "}
              {activeTreasury?.currencySymbol}
            </span>
          </div>
        );

      case ProposalTypeProgram.UpdateGovernanceConfig:
        const changeDetails: IUpdateGovernanceConfigProposalMetadata =
          proposalDetails as IUpdateGovernanceConfigProposalMetadata;
        return (
          <>
            <div className="proposal-modal-details__additional-info">
              <p className="proposal-modal-details__description">Governance</p>
              <span>{changeDetails.governanceType}</span>
            </div>

            <div className="proposal-modal-details__additional-info">
              <p className="proposal-modal-details__description">New quorum</p>
              <span>
                {changeDetails.newQuorum} {"%"}
              </span>
            </div>

            <div className="proposal-modal-details__additional-info">
              <p className="proposal-modal-details__description">
                New voting time
              </p>
              <span>
                {parseSecondsToDays(changeDetails.newVotingTime)?.toFixed(0)}{" "}
                days
              </span>
            </div>
          </>
        );

      case ProposalTypeProgram.UpdateRoleConfig:
        const roleChange: IUpdateRoleConfigProposalMetadata =
          proposalDetails as IUpdateRoleConfigProposalMetadata;
        return (
          <>
            <div className="proposal-modal-details__additional-info">
              <p className="proposal-modal-details__description">
                Roles configuration
              </p>
              <div className="proposal-modal-details__roles">
                {roleChange.roleConfigData &&
                  roleChange.roleConfigData.map((item) => (
                    <ul className="proposal-modal-details__roles-list">
                      <li className="proposal-modal-details__roles-item">
                        {item.role}:{item.updateWeight}
                      </li>
                    </ul>
                  ))}
              </div>
            </div>
          </>
        );

      case ProposalTypeProgram.AddSellPermission:
        const sellPermission: IAddSellPermissionProposal =
          proposalDetails as IAddSellPermissionProposal;
        return (
          <>
            <div className="proposal-modal-details__additional-info">
              <p className="proposal-modal-details__description">
                Trade approval configuration from:
              </p>
              <span>
                {getAmountWithDecimalsForCurrency(
                  activeTreasury?.currencyDecimals,
                  sellPermission.sellPermissionData.from
                )}{" "}
                {activeTreasury?.currencySymbol}
              </span>
            </div>

            <div className="proposal-modal-details__additional-info">
              <p className="proposal-modal-details__description">
                Trade approval configuration to:
              </p>
              <span>
                {getAmountWithDecimalsForCurrency(
                  activeTreasury?.currencyDecimals,
                  sellPermission.sellPermissionData.to
                )}{" "}
                {activeTreasury?.currencySymbol}
              </span>
            </div>

            <div className="proposal-modal-details__additional-info">
              <p className="proposal-modal-details__description">
                Trade approval configuration quorum:
              </p>
              <span>
                {sellPermission.sellPermissionData.quorumMinimum} {"%"}
              </span>
            </div>
          </>
        );
    }
  }, [proposalType, proposalDetails]);
  return (
    <div className="proposal-modal-details">
      <div className="proposal-modal-details__additional-info">
        <p className="proposal-modal-details__description">Proposal type</p>
        <span>{getProposalTypeTitle(proposalType)}</span>
      </div>
      <div className="proposal-modal-details__additional-info">
        <p className="proposal-modal-details__description">
          Approval vote percentage
        </p>
        <span>{approvalVotePercentage}%</span>
      </div>
      <div className="proposal-modal-details__additional-info">
        <p className="proposal-modal-details__description">Max voting time</p>
        <span>{parseSecondsToDays(maxVotingTime)?.toFixed(0)} days</span>
      </div>
      {link && (
        <div className="proposal-modal-details__additional-info">
          <p className="proposal-modal-details__description"> Link</p>
          <span className="proposal-modal-details__link">
            <img src={linkIcon} alt="Paperclip" />
            <a href={link} target="_blank">
              {link}
            </a>
          </span>
        </div>
      )}
      {additionalInfo}
    </div>
  );
};

export default ProposalModalDetails;
