import { AnchorWallet } from "@solana/wallet-adapter-react";
import {
  IGovernance,
  ISellPermission,
  ITreasuryData,
} from "../../common/interfaces/club.interface";
import {
  ChangeGovernanceTypeEnum,
  ProposalTypeProgram,
} from "../../common/enums/proposal.enum";
import { PublicKey } from "@metaplex-foundation/js";
import {
  ICreateProposalInfo,
  IProposal,
  IProposalArguments,
  IProposalInstructionAccount,
} from "../../common/interfaces/proposal.interface";
import {
  ClubAction,
  TreasuryAction,
  UpdateVWActionType,
} from "../../common/enums/clubs.enum";
import {
  escrowSeed,
  financialRecordSeed,
  fundraiseCfgSeed,
  governanceSeed,
  maxVoterWeightSeed,
  nftVoteRecordSeed,
  offerSeed,
  profitSeed,
  proposalMetadataSeed,
  sellPermissionGovernanceSeed,
  tokenLedgerSeed,
  unqClubSeed,
  voterWeightSeed,
  withdrawalDataSeed,
  withdrawalRecordSeed,
  withdrawalSeed,
} from "../../common/constants/seeds.constants";
import {
  RPC_CONNECTION,
  UNQ_SPL_GOVERNANCE_PROGRAM_ID,
  escrowProgramFactory,
  programFactory,
} from "../utils";
import {
  AccountMeta,
  ComputeBudgetProgram,
  SYSVAR_RENT_PUBKEY,
  SystemProgram,
  TransactionInstruction,
} from "@solana/web3.js";
import { updateVoterWeight } from "./clubs";
import {
  getGovernanceForGovernedAccount,
  getGovernedAccountSeeds,
  getOrCreateATAOwnedBySpecifiedAccount,
  parseEnum,
  returnAccountsAndSignerIndexes,
} from "../program-helpers";
import * as SplGovernance from "@solana/spl-governance";
import { BN } from "@project-serum/anchor";
import { NATIVE_MINT, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { IInstructionSet } from "../../common/interfaces/common.interface";
import { sendTransactions } from "../sendTransactions";
import { INFTForVote } from "../../common/interfaces/nft.interface";
import {
  IGovernanceConfig,
  IManageVotingPower,
} from "../../common/interfaces/form.interface";
import { hash } from "@project-serum/anchor/dist/cjs/utils/sha256";
import {
  ADD_SELL_PERMISSION_DISCRIMANOTOR,
  FUNDRAISE_PROPOSAL_DISCRIMINATOR,
  UPDATE_ROLE_CONFIG_DISCRIMINATOR,
} from "../../common/constants/discriminator.constants";
import {
  AddSellPermissionData,
  SellPermisionDto,
  UpdateGovernanceConfigInput,
  UpdateRoleConfigInput,
} from "../../common/dtos/club.dto";
import { serialize } from "@dao-xyz/borsh";
import { CHANGE_GOV_CONFIG_DISCRIMATOR } from "../../common/constants/discriminator.constants";
import { SequenceType } from "../../common/enums/common.enum";
import { act } from "@testing-library/react";
import { EMPTY_STRING } from "../../common/constants/common.constants";

/**
 * Dynamic method for create different types of proposals (Create proposal, insert transaction and sign off proposal)
 * @param proposalInfo
 * @param creator
 * @param creatorMemberAddress
 * @param activeTreasury
 * @param communityMint
 * @param specificProposalArguments
 */
export const createAndPrepareProposal = async (
  proposalInfo: ICreateProposalInfo,
  creator: AnchorWallet,
  creatorMemberAddress: string,
  activeTreasury: ITreasuryData,
  communityMint: string,
  specificProposalArguments?: IProposalArguments
) => {
  const instructionSet: IInstructionSet[] = [];
  try {
    const {
      createProposalIx,
      proposalAddress,
      proposalMetadataAddress,
      tokenOwnerRecord,
      voterWeightAddress,
      governance,
    } = await createProposal(
      creator,
      activeTreasury,
      creatorMemberAddress,
      communityMint,
      proposalInfo
    );
    instructionSet.push({
      description: "Create proposal",
      instructions: createProposalIx,
    });

    const insertRemainingAccounts: AccountMeta[] = [];
    const signerIndexesForInsert: number[] = [];
    let insertIx: Buffer | undefined = undefined;
    switch (proposalInfo.proposalType) {
      case ProposalTypeProgram.TransferFunds: {
        if (
          !specificProposalArguments ||
          !specificProposalArguments.transferAmount ||
          !specificProposalArguments.destination ||
          !specificProposalArguments.transferMint ||
          !specificProposalArguments.treasuryToken
        ) {
          throw new Error("Missing proposal data");
        }
        console.log(specificProposalArguments);
        const { accounts, signerIndexes, transferFundsIx, ataInsstruction } =
          await getTransferFundsIx(
            new PublicKey(activeTreasury.treasuryAddress),
            proposalAddress,
            specificProposalArguments.transferAmount,
            specificProposalArguments.destination,
            specificProposalArguments.transferMint,
            creator,
            specificProposalArguments.treasuryToken
          );
        signerIndexes.forEach((item) => signerIndexesForInsert.push(item));
        accounts.forEach((item) => insertRemainingAccounts.push(item));
        insertIx = transferFundsIx.data;
        if (ataInsstruction) {
          instructionSet.push({
            description: "Create ATA for destination",
            instructions: [ataInsstruction],
          });
        }
        break;
      }
      case ProposalTypeProgram.Discussion: {
        break;
      }
      case ProposalTypeProgram.Withdrawal: {
        if (
          !specificProposalArguments ||
          !specificProposalArguments.withdrawalAmount ||
          !specificProposalArguments.withdrawalMint ||
          !specificProposalArguments.treasuryToken
        ) {
          throw new Error("Missing proposal data");
        }
        const { initializeWithdrawalIx, accounts, signerIndexes } =
          await getWithdrawalIx(
            new PublicKey(activeTreasury.treasuryDataAddress),
            new PublicKey(activeTreasury.realmAddress),
            new PublicKey(activeTreasury.treasuryAddress),
            new PublicKey(activeTreasury.clubDataAddress),
            proposalAddress,
            specificProposalArguments.withdrawalAmount,
            specificProposalArguments.withdrawalMint,
            proposalMetadataAddress,
            specificProposalArguments.treasuryToken
          );
        signerIndexes.forEach((item) => signerIndexesForInsert.push(item));
        accounts.forEach((item) => insertRemainingAccounts.push(item));
        insertIx = initializeWithdrawalIx.data;
        break;
      }
      case ProposalTypeProgram.CreateFundraise: {
        if (
          !specificProposalArguments ||
          !specificProposalArguments.fundraiseAmount
        ) {
          throw new Error("Missing proposal data");
        }
        const { accounts, instructionData } = await getFundraiseProposalIx(
          specificProposalArguments.fundraiseAmount,
          activeTreasury
        );
        accounts.forEach((item) => insertRemainingAccounts.push(item));
        insertIx = instructionData;
        break;
      }
      case ProposalTypeProgram.UpdateRoleConfig: {
        if (
          !specificProposalArguments ||
          !specificProposalArguments.roleVotingConfig
        ) {
          throw new Error("Missing proposal data");
        }
        const { accounts, instructionData } = await getUpdateRoleConfigIx(
          specificProposalArguments.roleVotingConfig,
          new PublicKey(activeTreasury.realmAddress)
        );
        insertIx = instructionData;
        accounts.forEach((item) => insertRemainingAccounts.push(item));
        break;
      }
      case ProposalTypeProgram.UpdateGovernanceConfig: {
        if (
          !specificProposalArguments ||
          !specificProposalArguments.governanceConfig ||
          !specificProposalArguments.changeGovernanceType
        ) {
          throw new Error("Missing proposal data");
        }

        const { accounts, instructionData } = await getChangeGovConfigIx(
          specificProposalArguments.governanceConfig,
          specificProposalArguments.changeGovernanceType,
          activeTreasury
        );
        insertIx = instructionData;
        accounts.forEach((item) => insertRemainingAccounts.push(item));

        break;
      }
      case ProposalTypeProgram.AddSellPermission: {
        if (
          !specificProposalArguments ||
          !specificProposalArguments.addSellPermission
        ) {
          throw new Error("Missing proposal data");
        }

        const { accounts, instructionData } = await getAddSellPermissionIx(
          specificProposalArguments.addSellPermission.from,
          specificProposalArguments.addSellPermission.to,
          specificProposalArguments.addSellPermission.quorum,
          specificProposalArguments.addSellPermission.maxVotingTime,
          activeTreasury,
          new PublicKey(communityMint),
          creator.publicKey
        );
        insertIx = instructionData;
        accounts.forEach((item) => insertRemainingAccounts.push(item));
        break;
      }
      default:
        throw new Error("Proposal type not found");
    }

    const insertAndSignOffIxs: TransactionInstruction[] = [];
    if (insertIx) {
      const insertProposalIx = await insertProposalTransaction(
        insertIx,
        signerIndexesForInsert,
        insertRemainingAccounts,
        new PublicKey(governance.address),
        proposalAddress,
        proposalMetadataAddress,
        new PublicKey(activeTreasury.treasuryDataAddress),
        creator,
        tokenOwnerRecord,
        new PublicKey(communityMint)
      );
      insertAndSignOffIxs.push(insertProposalIx);
      //TODO@milica: check if we always can merge insert and signOff
      // instructionSet.push({
      //   description: "Insert proposal transaction",
      //   instructions: [insertProposalIx],
      // });
    }
    const signOffIxs = await signOffProposal(
      proposalAddress,
      new PublicKey(activeTreasury.realmAddress),
      new PublicKey(governance.address),
      voterWeightAddress,
      tokenOwnerRecord,
      creator.publicKey
    );
    insertAndSignOffIxs.push(...signOffIxs);
    instructionSet.push({
      description: "Insert ix and sign off proposal",
      instructions: insertAndSignOffIxs,
    });

    await sendTransactions(
      RPC_CONNECTION,
      creator,
      instructionSet,
      SequenceType.Sequential,
      true
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

/**
 * Prepare create proposal ix for createAndPrepareProposal method
 * @param payer
 * @param activeTreasury
 * @param creatorMemberAddress
 * @param communityToken
 * @param proposalInfo
 * @returns
 */
const createProposal = async (
  payer: AnchorWallet,
  activeTreasury: ITreasuryData,
  creatorMemberAddress: string,
  communityToken: string,
  proposalInfo: ICreateProposalInfo
): Promise<{
  createProposalIx: TransactionInstruction[];
  proposalAddress: PublicKey;
  proposalMetadataAddress: PublicKey;
  voterWeightAddress: PublicKey;
  tokenOwnerRecord: PublicKey;
  governance: IGovernance;
}> => {
  try {
    let governance: IGovernance;
    let action: TreasuryAction;
    let spGovernance: ISellPermission | undefined = undefined;

    const createProposalIxs: TransactionInstruction[] = [];

    const program = programFactory();

    if (proposalInfo.amount !== undefined) {
      spGovernance = activeTreasury.sellPermission.find(
        (item) =>
          proposalInfo.amount &&
          item.from <= proposalInfo.amount &&
          item.to > proposalInfo.amount
      );
    }

    switch (proposalInfo.proposalType) {
      case ProposalTypeProgram.BuyP2P:
      case ProposalTypeProgram.SellP2P:
        governance = spGovernance
          ? spGovernance.governance
          : activeTreasury.treasuryGovernance;
        action = TreasuryAction.CreateP2PProposal;
        break;

      case ProposalTypeProgram.BuySolsea:
      case ProposalTypeProgram.SellSolsea:
        governance = spGovernance
          ? spGovernance.governance
          : activeTreasury.treasuryGovernance;
        action = TreasuryAction.CreateSolseaProposal;
        break;

      case ProposalTypeProgram.BuyNowMagicEden:
      case ProposalTypeProgram.SellMagicEden:
        governance = spGovernance
          ? spGovernance.governance
          : activeTreasury.treasuryGovernance;
        action = TreasuryAction.CreateMeProposal;
        break;

      case ProposalTypeProgram.TransferFunds:
        governance =
          activeTreasury.transferGovernance ??
          activeTreasury.treasuryGovernance;
        action = TreasuryAction.CreateTransferProposal;
        break;

      case ProposalTypeProgram.Withdrawal:
        governance =
          activeTreasury.withdrawalGovernance ??
          activeTreasury.treasuryGovernance;
        action = TreasuryAction.CreateWithdrawalProposal;
        break;

      case ProposalTypeProgram.UpdateRoleConfig:
        governance = activeTreasury.treasuryGovernance;
        action = TreasuryAction.UpdateRoleConfig;
        break;
      case ProposalTypeProgram.UpdateGovernanceConfig:
        governance =
          activeTreasury.changeConfigGovernance ??
          activeTreasury.treasuryGovernance;
        action = TreasuryAction.UpdateGovernanceConfig;
        break;
      case ProposalTypeProgram.CreateFundraise:
        governance = activeTreasury.treasuryGovernance;
        action = TreasuryAction.Fundraise;
        break;
      default:
        governance = activeTreasury.treasuryGovernance;
        action = TreasuryAction.CreateDiscussionProposal;
        break;
    }

    let proposalIndexBuffer = Buffer.alloc(4);
    proposalIndexBuffer.writeInt32LE(governance.proposalCount, 0);
    const [proposalAddress] = PublicKey.findProgramAddressSync(
      [
        governanceSeed,
        new PublicKey(governance.address).toBuffer(),
        new PublicKey(communityToken).toBuffer(),
        proposalIndexBuffer,
      ],
      UNQ_SPL_GOVERNANCE_PROGRAM_ID
    );

    const [proposalMetadata] = PublicKey.findProgramAddressSync(
      [unqClubSeed, proposalAddress.toBuffer(), proposalMetadataSeed],
      program.programId
    );

    const [maxVoterWeightAddress] = PublicKey.findProgramAddressSync(
      [unqClubSeed, proposalAddress.toBuffer(), maxVoterWeightSeed],
      program.programId
    );

    const uvwForProposalRemainingAccounts: AccountMeta[] = [
      {
        pubkey: proposalAddress,
        isSigner: false,
        isWritable: true,
      },
      {
        pubkey: maxVoterWeightAddress,
        isSigner: false,
        isWritable: true,
      },
    ];

    const { voterWeightAddress, voterWeightIx } = await updateVoterWeight(
      new PublicKey(creatorMemberAddress),
      payer.publicKey,
      action,
      new PublicKey(activeTreasury.treasuryDataAddress),
      new PublicKey(activeTreasury.clubDataAddress),
      new PublicKey(activeTreasury.realmAddress),
      UpdateVWActionType.TreasuryAction,
      uvwForProposalRemainingAccounts
    );

    const realmConfigAddress = await SplGovernance.getRealmConfigAddress(
      UNQ_SPL_GOVERNANCE_PROGRAM_ID,
      new PublicKey(activeTreasury.realmAddress)
    );

    const [tokenOwnerRecord] = await PublicKey.findProgramAddressSync(
      [
        governanceSeed,
        new PublicKey(activeTreasury.realmAddress).toBuffer(),
        new PublicKey(communityToken).toBuffer(),
        payer.publicKey.toBuffer(),
      ],
      UNQ_SPL_GOVERNANCE_PROGRAM_ID
    );
    const [financialRecord] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        new PublicKey(activeTreasury.treasuryDataAddress).toBuffer(),
        financialRecordSeed,
        payer.publicKey.toBuffer(),
      ],
      program.programId
    );

    const createProposalIx = await program.methods
      .createClubProposal(
        proposalInfo.useDeny,
        parseEnum(ProposalTypeProgram, proposalInfo.proposalType),
        proposalInfo.name,
        EMPTY_STRING,
        proposalInfo.options
      )
      .accounts({
        clubData: new PublicKey(activeTreasury.clubDataAddress),
        communityTokenMint: new PublicKey(communityToken),
        governance: new PublicKey(governance.address),
        governanceAuthority: payer.publicKey,
        maxVoterWeightRecord: maxVoterWeightAddress,
        memberData: new PublicKey(creatorMemberAddress),
        payer: payer.publicKey,
        proposal: proposalAddress,
        proposalMetadata: proposalMetadata,
        realm: new PublicKey(activeTreasury.realmAddress),
        realmConfig: realmConfigAddress,
        rent: SYSVAR_RENT_PUBKEY,
        splGovernanceProgram: UNQ_SPL_GOVERNANCE_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        tokenOwnerRecord: tokenOwnerRecord,
        treasuryData: new PublicKey(activeTreasury.treasuryDataAddress),
        voterWeightRecord: voterWeightAddress,
        financialRecord: financialRecord,
      })
      .instruction();

    const updateProposalMetadataIx = await program.methods
      .updateProposalMetadata(
        proposalInfo.description,
        null,
        null,
        proposalInfo.discussionLink ?? null
      )
      .accounts({
        payer: payer.publicKey,
        proposalMetadata: proposalMetadata,
        systemProgram: SystemProgram.programId,
      })
      .instruction();

    createProposalIxs.push(voterWeightIx);
    createProposalIxs.push(createProposalIx);
    createProposalIxs.push(updateProposalMetadataIx);
    return {
      createProposalIx: createProposalIxs,
      proposalAddress: proposalAddress,
      proposalMetadataAddress: proposalMetadata,
      voterWeightAddress: voterWeightAddress,
      tokenOwnerRecord: tokenOwnerRecord,
      governance,
    };
  } catch (error) {
    throw error;
  }
};

/**
 * Prepare insert proposal transaction ix for createAndPrepareProposal method
 * @param ix
 * @param signerIndexes
 * @param remainingAccounts
 * @param governance
 * @param proposalAddress
 * @param proposalMetadataAddress
 * @param treasuryData
 * @param creator
 * @param creatorTokenOwnerRecord
 * @param communityMint
 * @returns
 */
const insertProposalTransaction = async (
  ixData: Buffer,
  signerIndexes: number[],
  remainingAccounts: AccountMeta[],
  governance: PublicKey,
  proposalAddress: PublicKey,
  proposalMetadataAddress: PublicKey,
  treasuryData: PublicKey,
  creator: AnchorWallet,
  creatorTokenOwnerRecord: PublicKey,
  communityMint: PublicKey
): Promise<TransactionInstruction> => {
  try {
    const program = programFactory();
    const proposalTxIxAddress = await getProposalTransactionAddress(
      proposalAddress
    );
    return await program.methods
      .insertTransaction([ixData], [signerIndexes])
      .accounts({
        splGovernanceProgram: UNQ_SPL_GOVERNANCE_PROGRAM_ID,
        communityTokenMint: communityMint,
        governance: governance,
        payer: creator.publicKey,
        proposal: proposalAddress,
        proposalMetadata: proposalMetadataAddress,
        proposalTransaction: proposalTxIxAddress,
        rent: SYSVAR_RENT_PUBKEY,
        systemProgram: SystemProgram.programId,
        tokenOwnerRecord: creatorTokenOwnerRecord,
        treasuryData: treasuryData,
      })
      .remainingAccounts(remainingAccounts)
      .instruction();
  } catch (error) {
    console.log(error);
    throw error;
  }
};

/**
 * Prepare sign of ix for createAndPrepareProposal method
 * @param proposalAddress
 * @param treasuryRealmAddress
 * @param governance
 * @param voterWeightAddress
 * @param tokenOwnerRecord
 * @param payer
 * @returns
 */
const signOffProposal = async (
  proposalAddress: PublicKey,
  treasuryRealmAddress: PublicKey,
  governance: PublicKey,
  voterWeightAddress: PublicKey,
  tokenOwnerRecord: PublicKey,
  payer: PublicKey
): Promise<TransactionInstruction[]> => {
  try {
    const ix: TransactionInstruction[] = [];
    SplGovernance.withSignOffProposal(
      ix,
      UNQ_SPL_GOVERNANCE_PROGRAM_ID,
      SplGovernance.PROGRAM_VERSION_V2,
      treasuryRealmAddress,
      governance,
      proposalAddress,
      payer,
      voterWeightAddress,
      tokenOwnerRecord
    );
    return ix;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

/**
 * Get address for proposal transaction account
 * @param proposalAddress
 * @returns
 */
const getProposalTransactionAddress = async (proposalAddress: PublicKey) => {
  //Think about this

  // let proposal = await SplGovernance.getProposal(
  //   RPC_CONNECTION,
  //   proposalAddress
  // );

  // let nextIndex = proposal.account.options[0].instructionsNextIndex;

  const optionIndex = 0;
  let optionIndexBuffer = Buffer.alloc(1);
  optionIndexBuffer.writeUInt8(optionIndex);

  let instructionIndexBuffer = Buffer.alloc(2);
  instructionIndexBuffer.writeInt16LE(0, 0);

  const [proposalTransactionAddress] = PublicKey.findProgramAddressSync(
    [
      governanceSeed,
      proposalAddress.toBuffer(),
      optionIndexBuffer,
      instructionIndexBuffer,
    ],
    UNQ_SPL_GOVERNANCE_PROGRAM_ID
  );

  return proposalTransactionAddress;
};

/**
 * Prepare transfer funds instruction and remaining accounts for insert transaction
 * @param treasuryAddress
 * @param proposalAddress
 * @param transferAmount
 * @param transferAddress
 * @param transferMint
 * @param creator
 * @param treasuryToken
 * @returns
 */
const getTransferFundsIx = async (
  treasuryAddress: PublicKey,
  proposalAddress: PublicKey,
  transferAmount: number,
  transferAddress: PublicKey,
  transferMint: PublicKey,
  creator: AnchorWallet,
  treasuryToken: PublicKey
): Promise<{
  accounts: AccountMeta[];
  signerIndexes: number[];
  transferFundsIx: TransactionInstruction;
  ataInsstruction: TransactionInstruction | undefined;
}> => {
  try {
    const escrowProgram = escrowProgramFactory();

    const [tokenLedger] = PublicKey.findProgramAddressSync(
      [
        escrowSeed,
        treasuryAddress.toBuffer(),
        tokenLedgerSeed,
        transferMint.toBuffer(),
      ],
      escrowProgram.programId
    );

    let [offer] = PublicKey.findProgramAddressSync(
      [offerSeed, proposalAddress.toBuffer()],
      escrowProgram.programId
    );

    const { destination, ataInsstruction } =
      await prepareDestinationAddressForTransferProposal(
        transferAddress,
        transferMint,
        creator.publicKey,
        treasuryToken,
        treasuryAddress
      );

    const transferFundsIx = await escrowProgram.methods
      .transferFunds(new BN(transferAmount))
      .accounts({
        proposal: proposalAddress,
        offer,
        payer: treasuryAddress,
        treasuryToken: treasuryToken,
        destination: destination,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        tokenLedger,
      })
      .instruction();

    const { accounts, signerIndexes } = returnAccountsAndSignerIndexes(
      transferFundsIx,
      transferFundsIx.programId
    );
    return {
      accounts,
      signerIndexes,
      transferFundsIx,
      ataInsstruction,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

/**
 * Prepare withdrawal instruction and remaining accounts for insert transaction
 * @param treasuryDataAddress
 * @param treasuryRealmAddress
 * @param treasuryAddress
 * @param clubDataAddress
 * @param proposalAddress
 * @param withdrawalAmount
 * @param withdrawalMint
 * @param proposalMetadataAddress
 * @param treasuryToken
 * @returns
 */
const getWithdrawalIx = async (
  treasuryDataAddress: PublicKey,
  treasuryRealmAddress: PublicKey,
  treasuryAddress: PublicKey,
  clubDataAddress: PublicKey,
  proposalAddress: PublicKey,
  withdrawalAmount: number,
  withdrawalMint: PublicKey,
  proposalMetadataAddress: PublicKey,
  treasuryToken: PublicKey
) => {
  try {
    const escrowProgram = escrowProgramFactory();

    const [withdrawalAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        proposalAddress.toBuffer(),
        treasuryAddress.toBuffer(),
        withdrawalSeed,
      ],
      escrowProgram.programId
    );
    const [withdrawalDataAddress] = PublicKey.findProgramAddressSync(
      [unqClubSeed, withdrawalAddress.toBuffer(), withdrawalDataSeed],
      escrowProgram.programId
    );
    const initializeWithdrawalIx = await escrowProgram.methods
      .initializeWithdrawal(new BN(withdrawalAmount))
      .accounts({
        realm: treasuryRealmAddress,
        treasuryData: treasuryDataAddress,
        treasury: treasuryAddress,
        treasuryToken,
        withdrawal: withdrawalAddress,
        withdrawalData: withdrawalDataAddress,
        withdrawalMint: withdrawalMint,
        proposalMetadata: proposalMetadataAddress,
        clubData: clubDataAddress,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        rent: SYSVAR_RENT_PUBKEY,
      })
      .instruction();

    let { accounts, signerIndexes } = returnAccountsAndSignerIndexes(
      initializeWithdrawalIx,
      initializeWithdrawalIx.programId
    );

    return {
      initializeWithdrawalIx,
      accounts,
      signerIndexes,
    };
  } catch (error) {
    throw error;
  }
};

const getUpdateRoleConfigIx = async (
  roleVotingConfig: IManageVotingPower[],
  treasuryDataRealm: PublicKey
) => {
  try {
    //TODO: check this
    // hash with hash256 algorihtm the string "global:UpdateGovernanceConfig"
    const clubInstructionDiscriminator = hash(
      "global:update_role_config"
    ).slice(0, 8);

    //TODO: check why we are sending this const
    const vecLen = Buffer.alloc(4);
    vecLen.writeUInt32LE(roleVotingConfig.length, 0);

    const seralizedData: Uint8Array[] = [];

    roleVotingConfig.forEach((item) => {
      seralizedData.push(
        serialize(
          new UpdateRoleConfigInput(
            item.role,
            new BN(item.oldVotingPower),
            new BN(item.roleVotingPower)
          )
        )
      );
    });

    const instructionData = Buffer.concat([
      UPDATE_ROLE_CONFIG_DISCRIMINATOR,
      vecLen,
      ...seralizedData,
    ]);
    const accounts: AccountMeta[] = [
      {
        pubkey: treasuryDataRealm,
        isWritable: true,
        isSigner: false,
      },
      {
        pubkey: programFactory().programId,
        isWritable: false,
        isSigner: false,
      },
    ];
    return {
      instructionData,
      accounts,
    };
  } catch (error) {
    throw error;
  }
};

const getChangeGovConfigIx = async (
  governanceConfig: IGovernanceConfig,
  changeGovernanceType: ChangeGovernanceTypeEnum,
  activeTreasury: ITreasuryData
) => {
  try {
    const newQuorumBuffer = Buffer.alloc(1);
    newQuorumBuffer.writeUInt8(governanceConfig.votePercentage, 0);

    const maxVotingTime = governanceConfig.maxVotingTime * 86400; //TODO: add to constant

    const governedAccountSeeds = getGovernedAccountSeeds(
      changeGovernanceType,
      activeTreasury,
      undefined,
      governanceConfig.sellPermissionIndex
    );

    if (!governedAccountSeeds) {
      throw new Error("Failed to find governed account seeds");
    }
    const seralizedData = serialize(
      new UpdateGovernanceConfigInput(
        newQuorumBuffer,
        governedAccountSeeds,
        maxVotingTime
      )
    );

    const instructionData = Buffer.concat([
      CHANGE_GOV_CONFIG_DISCRIMATOR,
      seralizedData,
    ]);

    const accounts: AccountMeta[] = [
      {
        pubkey: new PublicKey(governanceConfig.governance.address),
        isSigner: false,
        isWritable: true,
      },
      {
        pubkey: new PublicKey(governanceConfig.governance.governedAccount),
        isSigner: false,
        isWritable: true,
      },
      {
        pubkey: programFactory().programId,
        isSigner: false,
        isWritable: false,
      },
    ];
    return {
      instructionData,
      accounts,
    };
  } catch (error) {
    throw error;
  }
};

/**
 * Cast vote for proposal
 * @param proposal
 * @param realmAddress
 * @param treasuryDataAddress
 * @param wallet
 * @param memberDataAddress
 * @param clubDataAddress
 * @param choice
 * @param nfts
 */
export const voteToProposal = async (
  proposal: IProposal,
  realmAddress: string,
  treasuryDataAddress: string,
  wallet: AnchorWallet,
  memberDataAddress: string,
  clubDataAddress: string,
  choice: SplGovernance.Vote,
  cancelProposal?: boolean,
  nfts?: INFTForVote[]
) => {
  try {
    const program = programFactory();
    const castVoteIxs: TransactionInstruction[] = [];

    const [maxVoterWeightAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        new PublicKey(proposal.proposalMetadata.proposal).toBuffer(),
        maxVoterWeightSeed,
      ],
      program.programId
    );
    const [financialRecordAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        new PublicKey(treasuryDataAddress).toBuffer(),
        financialRecordSeed,
        wallet.publicKey.toBuffer(),
      ],
      program.programId
    );
    const [tokenOwnerRecord] = await PublicKey.findProgramAddress(
      [
        governanceSeed,
        new PublicKey(realmAddress).toBuffer(),
        new PublicKey(proposal.proposalAccount.governingTokenMint).toBuffer(),
        wallet.publicKey.toBuffer(),
      ],
      UNQ_SPL_GOVERNANCE_PROGRAM_ID
    );

    const uvwRemainingAccounts: AccountMeta[] = [
      {
        pubkey: new PublicKey(
          proposal.proposalMetadata.proposalMetadataAddress
        ),
        isWritable: true,
        isSigner: false,
      },
      {
        pubkey: maxVoterWeightAddress,
        isWritable: true,
        isSigner: false,
      },
      {
        pubkey: new PublicKey(proposal.proposalMetadata.proposal),
        isWritable: false,
        isSigner: false,
      },
    ];
    const { voterWeightAddress, voterWeightIx } = await updateVoterWeight(
      new PublicKey(memberDataAddress),
      wallet.publicKey,
      cancelProposal ? TreasuryAction.CancelProposal : TreasuryAction.CastVote,
      new PublicKey(treasuryDataAddress),
      new PublicKey(clubDataAddress),
      new PublicKey(realmAddress),
      UpdateVWActionType.TreasuryAction,
      uvwRemainingAccounts
    );

    if (nfts) {
      castVoteIxs.push(
        await castNftVote(
          nfts,
          wallet,
          new PublicKey(memberDataAddress),
          new PublicKey(realmAddress),
          voterWeightAddress,
          new PublicKey(proposal.proposalMetadata.proposal),
          new PublicKey(treasuryDataAddress)
        )
      );
    }

    castVoteIxs.push(voterWeightIx);

    await SplGovernance.withCastVote(
      castVoteIxs,
      UNQ_SPL_GOVERNANCE_PROGRAM_ID,
      SplGovernance.PROGRAM_VERSION_V2,
      new PublicKey(realmAddress),
      new PublicKey(proposal.proposalAccount.governance.address),
      new PublicKey(proposal.proposalMetadata.proposal),
      new PublicKey(proposal.proposalAccount.tokenOwnerRecord),
      tokenOwnerRecord,
      wallet.publicKey,
      new PublicKey(proposal.proposalAccount.governingTokenMint),
      choice,
      wallet.publicKey,
      voterWeightAddress,
      maxVoterWeightAddress
    );

    const instructionSet: IInstructionSet[] = [
      {
        description: cancelProposal ? "Veto proposal" : "Cast vote",
        instructions: castVoteIxs,
      },
    ];

    await sendTransactions(
      RPC_CONNECTION,
      wallet,
      instructionSet,
      SequenceType.Sequential,
      true
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

/**
 * Prepare cast nft vote ix in case of NFT clubs for voteToProposal method
 * @param nfts
 * @param wallet
 * @param memberData
 * @param treasuryRealm
 * @param voterWeightRecord
 * @param proposalAddress
 * @param treasuryDataAddress
 * @returns
 */
const castNftVote = async (
  nfts: INFTForVote[],
  wallet: AnchorWallet,
  memberData: PublicKey,
  treasuryRealm: PublicKey,
  voterWeightRecord: PublicKey,
  proposalAddress: PublicKey,
  treasuryDataAddress: PublicKey
) => {
  try {
    const program = programFactory();
    const remainingAccounts: AccountMeta[] = [];

    nfts.forEach((item) => {
      const [nftVoteRecord] = PublicKey.findProgramAddressSync(
        [nftVoteRecordSeed, proposalAddress.toBuffer(), item.mint.toBuffer()],
        program.programId
      );
      remainingAccounts.push(
        ...[
          {
            pubkey: item.tokenAccount,
            isSigner: false,
            isWritable: false,
          },
          {
            pubkey: item.metadata,
            isSigner: false,
            isWritable: false,
          },
          {
            pubkey: nftVoteRecord,
            isSigner: false,
            isWritable: true,
          },
        ]
      );
    });

    const castNftVoteIx = program.methods
      .castNftVote()
      .accounts({
        memberData,
        payer: wallet.publicKey,
        proposal: proposalAddress,
        realm: treasuryRealm,
        systemProgram: SystemProgram.programId,
        treasuryData: treasuryDataAddress,
        voterWeightRecord: voterWeightRecord,
      })
      .remainingAccounts(remainingAccounts)
      .instruction();

    return castNftVoteIx;
  } catch (error) {
    throw error;
  }
};

/**
 * Generic method for executing any type of Proposal
 * @param proposalAddress
 * @param proposalMetadataAddress
 * @param proposalType
 * @param payer
 * @param treasuryAddress
 * @param proposalsGovernance
 * @param treasuryDataAddress
 * @param clubDataAddress
 * @param proposalInstructionAccounts
 * @param memberDataAddress
 * @param withdrawalTreasuryToken
 * @param withdrawalMint
 */
export const executeProposal = async (
  proposalAddress: PublicKey,
  proposalMetadataAddress: PublicKey,
  proposalType: ProposalTypeProgram,
  payer: AnchorWallet,
  treasuryAddress: PublicKey,
  proposalsGovernance: PublicKey,
  treasuryDataAddress: PublicKey,
  clubDataAddress: PublicKey,
  proposalInstructionAccounts: IProposalInstructionAccount[],
  memberDataAddress: PublicKey,
  treasuryRealmAddress: PublicKey,
  withdrawalMint?: PublicKey,
  withdrawalTreasuryToken?: PublicKey
) => {
  try {
    const program = programFactory();
    const remainingAccounts: AccountMeta[] = [];
    const executeInstructions: TransactionInstruction[] = [];
    const instructionSet: IInstructionSet[] = [];

    proposalInstructionAccounts.forEach((item, index) => {
      remainingAccounts.push({
        isSigner: false,
        isWritable: item.isWritable,
        pubkey: new PublicKey(item.pubkey),
      });
    });
    const remainingProgramId = getProgramIdByProposalType(proposalType);
    if (remainingProgramId)
      remainingAccounts.push({
        isSigner: false,
        isWritable: false,
        pubkey: remainingProgramId,
      });

    switch (proposalType) {
      case ProposalTypeProgram.Withdrawal:
        if (withdrawalTreasuryToken && withdrawalMint) {
          const { instruction, additionalRemainingAccounts } =
            await getAdditionalRemainingAccountsForExecuteWithdrawal(
              withdrawalTreasuryToken,
              withdrawalMint,
              treasuryAddress,
              payer,
              memberDataAddress
            );
          remainingAccounts.push(...additionalRemainingAccounts);
          if (instruction) {
            instructionSet.push({
              description: "Create profit token account",
              instructions: [instruction],
            });
          }
        }
        break;
      case ProposalTypeProgram.TransferFunds:
        break;
    }

    if (proposalType === ProposalTypeProgram.AddSellPermission) {
      const { voterWeightIx, voterWeightAddress } = await updateVoterWeight(
        memberDataAddress,
        payer.publicKey,
        ClubAction.CreateTreasuryGovernance,
        treasuryDataAddress,
        clubDataAddress,
        treasuryRealmAddress,
        UpdateVWActionType.ClubAction
      );
      executeInstructions.push(voterWeightIx);
    }

    const requestComputeUnitsTx = ComputeBudgetProgram.setComputeUnitLimit({
      units: 300000,
    });
    executeInstructions.push(requestComputeUnitsTx);

    const executeProposalIx = await program.methods
      .executeProposal()
      .accounts({
        clubData: clubDataAddress,
        governance: proposalsGovernance,
        payer: payer.publicKey,
        proposal: proposalAddress,
        proposalMetadata: proposalMetadataAddress,
        proposalTransaction: await getProposalTransactionAddress(
          proposalAddress
        ),
        splGovernanceProgram: UNQ_SPL_GOVERNANCE_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
        treasury: treasuryAddress,
        treasuryData: treasuryDataAddress,
      })
      .remainingAccounts(remainingAccounts)
      .instruction();

    executeInstructions.push(executeProposalIx);
    instructionSet.push({
      description: "Execute proposal",
      instructions: executeInstructions,
    });

    await sendTransactions(
      RPC_CONNECTION,
      payer,
      instructionSet,
      SequenceType.Sequential,
      true
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Prepare additional remaining accounts for execution of withdrawal proposal
 * @param treasuryToken
 * @param transferMint
 * @param treasuryAddress
 * @param wallet
 * @param memberDataAddress
 * @returns
 */
export const getAdditionalRemainingAccountsForExecuteWithdrawal = async (
  treasuryToken: PublicKey,
  transferMint: PublicKey,
  treasuryAddress: PublicKey,
  wallet: AnchorWallet,
  memberDataAddress: PublicKey
) => {
  try {
    const additionalRemainingAccounts: AccountMeta[] = [];
    const program = programFactory();

    const [treasuryProfit] = PublicKey.findProgramAddressSync(
      [unqClubSeed, treasuryAddress.toBuffer(), profitSeed],
      program.programId
    );

    const { address, instruction } =
      await getOrCreateATAOwnedBySpecifiedAccount(
        treasuryProfit,
        wallet.publicKey,
        transferMint
      );

    additionalRemainingAccounts.push(
      {
        pubkey: memberDataAddress,
        isSigner: false,
        isWritable: false,
      },
      {
        pubkey: treasuryToken,
        isSigner: false,
        isWritable: true,
      },
      {
        pubkey: treasuryProfit,
        isSigner: false,
        isWritable: true,
      },
      {
        pubkey: address,
        isSigner: false,
        isWritable: true,
      }
    );
    return {
      additionalRemainingAccounts,
      instruction,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

/**
 * Check destination for Transfer proposal and create ATA if needed
 * @param transferAddress
 * @param mint
 * @param payer
 * @param treasuryTa
 * @param treasuryAddress
 * @returns
 */
export const prepareDestinationAddressForTransferProposal = async (
  transferAddress: PublicKey,
  mint: PublicKey,
  payer: PublicKey,
  treasuryTa: PublicKey,
  treasuryAddress: PublicKey
) => {
  try {
    let destination: PublicKey | undefined = undefined;
    let isTransferAddressATA = false;
    let ataInsstruction: TransactionInstruction | undefined = undefined;

    try {
      const transferAddressTABalance =
        await RPC_CONNECTION.getTokenAccountBalance(
          new PublicKey(transferAddress)
        );
      isTransferAddressATA = true;
    } catch (error) {
      console.log(error);
      isTransferAddressATA = false;
    }
    if (isTransferAddressATA) {
      const transferTAInfo: any = await RPC_CONNECTION.getParsedAccountInfo(
        new PublicKey(transferAddress)
      );
      if (
        transferTAInfo &&
        transferTAInfo.value?.data.parsed.info.mint.toString() ===
          mint.toString()
      ) {
        destination = new PublicKey(transferAddress);
      } else {
        throw new Error(`Provided token account's mint is not expected.`);
      }
    }
    if (destination === undefined) {
      if (mint.toString() === NATIVE_MINT.toString()) {
        destination = new PublicKey(transferAddress);
      } else {
        const { address, instruction } =
          await getOrCreateATAOwnedBySpecifiedAccount(
            transferAddress,
            payer,
            mint
          );
        destination = address;
        ataInsstruction = instruction;
      }
    }

    if (
      destination.toString() === treasuryTa.toString() ||
      (mint.toString() === NATIVE_MINT.toString() &&
        destination.toString() === treasuryAddress.toString())
    ) {
      throw new Error(
        "Destination addresss can not be the same as address from which tokens will be transfered"
      );
    }

    return {
      ataInsstruction,
      destination,
    };
  } catch (error) {
    throw error;
  }
};

/**
 * Get program id based on proposal type for insert remaining account
 * @param proposalType
 * @returns
 */
const getProgramIdByProposalType = (proposalType: ProposalTypeProgram) => {
  switch (proposalType) {
    case ProposalTypeProgram.TransferFunds:
    case ProposalTypeProgram.Withdrawal:
      return escrowProgramFactory().programId;
    case ProposalTypeProgram.UpdateRoleConfig:
    case ProposalTypeProgram.UpdateGovernanceConfig:
    case ProposalTypeProgram.CreateFundraise:
    case ProposalTypeProgram.AddSellPermission:
      return programFactory().programId;
  }
};

export const claimWithdrawal = async (
  withdrawalData: PublicKey,
  memberDataAddress: PublicKey,
  wallet: AnchorWallet,
  treasuryDataAddress: PublicKey,
  withdrawalAddress: PublicKey,
  withdrawalMint: PublicKey
) => {
  try {
    const escrowProgram = escrowProgramFactory();
    const program = programFactory();

    const instructions: TransactionInstruction[] = [];
    const instructionSet: IInstructionSet[] = [];

    const [financialRecordAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        treasuryDataAddress.toBuffer(),
        financialRecordSeed,
        wallet.publicKey.toBuffer(),
      ],
      program.programId
    );

    const [withdrawalRecord] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        withdrawalAddress.toBuffer(),
        withdrawalRecordSeed,
        wallet.publicKey.toBuffer(),
      ],
      escrowProgram.programId
    );

    const { address, instruction } =
      await getOrCreateATAOwnedBySpecifiedAccount(
        wallet.publicKey,
        wallet.publicKey,
        withdrawalMint
      );

    if (instruction) instructions.push(instruction);

    const withdrawIx = await escrowProgram.methods
      .withdraw()
      .accounts({
        financialRecord: financialRecordAddress,
        memberData: memberDataAddress,
        payer: wallet.publicKey,
        systemProgram: SystemProgram.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
        treasuryData: treasuryDataAddress,
        withdrawal: withdrawalAddress,
        withdrawalData: withdrawalData,
        memberTokens: address,
        withdrawalRecord,
      })
      .instruction();
    instructions.push(withdrawIx);

    instructionSet.push({
      description: "Withraw funds",
      instructions: instructions,
    });
    await sendTransactions(
      RPC_CONNECTION,
      wallet,
      instructionSet,
      SequenceType.Sequential,
      true
    );
  } catch (error) {
    throw error;
  }
};

export const getFundraiseProposalIx = async (
  fundraiseAmount: number,
  activeTreasury: ITreasuryData
) => {
  try {
    const fundraiseBuffer = Buffer.alloc(8);
    fundraiseBuffer.writeBigUInt64LE(BigInt(fundraiseAmount), 0);
    const instructionData = Buffer.concat([
      FUNDRAISE_PROPOSAL_DISCRIMINATOR,
      fundraiseBuffer,
    ]);

    let countBuff = Buffer.alloc(4);
    countBuff.writeUInt32LE(activeTreasury.fundraiseCount + 1, 0);
    const [fundraiseConfigAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        new PublicKey(activeTreasury.treasuryDataAddress).toBuffer(),
        fundraiseCfgSeed,
        countBuff,
      ],
      programFactory().programId
    );

    const accounts: AccountMeta[] = [
      {
        pubkey: fundraiseConfigAddress,
        isWritable: true,
        isSigner: false,
      },
      {
        pubkey: programFactory().programId,
        isWritable: false,
        isSigner: false,
      },
    ];
    return {
      accounts,
      instructionData,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAddSellPermissionIx = async (
  from: number,
  to: number,
  quorum: number,
  maxVotingTime: number,
  activeTreasury: ITreasuryData,
  communityMint: PublicKey,
  creator: PublicKey
) => {
  try {
    const addSellPermissionData = new AddSellPermissionData(
      new SellPermisionDto(BigInt(from), BigInt(to), quorum, 9),
      maxVotingTime
    );

    const seralizedData = serialize(addSellPermissionData);
    const instructionData = Buffer.concat([
      ADD_SELL_PERMISSION_DISCRIMANOTOR,
      seralizedData,
    ]);
    const accounts = await getAddSellPermissionIxRemainingAcc(
      activeTreasury,
      communityMint,
      creator
    );
    return {
      accounts,
      instructionData,
    };
  } catch (error) {
    throw error;
  }
};

export const getAddSellPermissionIxRemainingAcc = async (
  activeTreasury: ITreasuryData,
  communityToken: PublicKey,
  creator: PublicKey
): Promise<AccountMeta[]> => {
  const treasuryRealm = new PublicKey(activeTreasury.realmAddress);

  const sellPermissionGovernedAccount = getSellPermissionGovernedAccount(
    activeTreasury.sellPermission.length,
    treasuryRealm
  );

  const [tokenOwnerRecord] = await PublicKey.findProgramAddressSync(
    [
      governanceSeed,
      treasuryRealm.toBuffer(),
      communityToken.toBuffer(),
      creator.toBuffer(),
    ],
    UNQ_SPL_GOVERNANCE_PROGRAM_ID
  );

  const [voterWeightAddress] = PublicKey.findProgramAddressSync(
    [
      unqClubSeed,
      new PublicKey(activeTreasury.clubDataAddress).toBuffer(),
      voterWeightSeed,
      creator.toBuffer(),
    ],
    programFactory().programId
  );

  return [
    {
      pubkey: await getGovernanceForGovernedAccount(
        treasuryRealm,
        sellPermissionGovernedAccount
      ),
      isWritable: true,
      isSigner: false,
    },
    {
      pubkey: sellPermissionGovernedAccount,
      isWritable: false,
      isSigner: false,
    },
    {
      pubkey: treasuryRealm,
      isWritable: false,
      isSigner: false,
    },
    {
      pubkey: await SplGovernance.getRealmConfigAddress(
        UNQ_SPL_GOVERNANCE_PROGRAM_ID,
        treasuryRealm
      ),
      isWritable: false,
      isSigner: false,
    },
    {
      pubkey: tokenOwnerRecord,
      isWritable: true,
      isSigner: false,
    },
    {
      pubkey: voterWeightAddress,
      isWritable: false,
      isSigner: false,
    },
    {
      pubkey: programFactory().programId,
      isWritable: false,
      isSigner: false,
    },
  ];
};

export const getSellPermissionGovernedAccount = (
  sellPermissionIndex: number,
  treasuryRealm: PublicKey
) => {
  const indexBuffer = Buffer.alloc(4);
  indexBuffer.writeUint32LE(sellPermissionIndex, 0);

  return PublicKey.findProgramAddressSync(
    [
      unqClubSeed,
      treasuryRealm.toBuffer(),
      sellPermissionGovernanceSeed,
      indexBuffer,
    ],
    programFactory().programId
  )[0];
};

export const cancelProposal = async (
  treasuryRealmAddress: PublicKey,
  governance: PublicKey,
  proposalAddress: PublicKey,
  proposalOwnerRecord: PublicKey,
  memberDataAddress: PublicKey,
  treasuryDataAddress: PublicKey,
  clubDataAddress: PublicKey,
  proposalMetadataAddress: PublicKey,
  payer: AnchorWallet
) => {
  try {
    const program = programFactory();
    const instruction: TransactionInstruction[] = [];
    const instructionSet: IInstructionSet[] = [];

    SplGovernance.withCancelProposal(
      instruction,
      UNQ_SPL_GOVERNANCE_PROGRAM_ID,
      SplGovernance.PROGRAM_VERSION_V2,
      treasuryRealmAddress,
      governance,
      proposalAddress,
      proposalOwnerRecord,
      payer.publicKey
    );

    instructionSet.push({
      description: "Cancel proposal",
      instructions: instruction,
    });

    await sendTransactions(
      RPC_CONNECTION,
      payer,
      instructionSet,
      SequenceType.Sequential,
      true
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};
