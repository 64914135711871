import { CLIENT_CONFIGURATION } from "../../client/configuration";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import { FundraiseModel, KycType } from "../../common/enums/deals.enum";

export default {
  formId: "create-deal",
  formFields: {
    name: {
      name: "name",
      value: EMPTY_STRING,
      label: "Syndicate Name",
    },
    description: {
      name: "description",
      value: EMPTY_STRING,
      label: "Description",
    },
    verticals: {
      name: "verticals",
      value: [],
    },
    imageUrl: {
      name: "imageUrl",
      label: EMPTY_STRING,
      value: EMPTY_STRING,
    },
    roles: {
      name: "roles",
      label: "Roles",
      value: CLIENT_CONFIGURATION.clubRoles,
    },
    treasuryRoles: {
      name: "treasuryRoles",
      label: "Treasury roles",
      value: CLIENT_CONFIGURATION.treasuryRoles,
    },
    fundraiseModel: {
      name: "fundraiseModel",
      label: "Fundraise model",
      value: FundraiseModel.Token,
    },
    // KYC Not in current version of the app, will be used later
    // requireKyc: {
    //   name: "requireKyc",
    //   value: false,
    // },
    // kycType: {
    //   name: "kycType",
    //   value: KycType.All,
    //   label: "Kyc type",
    // },
    allowTradingShares: {
      name: "allowTradingShares",
      value: CLIENT_CONFIGURATION.allowTradingShares,
    },
    treasuryName: {
      name: "treasuryName",
      value:
        CLIENT_CONFIGURATION.treasuryConfiguration?.treasuryName ??
        EMPTY_STRING,
      label: "Treasury name",
    },
    chain: {
      name: "chain",
      value: CLIENT_CONFIGURATION.treasuryConfiguration?.chain ?? EMPTY_STRING,
      label: "Chain",
    },
    denominatedCurrency: {
      name: "denominatedCurrency",
      value:
        CLIENT_CONFIGURATION.treasuryConfiguration?.denominatedCurrency ??
        EMPTY_STRING,
      label: "Denominated currency",
    },
    approvalQuorum: {
      name: "approvalQuorum",
      value:
        CLIENT_CONFIGURATION.treasuryConfiguration?.approvalQuorum ??
        EMPTY_STRING,
      label: "Approval vote percentage",
    },
    maxVotingTime: {
      name: "maxVotingTime",
      value:
        CLIENT_CONFIGURATION.treasuryConfiguration?.maxVotingTime ??
        EMPTY_STRING,
      label: "Max voting time",
    },
  },
};
