import { EMPTY_STRING } from "../../common/constants/common.constants";

export default {
  formId: "set-reserved-rights",
  formFields: {
    members: {
      name: "members",
      value: [
        {
          wallet: [],
          membersAmount: EMPTY_STRING,
        },
      ],
      label: "Members",
    },
  },
};
