import React from "react";
import InputField from "../../InputFields/InputField";
import proposalFormConfig from "../proposalFormConfig";
import "./ChangeGovernanceConfiguration.scss";

const ChangeGovernanceConfiguration: React.FC = () => {
  const formFields = proposalFormConfig.formFields;
  return (
    <div className="change-governance-configuration">
      <InputField
        name={formFields.approvalVotePercentage.name}
        type="number"
        labelTitle="Vote percentage"
      />

      <InputField
        name={formFields.maxVotingTime.name}
        labelTitle="Max voting time"
        type="text"
      />
    </div>
  );
};

export default ChangeGovernanceConfiguration;
