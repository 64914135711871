import { FC } from "react";
import "./HomeHeader.scss";
import copy from "../../../assets/content_copy.png";
import { handleCopyToClipboard } from "../../../utilities/helpers";
import HoverIcon from "../../../components/HoverIcon/HoverIcon";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { IDashboardMemberInfo } from "../../../common/interfaces/dashboard.interface";
import { SocialNetworkType } from "../../../common/enums/clubs.enum";
import avatar from "../../../assets/unique_avatar.png";
import { EMPTY_STRING } from "../../../common/constants/common.constants";

const HomeHeader: FC<{
  memberInfo?: IDashboardMemberInfo;
}> = ({ memberInfo }) => {
  const wallet = useAnchorWallet();

  return (
    <div className="home-header">
      <div className="home-header-image">
        <img
          src={
            memberInfo?.imageUrl && memberInfo.imageUrl !== EMPTY_STRING
              ? memberInfo.imageUrl
              : avatar
          }
          alt="Profile picture"
          className="home-header-image-profile"
        />
      </div>
      <div className="home-header__details">
        <h2>{memberInfo?.name}</h2>
        <div className="home-header__wallet">
          <p>{wallet?.publicKey.toString()}</p>
          <HoverIcon
            src={copy}
            alt="Copy"
            onClick={() => {
              handleCopyToClipboard(wallet?.publicKey.toString()!);
            }}
          />
        </div>
        <p>
          {memberInfo &&
            memberInfo.socialNetworks?.find(
              (item) => item.type === SocialNetworkType.Mail
            )?.value}
        </p>
      </div>
    </div>
  );
};

export default HomeHeader;
