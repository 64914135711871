import { EMPTY_STRING } from "../../../common/constants/common.constants";

export default {
  formId: "manage-treasury-roles-form",
  formFields: {
    members: {
      name: "members",
      value: [
        {
          wallet: [],
          role: EMPTY_STRING,
        },
      ],
      label: "Members",
    },
  },
};
