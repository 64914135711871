import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    hedars: {},
  });
  return forward(operation);
});

const httpLink = new HttpLink({
  uri: "https://app-server.unq.club/graphql",
  fetch,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: "wss://app-emitter.unq.club/graphql",
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const apolloClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
    watchQuery: {
      fetchPolicy: "cache-first",
    },
  },
});
