import { FC } from "react";
import { useFormikContext } from "formik";
import "./DealTreasury.scss";
import { IBasicClubDataForm } from "../../common/interfaces/form.interface";
import createDealFormModel from "../../pages/CreateDeal/createDealFormModel";
import InputField from "../InputFields/InputField";
import { FundraiseModel } from "../../common/enums/deals.enum";
import { chainOptions } from "../../common/constants/common.constants";
import MultiToggle from "../MultiToggle/MultiToggle";
import AsyncTokenSearchField from "../AsyncTokenSearchField/AsyncTokenSearchField";
import React from "react";

const DealTreasury: FC<{ disabled?: boolean; edit?: boolean }> = ({
  disabled,
  edit,
}) => {
  const { values, setFieldValue, errors } =
    useFormikContext<IBasicClubDataForm>();
  const formFields = createDealFormModel.formFields;

  return (
    <div className="deal-treasury" style={edit ? { marginTop: 0 } : undefined}>
      <div className="deal-treasury__section">
        {!edit && <p className="deal-treasury__label">Treasury</p>}
        <InputField
          type="text"
          name={formFields.treasuryName.name}
          labelTitle={formFields.treasuryName.label}
          disabled={disabled}
        />
        <InputField
          type="select"
          name={formFields.chain.name}
          labelTitle={formFields.chain.label}
          options={chainOptions}
          disabled={disabled}
        />

        <AsyncTokenSearchField />
      </div>
      <div className="deal-treasury__section">
        <p className="deal-treasury__label">Treasury configuration</p>
        <div className="deal-treasury__config">
          <InputField
            type="text"
            name={formFields.approvalQuorum.name}
            labelTitle={formFields.approvalQuorum.label}
            disabled={disabled}
          />
          <InputField
            type="text"
            name={formFields.maxVotingTime.name}
            labelTitle={formFields.maxVotingTime.label}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="deal-configuration__fundraise-model">
        <p className="deal-configuration__fundraise-model-label">
          Fundraise model
        </p>
        {!disabled ? (
          <div className="deal-configuration__fundraise-model-toggle">
            <MultiToggle
              first
              isSelected={values.fundraiseModel === FundraiseModel.Token}
              label="Token"
              onClick={() =>
                setFieldValue(
                  formFields.fundraiseModel.name,
                  FundraiseModel.Token
                )
              }
            />
            <MultiToggle
              isSelected={values.fundraiseModel === FundraiseModel.Equity}
              label="Equity"
              onClick={() =>
                setFieldValue(
                  formFields.fundraiseModel.name,
                  FundraiseModel.Equity
                )
              }
            />
            <MultiToggle
              last
              isSelected={values.fundraiseModel === FundraiseModel.Both}
              label="Both"
              onClick={() =>
                setFieldValue(
                  formFields.fundraiseModel.name,
                  FundraiseModel.Both
                )
              }
            />
          </div>
        ) : (
          <InputField
            type="text"
            name={formFields.fundraiseModel.name}
            labelTitle={formFields.fundraiseModel.label}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

export default DealTreasury;
