import UserImage from "../../../components/UserImage/UserImage";
import "./TreasuryMember.scss";
import linkIcon from "../../../assets/link.png";
import { FC } from "react";
import { ITreasuryMembersInfo } from "../../../common/interfaces/treasury.interface";

const TreasuryMember: FC<{ member: ITreasuryMembersInfo }> = ({ member }) => {
  return (
    <div className="treasury-member">
      <div className="treasury-member__info">
        <UserImage
          image=""
          name={
            member.memberInfo?.name ? member.memberInfo?.name : member.authority
          }
        />
        <div className="treasury-member__info-basic">
          <label className="treasury-member__user-name">
            {member.memberInfo?.name
              ? member.memberInfo?.name
              : member.authority}
          </label>
          <label className="treasury-member__role">{member.treasuryRole}</label>
        </div>
      </div>
      <div className="treasury-member__characteristics">
        <label className="treasury-member__ownership">
          Ownership
          <span>{member.ownership.toFixed(2)}</span>
        </label>
        <label className="treasury-member__voting-power">
          Voting power <span>{member.votingPower}</span>
        </label>
        <div className="treasury-member__options">
          <img src={linkIcon} />
        </div>
      </div>
    </div>
  );
};

export default TreasuryMember;
