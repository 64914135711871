import React, { FC, useMemo, useState } from "react";
import "./BrowseDealsCard.scss";
import { IBasicClubData } from "../../../common/interfaces/club.interface";
import { Link, useNavigate } from "react-router-dom";
import { OVERVIEW } from "../../../common/constants/routes.constants";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import Chip from "../../../components/Chip/Chip";
import MainButton from "../../../components/MainButton/MainButton";
import {
  GREEN500,
  GREY500,
  WHITE300,
} from "../../../common/constants/layout.constants";
import avatar from "../../../assets/unique_avatar.png";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import { findTreasuryAddressByIndex } from "../../../program/program-helpers";

interface IBrowseDealsCard {
  deal: IBasicClubData;
}

const BrowseDealsCard: FC<IBrowseDealsCard> = ({ deal }) => {
  const [showCTA, setShowCTA] = useState(false);
  const navigate = useNavigate();

  const goToClubDetails = () => {
    navigate(
      `${OVERVIEW}/${deal.address}/${findTreasuryAddressByIndex(
        deal.address,
        1
      )}`
    );
  };

  return (
    <div
      className="browse-deals-card"
      onMouseEnter={() => setShowCTA(true)}
      onMouseLeave={() => setShowCTA(false)}
      onClick={goToClubDetails}
    >
      {deal.isMember && (
        <div className="browse-deals-card__badge">
          <Chip
            text="Member"
            backgroundColor={WHITE300}
            padding="0 10px 2px"
            borderRadius="8"
            fontSize="0.75"
          />
        </div>
      )}
      <img
        src={
          deal.imageUrl && deal.imageUrl !== EMPTY_STRING
            ? deal.imageUrl
            : avatar
        }
        alt="Deal"
      />
      {/* This CTA is not needed for non-syndicate version of the app */}
      {/* {showCTA &&
        (deal.isMember ? (
          <MainButton
            type="button"
            onClick={() => console.log("click")}
            squared
            backgroundColor={GREEN500}
            color={GREY500}
          >
            Invest Now
          </MainButton>
        ) : (
          <MainButton
            type="button"
            onClick={() => console.log("click")}
            squared
          >
            View Pitch Deck
          </MainButton>
        ))} */}
      <div className="browse-deals-card__content">
        <p className="browse-deals-card__content-title">{deal.name}</p>
        <ProgressBar
          numerator={deal.fundraisedAmount}
          denominator={deal.totalFundraiseAmount}
          active={deal.activeFundraise}
        />
        {deal.activeFundraise ? (
          <p className="browse-deals-card__content-fundraise">
            <span>{deal.fundraisedAmount} SOL</span> out of{" "}
            <span>{deal.totalFundraiseAmount} SOL</span> totally raised
          </p>
        ) : (
          <p>No active fundraise</p>
        )}

        <div className="browse-deals-card__tags">
          {deal.verticals?.map((tag, index) => (
            <p key={index}>{tag.label}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrowseDealsCard;
