import "./Treasury.scss";
import TreasuryInfo from "./TreasuryInfo/TreasuryInfo";
import FundraiseTabs from "./FundraiseTabs/FundraiseTabs";
import ClubDetailsLayout from "../../components/ClubDetailsWrapper/ClubDetailsWrapper";
import { clubStore } from "../../state/clubStore";
import ReservedRights from "./ActionsBeforeFundraise/ReservedRights";
import FundraiseHeader from "./FundraiseHeader/FundraiseHeader";
import FundraiseProgress from "./FundraiseProgress/FundraiseProgress";
import React, { useMemo } from "react";
import FundraiseCsv from "./ActionsBeforeFundraise/FundraiseCsv";
import { useWallet } from "@solana/wallet-adapter-react";
import { checkIfUserHasTreasuryPermissionForAction } from "../../utilities/helpers";
import { TreasuryAction } from "../../common/enums/clubs.enum";

const Treasury: React.FC = () => {
  const { fundraises, activeTreasury, clubBasicInfo, memberTreasuryInfo } =
    clubStore();
  const fundraiseInProgress = fundraises?.find((item) => item.isActive);

  const wallet = useWallet();

  const shouldBeAbleToReserveRights = useMemo(() => {
    return (
      !activeTreasury?.reservedRights &&
      activeTreasury?.fundraiseCount === 0 &&
      checkIfUserHasTreasuryPermissionForAction(
        memberTreasuryInfo,
        activeTreasury?.treasuryRoleConfig,
        TreasuryAction.AddReservedRights
      )
    );
  }, [activeTreasury]);

  const shouldBeAbleToImportCsv = useMemo(() => {
    return (
      clubBasicInfo?.authority === wallet.publicKey?.toString() &&
      activeTreasury?.fundraiseCount === 0
    );
  }, [clubBasicInfo, activeTreasury, wallet]);

  return (
    <ClubDetailsLayout>
      <div className="treasury-main">
        <TreasuryInfo />
        {shouldBeAbleToReserveRights && <ReservedRights />}
        {shouldBeAbleToImportCsv && <FundraiseCsv />}
        {fundraiseInProgress && (
          <div className="treasury-main__fundraise">
            <FundraiseHeader />
            <FundraiseProgress fundraise={fundraiseInProgress} />{" "}
          </div>
        )}
        <FundraiseTabs />
      </div>
    </ClubDetailsLayout>
  );
};

export default Treasury;
