import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import React, { FC, ReactNode } from "react";
import "./BasicTable.scss";
import sort from "../../assets/unfold_more.png";

const BasicTable: FC<{
  titles: string[];
  loader: boolean;
  children: ReactNode;
}> = ({ titles, loader, children }) => {
  return (
    <Table
      aria-label="simple table"
      className="basic-table"
      style={{ fontFamily: "Manrope" }}
    >
      <TableHead>
        {titles.map((item, index) => (
          <TableCell key={index}>
            <p>
              {item} {item.length > 1 && <img src={sort} alt="Sort" />}
            </p>
          </TableCell>
        ))}
      </TableHead>
      <TableBody
        sx={{
          position: "relative",
        }}
      >
        {loader ? (
          <CircularProgress
            color="primary"
            sx={{
              marginTop: 1,
              position: "absolute",
              right: "50%",
              top: "50%",
            }}
          />
        ) : (
          children
        )}
      </TableBody>
    </Table>
  );
};

export default BasicTable;
