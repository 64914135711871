import { Form, Formik } from "formik";
import Modal from "../Modal/Modal";
import { useMemo } from "react";
import {
  ICreateFundraiseFormFields,
  IMemberWallets,
} from "../../common/interfaces/form.interface";
import createFundraiseFormConfig from "./createFundraiseFormConfig";
import CreateFundraiseForm from "./CreateFundraiseForm/CreateFundraiseForm";
import FormActions from "../FormActions/FormActions";
import { validateCreateFundraiseForm } from "./createFundraiseFormValidate";
import { clubStore } from "../../state/clubStore";
import {
  createOrEndFundraise,
  updateAllocation,
} from "../../program/methods/clubs";
import { AnchorWallet, useAnchorWallet } from "@solana/wallet-adapter-react";
import { apolloClient } from "../../api/config.api";
import {
  GET_FUNDRAISES_FOR_TREASURY,
  saveTokenUnlockingData,
} from "../../api/club.api";
import { sleep } from "../../program/sendTransactions";
import { pow } from "mathjs";
import {
  AllocationLimitation,
  FundraiseAction,
} from "../../common/enums/fundraise.enum";
import {
  IClubData,
  ICustomAllocationForProgram,
  IFundraiseConfig,
  IMemberData,
  ITreasuryData,
} from "../../common/interfaces/club.interface";
import { PublicKey } from "@solana/web3.js";
import { BN } from "@project-serum/anchor";
import {
  EMPTY_STRING,
  MESSAGE_TYPE,
} from "../../common/constants/common.constants";
import {
  findFundraiseAdressFromPda,
  getAmountWithDecimalsForCurrency,
  mapTokenUnlockingDateArray,
} from "../../utilities/helpers";
import { createNotification } from "../../utilities/notifications";
import { ITokenUnlockingInput } from "../../common/interfaces/common.interface";

const CreateFundraiseModal: React.FC<{
  onClose: () => void;
  edit: boolean;
}> = ({ onClose, edit }) => {
  const {
    clubBasicInfo,
    setFundraises,
    activeTreasury,
    fundraises,
    memberData,
  } = clubStore();
  const wallet = useAnchorWallet();
  const formFields = createFundraiseFormConfig.formFields;

  const initialValues: ICreateFundraiseFormFields = useMemo(() => {
    if (edit) {
      const fundraiseInProgress = fundraises?.find((item) => item.isActive);
      const arrayCustomAllocationForForm: IMemberWallets[] = [];
      const customAllocationArray =
        fundraiseInProgress?.allocation?.custom ?? [];

      const amounts: number[] = [];

      customAllocationArray?.forEach((item) => {
        if (!amounts.includes(item.amount)) {
          amounts.push(item.amount);
        }
      });

      amounts.forEach((amount) => {
        arrayCustomAllocationForForm.push({
          membersAmount:
            getAmountWithDecimalsForCurrency(
              activeTreasury?.currencyDecimals,
              amount
            ) ?? 0,
          wallet: customAllocationArray
            ?.filter((item) => item.amount === amount)
            .map((item) => item.authority),
        });
      });

      return {
        amount: fundraiseInProgress?.capAmount
          ? getAmountWithDecimalsForCurrency(
              activeTreasury?.currencyDecimals,
              fundraiseInProgress?.capAmount
            ) ?? 0
          : EMPTY_STRING,
        tokenUnlockDate: [],
        tokensMembersAmount: fundraiseInProgress?.allocation?.equal
          ? getAmountWithDecimalsForCurrency(
              activeTreasury?.currencyDecimals,
              fundraiseInProgress?.allocation.equal
            ) ?? 0
          : 0,
        allocationLimitation: fundraiseInProgress?.allocation?.equal
          ? AllocationLimitation.Limited
          : AllocationLimitation.Unlimited,
        members: fundraiseInProgress?.allocation?.custom
          ? arrayCustomAllocationForForm
          : [],
      };
    } else
      return {
        amount: formFields.amount.value,
        tokenUnlockDate: formFields.tokenUnlockDate.value,
        tokensMembersAmount: formFields.tokensMembersAmount.value,
        allocationLimitation: formFields.allocationLimitation.value,
        members: formFields.members.value,
      };
  }, [fundraises, activeTreasury]);

  const handleSubmitForm = async (values: ICreateFundraiseFormFields) => {
    try {
      const fundraiseInProgress = fundraises?.find((item) => item.isActive);
      if (
        !clubBasicInfo ||
        !wallet ||
        !activeTreasury ||
        !fundraises ||
        !memberData
      ) {
        createNotification(MESSAGE_TYPE.ERROR, "Missing data");
        return;
      }

      if (edit && fundraiseInProgress) {
        editFundraise(
          values,
          fundraiseInProgress,
          activeTreasury,
          memberData,
          wallet
        );
      }

      if (!edit) {
        createFundraise(values, activeTreasury, wallet, clubBasicInfo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createFundraise = async (
    values: ICreateFundraiseFormFields,
    activeTreasury: ITreasuryData,
    wallet: AnchorWallet,
    clubBasicInfo: IClubData
  ) => {
    try {
      const arrayCustomAllocation: ICustomAllocationForProgram[] = [];

      values.members.map((memberItem) =>
        memberItem.wallet.map((walletItem) =>
          arrayCustomAllocation.push({
            authority: new PublicKey(walletItem),
            amount: new BN(
              Number(memberItem.membersAmount) *
                Number(pow(10, activeTreasury.currencyDecimals))
            ),
          })
        )
      );

      const fundraiseAddress = await createOrEndFundraise(
        activeTreasury?.treasuryAddress.toString(),
        activeTreasury.treasuryDataAddress.toString(),
        clubBasicInfo.address,
        wallet,
        activeTreasury.fundraiseCount,
        clubBasicInfo.clubType,
        FundraiseAction.Create,
        values.allocationLimitation === AllocationLimitation.Limited
          ? Number(values.tokensMembersAmount) *
              Number(pow(10, activeTreasury.currencyDecimals))
          : undefined,
        arrayCustomAllocation.length > 0 ? arrayCustomAllocation : undefined,
        Number(values.amount) *
          Number(pow(10, activeTreasury.currencyDecimals)),
        activeTreasury.financialTokenAccount,
        activeTreasury.denominatedCurrency
      );

      const tokenUnlockingInput: ITokenUnlockingInput[] =
        mapTokenUnlockingDateArray(
          values.tokenUnlockDate,
          fundraiseAddress.toString()
        );

      await saveTokenUnlockingData(
        wallet.publicKey.toString(),
        clubBasicInfo.address,
        tokenUnlockingInput
      );

      await sleep(1000);
      await apolloClient.refetchQueries({
        include: [GET_FUNDRAISES_FOR_TREASURY],
      });
      createNotification(
        MESSAGE_TYPE.SUCCESS,
        "Fundraise successfully created"
      );

      onClose();
    } catch (error) {
      createNotification(MESSAGE_TYPE.ERROR, "Fundraise creation failed ");
      console.log(error);
    }
  };

  const editFundraise = async (
    values: ICreateFundraiseFormFields,
    fundraiseInProgress: IFundraiseConfig,
    activeTreasury: ITreasuryData,
    memberData: IMemberData,
    wallet: AnchorWallet
  ) => {
    try {
      const updatedCustomAll: ICustomAllocationForProgram[] = [];
      const removedCustomAll: ICustomAllocationForProgram[] = [];

      fundraiseInProgress?.allocation?.custom?.map((item) => {
        let allocationExists = false;
        values.members.map((member) => {
          if (member.wallet.includes(item.authority)) {
            allocationExists = true;
            if (
              Number(member.membersAmount) *
                Number(pow(10, activeTreasury.currencyDecimals)) !==
              item.amount
            ) {
              updatedCustomAll.push({
                authority: new PublicKey(item.authority),
                amount: new BN(
                  Number(member.membersAmount) *
                    Number(pow(10, activeTreasury.currencyDecimals))
                ),
              });
            }
          }
        });
        if (!allocationExists) {
          removedCustomAll.push({
            authority: new PublicKey(item.authority),
            amount: new BN(Number(item.amount)),
          });
        }
      });

      values.members.forEach((member) => {
        member.wallet.forEach((wallet) => {
          if (
            !fundraiseInProgress?.allocation?.custom?.find(
              (item) => item.authority === wallet
            )
          ) {
            updatedCustomAll.push({
              authority: new PublicKey(wallet),
              amount: new BN(
                Number(member.membersAmount) *
                  Number(pow(10, activeTreasury.currencyDecimals))
              ),
            });
          }
        });
      });

      const equal =
        values.allocationLimitation === AllocationLimitation.Limited
          ? Number(values.tokensMembersAmount) *
            Number(pow(10, activeTreasury.currencyDecimals))
          : null;
      if (
        updatedCustomAll.length > 0 ||
        removedCustomAll.length > 0 ||
        equal !== fundraiseInProgress.allocation?.equal
      ) {
        await updateAllocation(
          activeTreasury.treasuryDataAddress,
          activeTreasury.clubDataAddress,
          memberData?.address,
          wallet,
          fundraiseInProgress.address,
          equal,
          updatedCustomAll,
          removedCustomAll,
          Number(values.amount) *
            Number(pow(10, activeTreasury.currencyDecimals))
        );

        await sleep(1000);
        await apolloClient.refetchQueries({
          include: [GET_FUNDRAISES_FOR_TREASURY],
        });

        createNotification(
          MESSAGE_TYPE.SUCCESS,
          "Fundraise successfully updated "
        );

        onClose();
      } else {
        createNotification(MESSAGE_TYPE.ERROR, "Nothing changed");
      }
    } catch (error) {
      createNotification(MESSAGE_TYPE.ERROR, "Failed to update fundraise");
    }
  };

  return (
    <Modal title="Create Fundraise" closeModal={onClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validateOnBlur
        validate={(values) => validateCreateFundraiseForm(values, {})}
      >
        <Form id={createFundraiseFormConfig.formId}>
          <CreateFundraiseForm formFields={formFields} />
          <FormActions
            buttonText={edit ? "Update fundraise" : "Create fundraise"}
            buttonAction={() => handleSubmitForm}
            cancelAction={onClose}
          />
        </Form>
      </Formik>
    </Modal>
  );
};
export default CreateFundraiseModal;
