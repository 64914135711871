import { FC, useCallback } from "react";
import { TableCell, TableRow } from "@mui/material";
import BasicDetailsCard from "../../../components/BasicDetailsCard/BasicDetailsCard";
import Chip from "../../../components/Chip/Chip";
import { getTrimmedPublicKey } from "../../../utilities/helpers";
import MainButton from "../../../components/MainButton/MainButton";
import { GREEN100 } from "../../../common/constants/layout.constants";
import { IDashboardWithdrawal } from "../../../common/interfaces/dashboard.interface";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { useDashboardStore } from "../../../state/dashboardStore";
import { MESSAGE_TYPE } from "../../../common/constants/common.constants";
import { createNotification } from "../../../utilities/notifications";

const WithdrawalTableRow: FC<{
  withdrawal: IDashboardWithdrawal;
}> = ({ withdrawal }) => {
  const wallet = useAnchorWallet();
  const { claimDashboard } = useDashboardStore();
  const handleClaim = useCallback(async () => {
    try {
      if (wallet) {
        await claimDashboard(wallet, withdrawal);
        createNotification(MESSAGE_TYPE.SUCCESS, "Succesfully claimed");
      }
    } catch (error: any) {
      createNotification(MESSAGE_TYPE.ERROR, error.message);
      console.log(error);
    }
  }, [wallet]);

  return (
    <TableRow>
      <TableCell style={{ fontSize: "16px" }} width={"40%"}>
        <BasicDetailsCard
          alt=""
          title="Treasury name"
          id={getTrimmedPublicKey(withdrawal.treasury)}
        />
      </TableCell>
      <TableCell width={"15%"}>
        <p className="home-tables__small-p">{withdrawal.proposalName}</p>
      </TableCell>
      <TableCell>
        <p className="home-tables-box--bold">
          {withdrawal.withdrawAmount /
            Math.pow(10, withdrawal.withdrawalToken.decimals)}{" "}
          <span>{withdrawal.withdrawalToken.symbol}</span>
        </p>
      </TableCell>
      <TableCell>
        <p className="home-tables-box--bold">
          {withdrawal.withdrawalRecord.withdrawAmount /
            Math.pow(10, withdrawal.withdrawalToken.decimals)}{" "}
          <span>{withdrawal.withdrawalToken.symbol}</span>
        </p>
      </TableCell>
      <TableCell>
        <p className="home-tables__small-p">
          {new Date(withdrawal.createdAt).toDateString()}
        </p>
      </TableCell>
      <TableCell width={"10%"}>
        <div className="home-tables__align">
          {!withdrawal.withdrawalRecord.hasWithdrawn ? (
            <MainButton type="button" light onClick={handleClaim}>
              Claim
            </MainButton>
          ) : (
            <Chip text="Claimed" backgroundColor={GREEN100} fontSize="0.85" />
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default WithdrawalTableRow;
