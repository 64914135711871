import { amount } from "@metaplex-foundation/js";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import { IFundraiseFormFields } from "../../common/interfaces/form.interface";
import { clubStore } from "../../state/clubStore";

export const fundraiseFormValidator = (
  values: IFundraiseFormFields,
  maxDeposit?: number,
  maxWithdraw?: number
) => {
  const errors: any = {};
  const { activeTreasury } = clubStore.getState();

  if (maxDeposit && Number(values.amount) > maxDeposit) {
    errors.amount = `Your max deposit amount is: ${maxDeposit} ${activeTreasury?.currencySymbol}`;
  }

  if (maxWithdraw && Number(values.amount) > maxWithdraw) {
    errors.amount = `Your max withdraw amount is: ${maxWithdraw} ${activeTreasury?.currencySymbol}`;
  }

  if (Number(values.amount) <= 0) {
    errors.amount = "Amount can't be zero";
  }

  if (values.amount === EMPTY_STRING) {
    errors.amount = "Field can't be empty";
  }

  if (isNaN(Number(values.amount))) {
    errors.amount = "Amount has to be a number";
  }
  return errors;
};
