import { Sort, SortLabel } from "../enums/common.enum";
import { ISortOption } from "../interfaces/common.interface";

export const sortVaultOptions: ISortOption[] = [
  {
    label: SortLabel.Collection,
    value: Sort.Collection,
  },
  {
    label: SortLabel.PurchasePrice,
    value: Sort.PurchasePrice,
  },
];

export const sortDealsOptions: ISortOption[] = [
  {
    label: SortLabel.DateAddedDesc,
    value: Sort.DateAddedDesc,
  },
  {
    label: SortLabel.DateAddedAsc,
    value: Sort.DateAddedAsc,
  },
  {
    label: SortLabel.ActiveFundraise,
    value: Sort.ActiveFundraise,
  },
  {
    label: SortLabel.TotalRaisedAsc,
    value: Sort.TotalRaisedAsc,
  },
  {
    label: SortLabel.TotalRaisedDesc,
    value: Sort.TotalRaisedDesc,
  },
];

export const sortMembersOptions = [
  {
    label: SortLabel.JoinDateEarliest,
    value: Sort.JoinDateEarliest,
  },
  {
    label: SortLabel.JoinDateLatest,
    value: Sort.JoinDateLatest,
  },
];

export const sortApplicationOptions = [
  {
    label: SortLabel.AppliedAtEarliest,
    value: Sort.AppliedAtEarliest,
  },
  {
    label: SortLabel.AppliedAtLatest,
    value: Sort.AppliedAtLatest,
  },
];

export const sortProposalsOptions = [
  {
    label: SortLabel.Status,
    value: Sort.Status,
  },
  {
    label: SortLabel.DataAddedLatest,
    value: Sort.DataAddedLatest,
  },
  {
    label: SortLabel.DateAdded,
    value: Sort.DateAdded,
  },
];

export const sortOTCOptions: ISortOption[] = [
  {
    label: SortLabel.AmountOfSharesAsc,
    value: Sort.AmountOfSharesAsc,
  },
  {
    label: SortLabel.AmountOfSharesDesc,
    value: Sort.AmountOfSharesDesc,
  },
  {
    label: SortLabel.TotalPriceAsc,
    value: Sort.TotalPriceAsc,
  },
  {
    label: SortLabel.TotalPriceDesc,
    value: Sort.TotalPriceDesc,
  },
  {
    label: SortLabel.PricePerPctAsc,
    value: Sort.PricePerPctAsc,
  },
  {
    label: SortLabel.PricePerPctDesc,
    value: Sort.PricePerPctDesc,
  },
];
