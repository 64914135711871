import { FC, useEffect, useMemo, useState } from "react";
import "./Fundraise.scss";
import Allocation from "../Allocation/Allocation";
import Deposit from "../Deposit/Deposit";
import { ITEMS_PER_PAGE } from "../../../common/constants/common.constants";
import Pagination from "../../../components/Pagination/Pagination";
import {
  checkIfUserHasTreasuryPermissionForAction,
  subsetArrayForPagination,
} from "../../../utilities/helpers";
import { TabLabel } from "../../../common/enums/common.enum";
import {
  ICustomAllocation,
  IFundraiseConfig,
  IHistoricalDeposit,
} from "../../../common/interfaces/club.interface";
import NoData from "../../../components/NoData/NoData";
import { useLazyQuery } from "@apollo/client";
import { GET_HISTORICAL_DEPOSITS } from "../../../api/club.api";
import { clubStore } from "../../../state/clubStore";
import CreateFundraiseModal from "../../../components/CreateFundraiseFormModal/CreateFundraiseModal";
import CreateProposalModal from "../../../components/CreateProposal/CreateProposalModal";
import { ProposalType } from "../../../common/enums/proposal.enum";
import { TreasuryAction } from "../../../common/enums/clubs.enum";

const Fundraise: FC<{
  fundraise: IFundraiseConfig;
}> = ({ fundraise }) => {
  const [createFundraise, toggleCreateFundraise] = useState(false);
  const [createFundraiseProposal, toggleFundraiseProposal] = useState(false);
  const [activeTab, setActiveTab] = useState(TabLabel.Deposits);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState<number>();
  const [allocations, setAllocations] = useState<ICustomAllocation[]>();
  const { activeTreasury, fundraises, memberTreasuryInfo } = clubStore();

  const [getAllHistoricalDeposits, getAllHistoricalDepositsInfo] = useLazyQuery(
    GET_HISTORICAL_DEPOSITS,
    {
      onCompleted: (data) => {
        setTotalItems(data.getFinancialLedgerForTreasury.count);
      },
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    getHistoryTabData();
  }, [currentPage, activeTab]);

  const getHistoryTabData = () => {
    if (activeTab === TabLabel.Allocation) {
      const customAllocations =
        fundraise.allocation?.custom &&
        subsetArrayForPagination<ICustomAllocation>(
          fundraise.allocation?.custom,
          currentPage,
          ITEMS_PER_PAGE
        );
      setAllocations(customAllocations);
      setTotalItems(fundraise.allocation?.custom?.length);
    } else {
      getAllHistoricalDeposits({
        variables: {
          treasuryAddress: activeTreasury?.treasuryAddress,
          financialLedgerFilter: {
            page: currentPage + 1,
            activeFundraise: true,
          },
        },
      });
    }
  };

  const showFundraisesTabData = useMemo(() => {
    switch (activeTab) {
      case TabLabel.Allocation:
        return allocations?.map((allocation) => (
          <Allocation allocation={allocation} />
        ));

      case TabLabel.Deposits:
        return getAllHistoricalDepositsInfo?.data?.getFinancialLedgerForTreasury.financialLedger.map(
          (item: IHistoricalDeposit) => <Deposit item={item} />
        );
      default:
        return [];
    }
  }, [activeTab, getAllHistoricalDepositsInfo, allocations]);

  return (
    <>
      <div className="fundraise-actions">
        {createFundraise && (
          <CreateFundraiseModal
            onClose={() => toggleCreateFundraise(false)}
            edit={false}
          />
        )}

        {createFundraiseProposal && (
          <CreateProposalModal
            proposalType={ProposalType.Fundraise}
            closeModal={() => toggleFundraiseProposal(false)}
          />
        )}
        <h2
          className={`fundraise-actions__deposits-tab
            ${
              activeTab === TabLabel.Deposits &&
              "fundraise-actions__deposits-tab--open"
            }`}
          onClick={() => setActiveTab(TabLabel.Deposits)}
        >
          Deposits
        </h2>
        {fundraise.allocation?.custom && (
          <h2
            className={`fundraise-actions__allocation-tab 
            ${
              activeTab === TabLabel.Allocation &&
              "fundraise-actions__allocation-tab--open"
            }`}
            onClick={() => setActiveTab(TabLabel.Allocation)}
          >
            Allocation
          </h2>
        )}
      </div>

      {totalItems && totalItems > 0 ? (
        <div className="members__cards">
          {showFundraisesTabData}{" "}
          <Pagination
            totalPages={totalItems ? Math.ceil(totalItems / ITEMS_PER_PAGE) : 0}
            currentPage={currentPage}
            handlePageChange={({ selected }) => {
              setCurrentPage(selected);
            }}
          />
        </div>
      ) : (
        <NoData
          noIcon={false}
          message={
            activeTab === TabLabel.Allocation
              ? "This club has no active allocation"
              : "This club has no active deposites"
          }
          mainButtonText={"Create Fundraise"}
          mainButtonOnClick={
            fundraises && fundraises.length > 0
              ? () => toggleFundraiseProposal(true)
              : () => toggleCreateFundraise(true)
          }
          disabled={
            !checkIfUserHasTreasuryPermissionForAction(
              memberTreasuryInfo,
              activeTreasury?.treasuryRoleConfig,
              TreasuryAction.Fundraise
            )
          }
        />
      )}
    </>
  );
};

export default Fundraise;
