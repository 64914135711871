import { YELLOW100 } from "../../../common/constants/layout.constants";
import Chip from "../../Chip/Chip";
import InputField from "../../InputFields/InputField";
import createFundraiseFormConfig from "../createFundraiseFormConfig";
import errorIcon from "../../../assets/error.svg";
import "./CreateFundraiseForm.scss";
import add from "../../../assets/add_circle_full.png";
import CreateAllocation from "../CreateAllocation/CreateAllocation";
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import {
  ICreateFundraiseFormFields,
  IProposalFormFields,
} from "../../../common/interfaces/form.interface";
import MainButton from "../../MainButton/MainButton";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import cancelIcon from "../../../assets/cancel.svg";
import { clubStore } from "../../../state/clubStore";
import FeeCard from "../../FeeCard/FeeCard";
import { getAdminConfigInfo } from "../../../program/program-helpers";
import { IFeeConfig } from "../../../common/interfaces/treasury.interface";

const CreateFundraiseForm: React.FC<{
  formFields: any;
  throughProposal?: boolean;
}> = ({ formFields, throughProposal }) => {
  const { values } = useFormikContext<
    ICreateFundraiseFormFields | IProposalFormFields
  >();
  const { activeTreasury } = clubStore();
  const [fundraiseFeeConfig, setFundraiseFeeConfig] = useState<IFeeConfig[]>();

  useEffect(() => {
    getAdminFeeInfo();
  }, []);

  const getAdminFeeInfo = async () => {
    try {
      const admin = await getAdminConfigInfo();
      setFundraiseFeeConfig(admin.fundraiseFeeConfigs);
    } catch (error) {
      console.log(error);
    }
  };

  const addMemberItem = (arrayHelper: FieldArrayRenderProps) => {
    arrayHelper.push({
      tokenAmount: 0,
      date: EMPTY_STRING,
    });
  };

  const removeOption = useCallback(
    (index: number, arrayHelpers: FieldArrayRenderProps) => {
      arrayHelpers.remove(index);
    },
    []
  );

  const getFeesAmount = useMemo(() => {
    return (
      (fundraiseFeeConfig?.reduce((acc, val) => acc + val.feePercentage, 0) ??
        0) / 100
    );
  }, [fundraiseFeeConfig]);

  return (
    <div className="create-fundraise-form">
      <h2>Treasury</h2>
      <h5>Define how much money you would like to raise</h5>
      <InputField
        name={formFields.amount.name}
        type="text"
        labelTitle={formFields.amount.label}
        placeholder={formFields.amount.label}
        badge={activeTreasury?.currencySymbol}
      />
      <FieldArray
        name={formFields.tokenUnlockDate.name}
        render={(arrayHelpers) => (
          <>
            {values.tokenUnlockDate.map((item, index) => (
              <div className="create-fundraise-form__treasury" key={index}>
                <img
                  src={cancelIcon}
                  alt="Cancel icon"
                  onClick={() => removeOption(index, arrayHelpers)}
                />
                <InputField
                  name={`tokenUnlockDate.${index}.tokenAmount`}
                  type="number"
                  labelTitle={`Token per $`}
                  placeholder={`Token per $`}
                />
                <InputField
                  name={`tokenUnlockDate.${index}.date`}
                  type="date-picker"
                />
              </div>
            ))}
            <div className="create-fundraise-form__custom-actions">
              <MainButton
                onClick={() => {
                  addMemberItem(arrayHelpers);
                }}
                type="button"
                light
              >
                <img src={add} alt="Add" />
                Add unlock date
              </MainButton>
            </div>
          </>
        )}
      ></FieldArray>
      {!throughProposal && <CreateAllocation formFields={formFields} />}
      {getFeesAmount > 0 && (
        <FeeCard>
          <p>
            Platform fee is <b>{getFeesAmount} %</b> of Fundraise amount.
          </p>
        </FeeCard>
      )}
      {!throughProposal && (
        <div className="create-fundraise-form__call-to-action">
          <Chip
            text="Creating fundraise fee ≈ 0.0215 SOL"
            backgroundColor={YELLOW100}
            stretch
            fontSize="0.8"
            padding="14px"
            icon
            image={errorIcon}
          />
        </div>
      )}
    </div>
  );
};
export default CreateFundraiseForm;
