import { PublicKey } from "@solana/web3.js";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import {
  MAX_APPROVAL_PERCENTAGE,
  MAX_VOTING_DAYS,
  MIN_APPROVAL_PERCENTAGE,
  MIN_VOTING_DAYS,
} from "../../common/constants/proposal.constants";

import {
  ChangeConfigurationTypeEnum,
  ChangeGovernanceTypeEnum,
  ProposalType,
} from "../../common/enums/proposal.enum";
import { IProposalFormFields } from "../../common/interfaces/form.interface";
import { validateCreateFundraiseForm } from "../CreateFundraiseFormModal/createFundraiseFormValidate";
import { clubStore } from "../../state/clubStore";
import { ITreasuryData } from "../../common/interfaces/club.interface";

export const proposalFormValidator = (values: IProposalFormFields) => {
  const errors: any = {};
  const { activeTreasury } = clubStore.getState();

  if (values.title === EMPTY_STRING) {
    errors.title = "Title can't be empty";
  }

  if (values.description === EMPTY_STRING) {
    errors.description = "Description can't be empty";
  }

  //WILL BE USED LATER
  switch (values.proposalType) {
    case ProposalType.Trading:
      break;
    case ProposalType.TransferFunds:
      validateTransferFundsProposal(values, errors);
      break;
    case ProposalType.Withdrawal:
      validateWithdrawalProposal(values, errors);
      break;
    case ProposalType.ConfigurationChanges:
      activeTreasury &&
        validateConfigurationChangesProposal(values, errors, activeTreasury);

      break;
    case ProposalType.Fundraise:
      validateCreateFundraiseForm(values, errors);
  }
  return errors;
};

const validateTransferFundsProposal = (
  values: IProposalFormFields,
  errors: any
) => {
  if (values.transferAmount === EMPTY_STRING) {
    errors.transferAmount = "Transfer amount can't be empty";
  }

  if (Number(values.transferAmount) <= 0) {
    errors.transferAmount = "Transfer amount can't be zero";
  }

  if (isNaN(Number(values.transferAmount))) {
    errors.transferAmount = "Transfer amount has to be a number";
  }

  if (values.transferAddress === EMPTY_STRING) {
    errors.transferAddress = "Transfer address can't be empty";
  }

  if (values.transferAddress !== EMPTY_STRING) {
    try {
      const publicKey = new PublicKey(values.transferAddress);
    } catch (error) {
      errors.transferAddress = "Public key is not valid";
    }
  }

  return errors;
};

const validateWithdrawalProposal = (
  values: IProposalFormFields,
  errors: any
) => {
  if (Number(values.withdrawalAmount) <= 0) {
    errors.withdrawalAmount = "Withdrawal amount can't be zero";
  }

  if (values.withdrawalAmount === EMPTY_STRING) {
    errors.withdrawalAmount = "Withdrawal amount can't be empty";
  }
  return errors;
};
const validateConfigurationChangesProposal = (
  values: IProposalFormFields,
  errors: any,
  treasury: ITreasuryData
) => {
  if (
    values.changeConfigurationType ===
      ChangeConfigurationTypeEnum.VotingPower ||
    values.changeConfigurationType ===
      ChangeConfigurationTypeEnum.VotePercentage
  ) {
    validateVotingPower(values, errors);
  }

  if (
    values.changeGovernanceType ===
    ChangeGovernanceTypeEnum.TradeApprovalConfiguration
  ) {
    validateTradeApproval(values, errors, treasury);
  } else {
    validateChangeGovernanceConfiguration(values, errors);
  }
};

const validateChangeGovernanceConfiguration = (
  values: IProposalFormFields,
  errors: any
) => {
  if (values.approvalVotePercentage.toString() === EMPTY_STRING) {
    errors.approvalVotePercentage = "New approval percentage can't be empty";
  }

  if (
    values.approvalVotePercentage > MAX_APPROVAL_PERCENTAGE ||
    values.approvalVotePercentage < MIN_APPROVAL_PERCENTAGE
  ) {
    errors.approvalVotePercentage =
      "New approval percentage can't be greater than 100% and less than 51%";
  }

  if (
    Number(values.maxVotingTime) > MAX_VOTING_DAYS ||
    Number(values.maxVotingTime) < MIN_VOTING_DAYS
  ) {
    errors.maxVotingTime =
      "New voting time can't be greater than 30 days and less than 1 day";
  }

  if (
    values.maxVotingTime.toString().includes(".") ||
    values.maxVotingTime.toString().includes(",")
  ) {
    errors.maxVotingTime = "New voting time can't be decimal";
  }
};

const validateVotingPower = (values: IProposalFormFields, errors: any) => {
  values.votingPowers.forEach((item, index) => {
    if (item.role === EMPTY_STRING) {
      errors[`votingPowers.${index}.roleVotingPower`] =
        "Role voting power can't be empty";
    }
    if (item.role !== EMPTY_STRING && item.roleVotingPower > 1000000) {
      errors[`votingPowers.${index}.roleVotingPower`] =
        "Role voting power can't be greater than 1000000";
    }

    if (item.role !== EMPTY_STRING && item.roleVotingPower < 1) {
      errors[`votingPowers.${index}.roleVotingPower`] =
        "Role voting power can't be less than 1";
    }
  });
};

const validateTradeApproval = (
  values: IProposalFormFields,
  errors: any,
  activeTreasury: ITreasuryData
) => {
  if (Number(values.from) < 0) {
    errors.from = "Value from can't be less than zero";
  }
  if (values.from.toString() === EMPTY_STRING) {
    errors.from = "Value from can't be empty";
  }

  if (Number(values.to) < 0) {
    errors.to = "Value to can't be less than zero";
  }
  if (values.to.toString() === EMPTY_STRING) {
    errors.to = "Value to can't be empty";
  }

  if (Number(values.quorum) < 0) {
    errors.to = "Quorum can't be less than zero";
  }
  if (Number(values.quorum) > 100) {
    errors.quorum = "Quorum can't be greater than 100";
  }

  if (values.quorum.toString() === EMPTY_STRING) {
    errors.quorum = "Value quorum can't be empty";
  }

  if (Number(values.from) >= Number(values.to)) {
    errors.to = "Value to has to be greater than value from";
  }

  if (
    !values.sellPermision &&
    activeTreasury.sellPermission.find(
      (sellPermission) =>
        Number(values.from) < sellPermission.to &&
        Number(values.to) > sellPermission.from
    )
  ) {
    errors.quorum = "You have already set values for this range";
  }
};
