import { error } from "console";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import { IClubApplicationFormFields } from "../../../common/interfaces/form.interface";
import { validateSocialNetworks } from "../../../utilities/helpers";

export const validateClubApplicationForm = (
  values: IClubApplicationFormFields,
  errors: any
) => {
  if (values.motivationLetter === EMPTY_STRING) {
    errors.motivationLetter = "Motivation letter can't be empty!";
  }

  validateSocialNetworks(values, errors);

  return errors;
};
