import React, { useMemo } from "react";
import "./WithdrawalProposal.scss";
import proposalFormConfig from "../proposalFormConfig";
import InputField from "../../InputFields/InputField";
import { IOption } from "../../../common/interfaces/common.interface";
import { useFormikContext } from "formik";
import { IProposalFormFields } from "../../../common/interfaces/form.interface";
import { clubStore } from "../../../state/clubStore";
import { getAmountWithDecimalsForCurrency } from "../../../utilities/helpers";
import AmountWithMint from "../AmountWithMint/AmountWithMint";

const WithdrawalProposal = () => {
  return (
    <div className="withdrawal-proposal">
      <div>
        <h3>Withdrawal</h3>
        <AmountWithMint withdrawal />
      </div>
    </div>
  );
};

export default WithdrawalProposal;
