import { ISocialNetwork } from "../../../common/interfaces/club.interface";
import "./ApplicationAndMemberDetailsModal.scss";
import FormActions from "../../../components/FormActions/FormActions";
import Modal from "../../../components/Modal/Modal";
import { clubStore } from "../../../state/clubStore";
import copyIcon from "../../../assets/content_copy.png";
import UserImage from "../../../components/UserImage/UserImage";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import SelectField from "../../../components/SelectField/SelectField";
import { useMemo, useState } from "react";
import {
  getTrimmedPublicKey,
  handleCopyToClipboard,
} from "../../../utilities/helpers";
import { SocialNetworkType } from "../../../common/enums/clubs.enum";
import telegram from "../../../assets/telegram dark.svg";
import discord from "../../../assets/discord black.svg";
import linkedin from "../../../assets/linkedin.svg";
import twitter from "../../../assets/twitter.svg";
import mail from "../../../assets/mail black.svg";
import ClubMemberTreasuries from "./ClubMemberTreasuriesModal";
import { MaterialUIMenuItem } from "../../../common/constants/mui.constants";
import { CLIENT_CONFIGURATION } from "../../../client/configuration";

const ApplicationAndMemberDetailsModal: React.FC<{
  onClose: () => void;
  imageUrl?: string;
  memberName?: string;
  publicKey: string;
  networks?: ISocialNetwork[];
  motivationLetter?: string;
  handleDenyApplication: () => void;
  handleAcceptApplication: (role: string) => void;
  title: string;
  alreadyAMember: boolean;
}> = ({
  onClose,
  publicKey,
  imageUrl,
  memberName,
  motivationLetter,
  networks,
  handleAcceptApplication,
  handleDenyApplication,
  title,
  alreadyAMember,
}) => {
  const { clubBasicInfo } = clubStore();
  const roleNames = useMemo(() => {
    return clubBasicInfo?.roleConfig
      .filter((role) => {
        const clientRole = CLIENT_CONFIGURATION.clubRoles.find(
          (item) => item.name === role.name
        );
        return !clientRole?.isFounder;
      })
      .map((item) => item.name);
  }, [clubBasicInfo]);
  const [role, setRole] = useState(roleNames && roleNames[0]);

  const findSocialNet = (value: string, type: SocialNetworkType) => {
    switch (type) {
      case SocialNetworkType.Discord:
        return { image: discord, link: value };
      case SocialNetworkType.Telegram:
        return { image: telegram, link: value };
      case SocialNetworkType.Twitter:
        return { image: twitter, link: value };
      case SocialNetworkType.Mail:
        return {
          image: mail,
          link: `mailto:${value}.com?body=`,
        };
      case SocialNetworkType.LinkedIn:
        return { image: linkedin, link: value };
      default:
        return { image: EMPTY_STRING, link: EMPTY_STRING };
    }
  };

  return (
    <Modal title={title} closeModal={onClose}>
      <div className="member-application">
        <div className="member-application__info">
          <UserImage
            width="56"
            image={imageUrl ? imageUrl : EMPTY_STRING}
            name={memberName ? memberName : publicKey}
          />
          <div className="member-application__name">
            <p>{memberName ? memberName : publicKey} </p>
            {!memberName && (
              <img
                src={copyIcon}
                className="member-application__wallet-copy"
                onClick={() => handleCopyToClipboard(publicKey)}
              />
            )}
          </div>
          <div className="member-application__social-networks">
            {networks?.map((item, index) => {
              return (
                <a href={findSocialNet(item.value, item.type).link}>
                  <img src={findSocialNet(item.value, item.type).image} />
                </a>
              );
            })}
          </div>
          {memberName && (
            <div className="member-application__wallet">
              <p>{getTrimmedPublicKey(publicKey)}</p>
              <img
                src={copyIcon}
                className="member-application__wallet-copy"
                onClick={() => handleCopyToClipboard(publicKey)}
              />
            </div>
          )}
          <p className="member-application__motivation-letter">
            {motivationLetter}
          </p>
        </div>
      </div>
      {!alreadyAMember ? (
        <>
          <div className="member-application__roles">
            {clubBasicInfo && role && roleNames && (
              <SelectField
                activeValue={role}
                onChange={(value: string) => setRole(value)}
                labelTitle="Role"
              >
                {roleNames.map((item) => (
                  <MaterialUIMenuItem value={item}>{item}</MaterialUIMenuItem>
                ))}
              </SelectField>
            )}
          </div>
          <FormActions
            buttonAction={() => role && handleAcceptApplication(role)}
            buttonText="Accept application"
            cancelBtnText="Deny Application"
            cancelAction={handleDenyApplication}
          />
        </>
      ) : (
        <ClubMemberTreasuries
          clubAddress={clubBasicInfo?.address}
          userAddress={publicKey}
        />
      )}
    </Modal>
  );
};

export default ApplicationAndMemberDetailsModal;
