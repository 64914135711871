import React, { FC, useMemo } from "react";
import {
  IProposal,
  IWithdrawalProposalMetadata,
} from "../../../common/interfaces/proposal.interface";
import { ProposalTypeProgram } from "../../../common/enums/proposal.enum";
import MainButton from "../../MainButton/MainButton";
import { PublicKey } from "@solana/web3.js";
import { MESSAGE_TYPE } from "../../../common/constants/common.constants";
import { claimWithdrawal } from "../../../program/methods/proposals";
import { createNotification } from "../../../utilities/notifications";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { clubStore } from "../../../state/clubStore";

const ProposalCompletedActions: FC<{ proposal: IProposal }> = ({
  proposal,
}) => {
  const { activeTreasury, memberData } = clubStore();
  const wallet = useAnchorWallet();

  const claimWithdrawalAmount = async () => {
    try {
      const proposalDetails: IWithdrawalProposalMetadata =
        proposal.proposalDetails as IWithdrawalProposalMetadata;
      if (!activeTreasury || !memberData || !wallet) {
        createNotification(MESSAGE_TYPE.ERROR, "Missing withdraw data");
        return;
      }
      await claimWithdrawal(
        new PublicKey(proposalDetails.withdrawalData),
        new PublicKey(memberData.address),
        wallet,
        new PublicKey(activeTreasury.treasuryDataAddress),
        new PublicKey(proposalDetails.withdrawal),
        new PublicKey(proposalDetails.withdrawalMint)
      );
      createNotification(MESSAGE_TYPE.SUCCESS, "Successfully claimed");
    } catch (error) {
      console.log(error);
      createNotification(MESSAGE_TYPE.ERROR, "Failed to claim");
    }
  };

  const renderProposalActionButton = useMemo(() => {
    switch (proposal.proposalMetadata.proposalType) {
      case ProposalTypeProgram.Withdrawal:
        const proposalDetails: IWithdrawalProposalMetadata =
          proposal.proposalDetails as IWithdrawalProposalMetadata;
        if (
          proposalDetails.withdrawalMembersInfo &&
          !proposalDetails.withdrawalMembersInfo?.hasUserClaimed
        )
          return (
            <MainButton onClick={claimWithdrawalAmount} type="button">
              Claim
            </MainButton>
          );
      //TODO: add actions for other types (Trading, Change gov,..)
    }
  }, [proposal]);

  return (
    <div className="proposals-completed-actions">
      {renderProposalActionButton}
    </div>
  );
};

export default ProposalCompletedActions;
