import { EMPTY_STRING } from "../../common/constants/common.constants";
import { IUserAccountSettingsFormFields } from "../../common/interfaces/form.interface";
import { validateSocialNetworks } from "../../utilities/helpers";

export const validateUserAccountSettings = (
  values: IUserAccountSettingsFormFields,
  errors: any
) => {
  validateSocialNetworks(values, errors);

  return errors;
};
