export enum FundraiseStatus {
  InProgress = "In Progress",
  NoFundraise = "No Fundraise",
  Finished = "Finished",
}

export enum AllocationLimitation {
  Limited = "Limited",
  Unlimited = "Unlimited",
}

export enum FundraiseAction {
  Create,
  Finish,
}

export enum SupportAction {
  Deposit,
  Withdraw,
}
export enum SupportActionLabel {
  Deposit = "Deposit",
  Withdraw = "Withdraw",
}
