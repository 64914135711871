import React from "react";
import DetailsCard from "../../../components/DetailsCard/DetailsCard";
import Chip from "../../../components/Chip/Chip";
import profile from "../../../assets/Profilepicture.png";
import BasicDetailsCard from "../../../components/BasicDetailsCard/BasicDetailsCard";
import { WHITE300 } from "../../../common/constants/layout.constants";

type Props = {};

const TeamCard = (props: Props) => {
  const TEAM = [
    {
      image: profile,
      name: "Alex Migitko",
      position: "CEO",
    },
    {
      image: profile,
      name: "Martin Kardzhilov",
      position: "CBDO",
    },
    {
      image: profile,
      name: "Uros Sosevic",
      position: "CTO",
    },
  ];
  return (
    <DetailsCard title="Team">
      <div className="intro-deck__details-card">
        {TEAM.map((t, index) => (
          <BasicDetailsCard
            image={t.image}
            alt="Profile"
            title={t.name}
            id={t.position}
            key={index}
          />
        ))}
      </div>
      <div className="intro-deck__details-card-chip">
        <Chip
          text={"+16 more full-time employees"}
          backgroundColor={WHITE300}
        />
      </div>
    </DetailsCard>
  );
};

export default TeamCard;
