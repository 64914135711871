import React, { Fragment } from "react";
import removeIcon from "../../assets/removeImg.svg";
import addPhotoIcon from "../../assets/add_photo_alternate.svg";
import { useDropzone } from "react-dropzone";
import {
  EMPTY_STRING,
  MAX_IMAGE_SIZE,
} from "../../common/constants/common.constants";
import { uploadImage, validateImageFormat } from "../../utilities/helpers";
import { FormikErrors } from "formik";
import { IUserAccountSettingsFormFields } from "../../common/interfaces/form.interface";
import "./UploadPhoto.scss";
const UploadPhoto: React.FC<{
  imageUrl: string;
  imageFormField: string;
  imageText: string;
  removeImage: (e: { stopPropagation: () => void }) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<IUserAccountSettingsFormFields>>;
}> = ({ imageUrl, imageFormField, imageText, removeImage, setFieldValue }) => {
  const reader = new FileReader();

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/*": [],
    },
    maxSize: MAX_IMAGE_SIZE,
    onDrop: (acceptedFiles) => {
      validateImageFormat(acceptedFiles);
      if (!acceptedFiles[0]) {
        return;
      }
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = async () => {
        const base64ImgFormat: any = reader.result;
        setFieldValue(imageFormField, base64ImgFormat);
      };
    },
  });
  return (
    <div className="upload-photo">
      <div className="upload-photo__image">
        <div
          {...getRootProps()}
          onClick={open}
          className="upload-photo__image-placeholder"
        >
          <input {...getInputProps()} />

          {imageUrl && imageUrl !== EMPTY_STRING ? (
            <Fragment>
              <img
                className="upload-photo__avatar"
                src={imageUrl}
                alt="Org avatar"
              />
              <img
                className="upload-photo__removeIcon"
                src={removeIcon}
                alt="Remove avatar"
                onClick={removeImage}
              />
            </Fragment>
          ) : (
            <img src={addPhotoIcon} alt=" avatar" />
          )}
        </div>
      </div>
      <p className="upload-photo__data">
        {imageText} <br /> Formats:png,jpg,gif. Max size: 2MB
      </p>
    </div>
  );
};

export default UploadPhoto;
