import { FC, useEffect, useRef, useState } from "react";
import flag from "../../../assets/flag.svg";
import MainButton from "../../../components/MainButton/MainButton";
import { createNotification } from "../../../utilities/notifications";
import {
  EMPTY_STRING,
  MESSAGE_TYPE,
} from "../../../common/constants/common.constants";
import Papa from "papaparse";
import { IExistingInvestment } from "../../../common/interfaces/treasury.interface";
import {
  extractNumbersFromString,
  notEligibleToUpload,
} from "../../../utilities/helpers";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { migrateFinancials } from "../../../program/methods/clubs";
import "./ActionsBeforeFundraise.scss";
import { clubStore } from "../../../state/clubStore";

const FundraiseCsv: FC = () => {
  const fileInputField: any = useRef(null);

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const { memberData, activeTreasury, clubBasicInfo } = clubStore();

  const wallet = useAnchorWallet();

  useEffect(() => {
    if (uploadedFile) {
      parseUploadedFile();
    }
  }, [setUploadedFile, uploadedFile]);

  const handleUploadBtnClick = () => {
    if (fileInputField && fileInputField.current) {
      fileInputField.current.click();
    }
  };

  const parseUploadedFile = () => {
    if (
      uploadedFile &&
      wallet &&
      memberData &&
      clubBasicInfo &&
      activeTreasury
    ) {
      if (uploadedFile.type !== "text/csv") {
        createNotification(MESSAGE_TYPE.ERROR, "Please only upload CSV files.");
        return;
      }
      Papa.parse(uploadedFile, {
        header: true,
        skipEmptyLines: true,
        complete: async (result) => {
          const jsonData = result.data as IExistingInvestment[];
          const importedDeposits = jsonData.map((memberDeposit) => ({
            wallet: memberDeposit.wallet,
            amount: memberDeposit.amount,
          }));
          const filteredDeposits = importedDeposits.filter((deposit) => {
            return (
              deposit.amount !== EMPTY_STRING &&
              deposit.wallet !== EMPTY_STRING &&
              deposit.amount !== undefined &&
              deposit.wallet !== undefined
            );
          });
          const { depositsPerMember } = handleMemberDeposits(filteredDeposits);

          try {
            await migrateFinancials(
              wallet,
              depositsPerMember,
              memberData,
              clubBasicInfo,
              activeTreasury
            );
          } catch (error) {
            console.log(error);
            createNotification(MESSAGE_TYPE.ERROR, "Failed to import CSV");
          }
        },
      });
    }
  };

  const handleMemberDeposits = (data: IExistingInvestment[]) => {
    let total = 0;
    let depositsPerMember: IExistingInvestment[] = [];
    let allDeposits: IExistingInvestment[] = [];
    let hasDuplicates = false;

    data.map((item) => {
      total += Number(extractNumbersFromString(item.amount));
      const duplicatedRecord = depositsPerMember.find(
        (e) => e.wallet === item.wallet
      );
      if (duplicatedRecord) {
        const totalRecordAmount =
          Number(duplicatedRecord.amount) +
          Number(extractNumbersFromString(item.amount));
        duplicatedRecord.amount = totalRecordAmount.toString();
        duplicatedRecord.isDuplicate = true;
        item.isDuplicate = true;
        hasDuplicates = true;

        const existingRecord = allDeposits.find(
          (e) => e.wallet === item.wallet
        );
        if (existingRecord) {
          existingRecord.isDuplicate = true;
        }

        const lastIndex = allDeposits.findIndex(
          (depositItem: IExistingInvestment) => {
            return depositItem.wallet === item.wallet;
          }
        );

        if (lastIndex !== -1) {
          allDeposits.splice(lastIndex, 0, parseInvitedMembers(item));
        }
      } else {
        item.isDuplicate = false;
        depositsPerMember.push(parseInvitedMembers(item));
        allDeposits.push(parseInvitedMembers(item));
      }
    });

    if (notEligibleToUpload(depositsPerMember, wallet?.publicKey.toString())) {
      createNotification(
        MESSAGE_TYPE.ERROR,
        "Maximum number of members per club (99 members) is reached. Please check your file and upload again."
      );
    }

    return {
      allDeposits,
      depositsPerMember,
      total,
      hasDuplicates,
    };
  };

  const parseInvitedMembers = (item: IExistingInvestment) => ({
    wallet: item.wallet,
    amount: extractNumbersFromString(item.amount),
    isDuplicate: item.isDuplicate,
  });

  return (
    <div className="actions-before-fundraise">
      <div className="actions-before-fundraise__info">
        <img
          src={flag}
          alt="flag icon"
          className="actions-before-fundraise__flag-icon"
        />
        <p className="actions-before-fundraise__description">
          You can import previously done fundraise through an csv file
        </p>
      </div>
      <div className="actions-before-fundraise__actions">
        <MainButton
          type="button"
          light
          squared
          onClick={() => {
            handleUploadBtnClick();
          }}
        >
          Import csv
        </MainButton>
        <input
          type="file"
          className="add-member-form__csv-input"
          ref={fileInputField}
          onChange={(e) => setUploadedFile(e.target.files && e.target.files[0])}
        />
      </div>
    </div>
  );
};

export default FundraiseCsv;
