import { EMPTY_STRING } from "../../common/constants/common.constants";
import { TreasuryStep } from "../../common/enums/clubs.enum";
import {
  IRoleConfig,
  ITreasuryFormFields,
} from "../../common/interfaces/form.interface";
import { validateMultipleMembersField } from "../../utilities/helpers";

export const validateTreasury = (
  values: ITreasuryFormFields,
  activeStep: TreasuryStep
) => {
  const errors: any = {};
  switch (activeStep) {
    case TreasuryStep.TreasuryConfiguration:
      validateTreasuryConfig(values, errors);
      break;
    case TreasuryStep.TreasuryRoles:
      validateRoles(values.treasuryRoles, "treasuryRoles", errors);
      break;
    case TreasuryStep.TreasuryOwnership:
      validateOwnership(values, errors);
      break;
  }
  return errors;
};

const validateTreasuryConfig = (values: ITreasuryFormFields, errors: any) => {
  if (values.treasuryName === EMPTY_STRING) {
    errors.treasuryName = "Treasury name can't be empty!";
  }

  if (values.chain === EMPTY_STRING) {
    errors.chain = "Chain can't be empty!";
  }

  if (values.denominatedCurrency === EMPTY_STRING) {
    errors.denominatedCurrency = "Choose denominated currency!";
  }

  if (values.approvalQuorum === EMPTY_STRING) {
    errors.approvalQuorum = "Approval quorum can't be empty!";
  }

  if (
    Number(values.approvalQuorum) < 51 ||
    Number(values.approvalQuorum) > 100
  ) {
    errors.approvalQuorum = "Approval quorum must be between 51 and 100";
  }

  if (values.maxVotingTime === EMPTY_STRING) {
    errors.maxVotingTime = "Max voting time can not be empty";
  }

  if (Number(values.maxVotingTime) < 7) {
    errors.maxVotingTime = "Max voting time can not be less than 7 days";
  }
};

const validateRoles = (
  roleConfigs: IRoleConfig[],
  arrayName: string,
  errors: any
) => {
  roleConfigs.forEach((item, index) => {
    if (Number(item.votingPower) <= 0 && item.chosen) {
      errors[`${arrayName}.${index}.votingPower`] =
        "Voting power can not be 0 or less then 0";
    }
  });
};

const validateOwnership = (values: ITreasuryFormFields, errors: any) => {
  validateMultipleMembersField(values.members, "members", errors);
  values.members.forEach((item, index) => {
    if (Number(item.membersAmount) <= 0 || Number(item.membersAmount) > 100) {
      errors[`members.${index}.membersAmount`] =
        "Voting power must be between 0 and 100";
    }
  });
};
