import { CLIENT_CONFIGURATION } from "../../../client/configuration";
import { EMPTY_STRING } from "../../../common/constants/common.constants";

export default {
  formId: "add-members",
  formFields: {
    members: {
      name: "members",
      value: [
        {
          wallet: EMPTY_STRING,
          role: CLIENT_CONFIGURATION.clubRoles.find((item) => item.default)
            ?.name,
        },
      ],
      label: "Members",
    },
  },
};
