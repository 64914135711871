import { FieldArray, useFormikContext } from "formik";
import React from "react";
import proposalFormConfig from "../proposalFormConfig";
import { IProposalFormFields } from "../../../common/interfaces/form.interface";
import { clubStore } from "../../../state/clubStore";
import InputField from "../../InputFields/InputField";
import "./VotingPower.scss";

const VotingPower = () => {
  const { values } = useFormikContext<IProposalFormFields>();
  const { clubBasicInfo } = clubStore();

  return (
    <div className="votingPowers">
      <FieldArray
        name={proposalFormConfig.formFields.votingPowers.name}
        render={(arrayHelpers) => (
          <React.Fragment>
            {values.votingPowers.map((item, index) => (
              <div>
                <div className="votingPowers__field">
                  <InputField
                    name={`votingPowers.${index}.role`}
                    type="text"
                    labelTitle="Role"
                    placeholder=""
                    disabled
                  />
                  <InputField
                    name={`votingPowers.${index}.roleVotingPower`}
                    type="number"
                    labelTitle="New voting power"
                    placeholder="New voting power"
                  />
                </div>
              </div>
            ))}
          </React.Fragment>
        )}
      ></FieldArray>
    </div>
  );
};

export default VotingPower;
