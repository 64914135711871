export enum ClubMember {
  Founder = "Founder",
  Member = "Member",
}

export enum ClubAction {
  CreateTreasuryGovernance,
  CreateWithdrawalGovernance,
  CreateTransferGovernance,
  AllowMember,
  CancelInvitation,
  SupportClub,
  UpdateMember,
  InitializeStaking,
  StakeTokens,
  AcceptFinancialOffer,
  CreateChangeClubConfigGovernance,
  CanLeave,
}

export enum TreasuryAction {
  CastVote,
  CreateDiscussionProposal,
  CreateP2PProposal,
  CreateWithdrawalProposal,
  CreateTransferProposal,
  CreateMeProposal,
  SignOffProposal,
  CancelProposal,
  CancelP2POffer,
  Fundraise,
  Distribute,
  CreateFinancialOffer,
  AcceptFinancialOffer,
  CreateSolseaProposal,
  UpdateGovernanceConfig,
  UpdateRoleConfig,
  AddReservedRights,
  UpdateAllocation,
  AddReservedRightsToSelf,
}

export enum UpdateVWActionType {
  ClubAction = "clubAction",
  TreasuryAction = "treasuryAction",
}

export enum ApiClubActions {
  CastVote = "CastVote",
  CreateDiscussionProposal = "CreateDiscussionProposal",
  CreateTreasuryGovernance = "CreateTreasuryGovernance",
  CreateWithdrawalGovernance = "CreateWithdrawalGovernance",
  CreateTransferGovernance = "CreateTransferGovernance",
  CreateP2PProposal = "CreateP2PProposal",
  CreateWithdrawalProposal = "CreateWithdrawalProposal",
  CreateTransferProposal = "CreateTransferProposal",
  CreateMeProposal = "CreateMeProposal",
  SignOffProposal = "SignOffProposal",
  CancelProposal = "CancelProposal",
  AllowMember = "AllowMember",
  CancelP2POffer = "CancelP2POffer",
  CancelInvitation = "CancelInvitation",
  Fundraise = "Fundraise",
  Distribute = "Distribute",
  SupportClub = "SupportClub",
  UpdateMember = "UpdateMember",
  InitializeStaking = "InitializeStaking",
  StakeTokens = "StakeTokens",
  CreateFinancialOffer = "CreateFinancialOffer",
  AcceptFinancialOffer = "AcceptFinancialOffer",
  CreateSolseaProposal = "CreateSolseaProposal",
  CreateChangeClubConfigGovernance = "CreateChangeClubConfigGovernance",
  UpdateGovernanceConfig = "UpdateGovernanceConfig",
  UpdateRoleConfig = "UpdateRoleConfig",
  AddReservedRights = "AddReservedRights",
  UpdateAllocation = "UpdateAllocation",
  AddReservedRightsToSelf = "AddReservedRightsToSelf",
  CanLeave = "CanLeave",
}

export enum TreasuryGovernanceType {
  Treasury,
  Withdrawal,
  Transfer,
  SellPermission,
  GovernanceChange,
}

export enum TradedRightType {
  Buy,
  Sell,
  BuyReserved,
  SellReserved,
}

export enum MemberStatus {
  Uninvited,
  Pending,
  Accepted,
  Rejected,
  Cancelled,
}
export enum AllowType {
  Club,
  Treasury,
}

export enum SocialNetworkType {
  Telegram = "Telegram",
  Mail = "Mail",
  Discord = "Discord",
  Twitter = "Twitter",
  LinkedIn = "LinkedIn",
}

export enum ApplicationStatus {
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected",
}

export enum DefaultExplorer {
  Solscan = "Solscan",
  SolanaExpolorer = "SolanaExpolorer",
  SolanaFM = "SolanaFM",
}

export enum TreasuryStep {
  TreasuryConfiguration,
  TreasuryRoles,
  TreasuryOwnership,
}
