import React, { FC, useMemo } from "react";
import "./MultiToggle.scss";
const MultiToggle: FC<{
  label: string;
  onClick: () => void;
  first?: boolean;
  last?: boolean;
  isSelected: boolean;
}> = ({ label, onClick, isSelected, first, last }) => {
  const getMultiToggleClassName = useMemo(() => {
    let baseClassName = "multi-toggle ";
    if (isSelected) {
      baseClassName += "multi-toggle--selected ";
    }
    if (first) {
      baseClassName += "multi-toggle--first ";
    }
    if (last) {
      baseClassName += "multi-toggle--last ";
    }
    return baseClassName;
  }, [first, last, isSelected]);

  return (
    <div onClick={onClick} className={getMultiToggleClassName}>
      <p>{label}</p>
    </div>
  );
};

export default MultiToggle;
