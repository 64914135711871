import React, { FC, useMemo } from "react";
import Modal from "../../../components/Modal/Modal";
import { Formik, Form } from "formik";
import addMembersFormModel from "./addMembersFormModel";
import AddNewMemberForm from "../AddNewMemberForm/AddNewMemberForm";
import { validateMemberForm } from "./addmembersFormValidator";
import FormActions from "../../../components/FormActions/FormActions";
import { IAddMembersFormFields } from "../../../common/interfaces/form.interface";
import { inviteMember } from "../../../program/methods/clubs";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { clubStore } from "../../../state/clubStore";
import { createNotification } from "../../../utilities/notifications";
import { MESSAGE_TYPE } from "../../../common/constants/common.constants";
import { AllowType } from "../../../common/enums/clubs.enum";
import { PublicKey } from "@metaplex-foundation/js";
const AddNewMemberModal: FC<{
  closeModal: () => void;
}> = ({ closeModal }) => {
  const wallet = useAnchorWallet();
  const { clubBasicInfo, activeTreasury } = clubStore();

  const initialValues = useMemo(
    () =>
      (() => {
        const values: any = {};
        Object.keys(addMembersFormModel.formFields).map(
          (key) =>
            (values[key] = (addMembersFormModel.formFields as any)[key].value)
        );
        return values;
      })(),
    []
  );

  const handleSubmit = async (values: IAddMembersFormFields) => {
    try {
      if (!wallet || !clubBasicInfo) return;
      await inviteMember(
        values.members,
        clubBasicInfo.name,
        wallet,
        AllowType.Club,
        activeTreasury && new PublicKey(activeTreasury?.treasuryDataAddress),
        activeTreasury && new PublicKey(activeTreasury?.realmAddress)
      );

      createNotification(MESSAGE_TYPE.SUCCESS, "Member successfully invited");
      closeModal();
    } catch (error) {
      createNotification(
        MESSAGE_TYPE.ERROR,
        "Member couldn't be invited. Please try again."
      );
      console.log(error);
    }
  };

  return (
    <Modal closeModal={closeModal} title="Invite Members">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur
        validate={(values) => validateMemberForm(values)}
      >
        <Form id={addMembersFormModel.formId}>
          <AddNewMemberForm roleConfig={clubBasicInfo?.roleConfig} />
          <FormActions
            buttonText="Send invitation"
            cancelAction={closeModal}
            buttonAction={() => {}}
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default AddNewMemberModal;
