import { FC, useEffect, useState } from "react";
import Modal from "../../Modal/Modal";
import "./CancelReasons.scss";
import FormActions from "../../FormActions/FormActions";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_CANCELLATION_REASONS,
  GET_SINGLE_PROPOSAL,
  STORE_CANCELLATION_REASON,
} from "../../../api/club.api";
import TextInputField from "../../TextInputField/TextInputField";
import {
  EMPTY_STRING,
  MESSAGE_TYPE,
} from "../../../common/constants/common.constants";
import { OTHER_CANCELLATION_REASON_ID } from "../../../common/constants/proposal.constants";
import { mapProposalEnumValueToType } from "../../../utilities/helpers";
import {
  IProposal,
  IProposalTypes,
} from "../../../common/interfaces/proposal.interface";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import {
  cancelProposal,
  voteToProposal,
} from "../../../program/methods/proposals";
import { clubStore } from "../../../state/clubStore";
import { Vote, YesNoVote } from "@solana/spl-governance";
import { PublicKey } from "@solana/web3.js";
import { createNotification } from "../../../utilities/notifications";
import useTransactionStore from "../../../state/transactionStore";
import { FetchingDataState } from "../../../common/enums/common.enum";

const CancelReasons: FC<{
  closeModal: () => void;
  proposal: IProposal;
}> = ({ closeModal, proposal }) => {
  const [selectedReason, setSelectedReason] = useState(EMPTY_STRING);
  const [filteredReasons, setFilteredReasons] = useState<IProposalTypes[]>([]);
  const [customReason, setCustomReason] = useState(EMPTY_STRING);

  const wallet = useAnchorWallet();
  const { activeTreasury, memberData, updateProposal } = clubStore();
  const { updateFetchingData } = useTransactionStore();

  const [storeCancellationReason] = useMutation(STORE_CANCELLATION_REASON);

  const [getCancellationReasons] = useLazyQuery(GET_CANCELLATION_REASONS, {
    onCompleted: (data) => {
      const reasonMap = data.getCancellationReasons.map((item: any) => {
        return {
          reasonId: item.reason.id,
          reasonLabel: item.reason.label,
          typeId: item.type.id,
          typeLabel: item.type.label,
        };
      });
      const filteredReasons = reasonMap.filter((item: any) => {
        return (
          item.typeId ===
            mapProposalEnumValueToType(
              proposal.proposalMetadata.proposalType
            ) && item.reasonId !== 999
        );
      });
      setFilteredReasons(filteredReasons);
    },
  });

  const [getUpdatedProposal, updatedProposalInfo] = useLazyQuery(
    GET_SINGLE_PROPOSAL,
    {
      onCompleted: (data) => {
        console.log(data, "GET SINGLE PROPOSAL");
        updateProposal(data?.getSingleProposal);
        updateFetchingData(FetchingDataState.Succeeded);
      },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    getCancellationReasons();
  }, []);

  const handleSubmit = async () => {
    try {
      if (!activeTreasury || !wallet || !proposal || !memberData) {
        createNotification(
          MESSAGE_TYPE.ERROR,
          "Missing data for canceling proposal"
        );
        return;
      }
      if (
        wallet?.publicKey?.toString() === proposal.proposalMetadata.authority
      ) {
        await cancelProposal(
          new PublicKey(activeTreasury.realmAddress),
          new PublicKey(proposal.proposalAccount.governance.address),
          new PublicKey(proposal.proposalMetadata.proposal),
          new PublicKey(proposal.proposalAccount.tokenOwnerRecord),
          new PublicKey(memberData.address),
          new PublicKey(activeTreasury.treasuryDataAddress),
          new PublicKey(activeTreasury.clubDataAddress),
          new PublicKey(proposal.proposalMetadata.proposalMetadataAddress),
          wallet
        );
      } else {
        await voteToProposal(
          proposal,
          activeTreasury?.realmAddress,
          activeTreasury?.treasuryDataAddress,
          wallet,
          memberData?.address,
          activeTreasury?.clubDataAddress,
          Vote.fromYesNoVote(YesNoVote.No),
          true
        );
      }
      await storeCancellationReason({
        variables: {
          proposalAddress: proposal.proposalMetadata.proposal,
          reasonId:
            selectedReason !== EMPTY_STRING
              ? Number(selectedReason)
              : OTHER_CANCELLATION_REASON_ID,
          customReason: customReason,
        },
      });

      if (
        wallet?.publicKey?.toString() === proposal.proposalMetadata.authority
      ) {
        await getUpdatedProposal({
          variables: {
            proposalAddress: proposal.proposalMetadata.proposal,
            userAddress: wallet?.publicKey.toString(),
          },
        });
      }
      createNotification(MESSAGE_TYPE.SUCCESS, "Proposal sucessfully canceled");
      closeModal();
    } catch (error) {
      console.log(error);
      createNotification(MESSAGE_TYPE.ERROR, "Failed to cancel proposal");
    }
  };

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedReason(event.target.value);
  };

  return (
    <Modal closeModal={closeModal} title="Cancel Discussion proposal">
      <div className="cancel-reasons__container">
        <p className="cancel-reasons__notice">
          Please let us know why do you want to cancel this proposal:
        </p>
        <div className="cancel-reasons__options">
          {filteredReasons.map((reason) => (
            <div className="cancel-reasons__option">
              <p>{reason.reasonLabel}</p>
              <input
                type="radio"
                value={String(reason.reasonId)}
                checked={selectedReason === String(reason.reasonId)}
                onChange={handleReasonChange}
                disabled={customReason !== EMPTY_STRING}
              />
            </div>
          ))}
        </div>
        <p className="cancel-reasons__other">Other</p>
        <TextInputField
          value={customReason}
          label="Description"
          onChange={(e) => setCustomReason(e)}
          disabled={selectedReason !== EMPTY_STRING}
        />
        <FormActions
          buttonText="Cancel proposal"
          buttonAction={handleSubmit}
          cancelAction={closeModal}
        />
      </div>
    </Modal>
  );
};

export default CancelReasons;
