import React, { FC } from "react";
import "./ProposalActions.scss";
import acceptIcon from "../../assets/thumb_up.png";
import rejectIcon from "../../assets/thumb_down.png";
import acceptIconFull from "../../assets/thumb_up_full.svg";
import rejectIconFull from "../../assets/thumb_down_full.svg";
import MainButton from "../MainButton/MainButton";
import { ProposalState, Vote, YesNoVote } from "@solana/spl-governance";
import { IProposal } from "../../common/interfaces/proposal.interface";

import {
  ProposalTypeProgram,
  UserProposalVoting,
} from "../../common/enums/proposal.enum";
import { clubStore } from "../../state/clubStore";
import {
  ApiClubActions,
  ClubAction,
  TreasuryAction,
} from "../../common/enums/clubs.enum";
import { checkIfUserHasTreasuryPermissionForAction } from "../../utilities/helpers";

export const ProposalVotingActions: FC<{
  proposal: IProposal;
  details?: boolean;
  spaced?: boolean;
  voteForProposal: (choice: Vote) => void;
}> = ({ proposal, spaced, voteForProposal }) => {
  const { memberData, clubBasicInfo, memberTreasuryInfo, activeTreasury } =
    clubStore();
  return (
    <div
      className="proposal-actions"
      style={{
        justifyContent: spaced ? "space-between" : "",
      }}
    >
      <MainButton
        type="button"
        light
        onClick={(e) => {
          e.stopPropagation();
          voteForProposal(Vote.fromYesNoVote(YesNoVote.Yes));
        }}
        disabledBold={
          proposal.proposalMetadata.userVote === UserProposalVoting.VotedYes
        }
        disabled={
          !checkIfUserHasTreasuryPermissionForAction(
            memberTreasuryInfo,
            activeTreasury?.treasuryRoleConfig,
            TreasuryAction.CastVote
          ) ||
          proposal.proposalMetadata.userVote !==
            UserProposalVoting.DidNotVote ||
          proposal.proposalAccount.state !== ProposalState.Voting
        }
      >
        {proposal.proposalMetadata.userVote === UserProposalVoting.VotedYes ? (
          <img src={acceptIconFull} alt="Thumb up" />
        ) : (
          <img src={acceptIcon} alt="Thumb up" />
        )}
        Accept
      </MainButton>
      <MainButton
        type="button"
        light
        onClick={(e) => {
          e.stopPropagation();
          voteForProposal(Vote.fromYesNoVote(YesNoVote.No));
        }}
        disabledBold={
          proposal.proposalMetadata.userVote === UserProposalVoting.VotedNo
        }
        disabled={
          !checkIfUserHasTreasuryPermissionForAction(
            memberTreasuryInfo,
            activeTreasury?.treasuryRoleConfig,
            TreasuryAction.CastVote
          ) ||
          proposal.proposalMetadata.userVote !==
            UserProposalVoting.DidNotVote ||
          proposal.proposalAccount.state !== ProposalState.Voting
        }
      >
        {proposal.proposalMetadata.userVote === UserProposalVoting.VotedNo ? (
          <img src={rejectIconFull} alt="Thumb down" />
        ) : (
          <img src={rejectIcon} alt="Thumb down" />
        )}
        Reject
      </MainButton>
    </div>
  );
};
