import { DealType, FundraiseModel } from "../common/enums/deals.enum";
import { ITreasuryConfiguration } from "../common/interfaces/club.interface";
import { BN } from "@project-serum/anchor";
import { IRoleConfig } from "../common/interfaces/form.interface";
import { EMPTY_STRING } from "../common/constants/common.constants";
import {
  DEFAULT_FOUNDER_CLUB_PERMISSIONS,
  DEFAULT_FOUNDER_TREASURY_PERMISSIONS,
  DEFAULT_MANAGER_TREASURY_PERMISSIONS,
  DEFAULT_MEMBER_CLUB_PERMISSIONS,
  DEFAULT_MEMBER_TREASURY_PERMISSIONS,
} from "../common/constants/club.constants";
import { ProposalType } from "../common/enums/proposal.enum";

interface IClientConfiguration {
  clubRoles: IRoleConfig[];
  treasuryConfiguration: ITreasuryConfiguration | undefined;
  createDealSteps: number;
  dealType: DealType;
  nftMaxVoterWeight: BN | null;
  treasuryRoles: IRoleConfig[];
  allowTradingShares: boolean;
  proposalTypes: ProposalType[];
  withdrawalGovernanceQuorum?: number;
  transferGovernanceQuorum?: number;
  changeGovernanceQuorum?: number;
  hideBrowseClubs?: boolean;
  disableWithdraw?: boolean;
}

export enum CreateDealStep {
  BasicDealData = 0,
  TreasuryConfiguration = -1,
  TreasuryRoles = 1,
  ClubRoles = -1,
  DealConfiguration = 2,
  DealPreview = 3,
}

export enum TreasuryStep {
  TreasuryConfiguration,
  TreasuryRoles,
  TreasuryOwnership,
}

export const CLIENT_CONFIGURATION: IClientConfiguration = {
  createDealSteps: 4,
  treasuryConfiguration: {
    treasuryName: "Treasury1",
    approvalQuorum: 51,
    chain: "sol",
    maxVotingTime: 30 * 86400,
    fundraiseModel: FundraiseModel.Token,
  },
  treasuryRoles: [
    {
      chosen: true,
      required: true,
      name: "founder",
      votingPower: EMPTY_STRING,
      default: false,
      actions: DEFAULT_FOUNDER_TREASURY_PERMISSIONS,
      isFounder: true,
    },
    {
      chosen: true,
      required: true,
      name: "member",
      votingPower: EMPTY_STRING,
      default: true,
      actions: DEFAULT_MEMBER_TREASURY_PERMISSIONS,
    },
    {
      chosen: false,
      required: false,
      name: "treasury manager",
      votingPower: EMPTY_STRING,
      default: false,
      actions: DEFAULT_MANAGER_TREASURY_PERMISSIONS,
    },
  ],
  nftMaxVoterWeight: null,
  dealType: DealType.Syndicate,
  clubRoles: [
    {
      chosen: true,
      required: true,
      name: "Syndicate lead",
      votingPower: 10,
      default: false,
      actions: DEFAULT_FOUNDER_CLUB_PERMISSIONS,
      isFounder: true,
    },
    {
      chosen: true,
      required: true,
      name: "Member",
      votingPower: 10,
      default: true,
      actions: DEFAULT_MEMBER_CLUB_PERMISSIONS,
    },
  ],
  allowTradingShares: true,
  withdrawalGovernanceQuorum: 0,
  transferGovernanceQuorum: 0,
  proposalTypes: [
    ProposalType.Discussion,
    ProposalType.TransferFunds,
    ProposalType.Withdrawal,
    ProposalType.ConfigurationChanges,
    ProposalType.Fundraise,
  ],
  hideBrowseClubs: false,
  disableWithdraw: false,
};
