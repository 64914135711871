import { CreateDealStep } from "../../client/configuration";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import {
  IBasicClubDataForm,
  IRoleConfig,
} from "../../common/interfaces/form.interface";

export const validateCreateClub = (
  values: IBasicClubDataForm,
  activeStep: CreateDealStep
) => {
  const errors: any = {};
  switch (activeStep) {
    case CreateDealStep.BasicDealData:
      validateBasicInfo(values, errors);
      break;
    case CreateDealStep.ClubRoles:
      validateRoles(values.roles, "roles", errors);
      break;
    case CreateDealStep.TreasuryRoles:
      validateRoles(values.treasuryRoles, "treasuryRoles", errors);
      break;
    case CreateDealStep.TreasuryConfiguration:
      validateTreasuryConfig(values, errors);
      break;
    case CreateDealStep.DealConfiguration:
      break;
    case CreateDealStep.DealPreview:
      validateBasicInfo(values, errors);
      validateRoles(values.roles, "roles", errors);
      validateRoles(values.treasuryRoles, "treasuryRoles", errors);
      validateTreasuryConfig(values, errors);
      break;
  }
  return errors;
};

const validateBasicInfo = (values: IBasicClubDataForm, errors: any) => {
  if (values.name === EMPTY_STRING) {
    errors.name = "Syndicate name can't be empty!";
  }

  if (values.name.length > 32) {
    errors.name = "Syndicate name can't be longer that 32 characters";
  }

  if (values.description === EMPTY_STRING) {
    errors.description = "Syndicate name can't be empty!";
  }

  if (values.description.length > 400) {
    errors.description = "Description too long!";
  }
};

const validateTreasuryConfig = (values: IBasicClubDataForm, errors: any) => {
  if (values.treasuryName === EMPTY_STRING) {
    errors.treasuryName = "Treasury name can't be empty!";
  }

  if (values.chain === EMPTY_STRING) {
    errors.chain = "Chain can't be empty!";
  }

  if (values.approvalQuorum === EMPTY_STRING) {
    errors.approvalQuorum = "Approval quorum can't be empty!";
  }

  if (
    Number(values.approvalQuorum) < 0 ||
    Number(values.approvalQuorum) > 100
  ) {
    errors.approvalQuorum = "Approval quorum must be between 0 and 100";
  }

  if (values.maxVotingTime === EMPTY_STRING) {
    errors.maxVotingTime = "Max voting time can not be empty";
  }

  if (Number(values.maxVotingTime) < 0) {
    errors.maxVotingTime = "Max voting time can not be negative";
  }
};

const validateRoles = (
  roleConfigs: IRoleConfig[],
  arrayName: string,
  errors: any
) => {
  roleConfigs.forEach((item, index) => {
    const votingPower = Number(item.votingPower);
    if (
      (votingPower <= 0 && item.chosen) ||
      !Number.isInteger(votingPower) ||
      /\.$/.test(item.votingPower.toString())
    ) {
      errors[`${arrayName}.${index}.votingPower`] =
        "Voting power must be a positive whole number";
    }
  });
};
