import { FC } from "react";
import "./CreateDealFooter.scss";
import MainButton from "../../../components/MainButton/MainButton";
import { CreateDealStep } from "../../../client/configuration";

const CreateDealFooter: FC<{
  activeStep: CreateDealStep;
  onConfirm: () => void;
  onCancel: () => void;
}> = ({ activeStep, onCancel, onConfirm }) => {
  return (
    <div className="create-deal-footer">
      <MainButton type="button" light onClick={onCancel}>
        {activeStep === CreateDealStep.BasicDealData ? "Cancel" : "Back"}
      </MainButton>
      <MainButton onClick={onConfirm} type="submit">
        <p>
          {activeStep === CreateDealStep.DealPreview
            ? "Create Deal"
            : "Continue"}
        </p>
      </MainButton>
    </div>
  );
};

export default CreateDealFooter;
