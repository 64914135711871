import React, { FC, useState } from "react";
import MainButton from "../../../components/MainButton/MainButton";
import DepositFormModal from "../../../components/FundraiseFormModal/DepositFormModal";
import WithdrawFormModal from "../../../components/FundraiseFormModal/WithdrawFormModal";
import { CLIENT_CONFIGURATION } from "../../../client/configuration";

const FundraiseActions: FC = () => {
  const [depositModal, toggleDepositModal] = useState(false);
  const [withdrawModal, toggleWithdrawModal] = useState(false);
  return (
    <div className="fundraise-info__actions">
      {depositModal && (
        <DepositFormModal closeModal={() => toggleDepositModal(false)} />
      )}

      {withdrawModal && (
        <WithdrawFormModal closeModal={() => toggleWithdrawModal(false)} />
      )}
      <MainButton
        type="button"
        color="white"
        onClick={() => toggleDepositModal(true)}
      >
        Deposit
      </MainButton>
      {!CLIENT_CONFIGURATION.disableWithdraw && (
        <MainButton
          light
          type="button"
          color="var(--grey-500)"
          onClick={() => toggleWithdrawModal(true)}
        >
          Withdraw
        </MainButton>
      )}
    </div>
  );
};

export default FundraiseActions;
