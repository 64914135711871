import { clubStore } from "../../state/clubStore";
import {
  findFundraiseInProgress,
  findNearestDateForTokenUnlocking,
  formatDateWithoutTime,
} from "../../utilities/helpers";
import "./TokenUnlockingDate.scss";
import lock_icon from "../../assets/lock.png";
import { FC, useMemo } from "react";

const TokenUnlockingDate: FC = () => {
  const { activeTreasury, fundraises } = clubStore();

  const nearestDateForUnlocking = useMemo(() => {
    return (
      fundraises && findNearestDateForTokenUnlocking(fundraises)?.tokenAmount
    );
  }, []);

  return (
    <div className="token-unlocking-date">
      <div className="token-unlocking-date__reward-tokens">
        <div className="token-unlocking-date__table">
          {fundraises &&
            findFundraiseInProgress(fundraises)?.tokenUnlocking?.map((item) => (
              <div className="token-unlocking-date__item">
                <p className="token-unlocking-date__amount">
                  {item.tokenAmount} {activeTreasury?.currencySymbol}
                </p>
                <div className="token-unlocking-date__date">
                  {formatDateWithoutTime(item.date)}
                </div>
              </div>
            ))}
        </div>
      </div>
      {nearestDateForUnlocking && (
        <div className="token-unlocking-date__reward-container">
          <label className="token-unlocking-date__reward-description">
            You will get{" "}
          </label>
          <label className="token-unlocking-date__reward-amount">
            <img src={lock_icon} />
            <span>
              {fundraises &&
                findNearestDateForTokenUnlocking(fundraises)?.tokenAmount}
            </span>{" "}
            <span className="token-unlocking-date__reward-amount-currency">
              {activeTreasury?.currencySymbol}
            </span>
          </label>
        </div>
      )}
    </div>
  );
};

export default TokenUnlockingDate;
