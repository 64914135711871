import { FC, useCallback, useEffect } from "react";
import "./HomeSummary.scss";
import DetailsCard from "../../../components/DetailsCard/DetailsCard";
import BasicDetailsCard from "../../../components/BasicDetailsCard/BasicDetailsCard";
import {
  EMPTY_STRING,
  MESSAGE_TYPE,
} from "../../../common/constants/common.constants";
import avatar from "../../../assets/unique_avatar.png";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import {
  CANCEL_CLUB_APPLICATION,
  GET_DASHBOARD_MEMBERSHIPS,
} from "../../../api/dashboard.api";
import { useDashboardStore } from "../../../state/dashboardStore";
import useTransactionStore from "../../../state/transactionStore";
import MainButton from "../../../components/MainButton/MainButton";
import { IDashboardMembership } from "../../../common/interfaces/dashboard.interface";
import {
  acceptInvitation,
  rejectInvitation,
} from "../../../program/methods/clubs";
import { createNotification } from "../../../utilities/notifications";
import {
  findNftTokenAccountAndMetadataFromCollection,
  getMemberDataPda,
  getSignedMessage,
  getTrimmedPublicKey,
} from "../../../utilities/helpers";
import { PublicKey } from "@solana/web3.js";
import { USER_MEMBER_DATA_CHANGED_SUBSCRIPTION } from "../../../api/club-subscriptions.api";
import { MemberStatus } from "../../../common/enums/clubs.enum";
import { FetchingDataState } from "../../../common/enums/common.enum";
import { DealType } from "../../../common/enums/deals.enum";

const MembershipsSummary: FC = () => {
  const wallet = useAnchorWallet();
  const { signMessage } = useWallet();
  //Note: uncomment after CIVIC implementation
  // const { gatewayToken } = useGateway();

  const { setDashboardMemberships, dashboardMemberships } = useDashboardStore();

  const { updateFetchingData } = useTransactionStore();

  useEffect(() => {
    if (wallet) {
      getDashboardMemberships();
    }
  }, [wallet]);

  const [getDashboardMemberships] = useLazyQuery(GET_DASHBOARD_MEMBERSHIPS, {
    onCompleted: (data) => {
      setDashboardMemberships([...data.getDashboardMemberships]);
    },
    variables: {
      userWallet: wallet?.publicKey.toString(),
    },
  });

  const [cancelApplication] = useMutation(CANCEL_CLUB_APPLICATION);

  useSubscription(USER_MEMBER_DATA_CHANGED_SUBSCRIPTION, {
    onData: ({ data }) => {
      console.log("MINE MEMBER DATA SUB", data);
      if (
        data.data.mineMemberDataChanged.memberData.status !==
        MemberStatus.Pending
      ) {
        setDashboardMemberships([
          ...dashboardMemberships.filter(
            (v) =>
              v.clubInfo.address !==
              data.data.mineMemberDataChanged.memberData.clubData
          ),
        ]);
      }
      updateFetchingData(FetchingDataState.Succeeded);
    },
    variables: {
      userAddress: wallet?.publicKey.toString(),
    },
  });

  const handleRejectInvitation = useCallback(
    async (club: IDashboardMembership) => {
      try {
        if (wallet) {
          await rejectInvitation(
            wallet,
            club.clubInfo.address,
            wallet.publicKey.toString(),
            club.clubInfo.authority
          );
        }
      } catch (error) {
        console.log(error);
        createNotification(MESSAGE_TYPE.ERROR, "Failed to reject");
      }
    },
    [wallet]
  );

  const handleJoinClub = useCallback(
    async (club: IDashboardMembership) => {
      try {
        if (!wallet) return;
        const memberData = getMemberDataPda(club.clubInfo.address, wallet);
        await acceptInvitation(
          club.clubInfo.address,
          memberData.toString(),
          wallet,
          club.clubInfo.name,
          club.clubInfo.clubType,
          //Note: uncomment after CIVIC implementation
          // club.clubInfo.kycConfig ? gatewayToken : undefined,
          club.clubInfo.clubType === DealType.NftBased
            ? await findNftTokenAccountAndMetadataFromCollection(
                wallet.publicKey,
                new PublicKey(club.clubInfo.communityMint)
              )
            : undefined
        );
        createNotification(
          MESSAGE_TYPE.SUCCESS,
          "Succesfully accepted invitation"
        );
      } catch (error) {
        console.log(error);
        createNotification(MESSAGE_TYPE.ERROR, "Failed to accept invitation");
      }
    },
    [wallet]
  );

  const handleCancelApplication = useCallback(
    async (clubAddress: string) => {
      try {
        if (wallet && signMessage) {
          const signedMessage = await getSignedMessage(
            `Cancel application in club ${clubAddress}`,
            signMessage
          );

          await cancelApplication({
            variables: {
              userAddress: wallet.publicKey.toString(),
              clubAddress,
              signedMessage: signedMessage,
            },
          });
          setDashboardMemberships(
            dashboardMemberships.filter((item) => {
              return item.clubInfo.address !== clubAddress;
            })
          );
          createNotification(MESSAGE_TYPE.SUCCESS, "Canceled application");
        }
      } catch (error) {
        createNotification(MESSAGE_TYPE.ERROR, "Failed to cancel application");
        console.log(error);
      }
    },
    [signMessage, wallet]
  );

  return (
    <DetailsCard title="Memberships">
      {dashboardMemberships.length < 1 ? (
        <p>No memberships at the moment</p>
      ) : (
        dashboardMemberships.map((membership: IDashboardMembership) => (
          <div
            className="home-summary__cards-claim"
            key={membership.clubInfo.address}
          >
            <BasicDetailsCard
              image={membership.clubInfo?.imgUrl ?? avatar}
              alt={EMPTY_STRING}
              title={getTrimmedPublicKey(membership.clubInfo.address)}
              id={`${
                membership.isApplication
                  ? `for ${membership.clubInfo.name}`
                  : `to join ${membership.clubInfo.name}`
              }`}
            />
            {membership.isApplication ? (
              <MainButton
                type="button"
                light
                onClick={() =>
                  handleCancelApplication(membership.clubInfo.address)
                }
              >
                Cancel
              </MainButton>
            ) : (
              <div style={{ display: "flex", gap: "4px" }}>
                <MainButton
                  type="button"
                  light
                  onClick={() => handleJoinClub(membership)}
                >
                  Accept
                </MainButton>
                <MainButton
                  type="button"
                  light
                  onClick={() => handleRejectInvitation(membership)}
                >
                  Deny
                </MainButton>
              </div>
            )}
          </div>
        ))
      )}
    </DetailsCard>
  );
};

export default MembershipsSummary;
