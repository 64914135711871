import { FC, Fragment, useCallback, useEffect, useRef, useState } from "react";
import { FieldArray, useFormikContext, FieldArrayRenderProps } from "formik";
import addMembersFormModel from "../AddNewMemberModal/addMembersFormModel";
import InputField from "../../../components/InputFields/InputField";
import "./AddNewMemberForm.scss";
import MainButton from "../../../components/MainButton/MainButton";
import {
  EMPTY_STRING,
  MESSAGE_TYPE,
  SHARE_ICONS,
} from "../../../common/constants/common.constants";
import { ClubMember } from "../../../common/enums/clubs.enum";
import cancelIcon from "../../../assets/cancel.svg";
import { IOption } from "../../../common/interfaces/common.interface";
import profile from "../../../assets/Profilepicture.png";
import add from "../../../assets/add_circle_full.png";
import Chip from "../../../components/Chip/Chip";
import { YELLOW100 } from "../../../common/constants/layout.constants";
import errorIcon from "../../../assets/error.svg";
import {
  IAddMember,
  IAddMembersFormFields,
} from "../../../common/interfaces/form.interface";
import { IClubRoleConfig } from "../../../common/interfaces/club.interface";
import Papa from "papaparse";
import { clubStore } from "../../../state/clubStore";
import clubAvatar from "../../../assets/unique_avatar.png";
import { createNotification } from "../../../utilities/notifications";
import { CLIENT_CONFIGURATION } from "../../../client/configuration";

const AddNewMemberForm: FC<{
  roleConfig: IClubRoleConfig[] | undefined;
}> = ({ roleConfig }) => {
  const { values, setFieldValue } = useFormikContext<IAddMembersFormFields>();
  const fileInputField: any = useRef(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const { clubBasicInfo } = clubStore();

  useEffect(() => {
    if (uploadedFile) {
      parseUploadedFile();
    }
  }, [setUploadedFile, uploadedFile]);

  const handleUploadBtnClick = () => {
    if (fileInputField && fileInputField.current) {
      fileInputField.current.click();
    }
  };

  const parseUploadedFile = () => {
    if (uploadedFile) {
      if (uploadedFile.type !== "text/csv") {
        createNotification(MESSAGE_TYPE.ERROR, "Please only upload CSV files.");
        return;
      }
      Papa.parse(uploadedFile, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          const jsonData = result.data as IAddMember[];
          const importedMembers = jsonData.map((memberData) => ({
            wallet: memberData.wallet,
            role: memberData.role,
          }));
          const concatedMembers = values.members.concat(importedMembers);
          const filteredMembers = concatedMembers.filter((member) => {
            return member.wallet !== EMPTY_STRING;
          });

          setFieldValue(
            addMembersFormModel.formFields.members.name,
            filteredMembers
          );
        },
      });
    }
  };

  const addMemberItem = (arrayHelper: FieldArrayRenderProps) => {
    arrayHelper.push({
      wallet: EMPTY_STRING,
      role: ClubMember.Member,
    });
  };

  const removeOption = useCallback(
    (index: number, arrayHelpers: FieldArrayRenderProps) => {
      arrayHelpers.remove(index);
    },
    []
  );

  const options: IOption[] = roleConfig
    ? roleConfig
        ?.filter((item) => {
          const clientRole = CLIENT_CONFIGURATION.clubRoles.find(
            (role) => item.name === role.name
          );
          return !clientRole?.isFounder;
        })
        .map((item) => {
          return {
            label: item.name,
            value: item.name,
          };
        })
    : [];

  return (
    <div className="add-member-form">
      <div className="add-member-form__info">
        <img
          src={
            clubBasicInfo?.imgUrl && clubBasicInfo?.imgUrl !== EMPTY_STRING
              ? clubBasicInfo?.imgUrl
              : clubAvatar
          }
          alt="Profile"
        />
        <h2>{clubBasicInfo?.name}</h2>
      </div>
      <FieldArray
        name={addMembersFormModel.formFields.members.name}
        render={(arrayHelpers) => (
          <Fragment>
            {values.members.map((item, index) => (
              <div className="add-member-form__fields">
                <img
                  src={cancelIcon}
                  alt="Cancel icon"
                  onClick={() => removeOption(index, arrayHelpers)}
                />
                <InputField
                  name={`members.${index}.wallet`}
                  type="text"
                  labelTitle="Member wallet"
                  placeholder="Enter wallet address"
                />
                <InputField
                  name={`members.${index}.role`}
                  type="select"
                  labelTitle="Role"
                  options={options}
                />
              </div>
            ))}
            <div className="add-member-form__actions">
              <MainButton
                onClick={() => {
                  addMemberItem(arrayHelpers);
                }}
                type="button"
                light
              >
                <img src={add} alt="Add circle" /> Add New Member
              </MainButton>
              <MainButton onClick={handleUploadBtnClick} type="button" light>
                Import from CSV
              </MainButton>
              <input
                type="file"
                className="add-member-form__csv-input"
                ref={fileInputField}
                onChange={(e) =>
                  setUploadedFile(e.target.files && e.target.files[0])
                }
              />
            </div>
            {/* Note: we do not have this for initial version
             <div className="add-member-form__share">
              <p>or Share Invitation via</p>
              <div className="add-member-form__share-flex">
                {SHARE_ICONS.map((icon) => (
                  <div style={{ backgroundColor: icon.background }}>
                    <img src={icon.image} alt={icon.alt} />
                  </div>
                ))}
              </div>
            </div> */}
            <div className="add-member-form__chip">
              <Chip
                text="Invite member fee ≈ 0.0215 SOL"
                backgroundColor={YELLOW100}
                stretch
                fontSize="0.8"
                padding="14px"
                icon
                image={errorIcon}
              />
            </div>
          </Fragment>
        )}
      />
    </div>
  );
};

export default AddNewMemberForm;
