import React, { FC, ReactNode } from "react";
import "./DetailsCard.scss";
import Chip from "../Chip/Chip";
import { EMPTY_STRING } from "../../common/constants/common.constants";

const DetailsCard: FC<{
  title: string;
  children: ReactNode;
  chip?: boolean;
  chipText?: string;
  backgroundColor?: string;
  chipBackgroundColor?: string;
}> = ({
  title,
  children,
  backgroundColor,
  chip,
  chipText,
  chipBackgroundColor,
}) => {
  return (
    <div
      className="details-card"
      style={{ backgroundColor: chip ? backgroundColor : EMPTY_STRING }}
    >
      <div className={chip ? "details-card--chip" : EMPTY_STRING}>
        <h3>{title}</h3>
        {chip && chipText && chipBackgroundColor && (
          <Chip
            text={chipText}
            backgroundColor={chipBackgroundColor}
            padding="6px 10px 8px"
          />
        )}
      </div>
      {children}
    </div>
  );
};

export default DetailsCard;
