import { FC, useMemo, useState } from "react";
import "./OTCCard.scss";
import Chip from "../../../components/Chip/Chip";
import { WHITE300 } from "../../../common/constants/layout.constants";
import MainButton from "../../../components/MainButton/MainButton";
import { IOTCDeal } from "../../../common/interfaces/otc.interface";
import { cancelFinancialOffer } from "../../../program/methods/clubs";
import { clubStore } from "../../../state/clubStore";
import { PublicKey } from "@solana/web3.js";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import {
  checkIfUserHasClubPermissionForAction,
  checkIfUserHasTreasuryPermissionForAction,
  getAmountWithDecimalsForCurrency,
  getTrimmedPublicKey,
} from "../../../utilities/helpers";
import { createNotification } from "../../../utilities/notifications";
import { MESSAGE_TYPE } from "../../../common/constants/common.constants";
import SharesModal from "../SharesModal/SharesModal";
import CancelSharesModal from "../CancelSharesModal/CancelSharesModal";
import { ClubAction, TreasuryAction } from "../../../common/enums/clubs.enum";

const OTCCard: FC<{ otc: IOTCDeal; offered?: boolean }> = ({
  otc,
  offered,
}) => {
  const [showActions, setShowActions] = useState(false);
  const [sellOfferModalActive, toggleSellOfferModalActive] = useState(false);
  const [cancelOfferModalActive, toggleCancelOfferModalActive] =
    useState(false);
  const { activeTreasury, clubBasicInfo, memberTreasuryInfo, memberData } =
    clubStore();
  const wallet = useAnchorWallet();

  const handleCancelOtcDeal = async () => {
    try {
      if (!clubBasicInfo || !activeTreasury || !wallet) {
        createNotification(MESSAGE_TYPE.ERROR, "Missing data");
        return;
      }

      await cancelFinancialOffer(
        new PublicKey(clubBasicInfo.address),
        wallet,
        new PublicKey(otc.address),
        new PublicKey(activeTreasury.treasuryAddress),
        new PublicKey(activeTreasury.treasuryDataAddress)
      );
    } catch (error) {
      throw error;
    }
  };

  const canUserAcceptOffer = useMemo(() => {
    return (
      checkIfUserHasTreasuryPermissionForAction(
        memberTreasuryInfo,
        activeTreasury?.treasuryRoleConfig,
        TreasuryAction.AcceptFinancialOffer
      ) ||
      checkIfUserHasClubPermissionForAction(
        memberData,
        clubBasicInfo?.roleConfig,
        ClubAction.AcceptFinancialOffer
      )
    );
  }, [memberTreasuryInfo, activeTreasury, memberData, clubBasicInfo]);

  return (
    <>
      {sellOfferModalActive && (
        <SharesModal
          closeModal={() => toggleSellOfferModalActive(false)}
          selling={false}
          otc={otc}
        />
      )}
      {cancelOfferModalActive && (
        <CancelSharesModal
          closeModal={() => toggleCancelOfferModalActive(false)}
          buttonAction={() => handleCancelOtcDeal()}
        />
      )}
      <div
        className="otc-card"
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
      >
        <div>
          <div className="otc-card__details">
            <h3>Seller's Wallet</h3>
            <p className="otc-card--bold">{getTrimmedPublicKey(otc.seller)}</p>
          </div>
          {offered && (
            <div className="otc-card__details">
              <h3>Offered To</h3>
              <p>{otc.buyer ? getTrimmedPublicKey(otc.buyer) : "Everyone"}</p>
            </div>
          )}
        </div>
        <div>
          <div className="otc-card__details">
            <h3>Offered</h3>
            <Chip
              text={`${otc.offeredPct.toFixed(2)}%`}
              backgroundColor={WHITE300}
              padding="4px 0.75em"
            />
          </div>
          <div className="otc-card__details">
            <h3>Available</h3>
            <Chip
              text={`${otc.remainingPct.toFixed(2)}%`}
              padding="4px 0.75em"
            />
          </div>
          <div className="otc-card__details">
            <h3>Price per 1%</h3>
            <p className="otc-card--bold">
              {otc.pricePerPct.toFixed(2)}
              <span> SOL</span>
            </p>
          </div>
          <div className="otc-card__details">
            <h3>Total Price</h3>
            <p className="otc-card--bold">
              {getAmountWithDecimalsForCurrency(
                activeTreasury?.currencyDecimals,
                otc.price
              )}
              <span> SOL</span>
            </p>
          </div>
          <div className="otc-card__details--actions">
            {otc.seller === wallet?.publicKey.toString()
              ? showActions && (
                  <MainButton
                    type="button"
                    light
                    onClick={() => toggleCancelOfferModalActive(true)}
                  >
                    Cancel
                  </MainButton>
                )
              : showActions && (
                  <MainButton
                    type="button"
                    light
                    onClick={() => toggleSellOfferModalActive(true)}
                    disabled={
                      (otc.buyer &&
                        otc.buyer !== wallet?.publicKey.toString()) ||
                      !canUserAcceptOffer
                    }
                  >
                    Buy Shares
                  </MainButton>
                )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OTCCard;
