import React, { useState } from "react";
import CardWrapper from "../../../components/CardWrapper/CardWrapper";
import Fundraise from "../Fundraise/Fundraise";
import NoData from "../../../components/NoData/NoData";
import { clubStore } from "../../../state/clubStore";
import { FC } from "react";
import { create } from "domain";
import CreateFundraiseForm from "../../../components/CreateFundraiseFormModal/CreateFundraiseForm/CreateFundraiseForm";
import CreateFundraiseModal from "../../../components/CreateFundraiseFormModal/CreateFundraiseModal";
import CreateProposalModal from "../../../components/CreateProposal/CreateProposalModal";
import { IHistoricalDeposit } from "../../../common/interfaces/club.interface";
import { ProposalType } from "../../../common/enums/proposal.enum";
import { checkIfUserHasTreasuryPermissionForAction } from "../../../utilities/helpers";
import { TreasuryAction } from "../../../common/enums/clubs.enum";

const TreasuryFundraise: FC = () => {
  const [createFundraise, toggleCreateFundraise] = useState(false);
  const [createFundraisePropoosal, toggleFundraiseProposal] = useState(false);
  const { fundraises, memberTreasuryInfo, activeTreasury } = clubStore();

  const fundraiseInProgress = fundraises?.find((item) => item.isActive);
  const fundraisesLength = fundraises && fundraises.length;
  return (
    <>
      {createFundraise && (
        <CreateFundraiseModal
          edit={false}
          onClose={() => toggleCreateFundraise(false)}
        />
      )}

      {createFundraisePropoosal && (
        <CreateProposalModal
          proposalType={ProposalType.Fundraise}
          closeModal={() => toggleFundraiseProposal(false)}
        />
      )}
      {fundraiseInProgress ? (
        <CardWrapper padding="1.5em" backgroundColor="white">
          <Fundraise fundraise={fundraiseInProgress} />
        </CardWrapper>
      ) : (
        <NoData
          message="This deal has no active fundraises"
          mainButtonText="Create fundraise"
          noIcon={false}
          mainButtonOnClick={
            fundraisesLength && fundraisesLength > 0
              ? () => toggleFundraiseProposal(true)
              : () => toggleCreateFundraise(true)
          }
          disabled={
            !checkIfUserHasTreasuryPermissionForAction(
              memberTreasuryInfo,
              activeTreasury?.treasuryRoleConfig,
              TreasuryAction.Fundraise
            )
          }
        />
      )}
    </>
  );
};

export default TreasuryFundraise;
