import { FC } from "react";
import "./HomeStats.scss";

interface IHomeStats {
  title: string;
  value: string | number;
  currency: string;
}

const HomeStats: FC<{ array: IHomeStats[] }> = ({ array }) => {
  return (
    <div className="home-stats">
      <div className="home-stats__table">
        {array.map((i, index) => (
          <div key={index}>
            <h4>{i.title}</h4>
            <p>
              {i.value} {i.currency && <span>{i.currency}</span>}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeStats;
