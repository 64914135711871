import { ChangeGovernanceTypeEnum, ProposalType } from "../enums/proposal.enum";
import { ISelectOption } from "../interfaces/common.interface";
import { EMPTY_STRING } from "./common.constants";

export const MIN_APPROVAL_PERCENTAGE = 51;
export const MAX_APPROVAL_PERCENTAGE = 100;
export const ADD_NEW_SELL_PERMISION = "Add";
export const MAX_VOTING_DAYS = 30;
export const MIN_VOTING_DAYS = 1;

export const OTHER_CANCELLATION_REASON_ID = 999;

export const governanceTypeOptions: ISelectOption[] = [
  {
    description: EMPTY_STRING,
    title: "Change club configuration",
    value: ChangeGovernanceTypeEnum.ChangeClubConfiguration,
  },
  {
    description: EMPTY_STRING,
    title: "Default configuration",
    value: ChangeGovernanceTypeEnum.DefaultConfiguration,
  },
  {
    description: EMPTY_STRING,
    title: "Trade approval configuration",
    value: ChangeGovernanceTypeEnum.TradeApprovalConfiguration,
  },
  {
    description: EMPTY_STRING,
    title: "Transfer configuration",
    value: ChangeGovernanceTypeEnum.TransferConfiguration,
  },
  {
    description: EMPTY_STRING,
    title: "Withdrawal configuration",
    value: ChangeGovernanceTypeEnum.WithdrawalConfiguration,
  },
];

export const ProposalFormInfo = [
  {
    title: ProposalType.Discussion,
    description:
      "Configure approval percentage needed for each type of proposal to pass and succeed. Different types of proposals might need a custom approval percentage.",
  },
  {
    title: ProposalType.Trading,
    description:
      "Buy or sell digital assets with your club members. Execute the trade once the proposal reaches the predefined quorum.",
  },
  {
    title: ProposalType.TransferFunds,
    description:
      "Send tokens from the Treasury to any address. Subject to the limits and thresholds defined in the Proposal Voting Configuration.",
  },
  {
    title: ProposalType.Withdrawal,
    description:
      "Withdraw funds from the Treasury and distribute the withdrawn amount to eligible club members, proportional to their financial rights.",
  },
  {
    title: ProposalType.ConfigurationChanges,
    description:
      "Change Voting Configuration for different types of proposals.",
  },
  {
    title: ProposalType.Fundraise,
    description: "Text for fundraise proposal",
  },
];
