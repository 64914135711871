import { FC, useState } from "react";
import "./OverviewVaultCard.scss";
import MainButton from "../../../components/MainButton/MainButton";
import linkIcon from "../../../assets/link.png";
import magicEdenIcon from "../../../assets/MagicEden.svg";
import solseaIcon from "../../../assets/solsea.svg";
import { IDetailNftData } from "../../../common/interfaces/common.interface";
import { EMPTY_STRING } from "../../../common/constants/common.constants";

//TODO: check links for magiceden/solsea
const OverviewVaultCard: FC<{ card: IDetailNftData }> = ({ card }) => {
  const [showAction, setShowAction] = useState(false);

  return (
    <div
      className="overview-vault-card"
      onMouseEnter={() => setShowAction(true)}
      onMouseLeave={() => setShowAction(false)}
    >
      <img src={card.image} alt="Nft avatar" />
      {showAction && (
        <>
          <div className="overview-vault-card-actions">
            <a href={EMPTY_STRING}>
              <img src={magicEdenIcon} alt="`Magic Eden logo" />
            </a>
            <a href={EMPTY_STRING}>
              <img src={solseaIcon} alt="Solsea logo" />
            </a>
            <a href={EMPTY_STRING}>
              <img src={linkIcon} alt="Paperclip" />
            </a>
          </div>
          <MainButton onClick={() => {}} type="button" squared>
            Sell this NFT
          </MainButton>
        </>
      )}
      <div className="overview-vault-card__details">
        <h3>{card.name}</h3>
        <p>{card.collection?.name}</p>
      </div>
    </div>
  );
};

export default OverviewVaultCard;
