import { create } from "zustand";
import {
  IClubData,
  IFinancialRecordOffer,
  IFundraiseConfig,
  IMemberData,
  IMemberTreasuryInfo,
  ITreasuryData,
  IVault,
} from "../common/interfaces/club.interface";
import { ITreasuryMembersInfo } from "../common/interfaces/treasury.interface";
import {
  IProposal,
  IWithdrawalMembersInfo,
} from "../common/interfaces/proposal.interface";
import { PublicKey } from "@solana/web3.js";

interface IClubStoreData {
  clubBasicInfo: IClubData | undefined;
  activeTreasury: ITreasuryData | undefined;
  fundraises: IFundraiseConfig[] | undefined;
  vault: IVault | undefined;
  financialOffers: IFinancialRecordOffer[] | undefined;
  memberData: IMemberData | undefined;
  memberTreasuryInfo: IMemberTreasuryInfo | undefined;
  clubMembers: IMemberData[] | undefined;
  treasuryMembers: ITreasuryMembersInfo[] | undefined;
  proposals: IProposal[] | undefined;
  allTreasuryDataAddresses: PublicKey[] | undefined;
}

interface IClubStore extends IClubStoreData {
  initialStoreSetup: (
    clubBasicInfo: IClubData,
    clubMembers: IMemberData[],
    activeTreasury: ITreasuryData,
    fundraises: IFundraiseConfig[],
    vault: IVault,
    financialOffers: IFinancialRecordOffer[],
    treasuryMembers: ITreasuryMembersInfo[]
  ) => void;
  setClubBasicInfo: (clubBasicInfo: IClubData) => void;
  setClubMembers: (clubMembers: IMemberData[]) => void;
  setActiveTreasury: (activeTreasury: ITreasuryData) => void;
  setFundraises: (fundraises: IFundraiseConfig[]) => void;
  setMemberData: (memberData: IMemberData | undefined) => void;
  setVault: (vault: IVault) => void;
  setFinancialOffers: (financialOffers: IFinancialRecordOffer[]) => void;
  setMemberTreasuryInfo: (
    memberTreasuryInfo: IMemberTreasuryInfo | undefined
  ) => void;
  setTreasuryMembers: (
    treasuryMembers: ITreasuryMembersInfo[] | undefined
  ) => void;
  setProposals: (proposals: IProposal[] | undefined) => void;
  resetStore: () => void;
  resetMemberStoreInfo: () => void;
  updateFundraise: (fundraise: IFundraiseConfig) => void;
  updateProposal: (proposal: IProposal) => void;
  addProposal: (proposal: IProposal) => void;
  updateWithdrawalProposalMemberData: (
    withdrawalMembetData: IWithdrawalMembersInfo
  ) => void;
  setAllTreasuryDataAddresses: (treasuryDatas: PublicKey[]) => void;
}

const initialState = {
  clubBasicInfo: undefined,
  clubMembers: undefined,
  activeTreasury: undefined,
  fundraises: undefined,
  vault: undefined,
  financialOffers: undefined,
  memberData: undefined,
  memberTreasuryInfo: undefined,
  treasuryMembers: undefined,
  proposals: undefined,
  allTreasuryDataAddresses: undefined,
};

export const clubStore = create<IClubStore>((set, get) => ({
  ...initialState,
  initialStoreSetup: (
    clubBasicInfo: IClubData,
    clubMembers: IMemberData[],
    activeTreasury: ITreasuryData,
    fundraises: IFundraiseConfig[],
    vault: IVault,
    financialOffers: IFinancialRecordOffer[],
    treasuryMembers: ITreasuryMembersInfo[]
  ) =>
    set({
      clubBasicInfo,
      clubMembers,
      activeTreasury,
      fundraises,
      vault,
      financialOffers,
      treasuryMembers,
    }),
  setActiveTreasury: (activeTreasury: ITreasuryData) => set({ activeTreasury }),
  setClubBasicInfo: (clubBasicInfo: IClubData) => set({ clubBasicInfo }),
  setClubMembers: (clubMembers: IMemberData[]) => set({ clubMembers }),
  setFinancialOffers: (financialOffers: IFinancialRecordOffer[]) =>
    set({ financialOffers }),
  setFundraises: (fundraises: IFundraiseConfig[]) => set({ fundraises }),
  setMemberData: (memberData: IMemberData | undefined) =>
    set({
      memberData,
    }),
  setVault: (vault: IVault) => set({ vault }),
  setMemberTreasuryInfo: (
    memberTreasuryInfo: IMemberTreasuryInfo | undefined
  ) => set({ memberTreasuryInfo }),

  setTreasuryMembers: (treasuryMembers: ITreasuryMembersInfo[] | undefined) =>
    set({ treasuryMembers }),

  resetStore: () => set(initialState),
  resetMemberStoreInfo: () =>
    set({
      memberData: undefined,
      memberTreasuryInfo: undefined,
    }),
  setProposals: (proposals: IProposal[] | undefined) => set({ proposals }),
  updateFundraise: (fundraise: IFundraiseConfig) => {
    if (get().fundraises !== undefined) {
      const fundraises = [...get().fundraises!];
      const fundraiseIndex = fundraises.findIndex(
        (item) => item.fundraiseIndex === fundraise.fundraiseIndex
      );
      if (fundraiseIndex < 0) {
        fundraises.push(fundraise);
      } else fundraises[fundraiseIndex] = fundraise;
      set({
        fundraises,
      });
    }
  },
  updateProposal: (proposal: IProposal) => {
    if (get().proposals !== undefined) {
      const proposals = [...get().proposals!];
      const proposalIndex = proposals.findIndex(
        (item) =>
          item.proposalMetadata.proposal === proposal.proposalMetadata.proposal
      );
      if (proposalIndex < 0) {
        proposals.unshift(proposal);
      } else proposals[proposalIndex] = proposal;
      set({
        proposals,
      });
    }
  },
  addProposal: (proposal: IProposal) => {
    if (get().proposals !== undefined) {
      const proposals = [...get().proposals!];
      proposals.push(proposal);
      set({
        proposals,
      });
    }
  },
  updateWithdrawalProposalMemberData: (
    withdrawalMemberData: IWithdrawalMembersInfo
  ) => {
    if (get().proposals !== undefined) {
      const proposals = [...get().proposals!];
      const proposalIndex = proposals.findIndex(
        (item) =>
          item.proposalMetadata.proposal === withdrawalMemberData.proposal
      );
      if (proposalIndex >= 0) {
        proposals[proposalIndex] = {
          ...proposals[proposalIndex],
          proposalDetails: {
            ...proposals[proposalIndex].proposalDetails,
            withdrawalMembersInfo: withdrawalMemberData,
          },
        };
        set({
          proposals,
        });
      }
    }
  },
  setAllTreasuryDataAddresses: (treasuryDatas: PublicKey[]) => {
    set({ allTreasuryDataAddresses: treasuryDatas });
  },
}));
