import { DefaultExplorer } from "../common/enums/clubs.enum";
import create from "zustand";
import { INotification } from "../common/interfaces/common.interface";

interface IUserData {
  notifications: INotification[];

  selectedExplorer: DefaultExplorer | undefined;
}

interface IUserStore extends IUserData {
  setNotifications: (notifications: INotification[]) => void;
  setSelectedExplorer: (selectedExplorer: DefaultExplorer | undefined) => void;
  updateNotification: (notification: INotification) => void;
}

const initialState = {
  user: undefined,
  notifications: [],
  selectedExplorer: undefined,
};

export const userStore = create<IUserStore>((set, get) => ({
  ...initialState,
  setNotifications: (notifications: INotification[]) =>
    set(() => ({ notifications })),
  setSelectedExplorer: (selectedExplorer: DefaultExplorer | undefined) =>
    set(() => ({ selectedExplorer })),
  updateNotification: (notification: INotification) => {
    if (get().notifications !== undefined) {
      const notifications = [...get().notifications!];
      const notificationIndex = notifications.findIndex(
        (item) => item.userNotificationId === notification.userNotificationId
      );
      if (notificationIndex < 0) {
        notifications.unshift(notification);
      } else notifications[notificationIndex] = notification;
      set({
        notifications,
      });
    }
  },
}));
