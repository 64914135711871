import { useAnchorWallet } from "@solana/wallet-adapter-react";
import React, { useMemo, useState } from "react";
import userAccountSettingsFormConfig from "../UserAccountModal/userAccountSettingsFormConfig";
import { IMemberInfo } from "../../common/interfaces/club.interface";
import { SocialNetworkType } from "../../common/enums/clubs.enum";
import SocialNetworkInput from "../../pages/Overview/ClubApplicationModal/SocialNetworkInput/SocialNetworkInput";
import telegramIcon from "../../assets/telegram dark.svg";
import emailIcon from "../../assets/mail black.svg";
import discordIcon from "../../assets/discord black.svg";
import twitterIcon from "../../assets/twitter.svg";
import linkedinIcon from "../../assets/linkedin.svg";
import "./SocialNetworkFields.scss";
import {
  IClubApplicationFormFields,
  IUserAccountSettingsFormFields,
} from "../../common/interfaces/form.interface";

const SocialNetworksFields: React.FC<{
  formFields: any;
  member?: IMemberInfo;
}> = ({ member, formFields }) => {
  const [showInputEmail, setShowInputEmail] = useState(false);
  const [showInputDiscord, setShowInputDiscord] = useState(false);
  const [showInputTwitter, setShowInputTwitter] = useState(false);
  const [showInputLinkedin, setShowInputLinkedin] = useState(false);
  const [showInputTelegram, setShowInputTelegram] = useState(true);

  const wallet = useAnchorWallet();

  const memberInfo = useMemo(() => {
    const discord = member?.socialNetworks.find(
      (item) => item.type === SocialNetworkType.Discord
    );
    const linkedin = member?.socialNetworks.find(
      (item) => item.type === SocialNetworkType.LinkedIn
    );
    const telegram = member?.socialNetworks.find(
      (item) => item.type === SocialNetworkType.Telegram
    );

    const twitter = member?.socialNetworks.find(
      (item) => item.type === SocialNetworkType.Twitter
    );
    const mail = member?.socialNetworks.find(
      (item) => item.type === SocialNetworkType.Mail
    );

    return { discord, linkedin, telegram, twitter, mail };
  }, [member, wallet, formFields]);

  return (
    <div className="social-networks-fields">
      <div className="social-networks-fields__wrapper">
        {(memberInfo.mail || showInputEmail) && (
          <SocialNetworkInput
            name={formFields.mail.name}
            type="text"
            labelTitle={formFields.mail.label}
            placeholder={formFields.mail.label}
            label={formFields.mail.label}
            img={emailIcon}
          />
        )}

        {(memberInfo.discord || showInputDiscord) && (
          <SocialNetworkInput
            name={formFields.discord.name}
            type="text"
            labelTitle={formFields.discord.label}
            placeholder={formFields.discord.label}
            label={formFields.discord.label}
            img={discordIcon}
          />
        )}

        {(memberInfo.linkedin || showInputLinkedin) && (
          <SocialNetworkInput
            name={formFields.linkedin.name}
            type="text"
            labelTitle={formFields.linkedin.label}
            placeholder={formFields.linkedin.label}
            label={formFields.linkedin.label}
            img={linkedinIcon}
          />
        )}
        {(memberInfo.telegram || showInputTelegram) && (
          <SocialNetworkInput
            name={formFields.telegram.name}
            type="text"
            labelTitle={formFields.telegram.label}
            placeholder={formFields.telegram.label}
            label={formFields.telegram.label}
            img={telegramIcon}
          />
        )}

        {(showInputTwitter || memberInfo.twitter) && (
          <SocialNetworkInput
            name={formFields.twitter.name}
            type="text"
            labelTitle={formFields.twitter.label}
            placeholder={formFields.twitter.label}
            label={formFields.twitter.label}
            img={twitterIcon}
          />
        )}
      </div>
      <div className="social-networks-fields__optional">
        <div className="social-networks-fields__add-networks">
          <p>Add another network:</p>
        </div>
        <div className="social-networks-fields__icons">
          <div className="social-networks-fields__icons-img">
            {" "}
            <img
              src={emailIcon}
              alt="email"
              onClick={() => setShowInputEmail(true)}
            />
          </div>

          <div className="social-networks-fields__icons-img">
            <img
              src={discordIcon}
              alt="discord"
              onClick={() => setShowInputDiscord(true)}
            />
          </div>
          <div className="social-networks-fields__icons-img">
            <img
              src={twitterIcon}
              alt="twitter"
              onClick={() => setShowInputTwitter(true)}
            />
          </div>
          <div className="social-networks-fields__icons-img">
            <img
              src={linkedinIcon}
              alt="linkedin"
              onClick={() => setShowInputLinkedin(true)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialNetworksFields;
