import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./ManageTreasuryRolesForm.scss";
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { IManageTreasuryRoles } from "../../../../common/interfaces/form.interface";
import manageTreasuryRolesFormConfig from "../manageTreasuryRolesFormConfig";
import { EMPTY_STRING } from "../../../../common/constants/common.constants";
import { getTrimmedPublicKey } from "../../../../utilities/helpers";
import { IOption } from "../../../../common/interfaces/common.interface";
import { clubStore } from "../../../../state/clubStore";
import cancelIcon from "../../../../assets/cancel.svg";
import InputField from "../../../../components/InputFields/InputField";
import MainButton from "../../../../components/MainButton/MainButton";
import add from "../../../../assets/add_circle_full.png";
import { useLazyQuery } from "@apollo/client";
import { GET_NON_FILTERED_MEMBERS_FOR_CLUB } from "../../../../api/club.api";
import { IMemberData } from "../../../../common/interfaces/club.interface";
import { CLIENT_CONFIGURATION } from "../../../../client/configuration";

const ManageTreasuryRolesForm = () => {
  const { values, errors } = useFormikContext<IManageTreasuryRoles>();
  const { treasuryMembers, activeTreasury, clubBasicInfo } = clubStore();

  const [availableMembers, setAvailableMembers] = useState<IMemberData[]>();

  const [getMembers, membersInfo] = useLazyQuery(
    GET_NON_FILTERED_MEMBERS_FOR_CLUB,
    {
      onCompleted: (data) => {
        setAvailableMembers(
          data?.getNonFilteredClubMembers.filter(
            (item: IMemberData) =>
              !treasuryMembers?.find(
                (trMember) => trMember.authority === item.memberPubkey
              )
          )
        );
      },
    }
  );

  useEffect(() => {
    void getClubMembers();
  }, [clubBasicInfo]);

  const getClubMembers = async () => {
    try {
      if (!clubBasicInfo) return;
      getMembers({
        variables: {
          clubAddress: clubBasicInfo?.address,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addMemberItem = useCallback((arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.push({
      wallet: [],
      role: EMPTY_STRING,
    });
  }, []);

  const removeOption = useCallback(
    (index: number, arrayHelpers: FieldArrayRenderProps) => {
      arrayHelpers.remove(index);
    },
    []
  );

  const membersOptions: IOption[] = useMemo(
    () =>
      availableMembers
        ? availableMembers?.map((member) => {
            return {
              label: getTrimmedPublicKey(member.memberPubkey),
              value: member.memberPubkey,
            };
          })
        : [],
    [availableMembers]
  );

  const roleOptions: IOption[] = useMemo(
    () =>
      activeTreasury?.treasuryRoleConfig
        ? activeTreasury?.treasuryRoleConfig
            .filter((role) => {
              const clientRole = CLIENT_CONFIGURATION.treasuryRoles.find(
                (item) => item.name === role.name
              );
              return !clientRole?.isFounder;
            })
            .map((role) => {
              return {
                label: role.name,
                value: role.name,
              };
            })
        : [],
    [activeTreasury]
  );
  return (
    <div className="manage-treasury">
      <p className="manage-treasury__description">
        Text about managing treasury roles
      </p>
      <FieldArray
        name={manageTreasuryRolesFormConfig.formFields.members.name}
        render={(arrayHelpers) => (
          <Fragment>
            {values.members.map((item, index) => (
              <div className="manage-treasury__members">
                <img
                  src={cancelIcon}
                  alt="Cancel icon"
                  onClick={() => removeOption(index, arrayHelpers)}
                />
                <div className="manage-treasury__members-wallet">
                  <InputField
                    name={`members.${index}.wallet`}
                    type="select"
                    labelTitle="Member wallet"
                    placeholder="Select wallet address"
                    multiple
                    options={membersOptions}
                  />
                </div>
                <div className="manage-treasury__members-role">
                  <InputField
                    name={`members.${index}.role`}
                    type="select"
                    labelTitle="Member role"
                    placeholder="Select member role"
                    options={roleOptions}
                  />
                </div>
              </div>
            ))}
            <div className="manage-treasury__actions">
              <MainButton
                onClick={() => {
                  addMemberItem(arrayHelpers);
                }}
                type="button"
                light
              >
                <img src={add} alt="Add circle" /> Add treasury member
              </MainButton>
            </div>
          </Fragment>
        )}
      />
    </div>
  );
};

export default ManageTreasuryRolesForm;
