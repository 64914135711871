import { FC } from "react";
import "../OTCCard/OTCCard.scss";
import { IOTCLedger } from "../../../common/interfaces/otc.interface";
import {
  getAmountWithDecimalsForCurrency,
  getTrimmedPublicKey,
  turnDateIntoString,
} from "../../../utilities/helpers";
import { clubStore } from "../../../state/clubStore";

const OTCLedgerCard: FC<{ otc: IOTCLedger }> = ({ otc }) => {
  const { activeTreasury } = clubStore();

  return (
    <>
      <div className="otc-card">
        <div>
          <div className="otc-card__details">
            <h3>Seller's Wallet</h3>
            <p className="otc-card--bold">{getTrimmedPublicKey(otc.seller)}</p>
          </div>
          <div className="otc-card__details">
            <h3>Buyer's Wallet</h3>
            <p>{getTrimmedPublicKey(otc.buyer)}</p>
          </div>
        </div>
        <div>
          <div className="otc-card__details">
            <h3>Date</h3>
            <p>{turnDateIntoString(otc.executedAt)}</p>
          </div>
          <div className="otc-card__details">
            <h3>Amount</h3>
            <p>
              {getAmountWithDecimalsForCurrency(
                activeTreasury?.currencyDecimals,
                otc.amount
              )}
            </p>
          </div>
          <div className="otc-card__details">
            <h3>Token</h3>
            <p className="otc-card--bold">{otc.token.name}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OTCLedgerCard;
