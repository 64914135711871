import { FC } from "react";
import { TableCell, TableRow } from "@mui/material";
import BasicDetailsCard from "../../../components/BasicDetailsCard/BasicDetailsCard";
import Chip from "../../../components/Chip/Chip";
import { ClubMember } from "../../../common/enums/clubs.enum";
import {
  BLUE300,
  GREEN100,
  WHITE300,
} from "../../../common/constants/layout.constants";
import CurrencyChip from "../../../components/CurrencyChip/CurrencyChip";
import { IDashboardTreasury } from "../../../common/interfaces/dashboard.interface";
import { getTrimmedPublicKey } from "../../../utilities/helpers";

const TreasuryTableRow: FC<{
  treasury: IDashboardTreasury;
}> = ({ treasury }) => {
  return (
    <TableRow>
      <TableCell style={{ fontSize: "16px" }} width={"35%"}>
        <BasicDetailsCard
          alt=""
          title={treasury.treasuryName}
          id={getTrimmedPublicKey(treasury.treasuryAddress)}
        />
      </TableCell>
      <TableCell>
        <Chip
          text={treasury.treasuryRole ?? "Not defined"}
          backgroundColor={
            treasury.treasuryRole === ClubMember.Member
              ? "transparent"
              : WHITE300
          }
        />
      </TableCell>
      <TableCell>
        <CurrencyChip currency={treasury.treasuryToken.symbol} />
      </TableCell>
      <TableCell>
        <Chip
          text={treasury.activeFundraise ? "In progress" : "No fundraise"}
          backgroundColor={!treasury.activeFundraise ? "transparent" : BLUE300}
          fontSize="0.85"
        />
      </TableCell>
      <TableCell>
        <Chip
          text={`${treasury.ownership.toFixed(2)}%`}
          backgroundColor={GREEN100}
          fontSize="0.85"
        />
      </TableCell>
      <TableCell>
        <p className="home-tables-box--bold">
          {treasury.balance} <span>SOL</span>
        </p>
      </TableCell>
      <TableCell align="right">
        <p className="home-tables-box--bold">
          {treasury.userDeposit} <span>SOL</span>
        </p>
      </TableCell>
    </TableRow>
  );
};

export default TreasuryTableRow;
