import { AnchorWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { create } from "zustand";
import {
  IDashboardAvailableClaim,
  IDashboardClub,
  IDashboardMembership,
  IDashboardProposalItem,
  IDashboardStaking,
  IDashboardTreasury,
  IDashboardWithdrawal,
} from "../common/interfaces/dashboard.interface";
import { IDashboardStats } from "../pages/Home/Home";
import { claimWithdrawal } from "../program/methods/proposals";
import { getClaimPDAs } from "../utilities/helpers";

export interface IDashboardStore {
  dashboardProposals: IDashboardProposalItem[];
  dashboardTreasuries: IDashboardTreasury[];
  dashboardWithdrawals: IDashboardWithdrawal[];
  dashboardStaking: IDashboardStaking[];
  dashboardClubs: IDashboardClub[];
  dashboardStats?: IDashboardStats;
  dashboardMemberships: IDashboardMembership[];
  availableClaims: IDashboardAvailableClaim[];
  setDashboardProposals: (dashboardProposals: IDashboardProposalItem[]) => void;
  setDashboardTreasuries: (dashboardTreasuries: IDashboardTreasury[]) => void;
  setDashboardWithdrawals: (
    dashboardWithdrawals: IDashboardWithdrawal[]
  ) => void;
  setDashboardStakings: (dashboardStakings: IDashboardStaking[]) => void;
  pages: number;
  setPages: (pages: number) => void;
  setDashboardClubs: (clubs: IDashboardClub[]) => void;
  setDashboardStats: (stats: IDashboardStats) => void;
  setDashboardMemberships: (memberships: IDashboardMembership[]) => void;
  setAvailableClaims: (claims: IDashboardAvailableClaim[]) => void;
  claimDashboard: (
    wallet: AnchorWallet,
    withdrwal: IDashboardAvailableClaim | IDashboardWithdrawal
  ) => void;
  resetStore: () => void;
}

const initialValues = {
  dashboardProposals: [],
  dashboardStaking: [],
  dashboardTreasuries: [],
  dashboardMemberships: [],
  dashboardWithdrawals: [],
  dashboardClubs: [],
  availableClaims: [],
  pages: 0,
};

export const useDashboardStore = create<IDashboardStore>((set, get) => ({
  ...initialValues,
  setDashboardProposals: (proposals) => set({ dashboardProposals: proposals }),
  setDashboardStakings: (stakings) => set({ dashboardStaking: stakings }),
  setDashboardTreasuries: (treasuries) =>
    set({ dashboardTreasuries: treasuries }),
  setDashboardWithdrawals: (withdrawals) =>
    set({ dashboardWithdrawals: withdrawals }),
  setPages: (pages) => set({ pages }),
  setDashboardClubs: (clubs) => set({ dashboardClubs: clubs }),
  setDashboardStats: (stats) => set({ dashboardStats: stats }),
  setDashboardMemberships: (memberships) =>
    set({ dashboardMemberships: memberships }),
  setAvailableClaims: (claims) => set({ availableClaims: claims }),
  claimDashboard: async (wallet, withdrawal) => {
    const [memberData, treasuryData] = getClaimPDAs(withdrawal, wallet);

    await claimWithdrawal(
      new PublicKey(withdrawal.address),
      memberData,
      wallet,
      treasuryData,
      new PublicKey(withdrawal.withdrawalAddress),
      new PublicKey(
        (withdrawal as any).userWithdrawal?.withdrawalMint ??
          (withdrawal as any)?.withdrawalToken.mint
      )
    );
  },
  resetStore: () => {
    set(initialValues);
  },
}));
