import { EMPTY_STRING } from "../../common/constants/common.constants";
import { AllocationLimitation } from "../../common/enums/fundraise.enum";
import {
  ICreateFundraiseFormFields,
  IMemberWallets,
  IProposalFormFields,
  ITokenUnlockDate,
} from "../../common/interfaces/form.interface";
import { clubStore } from "../../state/clubStore";
import {
  getAmountWithDecimalsForCurrency,
  validateMultipleMembersField,
} from "../../utilities/helpers";

export const validateCreateFundraiseForm = (
  values: ICreateFundraiseFormFields | IProposalFormFields,
  errors: any
) => {
  const { fundraises, activeTreasury } = clubStore.getState();

  const activeFundraise = fundraises?.find((item) => item.isActive);

  validateMultipleMembersField(values.members, "members", errors);
  validateTokenUnlockDate(values.tokenUnlockDate, "tokenUnlockDate", errors);
  validateCustomAllocation(values.members, errors, values.amount, "members");

  const capAmount =
    getAmountWithDecimalsForCurrency(
      activeTreasury?.currencyDecimals,
      activeFundraise?.raisedAmount
    ) ?? 0;

  if (activeFundraise && Number(values.amount) < capAmount) {
    errors.amount = "Cap cant be less than raised amount";
  }

  if (Number(values.amount) < Number(values.tokensMembersAmount)) {
    errors.tokensMembersAmount =
      "Limited allocation can not be greater than cap";
  }

  if (Number(values.amount) <= 0) {
    errors.amount = "Amount can't be zero or less";
  }

  if (
    values.allocationLimitation === AllocationLimitation.Limited &&
    Number(values.tokensMembersAmount) <= 0
  ) {
    errors.tokensMembersAmount = "Amount can't be zero or less";
  }

  if (isNaN(Number(values.amount))) {
    errors.amount = "Amount has to be a number";
  }

  if (values.amount === EMPTY_STRING) {
    errors.amount = "Amount can't be empty!";
  }

  if (
    values.allocationLimitation === AllocationLimitation.Limited &&
    values.tokensMembersAmount === EMPTY_STRING
  ) {
    errors.tokensMembersAmount = "Amount can't be empty!";
  }

  return errors;
};

const validateTokenUnlockDate = (
  unlockDates: ITokenUnlockDate[],
  arrayName: string,
  errors: any
) => {
  unlockDates.forEach((item, index) => {
    if (Number(item.tokenAmount) <= 0) {
      errors[`${arrayName}.${index}.tokenAmount`] =
        "Amount can't be zero or less";
    }
  });
};

const validateCustomAllocation = (
  members: IMemberWallets[],
  errors: any,
  amount: number | string,
  arrayName: string
) => {
  members.forEach((item, index) => {
    if (Number(item.membersAmount) > Number(amount)) {
      errors[`${arrayName}.${index}.membersAmount`] =
        "Custom allocation can not be greater than cap";
    }
  });
};
