import { EMPTY_STRING } from "../../common/constants/common.constants";
import { AllocationLimitation } from "../../common/enums/fundraise.enum";

export default {
  formId: "createFundraise",
  formFields: {
    amount: {
      name: "amount",
      label: "Amount",
      value: EMPTY_STRING,
    },
    tokenUnlockDate: {
      name: "tokenUnlockDate",
      value: [],
    },
    tokensMembersAmount: {
      name: "tokensMembersAmount",
      label: "Amount between members",
      value: EMPTY_STRING,
    },
    allocationLimitation: {
      name: "allocationLimitation",
      label: "Allocation Limitation",
      value: AllocationLimitation.Unlimited,
    },
    members: {
      name: "members",
      value: [
        {
          wallet: [],
          membersAmount: EMPTY_STRING,
        },
      ],
      label: "Members",
    },
  },
};
