import Routes from "./Routes";
import Navbar from "./components/Navbar/Navbar";
import { WalletWrapper } from "./components/WalletWrapper/WalletWrapper";
import TransactionModal from "./components/TransactionsModal/TransactionModal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "react-loading-skeleton/dist/skeleton.css";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./api/config.api";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

function App() {
  return (
    <WalletWrapper>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ApolloProvider client={apolloClient}>
          <NotificationContainer />
          <WalletModalProvider>
            <Navbar />
          </WalletModalProvider>
          <TransactionModal />
          <Routes />
        </ApolloProvider>
      </LocalizationProvider>
    </WalletWrapper>
  );
}

export default App;
