import React, { FC, useMemo } from "react";
import Modal from "../Modal/Modal";
import { Formik, Form } from "formik";
import FormActions from "../FormActions/FormActions";
import depositeFormConfig from "./fundraiseFormConfig";
import { IFundraiseFormFields } from "../../common/interfaces/form.interface";
import { fundraiseFormValidator } from "./fundraiseFormValidator";
import FundraiseForm from "./FundraiseForm/FundraiseForm";
import fundraiseFormConfig from "./fundraiseFormConfig";
import { supprotClub } from "../../program/methods/clubs";
import { SupportAction } from "../../common/enums/fundraise.enum";
import { clubStore } from "../../state/clubStore";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { createNotification } from "../../utilities/notifications";
import { MESSAGE_TYPE } from "../../common/constants/common.constants";
import { pow } from "mathjs";
import { getTokenAccountInfoForMemberByMint } from "../../program/program-helpers";
import { PublicKey } from "@solana/web3.js";
import {
  checkIfUserHasClubPermissionForAction,
  getAmountWithDecimalsForCurrency,
} from "../../utilities/helpers";
import { ClubAction } from "../../common/enums/clubs.enum";

const WithdrawFormModal: FC<{ closeModal: () => void }> = ({ closeModal }) => {
  const {
    clubBasicInfo,
    activeTreasury,
    fundraises,
    memberData,
    memberTreasuryInfo,
  } = clubStore();
  const wallet = useAnchorWallet();

  const maxWithdraw = useMemo(() => {
    return memberTreasuryInfo?.depositRecords &&
      memberTreasuryInfo?.depositRecords[
        memberTreasuryInfo?.depositRecords.length - 1
      ]?.fundraiseIndex === activeTreasury?.fundraiseCount
      ? getAmountWithDecimalsForCurrency(
          activeTreasury?.currencyDecimals,
          memberTreasuryInfo?.depositRecords[
            memberTreasuryInfo?.depositRecords.length - 1
          ]?.currentFundraiseDeposit
        )
      : 0;
  }, [memberTreasuryInfo, activeTreasury]);

  const initialValues: IFundraiseFormFields = useMemo(() => {
    return {
      amount: depositeFormConfig.formFields.amount.value,
    };
  }, []);

  const handleSubmit = async (values: IFundraiseFormFields) => {
    try {
      const activeFundraise = fundraises?.find((item) => item.isActive);
      if (
        !clubBasicInfo ||
        !wallet ||
        !activeTreasury ||
        !activeFundraise ||
        !memberData
      ) {
        createNotification(MESSAGE_TYPE.ERROR, "Missing data");
        return;
      }
      const memberTokenAccountAddress = activeTreasury.denominatedCurrency
        ? (
            await getTokenAccountInfoForMemberByMint(
              wallet,
              new PublicKey(activeTreasury.denominatedCurrency)
            )
          )?.tokenAccountAddress
        : undefined;
      await supprotClub(
        SupportAction.Withdraw,
        Number(values.amount) *
          Number(pow(10, activeTreasury.currencyDecimals)),
        clubBasicInfo?.address,
        activeTreasury?.treasuryDataAddress,
        activeTreasury?.treasuryAddress,
        activeFundraise.fundraiseIndex,
        memberData?.address,
        clubBasicInfo.name,
        wallet,
        clubBasicInfo.clubType,
        activeTreasury.treasuryIndex,
        clubBasicInfo.communityMint,
        activeTreasury.denominatedCurrency,
        activeTreasury.financialTokenAccount,
        memberTokenAccountAddress
      );

      createNotification(MESSAGE_TYPE.SUCCESS, "Successfully withdraw");
      closeModal();
    } catch (error) {
      createNotification(MESSAGE_TYPE.ERROR, "Failed to withdraw");
    }
  };

  return (
    <Modal closeModal={closeModal} title="Withdraw">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur
        validate={(values) =>
          fundraiseFormValidator(values, undefined, maxWithdraw)
        }
      >
        <Form id={fundraiseFormConfig.formId}>
          <FundraiseForm maxAmount={maxWithdraw} />
          <FormActions
            buttonText="Withdraw"
            cancelAction={closeModal}
            buttonAction={() => handleSubmit}
            disabled={
              !checkIfUserHasClubPermissionForAction(
                memberData,
                clubBasicInfo?.roleConfig,
                ClubAction.SupportClub
              )
            }
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default WithdrawFormModal;
