import { FC, useCallback, useState } from "react";
import { IVertical } from "../../../common/interfaces/club.interface";
import { useFormikContext } from "formik";
import "./PreviewDeal.scss";
import Tag from "../../../components/Tag/Tag";
import imgPlaceholder from "../../../assets/imgPlaceholder.svg";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import InputField from "../../../components/InputFields/InputField";
import createDealFormModel from "../createDealFormModel";
import PreviewSectionHeader from "../PreviewSectionHeader/PreviewSectionHeader";
import DealConfiguration from "../DealConfiguration/DealConfiguration";
import { VERTICALS } from "../../../utilities/data/deal.data";
import RolesConfiguration from "../../../components/RolesConfiguration/RolesConfiguration";
import DealTreasury from "../../../components/DealTreasury/DealTreasury";
import { IBasicClubDataForm } from "../../../common/interfaces/form.interface";
import { toggleClubVerticalHelper } from "../../../utilities/helpers";
import { CLIENT_CONFIGURATION } from "../../../client/configuration";

const formFields = createDealFormModel.formFields;

const PreviewDeal: FC = () => {
  const { values, setFieldValue } = useFormikContext<IBasicClubDataForm>();

  const [basicInfoEdit, toggleBasicInfoEdit] = useState(false);
  const [verticalsEdit, toggleVerticalsEdit] = useState(false);
  const [treasuryConfigEdit, toggleTreasuryConfigEdit] = useState(false);
  const [treasuryRolesEdit, toggleTreasuryRolesEdit] = useState(false);
  const [clubConfigEdit, toggleClubConfigEdit] = useState(false);
  const [clubRolesEdit, toggleClubRolesEdit] = useState(false);

  const toggleVerical = useCallback(
    (v: IVertical) => {
      setFieldValue(
        formFields.verticals.name,
        toggleClubVerticalHelper(v, values.verticals)
      );
    },
    [values, verticalsEdit]
  );

  const renderVerticals = useCallback(
    (verticals: IVertical[]) => {
      return verticals.map((v) => {
        return (
          <Tag
            handleClick={() => verticalsEdit && toggleVerical(v)}
            isActive={
              !!values.verticals.find((vertical) => v.slug === vertical.slug)
            }
            label={v.label}
            value={v.slug}
          />
        );
      });
    },
    [values, verticalsEdit]
  );

  return (
    <div className="preview-deal">
      <div className="preview-deal__header">
        <img
          src={
            values.imageUrl !== EMPTY_STRING ? values.imageUrl : imgPlaceholder
          }
          alt="Org image"
        />
        <h3>{values.name}</h3>
        <p>{values.description}</p>
      </div>
      <div className="preview-deal__section">
        <PreviewSectionHeader
          label="Basic information"
          onEdit={() => toggleBasicInfoEdit(!basicInfoEdit)}
          editInProgress={basicInfoEdit}
        />
        <InputField
          name={createDealFormModel.formFields.name.name}
          type="text"
          disabled={!basicInfoEdit}
          labelTitle={createDealFormModel.formFields.name.label}
        />
        <InputField
          name={createDealFormModel.formFields.description.name}
          type="text"
          disabled={!basicInfoEdit}
          labelTitle={createDealFormModel.formFields.description.label}
        />
      </div>
      {values.verticals.length > 0 && (
        <div className="preview-deal__section">
          <PreviewSectionHeader
            label="Verticals"
            onEdit={() => toggleVerticalsEdit(!verticalsEdit)}
            editInProgress={verticalsEdit}
          />
          <div className="preview-deal__verticals">
            {renderVerticals(verticalsEdit ? VERTICALS : values.verticals)}
          </div>
        </div>
      )}
      {CLIENT_CONFIGURATION.clubRoles.find((item) => !item.required) && (
        <div className="preview-deal__section">
          <PreviewSectionHeader
            label="Club roles"
            onEdit={() => toggleClubRolesEdit(!clubRolesEdit)}
            editInProgress={clubRolesEdit}
          />
          <RolesConfiguration
            edit
            disabled={!clubRolesEdit}
            rolesArray={formFields.roles.name}
            rolesLabel="Club roles"
          />
        </div>
      )}
      {!CLIENT_CONFIGURATION.treasuryConfiguration && (
        <div className="preview-deal__section">
          <PreviewSectionHeader
            label="Treasury"
            onEdit={() => toggleTreasuryConfigEdit(!treasuryConfigEdit)}
            editInProgress={treasuryConfigEdit}
          />
          <DealTreasury disabled={!treasuryConfigEdit} edit />
        </div>
      )}
      <div className="preview-deal__section">
        <PreviewSectionHeader
          label="Treasury roles"
          onEdit={() => toggleTreasuryRolesEdit(!treasuryRolesEdit)}
          editInProgress={treasuryRolesEdit}
        />
        <RolesConfiguration
          edit
          disabled={!treasuryRolesEdit}
          rolesArray={formFields.treasuryRoles.name}
          rolesLabel="Treasury roles"
        />
      </div>
      <div className="preview-deal__section">
        <PreviewSectionHeader
          label="Club configuration"
          onEdit={() => toggleClubConfigEdit(!clubConfigEdit)}
          editInProgress={clubConfigEdit}
        />
        <DealConfiguration edit disabled={!clubConfigEdit} />
      </div>
    </div>
  );
};

export default PreviewDeal;
