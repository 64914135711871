import React, { FC } from "react";
import "./TokenChain.scss";
import { TOKENS } from "../../common/constants/common.constants";
import { clubStore } from "../../state/clubStore";

const TokenChain: FC<{ show?: number }> = ({ show = 1 }) => {
  const { activeTreasury } = clubStore();

  return (
    <div className="token-chain">
      {activeTreasury?.treasuryTokens
        .map((t, index) => (
          <img src={t.image} alt={"Treasury token"} key={index} />
        ))
        .splice(0, show)}
      {activeTreasury?.treasuryTokens &&
        activeTreasury?.treasuryTokens.length > show && (
          <p> +{activeTreasury?.treasuryTokens.length - show}</p>
        )}
    </div>
  );
};

export default TokenChain;
