export enum ActiveTabTreasuryFundraise {
  Fundraise = "fundraise",
  History = "history",
  Tokens = "tokens",
  Members = "members",
}

export enum ActiveTabHistory {
  PastFundraises = "past fundraises",
  HistoricalDeposits = "historical deposits",
}

export enum ActiveTabFundraise {
  Allocation = "allocation",
  Deposits = "deposits",
}
