import { gql } from "@apollo/client";

export const DEPOSIT_SUBSCRIPTION = gql`
  subscription OnUpdatedFinancialRecord($treasuryAddress: String!) {
    updatedFinancialRecord(treasuryAddress: $treasuryAddress) {
      treasury {
        treasuryName
        treasuryDataAddress
        treasuryAddress
      }
      financialRecord {
        address
        authority
        treasury
      }
    }
  }
`;

export const FUNDRAISE_SUBSCRIPTION = gql`
  subscription OnFundraiseConfigChanged($treasuryAddress: String!) {
    fundraiseConfigChanged(treasuryAddress: $treasuryAddress) {
      fundraise {
        fundraiseIndex
      }
      treasuryData {
        treasuryName
        treasuryDataAddress
        treasuryAddress
      }
    }
  }
`;

export const PROPOSAL_SUBSCRIPTION = gql`
  subscription onProposalUpdated(
    $treasuryAddress: String!
    $clubAddress: String
  ) {
    proposalUpdated(
      treasuryAddress: $treasuryAddress
      clubAddress: $clubAddress
    ) {
      proposalAccount {
        governance {
          address
          realm
          activeProposalCount
          governedAccount
          proposalCount
          voteThreshold
          maxVotingTime
        }
        governingTokenMint
        state
        tokenOwnerRecord
        signatoriesCount
        signatoriesSignedOffCount
        yesVotesCount
        noVotesCount
        startVotingAt
        name
        description
        maxVoteWeight
        closedAt
        votingCompletedAt
        denyVoteWeight
        vetoVoteWeight
        abstainVoteWeight
        proposalInstructionAccounts {
          isSigner
          isWritable
          pubkey
        }
      }
      proposalDetails {
        ... on ITransferProposalMetadata {
          offer
          treasuryToken
          destination
          amountToInvest
          mint
          mintLogo
        }
        ... on IWithdrawalProposalMetadata {
          withdrawalAmount
          withdrawal
          withdrawalData
          treasuryToken
          withdrawalMembersInfo {
            proposal
            withdrawAmount
            withdrawal
            withdrawalData
            hasUserClaimed
            withdrawalMint
            withdrawalMintDecimals
            withdrawalMintSymbol
          }
          totalFinancialPower
          withdrawalMint
          mintSymbol
          mintDecimals
        }

        ... on IAddSellPermissionProposal {
          sellPermissionData {
            governance {
              address
            }
            from
            to
            quorumMinimum
          }
          maxVotingTime
        }

        ... on IUpdateGovernanceConfigProposalMetadata {
          newQuorum
          governancesToUpdate
          governedAccountsToUpdate
          newVotingTime
          governanceType
        }

        ... on IUpdateRoleConfigProposalMetadata {
          roleConfigData {
            role
            currentWeight
            updateWeight
          }
        }
      }
      proposalMetadata {
        proposalType
        proposalMetadataAddress
        name
        options
        authority
        discussionLink
        treasury
        totalFinancialPower
        proposal
        proposalIndex
        realm
        clubData
        isCreated
        authorityRole
        dataPda
        approvalQuorumPercentage
        maxVoterWeight
        canCancelProposal
        proposalStatus
        userVote
        description
      }
    }
  }
`;

export const CLUB_DATA_CREATED_SUBSCRIPTION = gql`
  subscription OnClubDataCreated($clubAddress: String!) {
    clubDataCreated(clubAddress: $clubAddress) {
      address
      authority
      name
      slotCreated
      clubType
      roleConfig {
        name
        clubActions
        membersCount
      }
      treasuryCount
      stakeCount
      activeStakeCount
      numberOfMembers
      hasActiveStaking
      defaultRole
      kycConfig
      createdAt
      imgUrl
      description
      verticals {
        label
        slug
      }
    }
  }
`;

export const TREASURY_DATA_UPDATED_SUBSCRIPTION = gql`
  subscription OntreasuryDataUpdated($clubData: String!) {
    treasuryDataUpdated(clubData: $clubData) {
      treasuryAddress
      treasuryGovernance {
        address
      }
    }
  }
`;

export const MEMBER_DATA_CHANGED_SUBSCRIPTION = gql`
  subscription OnmemberDataChanged($clubAddress: String!) {
    memberDataChanged(clubAddress: $clubAddress) {
      address
      clubData
      memberPubkey
      isMember
      status
      role
      joinedAt
    }
  }
`;

export const USER_MEMBER_DATA_CHANGED_SUBSCRIPTION = gql`
  subscription OnmineMemberDataChanged($userAddress: String!) {
    mineMemberDataChanged(userAddress: $userAddress) {
      memberData {
        address
        clubData
      }
      isDeleted
    }
  }
`;

export const MEMBER_DATA_DELETED_SUBSCRIPTION = gql`
  subscription memberDataDeleted($clubAddress: String!) {
    memberDataDeleted(clubAddress: $clubAddress) {
      clubAddress
      deletedMembers
    }
  }
`;

export const USER_WITHDRAWN_SUBSCRIPTION = gql`
  subscription userWithdrawn($userAddress: String!) {
    userWithdrawn(userAddress: $userAddress) {
      proposal
      withdrawAmount
      withdrawal
      withdrawalData
      hasUserClaimed
      withdrawalMint
      withdrawalMintDecimals
      withdrawalMintSymbol
    }
  }
`;

export const OTC_DEAL_SUBSCRIPTION = gql`
  subscription onOtcDealsChanged($treasuryAddress: String!) {
    otcDealsChanged(treasuryAddress: $treasuryAddress) {
      address
      treasury
      financialRecord
      amountOfRights
      price
      seller
      buyer
      wantedTokenMint
      financialIndex
      initialAmountOfRights
      sellerMemberData {
        address
        clubData
        memberPubkey
        isMember
        status
        role
        joinedAt
        joinedAtSlot
        memberInfo {
          userAddress
          imageUrl
          name
          socialNetworks {
            type
          }
        }
      }
      buyerMemberData {
        address
        clubData
        memberPubkey
        isMember
        status
        role
        joinedAt
        joinedAtSlot
        memberInfo {
          userAddress
          imageUrl
          name
          socialNetworks {
            type
          }
        }
      }
      pricePerPct
      offeredPct
      remainingPct
    }
  }
`;

export const OTC_DEAL_DELETED_SUBSCRIPTION = gql`
  subscription onOtcDealsCanceled($treasuryAddress: String!) {
    otcDealDeleted(treasuryAddress: $treasuryAddress) {
      treasuryAddress
      offerAddress
    }
  }
`;

export const TREAUSYRY_MEMBER_ADDED = gql`
  subscription treasuryMembersAdded($clubAddress: String!) {
    treasuryMembersAdded(clubAddress: $clubAddress) {
      clubAddress
    }
  }
`;

export const NEW_NOTIFICATION_SUBSCRIPTION = gql`
  subscription newNotification($userAddress: String!) {
    newNotification(userAddress: $userAddress) {
      userNotificationId
      tab
      proposalAddress
      hasRead
      notificationType
      message
      clubAddress
      createdAt
      updatedAt
      userPubKey
      treasuryAddress
      clubDataInfo {
        imageUrl
      }
    }
  }
`;
export const MEMBER_LEFT_SUBSCRIPTION = gql`
  subscription memberLeft($clubAddress: String!) {
    memberLeft(clubAddress: $clubAddress) {
      clubAddress
      deletedMembers
    }
  }
`;
