import {
  AccountMeta,
  Keypair,
  LAMPORTS_PER_SOL,
  PublicKey,
  SimulatedTransactionResponse,
  SystemProgram,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  GOVERNANCE_ERROR_IDENTIFIER,
  TRANSACTION_ERROR_IDENTIFIER,
  TRANSFER_ERROR_IDENTIFIER,
} from "../common/constants/common.constants";
import { TransactionError } from "../common/interfaces/common.interface";
import { ClubActionMap } from "../common/interfaces/common.interface";
import { ClubAction, TreasuryGovernanceType } from "../common/enums/clubs.enum";
import { Wallet } from "@project-serum/anchor/dist/cjs/provider";
import {
  RPC_CONNECTION,
  UNQ_SPL_GOVERNANCE_PROGRAM_ID,
  programFactory,
} from "./utils";
import {
  accountGovernanceSeed,
  adminSeed,
  financialRecordSeed,
  fundraiseCfgSeed,
  governanceChangeGovernanceSeed,
  sellPermissionGovernanceSeed,
  transferGovernanceSeed,
  treasuryDataSeed,
  treasurySeed,
  unqClubSeed,
  vaultDataSeed,
  vaultSeed,
} from "../common/constants/seeds.constants";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  AccountLayout,
  createAssociatedTokenAccountInstruction,
  createInitializeAccountInstruction,
  getAccount,
  getAssociatedTokenAddress,
  getMinimumBalanceForRentExemptAccount,
  getOrCreateAssociatedTokenAccount,
} from "@solana/spl-token";
import {
  IGovernance,
  ITreasuryData,
} from "../common/interfaces/club.interface";
import { ChangeGovernanceTypeEnum } from "../common/enums/proposal.enum";

export const emptyWallet = (publicKey: PublicKey): Wallet => ({
  signTransaction: async (tx: Transaction) => new Promise(() => tx),
  signAllTransactions: async (txs: Transaction[]) => new Promise(() => txs),
  publicKey: publicKey,
});

/**
 * Map errors from chain
 * @param simulateResult {SimulatedTransactionResponse | null} Tx result
 * @param errorMessage {string}
 * @param txid {string}
 */
export const mapSolanaErrors = (
  simulateResult: SimulatedTransactionResponse | null,
  errorMessage: string,
  txid: string
) => {
  const errorIndetifiers = [
    TRANSACTION_ERROR_IDENTIFIER,
    TRANSFER_ERROR_IDENTIFIER,
    GOVERNANCE_ERROR_IDENTIFIER,
  ];

  if (simulateResult && simulateResult.err) {
    if (simulateResult.logs) {
      errorIndetifiers.forEach((item) => {
        throwTransactionErrorIfNeeded(simulateResult.logs!, item, txid);
      });
    }
  }

  throw new TransactionError(errorMessage, txid);
};

/**
 * Find first substring with given parameter
 * @param array {string[]}
 * @param queryParameter {string}
 * @returns
 */
export const findFirstSubstring = (array: string[], queryParameter: string) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].indexOf(queryParameter) !== -1) return i;
  }
  return -1;
};

/**
 * Throws Transaction error
 * @param simulateResultLogs {string[]}
 * @param errorIndetifier {string}
 * @param txid {string}
 */
const throwTransactionErrorIfNeeded = (
  simulateResultLogs: string[],
  errorIndetifier: string,
  txid: string
) => {
  const hassIdentifiedError = findFirstSubstring(
    simulateResultLogs,
    errorIndetifier
  );

  if (hassIdentifiedError !== -1) {
    throw new TransactionError(
      simulateResultLogs[hassIdentifiedError].split(errorIndetifier)[1],
      txid
    );
  }
};

/**
 * Parse enum for club actions
 * @param clubAction {ClubAction}
 * @returns
 */
export const parseClubActionEnum = (clubAction: ClubAction) => {
  const clubActionMap: ClubActionMap = {} as ClubActionMap;

  for (const ca in ClubAction) {
    if (isNaN(parseInt(ca, 10))) {
      continue;
    }

    clubActionMap[parseInt(ca, 10)] = {
      [ClubAction[parseInt(ca, 10)].charAt(0).toLowerCase() +
      ClubAction[parseInt(ca, 10)].slice(1)]: {},
    };
  }
  return clubActionMap[clubAction] as Object;
};

/**
 * Parse any enum for chain
 * @param enumType {T}
 * @param enumVariant {number}
 * @returns
 */
export function parseEnum<T>(enumType: T, enumVariant: number): Object {
  const result: any = {};
  let count = 0;
  for (const key in enumType) {
    if (count == enumVariant) {
      return (result[key as any] = {
        [(enumType[key] as any).charAt(0).toLowerCase() +
        (enumType[key] as any).slice(1)]: {},
      });
    }
    count++;
  }
  return {};
}

export const getRemainingAccountForCreateGovernance = async (
  governanceType: TreasuryGovernanceType,
  treasuryDataAddress: PublicKey,
  treasury: PublicKey,
  treasuryRealm: PublicKey,
  clubData: PublicKey,
  chainId?: string
) => {
  const program = programFactory();

  switch (governanceType) {
    case TreasuryGovernanceType.Treasury:
      return [
        {
          pubkey: await getGovernanceForGovernedAccount(
            treasuryRealm,
            treasury
          ),
          isSigner: false,
          isWritable: true,
        },
        {
          pubkey: treasury,
          isSigner: false,
          isWritable: false,
        },
      ];
    case TreasuryGovernanceType.Withdrawal:
      return [
        {
          pubkey: await getGovernanceForGovernedAccount(
            treasuryRealm,
            getVaultAddresses(clubData, treasury, chainId).vaultAddress
          ),
          isSigner: false,
          isWritable: true,
        },
        {
          pubkey: getVaultAddresses(clubData, treasury, chainId).vaultAddress,
          isSigner: false,
          isWritable: false,
        },
      ];
    case TreasuryGovernanceType.Transfer:
      return [
        {
          pubkey: await getGovernanceForGovernedAccount(
            treasuryRealm,
            PublicKey.findProgramAddressSync(
              [unqClubSeed, treasury.toBuffer(), transferGovernanceSeed],
              program.programId
            )[0]
          ),
          isSigner: false,
          isWritable: true,
        },
        {
          pubkey: PublicKey.findProgramAddressSync(
            [unqClubSeed, treasury.toBuffer(), transferGovernanceSeed],
            program.programId
          )[0],
          isSigner: false,
          isWritable: false,
        },
      ];
    case TreasuryGovernanceType.SellPermission:
    //TODO
    // return [
    //   {
    //     pubkey: await getGovernanceForGovernedAccount(
    //       treasuryDataAddress,
    //       await getSellPermissionGovernedAccount(
    //         treasuryDataAddress,
    //         false
    //       )
    //     ),
    //     isSigner: false,
    //     isWritable: true,
    //   },
    //   {
    //     pubkey: await getSellPermissionGovernedAccount(
    //       treasuryDataAddress,
    //       true
    //     ),
    //     isSigner: false,
    //     isWritable: false,
    //   },
    // ];
    case TreasuryGovernanceType.GovernanceChange:
      return [
        {
          pubkey: await getGovernanceForGovernedAccount(
            treasuryRealm,
            PublicKey.findProgramAddressSync(
              [
                unqClubSeed,
                treasury.toBuffer(),
                governanceChangeGovernanceSeed,
              ],
              program.programId
            )[0]
          ),
          isSigner: false,
          isWritable: true,
        },
        {
          pubkey: PublicKey.findProgramAddressSync(
            [unqClubSeed, treasury.toBuffer(), governanceChangeGovernanceSeed],
            program.programId
          )[0],
          isSigner: false,
          isWritable: false,
        },
      ];
  }
};

export const getGovernanceForGovernedAccount = async (
  treasuryRealmAddress: PublicKey,
  governedAccount: PublicKey
) => {
  return PublicKey.findProgramAddressSync(
    [
      accountGovernanceSeed,
      treasuryRealmAddress.toBuffer(),
      governedAccount.toBuffer(),
    ],
    UNQ_SPL_GOVERNANCE_PROGRAM_ID
  )[0];
};

const getVaultAddresses = (
  clubDataAddress: PublicKey,
  treasuryAddress: PublicKey,
  chainId?: string
) => {
  chainId = chainId ?? "sol";
  const program = programFactory();

  const [vaultAddress] = PublicKey.findProgramAddressSync(
    [
      unqClubSeed,
      clubDataAddress.toBuffer(),
      treasuryAddress.toBuffer(),
      vaultSeed,
      Buffer.from(chainId),
    ],
    program.programId
  );

  const [vaultDataAddress] = PublicKey.findProgramAddressSync(
    [
      unqClubSeed,
      clubDataAddress.toBuffer(),
      treasuryAddress.toBuffer(),
      vaultDataSeed,
      Buffer.from(chainId),
    ],
    program.programId
  );

  return {
    vaultAddress,
    vaultDataAddress,
  };
};

//returns EnumType.EnumVariant
export function getEnumVariant<T extends { [key: string]: number }>(
  enumType: T,
  value: Object
): number | undefined {
  let chainEnum = Object.keys(value)[0].toString().toLowerCase();
  let count = 0;
  for (const key in enumType) {
    if (
      Object.values(enumType)[key as any].toString().toLowerCase() === chainEnum
    ) {
      return enumType[enumType[key as any]] as number;
    }
    count++;
  }
}

export const findTreasuryAddressByIndex = (
  clubAddress: string,
  treasuryIndex: number
) => {
  const program = programFactory();
  const treasuryIndexBuffer = Buffer.alloc(4);
  treasuryIndexBuffer.writeInt32LE(treasuryIndex, 0);
  const [treasuryAddress] = PublicKey.findProgramAddressSync(
    [
      unqClubSeed,
      new PublicKey(clubAddress).toBuffer(),
      treasurySeed,
      treasuryIndexBuffer,
    ],
    program.programId
  );
  return treasuryAddress;
};

export const getTreasuryByAddressOrReturnDefault = async (
  clubAddress: string,
  treasuryAddress?: string
) => {
  try {
    const defaultTreasury = findTreasuryAddressByIndex(
      clubAddress,
      1
    ).toString();
    if (!treasuryAddress) return defaultTreasury;

    try {
      const program = programFactory();
      const [treasuryDataAddress] = PublicKey.findProgramAddressSync(
        [
          unqClubSeed,
          new PublicKey(treasuryAddress).toBuffer(),
          treasuryDataSeed,
        ],
        program.programId
      );
      const treasuryData = await program.account.treasuryData.fetch(
        treasuryDataAddress
      );
      if (treasuryData.clubData.toString() === clubAddress) {
        return treasuryAddress;
      }
    } catch (error) {
      console.log("Can not find treasury data");
    }

    return defaultTreasury;
  } catch (error) {
    console.log(error);
  }
};

export const getTokenAccountInfoForMemberByMint = async (
  wallet: AnchorWallet,
  mint: PublicKey
): Promise<
  | {
      amount: number;
      tokenAccountAddress: PublicKey;
    }
  | undefined
> => {
  try {
    const tokenAccounts = await RPC_CONNECTION.getParsedTokenAccountsByOwner(
      wallet.publicKey,
      { mint: mint }
    );

    if (tokenAccounts.value.length !== 0) {
      tokenAccounts.value.sort((ta1, ta2) =>
        +ta2.account.data.parsed.info.tokenAmount.amount >
        +ta1.account.data.parsed.info.tokenAmount.amount
          ? 1
          : -1
      );
      return {
        amount:
          +tokenAccounts.value[0].account.data.parsed.info.tokenAmount.amount,
        tokenAccountAddress: tokenAccounts.value[0].pubkey,
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export async function getMaxDenomCureencyDepositAmountForTokenOwner(
  mint: PublicKey,
  member: PublicKey
): Promise<
  | {
      maxAmount: number;
      membersTA: PublicKey | undefined;
    }
  | undefined
> {
  try {
    const parsedAccountInfo =
      await RPC_CONNECTION.getParsedTokenAccountsByOwner(member, {
        mint: mint,
      });

    if (parsedAccountInfo.value.length === 0) {
      return {
        maxAmount: 0,
        membersTA: undefined,
      };
    }
    const associatedTokenAccountByMint = await getAssociatedTokenAddress(
      mint,
      member
    );

    const membersTa =
      parsedAccountInfo.value.find(
        (item) =>
          item.pubkey.toString() === associatedTokenAccountByMint.toString()
      ) ?? parsedAccountInfo.value[0];

    return {
      maxAmount: Number(membersTa.account.data.parsed.info.tokenAmount.amount),
      membersTA: membersTa.pubkey,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getMaxSolDepositAmountForTokenOwner(
  member: PublicKey
): Promise<number | undefined> {
  try {
    const parsedAccountInfo: any = await RPC_CONNECTION.getParsedAccountInfo(
      member
    );
    if (parsedAccountInfo.value === null) {
      return 0;
    }
    return parsedAccountInfo.value.lamports;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getRemainingAccountsForFinishSyndicateFundraise(
  payer: AnchorWallet,
  treasuryFinancialAccount?: PublicKey,
  treasuryDenominatedCurrency?: PublicKey
): Promise<{
  syndicateRemainingAccounts: AccountMeta[];
  instructions: TransactionInstruction[];
}> {
  const program = programFactory();
  const instructions: TransactionInstruction[] = [];

  const [adminsAddress] = PublicKey.findProgramAddressSync(
    [unqClubSeed, adminSeed],
    program.programId
  );
  let admins = await program.account.admins.fetch(adminsAddress);
  let feeDestinations = admins.fundraiseFeeConfigs.map((feeConfig) => {
    return feeConfig.authority;
  });

  let remainingAccounts: AccountMeta[] = [
    {
      pubkey: adminsAddress,
      isSigner: false,
      isWritable: false,
    },
  ];

  if (treasuryDenominatedCurrency && treasuryFinancialAccount) {
    remainingAccounts.push(
      {
        pubkey: treasuryFinancialAccount,
        isSigner: false,
        isWritable: true,
      },
      {
        pubkey: TOKEN_PROGRAM_ID,
        isSigner: false,
        isWritable: false,
      }
    );

    for await (const feeDestination of feeDestinations) {
      let destinationTokenAccount = await getOrCreateATAOwnedBySpecifiedAccount(
        feeDestination,
        payer.publicKey,
        treasuryDenominatedCurrency
      );
      remainingAccounts.push({
        pubkey: destinationTokenAccount.address,
        isSigner: false,
        isWritable: true,
      });
      if (destinationTokenAccount.instruction) {
        instructions.push(destinationTokenAccount.instruction);
      }
    }
  } else {
    remainingAccounts.push(
      ...feeDestinations.map((destination) => {
        return {
          pubkey: destination,
          isSigner: false,
          isWritable: true,
        };
      })
    );
  }

  return {
    syndicateRemainingAccounts: remainingAccounts,
    instructions,
  };
}

export async function getOrCreateATAOwnedBySpecifiedAccount(
  owner: PublicKey,
  feePayer: PublicKey,
  mint: PublicKey
): Promise<{
  instruction: TransactionInstruction | undefined;
  address: PublicKey;
}> {
  const ata = await getAssociatedTokenAddress(
    mint, // mint
    owner, // owner
    true,
    TOKEN_PROGRAM_ID,
    ASSOCIATED_TOKEN_PROGRAM_ID
  );
  try {
    await getAccount(RPC_CONNECTION, ata);
    return {
      instruction: undefined,
      address: ata,
    };
  } catch (error) {
    return {
      instruction: createAssociatedTokenAccountInstruction(
        feePayer, // fee payer
        ata, // ata
        owner, // owner of token account
        mint, // mint
        TOKEN_PROGRAM_ID,
        ASSOCIATED_TOKEN_PROGRAM_ID
      ),
      address: ata,
    };
  }
}

export const returnAccountsAndSignerIndexes = (
  ix: TransactionInstruction,
  programId: PublicKey
) => {
  let accounts = ix.keys;

  accounts.push({
    pubkey: programId,
    isWritable: false,
    isSigner: false,
  });

  let signerIndexes: number[] = [];
  accounts.forEach((account, index) => {
    if (account.isSigner) {
      signerIndexes.push(index);
      account.isSigner = false;
    }
  });

  return {
    accounts,
    signerIndexes,
  };
};

export async function createTokenAccountOwnedBySpecifiedAccount(
  owner: PublicKey,
  newOwner: PublicKey,
  mint: PublicKey,
  amount?: number
) {
  const tokenAccount = new Keypair();
  const balanceNeeded = await getMinimumBalanceForRentExemptAccount(
    RPC_CONNECTION
  );
  const createAccountIx = SystemProgram.createAccount({
    fromPubkey: owner,
    newAccountPubkey: tokenAccount.publicKey,
    lamports: amount ? amount : balanceNeeded,
    space: AccountLayout.span,
    programId: TOKEN_PROGRAM_ID,
  });

  const createInitAccountInstruction: TransactionInstruction =
    createInitializeAccountInstruction(
      tokenAccount.publicKey,
      mint,
      newOwner,
      TOKEN_PROGRAM_ID
    );

  return {
    tokenAccount,
    createAccountIx,
    createInitAccountInstruction,
  };
}

export const getGovernedAccountSeeds = (
  changeGovernanceType: ChangeGovernanceTypeEnum,
  activeTreasury: ITreasuryData,
  chainId: string = "sol", //TODO: add other chains
  sellPermissionIndex?: number
): Buffer[][] | undefined => {
  const program = programFactory();
  const bumpBuffer = Buffer.alloc(1);
  switch (changeGovernanceType) {
    case ChangeGovernanceTypeEnum.DefaultConfiguration: {
      let treasuryIndexBuffer = Buffer.alloc(4);
      treasuryIndexBuffer.writeInt32LE(activeTreasury.treasuryIndex, 0);
      const [_treasuryAddress, treasuryBump] = PublicKey.findProgramAddressSync(
        [
          unqClubSeed,
          new PublicKey(activeTreasury.clubDataAddress).toBuffer(),
          treasurySeed,
          treasuryIndexBuffer,
        ],
        program.programId
      );
      bumpBuffer.writeUInt8(treasuryBump, 0);
      return [
        [
          unqClubSeed,
          new PublicKey(activeTreasury.clubDataAddress).toBuffer(),
          treasurySeed,
          treasuryIndexBuffer,
          bumpBuffer,
        ],
      ];
    }
    case ChangeGovernanceTypeEnum.TransferConfiguration: {
      const [governedAccount, governedBump] = PublicKey.findProgramAddressSync(
        [
          unqClubSeed,
          new PublicKey(activeTreasury.treasuryAddress).toBuffer(),
          transferGovernanceSeed,
        ],
        program.programId
      );
      console.log(governedAccount.toString(), "GOVERNED ACC");
      bumpBuffer.writeUInt8(governedBump, 0);
      return [
        [
          unqClubSeed,
          new PublicKey(activeTreasury.treasuryAddress).toBuffer(),
          transferGovernanceSeed,
          bumpBuffer,
        ],
      ];
    }
    case ChangeGovernanceTypeEnum.WithdrawalConfiguration: {
      const [_vaultAddress, vaultBump] = PublicKey.findProgramAddressSync(
        [
          unqClubSeed,
          new PublicKey(activeTreasury.clubDataAddress).toBuffer(),
          new PublicKey(activeTreasury.treasuryAddress).toBuffer(),
          vaultSeed,
          Buffer.from(chainId),
        ],
        program.programId
      );
      bumpBuffer.writeUInt8(vaultBump, 0);
      return [
        [
          unqClubSeed,
          new PublicKey(activeTreasury.clubDataAddress).toBuffer(),
          new PublicKey(activeTreasury.treasuryAddress).toBuffer(),
          vaultSeed,
          Buffer.from(chainId),
          bumpBuffer,
        ],
      ];
    }
    case ChangeGovernanceTypeEnum.ChangeClubConfiguration: {
      const [_governedAccount, governedBump] = PublicKey.findProgramAddressSync(
        [
          unqClubSeed,
          new PublicKey(activeTreasury.treasuryAddress).toBuffer(),
          governanceChangeGovernanceSeed,
        ],
        program.programId
      );
      bumpBuffer.writeUInt8(governedBump, 0);
      return [
        [
          unqClubSeed,
          new PublicKey(activeTreasury.treasuryAddress).toBuffer(),
          governanceChangeGovernanceSeed,
          bumpBuffer,
        ],
      ];
    }
    case ChangeGovernanceTypeEnum.TradeApprovalConfiguration:
      if (sellPermissionIndex === undefined) {
        throw new Error("Sell permission index not provided");
      }
      const indexBuffer = Buffer.alloc(4);
      indexBuffer.writeUint32LE(sellPermissionIndex, 0);
      const [_governedAccount, governedBump] = PublicKey.findProgramAddressSync(
        [
          unqClubSeed,
          new PublicKey(activeTreasury.realmAddress).toBuffer(),
          sellPermissionGovernanceSeed,
          indexBuffer,
        ],
        programFactory().programId
      );
      bumpBuffer.writeUInt8(governedBump, 0);
      return [
        [
          unqClubSeed,
          new PublicKey(activeTreasury.realmAddress).toBuffer(),
          sellPermissionGovernanceSeed,
          indexBuffer,
          bumpBuffer,
        ],
      ];
  }
};

export const getFundraiseConfigAddress = (
  fundraiseIndex: number,
  treasuryDataAddress: PublicKey
): PublicKey => {
  let countBuff = Buffer.alloc(4);
  countBuff.writeUInt32LE(fundraiseIndex, 0);
  const [fundraiseConfigAddress] = PublicKey.findProgramAddressSync(
    [unqClubSeed, treasuryDataAddress.toBuffer(), fundraiseCfgSeed, countBuff],
    programFactory().programId
  );

  return fundraiseConfigAddress;
};

export function getUpdateVoterWeightInput(
  inputAction: string,
  targetAction: string
): any {
  const result: any = {};
  result[inputAction] = { "0": { [targetAction]: {} } };

  return result;
}

export const getRemainingAccountsForDeleteMember = (
  treasuryDatas: PublicKey[],
  member: PublicKey
) => {
  const remainingAccounts: AccountMeta[] = [];

  for (const treasuryData of treasuryDatas) {
    remainingAccounts.push({
      pubkey: treasuryData,
      isWritable: true,
      isSigner: false,
    });
    const [financialRecordAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        treasuryData.toBuffer(),
        financialRecordSeed,
        member.toBuffer(),
      ],
      programFactory().programId
    );
    remainingAccounts.push({
      pubkey: financialRecordAddress,
      isWritable: true,
      isSigner: false,
    });
  }
  return remainingAccounts;
};

export const getAdminConfigInfo = async () => {
  try {
    const program = programFactory();
    const [adminsAddress] = PublicKey.findProgramAddressSync(
      [unqClubSeed, adminSeed],
      program.programId
    );
    return await program.account.admins.fetch(adminsAddress);
  } catch (error) {
    console.log(error);
    throw error;
  }
};
