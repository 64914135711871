import { useState } from "react";
import "./Sidebar.scss";
import { SIDEBAR_ITEMS } from "../../common/constants/navigation.constants";
import SidebarLink from "../SidebarLink/SidebarLink";

const Sidebar = () => {
  const storedExpand = localStorage.getItem("sidebarExpand");
  const [expand, setExpand] = useState(
    storedExpand ? JSON.parse(storedExpand) : false
  );

  const toggleExpand = () => {
    setExpand(!expand);
    localStorage.setItem("sidebarExpand", JSON.stringify(!expand));
  };

  return (
    <div className="sidebar">
      {SIDEBAR_ITEMS.map((item, index) => (
        <SidebarLink
          item={item}
          key={index}
          toggleExpand={toggleExpand}
          expand={expand}
        />
      ))}
    </div>
  );
};

export default Sidebar;
