import React from "react";
import "./IntroDeck.scss";
import IntroDeckHeader from "./IntroDeckHeader/IntroDeckHeader";
import TeamCard from "./TeamCard/TeamCard";
import InvestorsCard from "./InvestorsCard/InvestorsCard";
import RaiseDetailsCard from "./RaiseDetailsCard/RaiseDetailsCard";
import VestingCard from "./VestingCard/VestingCard";
import ClubDetailsLayout from "../../components/ClubDetailsWrapper/ClubDetailsWrapper";

const IntroDeck = () => {
  return (
    <ClubDetailsLayout>
      <div className="intro-deck">
        <IntroDeckHeader />
        <div className="intro-deck__content">
          <TeamCard />
          <InvestorsCard />
          <RaiseDetailsCard />
          <VestingCard />
        </div>
      </div>
    </ClubDetailsLayout>
  );
};

export default IntroDeck;
