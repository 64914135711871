import React from "react";
import DetailsCard from "../../../components/DetailsCard/DetailsCard";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import MainButton from "../../../components/MainButton/MainButton";

const RaiseDetailsCard = () => {
  const RAISE_DETAILS = [
    {
      heading: "Round",
      value: "Seed",
    },
    {
      heading: "Model",
      value: "SAFE",
    },
    {
      heading: "Valuation",
      value: "20M Post-money",
    },
  ];

  return (
    <DetailsCard title="Raise Details">
      <div className="intro-deck__details-card">
        <div className="intro-deck__details-card-raised">
          <div>
            <h6>Total Raising</h6>
            <p>
              45.2714
              <span className="intro-deck__details-card-raised--grey">
                {" "}
                SOL
              </span>
            </p>
            <ProgressBar numerator={45.2714} denominator={500.0} active />
            <div className="intro-deck__details-card-stats">
              {RAISE_DETAILS.map((d, index) => (
                <div key={index}>
                  <h6>{d.heading}</h6>
                  <p>{d.value}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="intro-deck__details-card-stats">
          <MainButton onClick={() => console.log("click")} type="button">
            Deposit
          </MainButton>
          <MainButton onClick={() => console.log("click")} type="button" light>
            Withdraw
          </MainButton>
        </div>
      </div>
    </DetailsCard>
  );
};

export default RaiseDetailsCard;
