import React, { useState } from "react";
import {
  MaterialUIFormControl,
  MaterialUIInputLabel,
  MaterialUIMenuItem,
  MaterialUISelect,
} from "../../common/constants/mui.constants";

const SelectField: React.FC<{
  labelTitle?: string;
  activeValue: string;
  fieldError?: string;
  children?: React.ReactNode;
  onChange: (value: string) => void;
}> = ({ labelTitle, activeValue, onChange, fieldError, children }) => {
  return (
    <MaterialUIFormControl fullWidth>
      <MaterialUIInputLabel id="input-select" variant="filled">
        {labelTitle}
      </MaterialUIInputLabel>
      <MaterialUISelect
        labelId="input-select"
        id="input-select"
        value={activeValue}
        label="select"
        onChange={(e: any) => onChange(e.target.value)}
        variant="filled"
        sx={
          fieldError
            ? {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid red",
                },
              }
            : undefined
        }
      >
        {children}
      </MaterialUISelect>
    </MaterialUIFormControl>
  );
};

export default SelectField;
