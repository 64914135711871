import { FC, useState } from "react";
import copy from "../../assets/content_copy.png";
import info from "../../assets/info.svg";
import "./TreasuryDetails.scss";
import TreasuryConfigurationModal from "../../pages/Overview/TreasuryConfigurationModal/TreasuryConfigurationModal";
import { handleCopyToClipboard } from "../../utilities/helpers";
import HoverIcon from "../HoverIcon/HoverIcon";
import { ITreasuryData } from "../../common/interfaces/club.interface";

const TreasuryDetails: FC<{
  treasuryData: ITreasuryData;
}> = ({ treasuryData }) => {
  const [isTreasuryConfigurationModalActive, toggleTreasuryConfigurationModal] =
    useState(false);

  return (
    <>
      {isTreasuryConfigurationModalActive && (
        <TreasuryConfigurationModal
          closeModal={() => toggleTreasuryConfigurationModal(false)}
          treasuryData={treasuryData}
        />
      )}
      <div className="overview-treasury-details">
        <div className="overview-treasury-details-info">
          <h6>{treasuryData.treasuryName}</h6>
          <div>
            <p>{treasuryData.treasuryAddress}</p>
            <img
              src={copy}
              alt={"Copy"}
              onClick={(e) => {
                e.stopPropagation();
                handleCopyToClipboard(treasuryData.treasuryAddress);
              }}
            />
          </div>
        </div>
        <HoverIcon
          src={info}
          alt={"Info"}
          onClick={(e) => {
            e.stopPropagation();
            toggleTreasuryConfigurationModal(true);
          }}
        />
      </div>
    </>
  );
};

export default TreasuryDetails;
