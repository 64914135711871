import { BN } from "@project-serum/anchor";
import { field, vec, option, serialize } from "@dao-xyz/borsh";

import {
  ClubAction,
  TreasuryAction,
  TreasuryGovernanceType,
} from "../enums/clubs.enum";
import { parseClubActionEnum, parseEnum } from "../../program/program-helpers";
import { PublicKey } from "@solana/web3.js";
import {
  unqClubSeed,
  sellPermissionGovernanceSeed,
} from "../constants/seeds.constants";
import { IMembersAndPercentages } from "../interfaces/form.interface";

export class RoleDto {
  name: string;
  roleWeight: BN;
  clubActions: Object[];

  constructor(name: string, roleWeight: BN, clubActions: ClubAction[]) {
    let objects: Object[] = [];
    clubActions.forEach((ca) => {
      objects.push(parseClubActionEnum(ca));
    });
    let clubActionObjectArray: Object[] = objects;
    this.name = name;
    this.roleWeight = roleWeight;
    this.clubActions = clubActionObjectArray;
  }

  static serializeArray(arr: RoleDto[]) {
    return arr.map((x) => serialize(x));
  }
}

export class TreasuryRoleDto {
  name: string;
  roleWeight: BN;
  treasuryActions: Object[];
  isDefault: boolean;

  constructor(
    name: string,
    roleWeight: BN,
    treasuryActions: TreasuryAction[],
    isDefault: boolean
  ) {
    let objects: Object[] = [];
    treasuryActions.forEach((ca) => {
      objects.push(parseEnum(TreasuryAction as any, ca));
    });
    let treasuryActionObjectArray: Object[] = objects;
    this.name = name;
    this.roleWeight = roleWeight;
    this.treasuryActions = treasuryActionObjectArray;
    this.isDefault = isDefault;
  }

  static getOwnerRole(): string {
    return this.getDefaultRoleConfig()[0].name;
  }

  static getDefaultRoleConfig(): TreasuryRoleDto[] {
    return [
      new TreasuryRoleDto(
        "founder",
        new BN(500),
        this.getAllTreasuryActions(),
        false
      ),
      new TreasuryRoleDto(
        "treasuryManager",
        new BN(300),
        this.getAllTreasuryActions(),
        false
      ),
      new TreasuryRoleDto("member", new BN(200), [], true),
    ];
  }

  static getDefaultSerializedRoleConifg(): Uint8Array[] {
    return [serialize(this.getDefaultRoleConfig()[0])];
  }

  static getAllTreasuryActions() {
    return [
      TreasuryAction.CreateDiscussionProposal,
      TreasuryAction.CreateP2PProposal,
      TreasuryAction.CreateWithdrawalProposal,
      TreasuryAction.CreateTransferProposal,
      TreasuryAction.CreateMeProposal,
      TreasuryAction.SignOffProposal,
      TreasuryAction.CancelProposal,
      TreasuryAction.CancelP2POffer,
    ];
  }

  static getDefaultTreasuryActions() {
    return [];
  }

  static serializeArray(arr: TreasuryRoleDto[]) {
    return arr.map((x) => serialize(x));
  }
}

export class GovernanceDto {
  governanceType: Object;
  maxVotingTime: number;
  voteThresholdPercentage: number;
  sellPermission: SellPermision | null;

  constructor(
    governanceType: TreasuryGovernanceType,
    maxVotingTime: number,
    voteThresholdPercentage: number,
    sellPermission: SellPermision | null
  ) {
    this.governanceType = parseEnum(TreasuryGovernanceType, governanceType);
    this.maxVotingTime = maxVotingTime;
    this.voteThresholdPercentage = voteThresholdPercentage;
    this.sellPermission = sellPermission ?? null;
  }
}

export class SellPermision {
  from: BN;
  to: BN;
  quorumMinimum: number;
  decimal: number;
  governance: PublicKey;

  constructor(
    from: BN,
    to: BN,
    quorumMinimum: number,
    decimal: number,
    governance: PublicKey
  ) {
    this.from = from;
    this.to = to;
    this.quorumMinimum = quorumMinimum;
    this.decimal = decimal;
    this.governance = governance;
  }

  static getSellPermissionGovernanceSeeds(
    realm: PublicKey,
    index: number
  ): Array<Buffer | Uint8Array> {
    let indexBuffer = Buffer.alloc(4);
    indexBuffer.writeUInt32LE(index, 0);
    return [
      unqClubSeed,
      //TODO @Dzonixy: Change this seed to treasury
      realm.toBuffer(),
      sellPermissionGovernanceSeed,
      indexBuffer,
    ];
  }

  static async getGovernanceAddress(
    realm: PublicKey,
    index: number,
    programId: PublicKey
  ) {
    return PublicKey.findProgramAddressSync(
      this.getSellPermissionGovernanceSeeds(realm, index),
      programId
    )[0];
  }
}

export class ReservedRightsDto {
  totalReservedRights: BN;
  totalReservedPercentage: number;
  individualRights: IndividualRights[];

  constructor(individualRights: IMembersAndPercentages[]) {
    this.totalReservedRights = new BN(0);
    this.individualRights = [];
    let totalReservedPercentage = 0;
    individualRights.forEach((ir) => {
      totalReservedPercentage += ir.percentage;
      this.individualRights.push(
        new IndividualRights(ir.member, ir.percentage)
      );
    });
    this.totalReservedPercentage = totalReservedPercentage;
  }
}

class IndividualRights {
  memberPubkey: PublicKey;
  rightPercentage: number;
  amountOfRights: BN;

  constructor(member: PublicKey, rightPercentage: number) {
    this.memberPubkey = member;
    this.rightPercentage = rightPercentage;
    this.amountOfRights = new BN(0);
  }
}

export class UpdateRoleConfigInput {
  @field({ type: "string" })
  role: String;
  @field({ type: "u64" })
  currentWeight: BN;
  @field({ type: "u64" })
  updateWeight: BN;

  constructor(role: String, currentWeight: BN, updateWeight: BN) {
    this.role = role;
    this.currentWeight = currentWeight;
    this.updateWeight = updateWeight;
  }
}

export class UpdateGovernanceConfigInput {
  @field({ type: vec("u8") })
  newQuorums: Buffer;
  @field({ type: vec(vec(vec("u8"))) })
  seeds: Buffer[][];
  @field({ type: option("u32") })
  newVotingTime?: number;

  constructor(newQuorums: Buffer, seeds: Buffer[][], newVotingTime?: number) {
    this.newQuorums = newQuorums;
    this.seeds = seeds;
    this.newVotingTime = newVotingTime;
  }
}

export class SellPermisionDto {
  @field({ type: "u64" })
  from: bigint;

  @field({ type: "u64" })
  to: bigint;

  @field({ type: "u8" })
  quorumMinimum: number;

  @field({ type: "u16" })
  decimal: number;

  constructor(
    from: bigint,
    to: bigint,
    quorumMinimum: number,
    decimal: number
  ) {
    this.from = from;
    this.to = to;
    this.quorumMinimum = quorumMinimum;
    this.decimal = decimal;
  }
}

export class AddSellPermissionData {
  @field({ type: SellPermisionDto })
  sellPermissionDto: SellPermisionDto;

  @field({ type: "u32" })
  maxVotingTime: number;

  constructor(sellPermissionDto: SellPermisionDto, maxVotingTime: number) {
    this.sellPermissionDto = sellPermissionDto;
    this.maxVotingTime = maxVotingTime;
  }
}
