import { FC, useState } from "react";
import "./NftCard.scss";
import MainButton from "../../../components/MainButton/MainButton";
import linkIcon from "../../../assets/link.png";
import solseaIcon from "../../../assets/solsea.svg";
import magicedenIcon from "../../../assets/MagicEden.svg";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import { IDetailNftData } from "../../../common/interfaces/common.interface";

const NftCard: FC<{ card: IDetailNftData; noActions?: boolean }> = ({
  card,
  noActions,
}) => {
  const [showAction, setShowAction] = useState(false);

  return (
    <div
      className="vault-card"
      onMouseEnter={() => setShowAction(true)}
      onMouseLeave={() => setShowAction(false)}
    >
      <img src={card.image} alt="Nft image" />
      {showAction && (
        <>
          <div className="vault-card-actions">
            <a href={EMPTY_STRING}>
              <img src={magicedenIcon} alt="`Magic Eden logo" />
            </a>
            <a href={EMPTY_STRING}>
              <img src={solseaIcon} alt="Solsea logo" />
            </a>
            <a href={EMPTY_STRING}>
              <img src={linkIcon} alt="Paperclip" />
            </a>
          </div>
          <div className="vault-card-buttons">
            {card.offerType === undefined ? (
              <MainButton onClick={() => {}} type="button" squared>
                Sell this NFT
              </MainButton>
            ) : (
              <MainButton onClick={() => {}} type="button" squared>
                Go to Offer
              </MainButton>
            )}
          </div>
        </>
      )}
      <div className="vault-card__details">
        <h3>{card.name}</h3>
        <p>{card.collection?.name}</p>
        {!noActions && (
          <p className="vault-card__details-price">
            {card.purchasePrice} <span>SOL</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default NftCard;
