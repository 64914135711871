import {
  BROWSE_DEALS,
  CREATE_A_DEAL,
  HOME,
  INTRO_DECK,
  MEMBERS,
  OTC_DEAL,
  OVERVIEW,
  PROPOSALS,
  STAKING,
  TREASURY,
  VAULT,
} from "./routes.constants";
import dashboard from "../../assets/dashboard.png";
import introdeck from "../../assets/verified.png";
import proposal from "../../assets/article.png";
import treasury from "../../assets/monetization.png";
import vault from "../../assets/filter.png";
import member from "../../assets/person.png";
import otcdeal from "../../assets/broken_image.png";
import staking from "../../assets/local_atm.png";
import show from "../../assets/add_circle.png";
import { EMPTY_STRING } from "./common.constants";
import { TabLabel } from "../enums/common.enum";
import { CLIENT_CONFIGURATION } from "../../client/configuration";

export const NAVBAR_ITEMS = [
  {
    label: "Home",
    route: HOME,
  },
  {
    label: "Deals",
    route: BROWSE_DEALS,
  },
  { label: "Create a Deal", route: CREATE_A_DEAL },
];

export const SIDEBAR_ITEMS = [
  {
    label: "Overview",
    alt: "Overview",
    route: OVERVIEW,
    icon: dashboard,
  },
  //TODO: add only for project-fundraise
  // {
  //   label: "Intro Deck",
  //   alt: "Intro deck",
  //   route: INTRO_DECK,
  //   icon: introdeck,
  // },
  { label: "Proposals", alt: "Proposals", route: PROPOSALS, icon: proposal },
  { label: "Treasury", alt: "Treasury", route: TREASURY, icon: treasury },
  { label: "Vault", alt: "Vault", route: VAULT, icon: vault },
  { label: "Members", alt: "Members", route: MEMBERS, icon: member },
  { label: "OTC Deal", alt: "OTC Deal", route: OTC_DEAL, icon: otcdeal },
  { label: "Staking", alt: "Staking", route: STAKING, icon: staking },
  { label: "Show", alt: "Show", route: EMPTY_STRING, icon: show },
];

export const HOME_TABS = [
  TabLabel.Treasuries,
  TabLabel.Proposals,
  TabLabel.Withdrawals,
  TabLabel.Staking,
];

export const MEMBERS_TAB = [
  TabLabel.Members,
  TabLabel.Invitations,
  TabLabel.Applications,
];

export const DEALS_TABS = [TabLabel.AllDeals, TabLabel.MyDeals];

export const PROPOSAL_TABS = [TabLabel.Active, TabLabel.Ended];

export const STAKING_TABS = [
  TabLabel.Funding,
  TabLabel.Locked,
  TabLabel.History,
];

export const OTC_TABS = [
  TabLabel.AllOffers,
  TabLabel.MyOffers,
  TabLabel.History,
];

export const VAULT_TABS = [
  TabLabel.ClubNfts,
  TabLabel.Buying,
  TabLabel.Selling,
];
