import { FC } from "react";
import "./BasicDetailsCard.scss";
import { EMPTY_STRING } from "../../common/constants/common.constants";

const BasicDetailsCard: FC<{
  image?: string;
  alt: string;
  title: string;
  id: string;
  large?: boolean;
  additionalInfo?: string;
}> = ({ image, alt, title, id, large, additionalInfo }) => {
  return (
    <div className="basic-club-details-card">
      {image && (
        <div
          style={{
            borderRadius: large ? "12px" : EMPTY_STRING,
            width: large ? "56px" : "40px",
            height: large ? "56px" : "40px",
          }}
        >
          <img src={image} alt={alt} />
        </div>
      )}
      <div className="basic-club-details-card__text">
        <h4>{title}</h4>
        <div>
          <p>{id}</p>
          {additionalInfo && <span>{additionalInfo}</span>}
        </div>
      </div>
    </div>
  );
};

export default BasicDetailsCard;
