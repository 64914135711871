import { FC } from "react";
import Modal from "../../../components/Modal/Modal";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import MainButton from "../../../components/MainButton/MainButton";
import profile from "../../../assets/Profilepicture.png";
import { clubStore } from "../../../state/clubStore";

const CancelSharesModal: FC<{
  closeModal: () => void;
  buttonAction: () => void;
}> = ({ closeModal, buttonAction }) => {
  const wallet = useAnchorWallet();
  const { memberData } = clubStore();

  const handleSubmit = async () => {
    try {
      buttonAction();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal closeModal={closeModal} width="640px">
      <div className="add-member-form">
        <div className="add-member-form__info">
          <img src={memberData?.imageUrl ?? profile} alt="Profile" />
          <h2>Cancel selling offer?</h2>
          <h3>Are you sure you want to cancel?</h3>
          <div
            className="add-member-form__info"
            style={{ flexDirection: "row", gap: "0.5em" }}
          >
            <MainButton
              type="button"
              onClick={() => {
                closeModal();
              }}
              light
            >
              No I am not
            </MainButton>
            <MainButton
              type="button"
              onClick={() => {
                handleSubmit();
              }}
            >
              Cancel offer
            </MainButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CancelSharesModal;
