import React, { FC, useCallback, useMemo, useState } from "react";
import "./CreateProposalModal.scss";
import Modal from "../Modal/Modal";
import ProposalDescription from "./ProposalDescription/ProposalDescription";
import FormActions from "../FormActions/FormActions";
import Chip from "../Chip/Chip";
import { YELLOW100 } from "../../common/constants/layout.constants";
import errorIcon from "../../assets/error.svg";
import { Form, Formik, useFormikContext } from "formik";
import proposalFormConfig from "./proposalFormConfig";
import { proposalFormValidator } from "./proposalFormValidator";
import TransferFundsProposal from "./TransferFundsProposal/TransferFundsProposal";
import WithdrawalProposal from "./WithdrawalProposal/WithdrawalProposal";
import TradingProposal from "./TradingProposal/TradingProposal";
import ConfigurationChangesProposal from "./ConfigurationChangesProposal/ConfigurationChangesProposal";
import {
  IManageVotingPower,
  IProposalFormFields,
  IRoleConfig,
} from "../../common/interfaces/form.interface";
import InputField from "../InputFields/InputField";
import TreasuryConfiguration from "./TreasuryConfiguration/TreasuryConfiguration";
import {
  checkIfUserCanCreateAtLeastOneTypeOfProposal,
  findFundraiseAdressFromPda,
  mapTokenUnlockingDateArray,
  parseSecondsToDays,
} from "../../utilities/helpers";
import {
  ChangeConfigurationTypeEnum,
  ChangeGovernanceTypeEnum,
  ProposalType,
  ProposalTypeProgram,
} from "../../common/enums/proposal.enum";
import { createAndPrepareProposal } from "../../program/methods/proposals";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { clubStore } from "../../state/clubStore";
import { PublicKey } from "@metaplex-foundation/js";
import { createNotification } from "../../utilities/notifications";
import {
  EMPTY_STRING,
  MESSAGE_TYPE,
  NATIVE_MINT_DECIMALS,
} from "../../common/constants/common.constants";
import { RPC_CONNECTION, mint, programFactory } from "../../program/utils";
import { NATIVE_MINT } from "@solana/spl-token";
import { pow } from "mathjs";
import { Proposal } from "@solana/spl-governance";
import CreateFundraiseForm from "../CreateFundraiseFormModal/CreateFundraiseForm/CreateFundraiseForm";
import { saveTokenUnlockingData } from "../../api/club.api";
import { ITokenUnlockingInput } from "../../common/interfaces/common.interface";

const CreateProposalModal: FC<{
  closeModal: () => void;
  proposalType?: ProposalType;
}> = ({ closeModal, proposalType }) => {
  const [activeProposal, setActiveProposal] = useState(
    proposalType ?? ProposalType.Discussion
  );
  const wallet = useAnchorWallet();
  const { activeTreasury, clubBasicInfo, memberData, memberTreasuryInfo } =
    clubStore();

  const initialValues: IProposalFormFields = useMemo(() => {
    const roles: IManageVotingPower[] = [];
    activeTreasury?.treasuryRoleConfig.map((item) => {
      roles.push({
        role: item.name,
        roleVotingPower: item.roleWeight,
        oldVotingPower: item.roleWeight,
      });
    });

    return {
      proposalType:
        proposalType ?? proposalFormConfig.formFields.proposalType.value,
      title: proposalFormConfig.formFields.title.value,
      description: proposalFormConfig.formFields.description.value,
      discussionLink: proposalFormConfig.formFields.discussionLink.value,
      transferAmount: proposalFormConfig.formFields.transferAmount.value,
      transferAddress: proposalFormConfig.formFields.transferAddress.value,
      withdrawalAmount: proposalFormConfig.formFields.withdrawalAmount.value,
      changeConfigurationType:
        proposalFormConfig.formFields.changeConfigurationType.value,
      changeGovernanceType:
        proposalFormConfig.formFields.changeGovernanceType.value,
      votingPowers: roles,
      approvalVotePercentage:
        activeTreasury?.treasuryGovernance.voteThreshold ?? 0,
      maxVotingTime:
        (activeTreasury &&
          parseSecondsToDays(
            activeTreasury.treasuryGovernance.maxVotingTime
          )?.toFixed(0)) ??
        0,
      from: EMPTY_STRING,
      to: EMPTY_STRING,
      quorum: EMPTY_STRING,
      tokenAddress:
        activeTreasury?.treasuryTokens.find(
          (item) => item.tokenAddress === activeTreasury?.denominatedCurrency
        )?.tokenAddress ??
        activeTreasury?.treasuryAddress ??
        EMPTY_STRING,
      amount: proposalFormConfig.formFields.amount.value,
      tokenUnlockDate: proposalFormConfig.formFields.tokenUnlockDate.value,
      tokensMembersAmount:
        proposalFormConfig.formFields.tokensMembersAmount.value,
      allocationLimitation:
        proposalFormConfig.formFields.allocationLimitation.value,
      members: proposalFormConfig.formFields.members.value,
      selectedGovernance: activeTreasury?.treasuryGovernance,
      sellPermision: undefined,
    };
  }, [activeTreasury, proposalType]);

  const showForm = (activeProposal: ProposalType) => {
    switch (activeProposal) {
      case ProposalType.Trading:
        return <TradingProposal />;
      case ProposalType.TransferFunds:
        return <TransferFundsProposal />;
      case ProposalType.Withdrawal:
        return <WithdrawalProposal />;
      case ProposalType.ConfigurationChanges:
        return <ConfigurationChangesProposal />;
      case ProposalType.Fundraise:
        return (
          <CreateFundraiseForm
            formFields={proposalFormConfig.formFields}
            throughProposal
          />
        );
    }
  };

  const handleSubmit = async (values: IProposalFormFields) => {
    try {
      if (!wallet || !memberData || !activeTreasury || !clubBasicInfo) {
        throw new Error("Missing data for create proposal");
      }
      const treasuryToken = activeTreasury?.treasuryTokens.find(
        (item) => item.tokenAddress === values.tokenAddress
      );
      switch (values.proposalType) {
        case ProposalType.TransferFunds:
          await createAndPrepareProposal(
            {
              description: values.description,
              name: values.title,
              options: ["Option yes"],
              proposalType: ProposalTypeProgram.TransferFunds,
              useDeny: true,
              discussionLink: values.discussionLink,
            },
            wallet,
            memberData?.address,
            activeTreasury,
            clubBasicInfo.communityMint,
            {
              destination: new PublicKey(values.transferAddress),
              treasuryToken: new PublicKey(values.tokenAddress),
              transferMint: treasuryToken
                ? new PublicKey(treasuryToken.mint)
                : NATIVE_MINT,
              transferAmount:
                Number(values.transferAmount) *
                Number(
                  pow(10, treasuryToken?.decimals ?? NATIVE_MINT_DECIMALS)
                ),
            }
          );
          break;
        case ProposalType.Discussion:
          await createAndPrepareProposal(
            {
              description: values.description,
              name: values.title,
              options: ["Option yes"],
              proposalType: ProposalTypeProgram.Discussion,
              useDeny: true,
              discussionLink: values.discussionLink,
            },
            wallet,
            memberData?.address,
            activeTreasury,
            clubBasicInfo.communityMint
          );
          break;
        case ProposalType.Withdrawal:
          await createAndPrepareProposal(
            {
              description: values.description,
              name: values.title,
              options: ["Option yes"],
              proposalType: ProposalTypeProgram.Withdrawal,
              useDeny: true,
              discussionLink: values.discussionLink,
            },
            wallet,
            memberData?.address,
            activeTreasury,
            clubBasicInfo.communityMint,
            {
              treasuryToken: new PublicKey(values.tokenAddress),
              withdrawalMint: treasuryToken
                ? new PublicKey(treasuryToken.mint)
                : NATIVE_MINT,
              withdrawalAmount:
                Number(values.withdrawalAmount) *
                Number(
                  pow(10, treasuryToken?.decimals ?? NATIVE_MINT_DECIMALS)
                ),
            }
          );
          break;
        case ProposalType.Fundraise:
          await createAndPrepareProposal(
            {
              description: values.description,
              name: values.title,
              options: ["Option yes"],
              proposalType: ProposalTypeProgram.CreateFundraise,
              useDeny: true,
              discussionLink: values.discussionLink,
            },
            wallet,
            memberData?.address,
            activeTreasury,
            clubBasicInfo.communityMint,
            {
              fundraiseAmount:
                Number(values.amount) *
                Number(pow(10, activeTreasury.currencyDecimals)),
            }
          );
          const fundraiseConfigAddress = findFundraiseAdressFromPda(
            activeTreasury.fundraiseCount + 1,
            activeTreasury.treasuryDataAddress
          );

          const tokenUnlockingDates: ITokenUnlockingInput[] =
            mapTokenUnlockingDateArray(
              values.tokenUnlockDate,
              fundraiseConfigAddress.toString()
            );

          await saveTokenUnlockingData(
            wallet.publicKey.toString(),
            clubBasicInfo.address,
            tokenUnlockingDates
          );

          break;
        case ProposalType.ConfigurationChanges:
          await handleConfigurationChangesPropsal(values);
          break;
      }
      closeModal();
    } catch (error: any) {
      createNotification(
        MESSAGE_TYPE.ERROR,
        "Failed to create proposal",
        error.message
      );
    }
  };

  const handleConfigurationChangesPropsal = async (
    values: IProposalFormFields
  ) => {
    try {
      if (
        !wallet ||
        !memberData ||
        !activeTreasury ||
        !values.selectedGovernance ||
        !clubBasicInfo
      )
        return;
      switch (values.changeConfigurationType) {
        case ChangeConfigurationTypeEnum.VotePercentage:
          const isAddSellPermission =
            values.changeGovernanceType ===
              ChangeGovernanceTypeEnum.TradeApprovalConfiguration &&
            !values.sellPermision;
          const decimalsForDenomCurrency = Number(
            pow(10, activeTreasury.currencyDecimals)
          );

          await createAndPrepareProposal(
            {
              description: values.description,
              name: values.title,
              options: ["Option yes"],
              proposalType: isAddSellPermission
                ? ProposalTypeProgram.AddSellPermission
                : ProposalTypeProgram.UpdateGovernanceConfig,
              useDeny: true,
              discussionLink: values.discussionLink,
            },
            wallet,
            memberData?.address,
            activeTreasury,
            clubBasicInfo.communityMint,
            !isAddSellPermission
              ? {
                  governanceConfig: {
                    maxVotingTime: Number(values.maxVotingTime), //TODO@check max voting time for sell perm
                    votePercentage:
                      values.changeGovernanceType ===
                        ChangeGovernanceTypeEnum.TradeApprovalConfiguration &&
                      values.sellPermision
                        ? Number(values.quorum)
                        : Number(values.approvalVotePercentage),
                    governance:
                      values.changeGovernanceType ===
                        ChangeGovernanceTypeEnum.TradeApprovalConfiguration &&
                      values.sellPermision
                        ? values.sellPermision?.governance
                        : values.selectedGovernance,
                    sellPermissionIndex: values.sellPermision?.index,
                  },
                  changeGovernanceType: values.changeGovernanceType,
                }
              : {
                  addSellPermission: {
                    from: Number(values.from) * decimalsForDenomCurrency,
                    to: Number(values.to) * decimalsForDenomCurrency,
                    maxVotingTime:
                      activeTreasury.treasuryGovernance.maxVotingTime,
                    quorum: Number(values.quorum),
                  },
                }
          );
          break;
        case ChangeConfigurationTypeEnum.VotingPower:
          await createAndPrepareProposal(
            {
              description: values.description,
              name: values.title,
              options: ["Option yes"],
              proposalType: ProposalTypeProgram.UpdateRoleConfig,
              useDeny: true,
              discussionLink: values.discussionLink,
            },
            wallet,
            memberData?.address,
            activeTreasury,
            clubBasicInfo.communityMint,
            {
              roleVotingConfig: values.votingPowers,
            }
          );
          break;
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <Modal closeModal={closeModal} title="Create a Proposal">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur
        validate={(values) => proposalFormValidator(values)}
      >
        <Form id={proposalFormConfig.formId}>
          <div className="create-proposal">
            <ProposalDescription
              title={activeProposal}
              showProposalForm={setActiveProposal}
            />
            <div>
              <h3>Information</h3>
              <div className="create-proposal__form">
                <div>
                  <InputField
                    name={proposalFormConfig.formFields.title.name}
                    type="text"
                    labelTitle={proposalFormConfig.formFields.title.label}
                    placeholder={proposalFormConfig.formFields.title.label}
                  />
                  <InputField
                    name={proposalFormConfig.formFields.description.name}
                    type="text"
                    labelTitle={proposalFormConfig.formFields.description.label}
                    placeholder={
                      proposalFormConfig.formFields.description.label
                    }
                  />
                </div>
                {showForm(activeProposal)}

                <InputField
                  name={proposalFormConfig.formFields.discussionLink.name}
                  type="text"
                  labelTitle={
                    proposalFormConfig.formFields.discussionLink.label
                  }
                  placeholder={
                    proposalFormConfig.formFields.discussionLink.label
                  }
                />

                <TreasuryConfiguration />
              </div>
            </div>
            <Chip
              text="Creating proposal fee ≈0.0215 SOL"
              backgroundColor={YELLOW100}
              stretch
              fontSize="0.8"
              padding="14px"
              icon
              image={errorIcon}
            />
            <FormActions
              buttonText="Create Proposal"
              cancelAction={closeModal}
              buttonAction={() => {}}
              disabled={
                !checkIfUserCanCreateAtLeastOneTypeOfProposal(
                  memberTreasuryInfo,
                  activeTreasury?.treasuryRoleConfig
                )
              }
            />
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default CreateProposalModal;
