import React, { FC } from "react";
import "./TreasuryDropdownCard.scss";
import { ITreasuryDropdownCard } from "../../common/interfaces/treasury.interface";
import TokenChain from "../TokenChain/TokenChain";
import { Chain } from "../../common/enums/common.enum";
import Chip from "../Chip/Chip";
import ChainItems from "../ChainItems/ChainItem";
import TreasuryDetails from "../TreasuryDetails/TreasuryDetails";
import { ITreasuryData } from "../../common/interfaces/club.interface";
import { getAmountWithDecimalsForCurrency } from "../../utilities/helpers";

const TreasuryDropdownCard: FC<{
  treasury: ITreasuryData;
  onChange: (card: ITreasuryData) => void;
}> = ({ treasury, onChange }) => {
  return (
    <div
      className="multiple-treasuries-dropdown-card"
      onClick={() => onChange(treasury)}
    >
      <TreasuryDetails treasuryData={treasury} />
      <div className="multiple-treasuries-dropdown-card__table">
        <div>
          <h5>Treasury</h5>
          <p className="multiple-treasuries-dropdown-card__table-value-currency">
            {getAmountWithDecimalsForCurrency(
              treasury?.currencyDecimals,
              treasury?.balance
            )}{" "}
            <span className="multiple-treasuries-dropdown-card__table-value--grey">
              {treasury.currencySymbol}
            </span>
          </p>
        </div>
        <div>
          <h5>Tokens</h5>
          <TokenChain />
        </div>
        <div>
          <h5>Chain</h5>
          <ChainItems chain={Chain.Solana} />
        </div>
        <div>
          <h5>Fundraise</h5>
          <Chip
            text={
              treasury.hasActiveFundraise
                ? "Active Fundraise"
                : "No active fundraise"
            }
            borderRadius="8"
            fontSize="0.75"
            padding="6px 10px"
          />
        </div>
      </div>
    </div>
  );
};

export default TreasuryDropdownCard;
