import React from "react";
import "./TradingProposal.scss";

const TradingProposal = () => {
  return (
    <div className="trading-proposal">
      <div>
        <p>Choose option</p>
        <p>Select a platform</p>
      </div>
    </div>
  );
};

export default TradingProposal;
