import { Form, Formik } from "formik";
import { FC, useMemo, useState } from "react";
import BasicDealInfo from "./BasicDealInfo/BasicDealInfo";
import { CREATE_DEAL_STEP_CONTENT } from "../../utilities/data/deal.data";
import formModel from "./createDealFormModel";
import "./CreateDeal.scss";
import { useNavigate } from "react-router";
import { HOME } from "../../common/constants/routes.constants";
import { validateCreateClub } from "./validation";
import DealConfiguration from "./DealConfiguration/DealConfiguration";
import CreateDealPanel from "./CreateDealPanel/CreateDealPanel";
import CreateDealFooter from "./CreateDealFooter/CreateDealFooter";
import PreviewDeal from "./PreviewDeal/PreviewDeal";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { createClub, getClubDataPDA } from "../../program/methods/clubs";
import { IBasicClubDataForm } from "../../common/interfaces/form.interface";
import {
  CLIENT_CONFIGURATION,
  CreateDealStep,
} from "../../client/configuration";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import arrowLeftIcon from "../../assets/arrow_left_alt.svg";
import { OVERVIEW } from "../../common/constants/routes.constants";
import RolesConfiguration from "../../components/RolesConfiguration/RolesConfiguration";
import DealTreasury from "../../components/DealTreasury/DealTreasury";
import { useMutation, useSubscription } from "@apollo/client";
import { TREASURY_DATA_UPDATED_SUBSCRIPTION } from "../../api/club-subscriptions.api";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import { FetchingDataState } from "../../common/enums/common.enum";
import useTransactionStore from "../../state/transactionStore";
import {
  findTreasuryAddressByIndex,
  getTreasuryByAddressOrReturnDefault,
} from "../../program/program-helpers";
import { SAVE_FUNDRAISE_MODEL } from "../../api/club.api";

const CreateDeal: FC = () => {
  const [activeStep, setActiveStep] = useState(CreateDealStep.BasicDealData);
  const [clubAddress, setClubAddress] = useState(EMPTY_STRING);

  const navigate = useNavigate();
  const wallet = useAnchorWallet();

  const { updateFetchingData } = useTransactionStore();

  useSubscription(TREASURY_DATA_UPDATED_SUBSCRIPTION, {
    onData: ({ data }) => {
      console.log(data, "TREASURY DATA SUB");
      if (data.data.treasuryDataUpdated.treasuryGovernance) {
        const treasuryAddress = findTreasuryAddressByIndex(clubAddress, 1);
        updateFetchingData(FetchingDataState.Succeeded);
        navigate(`${OVERVIEW}/${clubAddress}/${treasuryAddress}`);
      }
    },
    variables: {
      clubData: clubAddress,
    },
  });

  const [saveFundraiseModel] = useMutation(SAVE_FUNDRAISE_MODEL);

  const { formFields } = formModel;

  const initialValues = useMemo(
    () =>
      (() => {
        const values: any = {};
        Object.keys(formModel.formFields).map(
          (key) => (values[key] = (formModel.formFields as any)[key].value)
        );
        return values;
      })(),
    []
  );

  const renderDealPanel = useMemo(() => {
    const dealContentData = CREATE_DEAL_STEP_CONTENT.get(activeStep)!;
    return (
      <CreateDealPanel
        dealPanelDescription={dealContentData.dealPanelContent}
        dealPanelTitle={dealContentData.dealPanelTitle}
      />
    );
  }, [activeStep]);

  const renderDealContent = useMemo(() => {
    switch (activeStep) {
      case CreateDealStep.BasicDealData: {
        return <BasicDealInfo />;
      }
      case CreateDealStep.DealConfiguration: {
        return <DealConfiguration />;
      }
      case CreateDealStep.DealPreview: {
        return <PreviewDeal />;
      }
      case CreateDealStep.TreasuryConfiguration: {
        return <DealTreasury />;
      }
      case CreateDealStep.TreasuryRoles: {
        return (
          <RolesConfiguration
            rolesArray={formFields.treasuryRoles.name}
            rolesLabel="Treasury roles"
          />
        );
      }
      case CreateDealStep.ClubRoles: {
        return (
          <RolesConfiguration
            rolesArray={formFields.roles.name}
            rolesLabel="Club roles"
          />
        );
      }
    }
  }, [activeStep, formFields]);

  const handleCreateClub = async (values: IBasicClubDataForm) => {
    try {
      if (!wallet) return;
      const clubAddress = getClubDataPDA(values.name);
      const treasuryAddress = await getTreasuryByAddressOrReturnDefault(
        clubAddress.toString()
      );
      setClubAddress(clubAddress.toString());
      await createClub(
        wallet,
        values.name,
        values.roles,
        values.treasuryRoles,
        values.requireKyc,
        values.treasuryName,
        Number(values.approvalQuorum),
        Number(values.maxVotingTime),
        values.chain,
        values.verticals,
        values.description,
        values.kycType,
        values.denominatedCurrency,
        values.imageUrl
      );
      await saveFundraiseModel({
        variables: {
          treasuryAddress: treasuryAddress,
          fundraiseModel:
            CLIENT_CONFIGURATION.treasuryConfiguration?.fundraiseModel ??
            values.fundraiseModel,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (values: IBasicClubDataForm) => {
    if (activeStep === CreateDealStep.DealPreview) {
      await handleCreateClub(values);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBackAction = () => {
    if (activeStep === CreateDealStep.BasicDealData) {
      navigate(HOME);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <Formik
      validateOnBlur
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={(values) => validateCreateClub(values, activeStep)}
    >
      <Form id={formModel.formId}>
        <div className="create-deal">
          <div className="create-deal__panel">{renderDealPanel}</div>
          <div className="create-deal__content">
            <div className="create-deal__steps">
              <button onClick={handleBackAction} type="button">
                <img src={arrowLeftIcon} alt="Back icon" />
              </button>
              <ProgressBar
                numerator={activeStep + 1}
                denominator={CLIENT_CONFIGURATION.createDealSteps}
                active
                width={85}
              />
              <p className="create-deal__step-counter">
                {activeStep + 1}/{CLIENT_CONFIGURATION.createDealSteps}
              </p>
            </div>
            <div className="create-deal__content-form">{renderDealContent}</div>
            <CreateDealFooter
              onCancel={handleBackAction}
              onConfirm={() => {}}
              activeStep={activeStep}
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default CreateDeal;
