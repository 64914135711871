import { FC } from "react";
import { TableCell, TableRow } from "@mui/material";
import Chip from "../../../components/Chip/Chip";
import MainButton from "../../../components/MainButton/MainButton";
import { GREEN100 } from "../../../common/constants/layout.constants";
import { IDashboardStaking } from "../../../common/interfaces/dashboard.interface";

const StakingTableRow: FC<{ stake: IDashboardStaking }> = ({ stake }) => {
  return (
    <TableRow>
      <TableCell width={"20%"}>
        <p className="home-tables__small-p">{stake.name}</p>
      </TableCell>
      <TableCell>
        <p className="home-tables__small-p">UNQ Token</p>
      </TableCell>
      <TableCell>
        <Chip text={stake.status} backgroundColor={GREEN100} fontSize="0.85" />
      </TableCell>
      <TableCell>
        <p className="home-tables__small-p">
          {new Date(stake.stakePeriodEnd).toDateString()} days
        </p>
      </TableCell>
      <TableCell>
        <p className="home-tables-box--bold">
          {stake.stakeRecord.amount} <span>UNQ</span>
        </p>
      </TableCell>
      <TableCell>
        <p className="home-tables-box--bold">
          {stake.stakeRecord.claimAmount} <span>UNQ</span>
        </p>
      </TableCell>
      <TableCell>
        <div className="home-tables__align">
          {!stake.stakeRecord.hasClaimed ? (
            <MainButton
              type="button"
              light
              onClick={() => console.log("click")}
            >
              Claim
            </MainButton>
          ) : (
            <Chip text="Claimed" backgroundColor={GREEN100} fontSize="0.85" />
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default StakingTableRow;
