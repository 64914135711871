import React, { FC, ReactNode, useState } from "react";
import "./Pagination.scss";
import left from "../../assets/chevron_left.png";
import right from "../../assets/chevron_right.png";
import ReactPaginate from "react-paginate";

const Pagination: FC<{
  children?: ReactNode;
  totalPages: number;
  handlePageChange: (selectedItem: { selected: number }) => void;
  currentPage: number;
}> = ({ children, handlePageChange, totalPages, currentPage }) => {
  return (
    <>
      {children}
      <ReactPaginate
        pageCount={totalPages}
        onPageChange={handlePageChange}
        previousLabel={<img src={left} alt="Left arrow" />}
        nextLabel={<img src={right} alt="Right arrow" />}
        breakLabel={"..."}
        containerClassName={"pagination"}
        activeClassName={"active"}
        pageRangeDisplayed={1}
        forcePage={currentPage}
      />
    </>
  );
};

export default Pagination;
