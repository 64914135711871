export type ClubProgram = {
  version: "0.2.0";
  name: "club_program";
  constants: [
    {
      name: "SPL_GOV_SEED";
      type: "bytes";
      value: "[103, 111, 118, 101, 114, 110, 97, 110, 99, 101]";
    },
    {
      name: "TREASURY_SEED";
      type: "bytes";
      value: "[116, 114, 101, 97, 115, 117, 114, 121]";
    },
    {
      name: "VAULT_SEED";
      type: "bytes";
      value: "[118, 97, 117, 108, 116]";
    },
    {
      name: "VAULT_DATA_SEED";
      type: "bytes";
      value: "[118, 97, 117, 108, 116, 45, 100, 97, 116, 97]";
    },
    {
      name: "UNQ_CFG_SEED";
      type: "bytes";
      value: "[117, 110, 113, 45, 99, 102, 103]";
    },
    {
      name: "FEE_SEED";
      type: "bytes";
      value: "[102, 101, 101]";
    },
    {
      name: "PERFORMANCE_SEED";
      type: "bytes";
      value: "[112, 101, 114, 102, 111, 114, 109, 97, 110, 99, 101]";
    },
    {
      name: "UNQ_CLUB_SEED";
      type: "bytes";
      value: "[117, 110, 113, 45, 99, 108, 117, 98]";
    },
    {
      name: "UNQ_CLUB_MEMBER_SEED";
      type: "bytes";
      value: "[109, 101, 109, 98, 101, 114]";
    },
    {
      name: "PERMISSION_SEED";
      type: "bytes";
      value: "[112, 101, 114, 109, 105, 115, 115, 105, 111, 110]";
    },
    {
      name: "VOTER_WEIGHT_RECORD_SEED";
      type: "bytes";
      value: "[118, 111, 116, 101, 114, 45, 119, 101, 105, 103, 104, 116, 45, 114, 101, 99, 111, 114, 100]";
    },
    {
      name: "FUNDRAISE_CFG_SEED";
      type: "bytes";
      value: "[102, 117, 110, 100, 114, 97, 105, 115, 101, 45, 99, 102, 103]";
    },
    {
      name: "DEPOSIT_RECORD_SEED";
      type: "bytes";
      value: "[100, 101, 112, 111, 115, 105, 116, 45, 114, 101, 99, 111, 114, 100]";
    },
    {
      name: "FINANCIAL_RECORD_SEED";
      type: "bytes";
      value: "[102, 105, 110, 97, 110, 99, 105, 97, 108, 45, 114, 101, 99, 111, 114, 100]";
    },
    {
      name: "TREASURY_DATA_SEED";
      type: "bytes";
      value: "[116, 114, 101, 97, 115, 117, 114, 121, 45, 100, 97, 116, 97]";
    },
    {
      name: "GOVERNANCE_SEED";
      type: "bytes";
      value: "[97, 99, 99, 111, 117, 110, 116, 45, 103, 111, 118, 101, 114, 110, 97, 110, 99, 101]";
    },
    {
      name: "MAX_VOTER_WEIGHT_RECORD_SEED";
      type: "bytes";
      value: "[109, 97, 120, 45, 118, 111, 116, 101, 114, 45, 119, 101, 105, 103, 104, 116, 45, 114, 101, 99, 111, 114, 100]";
    },
    {
      name: "ROLE_BASED_CONFIG_SEED";
      type: "bytes";
      value: "[114, 111, 108, 101, 45, 98, 97, 115, 101, 100, 45, 99, 111, 110, 102, 105, 103, 45, 115, 101, 101, 100]";
    },
    {
      name: "PROPOSAL_METADATA_SEED";
      type: "bytes";
      value: "[112, 114, 111, 112, 111, 115, 97, 108, 45, 109, 101, 116, 97, 100, 97, 116, 97]";
    },
    {
      name: "ESCROW_SEED";
      type: "bytes";
      value: "[101, 115, 99, 114, 111, 119, 101, 100, 45, 116, 111, 107, 101, 110, 115]";
    },
    {
      name: "WITHDRAWAL_SEED";
      type: "bytes";
      value: "[119, 105, 116, 104, 100, 114, 97, 119, 97, 108]";
    },
    {
      name: "WITHDRAWAL_DATA_SEED";
      type: "bytes";
      value: "[119, 105, 116, 104, 100, 114, 97, 119, 97, 108, 45, 100, 97, 116, 97]";
    },
    {
      name: "SELL_PERMISSION_GOVERNANCE_SEED";
      type: "bytes";
      value: "[115, 112, 99, 45, 103, 111, 118, 101, 114, 110, 97, 110, 99, 101]";
    },
    {
      name: "PROFIT_SEED";
      type: "bytes";
      value: "[112, 114, 111, 102, 105, 116]";
    },
    {
      name: "STAKED_TOKENS_SEED";
      type: "bytes";
      value: "[115, 116, 97, 107, 101, 100, 45, 116, 111, 107, 101, 110, 115]";
    },
    {
      name: "STAKE_CONFIG_SEED";
      type: "bytes";
      value: "[115, 116, 97, 107, 101, 45, 99, 111, 110, 102, 105, 103]";
    },
    {
      name: "STAKE_RECORD_SEED";
      type: "bytes";
      value: "[115, 116, 97, 107, 101, 45, 114, 101, 99, 111, 114, 100]";
    },
    {
      name: "CLUB_PROGRAM_UNQ_TOKENS_SEED";
      type: "bytes";
      value: "[99, 108, 117, 98, 45, 112, 114, 111, 103, 114, 97, 109, 45, 117, 110, 113, 45, 116, 111, 107, 101, 110, 115]";
    },
    {
      name: "UNIVERSE_METADATA_SEED";
      type: "bytes";
      value: "[109, 101, 116, 97, 100, 97, 116, 97]";
    },
    {
      name: "UNIVERSE_SIGNER_SEED";
      type: "bytes";
      value: "[117, 110, 105, 118, 101, 114, 115, 101]";
    },
    {
      name: "STAKED_NFT_SEED";
      type: "bytes";
      value: "[115, 116, 97, 107, 101, 100, 45, 110, 102, 116]";
    },
    {
      name: "FINANCIAL_OFFER_SEED";
      type: "bytes";
      value: "[102, 105, 110, 97, 110, 99, 105, 97, 108, 45, 111, 102, 102, 101, 114]";
    },
    {
      name: "MAGIC_EDEN_DATA_SEED";
      type: "bytes";
      value: "[109, 97, 103, 105, 99, 45, 101, 100, 101, 110]";
    },
    {
      name: "ACCEPTED_OFFER_SEED";
      type: "bytes";
      value: "[97, 99, 99, 101, 112, 116, 101, 100, 45, 111, 102, 102, 101, 114]";
    },
    {
      name: "DENOMINATED_TOKEN_SEED";
      type: "bytes";
      value: "[100, 101, 110, 111, 109, 105, 110, 97, 116, 101, 100, 45, 116, 111, 107, 101, 110]";
    },
    {
      name: "ADMIN_AUTHORITY_SEED";
      type: "bytes";
      value: "[97, 100, 109, 105, 110, 45, 97, 117, 116, 104, 111, 114, 105, 116, 121]";
    },
    {
      name: "ADMIN_SEED";
      type: "bytes";
      value: "[97, 100, 109, 105, 110]";
    },
    {
      name: "TREASURY_MEMBER_SEED";
      type: "bytes";
      value: "[116, 114, 101, 97, 115, 117, 114, 121, 45, 109, 101, 109, 98, 101, 114, 45, 100, 97, 116, 97]";
    },
    {
      name: "TREASURY_REALM_SEED";
      type: "bytes";
      value: "[116, 114, 101, 97, 115, 117, 114, 121, 45, 114, 101, 97, 108, 109]";
    }
  ];
  instructions: [
    {
      name: "createClub";
      accounts: [
        {
          name: "realm";
          isMut: false;
          isSigner: false;
        },
        {
          name: "realmConfig";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenOwnerRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "clubData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "clubName";
          type: "string";
        },
        {
          name: "clubType";
          type: {
            defined: "ClubType";
          };
        },
        {
          name: "roles";
          type: {
            vec: {
              defined: "RolesDto";
            };
          };
        },
        {
          name: "ownerRole";
          type: "string";
        },
        {
          name: "defaultRole";
          type: {
            option: "string";
          };
        },
        {
          name: "kycConfig";
          type: {
            option: {
              defined: "KycConfig";
            };
          };
        }
      ];
    },
    {
      name: "createGovernance";
      accounts: [
        {
          name: "splGovernanceProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "realm";
          isMut: true;
          isSigner: false;
        },
        {
          name: "realmConfig";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenOwnerRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "voterWeightRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "clubData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "governanceDtos";
          type: {
            vec: {
              defined: "GovernanceDto";
            };
          };
        }
      ];
    },
    {
      name: "createTreasury";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "treasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "profit";
          isMut: true;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "clubData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vaultData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "financialRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "realm";
          isMut: false;
          isSigner: false;
        },
        {
          name: "splGovernance";
          isMut: false;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "tokenOwnerRecord";
          isMut: false;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "name";
          type: "string";
        },
        {
          name: "chainId";
          type: "string";
        },
        {
          name: "nftMaxVoterWeight";
          type: {
            option: "u64";
          };
        },
        {
          name: "roleDtos";
          type: {
            vec: {
              defined: "TreasuryRolesDto";
            };
          };
        }
      ];
    },
    {
      name: "supportClub";
      accounts: [
        {
          name: "memberData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "fundraiseConfig";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "clubData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "financialRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "tokenOwnerRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "supportType";
          type: {
            defined: "SupportType";
          };
        }
      ];
    },
    {
      name: "leaveClub";
      accounts: [
        {
          name: "realm";
          isMut: true;
          isSigner: false;
        },
        {
          name: "clubData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "recipientMemberData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "recipient";
          isMut: true;
          isSigner: false;
        },
        {
          name: "voterWeightRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "createClubProposal";
      accounts: [
        {
          name: "governance";
          isMut: true;
          isSigner: false;
        },
        {
          name: "realm";
          isMut: true;
          isSigner: false;
        },
        {
          name: "governanceAuthority";
          isMut: false;
          isSigner: false;
        },
        {
          name: "realmConfig";
          isMut: false;
          isSigner: false;
        },
        {
          name: "proposal";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenOwnerRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "splGovernanceProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "communityTokenMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "voterWeightRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "maxVoterWeightRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "proposalMetadata";
          isMut: true;
          isSigner: false;
        },
        {
          name: "clubData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "financialRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "useDeny";
          type: "bool";
        },
        {
          name: "proposalType";
          type: {
            defined: "ProposalType";
          };
        },
        {
          name: "name";
          type: "string";
        },
        {
          name: "description";
          type: "string";
        },
        {
          name: "options";
          type: {
            vec: "string";
          };
        }
      ];
    },
    {
      name: "allowMember";
      accounts: [
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "clubData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "realm";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "roles";
          type: {
            vec: "string";
          };
        },
        {
          name: "allowType";
          type: {
            defined: "AllowType";
          };
        }
      ];
    },
    {
      name: "updateMember";
      accounts: [
        {
          name: "realm";
          isMut: true;
          isSigner: false;
        },
        {
          name: "clubData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "memberAddress";
          isMut: true;
          isSigner: false;
        },
        {
          name: "memberVoterWeightRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "updaterMemberData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "data";
          type: {
            defined: "UpdateMemberDto";
          };
        }
      ];
    },
    {
      name: "acceptMembership";
      accounts: [
        {
          name: "realm";
          isMut: false;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "memberData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "tokenOwnerRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "clubData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "cancelInvitation";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "memberData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "memberAddress";
          isMut: false;
          isSigner: false;
        },
        {
          name: "clubData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "recipient";
          isMut: true;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "updateVoterWeight";
      accounts: [
        {
          name: "realm";
          isMut: false;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "voterWeightRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "clubData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "action";
          type: {
            defined: "UpdateVoterWeightAction";
          };
        }
      ];
    },
    {
      name: "executeProposal";
      accounts: [
        {
          name: "governance";
          isMut: true;
          isSigner: false;
        },
        {
          name: "proposal";
          isMut: true;
          isSigner: false;
        },
        {
          name: "proposalTransaction";
          isMut: true;
          isSigner: false;
        },
        {
          name: "splGovernanceProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "proposalMetadata";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "clubData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "distribute";
      accounts: [
        {
          name: "clubData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "proposal";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "offer";
          isMut: true;
          isSigner: false;
        },
        {
          name: "escrowProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenLedger";
          isMut: true;
          isSigner: false;
        },
        {
          name: "profit";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "profitToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "escrowedWantedToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "makerWantedToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "treasuryIndex";
          type: "u32";
        },
        {
          name: "chainId";
          type: "string";
        }
      ];
    },
    {
      name: "castNftVote";
      accounts: [
        {
          name: "realm";
          isMut: false;
          isSigner: false;
        },
        {
          name: "voterWeightRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "proposal";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "initializeStaking";
      accounts: [
        {
          name: "clubData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "stakedTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "stakeConfig";
          isMut: true;
          isSigner: false;
        },
        {
          name: "unqMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "stakeName";
          type: "string";
        },
        {
          name: "capAmount";
          type: "u64";
        },
        {
          name: "stakePeriod";
          type: {
            defined: "StakePeriodType";
          };
        }
      ];
    },
    {
      name: "stakeTokens";
      accounts: [
        {
          name: "clubData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "stakeConfig";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "amount";
          type: "u64";
        },
        {
          name: "stakeOption";
          type: {
            defined: "StakeOption";
          };
        }
      ];
    },
    {
      name: "claimStakedTokens";
      accounts: [
        {
          name: "clubData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "stakeConfig";
          isMut: true;
          isSigner: false;
        },
        {
          name: "stakedTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "memberTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "stakeOption";
          type: {
            defined: "StakeOption";
          };
        }
      ];
    },
    {
      name: "unstakeTokens";
      accounts: [
        {
          name: "clubData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "stakeConfig";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "stakeOption";
          type: {
            defined: "StakeOption";
          };
        }
      ];
    },
    {
      name: "finishStaking";
      accounts: [
        {
          name: "clubData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "stakedTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "stakeConfig";
          isMut: true;
          isSigner: false;
        },
        {
          name: "clubUnqTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "startStaking";
      accounts: [
        {
          name: "clubData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "stakedTokens";
          isMut: false;
          isSigner: false;
        },
        {
          name: "stakeConfig";
          isMut: true;
          isSigner: false;
        },
        {
          name: "unqMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "initializeStakingReward";
      accounts: [
        {
          name: "clubUnqTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "unqMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "updateProposalMetadata";
      accounts: [
        {
          name: "proposalMetadata";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "description";
          type: {
            option: "string";
          };
        },
        {
          name: "name";
          type: {
            option: "string";
          };
        },
        {
          name: "options";
          type: {
            option: {
              vec: "string";
            };
          };
        },
        {
          name: "discussionLink";
          type: {
            option: "string";
          };
        }
      ];
    },
    {
      name: "createFinancialOffer";
      accounts: [
        {
          name: "clubData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "financialOffer";
          isMut: true;
          isSigner: false;
        },
        {
          name: "financialRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "amountOfRights";
          type: "u64";
        },
        {
          name: "price";
          type: "u64";
        },
        {
          name: "buyer";
          type: {
            option: "publicKey";
          };
        }
      ];
    },
    {
      name: "cancelFinancialOffer";
      accounts: [
        {
          name: "clubData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "treasuryData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: false;
          isSigner: false;
        },
        {
          name: "financialRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "financialOffer";
          isMut: true;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "acceptFinancialOffer";
      accounts: [
        {
          name: "clubData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "seller";
          isMut: true;
          isSigner: false;
        },
        {
          name: "financialOffer";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: false;
          isSigner: false;
        },
        {
          name: "buyerFinancialRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "sellerFinancialRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "buyerTokenOwnerRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "amountToBuy";
          type: "u64";
        }
      ];
    },
    {
      name: "reserveRights";
      accounts: [
        {
          name: "clubData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "financialRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "reservedRights";
          type: {
            defined: "ReservedRights";
          };
        }
      ];
    },
    {
      name: "updateAllocation";
      accounts: [
        {
          name: "clubData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "fundraiseConfig";
          isMut: true;
          isSigner: false;
        },
        {
          name: "financialRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "equal";
          type: {
            option: "u64";
          };
        },
        {
          name: "customAllocations";
          type: {
            option: {
              vec: {
                defined: "CustomAllocation";
              };
            };
          };
        },
        {
          name: "removeAllocation";
          type: {
            option: {
              vec: {
                defined: "CustomAllocation";
              };
            };
          };
        },
        {
          name: "newCap";
          type: {
            option: "u64";
          };
        }
      ];
    },
    {
      name: "configureWhitelistings";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "whitelistingData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "adminsData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "whitelistings";
          type: {
            vec: "publicKey";
          };
        },
        {
          name: "whitelistingAction";
          type: {
            defined: "WhitelistingAction";
          };
        }
      ];
    },
    {
      name: "configureAdmins";
      accounts: [
        {
          name: "admins";
          isMut: true;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "admins";
          type: {
            option: {
              vec: {
                defined: "AdminConfig";
              };
            };
          };
        },
        {
          name: "feeWallet";
          type: {
            option: "publicKey";
          };
        },
        {
          name: "feePercentage";
          type: {
            option: "u32";
          };
        },
        {
          name: "fundraiseFeeConfigs";
          type: {
            option: {
              vec: {
                defined: "FundraiseFeeConfig";
              };
            };
          };
        },
        {
          name: "otcFeeConfigs";
          type: {
            option: {
              vec: {
                defined: "OtcFeeConfig";
              };
            };
          };
        }
      ];
    },
    {
      name: "migrateFinancials";
      accounts: [
        {
          name: "clubData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "payerMemberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "member";
          isMut: false;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "financialRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "fundraiseConfig";
          isMut: true;
          isSigner: false;
        },
        {
          name: "splGovernance";
          isMut: false;
          isSigner: false;
        },
        {
          name: "clubRealm";
          isMut: false;
          isSigner: false;
        },
        {
          name: "treasuryRealm";
          isMut: false;
          isSigner: false;
        },
        {
          name: "governingTokenMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "clubTokenOwnerRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasuryTokenOwnerRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "usdcAmount";
          type: "u64";
        }
      ];
    },
    {
      name: "insertTransaction";
      accounts: [
        {
          name: "splGovernanceProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "governance";
          isMut: true;
          isSigner: false;
        },
        {
          name: "proposal";
          isMut: true;
          isSigner: false;
        },
        {
          name: "proposalMetadata";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "proposalTransaction";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenOwnerRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "communityTokenMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "data";
          type: {
            vec: "bytes";
          };
        },
        {
          name: "signerIndexes";
          type: {
            option: {
              vec: {
                vec: "u32";
              };
            };
          };
        }
      ];
    },
    {
      name: "fundraise";
      accounts: [
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "fundraiseConfig";
          isMut: true;
          isSigner: false;
        },
        {
          name: "financialRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "clubData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "fundraiseAction";
          type: {
            defined: "FundraiseAction";
          };
        }
      ];
    },
    {
      name: "cancelProposal";
      accounts: [
        {
          name: "clubData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "memberData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "offer";
          isMut: true;
          isSigner: false;
        },
        {
          name: "proposalMetadata";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasuryData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "makerOfferedTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "financialRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "escrowProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "chainId";
          type: "string";
        },
        {
          name: "action";
          type: {
            defined: "ProposalAction";
          };
        }
      ];
    }
  ];
  accounts: [
    {
      name: "clubData";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "realm";
            type: "publicKey";
          },
          {
            name: "name";
            type: "string";
          },
          {
            name: "slotCreated";
            type: "u64";
          },
          {
            name: "clubType";
            type: {
              defined: "ClubType";
            };
          },
          {
            name: "roleConfig";
            type: {
              vec: {
                defined: "RoleConfig";
              };
            };
          },
          {
            name: "treasuryCount";
            type: "u32";
          },
          {
            name: "stakeCount";
            type: "u32";
          },
          {
            name: "activeStakeCount";
            type: "u32";
          },
          {
            name: "hasActiveStaking";
            type: "bool";
          },
          {
            name: "numberOfMembers";
            type: "u32";
          },
          {
            name: "defaultRole";
            type: {
              option: "string";
            };
          },
          {
            name: "kycConfig";
            type: {
              option: {
                defined: "KycConfig";
              };
            };
          }
        ];
      };
    },
    {
      name: "whitelistingData";
      type: {
        kind: "struct";
        fields: [
          {
            name: "whitelistedMembers";
            type: {
              vec: "publicKey";
            };
          }
        ];
      };
    },
    {
      name: "admins";
      type: {
        kind: "struct";
        fields: [
          {
            name: "feeWallet";
            type: "publicKey";
          },
          {
            name: "feePercentage";
            type: "u32";
          },
          {
            name: "adminConfigs";
            type: {
              vec: {
                defined: "AdminConfig";
              };
            };
          },
          {
            name: "fundraiseFeeConfigs";
            type: {
              vec: {
                defined: "FundraiseFeeConfig";
              };
            };
          },
          {
            name: "otcFeeConfigs";
            type: {
              vec: {
                defined: "OtcFeeConfig";
              };
            };
          }
        ];
      };
    },
    {
      name: "clubVault";
      type: {
        kind: "struct";
        fields: [];
      };
    },
    {
      name: "clubVaultData";
      type: {
        kind: "struct";
        fields: [
          {
            name: "chainId";
            type: "string";
          }
        ];
      };
    },
    {
      name: "withdrawal";
      type: {
        kind: "struct";
        fields: [];
      };
    },
    {
      name: "withdrawalData";
      type: {
        kind: "struct";
        fields: [
          {
            name: "realm";
            type: "publicKey";
          },
          {
            name: "clubData";
            type: "publicKey";
          },
          {
            name: "treasury";
            type: "publicKey";
          },
          {
            name: "proposal";
            type: "publicKey";
          },
          {
            name: "fundraiseNumber";
            type: "u32";
          },
          {
            name: "withdrawalAmount";
            type: "u64";
          },
          {
            name: "amountWithdrawn";
            type: "u64";
          },
          {
            name: "totalFinancialPower";
            type: "u64";
          },
          {
            name: "withdrawalMint";
            type: "publicKey";
          },
          {
            name: "proposalCreatedAt";
            type: "i64";
          }
        ];
      };
    },
    {
      name: "fundraiseConfig";
      type: {
        kind: "struct";
        fields: [
          {
            name: "treasury";
            type: "publicKey";
          },
          {
            name: "capAmount";
            type: "u64";
          },
          {
            name: "raisedAmount";
            type: "u64";
          },
          {
            name: "isActive";
            type: "bool";
          },
          {
            name: "fundraiseIndex";
            type: "u32";
          },
          {
            name: "allocation";
            type: {
              option: {
                defined: "Allocation";
              };
            };
          }
        ];
      };
    },
    {
      name: "financialRecord";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "treasury";
            type: "publicKey";
          },
          {
            name: "sellOffersCount";
            type: "u32";
          },
          {
            name: "listedFinancialRights";
            type: "u64";
          },
          {
            name: "depositRecords";
            type: {
              vec: {
                defined: "DepositRecord";
              };
            };
          },
          {
            name: "treasuryRole";
            type: "string";
          },
          {
            name: "createdAt";
            type: "i64";
          },
          {
            name: "createdAtSlot";
            type: "u64";
          },
          {
            name: "tokenOwnerRecord";
            type: "publicKey";
          }
        ];
      };
    },
    {
      name: "financialRecordOffer";
      type: {
        kind: "struct";
        fields: [
          {
            name: "treasury";
            type: "publicKey";
          },
          {
            name: "financialRecord";
            type: "publicKey";
          },
          {
            name: "amountOfRights";
            type: "u64";
          },
          {
            name: "price";
            type: "u64";
          },
          {
            name: "seller";
            type: "publicKey";
          },
          {
            name: "wantedTokenMint";
            type: {
              option: "publicKey";
            };
          },
          {
            name: "buyer";
            type: {
              option: "publicKey";
            };
          },
          {
            name: "financialIndex";
            type: "u32";
          },
          {
            name: "initialAmountOfRights";
            type: "u64";
          }
        ];
      };
    },
    {
      name: "maxVoterWeightRecord";
      type: {
        kind: "struct";
        fields: [
          {
            name: "realm";
            type: "publicKey";
          },
          {
            name: "governingTokenMint";
            type: "publicKey";
          },
          {
            name: "maxVoterWeight";
            type: "u64";
          },
          {
            name: "maxVoterWeightExpiry";
            type: {
              option: "u64";
            };
          },
          {
            name: "reserved";
            type: {
              array: ["u8", 8];
            };
          }
        ];
      };
    },
    {
      name: "voterWeightRecord";
      type: {
        kind: "struct";
        fields: [
          {
            name: "realm";
            type: "publicKey";
          },
          {
            name: "governingTokenMint";
            type: "publicKey";
          },
          {
            name: "governingTokenOwner";
            type: "publicKey";
          },
          {
            name: "voterWeight";
            type: "u64";
          },
          {
            name: "voterWeightExpiry";
            type: {
              option: "u64";
            };
          },
          {
            name: "weightAction";
            type: {
              option: {
                defined: "VoterWeightAction";
              };
            };
          },
          {
            name: "weightActionTarget";
            type: {
              option: "publicKey";
            };
          },
          {
            name: "reserved";
            type: {
              array: ["u8", 8];
            };
          }
        ];
      };
    },
    {
      name: "allowedMemberData";
      type: {
        kind: "struct";
        fields: [
          {
            name: "clubData";
            type: "publicKey";
          },
          {
            name: "memberPubkey";
            type: "publicKey";
          },
          {
            name: "isMember";
            type: "bool";
          },
          {
            name: "status";
            type: {
              defined: "MemberStatus";
            };
          },
          {
            name: "role";
            type: "string";
          },
          {
            name: "joinedAt";
            type: "i64";
          },
          {
            name: "joinedAtSlot";
            type: "u64";
          }
        ];
      };
    },
    {
      name: "nftVoteRecord";
      type: {
        kind: "struct";
        fields: [
          {
            name: "proposal";
            type: "publicKey";
          },
          {
            name: "nftMint";
            type: "publicKey";
          },
          {
            name: "governingTokenOwner";
            type: "publicKey";
          }
        ];
      };
    },
    {
      name: "proposalMetadata";
      type: {
        kind: "struct";
        fields: [
          {
            name: "name";
            type: "string";
          },
          {
            name: "options";
            type: {
              vec: "string";
            };
          },
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "discussionLink";
            type: "string";
          },
          {
            name: "treasury";
            type: "publicKey";
          },
          {
            name: "currentFundraiseIndex";
            type: "u32";
          },
          {
            name: "totalFinancialPower";
            type: "u64";
          },
          {
            name: "proposal";
            type: "publicKey";
          },
          {
            name: "proposalIndex";
            type: "u32";
          },
          {
            name: "realm";
            type: "publicKey";
          },
          {
            name: "clubData";
            type: "publicKey";
          },
          {
            name: "governance";
            type: "publicKey";
          },
          {
            name: "isCreated";
            type: "bool";
          },
          {
            name: "description";
            type: "string";
          },
          {
            name: "authorityRole";
            type: "string";
          },
          {
            name: "dataPda";
            type: {
              option: "publicKey";
            };
          },
          {
            name: "proposalType";
            type: {
              defined: "ProposalType";
            };
          },
          {
            name: "proposalStatus";
            type: {
              defined: "ProposalStatus";
            };
          },
          {
            name: "approvalQuorumPercentage";
            type: "u8";
          },
          {
            name: "maxVoterWeight";
            type: {
              option: "u64";
            };
          },
          {
            name: "createdAt";
            type: "i64";
          }
        ];
      };
    },
    {
      name: "stakeConfig";
      type: {
        kind: "struct";
        fields: [
          {
            name: "clubData";
            type: "publicKey";
          },
          {
            name: "capAmount";
            type: "u64";
          },
          {
            name: "raisedAmount";
            type: "u64";
          },
          {
            name: "stakePeriodType";
            type: {
              defined: "StakePeriodType";
            };
          },
          {
            name: "stakePeriodEnd";
            type: {
              option: "i64";
            };
          },
          {
            name: "name";
            type: "string";
          },
          {
            name: "rewardPercentage";
            type: "u32";
          },
          {
            name: "status";
            type: {
              defined: "StakeStatus";
            };
          },
          {
            name: "nftStakeRecord";
            type: {
              option: {
                defined: "NftStakeRecord";
              };
            };
          },
          {
            name: "stakeIndex";
            type: "u32";
          }
        ];
      };
    },
    {
      name: "stakeRecord";
      type: {
        kind: "struct";
        fields: [
          {
            name: "stakeOwner";
            type: "publicKey";
          },
          {
            name: "amount";
            type: "u64";
          },
          {
            name: "hasClaimed";
            type: "bool";
          },
          {
            name: "claimAmount";
            type: "u64";
          },
          {
            name: "hasUnstaked";
            type: "bool";
          },
          {
            name: "unstakePeriodEnd";
            type: {
              option: "i64";
            };
          },
          {
            name: "stakeConfig";
            type: "publicKey";
          }
        ];
      };
    },
    {
      name: "treasuryData";
      type: {
        kind: "struct";
        fields: [
          {
            name: "clubData";
            docs: ["Club the treasury belongs to"];
            type: "publicKey";
          },
          {
            name: "realm";
            docs: ["Realm used to derive governances for this treasury"];
            type: "publicKey";
          },
          {
            name: "fundraiseCount";
            docs: ["The total number of fundraises within a treasury"];
            type: "u32";
          },
          {
            name: "treasury";
            docs: [
              "The address of the treasury that this TreasuryData expands upon"
            ];
            type: "publicKey";
          },
          {
            name: "hasActiveFundraise";
            docs: [
              "Flag for active fundraises used to permit or forbid actions"
            ];
            type: "bool";
          },
          {
            name: "governance";
            docs: ["The default/mandatory governance of the treasury"];
            type: "publicKey";
          },
          {
            name: "maxVoterWeight";
            docs: ["max_voter_weight"];
            type: "u64";
          },
          {
            name: "totalFinancialPower";
            docs: [
              "The total financial power within the treasury; Also max_voter_weight for Token clubs"
            ];
            type: "u64";
          },
          {
            name: "denominatedCurrency";
            docs: ["Mint of the Token used to determine financial rights"];
            type: {
              option: "publicKey";
            };
          },
          {
            name: "financialTokenAccount";
            docs: [
              "Token Account holding tokens that determine financial rights"
            ];
            type: {
              option: "publicKey";
            };
          },
          {
            name: "sellPermission";
            docs: [
              "SellPermission Data use for additional governance configurations",
              "TODO: Change this to vec"
            ];
            type: {
              array: [
                {
                  defined: "SellPermission";
                },
                10
              ];
            };
          },
          {
            name: "withdrawalGovernance";
            docs: ["An optional withdrawal governance account"];
            type: {
              option: "publicKey";
            };
          },
          {
            name: "financialRecordCount";
            docs: ["Number of member who supported the treasury"];
            type: "u32";
          },
          {
            name: "treasuryIndex";
            docs: ["The index of the treasury"];
            type: "u32";
          },
          {
            name: "financialOffersCount";
            docs: ["Number of active financial offers"];
            type: "u32";
          },
          {
            name: "transferGovernance";
            docs: ["Optional Governance account used for transfer proposals"];
            type: {
              option: "publicKey";
            };
          },
          {
            name: "changeClubConfigGovernance";
            docs: [
              "Optional Governance account used for governance config changes proposals"
            ];
            type: {
              option: "publicKey";
            };
          },
          {
            name: "hasActiveUpdateGovernanceConfig";
            docs: [
              "Flag for active update governance config changes proposals"
            ];
            type: "bool";
          },
          {
            name: "reservedRights";
            docs: [
              "Optional reserved rights parameter storing ownership over tresury by specific members"
            ];
            type: {
              option: {
                defined: "ReservedRights";
              };
            };
          },
          {
            name: "treasuryRoleConfig";
            docs: ["Treasury roles"];
            type: {
              vec: {
                defined: "TreasuryRoleConfig";
              };
            };
          },
          {
            name: "defaultTreasuryRole";
            docs: ["Default treasury role for assigning"];
            type: "string";
          },
          {
            name: "name";
            docs: ["Name of the treasury"];
            type: "string";
          }
        ];
      };
    },
    {
      name: "universeMetadata";
      docs: ["8 + 32 + 32 + 32 + 8 + 4 + 1 + 1 + 8"];
      type: {
        kind: "struct";
        fields: [
          {
            name: "discriminator";
            type: {
              array: ["u8", 8];
            };
          },
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "candyMachine";
            type: "publicKey";
          },
          {
            name: "mint";
            type: "publicKey";
          },
          {
            name: "mintingEpoch";
            type: "u64";
          },
          {
            name: "currentUniverseLevel";
            type: "u32";
          },
          {
            name: "status";
            type: "u8";
          },
          {
            name: "rarity";
            type: {
              defined: "Rarity";
            };
          },
          {
            name: "evolutionPoints";
            type: "u64";
          }
        ];
      };
    }
  ];
  types: [
    {
      name: "RoleConfig";
      type: {
        kind: "struct";
        fields: [
          {
            name: "name";
            type: "string";
          },
          {
            name: "clubActions";
            type: {
              vec: {
                defined: "ClubAction";
              };
            };
          },
          {
            name: "membersCount";
            type: "u32";
          }
        ];
      };
    },
    {
      name: "KycConfig";
      type: {
        kind: "struct";
        fields: [
          {
            name: "required";
            type: "bool";
          },
          {
            name: "location";
            type: {
              defined: "KycLocation";
            };
          }
        ];
      };
    },
    {
      name: "RolesDto";
      type: {
        kind: "struct";
        fields: [
          {
            name: "name";
            type: "string";
          },
          {
            name: "roleWeight";
            type: "u64";
          },
          {
            name: "clubActions";
            type: {
              vec: {
                defined: "ClubAction";
              };
            };
          }
        ];
      };
    },
    {
      name: "UpdateRoleWeight";
      type: {
        kind: "struct";
        fields: [
          {
            name: "role";
            type: "string";
          },
          {
            name: "currentWeight";
            type: "u64";
          },
          {
            name: "updateWeight";
            type: "u64";
          }
        ];
      };
    },
    {
      name: "AdminConfig";
      type: {
        kind: "struct";
        fields: [
          {
            name: "admin";
            type: "publicKey";
          },
          {
            name: "permissions";
            type: {
              vec: {
                defined: "AdminPermission";
              };
            };
          },
          {
            name: "status";
            type: {
              defined: "AdminStatus";
            };
          }
        ];
      };
    },
    {
      name: "FundraiseFeeConfig";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "feePercentage";
            type: "u32";
          }
        ];
      };
    },
    {
      name: "OtcFeeConfig";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "feePercentage";
            type: "u32";
          }
        ];
      };
    },
    {
      name: "Offer";
      type: {
        kind: "struct";
        fields: [
          {
            name: "maker";
            docs: ["The one who initialized the Offer (treasuy/vault)"];
            type: "publicKey";
          },
          {
            name: "treasury";
            docs: ["Treasury of the club which signed the initialization"];
            type: "publicKey";
          },
          {
            name: "offeredAmount";
            docs: ["Offered amount of tokens"];
            type: "u64";
          },
          {
            name: "offeredTokenMint";
            docs: ["Mint of the offer tokens"];
            type: "publicKey";
          },
          {
            name: "wantedAmount";
            docs: [
              "Amount of tokens the maker wants in return for offered ones"
            ];
            type: "u64";
          },
          {
            name: "wantedTokenMint";
            docs: ["Mint of the wanted tokens"];
            type: "publicKey";
          },
          {
            name: "tokenLedgerMint";
            docs: [
              "Mint of the NFT used to create TokenLedger when bought for the first time"
            ];
            type: "publicKey";
          },
          {
            name: "makerWantedToken";
            docs: [
              "Token account of the club to which the assest should be transfered after distribution"
            ];
            type: "publicKey";
          },
          {
            name: "escrowedWantedToken";
            docs: [
              "Token account to which the tokens will be deposited once the Taker accepts the offer"
            ];
            type: "publicKey";
          },
          {
            name: "status";
            docs: [
              "Current status of the offer: Initialised, Accepted, Canceled"
            ];
            type: {
              defined: "OfferStatus";
            };
          },
          {
            name: "offerType";
            docs: ["Buy/Sell NFT"];
            type: {
              defined: "OfferType";
            };
          },
          {
            name: "dedicatedTaker";
            docs: ["Optional dedicated Taker"];
            type: {
              option: "publicKey";
            };
          },
          {
            name: "proposal";
            docs: ["Proposal the offer is derived from"];
            type: "publicKey";
          },
          {
            name: "sellerFeeBps";
            docs: ["Fee"];
            type: "u16";
          }
        ];
      };
    },
    {
      name: "Allocation";
      type: {
        kind: "struct";
        fields: [
          {
            name: "equal";
            type: {
              option: "u64";
            };
          },
          {
            name: "custom";
            type: {
              option: {
                vec: {
                  defined: "CustomAllocation";
                };
              };
            };
          }
        ];
      };
    },
    {
      name: "CustomAllocation";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "amount";
            type: "u64";
          }
        ];
      };
    },
    {
      name: "DepositRecord";
      type: {
        kind: "struct";
        fields: [
          {
            name: "accumulatedAmount";
            type: "u64";
          },
          {
            name: "fundraiseIndex";
            type: "u32";
          },
          {
            name: "depositedAt";
            type: "i64";
          },
          {
            name: "currentFundraiseDeposit";
            type: "u64";
          },
          {
            name: "tradedRight";
            type: {
              option: {
                vec: {
                  defined: "TradedRight";
                };
              };
            };
          }
        ];
      };
    },
    {
      name: "TradedRight";
      type: {
        kind: "struct";
        fields: [
          {
            name: "amount";
            type: "u64";
          },
          {
            name: "timestamp";
            type: "i64";
          },
          {
            name: "tradeType";
            type: {
              defined: "TradedRightType";
            };
          }
        ];
      };
    },
    {
      name: "GovernanceDto";
      type: {
        kind: "struct";
        fields: [
          {
            name: "governanceType";
            type: {
              defined: "GovernanceType";
            };
          },
          {
            name: "maxVotingTime";
            type: "u32";
          },
          {
            name: "voteThresholdPercentage";
            type: "u8";
          },
          {
            name: "sellPermission";
            type: {
              option: {
                defined: "SellPermission";
              };
            };
          }
        ];
      };
    },
    {
      name: "UpdateGovernanceConfigInput";
      type: {
        kind: "struct";
        fields: [
          {
            name: "newQuorums";
            type: "bytes";
          },
          {
            name: "seeds";
            type: {
              vec: {
                vec: "bytes";
              };
            };
          },
          {
            name: "newVotingTime";
            type: {
              option: "u32";
            };
          }
        ];
      };
    },
    {
      name: "GovernanceConfig";
      type: {
        kind: "struct";
        fields: [
          {
            name: "voteThresholdPercentage";
            docs: [
              "The type of the vote threshold used for voting",
              "Note: In the current version only YesVote threshold is supported"
            ];
            type: {
              defined: "VoteThresholdPercentage";
            };
          },
          {
            name: "minCommunityWeightToCreateProposal";
            docs: [
              "Minimum community weight a governance token owner must possess to be able to create a proposal"
            ];
            type: "u64";
          },
          {
            name: "minTransactionHoldUpTime";
            docs: [
              "Minimum waiting time in seconds for a transaction to be executed after proposal is voted on"
            ];
            type: "u32";
          },
          {
            name: "maxVotingTime";
            docs: ["Time limit in seconds for proposal to be open for voting"];
            type: "u32";
          },
          {
            name: "voteTipping";
            docs: ["Conditions under which a vote will complete early"];
            type: {
              defined: "VoteTipping";
            };
          },
          {
            name: "proposalCoolOffTime";
            docs: [
              "The time period in seconds within which a Proposal can be still cancelled after being voted on",
              "Once cool off time expires Proposal can't be cancelled any longer and becomes a law",
              "Note: This field is not implemented in the current version"
            ];
            type: "u32";
          },
          {
            name: "minCouncilWeightToCreateProposal";
            docs: [
              "Minimum council weight a governance token owner must possess to be able to create a proposal"
            ];
            type: "u64";
          }
        ];
      };
    },
    {
      name: "UpdateMemberDto";
      type: {
        kind: "struct";
        fields: [
          {
            name: "isMember";
            type: {
              option: "bool";
            };
          },
          {
            name: "status";
            type: {
              option: {
                defined: "MemberStatus";
              };
            };
          },
          {
            name: "role";
            type: {
              option: "string";
            };
          }
        ];
      };
    },
    {
      name: "NftStakeRecord";
      type: {
        kind: "struct";
        fields: [
          {
            name: "nftOwner";
            type: "publicKey";
          },
          {
            name: "tokenAccount";
            type: "publicKey";
          },
          {
            name: "tokenMint";
            type: "publicKey";
          },
          {
            name: "stakedNft";
            type: "publicKey";
          },
          {
            name: "rarity";
            type: {
              defined: "Rarity";
            };
          },
          {
            name: "hasClaimed";
            type: "bool";
          },
          {
            name: "unstakePeriodEnd";
            type: {
              option: "i64";
            };
          }
        ];
      };
    },
    {
      name: "ReservedRights";
      type: {
        kind: "struct";
        fields: [
          {
            name: "totalReservedRights";
            type: "u64";
          },
          {
            name: "totalReservedPercentage";
            type: "u32";
          },
          {
            name: "individualRights";
            type: {
              vec: {
                defined: "IndividualRight";
              };
            };
          }
        ];
      };
    },
    {
      name: "IndividualRight";
      type: {
        kind: "struct";
        fields: [
          {
            name: "memberPubkey";
            type: "publicKey";
          },
          {
            name: "rightPercentage";
            type: "u32";
          },
          {
            name: "amountOfRights";
            type: "u64";
          }
        ];
      };
    },
    {
      name: "SellPermission";
      type: {
        kind: "struct";
        fields: [
          {
            name: "from";
            type: "u64";
          },
          {
            name: "to";
            type: "u64";
          },
          {
            name: "quorumMinimum";
            type: "u8";
          },
          {
            name: "decimal";
            type: "u16";
          },
          {
            name: "governance";
            type: "publicKey";
          }
        ];
      };
    },
    {
      name: "SellPermissionDto";
      type: {
        kind: "struct";
        fields: [
          {
            name: "from";
            type: "u64";
          },
          {
            name: "to";
            type: "u64";
          },
          {
            name: "quorumMinimum";
            type: "u8";
          },
          {
            name: "decimal";
            type: "u16";
          }
        ];
      };
    },
    {
      name: "AddSellPermissionData";
      type: {
        kind: "struct";
        fields: [
          {
            name: "sellPermission";
            type: {
              defined: "SellPermissionDto";
            };
          },
          {
            name: "maxVotingTime";
            type: "u32";
          }
        ];
      };
    },
    {
      name: "TreasuryRoleConfig";
      type: {
        kind: "struct";
        fields: [
          {
            name: "name";
            type: "string";
          },
          {
            name: "roleWeight";
            type: "u64";
          },
          {
            name: "treasuryActions";
            type: {
              vec: {
                defined: "TreasuryAction";
              };
            };
          },
          {
            name: "membersCount";
            type: "u32";
          },
          {
            name: "isDefault";
            type: "bool";
          }
        ];
      };
    },
    {
      name: "TreasuryRolesDto";
      type: {
        kind: "struct";
        fields: [
          {
            name: "name";
            type: "string";
          },
          {
            name: "roleWeight";
            type: "u64";
          },
          {
            name: "treasuryActions";
            type: {
              vec: {
                defined: "TreasuryAction";
              };
            };
          },
          {
            name: "isDefault";
            type: "bool";
          }
        ];
      };
    },
    {
      name: "SetGovCfgDto";
      type: {
        kind: "struct";
        fields: [
          {
            name: "config";
            type: {
              vec: {
                defined: "GovernanceConfig";
              };
            };
          },
          {
            name: "seeds";
            type: {
              vec: {
                vec: "bytes";
              };
            };
          }
        ];
      };
    },
    {
      name: "KycError";
      type: {
        kind: "enum";
        variants: [
          {
            name: "KycError";
          },
          {
            name: "IncorrectGatekeeper";
          },
          {
            name: "InvalidOwner";
          },
          {
            name: "InvalidToken";
          },
          {
            name: "InvalidSessionToken";
          },
          {
            name: "TokenRevoked";
          },
          {
            name: "ExpectedRevokedToken";
          },
          {
            name: "InvalidStateChange";
          },
          {
            name: "IncorrectProgramId";
          },
          {
            name: "TokenExpired";
          },
          {
            name: "InvalidGatekeeperAccount";
          }
        ];
      };
    },
    {
      name: "KycLocation";
      type: {
        kind: "enum";
        variants: [
          {
            name: "All";
          },
          {
            name: "OnlyUS";
          },
          {
            name: "NonUS";
          }
        ];
      };
    },
    {
      name: "ClubType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "TokenProportional";
          },
          {
            name: "TokenEqual";
          },
          {
            name: "RoleBased";
          },
          {
            name: "NftBased";
          },
          {
            name: "Syndicate";
          }
        ];
      };
    },
    {
      name: "ClubVoterWeightAction";
      type: {
        kind: "enum";
        variants: [
          {
            name: "CastVote";
          },
          {
            name: "CommentProposal";
          },
          {
            name: "CreateGovernance";
          },
          {
            name: "CreateProposal";
          },
          {
            name: "SignOffProposal";
          },
          {
            name: "CancelProposal";
          }
        ];
      };
    },
    {
      name: "UpdateMembersForRole";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Add";
          },
          {
            name: "Remove";
          }
        ];
      };
    },
    {
      name: "ClubAction";
      type: {
        kind: "enum";
        variants: [
          {
            name: "CreateTreasuryGovernance";
          },
          {
            name: "CreateWithdrawalGovernance";
          },
          {
            name: "CreateTransferGovernance";
          },
          {
            name: "AllowMember";
          },
          {
            name: "CancelInvitation";
          },
          {
            name: "SupportClub";
          },
          {
            name: "UpdateMember";
          },
          {
            name: "InitializeStaking";
          },
          {
            name: "StakeTokens";
          },
          {
            name: "AcceptFinancialOffer";
          },
          {
            name: "CreateChangeClubConfigGovernance";
          },
          {
            name: "CanLeave";
          }
        ];
      };
    },
    {
      name: "SupportType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Deposit";
            fields: ["u64"];
          },
          {
            name: "Withdrawal";
            fields: ["u64"];
          }
        ];
      };
    },
    {
      name: "WhitelistingAction";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Add";
          },
          {
            name: "Remove";
          }
        ];
      };
    },
    {
      name: "AdminPermission";
      type: {
        kind: "enum";
        variants: [
          {
            name: "CreateClub";
          },
          {
            name: "WhitelistMembers";
          }
        ];
      };
    },
    {
      name: "AdminStatus";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Accepted";
          },
          {
            name: "Rejected";
          }
        ];
      };
    },
    {
      name: "OfferStatus";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Initialised";
          },
          {
            name: "Accepted";
          },
          {
            name: "Canceled";
          },
          {
            name: "PendingBuyNowExecutionOnMagicEden";
          },
          {
            name: "ExecutedBuyNowOnMagicEden";
          },
          {
            name: "CanceledOnMagic";
          },
          {
            name: "PendingSellExecutionOnMagicEden";
          },
          {
            name: "ExecutedSellOnMagicEden";
          },
          {
            name: "FundsTransfered";
          },
          {
            name: "ListedOnSolsea";
          },
          {
            name: "ExecutedBuyNowOnSolsea";
          },
          {
            name: "CanceledOnSolsea";
          }
        ];
      };
    },
    {
      name: "OfferType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Buy";
          },
          {
            name: "Sell";
          }
        ];
      };
    },
    {
      name: "InitializeType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "MagicEdenSell";
            fields: ["bytes"];
          },
          {
            name: "MagicEdenBuy";
            fields: [
              {
                vec: "bytes";
              }
            ];
          },
          {
            name: "RegularBuySell";
            fields: [
              "u64",
              "u64",
              {
                option: "publicKey";
              }
            ];
          },
          {
            name: "Solsea";
            fields: ["u64", "u64", "u16"];
          }
        ];
      };
    },
    {
      name: "TradedRightType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Buy";
          },
          {
            name: "Sell";
          },
          {
            name: "BuyReserved";
          },
          {
            name: "SellReserved";
          }
        ];
      };
    },
    {
      name: "UpdateAllocationAction";
      type: {
        kind: "enum";
        variants: [
          {
            name: "UpdateCustomAllocation";
          },
          {
            name: "RemoveCustomAllocation";
          },
          {
            name: "UpdateEqual";
          }
        ];
      };
    },
    {
      name: "FundraiseAction";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Create";
            fields: ["u64"];
          },
          {
            name: "Finish";
          }
        ];
      };
    },
    {
      name: "GovernanceType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Treasury";
          },
          {
            name: "Withdrawal";
          },
          {
            name: "Transfer";
          },
          {
            name: "SellPermission";
          },
          {
            name: "GovernanceChange";
          }
        ];
      };
    },
    {
      name: "UpdateVoterWeightAction";
      type: {
        kind: "enum";
        variants: [
          {
            name: "ClubAction";
            fields: [
              {
                defined: "ClubAction";
              }
            ];
          },
          {
            name: "TreasuryAction";
            fields: [
              {
                defined: "TreasuryAction";
              }
            ];
          }
        ];
      };
    },
    {
      name: "VoteThresholdPercentage";
      type: {
        kind: "enum";
        variants: [
          {
            name: "YesVote";
            fields: ["u8"];
          },
          {
            name: "Quorum";
            fields: ["u8"];
          }
        ];
      };
    },
    {
      name: "VoteTipping";
      docs: [
        "The type of vote tipping to use on a Proposal.",
        "",
        "Vote tipping means that under some conditions voting will complete early."
      ];
      type: {
        kind: "enum";
        variants: [
          {
            name: "Strict";
          },
          {
            name: "Early";
          },
          {
            name: "Disabled";
          }
        ];
      };
    },
    {
      name: "MemberStatus";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Uninvited";
          },
          {
            name: "Pending";
          },
          {
            name: "Accepted";
          },
          {
            name: "Rejected";
          },
          {
            name: "Cancelled";
          }
        ];
      };
    },
    {
      name: "ProposalType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "BuyP2P";
          },
          {
            name: "SellP2P";
          },
          {
            name: "BuyNowMagicEden";
          },
          {
            name: "SellMagicEden";
          },
          {
            name: "TransferFunds";
          },
          {
            name: "Discussion";
          },
          {
            name: "Withdrawal";
          },
          {
            name: "SellSolsea";
          },
          {
            name: "BuySolsea";
          },
          {
            name: "UpdateGovernanceConfig";
          },
          {
            name: "UpdateRoleConfig";
          },
          {
            name: "CreateFundraise";
          },
          {
            name: "AddSellPermission";
          }
        ];
      };
    },
    {
      name: "ProposalStatus";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Pending";
          },
          {
            name: "CreatedBuyP2P";
          },
          {
            name: "CreatedSellP2P";
          },
          {
            name: "ExecutedBuyP2P";
          },
          {
            name: "ExecutedSellP2P";
          },
          {
            name: "CreatedBuyNowMagicEden";
          },
          {
            name: "ExecutedBuyNowMagicEden";
          },
          {
            name: "FinishedBuyNowMagicEden";
          },
          {
            name: "CreatedSellMagicEden";
          },
          {
            name: "ExecutedSellMagicEden";
          },
          {
            name: "FinishedSellMagicEden";
          },
          {
            name: "CreatedTransferFunds";
          },
          {
            name: "ExecutedTransferFunds";
          },
          {
            name: "CreatedDiscussion";
          },
          {
            name: "CreatedWithdrawal";
          },
          {
            name: "ExecutedWithdrawal";
          },
          {
            name: "CancelledByOwner";
          },
          {
            name: "CreatedUpdateGovernanceConfig";
          },
          {
            name: "ExecutedUpdateGovernanceConfig";
          },
          {
            name: "CreatedUpdateRoleConfig";
          },
          {
            name: "ExecutedUpdateRoleConfig";
          },
          {
            name: "ExecutedSolseaBuy";
          },
          {
            name: "ExecutedSolseaSell";
          },
          {
            name: "CreatedMetadata";
          },
          {
            name: "ProposalCreated";
          },
          {
            name: "ProposalPreparing";
          },
          {
            name: "ProposalReady";
          },
          {
            name: "ProposalExecuted";
          }
        ];
      };
    },
    {
      name: "ProposalAction";
      type: {
        kind: "enum";
        variants: [
          {
            name: "BuyNFT";
          },
          {
            name: "SellNFT";
          }
        ];
      };
    },
    {
      name: "ProposalUpdateStatus";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Updating";
          },
          {
            name: "Finished";
          },
          {
            name: "Close";
          }
        ];
      };
    },
    {
      name: "StakePeriodType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "OneMonth";
          },
          {
            name: "ThreeMonths";
          },
          {
            name: "SixMonths";
          },
          {
            name: "TwelveMonths";
          }
        ];
      };
    },
    {
      name: "StakeStatus";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Funding";
          },
          {
            name: "InProgress";
          },
          {
            name: "Finished";
          },
          {
            name: "Canceled";
          }
        ];
      };
    },
    {
      name: "StakeOption";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Ft";
          },
          {
            name: "Nft";
          }
        ];
      };
    },
    {
      name: "UNQNftRarity";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Common";
          },
          {
            name: "Rare";
          },
          {
            name: "Epic";
          },
          {
            name: "Legendary";
          }
        ];
      };
    },
    {
      name: "TreasuryAction";
      type: {
        kind: "enum";
        variants: [
          {
            name: "CastVote";
          },
          {
            name: "CreateDiscussionProposal";
          },
          {
            name: "CreateP2PProposal";
          },
          {
            name: "CreateWithdrawalProposal";
          },
          {
            name: "CreateTransferProposal";
          },
          {
            name: "CreateMeProposal";
          },
          {
            name: "SignOffProposal";
          },
          {
            name: "CancelProposal";
          },
          {
            name: "CancelP2POffer";
          },
          {
            name: "Fundraise";
          },
          {
            name: "Distribute";
          },
          {
            name: "CreateFinancialOffer";
          },
          {
            name: "AcceptFinancialOffer";
          },
          {
            name: "CreateSolseaProposal";
          },
          {
            name: "UpdateGovernanceConfig";
          },
          {
            name: "UpdateRoleConfig";
          },
          {
            name: "AddReservedRights";
          },
          {
            name: "UpdateAllocation";
          },
          {
            name: "AddReservedRightsToSelf";
          }
        ];
      };
    },
    {
      name: "AllowType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Club";
          },
          {
            name: "Treasury";
          }
        ];
      };
    },
    {
      name: "Rarity";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Common";
          },
          {
            name: "Rare";
          },
          {
            name: "Epic";
          },
          {
            name: "Legendary";
          }
        ];
      };
    },
    {
      name: "VoterWeightAction";
      type: {
        kind: "enum";
        variants: [
          {
            name: "CastVote";
          },
          {
            name: "CommentProposal";
          },
          {
            name: "CreateGovernance";
          },
          {
            name: "CreateProposal";
          },
          {
            name: "SignOffProposal";
          }
        ];
      };
    }
  ];
  events: [
    {
      name: "WhitelistingEvent";
      fields: [
        {
          name: "user";
          type: {
            vec: "publicKey";
          };
          index: false;
        },
        {
          name: "newStatus";
          type: {
            defined: "WhitelistingAction";
          };
          index: false;
        }
      ];
    },
    {
      name: "UpdateMemerEvent";
      fields: [
        {
          name: "clubAddress";
          type: "publicKey";
          index: false;
        },
        {
          name: "member";
          type: "publicKey";
          index: false;
        },
        {
          name: "isMember";
          type: {
            option: "bool";
          };
          index: false;
        },
        {
          name: "status";
          type: {
            option: {
              defined: "MemberStatus";
            };
          };
          index: false;
        },
        {
          name: "role";
          type: {
            option: "string";
          };
          index: false;
        },
        {
          name: "hasLeft";
          type: {
            option: "bool";
          };
          index: false;
        }
      ];
    },
    {
      name: "AcceptOfferEvent";
      fields: [
        {
          name: "proposalAddress";
          type: "publicKey";
          index: false;
        },
        {
          name: "offerAccount";
          type: "publicKey";
          index: false;
        }
      ];
    },
    {
      name: "InvitationEvent";
      fields: [
        {
          name: "clubData";
          type: "publicKey";
          index: false;
        },
        {
          name: "invitedMemberDatas";
          type: {
            vec: "publicKey";
          };
          index: false;
        }
      ];
    },
    {
      name: "FundraiseEvent";
      fields: [
        {
          name: "fundraiseConfig";
          type: "publicKey";
          index: false;
        },
        {
          name: "treasuryDataAddress";
          type: "publicKey";
          index: false;
        }
      ];
    },
    {
      name: "TreasuryRoleEvent";
      fields: [
        {
          name: "clubData";
          type: "publicKey";
          index: false;
        },
        {
          name: "invitedMemberDatas";
          type: {
            vec: "publicKey";
          };
          index: false;
        }
      ];
    },
    {
      name: "StakeEvent";
      fields: [
        {
          name: "stakeConfig";
          type: "publicKey";
          index: false;
        },
        {
          name: "stakeRecord";
          type: {
            option: "publicKey";
          };
          index: false;
        },
        {
          name: "clubData";
          type: {
            option: "publicKey";
          };
          index: false;
        }
      ];
    },
    {
      name: "CreateClubEvent";
      fields: [
        {
          name: "clubData";
          type: "publicKey";
          index: false;
        }
      ];
    },
    {
      name: "CreateTreasuryEvent";
      fields: [
        {
          name: "treasuryData";
          type: "publicKey";
          index: false;
        }
      ];
    },
    {
      name: "CreateMemberDataEvent";
      fields: [
        {
          name: "memberData";
          type: {
            vec: "publicKey";
          };
          index: false;
        }
      ];
    },
    {
      name: "AcceptMemberEvent";
      fields: [
        {
          name: "clubData";
          type: "publicKey";
          index: false;
        },
        {
          name: "invitedMemberDatas";
          type: {
            vec: "publicKey";
          };
          index: false;
        }
      ];
    },
    {
      name: "UpdateAllocationEvent";
      fields: [
        {
          name: "fundraiseConfig";
          type: "publicKey";
          index: false;
        },
        {
          name: "treasuryDataAddress";
          type: "publicKey";
          index: false;
        }
      ];
    },
    {
      name: "SupportClubEvent";
      fields: [
        {
          name: "financialRecord";
          type: "publicKey";
          index: false;
        },
        {
          name: "fundraiseConfig";
          type: "publicKey";
          index: false;
        },
        {
          name: "treasuryData";
          type: "publicKey";
          index: false;
        }
      ];
    },
    {
      name: "ReserveRightsEvent";
      fields: [
        {
          name: "treasuryData";
          type: "publicKey";
          index: false;
        }
      ];
    },
    {
      name: "CancelInvitationEvent";
      fields: [
        {
          name: "clubData";
          type: "publicKey";
          index: false;
        },
        {
          name: "invitedMemberDatas";
          type: {
            vec: "publicKey";
          };
          index: false;
        },
        {
          name: "isDecline";
          type: "bool";
          index: false;
        }
      ];
    },
    {
      name: "FinancialOfferEvent";
      fields: [
        {
          name: "treasuryDataAddress";
          type: "publicKey";
          index: false;
        },
        {
          name: "financialRecordAddress";
          type: "publicKey";
          index: false;
        },
        {
          name: "financialOfferAddress";
          type: "publicKey";
          index: false;
        },
        {
          name: "buyerFinancialRecord";
          type: {
            option: "publicKey";
          };
          index: false;
        },
        {
          name: "sellerFinancialRecord";
          type: {
            option: "publicKey";
          };
          index: false;
        },
        {
          name: "amount";
          type: {
            option: "u64";
          };
          index: false;
        },
        {
          name: "clubData";
          type: "publicKey";
          index: false;
        },
        {
          name: "isCancel";
          type: "bool";
          index: false;
        },
        {
          name: "dedicatedBuyer";
          type: {
            option: "publicKey";
          };
          index: false;
        }
      ];
    },
    {
      name: "ProposalEvent";
      fields: [
        {
          name: "proposalMetadata";
          type: "publicKey";
          index: false;
        },
        {
          name: "realmAddress";
          type: "publicKey";
          index: false;
        },
        {
          name: "clubAddress";
          type: "publicKey";
          index: false;
        }
      ];
    },
    {
      name: "CreateGovernanceEvent";
      fields: [
        {
          name: "treasuryData";
          type: "publicKey";
          index: false;
        }
      ];
    },
    {
      name: "DepositWithdrawEvent";
      fields: [
        {
          name: "memberAddress";
          type: "publicKey";
          index: false;
        },
        {
          name: "treasuryAddress";
          type: "publicKey";
          index: false;
        },
        {
          name: "supportType";
          type: {
            defined: "SupportType";
          };
          index: false;
        }
      ];
    },
    {
      name: "ProposalCastVoteEvent";
      fields: [
        {
          name: "proposalMetadata";
          type: "publicKey";
          index: false;
        }
      ];
    },
    {
      name: "ProposalCreateEvent";
      fields: [
        {
          name: "proposalMetadata";
          type: "publicKey";
          index: false;
        }
      ];
    }
  ];
  errors: [
    {
      code: 6000;
      name: "WrongSplGovProgram";
      msg: "Wrong spl gov id!";
    },
    {
      code: 6001;
      name: "WrongEscrowProgram";
      msg: "Wrong escrow id!";
    },
    {
      code: 6002;
      name: "WrongUNQPubKey";
      msg: "Wrong UNQ mint pub key!";
    },
    {
      code: 6003;
      name: "WrongPermissionLength";
      msg: "Wrong length of Permission config!";
    },
    {
      code: 6004;
      name: "NotAMember";
      msg: "You are not allowed to make deposits!";
    },
    {
      code: 6005;
      name: "InvalidMemberStatus";
      msg: "Invalid member status, valid values are: 0,1,2";
    },
    {
      code: 6006;
      name: "NotEnoughFunds";
      msg: "Not enough funds";
    },
    {
      code: 6007;
      name: "InvalidAuthority";
      msg: "Not a valid authority";
    },
    {
      code: 6008;
      name: "InvalidMint";
      msg: "Invalid mint provided";
    },
    {
      code: 6009;
      name: "InvalidPerformanceFeeConfig";
      msg: "Performance fee configuration is invalid!";
    },
    {
      code: 6010;
      name: "TimeInPast";
      msg: "Time in past";
    },
    {
      code: 6011;
      name: "StartAfterTheEndTime";
      msg: "Start after end time";
    },
    {
      code: 6012;
      name: "FundraiseInvalidAmount";
      msg: "Invalid fundraise amount";
    },
    {
      code: 6013;
      name: "FundraiseAmountAlreadyMet";
      msg: "Fundraise amount already met";
    },
    {
      code: 6014;
      name: "FundraiseHasEnded";
      msg: "Fundraise has ended";
    },
    {
      code: 6015;
      name: "FundraiseStillInProgress";
      msg: "Fundraise still in progress";
    },
    {
      code: 6016;
      name: "InvalidClubTypeStatus";
      msg: "Invalid club type, valid values are: 0,1,2,3";
    },
    {
      code: 6017;
      name: "InvalidGovernance";
      msg: "Invalid governance address";
    },
    {
      code: 6018;
      name: "SellPermissionNotSatisfied";
      msg: "Sell permission not satisfied";
    },
    {
      code: 6019;
      name: "InvalidCancelAuthority";
      msg: "You do not have the permission to cancel this invitation";
    },
    {
      code: 6020;
      name: "InvalidTreasury";
      msg: "Invalid treasury address";
    },
    {
      code: 6021;
      name: "InvalidRole";
      msg: "Invalid role to perform this action";
    },
    {
      code: 6022;
      name: "InvalidVoterWeightAction";
      msg: "Invalid voter weight action";
    },
    {
      code: 6023;
      name: "VoterWeightActionNotAllowed";
      msg: "Voter weight action not allowed";
    },
    {
      code: 6024;
      name: "BelowMinimumVoteTreshold";
      msg: "Below minimum vote treshold";
    },
    {
      code: 6025;
      name: "BelowMinimumVoteTime";
      msg: "Below minimum vote time";
    },
    {
      code: 6026;
      name: "MemberKeysMissmatch";
      msg: "Member data PDA sent doesn't match the one generated on Solana";
    },
    {
      code: 6027;
      name: "MemberAlreadyExists";
      msg: "Member data PDA has already been initialized";
    },
    {
      code: 6028;
      name: "InvalidProposalAction";
      msg: "Invalid proposal action";
    },
    {
      code: 6029;
      name: "VoteNotAllowed";
      msg: "Member not allowed to vote on this proposal";
    },
    {
      code: 6030;
      name: "MaxOrProposalRemainingAccountsMissing";
      msg: "Max Voter Weight Record and Proposal need to be passed as remaining accounts to cast a vote";
    },
    {
      code: 6031;
      name: "WrongProposalAddress";
      msg: "Wrong proposal account passed as remaining account";
    },
    {
      code: 6032;
      name: "WrongMaxVoterWeightRecord";
      msg: "Wrong MaxVoterWeightRecord account passed as remaining account";
    },
    {
      code: 6033;
      name: "InvalidVoterWeightProgram";
      msg: "Invalid voter weight program id";
    },
    {
      code: 6034;
      name: "WrongFundraiseConfig";
      msg: "Wrong fundraise config";
    },
    {
      code: 6035;
      name: "FundraiseRemainingAccountMissing";
      msg: "Fundraise config needs to be passed as remaining account";
    },
    {
      code: 6036;
      name: "InvalidAuthorityToCreateGovernance";
      msg: "Invalid authority to create governance: Only owner of the club can create governance!";
    },
    {
      code: 6037;
      name: "GovernanceCreationAfterFundraise";
      msg: "The governance over treasury must be defined before the fundraise";
    },
    {
      code: 6038;
      name: "WrongGovernanceAccount";
      msg: "Wrong or uninitialized governance account provided to create_fundraise ix";
    },
    {
      code: 6039;
      name: "ProposalAlreadyInitialized";
      msg: "Proposal aready initialized";
    },
    {
      code: 6040;
      name: "ProposalMetadataLengthOverflow";
      msg: "Proposal metadata string length overflow";
    },
    {
      code: 6041;
      name: "FundraiseAmountExceeded";
      msg: "Fundraise amount exceeded";
    },
    {
      code: 6042;
      name: "InvalidNftAccount";
      msg: "Invalid NFT account provided";
    },
    {
      code: 6043;
      name: "NftNotOnTheProvidedAccount";
      msg: "Invalid NFT account provided";
    },
    {
      code: 6044;
      name: "CollectionMustBeVerified";
      msg: "Collection must be verified";
    },
    {
      code: 6045;
      name: "CollectionMissing";
      msg: "Collection missing";
    },
    {
      code: 6046;
      name: "InvalidAccountOwner";
      msg: "Invalid account owner";
    },
    {
      code: 6047;
      name: "InvalidTokenMetadataAccount";
      msg: "Invalid token metadata account";
    },
    {
      code: 6048;
      name: "TokenMetadataDoesNotMatch";
      msg: "Token metadata does not match";
    },
    {
      code: 6049;
      name: "OnlyForNftBasedClubs";
      msg: "Only callable for nft based clubs";
    },
    {
      code: 6050;
      name: "InvalidCollection";
      msg: "Invalid collection";
    },
    {
      code: 6051;
      name: "NftAlreadyVoted";
      msg: "Nft already voted on";
    },
    {
      code: 6052;
      name: "MissingRemainingAccounts";
      msg: "Missing remaining accounts";
    },
    {
      code: 6053;
      name: "ProposalMetadataAlreadyInitialized";
      msg: "Proposal metadata aready initialized";
    },
    {
      code: 6054;
      name: "InvalidSellPermissionGovernanceAccount";
      msg: "Invalid sell permission governance account";
    },
    {
      code: 6055;
      name: "SellPermissionAmountNotSatisfied";
      msg: "Sell permission amount not satisfied";
    },
    {
      code: 6056;
      name: "SellPermissionGovernanceOnlyForBuyAction";
      msg: "Sell permission governance only for Buy action";
    },
    {
      code: 6057;
      name: "AccountClosingErr";
      msg: "Couldn't transfer SOLs from profit_token to profit";
    },
    {
      code: 6058;
      name: "WithdrawalGovernanceAlreadyCreated";
      msg: "Withdrawal governance for this club has already been created";
    },
    {
      code: 6059;
      name: "StakingInProgress";
      msg: "Staking already in progress";
    },
    {
      code: 6060;
      name: "InvalidStakeAmount";
      msg: "Invalid stake amount parameter";
    },
    {
      code: 6061;
      name: "InvalidStakeStartDate";
      msg: "Invalid stake start date: Must select current date or future date";
    },
    {
      code: 6062;
      name: "InvalidStakeEndDate";
      msg: "Invalid stake end date: Stake-funding period must last at least one day";
    },
    {
      code: 6063;
      name: "StakeCapOverflow";
      msg: "Stake cap overflow";
    },
    {
      code: 6064;
      name: "NotEnoughUnqTokens";
      msg: "Not Enought UNQ Tokens";
    },
    {
      code: 6065;
      name: "StakingHasntStartedYet";
      msg: "Staking funding period hasn't started yet";
    },
    {
      code: 6066;
      name: "StakingFundingEnded";
      msg: "Staking funding period has ended";
    },
    {
      code: 6067;
      name: "UnstakePeriodHasntEnded";
      msg: "Unstake period is three weeks, and it hasn't ended";
    },
    {
      code: 6068;
      name: "StakingWillBeOver";
      msg: "Staking will be over by the time, unstaking period has ended";
    },
    {
      code: 6069;
      name: "InvalidDepositAmount";
      msg: "Invalid deposit amount";
    },
    {
      code: 6070;
      name: "MaxNumberOfFinancialRecordsExceeded";
      msg: "Cannot support with less than 1 tokens";
    },
    {
      code: 6071;
      name: "MaxNumberOfActiveStakeRecords";
      msg: "Exceeds max number of active stakes";
    },
    {
      code: 6072;
      name: "MaxStakeNameExceeded";
      msg: "Max stake name exceeded";
    },
    {
      code: 6073;
      name: "MaxCapExceeded";
      msg: "Max cap exceeded!";
    },
    {
      code: 6074;
      name: "StakingCanceled";
      msg: "Staking canceled!";
    },
    {
      code: 6075;
      name: "AlreadyUnstaked";
      msg: "Already unstaked!";
    },
    {
      code: 6076;
      name: "CannotClaimBeforeFundingPeriodIsOver";
      msg: "Cannot claim before stake funding period is over!";
    },
    {
      code: 6077;
      name: "StakePeriodHasntEnded";
      msg: "Stake period hasn't ended";
    },
    {
      code: 6078;
      name: "StakeStatusNeedsToBeInProgress";
      msg: "Stake status needs to be InProgress";
    },
    {
      code: 6079;
      name: "WrongRewardTokenAccount";
      msg: "Stake status needs to be InProgress";
    },
    {
      code: 6080;
      name: "NotFundingStatus";
      msg: "StakeConfig must be in Funding status to be changed to InProgress";
    },
    {
      code: 6081;
      name: "SellPermissionConfigFull";
      msg: "Sell permission config full";
    },
    {
      code: 6082;
      name: "WrongProposalUpdateAuthorority";
      msg: "Wrong proposal update authority";
    },
    {
      code: 6083;
      name: "InvalidDepositRecordIndex";
      msg: "The index of DepositRecord seed must be leq than fundraise count at proposal init time";
    },
    {
      code: 6084;
      name: "WrongDepositRecordOwner";
      msg: "The payer of the transaction is not the owner of DepositRecord";
    },
    {
      code: 6085;
      name: "WrongDepositRecordKey";
      msg: "The payer of the transaction is not the owner of DepositRecord";
    },
    {
      code: 6086;
      name: "InvalidMaxAmountPerFundraise";
      msg: "The maximum amount of SOLs per fundraise is 200";
    },
    {
      code: 6087;
      name: "MaxNumberOfMembersExceeded";
      msg: "The maximum amount of members per club is 99";
    },
    {
      code: 6088;
      name: "InvalidMinAmountPerFundraise";
      msg: "The minimum amount of SOLs per fundraise is 200";
    },
    {
      code: 6089;
      name: "WrongOwnerOfTokenAccount";
      msg: "Wrong owner of NFT Token Account";
    },
    {
      code: 6090;
      name: "SpaceAlreadyAllocated";
      msg: "Space already reallocated";
    },
    {
      code: 6091;
      name: "WrongAmountOnTheNftAccount";
      msg: "The amount of token on the NFT Token Account must be 1";
    },
    {
      code: 6092;
      name: "WrongNftMintForStaking";
      msg: "Mint account doesn't match the token account provided for staking";
    },
    {
      code: 6093;
      name: "WrongUniverseMetadataForMint";
      msg: "UniverseMetadata doesn't match the provided mint";
    },
    {
      code: 6094;
      name: "UniverseLevelLow";
      msg: "Universe level too low to stake";
    },
    {
      code: 6095;
      name: "NftStakeAccountInitialized";
      msg: "The PDA of the TokenAccount storing NFT is already initialized";
    },
    {
      code: 6096;
      name: "WrongStakedNftAddress";
      msg: "The PDA of the TokenAccount storing NFT is wrong";
    },
    {
      code: 6097;
      name: "LowerRarity";
      msg: "Universe NFT must be of higher rarity than the one already staked";
    },
    {
      code: 6098;
      name: "NftClaimed";
      msg: "This NFT was already claimed";
    },
    {
      code: 6099;
      name: "WrongStakedTokensAddress";
      msg: "The PDA of the TokenAccount storing NFT is wrong";
    },
    {
      code: 6100;
      name: "CannotTransferOldStakedNft";
      msg: "Failed to transfer old NFT to its owner";
    },
    {
      code: 6101;
      name: "WrongNftReceiverTokenAccount";
      msg: "Wrong NFT receiver TokenAccount passed";
    },
    {
      code: 6102;
      name: "WrongOwnerOfStakedNft";
      msg: "Wrong owner of the staked NFT";
    },
    {
      code: 6103;
      name: "AlreadyClaimed";
      msg: "Already claimed your tokens";
    },
    {
      code: 6104;
      name: "WrongStakeRecordOwner";
      msg: "Wrong stake record owner";
    },
    {
      code: 6105;
      name: "InvalidFinancialRecordAmount";
      msg: "Invalid financial record amount";
    },
    {
      code: 6106;
      name: "WrongCancelFinancialOfferAuthority";
      msg: "Signer has no authority to cancel this financial offer";
    },
    {
      code: 6107;
      name: "WrongFinancialBuyerTokenMint";
      msg: "Wrong financial buyer token mint";
    },
    {
      code: 6108;
      name: "NotEnoughTokensToAcceptFinancialOffer";
      msg: "Not enough tokens to accept financial offer";
    },
    {
      code: 6109;
      name: "CannotCreateFinancialOffer";
      msg: "Cannot create financial offer while a fundraise is active";
    },
    {
      code: 6110;
      name: "NotDesignatedBuyerForFinancialOffer";
      msg: "You are not the designated buyer for this financial offer";
    },
    {
      code: 6111;
      name: "FinancialOffersNotZero";
      msg: "There cannot be any active financial offers when starting a fundraise";
    },
    {
      code: 6112;
      name: "WrongMagicEdenAccountsHolder";
      msg: "Wrong accounts holder for BuyNow on MagicEden";
    },
    {
      code: 6113;
      name: "NoOfferAccountForMagicEden";
      msg: "Wrong PDA passed as offer address for MagicEden BuyNow";
    },
    {
      code: 6114;
      name: "NoWantedTokenMintForMagicEdenBuyNow";
      msg: "Wrong wanted_token_mint passed as remaining account passed as remaining account";
    },
    {
      code: 6115;
      name: "NoEscrowedWantedTokenAccountForMagicEden";
      msg: "Wrong escrowed_wanted_token account passed as remaining account for BuyNow";
    },
    {
      code: 6116;
      name: "NoOfferedTokenMintForMagicEden";
      msg: "No offered_token_mint passed as remaining account";
    },
    {
      code: 6117;
      name: "NoMakerWantedTokenForMagicEden";
      msg: "No maker_wanted_token passed as remaining account";
    },
    {
      code: 6118;
      name: "NoTokenProgramForMagicEden";
      msg: "No token_program passed as remaining account";
    },
    {
      code: 6119;
      name: "InvalidFinancialRightsAmount";
      msg: "Invalid amount of financial rights to buy";
    },
    {
      code: 6120;
      name: "FinancialRightsAmountExceeded";
      msg: "You do not have enough financial rights";
    },
    {
      code: 6121;
      name: "WrongMeBuyNowMaker";
      msg: "This is not the treasury that made this offer";
    },
    {
      code: 6122;
      name: "WrongMeSellMaker";
      msg: "This is not the vault that made this offer";
    },
    {
      code: 6123;
      name: "ProposalToBuyNowNotExecuted";
      msg: "Proposal to buy on magic eden needs to be executed";
    },
    {
      code: 6124;
      name: "ProposalToSellNotExecuted";
      msg: "Proposal to sell on magic eden needs to be executed";
    },
    {
      code: 6125;
      name: "FailedToParseDenominatedCurrencyAsMint";
      msg: "Wrong denominated currency token account mint";
    },
    {
      code: 6126;
      name: "WrongDenominatedCurrencyTokenAccountMint";
      msg: "Wrong denominated currency token account mint";
    },
    {
      code: 6127;
      name: "WrongDenominatedTokenAddress";
      msg: "Wrong denominated token address";
    },
    {
      code: 6128;
      name: "FailedToSupportClub";
      msg: "Failed to support club";
    },
    {
      code: 6129;
      name: "TransferGovernanceAlreadyCreated";
      msg: "Transfer governance for this club has already been created";
    },
    {
      code: 6130;
      name: "InvalidAuthorityToInviteMember";
      msg: "Not a valid authority to invite a member";
    },
    {
      code: 6131;
      name: "InvalidAuthorityToCancelOffer";
      msg: "Invalid authority to cancel P2P offer";
    },
    {
      code: 6132;
      name: "InvalidAuthorityToCreateFundraise";
      msg: "Invalid authority to create fundraise";
    },
    {
      code: 6133;
      name: "InvalidRoleToSupport";
      msg: "Invalid role to perform the support club action";
    },
    {
      code: 6134;
      name: "InvalidAuthorityToFinishStaking";
      msg: "Invalid role to finish staking";
    },
    {
      code: 6135;
      name: "InvalidAuthorityToInitializeStaking";
      msg: "Invalid role to initialize staking";
    },
    {
      code: 6136;
      name: "InvalidAuthorityToUpdateAllocation";
      msg: "Invalid authority to update allocation";
    },
    {
      code: 6137;
      name: "InvalidClubType";
      msg: "Club type not implemented";
    },
    {
      code: 6138;
      name: "InvalidMintOfNftToBuy";
      msg: "The mint of the NFT that the offer was made for is different";
    },
    {
      code: 6139;
      name: "WrongAmountOfOfferedToken";
      msg: "Wrong amount of offered token";
    },
    {
      code: 6140;
      name: "CanNotUpdateGovernanceConfig";
      msg: "Can not update governance with proposals in voting state";
    },
    {
      code: 6141;
      name: "UpdateGovernanceProposalActive";
      msg: "Update governance proposal active";
    },
    {
      code: 6142;
      name: "InvalidProposalState";
      msg: "Invalid proposal state";
    },
    {
      code: 6143;
      name: "ChangeClubConfigGovernanceAlredyCreated";
      msg: "Change club config governance alredy created";
    },
    {
      code: 6144;
      name: "InvalidAuthorityToStartStaking";
      msg: "Invalid role to start staking";
    },
    {
      code: 6145;
      name: "InvalidDepositRecordState";
      msg: "Invalid DepositRecord State";
    },
    {
      code: 6146;
      name: "ReservedRightsCannotBeOverridden";
      msg: "Reserved rights cannot be overridden";
    },
    {
      code: 6147;
      name: "ReservedRightsCannotSetAfterFundraise";
      msg: "Reserved rights cannot be set after fundraise";
    },
    {
      code: 6148;
      name: "WrongTreasuryForClub";
      msg: "Wrong treasury for club";
    },
    {
      code: 6149;
      name: "NotUniqueIndividualRight";
      msg: "Each pubkey for which individual rights is reserved must be unique";
    },
    {
      code: 6150;
      name: "IndividualRightNotExists";
      msg: "Individual right for authority does not exist";
    },
    {
      code: 6151;
      name: "NotEnoughVoteRights";
      msg: "Not enought vote rights to vote on this proposal";
    },
    {
      code: 6152;
      name: "CannotAddAndRemoveAllocation";
      msg: "You cannot add and remove allocation at the same time";
    },
    {
      code: 6153;
      name: "InvalidFundraiseCapAmount";
      msg: "Invalid fundraise cap amount";
    },
    {
      code: 6154;
      name: "WrongGovernanceForProposalType";
      msg: "The passed governance doesn't match the proposal type";
    },
    {
      code: 6155;
      name: "WrongProposalStatus";
      msg: "Proposal status error";
    },
    {
      code: 6156;
      name: "WrongOfferStatus";
      msg: "Offer status error";
    },
    {
      code: 6157;
      name: "NoActiveProposalsForGovernanceAllowed";
      msg: "No active proposals for governance allowed";
    },
    {
      code: 6158;
      name: "InvalidRealmForClub";
      msg: "Realm isn't valid for club";
    },
    {
      code: 6159;
      name: "NoActiveProposalsAllowed";
      msg: "There should be no active proposals";
    },
    {
      code: 6160;
      name: "WrongProposalTypeForInstruction";
      msg: "Cannot insert that instruction in this type of proposal";
    },
    {
      code: 6161;
      name: "VotingProposalExists";
      msg: "Voting proposals exist in this club";
    },
    {
      code: 6162;
      name: "InvalidFinancialRecord";
      msg: "Invalid financial record";
    },
    {
      code: 6163;
      name: "InvalidTreasuryCount";
      msg: "Invalid treasury count";
    },
    {
      code: 6164;
      name: "InvalidFinancialPower";
      msg: "Invalid financial power";
    },
    {
      code: 6165;
      name: "InvalidWithdrawal";
      msg: "Invalid withdraw";
    },
    {
      code: 6166;
      name: "InvalidWithdrawalAmount";
      msg: "Invalid withdraw amount";
    },
    {
      code: 6167;
      name: "InvalidTokenOwnerRecord";
      msg: "Invalid token owner record provided";
    },
    {
      code: 6168;
      name: "InvalidClubName";
      msg: "Invalid club name";
    },
    {
      code: 6169;
      name: "WrongCommunityWeightAddinProgram";
      msg: "Community weight addin program is wrong";
    },
    {
      code: 6170;
      name: "InvalidPercentage";
      msg: "Invalid percentage amount";
    },
    {
      code: 6171;
      name: "MemberNotWhitelisted";
      msg: "Member not added to whitelistings";
    },
    {
      code: 6172;
      name: "AdminDoesntExist";
      msg: "Admin does not exist";
    },
    {
      code: 6173;
      name: "InvalidInstructionForClub";
      msg: "Invalid instruction for club";
    },
    {
      code: 6174;
      name: "SellPermissionRangeOverlap";
      msg: "Sell permission range overlap";
    },
    {
      code: 6175;
      name: "MaxNumberOfSellPermissionExceeded";
      msg: "Sell permission number exceeded";
    },
    {
      code: 6176;
      name: "InvalidSellPermissionRange";
      msg: "Sell permission from must be less than to";
    },
    {
      code: 6177;
      name: "NotTheFirstFundraise";
      msg: "Non-first fundraises must be created through a proposal";
    }
  ];
};

export const IDL: ClubProgram = {
  version: "0.2.0",
  name: "club_program",
  constants: [
    {
      name: "SPL_GOV_SEED",
      type: "bytes",
      value: "[103, 111, 118, 101, 114, 110, 97, 110, 99, 101]",
    },
    {
      name: "TREASURY_SEED",
      type: "bytes",
      value: "[116, 114, 101, 97, 115, 117, 114, 121]",
    },
    {
      name: "VAULT_SEED",
      type: "bytes",
      value: "[118, 97, 117, 108, 116]",
    },
    {
      name: "VAULT_DATA_SEED",
      type: "bytes",
      value: "[118, 97, 117, 108, 116, 45, 100, 97, 116, 97]",
    },
    {
      name: "UNQ_CFG_SEED",
      type: "bytes",
      value: "[117, 110, 113, 45, 99, 102, 103]",
    },
    {
      name: "FEE_SEED",
      type: "bytes",
      value: "[102, 101, 101]",
    },
    {
      name: "PERFORMANCE_SEED",
      type: "bytes",
      value: "[112, 101, 114, 102, 111, 114, 109, 97, 110, 99, 101]",
    },
    {
      name: "UNQ_CLUB_SEED",
      type: "bytes",
      value: "[117, 110, 113, 45, 99, 108, 117, 98]",
    },
    {
      name: "UNQ_CLUB_MEMBER_SEED",
      type: "bytes",
      value: "[109, 101, 109, 98, 101, 114]",
    },
    {
      name: "PERMISSION_SEED",
      type: "bytes",
      value: "[112, 101, 114, 109, 105, 115, 115, 105, 111, 110]",
    },
    {
      name: "VOTER_WEIGHT_RECORD_SEED",
      type: "bytes",
      value:
        "[118, 111, 116, 101, 114, 45, 119, 101, 105, 103, 104, 116, 45, 114, 101, 99, 111, 114, 100]",
    },
    {
      name: "FUNDRAISE_CFG_SEED",
      type: "bytes",
      value: "[102, 117, 110, 100, 114, 97, 105, 115, 101, 45, 99, 102, 103]",
    },
    {
      name: "DEPOSIT_RECORD_SEED",
      type: "bytes",
      value:
        "[100, 101, 112, 111, 115, 105, 116, 45, 114, 101, 99, 111, 114, 100]",
    },
    {
      name: "FINANCIAL_RECORD_SEED",
      type: "bytes",
      value:
        "[102, 105, 110, 97, 110, 99, 105, 97, 108, 45, 114, 101, 99, 111, 114, 100]",
    },
    {
      name: "TREASURY_DATA_SEED",
      type: "bytes",
      value: "[116, 114, 101, 97, 115, 117, 114, 121, 45, 100, 97, 116, 97]",
    },
    {
      name: "GOVERNANCE_SEED",
      type: "bytes",
      value:
        "[97, 99, 99, 111, 117, 110, 116, 45, 103, 111, 118, 101, 114, 110, 97, 110, 99, 101]",
    },
    {
      name: "MAX_VOTER_WEIGHT_RECORD_SEED",
      type: "bytes",
      value:
        "[109, 97, 120, 45, 118, 111, 116, 101, 114, 45, 119, 101, 105, 103, 104, 116, 45, 114, 101, 99, 111, 114, 100]",
    },
    {
      name: "ROLE_BASED_CONFIG_SEED",
      type: "bytes",
      value:
        "[114, 111, 108, 101, 45, 98, 97, 115, 101, 100, 45, 99, 111, 110, 102, 105, 103, 45, 115, 101, 101, 100]",
    },
    {
      name: "PROPOSAL_METADATA_SEED",
      type: "bytes",
      value:
        "[112, 114, 111, 112, 111, 115, 97, 108, 45, 109, 101, 116, 97, 100, 97, 116, 97]",
    },
    {
      name: "ESCROW_SEED",
      type: "bytes",
      value:
        "[101, 115, 99, 114, 111, 119, 101, 100, 45, 116, 111, 107, 101, 110, 115]",
    },
    {
      name: "WITHDRAWAL_SEED",
      type: "bytes",
      value: "[119, 105, 116, 104, 100, 114, 97, 119, 97, 108]",
    },
    {
      name: "WITHDRAWAL_DATA_SEED",
      type: "bytes",
      value:
        "[119, 105, 116, 104, 100, 114, 97, 119, 97, 108, 45, 100, 97, 116, 97]",
    },
    {
      name: "SELL_PERMISSION_GOVERNANCE_SEED",
      type: "bytes",
      value:
        "[115, 112, 99, 45, 103, 111, 118, 101, 114, 110, 97, 110, 99, 101]",
    },
    {
      name: "PROFIT_SEED",
      type: "bytes",
      value: "[112, 114, 111, 102, 105, 116]",
    },
    {
      name: "STAKED_TOKENS_SEED",
      type: "bytes",
      value: "[115, 116, 97, 107, 101, 100, 45, 116, 111, 107, 101, 110, 115]",
    },
    {
      name: "STAKE_CONFIG_SEED",
      type: "bytes",
      value: "[115, 116, 97, 107, 101, 45, 99, 111, 110, 102, 105, 103]",
    },
    {
      name: "STAKE_RECORD_SEED",
      type: "bytes",
      value: "[115, 116, 97, 107, 101, 45, 114, 101, 99, 111, 114, 100]",
    },
    {
      name: "CLUB_PROGRAM_UNQ_TOKENS_SEED",
      type: "bytes",
      value:
        "[99, 108, 117, 98, 45, 112, 114, 111, 103, 114, 97, 109, 45, 117, 110, 113, 45, 116, 111, 107, 101, 110, 115]",
    },
    {
      name: "UNIVERSE_METADATA_SEED",
      type: "bytes",
      value: "[109, 101, 116, 97, 100, 97, 116, 97]",
    },
    {
      name: "UNIVERSE_SIGNER_SEED",
      type: "bytes",
      value: "[117, 110, 105, 118, 101, 114, 115, 101]",
    },
    {
      name: "STAKED_NFT_SEED",
      type: "bytes",
      value: "[115, 116, 97, 107, 101, 100, 45, 110, 102, 116]",
    },
    {
      name: "FINANCIAL_OFFER_SEED",
      type: "bytes",
      value:
        "[102, 105, 110, 97, 110, 99, 105, 97, 108, 45, 111, 102, 102, 101, 114]",
    },
    {
      name: "MAGIC_EDEN_DATA_SEED",
      type: "bytes",
      value: "[109, 97, 103, 105, 99, 45, 101, 100, 101, 110]",
    },
    {
      name: "ACCEPTED_OFFER_SEED",
      type: "bytes",
      value:
        "[97, 99, 99, 101, 112, 116, 101, 100, 45, 111, 102, 102, 101, 114]",
    },
    {
      name: "DENOMINATED_TOKEN_SEED",
      type: "bytes",
      value:
        "[100, 101, 110, 111, 109, 105, 110, 97, 116, 101, 100, 45, 116, 111, 107, 101, 110]",
    },
    {
      name: "ADMIN_AUTHORITY_SEED",
      type: "bytes",
      value:
        "[97, 100, 109, 105, 110, 45, 97, 117, 116, 104, 111, 114, 105, 116, 121]",
    },
    {
      name: "ADMIN_SEED",
      type: "bytes",
      value: "[97, 100, 109, 105, 110]",
    },
    {
      name: "TREASURY_MEMBER_SEED",
      type: "bytes",
      value:
        "[116, 114, 101, 97, 115, 117, 114, 121, 45, 109, 101, 109, 98, 101, 114, 45, 100, 97, 116, 97]",
    },
    {
      name: "TREASURY_REALM_SEED",
      type: "bytes",
      value:
        "[116, 114, 101, 97, 115, 117, 114, 121, 45, 114, 101, 97, 108, 109]",
    },
  ],
  instructions: [
    {
      name: "createClub",
      accounts: [
        {
          name: "realm",
          isMut: false,
          isSigner: false,
        },
        {
          name: "realmConfig",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenOwnerRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "clubData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "clubName",
          type: "string",
        },
        {
          name: "clubType",
          type: {
            defined: "ClubType",
          },
        },
        {
          name: "roles",
          type: {
            vec: {
              defined: "RolesDto",
            },
          },
        },
        {
          name: "ownerRole",
          type: "string",
        },
        {
          name: "defaultRole",
          type: {
            option: "string",
          },
        },
        {
          name: "kycConfig",
          type: {
            option: {
              defined: "KycConfig",
            },
          },
        },
      ],
    },
    {
      name: "createGovernance",
      accounts: [
        {
          name: "splGovernanceProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "realm",
          isMut: true,
          isSigner: false,
        },
        {
          name: "realmConfig",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenOwnerRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "voterWeightRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "clubData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "governanceDtos",
          type: {
            vec: {
              defined: "GovernanceDto",
            },
          },
        },
      ],
    },
    {
      name: "createTreasury",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "treasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "profit",
          isMut: true,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "clubData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vaultData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "financialRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "realm",
          isMut: false,
          isSigner: false,
        },
        {
          name: "splGovernance",
          isMut: false,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "tokenOwnerRecord",
          isMut: false,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "name",
          type: "string",
        },
        {
          name: "chainId",
          type: "string",
        },
        {
          name: "nftMaxVoterWeight",
          type: {
            option: "u64",
          },
        },
        {
          name: "roleDtos",
          type: {
            vec: {
              defined: "TreasuryRolesDto",
            },
          },
        },
      ],
    },
    {
      name: "supportClub",
      accounts: [
        {
          name: "memberData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "fundraiseConfig",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "clubData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "financialRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "tokenOwnerRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "supportType",
          type: {
            defined: "SupportType",
          },
        },
      ],
    },
    {
      name: "leaveClub",
      accounts: [
        {
          name: "realm",
          isMut: true,
          isSigner: false,
        },
        {
          name: "clubData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "recipientMemberData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "recipient",
          isMut: true,
          isSigner: false,
        },
        {
          name: "voterWeightRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "createClubProposal",
      accounts: [
        {
          name: "governance",
          isMut: true,
          isSigner: false,
        },
        {
          name: "realm",
          isMut: true,
          isSigner: false,
        },
        {
          name: "governanceAuthority",
          isMut: false,
          isSigner: false,
        },
        {
          name: "realmConfig",
          isMut: false,
          isSigner: false,
        },
        {
          name: "proposal",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenOwnerRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "splGovernanceProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "communityTokenMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "voterWeightRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "maxVoterWeightRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "proposalMetadata",
          isMut: true,
          isSigner: false,
        },
        {
          name: "clubData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "financialRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "useDeny",
          type: "bool",
        },
        {
          name: "proposalType",
          type: {
            defined: "ProposalType",
          },
        },
        {
          name: "name",
          type: "string",
        },
        {
          name: "description",
          type: "string",
        },
        {
          name: "options",
          type: {
            vec: "string",
          },
        },
      ],
    },
    {
      name: "allowMember",
      accounts: [
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "clubData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "realm",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "roles",
          type: {
            vec: "string",
          },
        },
        {
          name: "allowType",
          type: {
            defined: "AllowType",
          },
        },
      ],
    },
    {
      name: "updateMember",
      accounts: [
        {
          name: "realm",
          isMut: true,
          isSigner: false,
        },
        {
          name: "clubData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "memberAddress",
          isMut: true,
          isSigner: false,
        },
        {
          name: "memberVoterWeightRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "updaterMemberData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "data",
          type: {
            defined: "UpdateMemberDto",
          },
        },
      ],
    },
    {
      name: "acceptMembership",
      accounts: [
        {
          name: "realm",
          isMut: false,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "memberData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "tokenOwnerRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "clubData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "cancelInvitation",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "memberData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "memberAddress",
          isMut: false,
          isSigner: false,
        },
        {
          name: "clubData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "recipient",
          isMut: true,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "updateVoterWeight",
      accounts: [
        {
          name: "realm",
          isMut: false,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "voterWeightRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "clubData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "action",
          type: {
            defined: "UpdateVoterWeightAction",
          },
        },
      ],
    },
    {
      name: "executeProposal",
      accounts: [
        {
          name: "governance",
          isMut: true,
          isSigner: false,
        },
        {
          name: "proposal",
          isMut: true,
          isSigner: false,
        },
        {
          name: "proposalTransaction",
          isMut: true,
          isSigner: false,
        },
        {
          name: "splGovernanceProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "proposalMetadata",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "clubData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "distribute",
      accounts: [
        {
          name: "clubData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "proposal",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "offer",
          isMut: true,
          isSigner: false,
        },
        {
          name: "escrowProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenLedger",
          isMut: true,
          isSigner: false,
        },
        {
          name: "profit",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "profitToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "escrowedWantedToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "makerWantedToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "treasuryIndex",
          type: "u32",
        },
        {
          name: "chainId",
          type: "string",
        },
      ],
    },
    {
      name: "castNftVote",
      accounts: [
        {
          name: "realm",
          isMut: false,
          isSigner: false,
        },
        {
          name: "voterWeightRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "proposal",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "initializeStaking",
      accounts: [
        {
          name: "clubData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "stakedTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "stakeConfig",
          isMut: true,
          isSigner: false,
        },
        {
          name: "unqMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "stakeName",
          type: "string",
        },
        {
          name: "capAmount",
          type: "u64",
        },
        {
          name: "stakePeriod",
          type: {
            defined: "StakePeriodType",
          },
        },
      ],
    },
    {
      name: "stakeTokens",
      accounts: [
        {
          name: "clubData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "stakeConfig",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "amount",
          type: "u64",
        },
        {
          name: "stakeOption",
          type: {
            defined: "StakeOption",
          },
        },
      ],
    },
    {
      name: "claimStakedTokens",
      accounts: [
        {
          name: "clubData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "stakeConfig",
          isMut: true,
          isSigner: false,
        },
        {
          name: "stakedTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "memberTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "stakeOption",
          type: {
            defined: "StakeOption",
          },
        },
      ],
    },
    {
      name: "unstakeTokens",
      accounts: [
        {
          name: "clubData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "stakeConfig",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "stakeOption",
          type: {
            defined: "StakeOption",
          },
        },
      ],
    },
    {
      name: "finishStaking",
      accounts: [
        {
          name: "clubData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "stakedTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "stakeConfig",
          isMut: true,
          isSigner: false,
        },
        {
          name: "clubUnqTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "startStaking",
      accounts: [
        {
          name: "clubData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "stakedTokens",
          isMut: false,
          isSigner: false,
        },
        {
          name: "stakeConfig",
          isMut: true,
          isSigner: false,
        },
        {
          name: "unqMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "initializeStakingReward",
      accounts: [
        {
          name: "clubUnqTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "unqMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "updateProposalMetadata",
      accounts: [
        {
          name: "proposalMetadata",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "description",
          type: {
            option: "string",
          },
        },
        {
          name: "name",
          type: {
            option: "string",
          },
        },
        {
          name: "options",
          type: {
            option: {
              vec: "string",
            },
          },
        },
        {
          name: "discussionLink",
          type: {
            option: "string",
          },
        },
      ],
    },
    {
      name: "createFinancialOffer",
      accounts: [
        {
          name: "clubData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "financialOffer",
          isMut: true,
          isSigner: false,
        },
        {
          name: "financialRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "amountOfRights",
          type: "u64",
        },
        {
          name: "price",
          type: "u64",
        },
        {
          name: "buyer",
          type: {
            option: "publicKey",
          },
        },
      ],
    },
    {
      name: "cancelFinancialOffer",
      accounts: [
        {
          name: "clubData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "treasuryData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: false,
          isSigner: false,
        },
        {
          name: "financialRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "financialOffer",
          isMut: true,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "acceptFinancialOffer",
      accounts: [
        {
          name: "clubData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "seller",
          isMut: true,
          isSigner: false,
        },
        {
          name: "financialOffer",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: false,
          isSigner: false,
        },
        {
          name: "buyerFinancialRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "sellerFinancialRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "buyerTokenOwnerRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "amountToBuy",
          type: "u64",
        },
      ],
    },
    {
      name: "reserveRights",
      accounts: [
        {
          name: "clubData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "financialRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "reservedRights",
          type: {
            defined: "ReservedRights",
          },
        },
      ],
    },
    {
      name: "updateAllocation",
      accounts: [
        {
          name: "clubData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "fundraiseConfig",
          isMut: true,
          isSigner: false,
        },
        {
          name: "financialRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "equal",
          type: {
            option: "u64",
          },
        },
        {
          name: "customAllocations",
          type: {
            option: {
              vec: {
                defined: "CustomAllocation",
              },
            },
          },
        },
        {
          name: "removeAllocation",
          type: {
            option: {
              vec: {
                defined: "CustomAllocation",
              },
            },
          },
        },
        {
          name: "newCap",
          type: {
            option: "u64",
          },
        },
      ],
    },
    {
      name: "configureWhitelistings",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "whitelistingData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "adminsData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "whitelistings",
          type: {
            vec: "publicKey",
          },
        },
        {
          name: "whitelistingAction",
          type: {
            defined: "WhitelistingAction",
          },
        },
      ],
    },
    {
      name: "configureAdmins",
      accounts: [
        {
          name: "admins",
          isMut: true,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "admins",
          type: {
            option: {
              vec: {
                defined: "AdminConfig",
              },
            },
          },
        },
        {
          name: "feeWallet",
          type: {
            option: "publicKey",
          },
        },
        {
          name: "feePercentage",
          type: {
            option: "u32",
          },
        },
        {
          name: "fundraiseFeeConfigs",
          type: {
            option: {
              vec: {
                defined: "FundraiseFeeConfig",
              },
            },
          },
        },
        {
          name: "otcFeeConfigs",
          type: {
            option: {
              vec: {
                defined: "OtcFeeConfig",
              },
            },
          },
        },
      ],
    },
    {
      name: "migrateFinancials",
      accounts: [
        {
          name: "clubData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "payerMemberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "member",
          isMut: false,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "financialRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "fundraiseConfig",
          isMut: true,
          isSigner: false,
        },
        {
          name: "splGovernance",
          isMut: false,
          isSigner: false,
        },
        {
          name: "clubRealm",
          isMut: false,
          isSigner: false,
        },
        {
          name: "treasuryRealm",
          isMut: false,
          isSigner: false,
        },
        {
          name: "governingTokenMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "clubTokenOwnerRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasuryTokenOwnerRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "usdcAmount",
          type: "u64",
        },
      ],
    },
    {
      name: "insertTransaction",
      accounts: [
        {
          name: "splGovernanceProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "governance",
          isMut: true,
          isSigner: false,
        },
        {
          name: "proposal",
          isMut: true,
          isSigner: false,
        },
        {
          name: "proposalMetadata",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "proposalTransaction",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenOwnerRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "communityTokenMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "data",
          type: {
            vec: "bytes",
          },
        },
        {
          name: "signerIndexes",
          type: {
            option: {
              vec: {
                vec: "u32",
              },
            },
          },
        },
      ],
    },
    {
      name: "fundraise",
      accounts: [
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "fundraiseConfig",
          isMut: true,
          isSigner: false,
        },
        {
          name: "financialRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "clubData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "fundraiseAction",
          type: {
            defined: "FundraiseAction",
          },
        },
      ],
    },
    {
      name: "cancelProposal",
      accounts: [
        {
          name: "clubData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "memberData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "offer",
          isMut: true,
          isSigner: false,
        },
        {
          name: "proposalMetadata",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasuryData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "makerOfferedTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "financialRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "escrowProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "chainId",
          type: "string",
        },
        {
          name: "action",
          type: {
            defined: "ProposalAction",
          },
        },
      ],
    },
  ],
  accounts: [
    {
      name: "clubData",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            type: "publicKey",
          },
          {
            name: "realm",
            type: "publicKey",
          },
          {
            name: "name",
            type: "string",
          },
          {
            name: "slotCreated",
            type: "u64",
          },
          {
            name: "clubType",
            type: {
              defined: "ClubType",
            },
          },
          {
            name: "roleConfig",
            type: {
              vec: {
                defined: "RoleConfig",
              },
            },
          },
          {
            name: "treasuryCount",
            type: "u32",
          },
          {
            name: "stakeCount",
            type: "u32",
          },
          {
            name: "activeStakeCount",
            type: "u32",
          },
          {
            name: "hasActiveStaking",
            type: "bool",
          },
          {
            name: "numberOfMembers",
            type: "u32",
          },
          {
            name: "defaultRole",
            type: {
              option: "string",
            },
          },
          {
            name: "kycConfig",
            type: {
              option: {
                defined: "KycConfig",
              },
            },
          },
        ],
      },
    },
    {
      name: "whitelistingData",
      type: {
        kind: "struct",
        fields: [
          {
            name: "whitelistedMembers",
            type: {
              vec: "publicKey",
            },
          },
        ],
      },
    },
    {
      name: "admins",
      type: {
        kind: "struct",
        fields: [
          {
            name: "feeWallet",
            type: "publicKey",
          },
          {
            name: "feePercentage",
            type: "u32",
          },
          {
            name: "adminConfigs",
            type: {
              vec: {
                defined: "AdminConfig",
              },
            },
          },
          {
            name: "fundraiseFeeConfigs",
            type: {
              vec: {
                defined: "FundraiseFeeConfig",
              },
            },
          },
          {
            name: "otcFeeConfigs",
            type: {
              vec: {
                defined: "OtcFeeConfig",
              },
            },
          },
        ],
      },
    },
    {
      name: "clubVault",
      type: {
        kind: "struct",
        fields: [],
      },
    },
    {
      name: "clubVaultData",
      type: {
        kind: "struct",
        fields: [
          {
            name: "chainId",
            type: "string",
          },
        ],
      },
    },
    {
      name: "withdrawal",
      type: {
        kind: "struct",
        fields: [],
      },
    },
    {
      name: "withdrawalData",
      type: {
        kind: "struct",
        fields: [
          {
            name: "realm",
            type: "publicKey",
          },
          {
            name: "clubData",
            type: "publicKey",
          },
          {
            name: "treasury",
            type: "publicKey",
          },
          {
            name: "proposal",
            type: "publicKey",
          },
          {
            name: "fundraiseNumber",
            type: "u32",
          },
          {
            name: "withdrawalAmount",
            type: "u64",
          },
          {
            name: "amountWithdrawn",
            type: "u64",
          },
          {
            name: "totalFinancialPower",
            type: "u64",
          },
          {
            name: "withdrawalMint",
            type: "publicKey",
          },
          {
            name: "proposalCreatedAt",
            type: "i64",
          },
        ],
      },
    },
    {
      name: "fundraiseConfig",
      type: {
        kind: "struct",
        fields: [
          {
            name: "treasury",
            type: "publicKey",
          },
          {
            name: "capAmount",
            type: "u64",
          },
          {
            name: "raisedAmount",
            type: "u64",
          },
          {
            name: "isActive",
            type: "bool",
          },
          {
            name: "fundraiseIndex",
            type: "u32",
          },
          {
            name: "allocation",
            type: {
              option: {
                defined: "Allocation",
              },
            },
          },
        ],
      },
    },
    {
      name: "financialRecord",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            type: "publicKey",
          },
          {
            name: "treasury",
            type: "publicKey",
          },
          {
            name: "sellOffersCount",
            type: "u32",
          },
          {
            name: "listedFinancialRights",
            type: "u64",
          },
          {
            name: "depositRecords",
            type: {
              vec: {
                defined: "DepositRecord",
              },
            },
          },
          {
            name: "treasuryRole",
            type: "string",
          },
          {
            name: "createdAt",
            type: "i64",
          },
          {
            name: "createdAtSlot",
            type: "u64",
          },
          {
            name: "tokenOwnerRecord",
            type: "publicKey",
          },
        ],
      },
    },
    {
      name: "financialRecordOffer",
      type: {
        kind: "struct",
        fields: [
          {
            name: "treasury",
            type: "publicKey",
          },
          {
            name: "financialRecord",
            type: "publicKey",
          },
          {
            name: "amountOfRights",
            type: "u64",
          },
          {
            name: "price",
            type: "u64",
          },
          {
            name: "seller",
            type: "publicKey",
          },
          {
            name: "wantedTokenMint",
            type: {
              option: "publicKey",
            },
          },
          {
            name: "buyer",
            type: {
              option: "publicKey",
            },
          },
          {
            name: "financialIndex",
            type: "u32",
          },
          {
            name: "initialAmountOfRights",
            type: "u64",
          },
        ],
      },
    },
    {
      name: "maxVoterWeightRecord",
      type: {
        kind: "struct",
        fields: [
          {
            name: "realm",
            type: "publicKey",
          },
          {
            name: "governingTokenMint",
            type: "publicKey",
          },
          {
            name: "maxVoterWeight",
            type: "u64",
          },
          {
            name: "maxVoterWeightExpiry",
            type: {
              option: "u64",
            },
          },
          {
            name: "reserved",
            type: {
              array: ["u8", 8],
            },
          },
        ],
      },
    },
    {
      name: "voterWeightRecord",
      type: {
        kind: "struct",
        fields: [
          {
            name: "realm",
            type: "publicKey",
          },
          {
            name: "governingTokenMint",
            type: "publicKey",
          },
          {
            name: "governingTokenOwner",
            type: "publicKey",
          },
          {
            name: "voterWeight",
            type: "u64",
          },
          {
            name: "voterWeightExpiry",
            type: {
              option: "u64",
            },
          },
          {
            name: "weightAction",
            type: {
              option: {
                defined: "VoterWeightAction",
              },
            },
          },
          {
            name: "weightActionTarget",
            type: {
              option: "publicKey",
            },
          },
          {
            name: "reserved",
            type: {
              array: ["u8", 8],
            },
          },
        ],
      },
    },
    {
      name: "allowedMemberData",
      type: {
        kind: "struct",
        fields: [
          {
            name: "clubData",
            type: "publicKey",
          },
          {
            name: "memberPubkey",
            type: "publicKey",
          },
          {
            name: "isMember",
            type: "bool",
          },
          {
            name: "status",
            type: {
              defined: "MemberStatus",
            },
          },
          {
            name: "role",
            type: "string",
          },
          {
            name: "joinedAt",
            type: "i64",
          },
          {
            name: "joinedAtSlot",
            type: "u64",
          },
        ],
      },
    },
    {
      name: "nftVoteRecord",
      type: {
        kind: "struct",
        fields: [
          {
            name: "proposal",
            type: "publicKey",
          },
          {
            name: "nftMint",
            type: "publicKey",
          },
          {
            name: "governingTokenOwner",
            type: "publicKey",
          },
        ],
      },
    },
    {
      name: "proposalMetadata",
      type: {
        kind: "struct",
        fields: [
          {
            name: "name",
            type: "string",
          },
          {
            name: "options",
            type: {
              vec: "string",
            },
          },
          {
            name: "authority",
            type: "publicKey",
          },
          {
            name: "discussionLink",
            type: "string",
          },
          {
            name: "treasury",
            type: "publicKey",
          },
          {
            name: "currentFundraiseIndex",
            type: "u32",
          },
          {
            name: "totalFinancialPower",
            type: "u64",
          },
          {
            name: "proposal",
            type: "publicKey",
          },
          {
            name: "proposalIndex",
            type: "u32",
          },
          {
            name: "realm",
            type: "publicKey",
          },
          {
            name: "clubData",
            type: "publicKey",
          },
          {
            name: "governance",
            type: "publicKey",
          },
          {
            name: "isCreated",
            type: "bool",
          },
          {
            name: "description",
            type: "string",
          },
          {
            name: "authorityRole",
            type: "string",
          },
          {
            name: "dataPda",
            type: {
              option: "publicKey",
            },
          },
          {
            name: "proposalType",
            type: {
              defined: "ProposalType",
            },
          },
          {
            name: "proposalStatus",
            type: {
              defined: "ProposalStatus",
            },
          },
          {
            name: "approvalQuorumPercentage",
            type: "u8",
          },
          {
            name: "maxVoterWeight",
            type: {
              option: "u64",
            },
          },
          {
            name: "createdAt",
            type: "i64",
          },
        ],
      },
    },
    {
      name: "stakeConfig",
      type: {
        kind: "struct",
        fields: [
          {
            name: "clubData",
            type: "publicKey",
          },
          {
            name: "capAmount",
            type: "u64",
          },
          {
            name: "raisedAmount",
            type: "u64",
          },
          {
            name: "stakePeriodType",
            type: {
              defined: "StakePeriodType",
            },
          },
          {
            name: "stakePeriodEnd",
            type: {
              option: "i64",
            },
          },
          {
            name: "name",
            type: "string",
          },
          {
            name: "rewardPercentage",
            type: "u32",
          },
          {
            name: "status",
            type: {
              defined: "StakeStatus",
            },
          },
          {
            name: "nftStakeRecord",
            type: {
              option: {
                defined: "NftStakeRecord",
              },
            },
          },
          {
            name: "stakeIndex",
            type: "u32",
          },
        ],
      },
    },
    {
      name: "stakeRecord",
      type: {
        kind: "struct",
        fields: [
          {
            name: "stakeOwner",
            type: "publicKey",
          },
          {
            name: "amount",
            type: "u64",
          },
          {
            name: "hasClaimed",
            type: "bool",
          },
          {
            name: "claimAmount",
            type: "u64",
          },
          {
            name: "hasUnstaked",
            type: "bool",
          },
          {
            name: "unstakePeriodEnd",
            type: {
              option: "i64",
            },
          },
          {
            name: "stakeConfig",
            type: "publicKey",
          },
        ],
      },
    },
    {
      name: "treasuryData",
      type: {
        kind: "struct",
        fields: [
          {
            name: "clubData",
            docs: ["Club the treasury belongs to"],
            type: "publicKey",
          },
          {
            name: "realm",
            docs: ["Realm used to derive governances for this treasury"],
            type: "publicKey",
          },
          {
            name: "fundraiseCount",
            docs: ["The total number of fundraises within a treasury"],
            type: "u32",
          },
          {
            name: "treasury",
            docs: [
              "The address of the treasury that this TreasuryData expands upon",
            ],
            type: "publicKey",
          },
          {
            name: "hasActiveFundraise",
            docs: [
              "Flag for active fundraises used to permit or forbid actions",
            ],
            type: "bool",
          },
          {
            name: "governance",
            docs: ["The default/mandatory governance of the treasury"],
            type: "publicKey",
          },
          {
            name: "maxVoterWeight",
            docs: ["max_voter_weight"],
            type: "u64",
          },
          {
            name: "totalFinancialPower",
            docs: [
              "The total financial power within the treasury; Also max_voter_weight for Token clubs",
            ],
            type: "u64",
          },
          {
            name: "denominatedCurrency",
            docs: ["Mint of the Token used to determine financial rights"],
            type: {
              option: "publicKey",
            },
          },
          {
            name: "financialTokenAccount",
            docs: [
              "Token Account holding tokens that determine financial rights",
            ],
            type: {
              option: "publicKey",
            },
          },
          {
            name: "sellPermission",
            docs: [
              "SellPermission Data use for additional governance configurations",
              "TODO: Change this to vec",
            ],
            type: {
              array: [
                {
                  defined: "SellPermission",
                },
                10,
              ],
            },
          },
          {
            name: "withdrawalGovernance",
            docs: ["An optional withdrawal governance account"],
            type: {
              option: "publicKey",
            },
          },
          {
            name: "financialRecordCount",
            docs: ["Number of member who supported the treasury"],
            type: "u32",
          },
          {
            name: "treasuryIndex",
            docs: ["The index of the treasury"],
            type: "u32",
          },
          {
            name: "financialOffersCount",
            docs: ["Number of active financial offers"],
            type: "u32",
          },
          {
            name: "transferGovernance",
            docs: ["Optional Governance account used for transfer proposals"],
            type: {
              option: "publicKey",
            },
          },
          {
            name: "changeClubConfigGovernance",
            docs: [
              "Optional Governance account used for governance config changes proposals",
            ],
            type: {
              option: "publicKey",
            },
          },
          {
            name: "hasActiveUpdateGovernanceConfig",
            docs: [
              "Flag for active update governance config changes proposals",
            ],
            type: "bool",
          },
          {
            name: "reservedRights",
            docs: [
              "Optional reserved rights parameter storing ownership over tresury by specific members",
            ],
            type: {
              option: {
                defined: "ReservedRights",
              },
            },
          },
          {
            name: "treasuryRoleConfig",
            docs: ["Treasury roles"],
            type: {
              vec: {
                defined: "TreasuryRoleConfig",
              },
            },
          },
          {
            name: "defaultTreasuryRole",
            docs: ["Default treasury role for assigning"],
            type: "string",
          },
          {
            name: "name",
            docs: ["Name of the treasury"],
            type: "string",
          },
        ],
      },
    },
    {
      name: "universeMetadata",
      docs: ["8 + 32 + 32 + 32 + 8 + 4 + 1 + 1 + 8"],
      type: {
        kind: "struct",
        fields: [
          {
            name: "discriminator",
            type: {
              array: ["u8", 8],
            },
          },
          {
            name: "authority",
            type: "publicKey",
          },
          {
            name: "candyMachine",
            type: "publicKey",
          },
          {
            name: "mint",
            type: "publicKey",
          },
          {
            name: "mintingEpoch",
            type: "u64",
          },
          {
            name: "currentUniverseLevel",
            type: "u32",
          },
          {
            name: "status",
            type: "u8",
          },
          {
            name: "rarity",
            type: {
              defined: "Rarity",
            },
          },
          {
            name: "evolutionPoints",
            type: "u64",
          },
        ],
      },
    },
  ],
  types: [
    {
      name: "RoleConfig",
      type: {
        kind: "struct",
        fields: [
          {
            name: "name",
            type: "string",
          },
          {
            name: "clubActions",
            type: {
              vec: {
                defined: "ClubAction",
              },
            },
          },
          {
            name: "membersCount",
            type: "u32",
          },
        ],
      },
    },
    {
      name: "KycConfig",
      type: {
        kind: "struct",
        fields: [
          {
            name: "required",
            type: "bool",
          },
          {
            name: "location",
            type: {
              defined: "KycLocation",
            },
          },
        ],
      },
    },
    {
      name: "RolesDto",
      type: {
        kind: "struct",
        fields: [
          {
            name: "name",
            type: "string",
          },
          {
            name: "roleWeight",
            type: "u64",
          },
          {
            name: "clubActions",
            type: {
              vec: {
                defined: "ClubAction",
              },
            },
          },
        ],
      },
    },
    {
      name: "UpdateRoleWeight",
      type: {
        kind: "struct",
        fields: [
          {
            name: "role",
            type: "string",
          },
          {
            name: "currentWeight",
            type: "u64",
          },
          {
            name: "updateWeight",
            type: "u64",
          },
        ],
      },
    },
    {
      name: "AdminConfig",
      type: {
        kind: "struct",
        fields: [
          {
            name: "admin",
            type: "publicKey",
          },
          {
            name: "permissions",
            type: {
              vec: {
                defined: "AdminPermission",
              },
            },
          },
          {
            name: "status",
            type: {
              defined: "AdminStatus",
            },
          },
        ],
      },
    },
    {
      name: "FundraiseFeeConfig",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            type: "publicKey",
          },
          {
            name: "feePercentage",
            type: "u32",
          },
        ],
      },
    },
    {
      name: "OtcFeeConfig",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            type: "publicKey",
          },
          {
            name: "feePercentage",
            type: "u32",
          },
        ],
      },
    },
    {
      name: "Offer",
      type: {
        kind: "struct",
        fields: [
          {
            name: "maker",
            docs: ["The one who initialized the Offer (treasuy/vault)"],
            type: "publicKey",
          },
          {
            name: "treasury",
            docs: ["Treasury of the club which signed the initialization"],
            type: "publicKey",
          },
          {
            name: "offeredAmount",
            docs: ["Offered amount of tokens"],
            type: "u64",
          },
          {
            name: "offeredTokenMint",
            docs: ["Mint of the offer tokens"],
            type: "publicKey",
          },
          {
            name: "wantedAmount",
            docs: [
              "Amount of tokens the maker wants in return for offered ones",
            ],
            type: "u64",
          },
          {
            name: "wantedTokenMint",
            docs: ["Mint of the wanted tokens"],
            type: "publicKey",
          },
          {
            name: "tokenLedgerMint",
            docs: [
              "Mint of the NFT used to create TokenLedger when bought for the first time",
            ],
            type: "publicKey",
          },
          {
            name: "makerWantedToken",
            docs: [
              "Token account of the club to which the assest should be transfered after distribution",
            ],
            type: "publicKey",
          },
          {
            name: "escrowedWantedToken",
            docs: [
              "Token account to which the tokens will be deposited once the Taker accepts the offer",
            ],
            type: "publicKey",
          },
          {
            name: "status",
            docs: [
              "Current status of the offer: Initialised, Accepted, Canceled",
            ],
            type: {
              defined: "OfferStatus",
            },
          },
          {
            name: "offerType",
            docs: ["Buy/Sell NFT"],
            type: {
              defined: "OfferType",
            },
          },
          {
            name: "dedicatedTaker",
            docs: ["Optional dedicated Taker"],
            type: {
              option: "publicKey",
            },
          },
          {
            name: "proposal",
            docs: ["Proposal the offer is derived from"],
            type: "publicKey",
          },
          {
            name: "sellerFeeBps",
            docs: ["Fee"],
            type: "u16",
          },
        ],
      },
    },
    {
      name: "Allocation",
      type: {
        kind: "struct",
        fields: [
          {
            name: "equal",
            type: {
              option: "u64",
            },
          },
          {
            name: "custom",
            type: {
              option: {
                vec: {
                  defined: "CustomAllocation",
                },
              },
            },
          },
        ],
      },
    },
    {
      name: "CustomAllocation",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            type: "publicKey",
          },
          {
            name: "amount",
            type: "u64",
          },
        ],
      },
    },
    {
      name: "DepositRecord",
      type: {
        kind: "struct",
        fields: [
          {
            name: "accumulatedAmount",
            type: "u64",
          },
          {
            name: "fundraiseIndex",
            type: "u32",
          },
          {
            name: "depositedAt",
            type: "i64",
          },
          {
            name: "currentFundraiseDeposit",
            type: "u64",
          },
          {
            name: "tradedRight",
            type: {
              option: {
                vec: {
                  defined: "TradedRight",
                },
              },
            },
          },
        ],
      },
    },
    {
      name: "TradedRight",
      type: {
        kind: "struct",
        fields: [
          {
            name: "amount",
            type: "u64",
          },
          {
            name: "timestamp",
            type: "i64",
          },
          {
            name: "tradeType",
            type: {
              defined: "TradedRightType",
            },
          },
        ],
      },
    },
    {
      name: "GovernanceDto",
      type: {
        kind: "struct",
        fields: [
          {
            name: "governanceType",
            type: {
              defined: "GovernanceType",
            },
          },
          {
            name: "maxVotingTime",
            type: "u32",
          },
          {
            name: "voteThresholdPercentage",
            type: "u8",
          },
          {
            name: "sellPermission",
            type: {
              option: {
                defined: "SellPermission",
              },
            },
          },
        ],
      },
    },
    {
      name: "UpdateGovernanceConfigInput",
      type: {
        kind: "struct",
        fields: [
          {
            name: "newQuorums",
            type: "bytes",
          },
          {
            name: "seeds",
            type: {
              vec: {
                vec: "bytes",
              },
            },
          },
          {
            name: "newVotingTime",
            type: {
              option: "u32",
            },
          },
        ],
      },
    },
    {
      name: "GovernanceConfig",
      type: {
        kind: "struct",
        fields: [
          {
            name: "voteThresholdPercentage",
            docs: [
              "The type of the vote threshold used for voting",
              "Note: In the current version only YesVote threshold is supported",
            ],
            type: {
              defined: "VoteThresholdPercentage",
            },
          },
          {
            name: "minCommunityWeightToCreateProposal",
            docs: [
              "Minimum community weight a governance token owner must possess to be able to create a proposal",
            ],
            type: "u64",
          },
          {
            name: "minTransactionHoldUpTime",
            docs: [
              "Minimum waiting time in seconds for a transaction to be executed after proposal is voted on",
            ],
            type: "u32",
          },
          {
            name: "maxVotingTime",
            docs: ["Time limit in seconds for proposal to be open for voting"],
            type: "u32",
          },
          {
            name: "voteTipping",
            docs: ["Conditions under which a vote will complete early"],
            type: {
              defined: "VoteTipping",
            },
          },
          {
            name: "proposalCoolOffTime",
            docs: [
              "The time period in seconds within which a Proposal can be still cancelled after being voted on",
              "Once cool off time expires Proposal can't be cancelled any longer and becomes a law",
              "Note: This field is not implemented in the current version",
            ],
            type: "u32",
          },
          {
            name: "minCouncilWeightToCreateProposal",
            docs: [
              "Minimum council weight a governance token owner must possess to be able to create a proposal",
            ],
            type: "u64",
          },
        ],
      },
    },
    {
      name: "UpdateMemberDto",
      type: {
        kind: "struct",
        fields: [
          {
            name: "isMember",
            type: {
              option: "bool",
            },
          },
          {
            name: "status",
            type: {
              option: {
                defined: "MemberStatus",
              },
            },
          },
          {
            name: "role",
            type: {
              option: "string",
            },
          },
        ],
      },
    },
    {
      name: "NftStakeRecord",
      type: {
        kind: "struct",
        fields: [
          {
            name: "nftOwner",
            type: "publicKey",
          },
          {
            name: "tokenAccount",
            type: "publicKey",
          },
          {
            name: "tokenMint",
            type: "publicKey",
          },
          {
            name: "stakedNft",
            type: "publicKey",
          },
          {
            name: "rarity",
            type: {
              defined: "Rarity",
            },
          },
          {
            name: "hasClaimed",
            type: "bool",
          },
          {
            name: "unstakePeriodEnd",
            type: {
              option: "i64",
            },
          },
        ],
      },
    },
    {
      name: "ReservedRights",
      type: {
        kind: "struct",
        fields: [
          {
            name: "totalReservedRights",
            type: "u64",
          },
          {
            name: "totalReservedPercentage",
            type: "u32",
          },
          {
            name: "individualRights",
            type: {
              vec: {
                defined: "IndividualRight",
              },
            },
          },
        ],
      },
    },
    {
      name: "IndividualRight",
      type: {
        kind: "struct",
        fields: [
          {
            name: "memberPubkey",
            type: "publicKey",
          },
          {
            name: "rightPercentage",
            type: "u32",
          },
          {
            name: "amountOfRights",
            type: "u64",
          },
        ],
      },
    },
    {
      name: "SellPermission",
      type: {
        kind: "struct",
        fields: [
          {
            name: "from",
            type: "u64",
          },
          {
            name: "to",
            type: "u64",
          },
          {
            name: "quorumMinimum",
            type: "u8",
          },
          {
            name: "decimal",
            type: "u16",
          },
          {
            name: "governance",
            type: "publicKey",
          },
        ],
      },
    },
    {
      name: "SellPermissionDto",
      type: {
        kind: "struct",
        fields: [
          {
            name: "from",
            type: "u64",
          },
          {
            name: "to",
            type: "u64",
          },
          {
            name: "quorumMinimum",
            type: "u8",
          },
          {
            name: "decimal",
            type: "u16",
          },
        ],
      },
    },
    {
      name: "AddSellPermissionData",
      type: {
        kind: "struct",
        fields: [
          {
            name: "sellPermission",
            type: {
              defined: "SellPermissionDto",
            },
          },
          {
            name: "maxVotingTime",
            type: "u32",
          },
        ],
      },
    },
    {
      name: "TreasuryRoleConfig",
      type: {
        kind: "struct",
        fields: [
          {
            name: "name",
            type: "string",
          },
          {
            name: "roleWeight",
            type: "u64",
          },
          {
            name: "treasuryActions",
            type: {
              vec: {
                defined: "TreasuryAction",
              },
            },
          },
          {
            name: "membersCount",
            type: "u32",
          },
          {
            name: "isDefault",
            type: "bool",
          },
        ],
      },
    },
    {
      name: "TreasuryRolesDto",
      type: {
        kind: "struct",
        fields: [
          {
            name: "name",
            type: "string",
          },
          {
            name: "roleWeight",
            type: "u64",
          },
          {
            name: "treasuryActions",
            type: {
              vec: {
                defined: "TreasuryAction",
              },
            },
          },
          {
            name: "isDefault",
            type: "bool",
          },
        ],
      },
    },
    {
      name: "SetGovCfgDto",
      type: {
        kind: "struct",
        fields: [
          {
            name: "config",
            type: {
              vec: {
                defined: "GovernanceConfig",
              },
            },
          },
          {
            name: "seeds",
            type: {
              vec: {
                vec: "bytes",
              },
            },
          },
        ],
      },
    },
    {
      name: "KycError",
      type: {
        kind: "enum",
        variants: [
          {
            name: "KycError",
          },
          {
            name: "IncorrectGatekeeper",
          },
          {
            name: "InvalidOwner",
          },
          {
            name: "InvalidToken",
          },
          {
            name: "InvalidSessionToken",
          },
          {
            name: "TokenRevoked",
          },
          {
            name: "ExpectedRevokedToken",
          },
          {
            name: "InvalidStateChange",
          },
          {
            name: "IncorrectProgramId",
          },
          {
            name: "TokenExpired",
          },
          {
            name: "InvalidGatekeeperAccount",
          },
        ],
      },
    },
    {
      name: "KycLocation",
      type: {
        kind: "enum",
        variants: [
          {
            name: "All",
          },
          {
            name: "OnlyUS",
          },
          {
            name: "NonUS",
          },
        ],
      },
    },
    {
      name: "ClubType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "TokenProportional",
          },
          {
            name: "TokenEqual",
          },
          {
            name: "RoleBased",
          },
          {
            name: "NftBased",
          },
          {
            name: "Syndicate",
          },
        ],
      },
    },
    {
      name: "ClubVoterWeightAction",
      type: {
        kind: "enum",
        variants: [
          {
            name: "CastVote",
          },
          {
            name: "CommentProposal",
          },
          {
            name: "CreateGovernance",
          },
          {
            name: "CreateProposal",
          },
          {
            name: "SignOffProposal",
          },
          {
            name: "CancelProposal",
          },
        ],
      },
    },
    {
      name: "UpdateMembersForRole",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Add",
          },
          {
            name: "Remove",
          },
        ],
      },
    },
    {
      name: "ClubAction",
      type: {
        kind: "enum",
        variants: [
          {
            name: "CreateTreasuryGovernance",
          },
          {
            name: "CreateWithdrawalGovernance",
          },
          {
            name: "CreateTransferGovernance",
          },
          {
            name: "AllowMember",
          },
          {
            name: "CancelInvitation",
          },
          {
            name: "SupportClub",
          },
          {
            name: "UpdateMember",
          },
          {
            name: "InitializeStaking",
          },
          {
            name: "StakeTokens",
          },
          {
            name: "AcceptFinancialOffer",
          },
          {
            name: "CreateChangeClubConfigGovernance",
          },
          {
            name: "CanLeave",
          },
        ],
      },
    },
    {
      name: "SupportType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Deposit",
            fields: ["u64"],
          },
          {
            name: "Withdrawal",
            fields: ["u64"],
          },
        ],
      },
    },
    {
      name: "WhitelistingAction",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Add",
          },
          {
            name: "Remove",
          },
        ],
      },
    },
    {
      name: "AdminPermission",
      type: {
        kind: "enum",
        variants: [
          {
            name: "CreateClub",
          },
          {
            name: "WhitelistMembers",
          },
        ],
      },
    },
    {
      name: "AdminStatus",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Accepted",
          },
          {
            name: "Rejected",
          },
        ],
      },
    },
    {
      name: "OfferStatus",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Initialised",
          },
          {
            name: "Accepted",
          },
          {
            name: "Canceled",
          },
          {
            name: "PendingBuyNowExecutionOnMagicEden",
          },
          {
            name: "ExecutedBuyNowOnMagicEden",
          },
          {
            name: "CanceledOnMagic",
          },
          {
            name: "PendingSellExecutionOnMagicEden",
          },
          {
            name: "ExecutedSellOnMagicEden",
          },
          {
            name: "FundsTransfered",
          },
          {
            name: "ListedOnSolsea",
          },
          {
            name: "ExecutedBuyNowOnSolsea",
          },
          {
            name: "CanceledOnSolsea",
          },
        ],
      },
    },
    {
      name: "OfferType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Buy",
          },
          {
            name: "Sell",
          },
        ],
      },
    },
    {
      name: "InitializeType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "MagicEdenSell",
            fields: ["bytes"],
          },
          {
            name: "MagicEdenBuy",
            fields: [
              {
                vec: "bytes",
              },
            ],
          },
          {
            name: "RegularBuySell",
            fields: [
              "u64",
              "u64",
              {
                option: "publicKey",
              },
            ],
          },
          {
            name: "Solsea",
            fields: ["u64", "u64", "u16"],
          },
        ],
      },
    },
    {
      name: "TradedRightType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Buy",
          },
          {
            name: "Sell",
          },
          {
            name: "BuyReserved",
          },
          {
            name: "SellReserved",
          },
        ],
      },
    },
    {
      name: "UpdateAllocationAction",
      type: {
        kind: "enum",
        variants: [
          {
            name: "UpdateCustomAllocation",
          },
          {
            name: "RemoveCustomAllocation",
          },
          {
            name: "UpdateEqual",
          },
        ],
      },
    },
    {
      name: "FundraiseAction",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Create",
            fields: ["u64"],
          },
          {
            name: "Finish",
          },
        ],
      },
    },
    {
      name: "GovernanceType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Treasury",
          },
          {
            name: "Withdrawal",
          },
          {
            name: "Transfer",
          },
          {
            name: "SellPermission",
          },
          {
            name: "GovernanceChange",
          },
        ],
      },
    },
    {
      name: "UpdateVoterWeightAction",
      type: {
        kind: "enum",
        variants: [
          {
            name: "ClubAction",
            fields: [
              {
                defined: "ClubAction",
              },
            ],
          },
          {
            name: "TreasuryAction",
            fields: [
              {
                defined: "TreasuryAction",
              },
            ],
          },
        ],
      },
    },
    {
      name: "VoteThresholdPercentage",
      type: {
        kind: "enum",
        variants: [
          {
            name: "YesVote",
            fields: ["u8"],
          },
          {
            name: "Quorum",
            fields: ["u8"],
          },
        ],
      },
    },
    {
      name: "VoteTipping",
      docs: [
        "The type of vote tipping to use on a Proposal.",
        "",
        "Vote tipping means that under some conditions voting will complete early.",
      ],
      type: {
        kind: "enum",
        variants: [
          {
            name: "Strict",
          },
          {
            name: "Early",
          },
          {
            name: "Disabled",
          },
        ],
      },
    },
    {
      name: "MemberStatus",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Uninvited",
          },
          {
            name: "Pending",
          },
          {
            name: "Accepted",
          },
          {
            name: "Rejected",
          },
          {
            name: "Cancelled",
          },
        ],
      },
    },
    {
      name: "ProposalType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "BuyP2P",
          },
          {
            name: "SellP2P",
          },
          {
            name: "BuyNowMagicEden",
          },
          {
            name: "SellMagicEden",
          },
          {
            name: "TransferFunds",
          },
          {
            name: "Discussion",
          },
          {
            name: "Withdrawal",
          },
          {
            name: "SellSolsea",
          },
          {
            name: "BuySolsea",
          },
          {
            name: "UpdateGovernanceConfig",
          },
          {
            name: "UpdateRoleConfig",
          },
          {
            name: "CreateFundraise",
          },
          {
            name: "AddSellPermission",
          },
        ],
      },
    },
    {
      name: "ProposalStatus",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Pending",
          },
          {
            name: "CreatedBuyP2P",
          },
          {
            name: "CreatedSellP2P",
          },
          {
            name: "ExecutedBuyP2P",
          },
          {
            name: "ExecutedSellP2P",
          },
          {
            name: "CreatedBuyNowMagicEden",
          },
          {
            name: "ExecutedBuyNowMagicEden",
          },
          {
            name: "FinishedBuyNowMagicEden",
          },
          {
            name: "CreatedSellMagicEden",
          },
          {
            name: "ExecutedSellMagicEden",
          },
          {
            name: "FinishedSellMagicEden",
          },
          {
            name: "CreatedTransferFunds",
          },
          {
            name: "ExecutedTransferFunds",
          },
          {
            name: "CreatedDiscussion",
          },
          {
            name: "CreatedWithdrawal",
          },
          {
            name: "ExecutedWithdrawal",
          },
          {
            name: "CancelledByOwner",
          },
          {
            name: "CreatedUpdateGovernanceConfig",
          },
          {
            name: "ExecutedUpdateGovernanceConfig",
          },
          {
            name: "CreatedUpdateRoleConfig",
          },
          {
            name: "ExecutedUpdateRoleConfig",
          },
          {
            name: "ExecutedSolseaBuy",
          },
          {
            name: "ExecutedSolseaSell",
          },
          {
            name: "CreatedMetadata",
          },
          {
            name: "ProposalCreated",
          },
          {
            name: "ProposalPreparing",
          },
          {
            name: "ProposalReady",
          },
          {
            name: "ProposalExecuted",
          },
        ],
      },
    },
    {
      name: "ProposalAction",
      type: {
        kind: "enum",
        variants: [
          {
            name: "BuyNFT",
          },
          {
            name: "SellNFT",
          },
        ],
      },
    },
    {
      name: "ProposalUpdateStatus",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Updating",
          },
          {
            name: "Finished",
          },
          {
            name: "Close",
          },
        ],
      },
    },
    {
      name: "StakePeriodType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "OneMonth",
          },
          {
            name: "ThreeMonths",
          },
          {
            name: "SixMonths",
          },
          {
            name: "TwelveMonths",
          },
        ],
      },
    },
    {
      name: "StakeStatus",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Funding",
          },
          {
            name: "InProgress",
          },
          {
            name: "Finished",
          },
          {
            name: "Canceled",
          },
        ],
      },
    },
    {
      name: "StakeOption",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Ft",
          },
          {
            name: "Nft",
          },
        ],
      },
    },
    {
      name: "UNQNftRarity",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Common",
          },
          {
            name: "Rare",
          },
          {
            name: "Epic",
          },
          {
            name: "Legendary",
          },
        ],
      },
    },
    {
      name: "TreasuryAction",
      type: {
        kind: "enum",
        variants: [
          {
            name: "CastVote",
          },
          {
            name: "CreateDiscussionProposal",
          },
          {
            name: "CreateP2PProposal",
          },
          {
            name: "CreateWithdrawalProposal",
          },
          {
            name: "CreateTransferProposal",
          },
          {
            name: "CreateMeProposal",
          },
          {
            name: "SignOffProposal",
          },
          {
            name: "CancelProposal",
          },
          {
            name: "CancelP2POffer",
          },
          {
            name: "Fundraise",
          },
          {
            name: "Distribute",
          },
          {
            name: "CreateFinancialOffer",
          },
          {
            name: "AcceptFinancialOffer",
          },
          {
            name: "CreateSolseaProposal",
          },
          {
            name: "UpdateGovernanceConfig",
          },
          {
            name: "UpdateRoleConfig",
          },
          {
            name: "AddReservedRights",
          },
          {
            name: "UpdateAllocation",
          },
          {
            name: "AddReservedRightsToSelf",
          },
        ],
      },
    },
    {
      name: "AllowType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Club",
          },
          {
            name: "Treasury",
          },
        ],
      },
    },
    {
      name: "Rarity",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Common",
          },
          {
            name: "Rare",
          },
          {
            name: "Epic",
          },
          {
            name: "Legendary",
          },
        ],
      },
    },
    {
      name: "VoterWeightAction",
      type: {
        kind: "enum",
        variants: [
          {
            name: "CastVote",
          },
          {
            name: "CommentProposal",
          },
          {
            name: "CreateGovernance",
          },
          {
            name: "CreateProposal",
          },
          {
            name: "SignOffProposal",
          },
        ],
      },
    },
  ],
  events: [
    {
      name: "WhitelistingEvent",
      fields: [
        {
          name: "user",
          type: {
            vec: "publicKey",
          },
          index: false,
        },
        {
          name: "newStatus",
          type: {
            defined: "WhitelistingAction",
          },
          index: false,
        },
      ],
    },
    {
      name: "UpdateMemerEvent",
      fields: [
        {
          name: "clubAddress",
          type: "publicKey",
          index: false,
        },
        {
          name: "member",
          type: "publicKey",
          index: false,
        },
        {
          name: "isMember",
          type: {
            option: "bool",
          },
          index: false,
        },
        {
          name: "status",
          type: {
            option: {
              defined: "MemberStatus",
            },
          },
          index: false,
        },
        {
          name: "role",
          type: {
            option: "string",
          },
          index: false,
        },
        {
          name: "hasLeft",
          type: {
            option: "bool",
          },
          index: false,
        },
      ],
    },
    {
      name: "AcceptOfferEvent",
      fields: [
        {
          name: "proposalAddress",
          type: "publicKey",
          index: false,
        },
        {
          name: "offerAccount",
          type: "publicKey",
          index: false,
        },
      ],
    },
    {
      name: "InvitationEvent",
      fields: [
        {
          name: "clubData",
          type: "publicKey",
          index: false,
        },
        {
          name: "invitedMemberDatas",
          type: {
            vec: "publicKey",
          },
          index: false,
        },
      ],
    },
    {
      name: "FundraiseEvent",
      fields: [
        {
          name: "fundraiseConfig",
          type: "publicKey",
          index: false,
        },
        {
          name: "treasuryDataAddress",
          type: "publicKey",
          index: false,
        },
      ],
    },
    {
      name: "TreasuryRoleEvent",
      fields: [
        {
          name: "clubData",
          type: "publicKey",
          index: false,
        },
        {
          name: "invitedMemberDatas",
          type: {
            vec: "publicKey",
          },
          index: false,
        },
      ],
    },
    {
      name: "StakeEvent",
      fields: [
        {
          name: "stakeConfig",
          type: "publicKey",
          index: false,
        },
        {
          name: "stakeRecord",
          type: {
            option: "publicKey",
          },
          index: false,
        },
        {
          name: "clubData",
          type: {
            option: "publicKey",
          },
          index: false,
        },
      ],
    },
    {
      name: "CreateClubEvent",
      fields: [
        {
          name: "clubData",
          type: "publicKey",
          index: false,
        },
      ],
    },
    {
      name: "CreateTreasuryEvent",
      fields: [
        {
          name: "treasuryData",
          type: "publicKey",
          index: false,
        },
      ],
    },
    {
      name: "CreateMemberDataEvent",
      fields: [
        {
          name: "memberData",
          type: {
            vec: "publicKey",
          },
          index: false,
        },
      ],
    },
    {
      name: "AcceptMemberEvent",
      fields: [
        {
          name: "clubData",
          type: "publicKey",
          index: false,
        },
        {
          name: "invitedMemberDatas",
          type: {
            vec: "publicKey",
          },
          index: false,
        },
      ],
    },
    {
      name: "UpdateAllocationEvent",
      fields: [
        {
          name: "fundraiseConfig",
          type: "publicKey",
          index: false,
        },
        {
          name: "treasuryDataAddress",
          type: "publicKey",
          index: false,
        },
      ],
    },
    {
      name: "SupportClubEvent",
      fields: [
        {
          name: "financialRecord",
          type: "publicKey",
          index: false,
        },
        {
          name: "fundraiseConfig",
          type: "publicKey",
          index: false,
        },
        {
          name: "treasuryData",
          type: "publicKey",
          index: false,
        },
      ],
    },
    {
      name: "ReserveRightsEvent",
      fields: [
        {
          name: "treasuryData",
          type: "publicKey",
          index: false,
        },
      ],
    },
    {
      name: "CancelInvitationEvent",
      fields: [
        {
          name: "clubData",
          type: "publicKey",
          index: false,
        },
        {
          name: "invitedMemberDatas",
          type: {
            vec: "publicKey",
          },
          index: false,
        },
        {
          name: "isDecline",
          type: "bool",
          index: false,
        },
      ],
    },
    {
      name: "FinancialOfferEvent",
      fields: [
        {
          name: "treasuryDataAddress",
          type: "publicKey",
          index: false,
        },
        {
          name: "financialRecordAddress",
          type: "publicKey",
          index: false,
        },
        {
          name: "financialOfferAddress",
          type: "publicKey",
          index: false,
        },
        {
          name: "buyerFinancialRecord",
          type: {
            option: "publicKey",
          },
          index: false,
        },
        {
          name: "sellerFinancialRecord",
          type: {
            option: "publicKey",
          },
          index: false,
        },
        {
          name: "amount",
          type: {
            option: "u64",
          },
          index: false,
        },
        {
          name: "clubData",
          type: "publicKey",
          index: false,
        },
        {
          name: "isCancel",
          type: "bool",
          index: false,
        },
        {
          name: "dedicatedBuyer",
          type: {
            option: "publicKey",
          },
          index: false,
        },
      ],
    },
    {
      name: "ProposalEvent",
      fields: [
        {
          name: "proposalMetadata",
          type: "publicKey",
          index: false,
        },
        {
          name: "realmAddress",
          type: "publicKey",
          index: false,
        },
        {
          name: "clubAddress",
          type: "publicKey",
          index: false,
        },
      ],
    },
    {
      name: "CreateGovernanceEvent",
      fields: [
        {
          name: "treasuryData",
          type: "publicKey",
          index: false,
        },
      ],
    },
    {
      name: "DepositWithdrawEvent",
      fields: [
        {
          name: "memberAddress",
          type: "publicKey",
          index: false,
        },
        {
          name: "treasuryAddress",
          type: "publicKey",
          index: false,
        },
        {
          name: "supportType",
          type: {
            defined: "SupportType",
          },
          index: false,
        },
      ],
    },
    {
      name: "ProposalCastVoteEvent",
      fields: [
        {
          name: "proposalMetadata",
          type: "publicKey",
          index: false,
        },
      ],
    },
    {
      name: "ProposalCreateEvent",
      fields: [
        {
          name: "proposalMetadata",
          type: "publicKey",
          index: false,
        },
      ],
    },
  ],
  errors: [
    {
      code: 6000,
      name: "WrongSplGovProgram",
      msg: "Wrong spl gov id!",
    },
    {
      code: 6001,
      name: "WrongEscrowProgram",
      msg: "Wrong escrow id!",
    },
    {
      code: 6002,
      name: "WrongUNQPubKey",
      msg: "Wrong UNQ mint pub key!",
    },
    {
      code: 6003,
      name: "WrongPermissionLength",
      msg: "Wrong length of Permission config!",
    },
    {
      code: 6004,
      name: "NotAMember",
      msg: "You are not allowed to make deposits!",
    },
    {
      code: 6005,
      name: "InvalidMemberStatus",
      msg: "Invalid member status, valid values are: 0,1,2",
    },
    {
      code: 6006,
      name: "NotEnoughFunds",
      msg: "Not enough funds",
    },
    {
      code: 6007,
      name: "InvalidAuthority",
      msg: "Not a valid authority",
    },
    {
      code: 6008,
      name: "InvalidMint",
      msg: "Invalid mint provided",
    },
    {
      code: 6009,
      name: "InvalidPerformanceFeeConfig",
      msg: "Performance fee configuration is invalid!",
    },
    {
      code: 6010,
      name: "TimeInPast",
      msg: "Time in past",
    },
    {
      code: 6011,
      name: "StartAfterTheEndTime",
      msg: "Start after end time",
    },
    {
      code: 6012,
      name: "FundraiseInvalidAmount",
      msg: "Invalid fundraise amount",
    },
    {
      code: 6013,
      name: "FundraiseAmountAlreadyMet",
      msg: "Fundraise amount already met",
    },
    {
      code: 6014,
      name: "FundraiseHasEnded",
      msg: "Fundraise has ended",
    },
    {
      code: 6015,
      name: "FundraiseStillInProgress",
      msg: "Fundraise still in progress",
    },
    {
      code: 6016,
      name: "InvalidClubTypeStatus",
      msg: "Invalid club type, valid values are: 0,1,2,3",
    },
    {
      code: 6017,
      name: "InvalidGovernance",
      msg: "Invalid governance address",
    },
    {
      code: 6018,
      name: "SellPermissionNotSatisfied",
      msg: "Sell permission not satisfied",
    },
    {
      code: 6019,
      name: "InvalidCancelAuthority",
      msg: "You do not have the permission to cancel this invitation",
    },
    {
      code: 6020,
      name: "InvalidTreasury",
      msg: "Invalid treasury address",
    },
    {
      code: 6021,
      name: "InvalidRole",
      msg: "Invalid role to perform this action",
    },
    {
      code: 6022,
      name: "InvalidVoterWeightAction",
      msg: "Invalid voter weight action",
    },
    {
      code: 6023,
      name: "VoterWeightActionNotAllowed",
      msg: "Voter weight action not allowed",
    },
    {
      code: 6024,
      name: "BelowMinimumVoteTreshold",
      msg: "Below minimum vote treshold",
    },
    {
      code: 6025,
      name: "BelowMinimumVoteTime",
      msg: "Below minimum vote time",
    },
    {
      code: 6026,
      name: "MemberKeysMissmatch",
      msg: "Member data PDA sent doesn't match the one generated on Solana",
    },
    {
      code: 6027,
      name: "MemberAlreadyExists",
      msg: "Member data PDA has already been initialized",
    },
    {
      code: 6028,
      name: "InvalidProposalAction",
      msg: "Invalid proposal action",
    },
    {
      code: 6029,
      name: "VoteNotAllowed",
      msg: "Member not allowed to vote on this proposal",
    },
    {
      code: 6030,
      name: "MaxOrProposalRemainingAccountsMissing",
      msg: "Max Voter Weight Record and Proposal need to be passed as remaining accounts to cast a vote",
    },
    {
      code: 6031,
      name: "WrongProposalAddress",
      msg: "Wrong proposal account passed as remaining account",
    },
    {
      code: 6032,
      name: "WrongMaxVoterWeightRecord",
      msg: "Wrong MaxVoterWeightRecord account passed as remaining account",
    },
    {
      code: 6033,
      name: "InvalidVoterWeightProgram",
      msg: "Invalid voter weight program id",
    },
    {
      code: 6034,
      name: "WrongFundraiseConfig",
      msg: "Wrong fundraise config",
    },
    {
      code: 6035,
      name: "FundraiseRemainingAccountMissing",
      msg: "Fundraise config needs to be passed as remaining account",
    },
    {
      code: 6036,
      name: "InvalidAuthorityToCreateGovernance",
      msg: "Invalid authority to create governance: Only owner of the club can create governance!",
    },
    {
      code: 6037,
      name: "GovernanceCreationAfterFundraise",
      msg: "The governance over treasury must be defined before the fundraise",
    },
    {
      code: 6038,
      name: "WrongGovernanceAccount",
      msg: "Wrong or uninitialized governance account provided to create_fundraise ix",
    },
    {
      code: 6039,
      name: "ProposalAlreadyInitialized",
      msg: "Proposal aready initialized",
    },
    {
      code: 6040,
      name: "ProposalMetadataLengthOverflow",
      msg: "Proposal metadata string length overflow",
    },
    {
      code: 6041,
      name: "FundraiseAmountExceeded",
      msg: "Fundraise amount exceeded",
    },
    {
      code: 6042,
      name: "InvalidNftAccount",
      msg: "Invalid NFT account provided",
    },
    {
      code: 6043,
      name: "NftNotOnTheProvidedAccount",
      msg: "Invalid NFT account provided",
    },
    {
      code: 6044,
      name: "CollectionMustBeVerified",
      msg: "Collection must be verified",
    },
    {
      code: 6045,
      name: "CollectionMissing",
      msg: "Collection missing",
    },
    {
      code: 6046,
      name: "InvalidAccountOwner",
      msg: "Invalid account owner",
    },
    {
      code: 6047,
      name: "InvalidTokenMetadataAccount",
      msg: "Invalid token metadata account",
    },
    {
      code: 6048,
      name: "TokenMetadataDoesNotMatch",
      msg: "Token metadata does not match",
    },
    {
      code: 6049,
      name: "OnlyForNftBasedClubs",
      msg: "Only callable for nft based clubs",
    },
    {
      code: 6050,
      name: "InvalidCollection",
      msg: "Invalid collection",
    },
    {
      code: 6051,
      name: "NftAlreadyVoted",
      msg: "Nft already voted on",
    },
    {
      code: 6052,
      name: "MissingRemainingAccounts",
      msg: "Missing remaining accounts",
    },
    {
      code: 6053,
      name: "ProposalMetadataAlreadyInitialized",
      msg: "Proposal metadata aready initialized",
    },
    {
      code: 6054,
      name: "InvalidSellPermissionGovernanceAccount",
      msg: "Invalid sell permission governance account",
    },
    {
      code: 6055,
      name: "SellPermissionAmountNotSatisfied",
      msg: "Sell permission amount not satisfied",
    },
    {
      code: 6056,
      name: "SellPermissionGovernanceOnlyForBuyAction",
      msg: "Sell permission governance only for Buy action",
    },
    {
      code: 6057,
      name: "AccountClosingErr",
      msg: "Couldn't transfer SOLs from profit_token to profit",
    },
    {
      code: 6058,
      name: "WithdrawalGovernanceAlreadyCreated",
      msg: "Withdrawal governance for this club has already been created",
    },
    {
      code: 6059,
      name: "StakingInProgress",
      msg: "Staking already in progress",
    },
    {
      code: 6060,
      name: "InvalidStakeAmount",
      msg: "Invalid stake amount parameter",
    },
    {
      code: 6061,
      name: "InvalidStakeStartDate",
      msg: "Invalid stake start date: Must select current date or future date",
    },
    {
      code: 6062,
      name: "InvalidStakeEndDate",
      msg: "Invalid stake end date: Stake-funding period must last at least one day",
    },
    {
      code: 6063,
      name: "StakeCapOverflow",
      msg: "Stake cap overflow",
    },
    {
      code: 6064,
      name: "NotEnoughUnqTokens",
      msg: "Not Enought UNQ Tokens",
    },
    {
      code: 6065,
      name: "StakingHasntStartedYet",
      msg: "Staking funding period hasn't started yet",
    },
    {
      code: 6066,
      name: "StakingFundingEnded",
      msg: "Staking funding period has ended",
    },
    {
      code: 6067,
      name: "UnstakePeriodHasntEnded",
      msg: "Unstake period is three weeks, and it hasn't ended",
    },
    {
      code: 6068,
      name: "StakingWillBeOver",
      msg: "Staking will be over by the time, unstaking period has ended",
    },
    {
      code: 6069,
      name: "InvalidDepositAmount",
      msg: "Invalid deposit amount",
    },
    {
      code: 6070,
      name: "MaxNumberOfFinancialRecordsExceeded",
      msg: "Cannot support with less than 1 tokens",
    },
    {
      code: 6071,
      name: "MaxNumberOfActiveStakeRecords",
      msg: "Exceeds max number of active stakes",
    },
    {
      code: 6072,
      name: "MaxStakeNameExceeded",
      msg: "Max stake name exceeded",
    },
    {
      code: 6073,
      name: "MaxCapExceeded",
      msg: "Max cap exceeded!",
    },
    {
      code: 6074,
      name: "StakingCanceled",
      msg: "Staking canceled!",
    },
    {
      code: 6075,
      name: "AlreadyUnstaked",
      msg: "Already unstaked!",
    },
    {
      code: 6076,
      name: "CannotClaimBeforeFundingPeriodIsOver",
      msg: "Cannot claim before stake funding period is over!",
    },
    {
      code: 6077,
      name: "StakePeriodHasntEnded",
      msg: "Stake period hasn't ended",
    },
    {
      code: 6078,
      name: "StakeStatusNeedsToBeInProgress",
      msg: "Stake status needs to be InProgress",
    },
    {
      code: 6079,
      name: "WrongRewardTokenAccount",
      msg: "Stake status needs to be InProgress",
    },
    {
      code: 6080,
      name: "NotFundingStatus",
      msg: "StakeConfig must be in Funding status to be changed to InProgress",
    },
    {
      code: 6081,
      name: "SellPermissionConfigFull",
      msg: "Sell permission config full",
    },
    {
      code: 6082,
      name: "WrongProposalUpdateAuthorority",
      msg: "Wrong proposal update authority",
    },
    {
      code: 6083,
      name: "InvalidDepositRecordIndex",
      msg: "The index of DepositRecord seed must be leq than fundraise count at proposal init time",
    },
    {
      code: 6084,
      name: "WrongDepositRecordOwner",
      msg: "The payer of the transaction is not the owner of DepositRecord",
    },
    {
      code: 6085,
      name: "WrongDepositRecordKey",
      msg: "The payer of the transaction is not the owner of DepositRecord",
    },
    {
      code: 6086,
      name: "InvalidMaxAmountPerFundraise",
      msg: "The maximum amount of SOLs per fundraise is 200",
    },
    {
      code: 6087,
      name: "MaxNumberOfMembersExceeded",
      msg: "The maximum amount of members per club is 99",
    },
    {
      code: 6088,
      name: "InvalidMinAmountPerFundraise",
      msg: "The minimum amount of SOLs per fundraise is 200",
    },
    {
      code: 6089,
      name: "WrongOwnerOfTokenAccount",
      msg: "Wrong owner of NFT Token Account",
    },
    {
      code: 6090,
      name: "SpaceAlreadyAllocated",
      msg: "Space already reallocated",
    },
    {
      code: 6091,
      name: "WrongAmountOnTheNftAccount",
      msg: "The amount of token on the NFT Token Account must be 1",
    },
    {
      code: 6092,
      name: "WrongNftMintForStaking",
      msg: "Mint account doesn't match the token account provided for staking",
    },
    {
      code: 6093,
      name: "WrongUniverseMetadataForMint",
      msg: "UniverseMetadata doesn't match the provided mint",
    },
    {
      code: 6094,
      name: "UniverseLevelLow",
      msg: "Universe level too low to stake",
    },
    {
      code: 6095,
      name: "NftStakeAccountInitialized",
      msg: "The PDA of the TokenAccount storing NFT is already initialized",
    },
    {
      code: 6096,
      name: "WrongStakedNftAddress",
      msg: "The PDA of the TokenAccount storing NFT is wrong",
    },
    {
      code: 6097,
      name: "LowerRarity",
      msg: "Universe NFT must be of higher rarity than the one already staked",
    },
    {
      code: 6098,
      name: "NftClaimed",
      msg: "This NFT was already claimed",
    },
    {
      code: 6099,
      name: "WrongStakedTokensAddress",
      msg: "The PDA of the TokenAccount storing NFT is wrong",
    },
    {
      code: 6100,
      name: "CannotTransferOldStakedNft",
      msg: "Failed to transfer old NFT to its owner",
    },
    {
      code: 6101,
      name: "WrongNftReceiverTokenAccount",
      msg: "Wrong NFT receiver TokenAccount passed",
    },
    {
      code: 6102,
      name: "WrongOwnerOfStakedNft",
      msg: "Wrong owner of the staked NFT",
    },
    {
      code: 6103,
      name: "AlreadyClaimed",
      msg: "Already claimed your tokens",
    },
    {
      code: 6104,
      name: "WrongStakeRecordOwner",
      msg: "Wrong stake record owner",
    },
    {
      code: 6105,
      name: "InvalidFinancialRecordAmount",
      msg: "Invalid financial record amount",
    },
    {
      code: 6106,
      name: "WrongCancelFinancialOfferAuthority",
      msg: "Signer has no authority to cancel this financial offer",
    },
    {
      code: 6107,
      name: "WrongFinancialBuyerTokenMint",
      msg: "Wrong financial buyer token mint",
    },
    {
      code: 6108,
      name: "NotEnoughTokensToAcceptFinancialOffer",
      msg: "Not enough tokens to accept financial offer",
    },
    {
      code: 6109,
      name: "CannotCreateFinancialOffer",
      msg: "Cannot create financial offer while a fundraise is active",
    },
    {
      code: 6110,
      name: "NotDesignatedBuyerForFinancialOffer",
      msg: "You are not the designated buyer for this financial offer",
    },
    {
      code: 6111,
      name: "FinancialOffersNotZero",
      msg: "There cannot be any active financial offers when starting a fundraise",
    },
    {
      code: 6112,
      name: "WrongMagicEdenAccountsHolder",
      msg: "Wrong accounts holder for BuyNow on MagicEden",
    },
    {
      code: 6113,
      name: "NoOfferAccountForMagicEden",
      msg: "Wrong PDA passed as offer address for MagicEden BuyNow",
    },
    {
      code: 6114,
      name: "NoWantedTokenMintForMagicEdenBuyNow",
      msg: "Wrong wanted_token_mint passed as remaining account passed as remaining account",
    },
    {
      code: 6115,
      name: "NoEscrowedWantedTokenAccountForMagicEden",
      msg: "Wrong escrowed_wanted_token account passed as remaining account for BuyNow",
    },
    {
      code: 6116,
      name: "NoOfferedTokenMintForMagicEden",
      msg: "No offered_token_mint passed as remaining account",
    },
    {
      code: 6117,
      name: "NoMakerWantedTokenForMagicEden",
      msg: "No maker_wanted_token passed as remaining account",
    },
    {
      code: 6118,
      name: "NoTokenProgramForMagicEden",
      msg: "No token_program passed as remaining account",
    },
    {
      code: 6119,
      name: "InvalidFinancialRightsAmount",
      msg: "Invalid amount of financial rights to buy",
    },
    {
      code: 6120,
      name: "FinancialRightsAmountExceeded",
      msg: "You do not have enough financial rights",
    },
    {
      code: 6121,
      name: "WrongMeBuyNowMaker",
      msg: "This is not the treasury that made this offer",
    },
    {
      code: 6122,
      name: "WrongMeSellMaker",
      msg: "This is not the vault that made this offer",
    },
    {
      code: 6123,
      name: "ProposalToBuyNowNotExecuted",
      msg: "Proposal to buy on magic eden needs to be executed",
    },
    {
      code: 6124,
      name: "ProposalToSellNotExecuted",
      msg: "Proposal to sell on magic eden needs to be executed",
    },
    {
      code: 6125,
      name: "FailedToParseDenominatedCurrencyAsMint",
      msg: "Wrong denominated currency token account mint",
    },
    {
      code: 6126,
      name: "WrongDenominatedCurrencyTokenAccountMint",
      msg: "Wrong denominated currency token account mint",
    },
    {
      code: 6127,
      name: "WrongDenominatedTokenAddress",
      msg: "Wrong denominated token address",
    },
    {
      code: 6128,
      name: "FailedToSupportClub",
      msg: "Failed to support club",
    },
    {
      code: 6129,
      name: "TransferGovernanceAlreadyCreated",
      msg: "Transfer governance for this club has already been created",
    },
    {
      code: 6130,
      name: "InvalidAuthorityToInviteMember",
      msg: "Not a valid authority to invite a member",
    },
    {
      code: 6131,
      name: "InvalidAuthorityToCancelOffer",
      msg: "Invalid authority to cancel P2P offer",
    },
    {
      code: 6132,
      name: "InvalidAuthorityToCreateFundraise",
      msg: "Invalid authority to create fundraise",
    },
    {
      code: 6133,
      name: "InvalidRoleToSupport",
      msg: "Invalid role to perform the support club action",
    },
    {
      code: 6134,
      name: "InvalidAuthorityToFinishStaking",
      msg: "Invalid role to finish staking",
    },
    {
      code: 6135,
      name: "InvalidAuthorityToInitializeStaking",
      msg: "Invalid role to initialize staking",
    },
    {
      code: 6136,
      name: "InvalidAuthorityToUpdateAllocation",
      msg: "Invalid authority to update allocation",
    },
    {
      code: 6137,
      name: "InvalidClubType",
      msg: "Club type not implemented",
    },
    {
      code: 6138,
      name: "InvalidMintOfNftToBuy",
      msg: "The mint of the NFT that the offer was made for is different",
    },
    {
      code: 6139,
      name: "WrongAmountOfOfferedToken",
      msg: "Wrong amount of offered token",
    },
    {
      code: 6140,
      name: "CanNotUpdateGovernanceConfig",
      msg: "Can not update governance with proposals in voting state",
    },
    {
      code: 6141,
      name: "UpdateGovernanceProposalActive",
      msg: "Update governance proposal active",
    },
    {
      code: 6142,
      name: "InvalidProposalState",
      msg: "Invalid proposal state",
    },
    {
      code: 6143,
      name: "ChangeClubConfigGovernanceAlredyCreated",
      msg: "Change club config governance alredy created",
    },
    {
      code: 6144,
      name: "InvalidAuthorityToStartStaking",
      msg: "Invalid role to start staking",
    },
    {
      code: 6145,
      name: "InvalidDepositRecordState",
      msg: "Invalid DepositRecord State",
    },
    {
      code: 6146,
      name: "ReservedRightsCannotBeOverridden",
      msg: "Reserved rights cannot be overridden",
    },
    {
      code: 6147,
      name: "ReservedRightsCannotSetAfterFundraise",
      msg: "Reserved rights cannot be set after fundraise",
    },
    {
      code: 6148,
      name: "WrongTreasuryForClub",
      msg: "Wrong treasury for club",
    },
    {
      code: 6149,
      name: "NotUniqueIndividualRight",
      msg: "Each pubkey for which individual rights is reserved must be unique",
    },
    {
      code: 6150,
      name: "IndividualRightNotExists",
      msg: "Individual right for authority does not exist",
    },
    {
      code: 6151,
      name: "NotEnoughVoteRights",
      msg: "Not enought vote rights to vote on this proposal",
    },
    {
      code: 6152,
      name: "CannotAddAndRemoveAllocation",
      msg: "You cannot add and remove allocation at the same time",
    },
    {
      code: 6153,
      name: "InvalidFundraiseCapAmount",
      msg: "Invalid fundraise cap amount",
    },
    {
      code: 6154,
      name: "WrongGovernanceForProposalType",
      msg: "The passed governance doesn't match the proposal type",
    },
    {
      code: 6155,
      name: "WrongProposalStatus",
      msg: "Proposal status error",
    },
    {
      code: 6156,
      name: "WrongOfferStatus",
      msg: "Offer status error",
    },
    {
      code: 6157,
      name: "NoActiveProposalsForGovernanceAllowed",
      msg: "No active proposals for governance allowed",
    },
    {
      code: 6158,
      name: "InvalidRealmForClub",
      msg: "Realm isn't valid for club",
    },
    {
      code: 6159,
      name: "NoActiveProposalsAllowed",
      msg: "There should be no active proposals",
    },
    {
      code: 6160,
      name: "WrongProposalTypeForInstruction",
      msg: "Cannot insert that instruction in this type of proposal",
    },
    {
      code: 6161,
      name: "VotingProposalExists",
      msg: "Voting proposals exist in this club",
    },
    {
      code: 6162,
      name: "InvalidFinancialRecord",
      msg: "Invalid financial record",
    },
    {
      code: 6163,
      name: "InvalidTreasuryCount",
      msg: "Invalid treasury count",
    },
    {
      code: 6164,
      name: "InvalidFinancialPower",
      msg: "Invalid financial power",
    },
    {
      code: 6165,
      name: "InvalidWithdrawal",
      msg: "Invalid withdraw",
    },
    {
      code: 6166,
      name: "InvalidWithdrawalAmount",
      msg: "Invalid withdraw amount",
    },
    {
      code: 6167,
      name: "InvalidTokenOwnerRecord",
      msg: "Invalid token owner record provided",
    },
    {
      code: 6168,
      name: "InvalidClubName",
      msg: "Invalid club name",
    },
    {
      code: 6169,
      name: "WrongCommunityWeightAddinProgram",
      msg: "Community weight addin program is wrong",
    },
    {
      code: 6170,
      name: "InvalidPercentage",
      msg: "Invalid percentage amount",
    },
    {
      code: 6171,
      name: "MemberNotWhitelisted",
      msg: "Member not added to whitelistings",
    },
    {
      code: 6172,
      name: "AdminDoesntExist",
      msg: "Admin does not exist",
    },
    {
      code: 6173,
      name: "InvalidInstructionForClub",
      msg: "Invalid instruction for club",
    },
    {
      code: 6174,
      name: "SellPermissionRangeOverlap",
      msg: "Sell permission range overlap",
    },
    {
      code: 6175,
      name: "MaxNumberOfSellPermissionExceeded",
      msg: "Sell permission number exceeded",
    },
    {
      code: 6176,
      name: "InvalidSellPermissionRange",
      msg: "Sell permission from must be less than to",
    },
    {
      code: 6177,
      name: "NotTheFirstFundraise",
      msg: "Non-first fundraises must be created through a proposal",
    },
  ],
};
