import solana from "../../assets/Solana.png";
import solanaToken from "../../assets/solanatoken.png";
import usdc from "../../assets/usdc.png";
import dai from "../../assets/dai.png";
import ethereum from "../../assets/ethereum.png";
import polygon from "../../assets/polygon.png";
import bucd from "../../assets/bucd.png";
import shiba from "../../assets/shiba.png";
import doge from "../../assets/doge.png";
import bitcoin from "../../assets/bitcoin.png";
import { Chain } from "../enums/common.enum";
import { BLUE400, GREEN400, PURPLE500 } from "./layout.constants";
import telegramIcon from "../../assets/telegram.svg";
import discordIcon from "../../assets/discord.svg";
import mailIcon from "../../assets/mail.svg";
import { IOption, ISortOption } from "../interfaces/common.interface";
import { DefaultExplorer, SocialNetworkType } from "../enums/clubs.enum";
import solScanLogo from "../../assets/solscan.png";
import solExplorerLogo from "../../assets/solexplorer.png";
import solFmLogo from "../../assets/solfm.png";

export const MAINNET_ID = 101;
export const DEVNET_ID = 103;

export const EMPTY_STRING = "";
export const MAX_IMAGE_SIZE = 2000000;
export const ITEMS_PER_PAGE = 5;
export const FIRST_TOKENS_IN_LIST = 8;
export const DEFAULT_TIMEOUT = 30000;
export const DEPOSIT_FEE = 0.00494888;
export const NATIVE_MINT_DECIMALS = 9;

export const POLL_INTERVAL = 5000;

export const MAX_NUM_OF_MEMBERS_WITH_FOUNDER = 99;
export const MAX_NUM_OF_MEMBERS_WITHOUT_FOUNDER = 98;

export const BASIC_LAZY_QUERY_OPTIONS = {
  pollInterval: POLL_INTERVAL,
};

export const CHAINS = [
  {
    chain: Chain.Solana,
    image: solana,
    alt: "Solana blockchain",
  },
  {
    chain: Chain.Polygon,
    image: polygon,
    alt: "Polygon blockchain",
  },
  {
    chain: Chain.Ethereum,
    image: ethereum,
    alt: "Ethereum blockchain",
  },
];

export const TOKENS = [
  { name: "Solana", image: solanaToken, alt: "Solana token" },
  { name: "USDC", image: usdc, alt: "USDC token" },
  { name: "Dai", image: dai, alt: "Dai token" },
  { name: "Ethereum", image: ethereum, alt: "Ethereum token" },
  { name: "Polygon", image: polygon, alt: "Polygon token" },
  { name: "BUSD", image: bucd, alt: "BUSD token" },
  { name: "Shiba", image: shiba, alt: "Shiba token" },
  { name: "Doge", image: doge, alt: "Doge token" },
  { name: "Bitcoin", image: bitcoin, alt: "Bitcoin token" },
];

export const TRANSACTION_ERROR_IDENTIFIER = "Error Message: ";
export const GOVERNANCE_ERROR_IDENTIFIER = "GOVERNANCE-ERROR: ";
export const TRANSFER_ERROR_IDENTIFIER = "Transfer: ";
export const INVALID_ACCOUNT_STATE_ERROR = "Invalid account state.";
export const SHARE_ICONS = [
  {
    image: telegramIcon,
    background: BLUE400,
    alt: "Telegram logo",
  },
  {
    image: discordIcon,
    background: PURPLE500,
    alt: "Discord logo",
  },
  {
    image: mailIcon,
    background: GREEN400,
    alt: "Envelope",
  },
];

export const chainOptions: IOption[] = [
  {
    label: "Solana",
    value: "Solana",
  },
  //TODO: uncomment after ethereum impl
  // {
  //   label: "Ethereum",
  //   value: "Ethereum",
  // },
];

export const MESSAGE_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
};

export const SOLSCAN_URL = "https://solscan.io";
export const DEVNET_CLUSTER = "cluster=devnet";
export const MAINNET_CLUSTER = "cluster=mainnet";
export const ADDRESS = "address";
export const DEVNET = "devnet";

export const defaultExplorerOptions: IOption[] = [
  {
    value: DefaultExplorer.Solscan,
    label: "Solscan",
    img: solScanLogo,
  },
  {
    value: DefaultExplorer.SolanaExpolorer,
    label: "Solana Explorer",
    img: solExplorerLogo,
  },
  {
    value: DefaultExplorer.SolanaFM,
    label: "Solana Fm",
    img: solFmLogo,
  },
];

export const allSocials: SocialNetworkType[] = [
  SocialNetworkType.Discord,
  SocialNetworkType.LinkedIn,
  SocialNetworkType.Mail,
  SocialNetworkType.Telegram,
  SocialNetworkType.Twitter,
];
