import React, { FC, ReactNode, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import StickyHeader from "../StickyHeader/StickyHeader";
import "./ClubDetailsWrapper.scss";
import { useLocation, useParams } from "react-router";
import {
  NO_STICKY_HEADER,
  STICKY_TREASURY,
} from "../../common/constants/layout.constants";
import { clubStore } from "../../state/clubStore";
import {
  GET_INITIAL_CLUB_DETAILS,
  GET_MEMBER_DATA,
  mapInitialClubDetails,
} from "../../api/club.api";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { useLazyQuery, useSubscription } from "@apollo/client";
import useTransactionStore from "../../state/transactionStore";
import {
  BASIC_LAZY_QUERY_OPTIONS,
  POLL_INTERVAL,
} from "../../common/constants/common.constants";
import { FetchingDataState } from "../../common/enums/common.enum";
import ClubDetailsSubscriptions from "../ClubDetailsSubscriptions/ClubDetailsSubscriptions";

const ClubDetailsWrapper: FC<{ children?: ReactNode }> = ({ children }) => {
  const location = useLocation();
  const wallet = useAnchorWallet();
  const params = useParams();

  const { setClubBasicInfo, setMemberData, resetStore } = clubStore();

  const [getInitialClubDetails, initialClubDetailsInfo] = useLazyQuery(
    GET_INITIAL_CLUB_DETAILS,
    {
      onCompleted: (data) => {
        const { clubBasicInfo } = mapInitialClubDetails(data);
        setClubBasicInfo(clubBasicInfo);
      },
    }
  );

  const [getMemberData, memberDataInfo] = useLazyQuery(GET_MEMBER_DATA, {
    onCompleted: (data) => {
      console.log(data, "MEMBER DATA");
      setMemberData(data.getMemberData);
    },
  });

  useEffect(() => {
    if (params.dealAddress) {
      getInitialSetupForClubDetails();
    }
    return () => {
      resetStore();
    };
  }, [params.dealAddress]);

  useEffect(() => {
    getMemberDataForWallet();
    if (!wallet) {
      setMemberData(undefined);
    }
  }, [params, wallet?.publicKey]);

  const getInitialSetupForClubDetails = () => {
    try {
      const clubAddress = params.dealAddress;
      if (!clubAddress) return;

      getInitialClubDetails({
        variables: {
          clubAddress,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getMemberDataForWallet = async () => {
    try {
      if (wallet && params.dealAddress) {
        getMemberData({
          variables: {
            memberAddress: wallet?.publicKey.toString(),
            clubAddress: params.dealAddress,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hideStickeyHeader = NO_STICKY_HEADER.find((item) =>
    location.pathname.includes(item)
  );
  const showStickyTreasury = STICKY_TREASURY.find((item) =>
    location.pathname.includes(item)
  );

  return (
    <ClubDetailsSubscriptions>
      <div className="club-details-layout">
        <Sidebar />
        <div className="club-details-layout__content">
          {!hideStickeyHeader && (
            <StickyHeader treasury={!!showStickyTreasury} />
          )}
          <div className="club-details-layout__content-components">
            {children}
          </div>
        </div>
      </div>
    </ClubDetailsSubscriptions>
  );
};

export default ClubDetailsWrapper;
