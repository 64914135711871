import React, { FC, useEffect, useMemo, useState } from "react";
import Modal from "../Modal/Modal";
import { Formik, Form } from "formik";
import FormActions from "../FormActions/FormActions";
import { IFundraiseFormFields } from "../../common/interfaces/form.interface";
import { fundraiseFormValidator } from "./fundraiseFormValidator";
import FundraiseForm from "./FundraiseForm/FundraiseForm";
import fundraiseFormConfig from "./fundraiseFormConfig";
import { supprotClub } from "../../program/methods/clubs";
import { SupportAction } from "../../common/enums/fundraise.enum";
import { clubStore } from "../../state/clubStore";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { pow, round, subtract } from "mathjs";
import { createNotification } from "../../utilities/notifications";
import {
  DEPOSIT_FEE,
  MESSAGE_TYPE,
} from "../../common/constants/common.constants";
import {
  getAdminConfigInfo,
  getMaxDenomCureencyDepositAmountForTokenOwner,
  getMaxSolDepositAmountForTokenOwner,
} from "../../program/program-helpers";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import {
  checkIfUserHasClubPermissionForAction,
  checkIfUserHasTreasuryPermissionForAction,
  findFundraiseInProgress,
  getAmountWithDecimalsForCurrency,
} from "../../utilities/helpers";
import { ClubAction } from "../../common/enums/clubs.enum";
import { CLIENT_CONFIGURATION } from "../../client/configuration";

const DepositFormModal: FC<{ closeModal: () => void }> = ({ closeModal }) => {
  const {
    activeTreasury,
    clubBasicInfo,
    fundraises,
    memberData,
    memberTreasuryInfo,
  } = clubStore();
  const wallet = useAnchorWallet();

  const [memberTokenAccountAddress, setMemberTokenAccountAddress] =
    useState<PublicKey>();
  const [maxDepositAmount, setMaxDepositAmount] = useState<number>();
  const [adminFeeInfo, setAdminFeeInfo] = useState<number>();

  useEffect(() => {
    if (adminFeeInfo !== undefined) void getTokenInfoForMember();
  }, [wallet?.publicKey, adminFeeInfo]);

  useEffect(() => {
    getAdminFeeInfo();
  }, []);

  const getAdminFeeInfo = async () => {
    try {
      const admin = await getAdminConfigInfo();
      setAdminFeeInfo(admin.feePercentage / 100);
    } catch (error) {
      console.log(error);
    }
  };

  const getTokenInfoForMember = async () => {
    try {
      if (!wallet) return;
      if (activeTreasury?.denominatedCurrency) {
        const maxDepositAmountAndTokens =
          await getMaxDenomCureencyDepositAmountForTokenOwner(
            new PublicKey(activeTreasury?.denominatedCurrency),
            wallet?.publicKey
          );
        setMemberTokenAccountAddress(maxDepositAmountAndTokens?.membersTA);
        setMaxDepositAmount(
          calculateMaxDepositAmount(maxDepositAmountAndTokens?.maxAmount ?? 0)
        );
      } else {
        const maxDepositAmount = await getMaxSolDepositAmountForTokenOwner(
          wallet?.publicKey
        );
        setMaxDepositAmount(
          calculateMaxDepositAmount(Number(maxDepositAmount))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculateMaxDepositAmount = (maxDeposit: number) => {
    try {
      const activeFundraise = fundraises?.find((item) => item.isActive);
      if (!activeTreasury || !activeFundraise || !fundraises) {
        throw new Error("Treasury or fundraise is undefined");
      }
      const raisedAmount = activeFundraise.raisedAmount;
      const capAmount = activeFundraise.capAmount;
      const currentFundraiseDeposit =
        memberTreasuryInfo?.depositRecords &&
        memberTreasuryInfo?.depositRecords[
          memberTreasuryInfo?.depositRecords.length - 1
        ]?.fundraiseIndex === activeTreasury?.fundraiseCount &&
        memberTreasuryInfo?.depositRecords[
          memberTreasuryInfo?.depositRecords.length - 1
        ]?.currentFundraiseDeposit;

      let remainingAmount = round(subtract(capAmount, raisedAmount));

      if (remainingAmount === 0) {
        createNotification(MESSAGE_TYPE.ERROR, "Fundraise cap alredy met.");
        return;
      }

      const fundraiseInProgress = findFundraiseInProgress(fundraises);
      let allocation =
        (fundraiseInProgress?.allocation?.custom &&
          fundraiseInProgress?.allocation?.custom.find(
            (item) => item.authority === wallet?.publicKey.toString()
          )?.amount) ??
        fundraiseInProgress?.allocation?.equal;

      if (allocation) {
        if (currentFundraiseDeposit) {
          allocation = allocation - currentFundraiseDeposit;
        }

        if (remainingAmount > allocation) {
          remainingAmount = allocation;
        }
      }

      if (maxDeposit === undefined) {
        createNotification(
          MESSAGE_TYPE.ERROR,
          "Problem loading wallet balance."
        );
        return;
      }

      let depositFee = activeTreasury.denominatedCurrency
        ? 0
        : DEPOSIT_FEE * LAMPORTS_PER_SOL;

      let amountToDeposit: number = 0;
      if (maxDeposit === remainingAmount + depositFee) {
        amountToDeposit = remainingAmount;
      } else if (maxDeposit > remainingAmount + depositFee) {
        amountToDeposit = remainingAmount;
      } else if (maxDeposit < remainingAmount + depositFee) {
        amountToDeposit = maxDeposit - depositFee;
      }

      if (
        adminFeeInfo !== undefined &&
        adminFeeInfo > 0 &&
        amountToDeposit < remainingAmount
      ) {
        const mgmtFee = (amountToDeposit * adminFeeInfo) / 100;
        amountToDeposit = round(
          amountToDeposit - mgmtFee,
          activeTreasury.currencyDecimals
        );
      }

      if (amountToDeposit < 0) {
        return 0;
      }

      return getAmountWithDecimalsForCurrency(
        activeTreasury.currencyDecimals,
        amountToDeposit
      );
    } catch (error) {
      createNotification(
        MESSAGE_TYPE.ERROR,
        "Failed to get max deposit amount."
      );
      return 0;
    }
  };

  const initialValues: IFundraiseFormFields = useMemo(() => {
    return {
      amount: fundraiseFormConfig.formFields.amount.value,
    };
  }, []);

  const handleSubmit = async (values: IFundraiseFormFields) => {
    try {
      const activeFundraise = fundraises?.find((item) => item.isActive);
      if (
        !activeTreasury ||
        !wallet ||
        !clubBasicInfo ||
        !activeFundraise ||
        !memberData
      ) {
        createNotification(MESSAGE_TYPE.ERROR, "Missing data");
        return;
      }

      let supportAmount =
        Number(values.amount) *
        Number(pow(10, activeTreasury.currencyDecimals));

      if (adminFeeInfo) {
        supportAmount = supportAmount + (supportAmount * adminFeeInfo) / 100;
      }

      const isEndingFundraise =
        supportAmount + activeFundraise.raisedAmount >=
        activeFundraise.capAmount;
      await supprotClub(
        SupportAction.Deposit,
        supportAmount,
        clubBasicInfo?.address,
        activeTreasury.treasuryDataAddress,
        activeTreasury.treasuryAddress,
        activeFundraise.fundraiseIndex,
        memberData?.address,
        clubBasicInfo.name,
        wallet,
        clubBasicInfo.clubType,
        activeTreasury.treasuryIndex,
        clubBasicInfo.communityMint,
        activeTreasury.denominatedCurrency,
        activeTreasury.financialTokenAccount,
        memberTokenAccountAddress,
        isEndingFundraise
      );

      createNotification(MESSAGE_TYPE.SUCCESS, "Successfully deposited");
      closeModal();
    } catch (error) {
      console.log(error);
      createNotification(MESSAGE_TYPE.ERROR, "Failed to deposit funds");
    }
  };

  return (
    <Modal closeModal={closeModal} title="Deposit">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur
        validate={(values) => fundraiseFormValidator(values, maxDepositAmount)}
      >
        <Form id={fundraiseFormConfig.formId}>
          <FundraiseForm
            deposit
            maxAmount={maxDepositAmount}
            mgmtFee={adminFeeInfo}
          />
          <FormActions
            buttonText="Deposit"
            cancelAction={closeModal}
            buttonAction={() => handleSubmit}
            disabled={
              !checkIfUserHasClubPermissionForAction(
                memberData,
                clubBasicInfo?.roleConfig,
                ClubAction.SupportClub
              )
            }
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default DepositFormModal;
