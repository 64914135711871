import { FC } from "react";
import "./ProposalModalHeader.scss";
import Chip from "../../Chip/Chip";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import {
  getProposalStatusTitle,
  getProposalTypeTitle,
  handleCopyToClipboard,
  setupVotingTime,
} from "../../../utilities/helpers";
import linkIcon from "../../../assets/link.png";
import BasicDetailsCard from "../../BasicDetailsCard/BasicDetailsCard";
import { getProposalChipColor } from "../../../utilities/chipColor";
import HoverIcon from "../../HoverIcon/HoverIcon";
import clubAvatar from "../../../assets/unique_avatar.png";
import { IProposal } from "../../../common/interfaces/proposal.interface";
import CancelReasons from "../CancelReasons/CancelReasons";

//TODO: make description dynamic
const ProposalModalHeader: FC<{
  proposal: IProposal;
  cancelling: boolean;
  toggleCancelling: (cancelling: boolean) => void;
}> = ({ proposal, cancelling, toggleCancelling }) => {
  const propsalMaxVotingTime = setupVotingTime(
    proposal.proposalAccount.governance.maxVotingTime,
    proposal.proposalAccount.votingAt
  );

  return (
    <>
      {cancelling ? (
        <CancelReasons
          closeModal={() => toggleCancelling(!cancelling)}
          proposal={proposal}
        />
      ) : (
        <div className="proposal-modal-header">
          <div className="proposal-modal-header__top">
            <div>
              <Chip
                text={getProposalTypeTitle(
                  proposal.proposalMetadata.proposalType
                )}
                fontSize="0.75"
              />
              <span>
                {propsalMaxVotingTime?.days}d {propsalMaxVotingTime?.hours}h{" "}
                {propsalMaxVotingTime?.minutes}m
              </span>
            </div>
            <div>
              <Chip
                text={getProposalStatusTitle(proposal.proposalAccount.state)}
                backgroundColor={getProposalChipColor(
                  proposal.proposalAccount.state
                )}
                fontSize="0.75"
              />
              <HoverIcon
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopyToClipboard(EMPTY_STRING);
                }}
                src={linkIcon}
                alt="Link"
              />
            </div>
          </div>
          <div className="proposal-modal-header__description">
            {/* TODO: add nft details */}
            <BasicDetailsCard
              image={clubAvatar}
              alt={"Proposal image"}
              title={proposal.proposalMetadata.name}
              id={EMPTY_STRING}
              large
              additionalInfo={EMPTY_STRING}
            />
            {/* TODO: add description */}
            {/* <p>{proposal.proposalMetadata.description}</p> */}
          </div>
        </div>
      )}
    </>
  );
};

export default ProposalModalHeader;
