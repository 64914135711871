import { FC, useMemo, useState } from "react";
import "./FundraiseTabs.scss";
import HistoryTab from "../HistoryTab/HistoryTab";
import { ActiveTabTreasuryFundraise } from "../../../common/enums/treasury.enum";
import TreasuryFundraise from "../TreasuryFundraise/TreasuryFundraise";
import TokenList from "../TokenList/TokenList";
import TreasuryMembersList from "../TreasuryMembers/TreasuryMembersList";
import MainButton from "../../../components/MainButton/MainButton";
import ManageTreasuryRolesModal from "../ManageTreasuryRolesModal/ManageTreasuryRolesModal";
import { clubStore } from "../../../state/clubStore";
import { checkIfUserHasClubPermissionForAction } from "../../../utilities/helpers";
import { ClubAction } from "../../../common/enums/clubs.enum";

const FundraiseTabs: FC = () => {
  const [activeTab, setActiveTab] = useState(ActiveTabTreasuryFundraise.Tokens);
  const [manageTreasuryRoles, toggleManageTreasuryRoles] = useState(false);
  const { memberData, clubBasicInfo } = clubStore();

  const activeTabData = useMemo(() => {
    switch (activeTab) {
      case ActiveTabTreasuryFundraise.Tokens:
        return <TokenList />;
      case ActiveTabTreasuryFundraise.Members:
        return <TreasuryMembersList />;
      case ActiveTabTreasuryFundraise.Fundraise:
        return <TreasuryFundraise />;
      case ActiveTabTreasuryFundraise.History:
        return <HistoryTab />;
    }
  }, [activeTab]);
  return (
    <>
      {manageTreasuryRoles && (
        <ManageTreasuryRolesModal
          closeModal={() => toggleManageTreasuryRoles(false)}
        />
      )}
      <div className="fundraise-tabs">
        <div className="fundraise-tabs__container">
          <h2
            className={`fundraise-tabs__tokens-tab
            ${
              activeTab === ActiveTabTreasuryFundraise.Tokens &&
              "fundraise-tabs__tokens-tab--open"
            }`}
            onClick={() => setActiveTab(ActiveTabTreasuryFundraise.Tokens)}
          >
            Tokens
          </h2>
          <h2
            className={`fundraise-tabs__members-tab
            ${
              activeTab === ActiveTabTreasuryFundraise.Members &&
              "fundraise-tabs__members-tab--open"
            }`}
            onClick={() => setActiveTab(ActiveTabTreasuryFundraise.Members)}
          >
            Members
          </h2>
          <h2
            className={`fundraise-tabs__fundraise-tab
            ${
              activeTab === ActiveTabTreasuryFundraise.Fundraise &&
              "fundraise-tabs__fundraise-tab--open"
            }`}
            onClick={() => setActiveTab(ActiveTabTreasuryFundraise.Fundraise)}
          >
            Fundraise
          </h2>
          <h2
            className={`fundraise-tabs__history-tab
            ${
              activeTab === ActiveTabTreasuryFundraise.History &&
              "fundraise-tabs__fundraise-tab--open"
            }`}
            onClick={() => setActiveTab(ActiveTabTreasuryFundraise.History)}
          >
            History
          </h2>
        </div>
        <MainButton
          type="button"
          onClick={() => toggleManageTreasuryRoles(true)}
          light
          disabled={
            !checkIfUserHasClubPermissionForAction(
              memberData,
              clubBasicInfo?.roleConfig,
              ClubAction.AllowMember
            )
          }
        >
          Manage treasury roles
        </MainButton>
      </div>
      {activeTabData}
    </>
  );
};

export default FundraiseTabs;
