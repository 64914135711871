import { PublicKey } from "@solana/web3.js";
import { divide, multiply, pow, round } from "mathjs";
import {
  ClubAction,
  SocialNetworkType,
  TreasuryAction,
} from "../common/enums/clubs.enum";
import {
  IClubRoleConfig,
  IFundraiseConfig,
  IMemberData,
  IMemberTreasuryInfo,
  ISocialNetwork,
  ITreasuryData,
  ITreasuryRoleConfig,
  IVertical,
} from "../common/interfaces/club.interface";
import {
  EMPTY_STRING,
  MAX_NUM_OF_MEMBERS_WITHOUT_FOUNDER,
  MAX_NUM_OF_MEMBERS_WITH_FOUNDER,
  MESSAGE_TYPE,
} from "../common/constants/common.constants";
import { createNotification } from "./notifications";
import {
  IClubApplicationFormFields,
  IMemberWallets,
  ITokenUnlockDate,
  IUserAccountSettingsFormFields,
} from "../common/interfaces/form.interface";

import {
  fundraiseCfgSeed,
  metadataSeed,
  treasuryDataSeed,
  unqClubMemberSeed,
  unqClubSeed,
} from "../common/constants/seeds.constants";
import {
  IBasicFilters,
  IBasicNftInfo,
  ITokenUnlockingInput,
} from "../common/interfaces/common.interface";
import {
  METADATA_PROGRAM_ID,
  RPC_CONNECTION,
  programFactory,
} from "../program/utils";
import { Metaplex } from "@metaplex-foundation/js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import {
  ProposalType,
  ProposalTypeProgram,
} from "../common/enums/proposal.enum";
import { ProposalState } from "@solana/spl-governance";
import {
  IDashboardAvailableClaim,
  IDashboardWithdrawal,
} from "../common/interfaces/dashboard.interface";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { bs58 } from "@project-serum/anchor/dist/cjs/utils/bytes";
import { IExistingInvestment } from "../common/interfaces/treasury.interface";

/**
 * Get percentage
 * @param current {number}
 * @param total {number}
 * @returns
 */
export const getPercentage = (current: number, total: number) => {
  let result = round(current / total, 2);
  return result * 100;
};

/**
 * Format date to DD/MM/YYYY
 * @param date {Date}
 * @returns string
 */
export const formatDate = (date: Date): string => {
  return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;
};

/**
 * Format date  DD/MM/YY HH:MM
 * @param date {Date}
 * @returns string
 */
export const formatDateWithTime = (date: Date | string) => {
  const dateInDateFormat = new Date(date);
  return `${dateInDateFormat.toLocaleTimeString()} ${dateInDateFormat.toLocaleDateString()}`;
};

export const formatDateWithoutTime = (date: Date | string | undefined) => {
  if (date === undefined) {
    return;
  }
  const dateInDateFormat = new Date(date);
  return `${dateInDateFormat.toLocaleDateString()}`;
};

export const findNearestDateForTokenUnlocking = (
  fundraises: IFundraiseConfig[]
) => {
  if (!fundraises) {
    return;
  }

  const activeFundraise = fundraises.find((item) => item.isActive);

  if (
    !activeFundraise?.tokenUnlocking ||
    activeFundraise.tokenUnlocking.length === 0
  ) {
    return undefined;
  }
  let currentDay = new Date();

  const sortedDates: ITokenUnlockDate[] = [...activeFundraise.tokenUnlocking];

  sortedDates.sort((d1, d2) =>
    new Date(d1.date).getDate() < new Date(d2.date).getDate() ? -1 : 1
  );

  return sortedDates.find(
    (item) => new Date(item.date).getDate() >= currentDay.getDate()
  );
};

export function parseSecondsToDays(seconds: number | undefined) {
  if (!seconds) return undefined;
  dayjs.extend(duration);
  return dayjs.duration(seconds - 1, "seconds").asDays();
}

export const generateRandomNumber = (array: any[]) => {
  return Math.floor(Math.random() * array.length);
};

export const findFundraiseInProgress = (fundraises: IFundraiseConfig[]) => {
  if (!fundraises) {
    return;
  }

  return fundraises?.find((item) => item.isActive);
};

export const subsetArrayForPagination = <T>(
  array: T[],
  currentPage: number,
  itemsPerPage: number
) => {
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return array.slice(startIndex, endIndex);
};

export const handleCopyToClipboard = (text?: string) => {
  createNotification(MESSAGE_TYPE.SUCCESS, "Copied.");
  navigator.clipboard.writeText(text ?? EMPTY_STRING);
};

export function getTrimmedPublicKey(publicKey: PublicKey | string): string {
  const publicKeyString = publicKey.toString();
  return (
    publicKeyString.substring(0, 5) +
    "..." +
    publicKeyString.substring(publicKeyString.length - 5)
  );
}

export const votedPercentage = (
  accepted: number,
  rejected: number,
  numberOfMembers: number
) => {
  const totalVotes = accepted + rejected;
  return ((numberOfMembers - totalVotes) / numberOfMembers) * 100;
};

export const getCirclePercentage = (
  accepted: number,
  declined: number,
  members: number
) => {
  return ((accepted + declined) / members) * 100;
};

//TODO@ana: refactor this method
export const sortByDateProperty = (
  array: any[],
  property: any,
  ascending = true
) => {
  const compare = (a: any, b: any) => {
    const valueA = a[property];
    const valueB = b[property];

    if (valueA instanceof Date && valueB instanceof Date) {
      if (valueA.getTime() < valueB.getTime()) {
        return ascending ? -1 : 1;
      } else if (valueA.getTime() > valueB.getTime()) {
        return ascending ? 1 : -1;
      } else {
        return 0;
      }
    } else if (valueA instanceof Date) {
      return ascending ? -1 : 1;
    } else if (valueB instanceof Date) {
      return ascending ? 1 : -1;
    } else {
      return 0;
    }
  };

  return array.slice().sort(compare);
};

export function uploadImage(
  acceptedFiles: any,
  reader: FileReader,
  fieldValue: string,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
) {
  if (!acceptedFiles[0]) {
    return;
  }
  reader.readAsDataURL(acceptedFiles[0]);
  reader.onload = async () => {
    const base64ImgFormat: any = reader.result;
    setFieldValue(fieldValue, base64ImgFormat);
  };
}

export const getAmountWithDecimalsForCurrency = (
  decimals: number | undefined,
  amount: number | undefined
) => {
  if (decimals !== undefined && amount !== undefined)
    return amount / Number(pow(10, decimals));
};

export const checkIfUserHasClubPermissionForAction = (
  memberData: IMemberData | undefined,
  roleConfig: IClubRoleConfig[] | undefined,
  clubAction: ClubAction
) => {
  return (
    memberData?.isMember &&
    !!roleConfig
      ?.find((item) => item.name === memberData?.role)
      ?.clubActions.some((item) => item === clubAction)
  );
};

export const checkIfUserHasTreasuryPermissionForAction = (
  memberTreasuryInfo: IMemberTreasuryInfo | undefined,
  roleConfig: ITreasuryRoleConfig[] | undefined,
  treasuryAction: TreasuryAction
) => {
  return (
    memberTreasuryInfo &&
    !!roleConfig
      ?.find((item) => item.name === memberTreasuryInfo?.treasuryRole)
      ?.treasuryActions.some((item) => item === treasuryAction)
  );
};

export const checkIfUserCanCreateAtLeastOneTypeOfProposal = (
  memberTreasuryInfo: IMemberTreasuryInfo | undefined,
  roleConfig: ITreasuryRoleConfig[] | undefined
) => {
  const treasuryProposalActions = [
    TreasuryAction.CreateDiscussionProposal,
    TreasuryAction.CreateMeProposal,
    TreasuryAction.CreateP2PProposal,
    TreasuryAction.CreateSolseaProposal,
    TreasuryAction.CreateTransferProposal,
    TreasuryAction.CreateWithdrawalProposal,
    TreasuryAction.UpdateGovernanceConfig,
    TreasuryAction.Fundraise,
    TreasuryAction.UpdateRoleConfig,
  ];
  return (
    memberTreasuryInfo &&
    !!roleConfig
      ?.find((item) => item.name === memberTreasuryInfo?.treasuryRole)
      ?.treasuryActions.some((item) => treasuryProposalActions.includes(item))
  );
};

export const toggleClubVerticalHelper = (
  v: IVertical,
  verticals: IVertical[]
) => {
  if (verticals.find((vertical) => vertical.slug === v.slug)) {
    return [...verticals.filter((vertical) => vertical.slug !== v.slug)];
  } else {
    return [...verticals, v];
  }
};

export const validateMultipleMembersField = (
  members: IMemberWallets[],
  arrayName: string,
  errors: any
) => {
  members.forEach((item, index) => {
    if (Number(item.membersAmount) <= 0) {
      errors[`${arrayName}.${index}.membersAmount`] =
        "Members amount can not be zero or less";
    }

    item.wallet.forEach((wallet) => {
      if (
        members.find(
          (member, memberIndex) =>
            memberIndex !== index &&
            member.wallet.find((memberWallet) => memberWallet === wallet)
        )
      ) {
        errors[`${arrayName}.${index}.wallet`] =
          "This member is already in this allocation";
      }
    });

    if (item.wallet.length < 1) {
      errors[`${arrayName}.${index}.wallet`] = "Wallet can not be empty";
    }

    if (item.membersAmount === EMPTY_STRING) {
      errors[`${arrayName}.${index}.membersAmount`] =
        "Members amount can not be empty";
    }
  });
};

export const findNftTokenAccountAndMetadataFromCollection = async (
  pubKey: PublicKey,
  collectionAddress: PublicKey
): Promise<IBasicNftInfo> => {
  try {
    const ownerNfts = await new Metaplex(RPC_CONNECTION).nfts().findAllByOwner({
      owner: pubKey,
    });

    const collectionNft = ownerNfts.find(
      (item) =>
        item.collection?.address.toString() === collectionAddress.toString()
    );

    if (!collectionNft) throw new Error("Can not find nft from collection");

    const nftTA = await RPC_CONNECTION.getParsedTokenAccountsByOwner(pubKey, {
      mint: collectionNft?.address,
    });
    const [metadataAddress] = await PublicKey.findProgramAddress(
      [
        metadataSeed,
        METADATA_PROGRAM_ID.toBuffer(),
        collectionNft.address.toBuffer(),
      ],
      METADATA_PROGRAM_ID
    );
    return {
      metadataAddress,
      mint: collectionNft.address,
      ownertokenAddress: nftTA.value[0].pubkey,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getNftTokenAccountsByOwner = async (wallet: PublicKey) => {
  const tokenAccounts = await RPC_CONNECTION.getParsedTokenAccountsByOwner(
    wallet,
    { programId: TOKEN_PROGRAM_ID }
  );

  return tokenAccounts.value.filter(
    (tokenAccount) =>
      tokenAccount.account.data.parsed.info.tokenAmount.decimals === 0 &&
      tokenAccount.account.data.parsed.info.tokenAmount.uiAmount >= 1
  );
};

export const showQuorumMaxVotingTimeForGovernance = (
  proposalType: ProposalType,
  activeTreasury: ITreasuryData
) => {
  switch (proposalType) {
    case ProposalType.ConfigurationChanges:
      return {
        approvalVotePercentage:
          activeTreasury?.changeConfigGovernance.voteThreshold,
        maxVotingTime: activeTreasury?.changeConfigGovernance.maxVotingTime,
      };
    case ProposalType.Discussion:
      return {
        approvalVotePercentage:
          activeTreasury?.treasuryGovernance.voteThreshold,
        maxVotingTime: activeTreasury?.treasuryGovernance.maxVotingTime,
      };
    case ProposalType.Trading:
      return {
        approvalVotePercentage:
          activeTreasury?.treasuryGovernance.voteThreshold,
        maxVotingTime: activeTreasury?.treasuryGovernance.maxVotingTime,
      };
    case ProposalType.TransferFunds:
      return {
        approvalVotePercentage:
          activeTreasury?.transferGovernance.voteThreshold,
        maxVotingTime: activeTreasury?.transferGovernance.maxVotingTime,
      };
    case ProposalType.Withdrawal:
      return {
        approvalVotePercentage:
          activeTreasury?.withdrawalGovernance.voteThreshold,
        maxVotingTime: activeTreasury?.withdrawalGovernance.maxVotingTime,
      };
    default:
      return {
        approvalVotePercentage:
          activeTreasury?.treasuryGovernance.voteThreshold,
        maxVotingTime: activeTreasury?.treasuryGovernance.maxVotingTime,
      };
  }
};

export const calculateVotePercentage = (c: number, total?: number) => {
  const votePrecision = 10000;
  if (total === 0) {
    return 0;
  }

  return round(
    divide(multiply(c, votePrecision), total ?? 1) * (100 / votePrecision),
    2
  );
};

export const setupVotingTime = (maxVotingTime: number, votingAt?: number) => {
  const now = dayjs().unix();

  let timeToVoteEnd = votingAt
    ? (votingAt ?? 0) + maxVotingTime - now
    : maxVotingTime;

  if (timeToVoteEnd <= 0) {
    return null;
  }

  const days = Math.floor(timeToVoteEnd / 86400);
  timeToVoteEnd -= days * 86400;

  const hours = Math.floor(timeToVoteEnd / 3600) % 24;
  timeToVoteEnd -= hours * 3600;

  const minutes = Math.floor(timeToVoteEnd / 60) % 60;
  timeToVoteEnd -= minutes * 60;
  const seconds = Math.floor(timeToVoteEnd % 60);

  return { days, hours, minutes, seconds };
};

export const getProposalTypeTitle = (proposalType: ProposalTypeProgram) => {
  switch (proposalType) {
    case ProposalTypeProgram.BuyNowMagicEden:
    case ProposalTypeProgram.BuyP2P:
    case ProposalTypeProgram.BuySolsea:
    case ProposalTypeProgram.SellMagicEden:
    case ProposalTypeProgram.SellP2P:
    case ProposalTypeProgram.SellSolsea:
      return ProposalType.Trading;
    case ProposalTypeProgram.Discussion:
      return ProposalType.Discussion;
    case ProposalTypeProgram.TransferFunds:
      return ProposalType.TransferFunds;
    case ProposalTypeProgram.UpdateGovernanceConfig:
      return ProposalType.ConfigurationChanges;
    case ProposalTypeProgram.UpdateRoleConfig:
      return ProposalType.ConfigurationChanges;

    case ProposalTypeProgram.Withdrawal:
      return ProposalType.Withdrawal;
    case ProposalTypeProgram.CreateFundraise:
      return ProposalType.Fundraise;
    case ProposalTypeProgram.AddSellPermission:
      return ProposalType.AddSellPermission;
  }
};

export const getProposalStatusTitle = (proposalState: ProposalState) => {
  switch (proposalState) {
    case ProposalState.Defeated:
      return "Defeated";
    case ProposalState.Cancelled:
      return "Cancelled";
    case ProposalState.Completed:
      return "Completed";
    case ProposalState.Draft:
      return "Draft";
    case ProposalState.Executing:
      return "Executing";
    case ProposalState.ExecutingWithErrors:
      return "ExecutingWithErrors";
    case ProposalState.SigningOff:
      return "SigningOff";
    case ProposalState.Succeeded:
      return "Succeeded";
    case ProposalState.Voting:
      return "Voting";
  }
};

export const getInitialValuesForFilters = (): IBasicFilters => {
  return {
    page: 0,
    search: EMPTY_STRING,
  };
};

export const generateSkeletonArrays = (quantity: number) => [
  ...Array(quantity).keys(),
];

export const findFundraiseAdressFromPda = (
  fundraiseCount: number,
  treasuryDataAddress: string
) => {
  const program = programFactory();
  const fundraiseIndex = Buffer.alloc(4);
  fundraiseIndex.writeInt32LE(fundraiseCount, 0);

  const [fundraiseConfigAddress] = PublicKey.findProgramAddressSync(
    [
      unqClubSeed,
      new PublicKey(treasuryDataAddress).toBuffer(),
      fundraiseCfgSeed,
      fundraiseIndex,
    ],
    program.programId
  );

  return fundraiseConfigAddress;
};

export const mapTokenUnlockingDateArray = (
  tokenUnlockingArray: ITokenUnlockDate[],
  fundraiseConfigAddress: string
) => {
  const tokenUnlockingInput: ITokenUnlockingInput[] = [];
  tokenUnlockingArray.forEach((item) =>
    tokenUnlockingInput.push({
      date: item.date.toString(),
      tokenAmount: item.tokenAmount,
      fundraiseAddress: fundraiseConfigAddress,
    })
  );

  return tokenUnlockingInput;
};

export const getClaimPDAs = (
  availableClaim: IDashboardAvailableClaim | IDashboardWithdrawal,
  wallet: AnchorWallet
) => {
  const program = programFactory();

  const [memberDataAddress] = PublicKey.findProgramAddressSync(
    [
      unqClubSeed,
      new PublicKey(availableClaim.clubData).toBuffer(),
      unqClubMemberSeed,
      wallet.publicKey.toBuffer(),
    ],
    program.programId
  );

  const [treasuryDataAddress] = PublicKey.findProgramAddressSync(
    [
      unqClubSeed,
      new PublicKey(availableClaim.treasury).toBuffer(),
      treasuryDataSeed,
    ],
    program.programId
  );

  return [memberDataAddress, treasuryDataAddress];
};

export const getMemberDataPda = (clubAddress: string, wallet: AnchorWallet) => {
  const program = programFactory();
  const [memberDataAddress] = PublicKey.findProgramAddressSync(
    [
      unqClubSeed,
      new PublicKey(clubAddress).toBuffer(),
      unqClubMemberSeed,
      wallet.publicKey.toBuffer(),
    ],
    program.programId
  );
  return memberDataAddress;
};

export function turnDateIntoString(timestamp: string) {
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${day}-${month}-${year}`;
}

export function capitalizeFirstLetter(input: string) {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export async function getSignedMessage(
  input: string,
  signMessage: (message: Uint8Array) => Promise<Uint8Array>
) {
  const signedMessage = await signMessage(new TextEncoder().encode(`${input}`));
  return bs58.encode(signedMessage);
}

export const updateSocialNetworkApiArray = (
  value: string,
  type: SocialNetworkType,
  networks: ISocialNetwork[]
) => {
  if (value !== EMPTY_STRING) {
    networks.push({
      type: type,
      value: value,
    });
  }
};

export const validateSocialNetworks = (
  values: IClubApplicationFormFields | IUserAccountSettingsFormFields,
  errors: any
) => {
  const mailRegExp = new RegExp("\\w+@\\w+\\.\\w+");
  const telegramRegExp = new RegExp(
    `(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$`
  );
  const discordRegExp = new RegExp(
    `((https?:\/\/)?(discord\.com)\/invite\/+[a-zA-Z0-9_-])|((https?:\/\/)?(discord\.gg)\/+[a-zA-Z0-9_-])`
  );
  const twitterRegExp = new RegExp(
    `(https?:\/\/)?(www\.)?(twitter\.com)\/+[a-zA-Z0-9]`
  );

  const linkedinRegExp = new RegExp(
    `http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?`
  );

  if (values.mail && !mailRegExp.test(values.mail)) {
    errors.mail = "Please enter valid email";
  }

  if (values.telegram && !telegramRegExp.test(values.telegram)) {
    errors.telegram = "Please enter valid Telegram link";
  }

  if (values.twitter && !twitterRegExp.test(values.twitter)) {
    errors.twitter = "Please enter valid Twitter link";
  }
  if (values.discord && !discordRegExp.test(values.discord)) {
    errors.discord = "Please enter valid Discord link";
  }
  if (values.linkedin && !linkedinRegExp.test(values.linkedin)) {
    errors.linkedin = "Please enter valid Linkedin link";
  }

  return errors;
};

export const validateImageFormat = (acceptedFiles: any[]) => {
  if (acceptedFiles.length > 1) {
    createNotification(MESSAGE_TYPE.ERROR, "Please upload only one image");

    throw new Error("Please upload only one image");
  }
  const allowedFormats = ["image/jpeg", "image/png", "image/gif"];
  if (!allowedFormats.includes(acceptedFiles[0].type)) {
    createNotification(
      MESSAGE_TYPE.ERROR,
      "Only JPG, PNG, and GIF formats are allowed."
    );
    throw new Error("Only JPG, PNG, and GIF formats are allowed.");
  }
};

export function mapProposalEnumValueToType(
  enumValue: ProposalTypeProgram
): string {
  switch (enumValue) {
    case ProposalTypeProgram.BuyP2P:
      return "buy-p2p";
    case ProposalTypeProgram.SellP2P:
      return "sell-p2p";
    case ProposalTypeProgram.BuyNowMagicEden:
      return "buy-me";
    case ProposalTypeProgram.SellMagicEden:
      return "sell-me";
    case ProposalTypeProgram.Discussion:
      return "discussion";
    case ProposalTypeProgram.Withdrawal:
      return "withdrawal";
    case ProposalTypeProgram.SellSolsea:
      return "sell-ss";
    case ProposalTypeProgram.BuySolsea:
      return "buy-ss";
    case ProposalTypeProgram.UpdateGovernanceConfig:
      return "approval-percentage";
    case ProposalTypeProgram.UpdateRoleConfig:
      return "role-voting-power";
    // NOTICE! These next 2 types still do not exist in the db so will return no reasons.
    case ProposalTypeProgram.TransferFunds:
      return "transfer-funds";
    case ProposalTypeProgram.CreateFundraise:
      return "create-fundraise";
    case ProposalTypeProgram.AddSellPermission:
      return "add-sell-permission";
    default:
      return "";
  }
}

export const extractNumbersFromString = (str: string): string =>
  str.replace(/[^(\d+)\.(\d+)]/g, "");

export const notEligibleToUpload = (
  invited: IExistingInvestment[],
  wallet?: string
) =>
  (invited.find((inv) => inv.wallet === wallet) &&
    invited.length > MAX_NUM_OF_MEMBERS_WITH_FOUNDER) ||
  invited.find(
    (inv) =>
      inv.wallet !== wallet &&
      invited.length > MAX_NUM_OF_MEMBERS_WITHOUT_FOUNDER
  );

export const parseDateToRelativeTime = (date: Date) => {
  const daysDiff = dayjs().diff(date, "days");
  if (daysDiff <= 0) {
    const mintutesDiff = dayjs().diff(date, "minutes");
    return `${
      mintutesDiff > 60
        ? `${(mintutesDiff / 60).toFixed(0)} hours ago`
        : `${mintutesDiff} minutes ago`
    }`;
  }
  if (daysDiff <= 14) {
    return `${daysDiff} days ago`;
  }
  const weeks = (daysDiff / 7).toFixed(0);
  return `${weeks} weeks ago`;
};
