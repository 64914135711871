import React, { FC, useEffect, useState } from "react";
import "./UserImage.scss";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import { EMPTY_IMAGE_BG_COLORS } from "../../common/constants/layout.constants";
import { generateRandomNumber } from "../../utilities/helpers";

const UserImage: FC<{
  image: string;
  name: string;
  width?: string;
}> = ({ image, width, name }) => {
  const [randomColor, setRandomColor] = useState<string>(
    EMPTY_IMAGE_BG_COLORS[generateRandomNumber(EMPTY_IMAGE_BG_COLORS)]
  );

  const showFirstInitial = (name: string) => {
    return name.slice(0, 1);
  };

  return (
    <div className="user-image">
      {image !== EMPTY_STRING ? (
        <img
          src={image}
          alt={name}
          style={{
            width: width ? `${width}px` : EMPTY_STRING,
            height: width ? `${width}px` : EMPTY_STRING,
          }}
        />
      ) : (
        <div
          style={{
            width: width ? `${width}px` : EMPTY_STRING,
            height: width ? `${width}px` : EMPTY_STRING,

            backgroundColor: `${randomColor}`,
          }}
        >
          <p>{showFirstInitial(name)}</p>
        </div>
      )}
    </div>
  );
};

export default UserImage;
