import React, { FC } from "react";
import "./StakingCard.scss";
import Chip from "../../../components/Chip/Chip";
import MainButton from "../../../components/MainButton/MainButton";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { RED500, WHITE300 } from "../../../common/constants/layout.constants";
import StakingItem from "../StakingItem/StakingItem";

const StakingCard: FC<{}> = ({}) => {
  return (
    <div className="staking-card">
      <Chip text="Funding Initiated" backgroundColor={WHITE300} />
      <h2>Quisque congue felis id dictum posuere.</h2>
      <div>
        <ProgressBar numerator={20.19} denominator={500} active />
        <p>
          <span>20.19 UNQ</span> out of <span>500.00 UNQ</span> totally raised
        </p>
      </div>
      <div className="staking-card__info">
        <div className="staking-card__info-tab">
          <h4>Item</h4>
          <p>UNQ Token</p>
        </div>
        <div className="staking-card__info-tab">
          <h4>Reward</h4>
          <p>6% APY</p>
        </div>
        <div className="staking-card__info-tab--last">
          <h4>Period</h4>
          <p>30 days</p>
        </div>
      </div>
      <div className="staking-card__total">
        <h4>My Deposit</h4>
        <div className="staking-card__total-reward">
          <h4>Potential Award</h4>
          <h4>Total</h4>
        </div>
      </div>
      <div className="staking-card__deposit-cards">
        <StakingItem deposit={100} award={10} total={110} currency="SOL" />
      </div>
      <div className="staking-card-actions">
        <div>
          <MainButton type="button" light onClick={() => console.log("click")}>
            Lock
          </MainButton>
          <MainButton
            type="button"
            light
            onClick={() => console.log("click")}
            borderColor={RED500}
            color={RED500}
          >
            Cancel
          </MainButton>
        </div>
        <MainButton type="button" onClick={() => console.log("click")}>
          Deposit
        </MainButton>
      </div>
    </div>
  );
};

export default StakingCard;
