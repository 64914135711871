import { useEffect, useState } from "react";
import "./OverviewHeader.scss";
import clubAvatar from "../../../assets/unique_avatar.png";
import editIcon from "../../../assets/edit.png";
import info from "../../../assets/info.svg";
import Chip from "../../../components/Chip/Chip";
import {
  GREY000,
  GREY500,
  WHITE300,
} from "../../../common/constants/layout.constants";
import { clubStore } from "../../../state/clubStore";
import ClubApplicationModal from "../ClubApplicationModal/ClubApplicationModal";
import HoverIcon from "../../../components/HoverIcon/HoverIcon";
import TreasuryDropdown from "../../../components/TreasuryDropdown/TreasuryDropdown";
import MainButton from "../../../components/MainButton/MainButton";
import ClubConfigurationModal from "../../../components/ClubConfigurationModal/ClubConfigurationModal";
import AddNewMemberModal from "../../Members/AddNewMemberModal/AddNewMemberModal";
import {
  checkIfUserHasClubPermissionForAction,
  getAmountWithDecimalsForCurrency,
  validateImageFormat,
} from "../../../utilities/helpers";
import {
  ApplicationStatus,
  ClubAction,
} from "../../../common/enums/clubs.enum";
import {
  EMPTY_STRING,
  MAX_IMAGE_SIZE,
  MESSAGE_TYPE,
} from "../../../common/constants/common.constants";
import { useDropzone } from "react-dropzone";
import {
  GET_INITIAL_CLUB_DETAILS,
  GET_USER_APPLICATION,
  storeClubBasicInfo,
} from "../../../api/club.api";
import { apolloClient } from "../../../api/config.api";
import { createNotification } from "../../../utilities/notifications";
import { useLazyQuery } from "@apollo/client";
import { useWallet } from "@solana/wallet-adapter-react";

const OverviewHeader = () => {
  const [isProfileImageHovered, setisProfileImageHovered] = useState(false);
  const [isConfigurationOpen, toggleConfigurationModal] = useState(false);
  const [showClubApplication, setShowApplicationModal] = useState(false);
  const [showInviteMembersModal, setShowMembersModal] = useState(false);
  const [allowApplication, toggleAllowApplication] = useState(true);

  const { clubBasicInfo, activeTreasury, memberData } = clubStore();
  const wallet = useWallet();

  useEffect(() => {
    getApplicationStatus();
  }, [clubBasicInfo, memberData]);

  const reader = new FileReader();

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/*": [],
    },
    maxSize: MAX_IMAGE_SIZE,
    onDrop: async (acceptedFiles) => {
      try {
        validateImageFormat(acceptedFiles);
        if (clubBasicInfo) {
          reader.readAsDataURL(acceptedFiles[0]);
          reader.onload = async () => {
            try {
              const base64ImgFormat: any = reader.result;
              await storeClubBasicInfo(
                clubBasicInfo.verticals,
                base64ImgFormat,
                clubBasicInfo.address,
                clubBasicInfo.description
              );
              apolloClient.refetchQueries({
                include: [GET_INITIAL_CLUB_DETAILS],
              });
            } catch (error) {
              createNotification(MESSAGE_TYPE.ERROR, "Failed to upload img");
            }
          };
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const [getApplicationStatus] = useLazyQuery(GET_USER_APPLICATION, {
    onCompleted: (data) => {
      if (
        data.getUserApplication.applicationStatus === ApplicationStatus.Pending
      ) {
        toggleAllowApplication(false);
      }
    },
    variables: {
      memberAddress: wallet.publicKey?.toString(),
      clubAddress: clubBasicInfo?.address,
    },
  });

  return (
    <>
      {isConfigurationOpen && (
        <ClubConfigurationModal
          closeModal={() => toggleConfigurationModal(false)}
        />
      )}
      {showClubApplication && (
        <ClubApplicationModal onClose={() => setShowApplicationModal(false)} />
      )}

      {showInviteMembersModal && (
        <AddNewMemberModal closeModal={() => setShowMembersModal(false)} />
      )}
      <div className="overview-header">
        <div className="overview-header__details">
          <div
            className="overview-header__details-image"
            onMouseEnter={() => setisProfileImageHovered(true)}
            onMouseLeave={() => setisProfileImageHovered(false)}
            {...getRootProps()}
          >
            <img
              src={
                clubBasicInfo?.imgUrl && clubBasicInfo?.imgUrl !== EMPTY_STRING
                  ? clubBasicInfo?.imgUrl
                  : clubAvatar
              }
              alt="Club profile image"
              className="overview-header__details-image-profile"
            />
            {isProfileImageHovered && (
              <>
                <input {...getInputProps()} />
                <img
                  src={editIcon}
                  alt="Pen"
                  className="overview-header__edit-pen"
                  onClick={open}
                />
              </>
            )}
          </div>
          <div className="overview-header-basic-details">
            <h2>{clubBasicInfo?.name}</h2>
            <p>{clubBasicInfo?.description}</p>
            <div className="tags">
              {clubBasicInfo?.verticals?.map((tag, index) => (
                <Chip
                  text={tag.label}
                  backgroundColor={GREY000}
                  padding="0.75em"
                  borderRadius="100"
                  key={index}
                  fontSize="0.875"
                  color={GREY500}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="overview-header__options">
          <div className="overview-header__options-actions">
            <HoverIcon
              src={info}
              alt={"Info"}
              onClick={() => toggleConfigurationModal(true)}
            />
            {memberData?.isMember && (
              <Chip text={memberData.role} backgroundColor={WHITE300} />
            )}
            <div className="sticky-header__options-divider"></div>
            {checkIfUserHasClubPermissionForAction(
              memberData,
              clubBasicInfo?.roleConfig,
              ClubAction.AllowMember
            ) && (
              <MainButton
                type="button"
                onClick={() => setShowMembersModal(true)}
                light
              >
                Invite Members
              </MainButton>
            )}
            {!memberData && (
              <MainButton
                type="button"
                onClick={() => setShowApplicationModal(true)}
                disabled={!allowApplication}
              >
                Join this Club
              </MainButton>
            )}
          </div>
          <div className="overview-header__options-treasury-details">
            <div>
              <h4>Total Balance</h4>
              <p>
                {getAmountWithDecimalsForCurrency(
                  activeTreasury?.currencyDecimals,
                  activeTreasury?.balance
                )}
                <span> {activeTreasury?.currencySymbol}</span>
              </p>
            </div>
            <div>
              <h4>Members</h4>
              <p>{clubBasicInfo?.numberOfMembers}</p>
            </div>
            <div>
              <h4>Treasuries</h4>
              <p>{clubBasicInfo?.treasuryCount}</p>
            </div>
          </div>
        </div>
      </div>
      <TreasuryDropdown />
    </>
  );
};

export default OverviewHeader;
