import React, { useMemo } from "react";
import Modal from "../../../components/Modal/Modal";
import { Form, Formik } from "formik";
import FormActions from "../../../components/FormActions/FormActions";
import manageTreasuryRolesFormConfig from "./manageTreasuryRolesFormConfig";
import { validateManageTreasuryRoles } from "./manageTreasuryRolesValidation";
import {
  IAddMember,
  IManageTreasuryRoles,
} from "../../../common/interfaces/form.interface";
import ManageTreasuryRolesForm from "./ManageTreasuryRolesForm/ManageTreasuryRolesForm";
import { PublicKey } from "@solana/web3.js";
import { MESSAGE_TYPE } from "../../../common/constants/common.constants";
import { AllowType } from "../../../common/enums/clubs.enum";
import { inviteMember } from "../../../program/methods/clubs";
import { createNotification } from "../../../utilities/notifications";
import { clubStore } from "../../../state/clubStore";
import { useAnchorWallet } from "@solana/wallet-adapter-react";

const ManageTreasuryRolesModal: React.FC<{ closeModal: () => void }> = ({
  closeModal,
}) => {
  const { clubBasicInfo, activeTreasury } = clubStore();
  const wallet = useAnchorWallet();
  const initialValues: IManageTreasuryRoles = useMemo(
    () =>
      (() => {
        const values: any = {};
        Object.keys(manageTreasuryRolesFormConfig.formFields).map(
          (key) =>
            (values[key] = (manageTreasuryRolesFormConfig.formFields as any)[
              key
            ].value)
        );
        return values;
      })(),
    []
  );

  const handleSubmitForm = async (values: IManageTreasuryRoles) => {
    try {
      if (!wallet || !clubBasicInfo || !activeTreasury) return;
      const membersToAdd: IAddMember[] = [];
      values.members.forEach((item) => {
        item.wallet.forEach((walletItem) => {
          membersToAdd.push({
            role: item.role,
            wallet: walletItem,
          });
        });
      });
      await inviteMember(
        membersToAdd,
        clubBasicInfo.name,
        wallet,
        AllowType.Treasury,
        new PublicKey(activeTreasury?.treasuryDataAddress),
        new PublicKey(activeTreasury?.realmAddress)
      );

      createNotification(MESSAGE_TYPE.SUCCESS, "Member successfully added");
      closeModal();
    } catch (error) {
      createNotification(
        MESSAGE_TYPE.ERROR,
        "Member couldn't be added. Please try again."
      );
      console.log(error);
    }
  };
  return (
    <Modal title="Manage treasury roles" closeModal={closeModal}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validateOnBlur
        validate={(values) => validateManageTreasuryRoles(values)}
      >
        <Form id={manageTreasuryRolesFormConfig.formId}>
          <ManageTreasuryRolesForm />
          <FormActions
            buttonAction={() => {}}
            buttonText="Save changes"
            cancelAction={closeModal}
            cancelBtnText="Cancel"
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default ManageTreasuryRolesModal;
