import { EMPTY_STRING } from "../common/constants/common.constants";
import { ClubMember } from "../common/enums/clubs.enum";
import members from "../assets/membersoverview.png";

export const MEMBERS = [
  {
    name: "Jane B",
    image: EMPTY_STRING,
    clubMember: ClubMember.Founder,
    ownership: 0.0,
    votingPower: 5,
    member: true,
    invited: new Date(),
    applied: new Date(),
    joined: new Date(new Date().setDate(new Date().getDate() - 10)),
  },
  {
    name: "Kate B",
    image: EMPTY_STRING,
    clubMember: ClubMember.Member,
    ownership: 0.0,
    votingPower: 5,
    member: false,
    invited: new Date(),
    applied: new Date(),
    joined: new Date(),
  },
  {
    name: "Nane B",
    image: EMPTY_STRING,
    clubMember: ClubMember.Member,
    ownership: 0.0,
    votingPower: 5,
    member: true,
    invited: new Date(),
    applied: new Date(),
    joined: new Date(new Date().setDate(new Date().getDate() - 1)),
  },
  {
    name: "Date B",
    image: EMPTY_STRING,
    clubMember: ClubMember.Member,
    ownership: 0.0,
    votingPower: 5,
    member: false,
    invited: new Date(),
    applied: new Date(),
    joined: new Date(),
  },
  {
    name: "Zane B",
    image: EMPTY_STRING,
    clubMember: ClubMember.Founder,
    ownership: 0.0,
    votingPower: 5,
    member: true,
    invited: new Date(),
    applied: new Date(),
    joined: new Date(new Date().setDate(new Date().getDate() - 15)),
  },
  {
    name: "Pate B",
    image: EMPTY_STRING,
    clubMember: ClubMember.Member,
    ownership: 0.0,
    votingPower: 5,
    member: false,
    invited: new Date(),
    applied: new Date(),
    joined: new Date(),
  },
  {
    name: "Aane B",
    image: EMPTY_STRING,
    clubMember: ClubMember.Founder,
    ownership: 0.0,
    votingPower: 5,
    member: true,
    invited: new Date(),
    applied: new Date(),
    joined: new Date(new Date().setDate(new Date().getDate() - 23)),
  },
  {
    name: "Kate B",
    image: EMPTY_STRING,
    clubMember: ClubMember.Member,
    ownership: 0.0,
    votingPower: 5,
    member: true,
    invited: new Date(),
    applied: new Date(),
    joined: new Date(new Date().setDate(new Date().getDate() - 3)),
  },
];

export const MEMBER = {
  name: "Jane B",
  image: EMPTY_STRING,
  clubMember: ClubMember.Founder,
  ownership: 0.0,
  votingPower: 5,
  joined: new Date(new Date().setDate(new Date().getDate() - 13)),
};

export const PROFILE_IMAGES = [
  members,
  members,
  members,
  members,
  members,
  members,
  members,
];
