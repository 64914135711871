import "./StickyHeader.scss";
import info from "../../assets/info.svg";
import MainButton from "../MainButton/MainButton";
import { clubStore } from "../../state/clubStore";
import HoverIcon from "../HoverIcon/HoverIcon";
import Chip from "../Chip/Chip";
import { WHITE300 } from "../../common/constants/layout.constants";
import TreasuryDropdown from "../TreasuryDropdown/TreasuryDropdown";
import { FC, Fragment, useState } from "react";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import ClubConfigurationModal from "../ClubConfigurationModal/ClubConfigurationModal";
import AddNewMemberModal from "../../pages/Members/AddNewMemberModal/AddNewMemberModal";
import { checkIfUserHasClubPermissionForAction } from "../../utilities/helpers";
import { ApiClubActions, ClubAction } from "../../common/enums/clubs.enum";
import ClubApplicationModal from "../../pages/Overview/ClubApplicationModal/ClubApplicationModal";
import clubAvatar from "../../assets/unique_avatar.png";

const StickyHeader: FC<{ treasury: boolean }> = ({ treasury }) => {
  const [isConfigurationOpen, toggleConfigurationModal] = useState(false);
  const [showClubApplication, setShowApplicationModal] = useState(false);
  const [showInviteMembersModal, setShowMembersModal] = useState(false);

  const { clubBasicInfo, memberData } = clubStore();

  return (
    <Fragment>
      {isConfigurationOpen && (
        <ClubConfigurationModal
          closeModal={() => toggleConfigurationModal(false)}
        />
      )}
      {showClubApplication && (
        <ClubApplicationModal onClose={() => setShowApplicationModal(false)} />
      )}
      {showInviteMembersModal && (
        <AddNewMemberModal closeModal={() => setShowMembersModal(false)} />
      )}
      <div
        className="sticky-header"
        style={{
          paddingBottom: treasury ? "4em" : EMPTY_STRING,
        }}
      >
        <div className="sticky-header__deal-details">
          <img
            src={
              clubBasicInfo?.imgUrl && clubBasicInfo?.imgUrl !== EMPTY_STRING
                ? clubBasicInfo?.imgUrl
                : clubAvatar
            }
            alt="profile"
          />
          <h2>{clubBasicInfo?.name}</h2>
        </div>
        <div className="sticky-header__options">
          <HoverIcon
            src={info}
            alt={"Info"}
            onClick={() => toggleConfigurationModal(true)}
          />
          {memberData?.isMember && (
            <Chip text={memberData.role} backgroundColor={WHITE300} />
          )}
          <div className="sticky-header__options-divider"></div>
          {checkIfUserHasClubPermissionForAction(
            memberData,
            clubBasicInfo?.roleConfig,
            ClubAction.AllowMember
          ) && (
            <MainButton
              type="button"
              onClick={() => setShowMembersModal(true)}
              light
            >
              Invite Members
            </MainButton>
          )}
          {!memberData && (
            <MainButton
              type="button"
              onClick={() => setShowApplicationModal(true)}
            >
              Join this Club
            </MainButton>
          )}
        </div>
      </div>
      {treasury && <TreasuryDropdown />}
    </Fragment>
  );
};

export default StickyHeader;
