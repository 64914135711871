import "./TokenList.scss";
import TokenItem from "../TokenItem/TokenItem";
import { clubStore } from "../../../state/clubStore";
import CardWrapper from "../../../components/CardWrapper/CardWrapper";
import React, { FC } from "react";
const TokenList: FC = () => {
  const { activeTreasury } = clubStore();

  return (
    <CardWrapper backgroundColor="white" padding="2em">
      <div className="tokens">
        <ul className="tokens__list">
          {activeTreasury?.treasuryTokens.map((item, index) => (
            <TokenItem token={item} key={index} />
          ))}
        </ul>
      </div>
    </CardWrapper>
  );
};
export default TokenList;
