import React, { FC, ReactNode } from "react";
import "./TabHeader.scss";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import { TabLabel } from "../../common/enums/common.enum";

const TabHeader: FC<{
  children?: ReactNode;
  padding?: string;
  tabs: TabLabel[];
  activeTab: string;
  onClick: (tab: TabLabel) => void;
}> = ({ children, padding, tabs, onClick, activeTab }) => {
  return (
    <div
      className="tab-header"
      style={{ padding: padding ? padding : EMPTY_STRING }}
    >
      <ul>
        {tabs.map((tab) => (
          <li
            onClick={() => {
              onClick(tab);
            }}
            className={
              tab === activeTab ? "tab-header__tab--active" : "tab-header__tab"
            }
          >
            {tab}
          </li>
        ))}
      </ul>
      {children}
    </div>
  );
};

export default TabHeader;
