import { CLIENT_CONFIGURATION } from "../../client/configuration";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import { FundraiseModel } from "../../common/enums/deals.enum";

export default {
  formId: "create-treasury",
  formFields: {
    treasuryName: {
      name: "treasuryName",
      value: EMPTY_STRING,
      label: "Treasury name",
    },
    chain: {
      name: "chain",
      value: EMPTY_STRING,
      label: "Chain",
    },
    denominatedCurrency: {
      name: "denominatedCurrency",
      value: EMPTY_STRING,
      label: "Denominated currency",
    },
    approvalQuorum: {
      name: "approvalQuorum",
      value: EMPTY_STRING,
      label: "Approval vote percentage",
    },
    maxVotingTime: {
      name: "maxVotingTime",
      value: EMPTY_STRING,
      label: "Max voting time",
    },
    fundraiseModel: {
      name: "fundraiseModel",
      label: "Fundraise model",
      value: FundraiseModel.Token,
    },
    treasuryRoles: {
      name: "treasuryRoles",
      label: "Treasury roles",
      value: CLIENT_CONFIGURATION.treasuryRoles,
    },
    members: {
      name: "members",
      value: [],
      label: "Members",
    },
  },
};
