export const UPDATE_ROLE_CONFIG_DISCRIMINATOR = Buffer.from(
  new Uint8Array([125, 20, 213, 135, 48, 221, 67, 221])
);

export const CHANGE_GOV_CONFIG_DISCRIMATOR = Buffer.from(
  new Uint8Array([81, 166, 117, 113, 244, 61, 188, 133])
);

export const FUNDRAISE_PROPOSAL_DISCRIMINATOR = new Uint8Array([
  131, 84, 228, 72, 48, 100, 238, 108,
]);

export const ADD_SELL_PERMISSION_DISCRIMANOTOR = new Uint8Array([
  31, 3, 191, 151, 142, 130, 51, 45,
]);
