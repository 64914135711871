import { EMPTY_STRING } from "../../../common/constants/common.constants";
import { ISharesOffer } from "../../../common/interfaces/form.interface";
import { IOTCDeal } from "../../../common/interfaces/otc.interface";
import {
  IMemberTreasuryInfo,
  ITreasuryData,
} from "../../../common/interfaces/club.interface";
import { getAmountWithDecimalsForCurrency } from "../../../utilities/helpers";

export const validateSharesForm = (
  values: ISharesOffer,
  otc?: IOTCDeal,
  memberTreasuryInfo?: IMemberTreasuryInfo,
  activeTreasury?: ITreasuryData,
  selling?: boolean
) => {
  const errors: any = {};

  if (values.sharesAmount === EMPTY_STRING) {
    errors.sharesAmount = "Can not be empty";
  }

  if (values.sharesPercentage === EMPTY_STRING) {
    errors.sharesPercentage = "Can not be empty";
  }

  if (Number(values.sharesPercentage) > 100) {
    errors.sharesPercentage = "Percentage can not be greater than 100";
  }

  if (Number(values.price) < 0) {
    errors.price = "Price can not be negative";
  }

  if (otc) {
    const amountOfRights = getAmountWithDecimalsForCurrency(
      activeTreasury?.currencyDecimals,
      otc.amountOfRights
    );
    if (amountOfRights) {
      if (Number(values.sharesAmount) > amountOfRights) {
        errors.sharesAmount =
          "Amount can not be greater than the amount offered";
      }
    }
  }

  if (memberTreasuryInfo && selling) {
    const availableShares = getAmountWithDecimalsForCurrency(
      activeTreasury?.currencyDecimals,
      memberTreasuryInfo.availableShares
    );
    if (availableShares) {
      if (Number(values.sharesAmount) > availableShares!) {
        errors.sharesAmount = "You do not have that many available shares";
      }
    }
  }

  return errors;
};
