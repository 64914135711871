import { EMPTY_STRING } from "./common.constants";

export const ClubsTableTitles = [
  "Club",
  "Role",
  "Your ETH wallet",
  "Fundraise",
  "Balance",
  "Ownership",
  "Members",
  "NFTs",
  "Proposals",
  "Staking",
];

export const TreasuriesTableTitles = [
  "Club",
  "Role",
  // "Your ETH Wallet",
  "Currency",
  "Fundraise",
  "Ownership",
  "Balance",
  "Your Deposit",
];

export const ProposalsTableTitles = [
  "Treasury",
  "Name",
  "Type",
  "Status",
  "Accepted",
  "Rejected",
  EMPTY_STRING,
];

export const WithdrawalsTableTitles = [
  "Treasury",
  "Name",
  "Total Withdrawal",
  "User Withdrawal",
  "Date",
  EMPTY_STRING,
];

export const StakingTableTitles = [
  "Name",
  "Item",
  "Status",
  "Period",
  "Deposited",
  "Reward",
  EMPTY_STRING,
];

export const MembershipTableTitles = [
  "Club",
  "Type",
  "Role",
  "Date",
  EMPTY_STRING,
];
