import React, { useEffect, useMemo, useState } from "react";
import "./NotificationModal.scss";
import {
  GET_USER_NOTIFICATIONS,
  MARK_ALL_NOT_AS_READ,
} from "../../api/club.api";
import { useLazyQuery, useMutation } from "@apollo/client";
import { userStore } from "../../state/userStore";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import NotificationItem from "./NotificationItem/NotificationItem";
import { NotificationActiveTab } from "../../common/enums/common.enum";
import { apolloClient } from "../../api/config.api";

const NotificationModal = () => {
  const { setNotifications, notifications } = userStore();
  const [activeTab, setActiveTab] = useState(NotificationActiveTab.All);
  const [markAllAsRead] = useMutation(MARK_ALL_NOT_AS_READ);

  const wallet = useAnchorWallet();

  const unreadNotifications = useMemo(() => {
    return notifications.filter((item) => !item.hasRead).length;
  }, [notifications]);

  const renderNotifications = useMemo(() => {
    let renderNotificationsItems = [...notifications];
    if (activeTab === NotificationActiveTab.Unread) {
      renderNotificationsItems = renderNotificationsItems.filter(
        (item) => !item.hasRead
      );
    }
    return renderNotificationsItems.map((item) => (
      <NotificationItem notification={item} />
    ));
  }, [notifications, activeTab]);

  const markAllAsReadHandler = async () => {
    await markAllAsRead({
      variables: {
        userWallet: wallet?.publicKey,
      },
    });
    apolloClient.refetchQueries({
      include: [GET_USER_NOTIFICATIONS],
    });
  };

  return (
    <div className="notification-modal">
      <div className="notification-modal__header">
        <h4 className="notification-modal__title">Notifications</h4>
        <div className="notification-modal__actions">
          <div className="notification-modal__tabs">
            <button
              onClick={() => setActiveTab(NotificationActiveTab.All)}
              className={`notification-modal__button ${
                activeTab === NotificationActiveTab.All &&
                "notification-modal__button--active"
              }`}
            >
              All ({notifications.length})
            </button>
            <button
              onClick={() => setActiveTab(NotificationActiveTab.Unread)}
              className={`notification-modal__button ${
                activeTab === NotificationActiveTab.Unread &&
                "notification-modal__button--active"
              }`}
            >
              Unread ({unreadNotifications})
            </button>
          </div>
          <button
            className="notification-modal__button"
            onClick={markAllAsReadHandler}
          >
            Mark all as read
          </button>
        </div>
      </div>
      <div className="notification-modal__items">{renderNotifications}</div>
    </div>
  );
};

export default NotificationModal;
