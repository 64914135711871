import React, { FC, useState } from "react";
import "./ProposalDescription.scss";
import dropdownIcon from "../../../assets/arrow_down.svg";
import { ProposalFormInfo } from "../../../common/constants/proposal.constants";
import { ProposalType } from "../../../common/enums/proposal.enum";
import { useFormikContext } from "formik";
import { IProposalFormFields } from "../../../common/interfaces/form.interface";
import proposalFormConfig from "../proposalFormConfig";
import { CLIENT_CONFIGURATION } from "../../../client/configuration";

const ProposalDescription: FC<{
  title: ProposalType;
  showProposalForm: (proposal: ProposalType) => void;
}> = ({ title, showProposalForm }) => {
  const [proposalList, toggleProposalList] = useState(false);
  const { setFieldValue } = useFormikContext<IProposalFormFields>();

  const getProposalDescription = (title: ProposalType) => {
    const arr = ProposalFormInfo.filter((info) => info.title === title);
    const description = arr.map((info) => info.description);
    return description[0];
  };

  return (
    <div className="proposal-description">
      <div className="proposal-description__flex">
        <h2>{title}</h2>
        <img
          src={dropdownIcon}
          alt="Dropdown arrow"
          onClick={() => toggleProposalList(!proposalList)}
        />
      </div>
      {proposalList && (
        <>
          <ul>
            {CLIENT_CONFIGURATION.proposalTypes.map((proposal, index) => (
              <li
                key={index}
                onClick={() => {
                  showProposalForm(proposal);
                  setFieldValue(
                    proposalFormConfig.formFields.proposalType.name,
                    proposal
                  );
                  toggleProposalList(false);
                }}
              >
                {proposal}
              </li>
            ))}
          </ul>
          <div
            className="backdrop"
            onClick={() => toggleProposalList(false)}
          ></div>
        </>
      )}
      <p>{getProposalDescription(title)}</p>
    </div>
  );
};

export default ProposalDescription;
