import "./TokenItem.scss";
import { ITreasuryTokenAccInfo } from "../../../common/interfaces/club.interface";
import React, { FC } from "react";
import { getAmountWithDecimalsForCurrency } from "../../../utilities/helpers";
const TokenItem: FC<{ token: ITreasuryTokenAccInfo }> = ({ token }) => {
  return (
    <div className="token">
      <div className="token__info">
        <div className="token__logo">
          <img src={token.image} className="token__logo-img" alt="token-logo" />
        </div>
        <label className="token__name">{token.name}</label>
      </div>

      <div>
        <label className="token__amount">
          {token.balance
            ? getAmountWithDecimalsForCurrency(token.decimals, token.balance)
            : 0}
          <span className="token__amount-currency"> {token.symbol}</span>
        </label>
      </div>
    </div>
  );
};
export default TokenItem;
