import React, { FC, useEffect, useState } from "react";
import "./SearchBar.scss";
import search from "../../assets/search.png";
import { EMPTY_STRING } from "../../common/constants/common.constants";

const SearchBar: FC<{
  setSearchValue: (value: string) => void;
  initialValue?: string;
}> = ({ setSearchValue, initialValue }) => {
  const [value, setValue] = useState(initialValue ?? EMPTY_STRING);

  useEffect(() => {
    const timer = setTimeout(() => setSearchValue(value), 500);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder="Search"
        onChange={(e) => setValue(e.target.value)}
      />
      <img src={search} alt="Search" />
    </div>
  );
};

export default SearchBar;
