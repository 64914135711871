import React, { FC } from "react";
import "./NoData.scss";
import MainButton from "../MainButton/MainButton";
import add from "../../assets/add_circle_full.png";
import { EMPTY_STRING } from "../../common/constants/common.constants";

const NoData: FC<{
  message: string;
  mainButtonText?: string;
  mainButtonOnClick?: () => void;
  height?: string;
  noIcon?: boolean;
  secondButtonText?: string;
  disabled?: boolean;
}> = ({
  message,
  mainButtonText,
  height,
  noIcon,
  secondButtonText,
  mainButtonOnClick,
  disabled,
}) => {
  return (
    <div className="no-data" style={{ height: height ? height : EMPTY_STRING }}>
      <p>{message}</p>
      {mainButtonText && mainButtonOnClick && (
        <MainButton
          light
          onClick={mainButtonOnClick}
          type="button"
          disabled={disabled}
        >
          {!noIcon && <img src={add} alt="Add" />}
          {mainButtonText}
        </MainButton>
      )}
      {secondButtonText && (
        <MainButton light onClick={() => console.log("click")} type="button">
          {!noIcon && <img src={add} alt="Add" />}
          {secondButtonText}
        </MainButton>
      )}
    </div>
  );
};

export default NoData;
