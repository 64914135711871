import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import MainButton from "../../MainButton/MainButton";
import React, { useCallback, useEffect, useMemo } from "react";
import { AllocationLimitation } from "../../../common/enums/fundraise.enum";
import MultiToggle from "../../MultiToggle/MultiToggle";
import createFundraiseFormConfig from "../createFundraiseFormConfig";
import InputField from "../../InputFields/InputField";
import cancelIcon from "../../../assets/cancel.svg";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import {
  ICreateFundraiseFormFields,
  IProposalFormFields,
} from "../../../common/interfaces/form.interface";
import "./CreateAllocation.scss";
import { IOption } from "../../../common/interfaces/common.interface";
import { clubStore } from "../../../state/clubStore";
import { getTrimmedPublicKey } from "../../../utilities/helpers";
import add from "../../../assets/add_circle_full.png";
import { IMemberData } from "../../../common/interfaces/club.interface";
import { useLazyQuery } from "@apollo/client";
import { GET_NON_FILTERED_MEMBERS_FOR_CLUB } from "../../../api/club.api";

const CreateAllocation: React.FC<{ formFields: any }> = ({ formFields }) => {
  const { values, errors, setFieldValue } = useFormikContext<
    ICreateFundraiseFormFields | IProposalFormFields
  >();
  const { clubBasicInfo, activeTreasury } = clubStore();

  const [getMembers, membersInfo] = useLazyQuery(
    GET_NON_FILTERED_MEMBERS_FOR_CLUB
  );

  useEffect(() => {
    void getClubMembers();
  }, [clubBasicInfo]);

  const getClubMembers = async () => {
    try {
      if (!clubBasicInfo) return;
      getMembers({
        variables: {
          clubAddress: clubBasicInfo?.address,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addMemberItem = (arrayHelper: FieldArrayRenderProps) => {
    arrayHelper.push({
      wallet: [],
      membersAmount: EMPTY_STRING,
    });
  };

  const removeOption = useCallback(
    (index: number, arrayHelpers: FieldArrayRenderProps) => {
      arrayHelpers.remove(index);
    },
    []
  );

  const options: IOption[] = useMemo(
    () =>
      membersInfo.data?.getNonFilteredClubMembers
        ? membersInfo.data?.getNonFilteredClubMembers.map(
            (member: IMemberData) => {
              return {
                label: getTrimmedPublicKey(member.memberPubkey),
                value: member.memberPubkey,
              };
            }
          )
        : [],
    [membersInfo.data]
  );

  return (
    <div className="create-allocation">
      <h2>Allocation</h2>
      <h5>
        Define how much members can deposit into Fundraise. You can assign
        custom allocations to specific members.
      </h5>
      <div className="create-allocation__multi-toggle">
        <MultiToggle
          first
          isSelected={
            values.allocationLimitation === AllocationLimitation.Unlimited
          }
          label="Unlimited"
          onClick={() =>
            setFieldValue(
              formFields.allocationLimitation.name,
              AllocationLimitation.Unlimited
            )
          }
        />
        <MultiToggle
          last
          isSelected={
            values.allocationLimitation === AllocationLimitation.Limited
          }
          label="Limited"
          onClick={() =>
            setFieldValue(
              formFields.allocationLimitation.name,
              AllocationLimitation.Limited
            )
          }
        />
      </div>
      {values.allocationLimitation === AllocationLimitation.Limited && (
        <div>
          <InputField
            name={createFundraiseFormConfig.formFields.tokensMembersAmount.name}
            type="text"
            labelTitle={
              createFundraiseFormConfig.formFields.tokensMembersAmount.label
            }
            placeholder={
              createFundraiseFormConfig.formFields.tokensMembersAmount.label
            }
            badge={activeTreasury?.currencySymbol}
          />
        </div>
      )}
      <h2>Custom allocation</h2>
      <h5>Allocate custom amounts to specific or all Club members.</h5>
      <div className="create-allocation__custom">
        <FieldArray
          name={createFundraiseFormConfig.formFields.members.name}
          render={(arrayHelpers) => (
            <React.Fragment>
              {values.members.map((item, index) => (
                <div className="add-member-form__fields">
                  <div className="create-allocation__grid">
                    <img
                      src={cancelIcon}
                      alt="Cancel icon"
                      onClick={() => removeOption(index, arrayHelpers)}
                    />
                    <div>
                      <InputField
                        name={`members.${index}.wallet`}
                        type="select"
                        labelTitle="Members"
                        placeholder="Enter wallet address"
                        multiple
                        options={options}
                      />
                    </div>
                    <InputField
                      name={`members.${index}.membersAmount`}
                      type="text"
                      labelTitle="Amount"
                      placeholder="Enter members amount"
                      badge={activeTreasury?.currencySymbol}
                    />
                  </div>
                </div>
              ))}
              <div className="create-allocation__custom-actions">
                <MainButton
                  onClick={() => {
                    addMemberItem(arrayHelpers);
                  }}
                  type="button"
                  light
                >
                  <img src={add} alt="Add" />
                  New rule
                </MainButton>
              </div>
            </React.Fragment>
          )}
        ></FieldArray>
      </div>
    </div>
  );
};
export default CreateAllocation;
