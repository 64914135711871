import { AnchorWallet } from "@solana/wallet-adapter-react";
import { DealType, KycType } from "../../common/enums/deals.enum";
import * as SplGovernance from "@solana/spl-governance";
import {
  AccountMeta,
  PublicKey,
  SYSVAR_RENT_PUBKEY,
  SystemProgram,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  CLUB_PROGRAM_ID,
  RPC_CONNECTION,
  UNQ_SPL_GOVERNANCE_PROGRAM_ID,
  mint,
  programFactory,
} from "../utils";
import { BN } from "@project-serum/anchor";
import {
  adminSeed,
  denominatedTokenSeed,
  financialOfferSeed,
  financialRecordSeed,
  fundraiseCfgSeed,
  profitSeed,
  syndicateAuthoritySeed,
  treasuryDataSeed,
  treasurySeed,
  unqClubMemberSeed,
  unqClubSeed,
  vaultDataSeed,
  vaultSeed,
  voterWeightSeed,
} from "../../common/constants/seeds.constants";
import { NATIVE_MINT, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import {
  GovernanceDto,
  ReservedRightsDto,
  RoleDto,
  TreasuryRoleDto,
} from "../../common/dtos/club.dto";
import {
  getOrCreateATAOwnedBySpecifiedAccount,
  getRemainingAccountForCreateGovernance,
  getRemainingAccountsForDeleteMember,
  getRemainingAccountsForFinishSyndicateFundraise,
  getUpdateVoterWeightInput,
  parseClubActionEnum,
  parseEnum,
} from "../program-helpers";
import {
  ICustomAllocationForProgram,
  IMemberData,
  ITreasuryData,
} from "../../common/interfaces/club.interface";
import {
  IBasicNftInfo,
  IInstructionSet,
} from "../../common/interfaces/common.interface";
import { sendTransactions } from "../sendTransactions";
import { CLIENT_CONFIGURATION } from "../../client/configuration";
import {
  AllowType,
  ClubAction,
  MemberStatus,
  TreasuryAction,
  TreasuryGovernanceType,
  UpdateVWActionType,
} from "../../common/enums/clubs.enum";
import { governanceSeed } from "../../common/constants/seeds.constants";
import { saveTokenUnlockingData, storeClubBasicInfo } from "../../api/club.api";
import { IVertical } from "../../common/interfaces/club.interface";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import {
  IAddMember,
  IMemberWallets,
  IMembersAndPercentages,
  IRoleConfig,
} from "../../common/interfaces/form.interface";
import { IClubData } from "../../common/interfaces/club.interface";
import { SequenceType } from "../../common/enums/common.enum";
import { SupportAction } from "../../common/enums/fundraise.enum";
import { FundraiseAction } from "../../common/enums/fundraise.enum";
import { findProgramAddressSync } from "@project-serum/anchor/dist/cjs/utils/pubkey";
import { IExistingInvestment } from "../../common/interfaces/treasury.interface";
import { extractNumbersFromString } from "../../utilities/helpers";
import { chunk } from "@metaplex-foundation/js";
import {
  clubActionToCamelCase,
  treasuryActionToCamelCase,
} from "../../common/constants/club.constants";
import { pow } from "mathjs";

export const createClub = async (
  creator: AnchorWallet,
  clubName: string,
  roles: IRoleConfig[],
  treasuryRoles: IRoleConfig[],
  kycRequired: boolean,
  treasuryName: string,
  approvalQuorum: number,
  maxVotingTime: number,
  chainId: string,
  verticals: IVertical[],
  description: string,
  kycType?: KycType,
  denominatedCurrency?: string,
  image?: string
) => {
  try {
    const program = programFactory();
    const instructionSet: IInstructionSet[] = [];
    let remainingAccounts: AccountMeta[] = [];
    let createTreasuryRemainingAccounts: AccountMeta[] = [];
    let createGovernancesRemainingAccounts: AccountMeta[] = [];

    const createRealmAndTokenOwnerRecordIxs: TransactionInstruction[] = [];

    const realmAddress = await SplGovernance.withCreateRealm(
      createRealmAndTokenOwnerRecordIxs,
      UNQ_SPL_GOVERNANCE_PROGRAM_ID,
      SplGovernance.PROGRAM_VERSION_V2,
      clubName,
      creator.publicKey,
      mint,
      creator.publicKey,
      undefined,
      SplGovernance.MintMaxVoteWeightSource.FULL_SUPPLY_FRACTION,
      new BN(1),
      program.programId,
      program.programId
    );

    const realmConfigAddress = await SplGovernance.getRealmConfigAddress(
      UNQ_SPL_GOVERNANCE_PROGRAM_ID,
      realmAddress
    );

    const creatorTokenOwnerRecord =
      await SplGovernance.withCreateTokenOwnerRecord(
        createRealmAndTokenOwnerRecordIxs,
        UNQ_SPL_GOVERNANCE_PROGRAM_ID,
        realmAddress,
        creator.publicKey,
        mint,
        creator.publicKey
      );

    const [clubDataAddress] = PublicKey.findProgramAddressSync(
      [unqClubSeed, realmAddress.toBuffer()],
      program.programId
    );
    const [memberAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        clubDataAddress.toBuffer(),
        unqClubMemberSeed,
        creator.publicKey.toBuffer(),
      ],
      program.programId
    );

    //Ask for permissions
    const roleConfig: RoleDto[] = [];
    roles
      .filter((item) => item.chosen)
      .forEach((item) => {
        roleConfig.push(
          new RoleDto(item.name, new BN(item.votingPower), item.actions)
        );
      });
    //Ask for permissions
    const treasuryRoleConfig: TreasuryRoleDto[] = [];
    treasuryRoles
      .filter((item) => item.chosen)
      .forEach((item) => {
        treasuryRoleConfig.push(
          new TreasuryRoleDto(
            item.name,
            new BN(item.votingPower),
            item.actions,
            item.default
          )
        );
      });

    if (CLIENT_CONFIGURATION.dealType === DealType.Syndicate) {
      const adminsAddress = PublicKey.findProgramAddressSync(
        [unqClubSeed, adminSeed],
        program.programId
      )[0];
      remainingAccounts.push({
        isSigner: false,
        isWritable: false,
        pubkey: adminsAddress,
      });
    }
    const createClubIx = await program.methods
      .createClub(
        clubName,
        parseEnum(DealType, CLIENT_CONFIGURATION.dealType),
        roleConfig,
        roles.find((item) => item.required)?.name ?? "founder",
        roles.find((item) => item.default)?.name ?? "member",
        {
          required: kycRequired,
          location: parseEnum(KycType, kycType ?? KycType.All),
        }
      )
      .accounts({
        clubData: clubDataAddress,
        memberData: memberAddress,
        payer: creator.publicKey,
        realm: realmAddress,
        realmConfig: realmConfigAddress,
        rent: SYSVAR_RENT_PUBKEY,
        systemProgram: SystemProgram.programId,
        tokenOwnerRecord: creatorTokenOwnerRecord,
        tokenProgram: TOKEN_PROGRAM_ID,
      })
      .remainingAccounts(remainingAccounts)
      .instruction();
    createRealmAndTokenOwnerRecordIxs.push(createClubIx);

    //Setup first treasury
    const treasuryIndexBuffer = Buffer.alloc(4);
    treasuryIndexBuffer.writeInt32LE(1, 0);

    const [treasuryAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        clubDataAddress.toBuffer(),
        treasurySeed,
        treasuryIndexBuffer,
      ],
      program.programId
    );

    const [treasuryDataAddress] = PublicKey.findProgramAddressSync(
      [unqClubSeed, treasuryAddress.toBuffer(), treasuryDataSeed],
      program.programId
    );

    const [vaultAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        clubDataAddress.toBuffer(),
        treasuryAddress.toBuffer(),
        vaultSeed,
        Buffer.from(chainId),
      ],
      program.programId
    );

    const [vaultDataAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        clubDataAddress.toBuffer(),
        treasuryAddress.toBuffer(),
        vaultDataSeed,
        Buffer.from(chainId),
      ],
      program.programId
    );

    const [profitAddress] = PublicKey.findProgramAddressSync(
      [unqClubSeed, treasuryAddress.toBuffer(), profitSeed],
      program.programId
    );

    const [financialRecord] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        treasuryDataAddress.toBuffer(),
        financialRecordSeed,
        creator.publicKey.toBuffer(),
      ],
      program.programId
    );

    if (denominatedCurrency) {
      const [denominatedToken] = PublicKey.findProgramAddressSync(
        [unqClubSeed, treasuryAddress.toBuffer(), denominatedTokenSeed],
        program.programId
      );
      createTreasuryRemainingAccounts.push(
        ...[
          {
            pubkey: new PublicKey(denominatedCurrency),
            isWritable: false,
            isSigner: false,
          },
          {
            pubkey: denominatedToken,
            isSigner: false,
            isWritable: true,
          },
        ]
      );
    }

    let treasuryInstructions: TransactionInstruction[] = [];

    let treasuryRealmName = clubName.concat((1).toString());

    const treasuryRealmAddress = await SplGovernance.withCreateRealm(
      treasuryInstructions,
      UNQ_SPL_GOVERNANCE_PROGRAM_ID,
      SplGovernance.PROGRAM_VERSION_V2,
      treasuryRealmName,
      treasuryAddress,
      mint,
      creator.publicKey,
      undefined,
      SplGovernance.MintMaxVoteWeightSource.FULL_SUPPLY_FRACTION,
      new BN(1),
      program.programId,
      program.programId
    );

    const treasuryTokenOwnerRecordAddress =
      await SplGovernance.withCreateTokenOwnerRecord(
        treasuryInstructions,
        UNQ_SPL_GOVERNANCE_PROGRAM_ID,
        treasuryRealmAddress,
        creator.publicKey,
        mint,
        creator.publicKey
      );
    const createTreasuryIx = await program.methods
      .createTreasury(
        treasuryName,
        chainId,
        CLIENT_CONFIGURATION.nftMaxVoterWeight,
        treasuryRoleConfig
      )
      .accounts({
        clubData: clubDataAddress,
        financialRecord,
        memberData: memberAddress,
        payer: creator.publicKey,
        profit: profitAddress,
        realm: treasuryRealmAddress,
        tokenOwnerRecord: treasuryTokenOwnerRecordAddress,
        splGovernance: UNQ_SPL_GOVERNANCE_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
        treasury: treasuryAddress,
        treasuryData: treasuryDataAddress,
        vault: vaultAddress,
        vaultData: vaultDataAddress,
      })
      .remainingAccounts(createTreasuryRemainingAccounts)
      .instruction();
    treasuryInstructions.push(createTreasuryIx);

    const { governancesInstructions } = await createGovernanceIx(
      memberAddress,
      creator,
      treasuryDataAddress,
      clubDataAddress,
      treasuryRealmAddress,
      maxVotingTime,
      approvalQuorum,
      treasuryAddress,
      chainId,
      treasuryTokenOwnerRecordAddress
    );

    instructionSet.push({
      description: "Create club",
      instructions: createRealmAndTokenOwnerRecordIxs,
    });
    instructionSet.push({
      description: "Create initial treasury",
      instructions: treasuryInstructions,
    });
    instructionSet.push({
      description: "Create governances for initial treasury",
      instructions: governancesInstructions,
    });
    await sendTransactions(
      RPC_CONNECTION,
      creator,
      instructionSet,
      SequenceType.Sequential,
      true
    );
    await storeClubBasicInfo(
      verticals,
      image ?? EMPTY_STRING,
      clubDataAddress.toString(),
      description
    );
    return clubDataAddress.toString();
  } catch (error) {
    throw error;
  }
};

const createGovernanceIx = async (
  memberAddress: PublicKey,
  creator: AnchorWallet,
  treasuryDataAddress: PublicKey,
  clubDataAddress: PublicKey,
  treasuryRealmAddress: PublicKey,
  maxVotingTime: number,
  approvalQuorum: number,
  treasuryAddress: PublicKey,
  chainId: string,
  treasuryTokenOwnerRecordAddress: PublicKey
) => {
  try {
    const program = programFactory();
    let governancesInstructions: TransactionInstruction[] = [];
    const createGovernancesRemainingAccounts: AccountMeta[] = [];
    const { voterWeightIx, voterWeightAddress } = await updateVoterWeight(
      memberAddress,
      creator.publicKey,
      ClubAction.CreateTreasuryGovernance,
      treasuryDataAddress,
      clubDataAddress,
      treasuryRealmAddress,
      UpdateVWActionType.ClubAction
    );
    governancesInstructions.push(voterWeightIx);
    const governancesDtos: GovernanceDto[] = [];

    const withdrawalGovernance = new GovernanceDto(
      TreasuryGovernanceType.Withdrawal,
      maxVotingTime,
      CLIENT_CONFIGURATION.withdrawalGovernanceQuorum !== undefined
        ? CLIENT_CONFIGURATION.withdrawalGovernanceQuorum
        : approvalQuorum,
      null
    );
    createGovernancesRemainingAccounts.push(
      ...(await getRemainingAccountForCreateGovernance(
        TreasuryGovernanceType.Withdrawal,
        treasuryDataAddress,
        treasuryAddress,
        treasuryRealmAddress,
        clubDataAddress,
        chainId
      ))
    );
    const transferGovernance = new GovernanceDto(
      TreasuryGovernanceType.Transfer,
      maxVotingTime,
      CLIENT_CONFIGURATION.transferGovernanceQuorum !== undefined
        ? CLIENT_CONFIGURATION.transferGovernanceQuorum
        : approvalQuorum,
      null
    );
    createGovernancesRemainingAccounts.push(
      ...(await getRemainingAccountForCreateGovernance(
        TreasuryGovernanceType.Transfer,
        treasuryDataAddress,
        treasuryAddress,
        treasuryRealmAddress,
        clubDataAddress,
        chainId
      ))
    );

    const governanceChangeGovernance = new GovernanceDto(
      TreasuryGovernanceType.GovernanceChange,
      maxVotingTime,
      CLIENT_CONFIGURATION.changeGovernanceQuorum !== undefined
        ? CLIENT_CONFIGURATION.changeGovernanceQuorum
        : approvalQuorum,
      null
    );
    createGovernancesRemainingAccounts.push(
      ...(await getRemainingAccountForCreateGovernance(
        TreasuryGovernanceType.GovernanceChange,
        treasuryDataAddress,
        treasuryAddress,
        treasuryRealmAddress,
        clubDataAddress,
        chainId
      ))
    );
    const treasuryGovernance = new GovernanceDto(
      TreasuryGovernanceType.Treasury,
      maxVotingTime,
      approvalQuorum,
      null
    );
    createGovernancesRemainingAccounts.push(
      ...(await getRemainingAccountForCreateGovernance(
        TreasuryGovernanceType.Treasury,
        treasuryDataAddress,
        treasuryAddress,
        treasuryRealmAddress,
        clubDataAddress,
        chainId
      ))
    );
    governancesDtos.push(withdrawalGovernance);
    governancesDtos.push(transferGovernance);
    governancesDtos.push(governanceChangeGovernance);
    governancesDtos.push(treasuryGovernance);

    const createGovernanceIx = await program.methods
      .createGovernance(governancesDtos)
      .accounts({
        realm: treasuryRealmAddress,
        clubData: clubDataAddress,
        memberData: memberAddress,
        payer: creator.publicKey,
        realmConfig: await SplGovernance.getRealmConfigAddress(
          UNQ_SPL_GOVERNANCE_PROGRAM_ID,
          treasuryRealmAddress
        ),
        splGovernanceProgram: UNQ_SPL_GOVERNANCE_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        tokenOwnerRecord: treasuryTokenOwnerRecordAddress,
        treasuryData: treasuryDataAddress,
        voterWeightRecord: voterWeightAddress,
      })
      .remainingAccounts(createGovernancesRemainingAccounts)
      .instruction();

    governancesInstructions.push(createGovernanceIx);
    return {
      governancesInstructions,
      voterWeightAddress,
      voterWeightIx,
    };
  } catch (error) {
    throw error;
  }
};

export const createClubTreasury = async (
  treasuryRoles: IRoleConfig[],
  treasuryIndex: number,
  clubDataAddress: PublicKey,
  memberDataAddress: PublicKey,
  chainId: string,
  creatorWallet: AnchorWallet,
  treasuryName: string,
  clubName: string,
  approvalQuorum: number,
  maxVotingTime: number,
  denominatedCurrency?: string,
  membersWithPercentages?: IMemberWallets[]
) => {
  try {
    const program = programFactory();
    const instructionSet: IInstructionSet[] = [];
    const treasuryRealmName = clubName.concat((treasuryIndex + 1).toString());

    let createTreasuryRemainingAccounts: AccountMeta[] = [];
    //Ask for permissions
    const treasuryRoleConfig: TreasuryRoleDto[] = [];
    treasuryRoles
      .filter((item) => item.chosen)
      .forEach((item) => {
        treasuryRoleConfig.push(
          new TreasuryRoleDto(
            item.name,
            new BN(item.votingPower),
            item.actions,
            item.default
          )
        );
      });

    //Setup treasury
    const treasuryIndexBuffer = Buffer.alloc(4);
    treasuryIndexBuffer.writeInt32LE(treasuryIndex + 1, 0);

    const [treasuryAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        clubDataAddress.toBuffer(),
        treasurySeed,
        treasuryIndexBuffer,
      ],
      program.programId
    );

    const [treasuryDataAddress] = PublicKey.findProgramAddressSync(
      [unqClubSeed, treasuryAddress.toBuffer(), treasuryDataSeed],
      program.programId
    );

    const [vaultAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        clubDataAddress.toBuffer(),
        treasuryAddress.toBuffer(),
        vaultSeed,
        Buffer.from(chainId),
      ],
      program.programId
    );

    const [vaultDataAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        clubDataAddress.toBuffer(),
        treasuryAddress.toBuffer(),
        vaultDataSeed,
        Buffer.from(chainId),
      ],
      program.programId
    );

    const [profitAddress] = PublicKey.findProgramAddressSync(
      [unqClubSeed, treasuryAddress.toBuffer(), profitSeed],
      program.programId
    );

    const [financialRecord] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        treasuryDataAddress.toBuffer(),
        financialRecordSeed,
        creatorWallet.publicKey.toBuffer(),
      ],
      program.programId
    );

    if (denominatedCurrency && denominatedCurrency !== NATIVE_MINT.toString()) {
      const [denominatedToken] = PublicKey.findProgramAddressSync(
        [unqClubSeed, treasuryAddress.toBuffer(), denominatedTokenSeed],
        program.programId
      );
      createTreasuryRemainingAccounts.push(
        ...[
          {
            pubkey: new PublicKey(denominatedCurrency),
            isWritable: false,
            isSigner: false,
          },
          {
            pubkey: denominatedToken,
            isSigner: false,
            isWritable: true,
          },
        ]
      );
    }

    let treasuryInstructions: TransactionInstruction[] = [];

    const treasuryRealmAddress = await SplGovernance.withCreateRealm(
      treasuryInstructions,
      UNQ_SPL_GOVERNANCE_PROGRAM_ID,
      SplGovernance.PROGRAM_VERSION_V2,
      treasuryRealmName,
      treasuryAddress,
      mint,
      creatorWallet.publicKey,
      undefined,
      SplGovernance.MintMaxVoteWeightSource.FULL_SUPPLY_FRACTION,
      new BN(1),
      program.programId,
      program.programId
    );

    const treasuryTokenOwnerRecordAddress =
      await SplGovernance.withCreateTokenOwnerRecord(
        treasuryInstructions,
        UNQ_SPL_GOVERNANCE_PROGRAM_ID,
        treasuryRealmAddress,
        creatorWallet.publicKey,
        mint,
        creatorWallet.publicKey
      );
    const createTreasuryIx = await program.methods
      .createTreasury(
        treasuryName,
        chainId,
        CLIENT_CONFIGURATION.nftMaxVoterWeight,
        treasuryRoleConfig
      )
      .accounts({
        clubData: clubDataAddress,
        financialRecord,
        memberData: memberDataAddress,
        payer: creatorWallet.publicKey,
        profit: profitAddress,
        realm: treasuryRealmAddress,
        tokenOwnerRecord: treasuryTokenOwnerRecordAddress,
        splGovernance: UNQ_SPL_GOVERNANCE_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
        treasury: treasuryAddress,
        treasuryData: treasuryDataAddress,
        vault: vaultAddress,
        vaultData: vaultDataAddress,
      })
      .remainingAccounts(createTreasuryRemainingAccounts)
      .instruction();
    treasuryInstructions.push(createTreasuryIx);

    const { governancesInstructions } = await createGovernanceIx(
      memberDataAddress,
      creatorWallet,
      treasuryDataAddress,
      clubDataAddress,
      treasuryRealmAddress,
      maxVotingTime,
      approvalQuorum,
      treasuryAddress,
      chainId,
      treasuryTokenOwnerRecordAddress
    );

    instructionSet.push({
      description: "Create treasury",
      instructions: treasuryInstructions,
    });

    instructionSet.push({
      description: "Create governances for treasury",
      instructions: governancesInstructions,
    });
    if (membersWithPercentages && membersWithPercentages.length > 0) {
      const reserveRightIx = await getReserveRightsIx(
        membersWithPercentages,
        clubDataAddress,
        memberDataAddress,
        creatorWallet,
        treasuryDataAddress
      );
      instructionSet.push({
        description: "Reserve rights for treasury",
        instructions: [reserveRightIx],
      });
    }
    await sendTransactions(
      RPC_CONNECTION,
      creatorWallet,
      instructionSet,
      SequenceType.Sequential,
      true
    );
    return treasuryAddress;
  } catch (error) {
    throw error;
  }
};

export const updateVoterWeight = async (
  memberData: PublicKey,
  payer: PublicKey,
  action: ClubAction | TreasuryAction,
  treasuryData: PublicKey,
  clubData: PublicKey,
  treasuryRealm: PublicKey,
  actionType: UpdateVWActionType,
  remainingAccounts?: AccountMeta[]
): Promise<{
  voterWeightIx: TransactionInstruction;
  voterWeightAddress: PublicKey;
}> => {
  try {
    const program = programFactory();
    let vwRemainingAccounts: AccountMeta[] = [];
    if (actionType === UpdateVWActionType.TreasuryAction) {
      const [financialRecord] = PublicKey.findProgramAddressSync(
        [
          unqClubSeed,
          treasuryData.toBuffer(),
          financialRecordSeed,
          payer.toBuffer(),
        ],
        program.programId
      );
      vwRemainingAccounts.push({
        pubkey: financialRecord,
        isSigner: false,
        isWritable: true,
      });
    }
    remainingAccounts?.forEach((item) => vwRemainingAccounts.push(item));
    const [voterWeightAddress] = PublicKey.findProgramAddressSync(
      [unqClubSeed, clubData.toBuffer(), voterWeightSeed, payer.toBuffer()],
      program.programId
    );

    const voterWeightIx = await program.methods
      .updateVoterWeight(
        getUpdateVoterWeightInput(
          actionType,
          actionType === UpdateVWActionType.ClubAction
            ? clubActionToCamelCase[action as ClubAction]
            : treasuryActionToCamelCase[action as TreasuryAction]
        )
      )
      .accounts({
        clubData,
        memberData,
        payer,
        realm: treasuryRealm,
        treasuryData,
        voterWeightRecord: voterWeightAddress,
        systemProgram: SystemProgram.programId,
      })
      .remainingAccounts(vwRemainingAccounts)
      .instruction();
    return { voterWeightIx, voterWeightAddress };
  } catch (error) {
    throw error;
  }
};

export const getClubTreasuryByIndex = async (
  clubAddress: string,
  treasruyIndex: number
) => {
  try {
    const program = programFactory();
    const treasruyIndexBuffer = Buffer.alloc(4);
    treasruyIndexBuffer.writeInt32LE(treasruyIndex, 0);

    const [treasuryAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        new PublicKey(clubAddress).toBuffer(),
        treasurySeed,
        treasruyIndexBuffer,
      ],
      program.programId
    );
    const [treasuryDataAddress] = PublicKey.findProgramAddressSync(
      [unqClubSeed, treasuryAddress.toBuffer(), treasuryDataSeed],
      program.programId
    );

    const treasuryDataInfo = await program.account.treasuryData.fetch(
      treasuryDataAddress
    );
    return {
      treasuryDataAddress: treasuryDataAddress,
      treasuryDataInfo: treasuryDataInfo,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const inviteMember = async (
  members: IAddMember[],
  clubName: string,
  wallet: AnchorWallet,
  allowType: AllowType,
  treasuryDataAddress?: PublicKey,
  treasuryRealmAddress?: PublicKey
) => {
  try {
    const program = programFactory();
    const roles = members.map((item) => item.role);
    const instructionSet: IInstructionSet[] = [];

    let counter = 1;

    const [realmAddress] = await PublicKey.findProgramAddressSync(
      [governanceSeed, Buffer.from(clubName)],
      UNQ_SPL_GOVERNANCE_PROGRAM_ID
    );

    const [clubDataAddress] = PublicKey.findProgramAddressSync(
      [unqClubSeed, realmAddress.toBuffer()],
      program.programId
    );

    const [memberAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        clubDataAddress.toBuffer(),
        unqClubMemberSeed,
        wallet.publicKey.toBuffer(),
      ],
      program.programId
    );

    // TODO: Check optimal number, 5 is the best I could make work
    const chunkedMembers = chunk(members, 5);

    for (const chunkOfMembers of chunkedMembers) {
      const remainingAccounts: AccountMeta[] = [];
      const tokenOwnerRecordIxs: TransactionInstruction[] = [];

      if (allowType === AllowType.Treasury && treasuryDataAddress) {
        remainingAccounts.push({
          pubkey: treasuryDataAddress,
          isSigner: false,
          isWritable: true,
        });
      }

      for (const member of chunkOfMembers) {
        remainingAccounts.push({
          pubkey: new PublicKey(member.wallet),
          isSigner: false,
          isWritable: false,
        });

        if (allowType === AllowType.Club) {
          const [memberDataAddress] = PublicKey.findProgramAddressSync(
            [
              unqClubSeed,
              clubDataAddress.toBuffer(),
              unqClubMemberSeed,
              new PublicKey(member.wallet).toBuffer(),
            ],
            program.programId
          );

          remainingAccounts.push({
            pubkey: memberDataAddress,
            isSigner: false,
            isWritable: true,
          });
        } else {
          if (treasuryDataAddress && treasuryRealmAddress) {
            const [financialRecord] = PublicKey.findProgramAddressSync(
              [
                unqClubSeed,
                treasuryDataAddress.toBuffer(),
                financialRecordSeed,
                new PublicKey(member.wallet).toBuffer(),
              ],
              program.programId
            );

            remainingAccounts.push({
              pubkey: financialRecord,
              isSigner: false,
              isWritable: true,
            });

            await SplGovernance.withCreateTokenOwnerRecord(
              tokenOwnerRecordIxs,
              UNQ_SPL_GOVERNANCE_PROGRAM_ID,
              treasuryRealmAddress,
              new PublicKey(member.wallet),
              mint,
              wallet.publicKey
            );
          }
        }
      }
      const inviteMemberIx = await program.methods
        .allowMember(roles, parseEnum(AllowType, allowType))
        .accounts({
          clubData: clubDataAddress,
          memberData: memberAddress,
          payer: wallet.publicKey,
          realm:
            allowType === AllowType.Club ? realmAddress : treasuryRealmAddress,
          systemProgram: SystemProgram.programId,
        })
        .remainingAccounts(remainingAccounts)
        .instruction();

      instructionSet.push({
        description: `Invite members instruction ${counter}`,
        instructions: [...tokenOwnerRecordIxs, inviteMemberIx],
      });

      counter++;
    }

    await sendTransactions(
      RPC_CONNECTION,
      wallet,
      instructionSet,
      SequenceType.Sequential,
      true
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createOrEndFundraise = async (
  treasuryAddress: string,
  treasuryDataAddress: string,
  clubDataAddress: string,
  wallet: AnchorWallet,
  fundraiseCount: number,
  clubType: DealType,
  fundraiseAction: FundraiseAction,
  equal?: number,
  customAllocation?: ICustomAllocationForProgram[],
  fundraiseAmount?: number,
  treasuryFinancialAccount?: string,
  treasuryDenominatedCurrency?: string
) => {
  try {
    const program = programFactory();
    const instructionSet: IInstructionSet[] = [];
    const remainingAccounts: AccountMeta[] = [];
    const createOrEndInstructions: TransactionInstruction[] = [];

    const conditionalFundraiseCount =
      fundraiseAction === FundraiseAction.Create
        ? fundraiseCount + 1
        : fundraiseCount;

    const fundraiseIndex = Buffer.alloc(4);
    fundraiseIndex.writeInt32LE(conditionalFundraiseCount, 0);

    const [fundraiseConfigAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        new PublicKey(treasuryDataAddress).toBuffer(),
        fundraiseCfgSeed,
        fundraiseIndex,
      ],
      program.programId
    );

    const [memberDataAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        new PublicKey(clubDataAddress).toBuffer(),
        unqClubMemberSeed,
        wallet.publicKey.toBuffer(),
      ],
      program.programId
    );

    if (
      clubType === DealType.Syndicate &&
      fundraiseAction === FundraiseAction.Finish
    ) {
      const { instructions, syndicateRemainingAccounts } =
        await getRemainingAccountsForFinishSyndicateFundraise(
          wallet,
          treasuryFinancialAccount
            ? new PublicKey(treasuryFinancialAccount)
            : undefined,
          treasuryDenominatedCurrency
            ? new PublicKey(treasuryDenominatedCurrency)
            : undefined
        );
      instructions.forEach((item) => createOrEndInstructions.push(item));
      syndicateRemainingAccounts.forEach((item) =>
        remainingAccounts.push(item)
      );
    }

    const [financialRecord] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        new PublicKey(treasuryDataAddress).toBuffer(),
        financialRecordSeed,
        wallet.publicKey.toBuffer(),
      ],
      program.programId
    );

    const createOrEndFundraiseIx = await program.methods
      .fundraise(
        fundraiseAction === FundraiseAction.Create && fundraiseAmount
          ? {
              create: {
                "0": new BN(fundraiseAmount),
              },
            }
          : {
              finish: {},
            }
      )
      .accounts({
        clubData: new PublicKey(clubDataAddress),
        fundraiseConfig: fundraiseConfigAddress,
        memberData: memberDataAddress,
        payer: wallet.publicKey,
        systemProgram: SystemProgram.programId,
        treasury: new PublicKey(treasuryAddress),
        treasuryData: new PublicKey(treasuryDataAddress),
        financialRecord,
      })
      .remainingAccounts(remainingAccounts)
      .instruction();
    createOrEndInstructions.push(createOrEndFundraiseIx);
    instructionSet.push({
      description:
        fundraiseAction === FundraiseAction.Create
          ? "Create fundraise"
          : "End fundraise",
      instructions: createOrEndInstructions,
    });

    if (equal || customAllocation) {
      const updateAllocationIx = await program.methods
        .updateAllocation(
          equal ? new BN(equal) : null,
          customAllocation ? customAllocation : null,
          null,
          null
        )
        .accounts({
          clubData: new PublicKey(clubDataAddress),
          treasuryData: new PublicKey(treasuryDataAddress),
          memberData: memberDataAddress,
          payer: wallet.publicKey,
          fundraiseConfig: fundraiseConfigAddress,
          systemProgram: SystemProgram.programId,
          financialRecord,
        })
        .instruction();

      instructionSet.push({
        description: "Custom allocation",
        instructions: [updateAllocationIx],
      });
    }

    await sendTransactions(
      RPC_CONNECTION,
      wallet,
      instructionSet,
      SequenceType.Sequential,
      true
    );
    return fundraiseConfigAddress;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const rejectInvitation = async (
  wallet: AnchorWallet,
  clubDataAdress: string,
  memberAddress: string,
  authorityAddress: string
) => {
  try {
    const program = programFactory();
    const instructionsSet: IInstructionSet[] = [];

    const [memberDataAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        new PublicKey(clubDataAdress).toBuffer(),
        unqClubMemberSeed,
        new PublicKey(memberAddress).toBuffer(),
      ],
      program.programId
    );

    const rejectInvitationIx = await program.methods
      .cancelInvitation()
      .accounts({
        clubData: new PublicKey(clubDataAdress),
        memberData: memberDataAddress,
        memberAddress: new PublicKey(memberAddress),
        payer: wallet.publicKey,
        recipient: new PublicKey(authorityAddress),
        systemProgram: SystemProgram.programId,
      })
      .instruction();

    instructionsSet.push({
      description: "Cancel invitation",
      instructions: [rejectInvitationIx],
    });

    await sendTransactions(
      RPC_CONNECTION,
      wallet,
      instructionsSet,
      SequenceType.Sequential,
      true
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const acceptInvitation = async (
  clubAddress: string,
  memberDataAddress: string,
  wallet: AnchorWallet,
  clubName: string,
  clubType: DealType,
  //Note: uncomment after CIVIC implementation
  // gatewayToken?: SolanaGatewayToken | undefined,
  usersNft?: IBasicNftInfo
) => {
  try {
    const program = programFactory();
    const instructions: IInstructionSet[] = [];
    const transactionIx: TransactionInstruction[] = [];
    const remainingAccounts: AccountMeta[] = [];

    const [reamAddress] = await PublicKey.findProgramAddressSync(
      [governanceSeed, Buffer.from(clubName)],
      UNQ_SPL_GOVERNANCE_PROGRAM_ID
    );

    const tokenOwnerRecord = await SplGovernance.withCreateTokenOwnerRecord(
      transactionIx,
      UNQ_SPL_GOVERNANCE_PROGRAM_ID,
      reamAddress,
      wallet.publicKey,
      mint,
      wallet.publicKey
    );

    //TODO@milica
    if (clubType === DealType.NftBased && usersNft) {
      //tokenAccunt and metadata
      remainingAccounts.push({
        isSigner: false,
        isWritable: false,
        pubkey: usersNft.ownertokenAddress,
      });
      remainingAccounts.push({
        isSigner: false,
        isWritable: false,
        pubkey: usersNft.metadataAddress,
      });
    }

    if (clubType === DealType.Syndicate) {
      //whitelisting data address
      const [whitelistingDataAddress] = await PublicKey.findProgramAddressSync(
        [unqClubSeed, syndicateAuthoritySeed],
        program.programId
      );
      remainingAccounts.push({
        isSigner: false,
        isWritable: false,
        pubkey: whitelistingDataAddress,
      });
    }
    //Note: uncomment after CIVIC implementation
    // if (gatewayToken) {
    //   //if club has kyc add gateway token
    //   remainingAccounts.push({
    //     isSigner: false,
    //     isWritable: false,
    //     pubkey: gatewayToken.publicKey,
    //   });
    // }

    const acceptInvitationIx = await program.methods
      .acceptMembership()
      .accounts({
        clubData: new PublicKey(clubAddress),
        memberData: new PublicKey(memberDataAddress),
        payer: wallet.publicKey,
        realm: reamAddress,
        systemProgram: SystemProgram.programId,
        tokenOwnerRecord, //token owner record is created for every member, so we need to create it first with spl governance
      })
      .remainingAccounts(remainingAccounts)
      .instruction();

    transactionIx.push(acceptInvitationIx);

    instructions.push({
      description: "Accept invitation",
      instructions: transactionIx,
    });

    await sendTransactions(
      RPC_CONNECTION,
      wallet,
      instructions,
      SequenceType.Sequential,
      true
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const supprotClub = async (
  supportAction: SupportAction,
  supportAmount: number,
  clubDataAddress: string,
  treasuryDataAddress: string,
  treasuryAddress: string,
  fundraiseCount: number,
  memberData: string,
  clubName: string,
  wallet: AnchorWallet,
  clubType: DealType,
  treasuryIndex: number,
  communityMint: string,
  treasuryDenominatedCurrency?: string,
  treasuryFinancialAccount?: string,
  memberTokenAccountAddress?: PublicKey,
  isEndFundraise?: boolean
) => {
  try {
    const program = programFactory();
    const remainingAccounts: AccountMeta[] = [];
    const instructionSet: IInstructionSet[] = [];
    const depositInstructions: TransactionInstruction[] = [];

    const [financialRecord] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        new PublicKey(treasuryDataAddress).toBuffer(),
        financialRecordSeed,
        wallet.publicKey.toBuffer(),
      ],
      program.programId
    );

    const [realmAddress] = PublicKey.findProgramAddressSync(
      [governanceSeed, Buffer.from(clubName.concat(treasuryIndex.toString()))],
      UNQ_SPL_GOVERNANCE_PROGRAM_ID
    );

    const [tokenOwnerRecord] = PublicKey.findProgramAddressSync(
      [
        governanceSeed,
        realmAddress.toBuffer(),
        new PublicKey(communityMint).toBuffer(),
        wallet.publicKey.toBuffer(),
      ],
      UNQ_SPL_GOVERNANCE_PROGRAM_ID
    );
    //Check if tokenOwnerRecord for treasury realm is created
    try {
      await SplGovernance.getTokenOwnerRecord(RPC_CONNECTION, tokenOwnerRecord);
    } catch (error) {
      console.log(error);
      await SplGovernance.withCreateTokenOwnerRecord(
        depositInstructions,
        UNQ_SPL_GOVERNANCE_PROGRAM_ID,
        realmAddress,
        wallet.publicKey,
        new PublicKey(communityMint),
        wallet.publicKey
      );
    }

    const fundraiseIndex = Buffer.alloc(4);
    fundraiseIndex.writeInt32LE(fundraiseCount, 0);

    const [fundraiseConfigAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        new PublicKey(treasuryDataAddress).toBuffer(),
        fundraiseCfgSeed,
        fundraiseIndex,
      ],
      program.programId
    );

    if (
      clubType === DealType.Syndicate &&
      supportAction === SupportAction.Deposit
    ) {
      const [adminsAddress] = PublicKey.findProgramAddressSync(
        [unqClubSeed, adminSeed],
        program.programId
      );
      const adminAccount = await program.account.admins.fetch(adminsAddress);
      remainingAccounts.push({
        isSigner: false,
        isWritable: false,
        pubkey: adminsAddress,
      });
      let feeAddress = adminAccount.feeWallet;
      if (treasuryDenominatedCurrency) {
        feeAddress = (
          await RPC_CONNECTION.getParsedTokenAccountsByOwner(
            adminAccount.feeWallet,
            { mint: new PublicKey(treasuryDenominatedCurrency) }
          )
        ).value[0].pubkey;
      }
      remainingAccounts.push({
        isSigner: false,
        isWritable: true,
        pubkey: feeAddress,
      });
    }

    if (treasuryFinancialAccount && memberTokenAccountAddress) {
      remainingAccounts.push({
        isSigner: false,
        isWritable: true,
        pubkey: new PublicKey(treasuryFinancialAccount),
      });
      remainingAccounts.push({
        isSigner: false,
        isWritable: true,
        pubkey: new PublicKey(memberTokenAccountAddress),
      });
    }

    if (isEndFundraise) {
      const { instructions, syndicateRemainingAccounts } =
        await getRemainingAccountsForFinishSyndicateFundraise(
          wallet,
          treasuryFinancialAccount
            ? new PublicKey(treasuryFinancialAccount)
            : undefined,
          treasuryDenominatedCurrency
            ? new PublicKey(treasuryDenominatedCurrency)
            : undefined
        );
      instructions.forEach((item) => depositInstructions.push(item));
      syndicateRemainingAccounts.forEach((item) =>
        remainingAccounts.push(item)
      );
    }
    const depositIx = await program.methods
      .supportClub(
        supportAction === SupportAction.Deposit
          ? {
              deposit: { "0": new BN(supportAmount) },
            }
          : {
              withdrawal: { "0": new BN(supportAmount) },
            }
      )
      .accounts({
        clubData: new PublicKey(clubDataAddress),
        financialRecord,
        fundraiseConfig: new PublicKey(fundraiseConfigAddress),
        memberData: new PublicKey(memberData),
        payer: wallet.publicKey,
        systemProgram: SystemProgram.programId,
        tokenOwnerRecord,
        tokenProgram: TOKEN_PROGRAM_ID,
        treasury: new PublicKey(treasuryAddress),
        treasuryData: new PublicKey(treasuryDataAddress),
      })
      .remainingAccounts(remainingAccounts)
      .instruction();

    depositInstructions.push(depositIx);

    instructionSet.push({
      description:
        supportAction === SupportAction.Withdraw
          ? "Withdraw funds"
          : "Deposit funds",
      instructions: depositInstructions,
    });

    await sendTransactions(
      RPC_CONNECTION,
      wallet,
      instructionSet,
      undefined,
      true
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getClubDataPDA = (clubName: string) => {
  const program = programFactory();
  const [realmAddress] = PublicKey.findProgramAddressSync(
    [governanceSeed, Buffer.from(clubName)],
    UNQ_SPL_GOVERNANCE_PROGRAM_ID
  );
  const [clubDataAddress] = PublicKey.findProgramAddressSync(
    [unqClubSeed, realmAddress.toBuffer()],
    program.programId
  );
  return clubDataAddress;
};

const getReserveRightsIx = async (
  membersWithPercentages: IMemberWallets[],
  clubDataAddress: PublicKey,
  memberDataAddress: PublicKey,
  payer: AnchorWallet,
  treasuryDataAddress: PublicKey
): Promise<TransactionInstruction> => {
  try {
    const program = programFactory();
    const mappedReservedRights: IMembersAndPercentages[] = [];
    membersWithPercentages.forEach((item) => {
      item.wallet.forEach((wallet) => {
        mappedReservedRights.push({
          member: new PublicKey(wallet),
          percentage: Number(item.membersAmount) * 100,
        });
      });
    });
    const reserveRightsArguments = new ReservedRightsDto(mappedReservedRights);

    const [financialRecord] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        treasuryDataAddress.toBuffer(),
        financialRecordSeed,
        payer.publicKey.toBuffer(),
      ],
      program.programId
    );

    const reserveRightIx = await program.methods
      .reserveRights(reserveRightsArguments)
      .accounts({
        clubData: clubDataAddress,
        memberData: memberDataAddress,
        payer: payer.publicKey,
        systemProgram: SystemProgram.programId,
        treasuryData: treasuryDataAddress,
        financialRecord,
      })
      .instruction();

    return reserveRightIx;
  } catch (error) {
    throw error;
  }
};

export const reserveRights = async (
  membersWithPercentages: IMemberWallets[],
  clubDataAddress: PublicKey,
  memberDataAddress: PublicKey,
  payer: AnchorWallet,
  treasuryDataAddress: PublicKey
) => {
  try {
    const reserveRightIx = await getReserveRightsIx(
      membersWithPercentages,
      clubDataAddress,
      memberDataAddress,
      payer,
      treasuryDataAddress
    );

    const instructionSet: IInstructionSet[] = [
      {
        description: "Reserve rights",
        instructions: [reserveRightIx],
      },
    ];

    await sendTransactions(
      RPC_CONNECTION,
      payer,
      instructionSet,
      SequenceType.Sequential,
      true
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const acceptFinancialOffer = async (
  memberData: PublicKey,
  payer: AnchorWallet,
  treasuryAddress: PublicKey,
  treasuryDataAddress: PublicKey,
  treasuryRealmAddress: PublicKey,
  seller: PublicKey,
  financialOffer: PublicKey,
  financialRecord: PublicKey,
  clubData: IClubData,
  amountOfRights: number,
  wantedTokenMint?: PublicKey
) => {
  try {
    const program = programFactory();
    const remainingAccounts: AccountMeta[] = [];
    const instructionSet: IInstructionSet[] = [];
    const acceptOfferIxs: TransactionInstruction[] = [];

    if (wantedTokenMint) {
      const buyerTA = await getOrCreateATAOwnedBySpecifiedAccount(
        payer.publicKey,
        payer.publicKey,
        new PublicKey(wantedTokenMint)
      );
      const sellerTA = await getOrCreateATAOwnedBySpecifiedAccount(
        seller,
        payer.publicKey,
        new PublicKey(wantedTokenMint)
      );
      if (buyerTA.instruction && sellerTA.instruction) {
        instructionSet.push({
          description: "Create associated token accounts",
          instructions: [buyerTA.instruction, sellerTA.instruction],
        });
      }
      remainingAccounts.push(
        {
          isSigner: false,
          isWritable: true,
          pubkey: buyerTA.address,
        },
        {
          isSigner: false,
          isWritable: true,
          pubkey: sellerTA.address,
        },
        {
          isSigner: false,
          isWritable: false,
          pubkey: TOKEN_PROGRAM_ID,
        }
      );
    }

    if (clubData.clubType === DealType.Syndicate) {
      const [adminsAddress] = PublicKey.findProgramAddressSync(
        [unqClubSeed, adminSeed],
        program.programId
      );
      const adminAccounts = await program.account.admins.fetch(adminsAddress);
      remainingAccounts.push({
        isSigner: false,
        isWritable: false,
        pubkey: adminsAddress,
      });
      for (const config of adminAccounts.otcFeeConfigs) {
        if (wantedTokenMint) {
          const otcFeeConfigTA = await getOrCreateATAOwnedBySpecifiedAccount(
            config.authority,
            payer.publicKey,
            new PublicKey(wantedTokenMint)
          );
          if (otcFeeConfigTA.instruction) {
            instructionSet.push({
              description: "Create admin token accounts",
              instructions: [otcFeeConfigTA.instruction],
            });
          }
          remainingAccounts.push({
            isSigner: false,
            isWritable: true,
            pubkey: otcFeeConfigTA.address,
          });
        } else {
          remainingAccounts.push({
            isSigner: false,
            isWritable: true,
            pubkey: config.authority,
          });
        }
      }
    }

    const [buyerTokenOwnerRecord] = PublicKey.findProgramAddressSync(
      [
        governanceSeed,
        treasuryRealmAddress.toBuffer(),
        mint.toBuffer(),
        payer.publicKey.toBuffer(),
      ],
      UNQ_SPL_GOVERNANCE_PROGRAM_ID
    );

    try {
      await SplGovernance.getTokenOwnerRecord(
        RPC_CONNECTION,
        buyerTokenOwnerRecord
      );
    } catch (error) {
      await SplGovernance.withCreateTokenOwnerRecord(
        acceptOfferIxs,
        UNQ_SPL_GOVERNANCE_PROGRAM_ID,
        treasuryRealmAddress,
        payer.publicKey,
        mint,
        payer.publicKey
      );
    }

    const [buyerFinancialRecord] = findProgramAddressSync(
      [
        unqClubSeed,
        treasuryDataAddress.toBuffer(),
        financialRecordSeed,
        payer.publicKey.toBuffer(),
      ],
      program.programId
    );

    const acceptFinancialOfferIx = await program.methods
      .acceptFinancialOffer(new BN(amountOfRights))
      .accounts({
        clubData: new PublicKey(clubData.address),
        memberData,
        payer: payer.publicKey,
        treasury: new PublicKey(treasuryAddress),
        treasuryData: new PublicKey(treasuryDataAddress),
        financialOffer,
        buyerFinancialRecord,
        buyerTokenOwnerRecord,
        seller: seller,
        sellerFinancialRecord: financialRecord,
        systemProgram: SystemProgram.programId,
      })
      .remainingAccounts(remainingAccounts)
      .instruction();

    acceptOfferIxs.push(acceptFinancialOfferIx);
    instructionSet.push({
      description: "Accept financial rights offer",
      instructions: acceptOfferIxs,
    });

    await sendTransactions(
      RPC_CONNECTION,
      payer,
      instructionSet,
      SequenceType.Sequential,
      true
    );
  } catch (error) {
    throw error;
  }
};

export const cancelFinancialOffer = async (
  clubDataAddress: PublicKey,
  payer: AnchorWallet,
  financialOffer: PublicKey,
  treasuryAddress: PublicKey,
  treasuryDataAddress: PublicKey
) => {
  try {
    const program = programFactory();

    const [financialRecordAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        treasuryDataAddress.toBuffer(),
        financialRecordSeed,
        payer.publicKey.toBuffer(),
      ],
      program.programId
    );

    const cancelFinOfferIx = await program.methods
      .cancelFinancialOffer()
      .accounts({
        clubData: clubDataAddress,
        payer: payer.publicKey,
        treasuryData: treasuryDataAddress,
        financialOffer: financialOffer,
        financialRecord: financialRecordAddress,
        treasury: treasuryAddress,
        systemProgram: SystemProgram.programId,
      })
      .instruction();

    const instructionSet: IInstructionSet[] = [
      {
        description: "Cancel financial rights offer",
        instructions: [cancelFinOfferIx],
      },
    ];

    await sendTransactions(
      RPC_CONNECTION,
      payer,
      instructionSet,
      SequenceType.Sequential,
      true
    );
  } catch (error) {
    throw error;
  }
};

export const createFinancialOffer = async (
  amountOfRights: number,
  price: number,
  clubData: PublicKey,
  payer: AnchorWallet,
  treasuryAddress: PublicKey,
  treasuryDataAddress: PublicKey,
  memberData: PublicKey,
  financialOfferIndex: number,
  denominatedCurrency?: PublicKey,
  buyer?: PublicKey
) => {
  try {
    const program = programFactory();
    const instructionSet: IInstructionSet[] = [];
    const remainingAccounts: AccountMeta[] = [];

    const [financialRecord] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        treasuryDataAddress.toBuffer(),
        financialRecordSeed,
        payer.publicKey.toBuffer(),
      ],
      program.programId
    );

    const indexBuffer = Buffer.alloc(4);
    indexBuffer.writeUInt32LE(financialOfferIndex + 1);
    const [financialOffer] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        treasuryDataAddress.toBuffer(),
        financialOfferSeed,
        payer.publicKey.toBuffer(),
        indexBuffer,
      ],
      program.programId
    );

    if (denominatedCurrency) {
      remainingAccounts.push({
        isSigner: false,
        isWritable: false,
        pubkey: denominatedCurrency,
      });
    }

    const createFinOfferIx = await program.methods
      .createFinancialOffer(
        new BN(amountOfRights),
        new BN(price),
        buyer ? buyer : null
      )
      .accounts({
        clubData,
        payer: payer.publicKey,
        treasury: treasuryAddress,
        treasuryData: treasuryDataAddress,
        memberData,
        financialRecord,
        financialOffer,
        systemProgram: SystemProgram.programId,
      })
      .remainingAccounts(remainingAccounts)
      .instruction();

    instructionSet.push({
      description: "Create financial offer",
      instructions: [createFinOfferIx],
    });

    await sendTransactions(
      RPC_CONNECTION,
      payer,
      instructionSet,
      SequenceType.Sequential,
      true
    );
  } catch (error) {
    throw error;
  }
};

export const updateAllocation = async (
  treasuryDataAddress: string,
  clubDataAddress: string,
  memberData: string,
  wallet: AnchorWallet,
  fundraiseInfo: string,
  equal?: number | null,
  customAllocation?: ICustomAllocationForProgram[],
  removeAllocation?: ICustomAllocationForProgram[],
  new_cap?: number
) => {
  try {
    const program = programFactory();
    const instructionSet: IInstructionSet[] = [];

    const [financialRecord] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        new PublicKey(treasuryDataAddress).toBuffer(),
        financialRecordSeed,
        wallet.publicKey.toBuffer(),
      ],
      program.programId
    );

    if (
      (customAllocation && customAllocation.length > 0) ||
      equal !== undefined
    ) {
      const updateAllocationIxUpdate = await program.methods
        .updateAllocation(
          equal ? new BN(equal) : null,
          customAllocation ?? null,
          null,
          new_cap ? new BN(new_cap) : null
        )
        .accounts({
          clubData: new PublicKey(clubDataAddress),
          treasuryData: new PublicKey(treasuryDataAddress),
          memberData: new PublicKey(memberData),
          payer: wallet.publicKey,
          fundraiseConfig: new PublicKey(fundraiseInfo),
          systemProgram: SystemProgram.programId,
          financialRecord: financialRecord,
        })
        .instruction();

      instructionSet.push({
        description: "Update allocation for members",
        instructions: [updateAllocationIxUpdate],
      });
    }

    if (removeAllocation && removeAllocation.length > 0) {
      const updateAllocationIxRemove = await program.methods
        .updateAllocation(
          equal ? new BN(equal) : null,
          null,
          removeAllocation ?? null,
          new_cap ? new BN(new_cap) : null
        )
        .accounts({
          clubData: new PublicKey(clubDataAddress),
          treasuryData: new PublicKey(treasuryDataAddress),
          memberData: new PublicKey(memberData),
          payer: wallet.publicKey,
          fundraiseConfig: new PublicKey(fundraiseInfo),
          systemProgram: SystemProgram.programId,
        })
        .instruction();
      instructionSet.push({
        description: "Update allocation for members",
        instructions: [updateAllocationIxRemove],
      });
    }

    await sendTransactions(RPC_CONNECTION, wallet, instructionSet);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const migrateFinancials = async (
  creator: AnchorWallet,
  invitedMembers: IExistingInvestment[],
  memberData: IMemberData,
  clubBasicInfo: IClubData,
  treasury: ITreasuryData
) => {
  try {
    const program = programFactory();
    const instructionSet: IInstructionSet[] = [];
    let counter = 0;

    const fundraiseCountBuff = Buffer.alloc(4);
    fundraiseCountBuff.writeInt32LE(treasury.fundraiseCount + 1, 0);

    const [realmAddress] = PublicKey.findProgramAddressSync(
      [governanceSeed, Buffer.from(clubBasicInfo.name)],
      UNQ_SPL_GOVERNANCE_PROGRAM_ID
    );

    const [fundraiseConfigAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        new PublicKey(treasury.treasuryDataAddress).toBuffer(),
        fundraiseCfgSeed,
        fundraiseCountBuff,
      ],
      program.programId
    );

    const chunkedInvitedMembers = chunk(invitedMembers, 3);

    for (const invitedMembers of chunkedInvitedMembers) {
      const chunkedInstructions: TransactionInstruction[] = [];

      for (const invitedMember of invitedMembers) {
        const [importedMemberDataAddress] = PublicKey.findProgramAddressSync(
          [
            unqClubSeed,
            new PublicKey(clubBasicInfo.address).toBuffer(),
            unqClubMemberSeed,
            new PublicKey(invitedMember.wallet).toBuffer(),
          ],
          CLUB_PROGRAM_ID
        );

        const [financialRecord] = PublicKey.findProgramAddressSync(
          [
            unqClubSeed,
            new PublicKey(treasury.treasuryDataAddress).toBuffer(),
            financialRecordSeed,
            new PublicKey(invitedMember.wallet).toBuffer(),
          ],
          CLUB_PROGRAM_ID
        );

        const [clubTokenOwnerRecord] = PublicKey.findProgramAddressSync(
          [
            governanceSeed,
            realmAddress.toBuffer(),
            new PublicKey(clubBasicInfo.communityMint).toBuffer(),
            new PublicKey(invitedMember.wallet).toBuffer(),
          ],
          UNQ_SPL_GOVERNANCE_PROGRAM_ID
        );

        const [treasuryTokenOwnerRecord] = PublicKey.findProgramAddressSync(
          [
            governanceSeed,
            new PublicKey(treasury.realmAddress).toBuffer(),
            new PublicKey(clubBasicInfo.communityMint).toBuffer(),
            new PublicKey(invitedMember.wallet).toBuffer(),
          ],
          UNQ_SPL_GOVERNANCE_PROGRAM_ID
        );

        const memberInstruction = await program.methods
          .migrateFinancials(
            new BN(
              Number(extractNumbersFromString(invitedMember.amount)) *
                Number(pow(10, treasury.currencyDecimals))
            )
          )
          .accounts({
            clubData: clubBasicInfo.address,
            clubRealm: realmAddress,
            clubTokenOwnerRecord: clubTokenOwnerRecord,
            payer: creator.publicKey,
            payerMemberData: new PublicKey(memberData.address),
            member: new PublicKey(invitedMember.wallet),
            memberData: importedMemberDataAddress,
            treasuryData: new PublicKey(treasury.treasuryDataAddress),
            treasuryRealm: new PublicKey(treasury.realmAddress),
            financialRecord: financialRecord,
            fundraiseConfig: fundraiseConfigAddress,
            governingTokenMint: new PublicKey(clubBasicInfo.communityMint),
            treasuryTokenOwnerRecord: treasuryTokenOwnerRecord,
            systemProgram: SystemProgram.programId,
            splGovernance: UNQ_SPL_GOVERNANCE_PROGRAM_ID,
          })
          .instruction();

        chunkedInstructions.push(memberInstruction);
      }
      counter++;
      instructionSet.push({
        description: `Chunk ${counter}`,
        instructions: chunkedInstructions,
      });
    }

    await sendTransactions(
      RPC_CONNECTION,
      creator,
      instructionSet,
      SequenceType.Sequential,
      true
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const leaveClub = async (
  clubName: string,
  memberData: PublicKey,
  clubData: PublicKey,
  payer: AnchorWallet,
  treasuryDatas: PublicKey[]
) => {
  try {
    const program = programFactory();

    const [realmAddress] = await PublicKey.findProgramAddressSync(
      [governanceSeed, Buffer.from(clubName)],
      UNQ_SPL_GOVERNANCE_PROGRAM_ID
    );

    const [voterWeightAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        clubData.toBuffer(),
        voterWeightSeed,
        payer.publicKey.toBuffer(),
      ],
      program.programId
    );

    const leaveClubIx = await program.methods
      .leaveClub()
      .accounts({
        clubData,
        memberData,
        payer: payer.publicKey,
        realm: realmAddress,
        recipient: payer.publicKey,
        recipientMemberData: memberData,
        systemProgram: SystemProgram.programId,
        voterWeightRecord: voterWeightAddress,
      })
      .remainingAccounts(
        getRemainingAccountsForDeleteMember(treasuryDatas, payer.publicKey)
      )
      .instruction();

    await sendTransactions(
      RPC_CONNECTION,
      payer,
      [
        {
          description: "Leave club",
          instructions: [leaveClubIx],
        },
      ],
      SequenceType.Sequential,
      true
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const removeMember = async (
  memberRole: string,
  memberStatus: MemberStatus,
  clubData: PublicKey,
  clubName: string,
  memberAddress: PublicKey,
  updaterMemberData: PublicKey,
  payer: AnchorWallet,
  treasuryDatas: PublicKey[]
) => {
  try {
    const program = programFactory();

    const [realmAddress] = await PublicKey.findProgramAddressSync(
      [governanceSeed, Buffer.from(clubName)],
      UNQ_SPL_GOVERNANCE_PROGRAM_ID
    );

    const [voterWeightAddress] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        clubData.toBuffer(),
        voterWeightSeed,
        memberAddress.toBuffer(),
      ],
      program.programId
    );

    const [memberData] = PublicKey.findProgramAddressSync(
      [
        unqClubSeed,
        clubData.toBuffer(),
        unqClubMemberSeed,
        memberAddress.toBuffer(),
      ],
      program.programId
    );

    const removeMemberIx = await program.methods
      .updateMember({
        isMember: false,
        role: null,
        status: null,
      })
      .accounts({
        clubData,
        memberAddress,
        memberData,
        memberVoterWeightRecord: voterWeightAddress,
        payer: payer.publicKey,
        realm: realmAddress,
        updaterMemberData,
        systemProgram: SystemProgram.programId,
      })
      .remainingAccounts(
        getRemainingAccountsForDeleteMember(treasuryDatas, memberAddress)
      )
      .instruction();

    await sendTransactions(
      RPC_CONNECTION,
      payer,
      [
        {
          description: "Remove member",
          instructions: [removeMemberIx],
        },
      ],
      SequenceType.Sequential,
      true
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};
