export enum Vote {
  Accept = "Accept",
  Reject = "Reject",
}

export enum ProposalType {
  Discussion = "Discussion",
  Trading = "Trading",
  TransferFunds = "Transfer Funds",
  Withdrawal = "Withdrawal",
  ConfigurationChanges = "Configuration Changes",
  Fundraise = "Fundraise",
  AddSellPermission = "Add sell permission",
}

export enum ChangeConfigurationTypeEnum {
  VotingPower = "Voting power",
  VotePercentage = "Vote percentage",
}

export enum ChangeGovernanceTypeEnum {
  DefaultConfiguration = "Default configuration",
  WithdrawalConfiguration = "Withdrawal configuration",
  TransferConfiguration = "Transfer configuration",
  ChangeClubConfiguration = "Change club configuration",
  TradeApprovalConfiguration = "Trade approval configuration",
}
export enum ProposalTypeProgram {
  BuyP2P,
  SellP2P,
  BuyNowMagicEden,
  SellMagicEden,
  TransferFunds,
  Discussion,
  Withdrawal,
  SellSolsea,
  BuySolsea,
  UpdateGovernanceConfig,
  UpdateRoleConfig,
  CreateFundraise,
  AddSellPermission,
}

export enum ProposalMetadataStatus {
  Created,
  Voting,
  Succeded,
  Defeated,
  Executed,
  Completed,
  Canceled,
  FundsTransfered,
  BoughtOnSolsea,
  SoldOnSolsea,
  WithdrawalExecuted,
  BoughtP2P,
  SoldP2P,
  GovernanceConfigUpdated,
  RoleConfigUpdated,
}
export enum UserProposalVoting {
  VotedYes = "VotedYes",
  DidNotVote = "DidNotVote",
  VotedNo = "VotedNo",
}
