import React, { FC } from "react";
import "./ProposalModalProgress.scss";
import { ProgressBarCircle } from "../../ProgressBarCircle/ProgressBarCircle";
import { ProposalVotingActions } from "../../ProposalActions/ProposalActions";
import { ProposalState, Vote } from "@solana/spl-governance";
import { IProposal } from "../../../common/interfaces/proposal.interface";
import { calculateVotePercentage } from "../../../utilities/helpers";

const ProposalModalProgress: FC<{
  proposal: IProposal;
  voteForProposal: (choice: Vote) => void;
  executeProposal: () => void;
}> = ({ proposal, executeProposal, voteForProposal }) => {
  const approvedVotes = calculateVotePercentage(
    proposal.proposalAccount.yesVotesCount ?? 0,
    proposal.proposalMetadata.maxVoterWeight
  );
  const rejectedVotes = calculateVotePercentage(
    proposal.proposalAccount.denyVoteWeight ?? 0,
    proposal.proposalMetadata.maxVoterWeight
  );
  return (
    <div className="proposal-modal-progress">
      <div className="proposal-modal-progress__bar">
        <ProgressBarCircle
          approved={approvedVotes}
          rejected={rejectedVotes}
          maxVoterWeight={proposal.proposalMetadata.maxVoterWeight ?? 0}
        />
      </div>
      <ProposalVotingActions
        proposal={proposal}
        spaced
        voteForProposal={voteForProposal}
      />
      <div className="proposal-modal-progress__bar-results">
        <p>{approvedVotes}% Voted to Accept</p>
        <p>{rejectedVotes}% Voted to Reject</p>
      </div>
    </div>
  );
};

export default ProposalModalProgress;
