import { EMPTY_STRING } from "../../common/constants/common.constants";
import { AllocationLimitation } from "../../common/enums/fundraise.enum";

import {
  ChangeConfigurationTypeEnum,
  ChangeGovernanceTypeEnum,
  ProposalType,
} from "../../common/enums/proposal.enum";

export default {
  formId: "create-proposal",
  formFields: {
    proposalType: {
      name: "proposalType",
      label: "Proposal type",
      value: ProposalType.Discussion,
    },
    title: {
      name: "title",
      label: "Title",
      value: EMPTY_STRING,
    },
    description: {
      name: "description",
      label: "Description",
      value: EMPTY_STRING,
    },
    discussionLink: {
      name: "discussionLink",
      label: "External discussion URL",
      value: EMPTY_STRING,
    },
    transferAmount: {
      name: "transferAmount",
      label: "Amount to transfer",
      value: EMPTY_STRING,
    },
    transferAddress: {
      name: "transferAddress",
      label: "Address where to transfer funds",
      value: EMPTY_STRING,
    },
    withdrawalAmount: {
      name: "withdrawalAmount",
      label: "Amount to be withdrawn",
      value: EMPTY_STRING,
    },

    tokenAddress: {
      name: "tokenAddress",
      label: "Token",
      value: EMPTY_STRING,
    },
    amount: {
      name: "amount",
      label: "Amount",
      value: EMPTY_STRING,
    },
    tokenUnlockDate: {
      name: "tokenUnlockDate",
      value: [],
    },
    tokensMembersAmount: {
      name: "tokensMembersAmount",
      label: "Amount between members",
      value: EMPTY_STRING,
    },
    allocationLimitation: {
      name: "allocationLimitation",
      label: "Allocation Limitation",
      value: AllocationLimitation.Unlimited,
    },
    members: {
      name: "members",
      value: [],
      label: "Members",
    },

    changeConfigurationType: {
      name: "changeConfigurationType",
      label: "Change configuration type",
      value: ChangeConfigurationTypeEnum.VotePercentage,
    },

    changeGovernanceType: {
      name: "changeGovernanceType",
      label: "Change governance type",
      value: ChangeGovernanceTypeEnum.DefaultConfiguration,
    },
    selectedGovernance: {
      name: "selectedGovernance",
      label: "Governance",
      value: undefined,
    },
    votingPowers: {
      name: "votingPowers",
      value: [
        {
          role: EMPTY_STRING,
          roleVotingPower: 0,
          oldVotingPower: 0,
        },
      ],
      label: "Voting powers",
    },

    approvalVotePercentage: {
      name: "approvalVotePercentage",
      label: "Approval vote percentage",
      value: 0,
    },

    maxVotingTime: {
      name: "maxVotingTime",
      label: "Max voting time",
      value: 0,
    },

    from: {
      name: "from",
      label: "from",
      value: EMPTY_STRING,
    },
    to: {
      name: "to",
      label: "to",
      value: EMPTY_STRING,
    },
    quorum: {
      name: "quorum",
      label: "quorum",
      value: EMPTY_STRING,
    },

    sellPermision: {
      name: "sellPermision",
      label: "",
      value: undefined,
    },
  },
};
