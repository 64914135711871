import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { FC, ReactNode } from "react";
import { useLocation } from "react-router";
import { DocumentNode, useLazyQuery, useSubscription } from "@apollo/client";
import {
  DEPOSIT_SUBSCRIPTION,
  FUNDRAISE_SUBSCRIPTION,
  MEMBER_DATA_CHANGED_SUBSCRIPTION,
  MEMBER_LEFT_SUBSCRIPTION,
  PROPOSAL_SUBSCRIPTION,
  TREASURY_DATA_UPDATED_SUBSCRIPTION,
  TREAUSYRY_MEMBER_ADDED,
  USER_MEMBER_DATA_CHANGED_SUBSCRIPTION,
  USER_WITHDRAWN_SUBSCRIPTION,
} from "../../api/club-subscriptions.api";
import {
  FetchingDataState,
  TransactionState,
} from "../../common/enums/common.enum";
import useTransactionStore from "../../state/transactionStore";
import {
  ACTIVE_TREASURY_INFO,
  GET_ALL_TREASURIES_FOR_CLUB,
  GET_ALL_TREASURY_PROPOSALS,
  GET_FUNDRAISES_FOR_TREASURY,
  GET_INITIAL_CLUB_DETAILS,
  GET_MEMBER_ACTIVE_TREASURY_INFO,
  GET_MEMBER_DATA,
  GET_SINGLE_PROPOSAL,
  GET_TREASURY_MEMBERS,
} from "../../api/club.api";
import { apolloClient } from "../../api/config.api";
import { clubStore } from "../../state/clubStore";
import { MEMBERS } from "../../common/constants/routes.constants";
import { ProposalState } from "@solana/spl-governance";

const ClubDetailsSubscriptions: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const wallet = useAnchorWallet();
  const location = useLocation();

  const { updateFetchingData, transactions } = useTransactionStore();
  const {
    activeTreasury,
    clubBasicInfo,
    updateProposal,
    memberData,
    setMemberData,
    updateWithdrawalProposalMemberData,
  } = clubStore();

  const [getUpdatedProposal, updatedProposalInfo] = useLazyQuery(
    GET_SINGLE_PROPOSAL,
    {
      onCompleted: (data) => {
        console.log(data, "GET SINGLE PROPOSAL");
        updateProposal(data?.getSingleProposal);
        updateFetchingData(FetchingDataState.Succeeded);
      },
      fetchPolicy: "no-cache",
    }
  );

  useSubscription(DEPOSIT_SUBSCRIPTION, {
    onData: ({ data }) => {
      console.log(data, "DEPOSIT SUB DATA");
      const refetchQueries: DocumentNode[] = [];
      if (
        data.data.updatedFinancialRecord.financialRecord.authority ===
        wallet?.publicKey.toString()
      ) {
        refetchQueries.push(GET_MEMBER_ACTIVE_TREASURY_INFO);
      }
      refetchQueries.push(GET_FUNDRAISES_FOR_TREASURY);
      refetchQueries.push(ACTIVE_TREASURY_INFO);
      apolloClient.refetchQueries({
        include: refetchQueries,
      });
      updateFetchingData(FetchingDataState.Succeeded);
    },
    variables: {
      treasuryAddress: activeTreasury?.treasuryAddress,
    },
  });

  useSubscription(FUNDRAISE_SUBSCRIPTION, {
    onData: async ({ data }) => {
      console.log(data, "FUNDRAISE SUB DATA");

      //refetch treasury and fundraise
      apolloClient.refetchQueries({
        include: [
          ACTIVE_TREASURY_INFO,
          GET_ALL_TREASURY_PROPOSALS, //TODO@milica: check if this is necessary
          GET_FUNDRAISES_FOR_TREASURY,
        ],
      });
      updateFetchingData(FetchingDataState.Succeeded);
    },
    variables: {
      treasuryAddress: activeTreasury?.treasuryAddress,
    },
  });

  useSubscription(PROPOSAL_SUBSCRIPTION, {
    onData: async ({ client, data }) => {
      console.log(data, "PROPOSAL SUB DATA");
      //TODO: add filters for proposals that has effect on trasury
      apolloClient.refetchQueries({
        include: [ACTIVE_TREASURY_INFO, GET_ALL_TREASURIES_FOR_CLUB],
      });
      if (
        data.data?.proposalUpdated.proposalAccount.state !== ProposalState.Draft
      ) {
        getUpdatedProposal({
          variables: {
            proposalAddress:
              data.data?.proposalUpdated.proposalMetadata.proposal,
            userAddress: wallet?.publicKey.toString(),
          },
        });
      }
    },
    variables: {
      treasuryAddress: activeTreasury?.treasuryAddress,
    },
  });

  useSubscription(TREASURY_DATA_UPDATED_SUBSCRIPTION, {
    onData: ({ data }) => {
      console.log("TREASURY DATA CHANGED SUB");
      if (
        transactions.filter(
          (item) => item.transactionState === TransactionState.Succeeded
        ).length === transactions.length
      ) {
        apolloClient.refetchQueries({
          include: [GET_ALL_TREASURIES_FOR_CLUB],
        });
        updateFetchingData(FetchingDataState.Succeeded);
      }
    },
    variables: {
      clubData: clubBasicInfo?.address,
    },
  });

  useSubscription(USER_MEMBER_DATA_CHANGED_SUBSCRIPTION, {
    onData: ({ data }) => {
      console.log("USER MEMBER DATA SUB");
      if (data.data?.mineMemberDataChanged.isDeleted) {
        setMemberData(undefined);
      } else {
        apolloClient.refetchQueries({
          include: [GET_MEMBER_DATA, GET_INITIAL_CLUB_DETAILS],
        });
      }
      updateFetchingData(FetchingDataState.Succeeded);
    },
    variables: {
      userAddress: wallet?.publicKey.toString(),
    },
  });

  useSubscription(MEMBER_DATA_CHANGED_SUBSCRIPTION, {
    onData: ({ data }) => {
      console.log("MEMBER DATA CHANGED SUB", data);
      if (!location.pathname.includes(MEMBERS)) {
        updateFetchingData(FetchingDataState.Succeeded);
      }
    },
    variables: {
      clubAddress: clubBasicInfo?.address,
    },
  });

  useSubscription(USER_WITHDRAWN_SUBSCRIPTION, {
    onData: ({ data }) => {
      console.log("USER WITHDRAWN SUB", data);
      apolloClient.refetchQueries({
        include: [ACTIVE_TREASURY_INFO, GET_ALL_TREASURIES_FOR_CLUB],
      });

      updateWithdrawalProposalMemberData(data.data.userWithdrawn);
      updateFetchingData(FetchingDataState.Succeeded);
    },
    variables: {
      userAddress: wallet?.publicKey.toString(),
    },
  });

  useSubscription(TREAUSYRY_MEMBER_ADDED, {
    onData: ({ data }) => {
      apolloClient.refetchQueries({
        include: [GET_TREASURY_MEMBERS],
      });
      updateFetchingData(FetchingDataState.Succeeded);
    },
    variables: {
      clubAddress: clubBasicInfo?.address,
    },
  });

  useSubscription(MEMBER_LEFT_SUBSCRIPTION, {
    onData: ({ data }) => {
      console.log("MEMBER LEFT SUB", data);
      setMemberData(undefined);
      apolloClient.refetchQueries({
        include: [GET_INITIAL_CLUB_DETAILS],
      });
      updateFetchingData(FetchingDataState.Succeeded);
    },
    variables: {
      clubAddress: clubBasicInfo?.address,
    },
  });

  return <div>{children}</div>;
};

export default ClubDetailsSubscriptions;
