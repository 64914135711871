import React, { FC, useCallback, useState } from "react";
import "./MemberCard.scss";
import {
  DEVNET,
  DEVNET_CLUSTER,
  EMPTY_STRING,
  MAINNET_CLUSTER,
  MESSAGE_TYPE,
  SOLSCAN_URL,
} from "../../../common/constants/common.constants";
import link from "../../../assets/link.png";
import UserImage from "../../../components/UserImage/UserImage";
import {
  checkIfUserHasClubPermissionForAction,
  formatDateWithTime,
} from "../../../utilities/helpers";
import { TabLabel } from "../../../common/enums/common.enum";
import declineIcon from "../../../assets/cancel-circle-light.png";
import { ISocialNetwork } from "../../../common/interfaces/club.interface";
import ApplicationAndMemberDetailsModal from "../ApplicationAndMemberDetailsModal/ApplicationAndMemberDetailsModal";
import { inviteMember, rejectInvitation } from "../../../program/methods/clubs";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { clubStore } from "../../../state/clubStore";
import {
  GET_APPLICATIONS_FOR_CLUB,
  updateApplication,
} from "../../../api/club.api";
import {
  AllowType,
  ApplicationStatus,
  ClubAction,
} from "../../../common/enums/clubs.enum";
import { apolloClient } from "../../../api/config.api";
import { SOLANA_ENDPOINT } from "../../../program/utils";
import { createNotification } from "../../../utilities/notifications";
import { PublicKey } from "@metaplex-foundation/js";

const MemberCard: FC<{
  tab?: TabLabel;
  imageUrl?: string;
  memberName?: string;
  publicKey: string;
  role?: string;
  appliedAt?: Date;
  joinedAt?: Date;
  networks?: ISocialNetwork[];
  motivationLetter?: string;
}> = ({
  tab,
  publicKey,
  appliedAt,
  imageUrl,
  joinedAt,
  memberName,
  role,
  networks,
  motivationLetter,
}) => {
  const [showActions, setShowActions] = useState(false);
  const [showApplicationDetailsModal, setShowApplicationDetailsModal] =
    useState(false);

  const { clubBasicInfo, memberData, activeTreasury } = clubStore();

  const wallet = useAnchorWallet();

  const handleOnClick = () => {
    tab !== TabLabel.Invitations &&
      checkIfUserHasClubPermissionForAction(
        memberData,
        clubBasicInfo?.roleConfig,
        ClubAction.AllowMember
      ) &&
      setShowApplicationDetailsModal(true);
  };

  const handleDenyApplication = async (e?: any) => {
    if (e) {
      e.stopPropagation();
    }

    try {
      if (!wallet || !clubBasicInfo) return;
      await updateApplication(
        wallet.publicKey.toString(),
        clubBasicInfo?.address,
        publicKey,
        ApplicationStatus.Rejected
      );

      await apolloClient.refetchQueries({
        include: [GET_APPLICATIONS_FOR_CLUB],
      });

      if (showApplicationDetailsModal) {
        setShowApplicationDetailsModal(false);
      }
      createNotification(
        MESSAGE_TYPE.SUCCESS,
        "Application successfully denied"
      );
    } catch (error) {
      console.log(error);
      createNotification(MESSAGE_TYPE.ERROR, "Failed to deny application");
    }
  };

  const handleAcceptApplication = async (
    role: string,
    e?: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    if (e) {
      e.stopPropagation();
    }
    try {
      if (!wallet || !clubBasicInfo || !role) return;
      //Send invitation to program
      await inviteMember(
        [{ role: role, wallet: publicKey }],
        clubBasicInfo.name,
        wallet,
        AllowType.Club,
        activeTreasury && new PublicKey(activeTreasury?.treasuryDataAddress),
        activeTreasury && new PublicKey(activeTreasury?.realmAddress)
      );
      //If transaction is successfully sent, update application status on api
      await updateApplication(
        wallet.publicKey.toString(),
        clubBasicInfo?.address,
        publicKey,
        ApplicationStatus.Accepted
      );
      await apolloClient.refetchQueries({
        include: [GET_APPLICATIONS_FOR_CLUB],
      });
      if (showApplicationDetailsModal) {
        setShowApplicationDetailsModal(false);
      }
      createNotification(
        MESSAGE_TYPE.SUCCESS,
        "Application successfully accepted"
      );
    } catch (error) {
      console.log(error);
      createNotification(MESSAGE_TYPE.ERROR, "Failed to accept application");
    }
  };

  const handleRejectInvitation = async () => {
    try {
      if (!wallet || !clubBasicInfo) return;
      await rejectInvitation(
        wallet,
        clubBasicInfo?.address,
        publicKey,
        clubBasicInfo?.authority
      );
      createNotification(MESSAGE_TYPE.SUCCESS, "Invitation rejected");
    } catch (error) {
      console.log(error);
      createNotification(MESSAGE_TYPE.ERROR, "Failed to reject invitation");
    }
  };

  const findMemberOnExplorer = useCallback(() => {
    return publicKey
      ? `${SOLSCAN_URL}/address/${publicKey}?${
          SOLANA_ENDPOINT.includes(DEVNET) ? DEVNET_CLUSTER : MAINNET_CLUSTER
        }`
      : EMPTY_STRING;
  }, [publicKey]);

  return (
    <>
      {showApplicationDetailsModal && (
        <ApplicationAndMemberDetailsModal
          publicKey={publicKey}
          imageUrl={imageUrl}
          memberName={memberName}
          motivationLetter={motivationLetter}
          networks={networks}
          onClose={() => setShowApplicationDetailsModal(false)}
          handleAcceptApplication={handleAcceptApplication}
          handleDenyApplication={handleDenyApplication}
          title={
            tab === TabLabel.Members ? "Club member" : "Member application"
          }
          alreadyAMember={tab === TabLabel.Members}
        />
      )}
      <div
        className="member-card"
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
        onClick={handleOnClick}
      >
        <div className="member-card__name">
          {imageUrl ? (
            <img src={imageUrl} alt="Member" />
          ) : (
            <UserImage name={memberName ?? publicKey} image={EMPTY_STRING} />
          )}
          <div>
            <h4>{memberName ?? publicKey}</h4>
            <p>{role}</p>
          </div>
        </div>
        <>
          {tab === TabLabel.Invitations && (
            <div className="member-card__date">
              <div>
                {showActions && (
                  <div className="member-card__all-box-actions">
                    {showActions && (
                      <>
                        <a href={findMemberOnExplorer()} target="_blank">
                          <img src={link} alt="Checkmark" />
                        </a>

                        <button
                          disabled={
                            !checkIfUserHasClubPermissionForAction(
                              memberData,
                              clubBasicInfo?.roleConfig,
                              ClubAction.CancelInvitation
                            )
                          }
                          onClick={handleRejectInvitation}
                        >
                          <img src={declineIcon} alt="X" />
                        </button>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {tab === TabLabel.Members && joinedAt && (
            <div className="member-card__date">
              <div>
                <h6>Joined at</h6>
                <p>{formatDateWithTime(joinedAt)}</p>
              </div>

              <div className="member-card__all-box-actions">
                <a href={findMemberOnExplorer()} target="_blank">
                  <img
                    src={link}
                    alt="Checkmark"
                    style={{ visibility: showActions ? "visible" : "hidden" }}
                  />
                </a>
              </div>
            </div>
          )}
          {tab === TabLabel.Applications && appliedAt && (
            <div className="member-card__date">
              <div>
                <h6>Applied</h6>
                <p>{formatDateWithTime(appliedAt)}</p>
              </div>
              <div>
                {showActions && (
                  <div className="member-card__all-box-actions">
                    {showActions && (
                      <>
                        <a href={findMemberOnExplorer()} target="_blank">
                          <img
                            src={link}
                            alt="Checkmark"
                            style={{
                              visibility: showActions ? "visible" : "hidden",
                            }}
                          />
                        </a>
                        <button
                          onClick={(e) => handleDenyApplication(e)}
                          disabled={
                            !checkIfUserHasClubPermissionForAction(
                              memberData,
                              clubBasicInfo?.roleConfig,
                              ClubAction.AllowMember
                            )
                          }
                        >
                          <img src={declineIcon} alt="X" />
                        </button>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default MemberCard;
