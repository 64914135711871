import React, { useMemo } from "react";
import Modal from "../Modal/Modal";
import Ownership from "../Ownership/Ownership";
import { Form, Formik } from "formik";
import setReservedRightsFormConfig from "./setReservedRightsFormConfig";
import { ISetReservedRights } from "../../common/interfaces/form.interface";
import FormActions from "../FormActions/FormActions";
import { setReservedRightsValidation } from "./setReservedRightsValidation";

const SetReservedRightsModal: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const handleSubmitForm = () => {
    console.log("Successful");
    onClose();
  };

  const initialValues: ISetReservedRights = useMemo(
    () =>
      (() => {
        const values: any = {};
        Object.keys(setReservedRightsFormConfig.formFields).map(
          (key) =>
            (values[key] = (setReservedRightsFormConfig.formFields as any)[
              key
            ].value)
        );
        return values;
      })(),
    []
  );
  return (
    <Modal closeModal={onClose} title="Reserved rights">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validateOnBlur
        validate={(values) => setReservedRightsValidation(values)}
      >
        {({ values }) => (
          <Form id={setReservedRightsFormConfig.formId}>
            <Ownership members={values.members} />
            <FormActions
              cancelAction={onClose}
              buttonText="Save changes"
              buttonAction={() => handleSubmitForm}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default SetReservedRightsModal;
