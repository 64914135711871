import { FC } from "react";
import "./Chip.scss";
import { EMPTY_STRING } from "../../common/constants/common.constants";

const Chip: FC<{
  text: string | number;
  handleClick?: () => void;
  isActive?: boolean;
  backgroundColor?: string;
  padding?: string;
  fontSize?: string;
  borderRadius?: string;
  color?: string;
  stretch?: boolean;
  icon?: boolean;
  image?: string;
  borderColor?: string;
}> = ({
  text,
  backgroundColor,
  padding,
  fontSize,
  borderRadius,
  color,
  handleClick,
  isActive,
  stretch,
  icon,
  image,
  borderColor,
}) => {
  return (
    <div
      onClick={handleClick}
      className={isActive ? "chip chip--active" : "chip"}
      style={{
        backgroundColor: backgroundColor ?? EMPTY_STRING,
        fontSize: `${fontSize}em` ?? EMPTY_STRING,
        padding: `${padding}` ?? EMPTY_STRING,
        borderRadius: `${borderRadius}px` ?? EMPTY_STRING,
        color: color ?? EMPTY_STRING,
        width: stretch ? "100%" : EMPTY_STRING,
        border: borderColor ? `1px solid ${borderColor}` : "none",
      }}
    >
      <p>
        {icon && <img src={image} alt="Icon" />}
        {text}
      </p>
    </div>
  );
};

export default Chip;
