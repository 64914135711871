import "./PastFundraises.scss";
import openInNew from "../../../assets/open_in_new.png";
import { IFundraiseConfig } from "../../../common/interfaces/club.interface";
import { clubStore } from "../../../state/clubStore";
import { getAmountWithDecimalsForCurrency } from "../../../utilities/helpers";
import React, { FC } from "react";

const PastFundraises: FC<{ fundraise: IFundraiseConfig }> = ({ fundraise }) => {
  const { activeTreasury } = clubStore();

  return (
    <div className="past-fundraise">
      <div>
        <label className="past-fundraise__title">
          Fundraise {fundraise.fundraiseIndex}{" "}
        </label>
        <p className="past-fundraise__description">
          is now{" "}
          <span
            className={`past-fundraise__status 
          ${!fundraise.isActive && "past-fundraise__status--finished"}`}
          >
            {fundraise.isActive ? "In progress" : "Finished"}
          </span>{" "}
          raised{" "}
          <span className="past-fundraise__totaly_raised">
            {getAmountWithDecimalsForCurrency(
              activeTreasury?.currencyDecimals,
              fundraise.raisedAmount
            )}
          </span>{" "}
          <span className="past-fundraise__currency">
            {activeTreasury?.currencySymbol}
          </span>
        </p>
      </div>
      <div className="past-fundraise__options">
        {/* We don't have information about the date */}
        {/* <label className="past-fundraise__date">
          {formatDateWithTime(item.date)}
        </label>{" "} */}
      </div>
    </div>
  );
};
export default PastFundraises;
