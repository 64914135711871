import "./TreasuryConfigurationModal.scss";
import Modal from "../../../components/Modal/Modal";
import React, { FC } from "react";
import LabelCard from "../../../components/LabelCard/LabelCard";
import { ITreasuryData } from "../../../common/interfaces/club.interface";

const TreasuryConfigurationModal: FC<{
  closeModal: () => void;
  treasuryData: ITreasuryData;
}> = ({ closeModal, treasuryData }) => {
  return (
    <Modal title="Treasury configuration" closeModal={closeModal}>
      <div className="treasury-configuration-modal">
        <div className="treasury-configuration-modal__details">
          <LabelCard
            label="Treasury name"
            description={treasuryData.treasuryName}
          />
          <LabelCard label="Chain" description="Solana" />
          <LabelCard
            label="Denominated currency"
            description={treasuryData.currencySymbol}
          />
          <LabelCard
            label="Roles"
            description={`${treasuryData.treasuryRoleConfig.map(
              (item) => `${item.name}`
            )}`}
          />
        </div>
        <div className="treasury-configuration-modal__governance-rules">
          <h3>Governance rules</h3>
          <LabelCard
            label="Default"
            description={`${treasuryData.treasuryGovernance.voteThreshold.toString()}%`}
          />
          <LabelCard
            label="Withdrawal"
            description={`${
              treasuryData.withdrawalGovernance
                ? treasuryData.withdrawalGovernance.voteThreshold.toString()
                : treasuryData.treasuryGovernance.voteThreshold.toString()
            }%`}
          />
          <LabelCard
            label="Transfer"
            description={`${
              treasuryData.transferGovernance
                ? treasuryData.transferGovernance.voteThreshold.toString()
                : treasuryData.treasuryGovernance.voteThreshold.toString()
            }%`}
          />
          <LabelCard
            label="Change governance"
            description={`${
              treasuryData.changeConfigGovernance
                ? treasuryData.changeConfigGovernance.voteThreshold.toString()
                : treasuryData.treasuryGovernance.voteThreshold.toString()
            }%`}
          />
        </div>
      </div>
    </Modal>
  );
};
export default TreasuryConfigurationModal;
