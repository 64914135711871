import React, { FC } from "react";
import { IAllocation } from "../../../common/interfaces/treasury.interface";
import "./Allocation.scss";
import {
  formatDateWithTime,
  getAmountWithDecimalsForCurrency,
} from "../../../utilities/helpers";
import openInNew from "../../../assets/open_in_new.png";
import { ICustomAllocation } from "../../../common/interfaces/club.interface";
import { clubStore } from "../../../state/clubStore";

const Allocation: FC<{ allocation: ICustomAllocation }> = ({ allocation }) => {
  const { activeTreasury } = clubStore();
  return (
    <div className="allocation-item">
      <p className="allocation-item__description">
        <span className="allocation-item__amount">
          {getAmountWithDecimalsForCurrency(
            activeTreasury?.currencyDecimals,
            allocation.amount
          )}
        </span>{" "}
        <span className="allocation-item__currency">
          {activeTreasury?.currencySymbol}{" "}
        </span>
        allocated to
        <span className="allocation-item__adress"> {allocation.authority}</span>
      </p>
    </div>
  );
};

export default Allocation;
