import { EMPTY_STRING } from "../common/constants/common.constants";
import {
  IBasicClubData,
  IClubData,
  IKYCConfig,
  IMemberInfo,
  ISocialNetwork,
  IVertical,
} from "../common/interfaces/club.interface";
import { apolloClient } from "./config.api";
import { gql } from "@apollo/client";
import { ApplicationStatus, DefaultExplorer } from "../common/enums/clubs.enum";
import { mint } from "../program/utils";
import { async } from "q";
import { ITokenUnlockDate } from "../common/interfaces/form.interface";
import { string } from "mathjs";
import { ITokenUnlockingInput } from "../common/interfaces/common.interface";

export const GET_BROWSE_CLUBS_DATA = gql`
  query getClubData(
    $browseClubFilters: BrowseClubFilters!
    $memberAddress: String
  ) {
    getClubData(
      browseClubFilters: $browseClubFilters
      memberAddress: $memberAddress
    ) {
      address
      imageUrl
      name
      activeFundraise
      totalFundraiseAmount
      fundraisedAmount
      verticals {
        label
        slug
      }
      createdAt
    }
  }
`;

export const getBrowseClubsInfo = async (
  memberAddress?: string
): Promise<IBasicClubData[]> => {
  try {
    const response = await apolloClient.query({
      query: gql`
        query getClubData($memberAddress: String) {
          getClubData(memberAddress: $memberAddress) {
            address
            imageUrl
            name
            activeFundraise
            totalFundraiseAmount
            fundraisedAmount
            verticals {
              label
              slug
            }
            createdAt
          }
        }
      `,
      variables: {
        memberAddress,
      },
    });
    return response.data.getClubData.map((item: IBasicClubData) => {
      return {
        ...item,
        createdAt: new Date(item.createdAt),
      };
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const storeClubBasicInfo = async (
  verticals: IVertical[],
  image: string,
  address: string,
  description: string
) => {
  try {
    await apolloClient.query({
      query: gql`
        mutation storeClubBasicInfo($clubDataDto: ClubDataDto!) {
          storeClubBasicInfo(clubDataDto: $clubDataDto)
        }
      `,
      variables: {
        clubDataDto: {
          verticals,
          image,
          address,
          description,
        },
      },
    });
  } catch (error) {
    throw error;
  }
};

export const saveTokenUnlockingData = async (
  signer: string,
  clubAddress: string,
  tokenUnlocking: ITokenUnlockingInput[]
) => {
  await apolloClient.query({
    query: gql`
      mutation saveTokenUnlockingData(
        $signer: String!
        $clubAddress: String!
        $tokenUnlocking: [TokenUnlockingInput!]!
      ) {
        saveTokenUnlockingData(
          signer: $signer
          clubAddress: $clubAddress
          tokenUnlocking: $tokenUnlocking
        )
      }
    `,
    variables: {
      signer,
      clubAddress,
      tokenUnlocking,
    },
  });

  try {
  } catch (error) {
    throw error;
  }
};

export const updateApplication = async (
  authorityAddress: string,
  clubAddress: string,
  applicantAddress: string,
  newStatus: ApplicationStatus
) => {
  try {
    await apolloClient.query({
      query: gql`
        mutation changeStatus(
          $authorityAddress: String!
          $clubAddress: String!
          $applicantAddress: String!
          $newStatus: ApplicationStatus!
        ) {
          changeStatus(
            authorityAddress: $authorityAddress
            clubAddress: $clubAddress
            applicantAddress: $applicantAddress
            newStatus: $newStatus
          )
        }
      `,
      variables: {
        authorityAddress,
        clubAddress,
        applicantAddress,
        newStatus,
      },
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const storeApplication = async (
  clubAddress: string,
  memberAddress: string,
  motivationLetter: string,
  networks: ISocialNetwork[]
) => {
  try {
    const response = await apolloClient.query({
      query: gql`
        mutation storeApplication($application: ApplicationInput!) {
          storeApplication(application: $application)
        }
      `,
      variables: {
        application: {
          clubAddress,
          memberAddress,
          motivationLetter,
          networks,
        },
      },
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const mapInitialClubDetails = (response: any) => {
  const rawClubDatas = response.getClubDetails.clubBasicInfo;

  const kycConfig: IKYCConfig | undefined =
    rawClubDatas.kycConfig !== EMPTY_STRING &&
    JSON.parse(rawClubDatas.kycConfig);
  const clubData: IClubData = {
    ...rawClubDatas,
    kycConfig: kycConfig,
    communityMint: mint, //TODO: for now
  };

  return {
    clubBasicInfo: clubData,
    clubMembers: response.getClubDetails.clubMembers,
  };
};

export const mapActiveTreasuryInfo = (data: any) => {
  return {
    activeTreasury: data.getTreasuryDetails.treasury,
    fundraises: data.getTreasuryDetails.fundraises,
    vault: data.getTreasuryDetails.vaults[0],
  };
};

export const GET_INITIAL_CLUB_DETAILS = gql`
  query getClubDetails($clubAddress: String!) {
    getClubDetails(clubAddress: $clubAddress) {
      clubBasicInfo {
        address
        authority
        name
        slotCreated
        clubType
        roleConfig {
          name
          clubActions
          membersCount
        }
        treasuryCount
        stakeCount
        activeStakeCount
        numberOfMembers
        hasActiveStaking
        defaultRole
        kycConfig
        createdAt
        imgUrl
        description
        verticals {
          label
          slug
        }
      }
    }
  }
`;

export const GET_ALL_TREASURIES_FOR_CLUB = gql`
  query getTreasuriesForClub($clubAddress: String!) {
    getTreasuriesForClub(clubAddress: $clubAddress) {
      treasuryName
      treasuryDataAddress
      treasuryAddress
      clubDataAddress
      realmAddress
      fundraiseCount
      hasActiveFundraise
      treasuryGovernance {
        address
        realm
        activeProposalCount
        governedAccount
        proposalCount
        voteThreshold
        maxVotingTime
      }
      maxVoterWeight
      totalFinancialPower
      denominatedCurrency
      financialTokenAccount
      transferGovernance {
        address
        realm
        activeProposalCount
        governedAccount
        proposalCount
        voteThreshold
        maxVotingTime
      }
      sellPermission {
        from
        to
        quorumMinimum
        decimal
        governance {
          address
          realm
          activeProposalCount
          governedAccount
          proposalCount
          voteThreshold
          maxVotingTime
        }
      }
      withdrawalGovernance {
        address
        realm
        activeProposalCount
        governedAccount
        proposalCount
        voteThreshold
        maxVotingTime
      }
      financialRecordCount
      balance
      currencyLogo
      currencySymbol
      currencyDecimals
      treasuryIndex
      financialOffersCount
      changeConfigGovernance {
        address
        realm
        activeProposalCount
        governedAccount
        proposalCount
        voteThreshold
        maxVotingTime
      }
      hasActiveUpdateGovernanceConfig
      reservedRights {
        totalReservedRights
        totalReservedPercentage
        individualRights {
          memberPubkey
          rightPercentage
          amountOfRights
        }
      }
      treasuryRoleConfig {
        name
        roleWeight
        treasuryActions
        membersCount
        isDefault
      }
      defaultRole
      treasuryTokens {
        tokenAddress
        symbol
        image
        decimals
        name
        mint
        balance
      }
    }
  }
`;

export const GET_ALL_TREASURY_ADDRESSES = gql`
  query getTreasuriesForClub($clubAddress: String!) {
    getTreasuriesForClub(clubAddress: $clubAddress) {
      treasuryName
      treasuryAddress
    }
  }
`;

export const ACTIVE_TREASURY_INFO = gql`
  query getTreasuryDetails($treasuryAddress: String!) {
    getTreasuryDetails(treasuryAddress: $treasuryAddress) {
      treasury {
        treasuryName
        treasuryDataAddress
        treasuryAddress
        clubDataAddress
        realmAddress
        fundraiseCount
        hasActiveFundraise
        treasuryGovernance {
          address
          realm
          activeProposalCount
          governedAccount
          proposalCount
          voteThreshold
          maxVotingTime
        }
        maxVoterWeight
        totalFinancialPower
        denominatedCurrency
        financialTokenAccount
        transferGovernance {
          address
          realm
          activeProposalCount
          governedAccount
          proposalCount
          voteThreshold
          maxVotingTime
        }
        sellPermission {
          from
          to
          quorumMinimum
          decimal
          governance {
            address
            realm
            activeProposalCount
            governedAccount
            proposalCount
            voteThreshold
            maxVotingTime
          }
          index
        }
        withdrawalGovernance {
          address
          realm
          activeProposalCount
          governedAccount
          proposalCount
          voteThreshold
          maxVotingTime
        }
        financialRecordCount
        balance
        currencyLogo
        currencySymbol
        currencyDecimals
        treasuryIndex
        financialOffersCount
        changeConfigGovernance {
          address
          realm
          activeProposalCount
          governedAccount
          proposalCount
          voteThreshold
          maxVotingTime
        }
        hasActiveUpdateGovernanceConfig
        reservedRights {
          totalReservedRights
          totalReservedPercentage
          individualRights {
            memberPubkey
            rightPercentage
            amountOfRights
          }
        }
        treasuryRoleConfig {
          name
          roleWeight
          treasuryActions
          membersCount
          isDefault
        }
        defaultRole
        treasuryTokens {
          tokenAddress
          symbol
          image
          decimals
          name
          mint
          balance
        }
      }
      vaults {
        address
        nfts {
          mintAddress
          name
          image
          symbol
          collection
          tokenAddress
        }
      }
    }
  }
`;

export const GET_FUNDRAISES_FOR_TREASURY = gql`
  query getFundraisesForTreasury($treasuryAddress: String!) {
    getFundraisesForTreasury(treasuryAddress: $treasuryAddress) {
      address
      capAmount
      raisedAmount
      isActive
      fundraiseIndex
      allocation {
        equal
        custom {
          authority
          amount
        }
      }
      tokenUnlocking {
        tokenAmount
        date
        fundraiseAddress
      }
    }
  }
`;

export const GET_MEMBER_DATA = gql`
  query getMemberData($memberAddress: String!, $clubAddress: String!) {
    getMemberData(memberAddress: $memberAddress, clubAddress: $clubAddress) {
      address
      clubData
      memberPubkey
      isMember
      status
      role
      joinedAt
    }
  }
`;

export const GET_MEMBER_ACTIVE_TREASURY_INFO = gql`
  query getSingleFinancialRecord(
    $treasuryAddress: String!
    $memberAddress: String!
  ) {
    getSingleFinancialRecord(
      treasuryAddress: $treasuryAddress
      memberAddress: $memberAddress
    ) {
      address
      authority
      treasury
      sellOffersCount
      listedFinancialRights
      depositRecords {
        accumulatedAmount
        fundraiseIndex
        currentFundraiseDeposit
        tradedRight {
          amount
          timestamp
          tradeType
        }
      }
      treasuryRole
      createdAt
      tokenOwnerRecord
      ownership
      votingPower
      totalShares
      availableShares
    }
  }
`;

export const GET_TREASURY_MEMBERS = gql`
  query getAllFinancialRecordsForTreasury($treasuryAddress: String!) {
    getAllFinancialRecordsForTreasury(treasuryAddress: $treasuryAddress) {
      address
      authority
      treasury
      sellOffersCount
      listedFinancialRights
      depositRecords {
        accumulatedAmount
        fundraiseIndex
        currentFundraiseDeposit
        tradedRight {
          amount
          timestamp
          tradeType
        }
      }
      treasuryRole
      createdAt
      createdAtSlot
      tokenOwnerRecord

      memberInfo {
        userAddress
        imageUrl
        name
        socialNetworks {
          type
          value
        }
      }
      ownership
      votingPower
    }
  }
`;

export const GET_ALL_MEMBERS_FOR_CLUB = gql`
  query getAllClubMembers(
    $clubAddress: String!
    $memberDataFilters: MemberDataFilters!
  ) {
    getAllClubMembers(
      clubAddress: $clubAddress
      memberDataFilters: $memberDataFilters
    ) {
      memberData {
        address
        clubData
        memberPubkey
        isMember
        status
        role
        joinedAt
      }
      count
    }
  }
`;

export const GET_APPLICATIONS_FOR_CLUB = gql`
  query getApplicationsForClub(
    $clubAddress: String!
    $applicationFilters: ApplicationFilters!
  ) {
    getApplicationsForClub(
      clubAddress: $clubAddress
      applicationFilters: $applicationFilters
    ) {
      applications {
        clubAddress
        memberAddress
        motivationLetter
        appliedAt
        applicationStatus
        networks {
          type
          value
        }
      }
      count
    }
  }
`;

//Add proposals details
export const GET_ALL_TREASURY_PROPOSALS = gql`
  query getProposalsForTreasury(
    $treasuryAddress: String!
    $userAddress: String
    $proposalFilters: ProposalFilters!
  ) {
    getProposalsForTreasury(
      treasuryAddress: $treasuryAddress
      userAddress: $userAddress
      proposalFilters: $proposalFilters
    ) {
      proposals {
        proposalAccount {
          proposalInstructionAccounts {
            isWritable
            isSigner
            pubkey
          }
          governance {
            address
            realm
            activeProposalCount
            governedAccount
            proposalCount
            voteThreshold
            maxVotingTime
          }
          governingTokenMint
          state
          tokenOwnerRecord
          signatoriesCount
          signatoriesSignedOffCount
          yesVotesCount
          noVotesCount
          name
          description
          maxVoteWeight
          closedAt
          denyVoteWeight
          vetoVoteWeight
          abstainVoteWeight
        }
        proposalDetails {
          ... on ITransferProposalMetadata {
            offer
            treasuryToken
            destination
            amountToInvest
            mint
            mintDecimals
            mintLogo
            mintSymbol
          }
        }

        proposalDetails {
          ... on IAddSellPermissionProposal {
            sellPermissionData {
              governance {
                address
              }
              from
              to
              quorumMinimum
            }
            maxVotingTime
          }
        }

        proposalDetails {
          ... on IUpdateRoleConfigProposalMetadata {
            roleConfigData {
              role
              currentWeight
              updateWeight
            }
          }
        }

        proposalDetails {
          ... on ICreateFundraiseProposalMetadata {
            capAmount
          }
        }

        proposalDetails {
          ... on IUpdateGovernanceConfigProposalMetadata {
            newQuorum
            governancesToUpdate
            governedAccountsToUpdate
            newVotingTime
            governanceType
          }
        }

        proposalDetails {
          ... on IWithdrawalProposalMetadata {
            withdrawalAmount
            withdrawal
            withdrawalData
            treasuryToken
            withdrawalMembersInfo {
              proposal
              withdrawAmount
              withdrawal
              withdrawalData
              hasUserClaimed
              withdrawalMint
              withdrawalMintDecimals
              withdrawalMintSymbol
            }
            totalFinancialPower
            withdrawalMint
            mintSymbol
            mintDecimals
          }
        }
        proposalMetadata {
          proposalType
          proposalMetadataAddress
          name
          options
          authority
          discussionLink
          treasury
          totalFinancialPower
          proposal
          proposalIndex
          realm
          clubData
          isCreated
          authorityRole
          dataPda
          approvalQuorumPercentage
          maxVoterWeight
          canCancelProposal
          proposalStatus
          userVote
          description
        }
      }
      count
    }
  }
`;

export const GET_SINGLE_PROPOSAL = gql`
  query getSingleProposal($proposalAddress: String!, $userAddress: String!) {
    getSingleProposal(
      proposalAddress: $proposalAddress
      userAddress: $userAddress
    ) {
      proposalAccount {
        proposalInstructionAccounts {
          isWritable
          isSigner
          pubkey
        }
        governance {
          address
          realm
          activeProposalCount
          governedAccount
          proposalCount
          voteThreshold
          maxVotingTime
        }
        governingTokenMint
        state
        tokenOwnerRecord
        signatoriesCount
        signatoriesSignedOffCount
        yesVotesCount
        noVotesCount
        name
        description
        maxVoteWeight
        closedAt
        denyVoteWeight
        vetoVoteWeight
        abstainVoteWeight
      }
      proposalDetails {
        ... on ITransferProposalMetadata {
          offer
          treasuryToken
          destination
          amountToInvest
          mint
          mintDecimals
          mintLogo
          mintSymbol
        }
      }

      proposalDetails {
        ... on IAddSellPermissionProposal {
          sellPermissionData {
            governance {
              address
            }
            from
            to
            quorumMinimum
          }
          maxVotingTime
        }
      }

      proposalDetails {
        ... on IUpdateRoleConfigProposalMetadata {
          roleConfigData {
            role
            currentWeight
            updateWeight
          }
        }
      }

      proposalDetails {
        ... on ICreateFundraiseProposalMetadata {
          capAmount
        }
      }

      proposalDetails {
        ... on IUpdateGovernanceConfigProposalMetadata {
          newQuorum
          governancesToUpdate
          governedAccountsToUpdate
          newVotingTime
          governanceType
        }
      }

      proposalDetails {
        ... on IWithdrawalProposalMetadata {
          withdrawalAmount
          withdrawal
          withdrawalData
          treasuryToken
          withdrawalMembersInfo {
            proposal
            withdrawAmount
            withdrawal
            withdrawalData
            hasUserClaimed
            withdrawalMint
            withdrawalMintDecimals
            withdrawalMintSymbol
          }
          totalFinancialPower
          withdrawalMint
          mintSymbol
          mintDecimals
        }
      }
      proposalMetadata {
        proposalType
        proposalMetadataAddress
        name
        options
        authority
        discussionLink
        treasury
        totalFinancialPower
        proposal
        proposalIndex
        realm
        clubData
        isCreated
        authorityRole
        dataPda
        approvalQuorumPercentage
        maxVoterWeight
        canCancelProposal
        proposalStatus
        userVote
        description
      }
    }
  }
`;

export const GET_NON_FILTERED_MEMBERS_FOR_CLUB = gql`
  query getNonFilteredClubMembers($clubAddress: String!) {
    getNonFilteredClubMembers(clubAddress: $clubAddress) {
      address
      clubData
      memberPubkey
      isMember
      status
      role
      joinedAt
    }
  }
`;

export const GET_HISTORICAL_DEPOSITS = gql`
  query getFinancialLedgerForTreasury(
    $treasuryAddress: String!
    $financialLedgerFilter: FinancialLedgerFilters!
  ) {
    getFinancialLedgerForTreasury(
      treasuryAddress: $treasuryAddress
      financialLedgerFilter: $financialLedgerFilter
    ) {
      financialLedger {
        memberAddress
        treasuryAddress
        executedAt
        action
        fundraiseIndex
        amount
        token {
          tokenAddress
          symbol
          image
          decimals
          name
          mint
          balance
        }
      }
      count
    }
  }
`;

export const GET_OTC_DEALS_FOR_TREASURY = gql`
  query getOtcDealsForTreasury(
    $treasuryAddress: String!
    $otcFilters: OtcDealsFilters!
    $userAddress: String
  ) {
    getOtcDealsForTreasury(
      treasuryAddress: $treasuryAddress
      otcFilters: $otcFilters
      userAddress: $userAddress
    ) {
      otcDeals {
        address
        treasury
        financialRecord
        amountOfRights
        price
        seller
        buyer
        wantedTokenMint
        financialIndex
        initialAmountOfRights
        sellerMemberData {
          address
          clubData
          memberPubkey
          isMember
          status
          role
          joinedAt
          joinedAtSlot
          memberInfo {
            userAddress
            imageUrl
            name
            socialNetworks {
              type
            }
          }
        }
        buyerMemberData {
          address
          clubData
          memberPubkey
          isMember
          status
          role
          joinedAt
          joinedAtSlot
          memberInfo {
            userAddress
            imageUrl
            name
            socialNetworks {
              type
            }
          }
        }
        pricePerPct
        offeredPct
        remainingPct
      }
      count
    }
  }
`;

export const GET_SPL_TOKENS = gql`
  query getSplTokens($tokenName: String!, $chainId: Float!) {
    getSplTokens(tokenName: $tokenName, chainId: $chainId) {
      tokenAddress
      symbol
      image
      decimals
      name
      mint
      balance
    }
  }
`;

export const GET_OTC_LEDGER_FOR_TREASURY = gql`
  query getOtcDealsLedgerPerTreasury($treasuryAddress: String!, $page: Float!) {
    getOtcDealsLedgerPerTreasury(
      treasuryAddress: $treasuryAddress
      page: $page
    ) {
      otcDeals {
        buyer
        seller
        executedAt
        treasuryAddress
        financialOfferAddress
        amount
        token {
          tokenAddress
          symbol
          image
          decimals
          name
          mint
          balance
        }
      }
      count
    }
  }
`;

export const saveMemberInfo = async (
  memberInfo: IMemberInfo,
  signedMessage: string
) => {
  try {
    await apolloClient.query({
      query: gql`
        mutation saveMemberInfo(
          $memberInfo: MemberInfoInput!
          $signedMessage: String!
        ) {
          saveMemberInfo(memberInfo: $memberInfo, signedMessage: $signedMessage)
        }
      `,
      variables: {
        memberInfo,
        signedMessage,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const changeBlockExplorer = async (
  signedMessage: string,
  explorer: DefaultExplorer,
  userAddress: string
) => {
  try {
    await apolloClient.query({
      query: gql`
        mutation changeBlockExplorer(
          $signedMessage: String!
          $explorer: Explorer!
          $userAddress: String!
        ) {
          changeBlockExplorer(
            signedMessage: $signedMessage
            explorer: $explorer
            userAddress: $userAddress
          )
        }
      `,
      variables: {
        signedMessage,
        explorer,
        userAddress,
      },
    });
  } catch (error) {
    throw error;
  }
};
export const GET_MY_CLUB_TREASURIES = gql`
  query getMyClubTreasuries($clubAddress: String!, $userAddress: String!) {
    getMyClubTreasuries(clubAddress: $clubAddress, userAddress: $userAddress) {
      treasuryName
      treasuryRole
      treasuryToken {
        tokenAddress
        symbol
        image
        decimals
        name
        balance
      }
      depositedAmount
    }
  }
`;

export const GET_CANCELLATION_REASONS = gql`
  query getCancellationReasons {
    getCancellationReasons {
      id
      order
      type {
        id
        label
      }
      reason {
        id
        label
      }
    }
  }
`;

export const STORE_CANCELLATION_REASON = gql`
  mutation storeCancellationReason(
    $proposalAddress: String!
    $reasonId: Float!
    $customReason: String
  ) {
    storeCancellationReason(
      proposalAddress: $proposalAddress
      reasonId: $reasonId
      customReason: $customReason
    )
  }
`;

export const GET_USER_NOTIFICATIONS = gql`
  query getUserNotifications($userWallet: String!) {
    getUserNotifications(userWallet: $userWallet) {
      userNotificationId
      tab
      proposalAddress
      hasRead
      notificationType
      message
      clubAddress
      createdAt
      updatedAt
      userPubKey
      treasuryAddress
      clubDataInfo {
        imageUrl
      }
    }
  }
`;

export const TOGGLE_NOTIFICATION = gql`
  mutation toggleHasRead($userWallet: String!, $notificationId: String!) {
    toggleHasRead(userWallet: $userWallet, notificationId: $notificationId)
  }
`;

export const MARK_ALL_NOT_AS_READ = gql`
  mutation markAllAsRead($userWallet: String!) {
    markAllAsRead(userWallet: $userWallet)
  }
`;

export const SAVE_FUNDRAISE_MODEL = gql`
  mutation storeFundraiseModel(
    $treasuryAddress: String!
    $fundraiseModel: fundraiseModel!
  ) {
    storeFundraiseModel(
      treasuryAddress: $treasuryAddress
      fundraiseModel: $fundraiseModel
    )
  }
`;

export const GET_USER_APPLICATION = gql`
  query getUserApplication($memberAddress: String!, $clubAddress: String!) {
    getUserApplication(
      memberAddress: $memberAddress
      clubAddress: $clubAddress
    ) {
      clubAddress
      memberAddress
      applicationStatus
    }
  }
`;
