import React, { FC, useEffect } from "react";
import { clubStore } from "../../../state/clubStore";
import TreasuryMember from "../TreasuryMember/TreasuryMember";
import CardWrapper from "../../../components/CardWrapper/CardWrapper";

const TreasuryMembersList: FC = () => {
  const { treasuryMembers } = clubStore();

  return (
    <CardWrapper backgroundColor="white" padding="2em">
      <ul>
        {treasuryMembers?.map((item, index) => (
          <TreasuryMember member={item} key={index} />
        ))}
      </ul>
    </CardWrapper>
  );
};

export default TreasuryMembersList;
