import { CreateDealStep } from "../../client/configuration";
import { IVertical } from "../../common/interfaces/club.interface";
import { ICreateClubContent } from "../../common/interfaces/form.interface";

export const CREATE_DEAL_STEP_CONTENT = new Map<
  CreateDealStep,
  ICreateClubContent
>([
  [
    CreateDealStep.BasicDealData,

    {
      dealPanelTitle: "Basic Syndicate Information",
      dealPanelContent:
        "Upload your syndicate avatar, enter its name and description, and select your preferred startup verticals to invest in. This will help you match with other investors.",
    },
  ],
  [
    CreateDealStep.DealConfiguration,
    {
      dealPanelTitle: "Customize Your Syndicate",
      dealPanelContent:
        "Define roles, choose the desired fundraising model, and set up optional KYC requirements for joining. You can also decide whether trading shares among members is allowed.",
    },
  ],
  [
    CreateDealStep.DealPreview,
    {
      dealPanelTitle: "You're Almost Done!",
      dealPanelContent:
        "Good job! Double-check your syndicate configuration to ensure it meets your needs, and proceed to creating it.",
    },
  ],
  [
    CreateDealStep.TreasuryConfiguration,
    {
      dealPanelTitle: "Set up Treasury",
      dealPanelContent:
        "Good job! Double-check your syndicate configuration to ensure it meets your needs, and proceed to creating it.",
    },
  ],
  [
    CreateDealStep.TreasuryRoles,
    {
      dealPanelTitle: "Treasury roles",
      dealPanelContent:
        "Good job! Double-check your syndicate configuration to ensure it meets your needs, and proceed to creating it.",
    },
  ],
  [
    CreateDealStep.ClubRoles,
    {
      dealPanelTitle: "Club roles",
      dealPanelContent:
        "Good job! Double-check your syndicate configuration to ensure it meets your needs, and proceed to creating it.",
    },
  ],
]);

export const VERTICALS: IVertical[] = [
  { label: "DeFi", slug: "defi" },
  { label: "Social", slug: "social" },
  { label: "Payments", slug: "payments" },
  { label: "Web3", slug: "web3" },
  { label: "Consumer", slug: "consumer" },
  { label: "DAOs", slug: "daos" },
  { label: "Infrastrucutre & Tools", slug: "infrastructure&tools" },
  { label: "Mobile", slug: "mobile" },
  { label: "Gaming", slug: "gaming" },
  { label: "CeFi", slug: "cefi" },
];
