import { useCallback, useEffect, useState } from "react";
import "./Home.scss";
import Pagination from "../../components/Pagination/Pagination";
import HomeTables from "./HomeTables/HomeTables";
import { FetchingDataState, TabLabel } from "../../common/enums/common.enum";
import HomeHeader from "./HomeHeader/HomeHeader";
import HomeStats from "./HomeStats/HomeStats";
import HomeSummary from "./HomeSummary/HomeSummary";
import TabHeader from "../../components/TabHeader/TabHeader";
import { HOME_TABS } from "../../common/constants/navigation.constants";
import {
  getInitialValuesForFilters,
  subsetArrayForPagination,
} from "../../utilities/helpers";
import {
  EMPTY_STRING,
  ITEMS_PER_PAGE,
} from "../../common/constants/common.constants";
import { useLazyQuery, useSubscription } from "@apollo/client";
import { GET_USER_DEPOSIT_WITHDRAW_STATS } from "../../api/dashboard.api";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import ClubsDropdown from "./ClubsDropdown/ClubsDropdown";
import {
  GET_DASHBOARD_CLUBS,
  GET_DASHBOARD_PROPOSALS,
  GET_DASHBOARD_STAKINGS,
  GET_DASHBOARD_TREASURIES,
  GET_DASHBOARD_WITHDRAWALS,
  GET_MY_MEMBER_INFO,
} from "../../api/dashboard.api";
import { IDashboardClub } from "../../common/interfaces/dashboard.interface";
import { PROPOSAL_SUBSCRIPTION } from "../../api/club-subscriptions.api";
import useTransactionStore from "../../state/transactionStore";
import { IBasicFilters } from "../../common/interfaces/common.interface";
import { useDashboardStore } from "../../state/dashboardStore";
import KYCBanner from "./KYCBanner/KYCBanner";

export interface IDashboardStats {
  totalDeposit: number;
  totalWithdrawn: number;
  projects: number;
  invitations: number;
}

const Home = () => {
  const [activeTab, setActiveTab] = useState(TabLabel.Treasuries);
  const [currentStats, setCurrentStats] = useState<IDashboardStats>();
  const [count, setCount] = useState(0);
  const [selectedClub, setSelectedClub] = useState<IDashboardClub>();
  const [dropdown, toggleDropdown] = useState(false);
  const [filters, setFilters] = useState<IBasicFilters>({
    ...getInitialValuesForFilters(),
    filter: TabLabel.Members,
  });

  const wallet = useAnchorWallet();
  const {
    setDashboardClubs,
    setDashboardProposals,
    setDashboardStakings,
    setDashboardWithdrawals,
    setDashboardTreasuries,
    dashboardClubs,
    dashboardProposals,
    dashboardStaking,
    dashboardTreasuries,
    dashboardWithdrawals,
    pages,
    setPages,
    resetStore,
  } = useDashboardStore();
  const { updateFetchingData } = useTransactionStore();

  const [getMemberInfo, memberInfo] = useLazyQuery(GET_MY_MEMBER_INFO, {});

  const [getProposals] = useLazyQuery(GET_DASHBOARD_PROPOSALS, {
    onCompleted(data) {
      setDashboardProposals(data.getDashboardProposals.proposals);
      setCount(data.getDashboardProposals.count);
    },
  });

  const [getTreasuries] = useLazyQuery(GET_DASHBOARD_TREASURIES, {
    onCompleted(data) {
      setDashboardTreasuries(data.getDashboardTreasuries.treasuries);
      setCount(data.getDashboardTreasuries.count);
    },
  });

  const [getWithdrawals] = useLazyQuery(GET_DASHBOARD_WITHDRAWALS, {
    onCompleted(data) {
      setDashboardWithdrawals(data.getDashboardWithdrawals.withdrawals);
      setCount(data.getDashboardWithdrawals.count);
    },
  });

  const [getStakings] = useLazyQuery(GET_DASHBOARD_STAKINGS, {
    onCompleted(data) {
      setDashboardStakings(data.getDashboardStakings.stakings);
      setCount(data.getDashboardStakings.count);
    },
  });

  const [getDepositWithdrawStats] = useLazyQuery(
    GET_USER_DEPOSIT_WITHDRAW_STATS,
    {
      onCompleted(data) {
        setCurrentStats(data.getAllDepositsAndWithdrawals);
      },
    }
  );

  const [getClubs] = useLazyQuery(GET_DASHBOARD_CLUBS, {
    onCompleted(data) {
      setDashboardClubs(data.getMemberDashboardClubs);
    },
  });

  useSubscription(PROPOSAL_SUBSCRIPTION, {
    onData: async ({ data }) => {
      const storedProposals = [...dashboardProposals];
      const proposalIndex = dashboardProposals.findIndex(
        (p) =>
          p.proposal.proposalMetadata.proposalMetadataAddress ===
          data.data.proposalUpdated.proposalMetadata.proposalMetadataAddress
      );
      storedProposals[proposalIndex] = {
        ...storedProposals[proposalIndex],
        proposal: { ...data.data.proposalUpdated },
      };
      setDashboardProposals([...storedProposals]);
      updateFetchingData(FetchingDataState.Succeeded);
    },
    variables: {
      treasuryAddress: EMPTY_STRING,
      clubAddress: selectedClub?.clubAddress,
    },
  });

  useEffect(() => {
    setSelectedClub(dashboardClubs[0]);
  }, [dashboardClubs]);

  useEffect(() => {
    getDashboardData();
  }, [selectedClub, activeTab, filters]);

  useEffect(() => {
    if (wallet) {
      getMemberInfo({
        variables: {
          address: wallet?.publicKey.toString(),
        },
      });
      getClubs({
        variables: {
          memberAddress: wallet?.publicKey.toString(),
        },
      });
      getDepositWithdrawStats({
        variables: {
          memberAddress: wallet?.publicKey.toString(),
        },
      });
    } else {
      resetStore();
      setCurrentStats(undefined);
    }
  }, [wallet]);

  const handleCardChange = (club: IDashboardClub) => {
    toggleDropdown(false);
    setSelectedClub(club);
    setActiveTab(TabLabel.Treasuries);
  };

  const getDashboardData = useCallback(async () => {
    switch (activeTab) {
      case TabLabel.Staking: {
        await getStakings({
          variables: {
            userAddress: wallet?.publicKey.toString(),
            clubAddress: selectedClub?.clubAddress,
            page: filters.page ? filters.page + 1 : 1,
          },
        });
        break;
      }
      case TabLabel.Treasuries: {
        await getTreasuries({
          variables: {
            clubAddress: selectedClub?.clubAddress,
            memberAddress: wallet?.publicKey.toString(),
            page: filters.page ? filters.page + 1 : 1,
          },
        });
        break;
      }
      case TabLabel.Proposals: {
        await getProposals({
          variables: {
            userAddress: wallet?.publicKey.toString(),
            clubAddress: selectedClub?.clubAddress,
            page: filters.page ? filters.page + 1 : 1,
          },
        });
        break;
      }
      case TabLabel.Withdrawals: {
        await getWithdrawals({
          variables: {
            userAddress: wallet?.publicKey.toString(),
            clubAddress: selectedClub?.clubAddress,
            page: filters.page ? filters.page + 1 : 1,
          },
        });
        break;
      }
      default: {
        break;
      }
    }
  }, [activeTab, filters, selectedClub, wallet]);

  return (
    <div className="home">
      <div className="home__content">
        <HomeHeader memberInfo={memberInfo.data?.getMyMemberInfo} />
        <HomeStats
          array={
            currentStats
              ? [
                  {
                    currency: "$USD",
                    title: "Total deposits",
                    value: currentStats?.totalDeposit,
                  },
                  {
                    currency: "$USD",
                    title: "Total withdrawn",
                    value: currentStats?.totalWithdrawn,
                  },
                  {
                    currency: "",
                    title: "Projects",
                    value: currentStats?.projects,
                  },
                  {
                    currency: "",
                    title: "Invitations",
                    value: currentStats?.invitations,
                  },
                ]
              : []
          }
        />
        {/* No civic intergration yet, but component will be useful later */}
        {/* <KYCBanner /> */}
        <HomeSummary
          refetchStats={() => getDepositWithdrawStats()}
          clubs={dashboardClubs}
        />
        <h3>Clubs</h3>
        <ClubsDropdown
          selectedClub={selectedClub}
          dropdown={dropdown}
          handleCardChange={(e) => {
            handleCardChange(e);
          }}
          toggleDropdown={(e) => toggleDropdown(e)}
          clubs={dashboardClubs}
        />
        <div className="home-tables">
          <TabHeader
            padding="0 2em"
            tabs={HOME_TABS}
            onClick={(tab: TabLabel) => {
              setActiveTab(tab);
              setPages(0);
              setFilters({ ...filters, page: 0 });
            }}
            activeTab={activeTab}
          />
          <Pagination
            totalPages={Math.ceil(count / ITEMS_PER_PAGE)}
            handlePageChange={({ selected }) => {
              setFilters({ ...filters, page: selected });
            }}
            currentPage={filters.page ?? 0}
          >
            <HomeTables
              activeTab={activeTab}
              treasuries={subsetArrayForPagination(
                dashboardTreasuries,
                pages,
                ITEMS_PER_PAGE
              )}
              proposals={subsetArrayForPagination(
                dashboardProposals,
                pages,
                ITEMS_PER_PAGE
              )}
              withdrawals={subsetArrayForPagination(
                dashboardWithdrawals,
                pages,
                ITEMS_PER_PAGE
              )}
              staking={subsetArrayForPagination(
                dashboardStaking,
                pages,
                ITEMS_PER_PAGE
              )}
            />
          </Pagination>
        </div>
      </div>
    </div>
  );
};

export default Home;
