import { IFundraiseConfig } from "../../../common/interfaces/club.interface";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { clubStore } from "../../../state/clubStore";
import { getAmountWithDecimalsForCurrency } from "../../../utilities/helpers";
import FundraiseActions from "../FundraiseActions/FundraiseActions";
import "./FundraiseProgress.scss";
import React, { FC } from "react";
import TokenUnlockingDate from "../../../components/TokenUnlockingDate/TokenUnlockingDate";

const FundraiseProgress: FC<{ fundraise: IFundraiseConfig }> = ({
  fundraise,
}) => {
  const { activeTreasury, memberTreasuryInfo } = clubStore();
  const arrayDeposit = memberTreasuryInfo?.depositRecords;
  return (
    <div className="fundraise-progress">
      <div className="fundraise-progress__raised">
        Totaly raised
        <label className="fundraise-progress__raised-amount">
          {getAmountWithDecimalsForCurrency(
            activeTreasury?.currencyDecimals,
            fundraise.raisedAmount
          )}
          <span className="fundraise-progress__raised-currency">
            {" "}
            {activeTreasury?.currencySymbol}
          </span>
        </label>
      </div>
      <div className="fundraise-progress__cap">
        Goal{" "}
        <label className="fundraise-progress__cap-amount">
          {getAmountWithDecimalsForCurrency(
            activeTreasury?.currencyDecimals,
            fundraise?.capAmount
          )}
          <span className="fundraise-progress__cap-currency">
            {activeTreasury?.currencySymbol}
          </span>
        </label>
      </div>
      <ProgressBar
        numerator={fundraise.raisedAmount}
        denominator={fundraise.capAmount}
        active={true}
      />
      <div className="fundraise-progress__deposit">
        You deposited{" "}
        <label className="fundraise-progress__deposit-amount">
          {arrayDeposit &&
          arrayDeposit[memberTreasuryInfo?.depositRecords.length - 1]
            ?.fundraiseIndex === activeTreasury?.fundraiseCount
            ? getAmountWithDecimalsForCurrency(
                activeTreasury?.currencyDecimals,
                arrayDeposit[arrayDeposit.length - 1]?.currentFundraiseDeposit
              )
            : 0}
          <span className="fundraise-progress__deposit-currency">
            {activeTreasury?.currencySymbol}
          </span>
        </label>
      </div>
      <TokenUnlockingDate />
      <div className="fundraise-progress__actions">
        <FundraiseActions />
      </div>
    </div>
  );
};

export default FundraiseProgress;
