import React, { FC } from "react";
import "./PreviewSectionHeader.scss";

const PreviewSectionHeader: FC<{
  editInProgress?: boolean;
  onEdit: () => void;
  label: string;
}> = ({ editInProgress, onEdit, label }) => {
  return (
    <div className="preview-section-header">
      <p>{label}</p>
      <button type="button" onClick={onEdit}>
        {editInProgress ? "Save changes" : "Edit"}
      </button>
    </div>
  );
};

export default PreviewSectionHeader;
