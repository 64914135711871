import { useLazyQuery } from "@apollo/client";
import "./ApplicationAndMemberDetailsModal.scss";
import {
  GET_ALL_TREASURIES_FOR_CLUB,
  GET_MY_CLUB_TREASURIES,
} from "../../../api/club.api";
import { FC, useEffect, useState } from "react";
import {
  IMemberTreasuries,
  ITreasuryData,
} from "../../../common/interfaces/club.interface";
import {
  checkIfUserHasClubPermissionForAction,
  getAmountWithDecimalsForCurrency,
} from "../../../utilities/helpers";
import NoData from "../../../components/NoData/NoData";
import MainButton from "../../../components/MainButton/MainButton";
import { createNotification } from "../../../utilities/notifications";
import { MESSAGE_TYPE } from "../../../common/constants/common.constants";
import { clubStore } from "../../../state/clubStore";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { removeMember } from "../../../program/methods/clubs";
import { PublicKey } from "@metaplex-foundation/js";
import { ClubAction } from "../../../common/enums/clubs.enum";

const ClubMemberTreasuries: FC<{
  clubAddress: string | undefined;
  userAddress: string;
}> = ({ clubAddress, userAddress }) => {
  const [treasuries, setTreasuries] = useState<IMemberTreasuries[]>();
  const { clubBasicInfo, memberData } = clubStore();
  const wallet = useAnchorWallet();

  useEffect(() => {
    getMyClubTreasuries();
  }, [clubAddress, userAddress]);

  const [getMyClubTreasuries] = useLazyQuery(GET_MY_CLUB_TREASURIES, {
    onCompleted: (data) => {
      setTreasuries(data.getMyClubTreasuries);
    },
    variables: {
      clubAddress: clubAddress,
      userAddress: userAddress,
    },
  });

  const [getAllTreasuriesForClub, getAllTreasuriesForClubInfo] = useLazyQuery(
    GET_ALL_TREASURIES_FOR_CLUB
  );

  const removeMemberHandler = async () => {
    try {
      if (!clubBasicInfo || !memberData || !wallet) {
        createNotification(MESSAGE_TYPE.ERROR, "Missing data");
        return;
      }

      const allTreasuries = await getAllTreasuriesForClub({
        variables: {
          clubAddress,
        },
      });

      await removeMember(
        memberData.role,
        memberData.status,
        new PublicKey(clubBasicInfo.address),
        clubBasicInfo.name,
        new PublicKey(userAddress),
        new PublicKey(memberData.address),
        wallet,
        allTreasuries.data.getTreasuriesForClub.map(
          (item: ITreasuryData) => new PublicKey(item.treasuryDataAddress)
        )
      );

      createNotification(MESSAGE_TYPE.SUCCESS, "Member sucessfully removed");
    } catch (error) {
      console.log(error);
      createNotification(MESSAGE_TYPE.ERROR, "Failed to remove member");
    }
  };

  return (
    <div className="member-application__roles">
      <p className="member-application__header">Treasuries</p>
      {treasuries && (
        <div className="member-application__treasuries">
          {treasuries.length > 0 ? (
            <>
              {treasuries.map((treasury, index) => (
                <div className="member-application__treasury-info" key={index}>
                  <p className="member-application__treasury-info__treasury-name">
                    {treasury.treasuryName}
                  </p>
                  <div className="member-application__treasury-data">
                    <div className="member-application__treasury-column">
                      <p>Treasury role</p>
                      <p className="member-application__treasury-column__bold">
                        {treasury.treasuryRole}
                      </p>
                    </div>
                    <div className="member-application__treasury-column">
                      <p>Deposited</p>
                      <p className="member-application__treasury-column__bold">
                        {getAmountWithDecimalsForCurrency(
                          treasury.treasuryToken.decimals,
                          treasury.depositedAmount
                        )}{" "}
                        <span>{treasury.treasuryToken.symbol}</span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            //TODO: Check with fiki, this is a temporary solution
            <>
              <NoData message="This member has not joined any treasuries yet" />
              <MainButton
                onClick={removeMemberHandler}
                type="button"
                color="var(--red-500)"
                borderColor="var(--red-500)"
                light
                disabled={
                  !checkIfUserHasClubPermissionForAction(
                    memberData,
                    clubBasicInfo?.roleConfig,
                    ClubAction.UpdateMember
                  )
                }
              >
                Remove member
              </MainButton>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ClubMemberTreasuries;
