import React, { FC } from "react";
import "./HoverIcon.scss";

const HoverIcon: FC<{
  src: string;
  alt: string;
  onClick: (e?: any) => void;
}> = ({ src, alt, onClick }) => {
  return (
    <div className="hover-icon" onClick={onClick}>
      <img src={src} alt={alt} />
    </div>
  );
};

export default HoverIcon;
