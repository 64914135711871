import React from "react";
import "./InviteBar.scss";
import flagIcon from "../../../assets/flag.svg";
import MainButton from "../../../components/MainButton/MainButton";
import { clubStore } from "../../../state/clubStore";
import {
  acceptInvitation,
  rejectInvitation,
} from "../../../program/methods/clubs";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
//Note: uncomment after CIVIC implementation
// import { useGateway } from "@civic/solana-gateway-react";
import { apolloClient } from "../../../api/config.api";
import { sleep } from "../../../program/sendTransactions";
import { GET_MEMBER_DATA } from "../../../api/club.api";
import { findNftTokenAccountAndMetadataFromCollection } from "../../../utilities/helpers";
import { PublicKey } from "@metaplex-foundation/js";
import { DealType } from "../../../common/enums/deals.enum";

const InviteBar = () => {
  const { clubBasicInfo, memberData, setMemberData } = clubStore();

  const wallet = useAnchorWallet();
  //Note: uncomment after CIVIC implementation
  // const { gatewayToken } = useGateway();

  const handleRejectInvitation = async () => {
    try {
      if (!wallet || !clubBasicInfo || !memberData) return;
      await rejectInvitation(
        wallet,
        clubBasicInfo?.address,
        memberData?.memberPubkey,
        clubBasicInfo?.authority
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleJoinClub = async () => {
    try {
      if (!wallet || !clubBasicInfo || !memberData) return;

      await acceptInvitation(
        clubBasicInfo.address,
        memberData.address,
        wallet,
        clubBasicInfo.name,
        clubBasicInfo.clubType,
        //Note: uncomment after CIVIC implementation
        // clubBasicInfo.kycConfig ? gatewayToken : undefined,
        clubBasicInfo.clubType === DealType.NftBased
          ? await findNftTokenAccountAndMetadataFromCollection(
              wallet.publicKey,
              new PublicKey(clubBasicInfo.communityMint)
            )
          : undefined
      );
      sleep(1000);
      await apolloClient.refetchQueries({
        include: [GET_MEMBER_DATA],
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="invite-bar">
      <div className="invite-bar__flex">
        <img src={flagIcon} alt="Flag" />
        <p>
          You have a pending invitation for this club, accept to join and get
          more benefits
        </p>
      </div>
      <div className="invite-bar__flex">
        <button
          className="invite-bar__reject-button"
          onClick={handleRejectInvitation}
        >
          Reject
        </button>
        <MainButton
          onClick={handleJoinClub}
          light
          type="button"
          padding="0.5em 0.75em"
        >
          Join this Club
        </MainButton>
      </div>
    </div>
  );
};

export default InviteBar;
