import React from "react";
import "./ConfigurationChangesProposal.scss";
import MultiToggle from "../../MultiToggle/MultiToggle";
import { useFormikContext } from "formik";
import { IProposalFormFields } from "../../../common/interfaces/form.interface";
import { ChangeConfigurationTypeEnum } from "../../../common/enums/proposal.enum";
import proposalFormConfig from "../proposalFormConfig";
import ChangeGovernanceType from "./ChangeGovernanceType/ChangeGovernanceType";
import VotingPower from "../VotingPower/VotingPower";

const ConfigurationChangesProposal = () => {
  const { values, errors, setFieldValue } =
    useFormikContext<IProposalFormFields>();
  const formFields = proposalFormConfig.formFields;

  return (
    <div className="configuration-changes-proposal">
      <div>
        <p>What do you want to change</p>
        <div className="configuration-changes-proposal__configuration-type">
          <MultiToggle
            first
            label="Voting power"
            isSelected={
              values.changeConfigurationType ===
              ChangeConfigurationTypeEnum.VotingPower
            }
            onClick={() =>
              setFieldValue(
                formFields.changeConfigurationType.name,
                ChangeConfigurationTypeEnum.VotingPower
              )
            }
          />
          <MultiToggle
            last
            label="Vote percentage"
            isSelected={
              values.changeConfigurationType ===
              ChangeConfigurationTypeEnum.VotePercentage
            }
            onClick={() =>
              setFieldValue(
                formFields.changeConfigurationType.name,
                ChangeConfigurationTypeEnum.VotePercentage
              )
            }
          />
        </div>
      </div>
      {values.changeConfigurationType ===
        ChangeConfigurationTypeEnum.VotePercentage && <ChangeGovernanceType />}
      {values.changeConfigurationType ===
        ChangeConfigurationTypeEnum.VotingPower && (
        <>
          <VotingPower />
        </>
      )}
    </div>
  );
};

export default ConfigurationChangesProposal;
