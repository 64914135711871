import React, { useCallback, useMemo, useState } from "react";
import { defaultExplorerOptions } from "../../common/constants/common.constants";
import SelectField from "../SelectField/SelectField";
import { DefaultExplorer } from "../../common/enums/clubs.enum";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import { createNotification } from "../../utilities/notifications";
import { MessageType } from "graphql-ws";
import { apolloClient } from "../../api/config.api";
import { GET_MY_MEMBER_INFO } from "../../api/dashboard.api";
import { changeBlockExplorer } from "../../api/club.api";
import { getSignedMessage } from "../../utilities/helpers";
import "./DefaultExplorerMenu.scss";
import { MaterialUIMenuItem } from "../../common/constants/mui.constants";

const DefaultExplorerMenu: React.FC<{ defaultExplorer: DefaultExplorer }> = ({
  defaultExplorer,
}) => {
  const { signMessage } = useWallet();
  const wallet = useAnchorWallet();

  const handleChangeExplorer = async (explorer: string) => {
    try {
      if (!wallet) {
        createNotification(MessageType.Error, "Missing data");
        return;
      }

      if (signMessage) {
        const signature = await getSignedMessage("s", signMessage);
        /*  Real message will be added later */

        await changeBlockExplorer(
          signature,
          explorer as DefaultExplorer,
          wallet?.publicKey.toString()
        );
        apolloClient.refetchQueries({ include: [GET_MY_MEMBER_INFO] });
      }
    } catch (error) {
      createNotification(
        MessageType.Error,
        "Failed to change your default explorer"
      );
    }
  };

  return (
    <div className="default-explorer">
      <SelectField
        activeValue={
          defaultExplorer ? defaultExplorer : DefaultExplorer.SolanaExpolorer
        }
        onChange={(value: string) => {
          handleChangeExplorer(value);
        }}
      >
        {defaultExplorerOptions.map((option) => (
          <MaterialUIMenuItem value={option.value}>
            <div className="default-explorer__item">
              <img src={option.img} className="default-explorer__item-logo" />
              <label className="default-explorer__item-name">
                {" "}
                {option.label}
              </label>
            </div>
          </MaterialUIMenuItem>
        ))}
      </SelectField>
    </div>
  );
};

export default DefaultExplorerMenu;
