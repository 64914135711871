import React, { useEffect, useState } from "react";
import {
  MaterialUIAutocomplete,
  MaterialUIMenuItem,
  MaterialUITextField,
} from "../../common/constants/mui.constants";
import { ITreasuryTokenAccInfo } from "../../common/interfaces/club.interface";
import {
  DEVNET_ID,
  EMPTY_STRING,
} from "../../common/constants/common.constants";
import { useLazyQuery } from "@apollo/client";
import { GET_SPL_TOKENS } from "../../api/club.api";
import { useFormikContext } from "formik";
import {
  IBasicClubDataForm,
  ITreasuryFormFields,
} from "../../common/interfaces/form.interface";
import treasuryFormModel from "../CreateTreasuryModal/treasuryFormModel";
import "./AsyncTokenSearchField.scss";

const AsyncTokenSearchField = () => {
  const [value, setValue] = useState<ITreasuryTokenAccInfo>();
  const [options, setOptions] = useState<ITreasuryTokenAccInfo[]>([]);

  const [inputValue, setInputValue] = useState(EMPTY_STRING);
  const [searchValue, setSearchValue] = useState(EMPTY_STRING);

  const { setFieldValue, errors } = useFormikContext<
    ITreasuryFormFields | IBasicClubDataForm
  >();

  const [getSplTokens, getSplTokensInfo] = useLazyQuery(GET_SPL_TOKENS, {
    onCompleted: (data) => {
      console.log(data, "SPL TOKENS DATA");
      setOptions(data?.getSplTokens);
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => setSearchValue(inputValue), 1000);
    return () => clearTimeout(timer);
  }, [inputValue]);

  useEffect(() => {
    void getOptions();
  }, [searchValue]);

  const getOptions = async () => {
    try {
      getSplTokens({
        variables: {
          tokenName: searchValue,
          chainId: DEVNET_ID,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="async-token-search">
      <MaterialUIAutocomplete
        id="async-autocomplete"
        getOptionLabel={(option: any) => option.name}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText="No currency"
        onChange={(event: any, newValue: any) => {
          const value = newValue as ITreasuryTokenAccInfo;
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);

          setFieldValue(
            treasuryFormModel.formFields.denominatedCurrency.name,
            value ? value.mint : EMPTY_STRING
          );
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => {
          return (
            <MaterialUITextField
              {...params}
              id="standard-textarea"
              label={"Denominated currency"}
              multiline
              variant="filled"
            />
          );
        }}
        renderOption={(props, option: any) => {
          return (
            <li {...props}>
              <MaterialUIMenuItem value={option.name} key={option.name}>
                {option.name}
              </MaterialUIMenuItem>
            </li>
          );
        }}
      />
      {errors.denominatedCurrency && (
        <p className="input-field__error">{errors.denominatedCurrency}</p>
      )}
    </div>
  );
};

export default AsyncTokenSearchField;
