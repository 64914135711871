export const HOME = "/";
export const BROWSE_DEALS = "/deals";
export const CREATE_A_DEAL = "/create-a-deal";
export const OVERVIEW = "/deal";
export const INTRO_DECK = "/intro-deck";
export const PROPOSALS = "/proposals";
export const TREASURY = "/treasury";
export const VAULT = "/vault";
export const MEMBERS = "/members";
export const OTC_DEAL = "/otc-deal";
export const STAKING = "/staking";
export const NOT_FOUND = "*";
