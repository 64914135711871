import { EMPTY_STRING } from "../../../common/constants/common.constants";
import { IManageTreasuryRoles } from "../../../common/interfaces/form.interface";

export const validateManageTreasuryRoles = (values: IManageTreasuryRoles) => {
  const errors: any = {};

  if (values.members.length <= 0) {
    errors.members = "Members length can not be zero";
  }
  values.members?.forEach((item, index) => {
    item.wallet?.forEach((wallet) => {
      if (
        values.members.find(
          (member, memberIndex) =>
            memberIndex !== index &&
            member.wallet.find((memberWallet) => memberWallet === wallet)
        )
      ) {
        errors[`members.${index}.wallet`] =
          "This member is already in this allocation";
      }
    });

    if (item.wallet.length < 1) {
      errors[`members.${index}.wallet`] = "Wallet can not be empty";
    }

    if (item.role === EMPTY_STRING) {
      errors[`members.${index}.role`] = "Members roles can not be empty";
    }
  });
  return errors;
};
