import React, { FC, useMemo } from "react";
import "./FundraiseForm.scss";
import MainButton from "../../MainButton/MainButton";
import fundraiseFormConfig from "../fundraiseFormConfig";
import InputField from "../../InputFields/InputField";
import Chip from "../../Chip/Chip";
import { YELLOW100 } from "../../../common/constants/layout.constants";
import placeholderImage from "../../../assets/Profilepicture.png";
import errorIcon from "../../../assets/error.svg";
import CurrencyChip from "../../CurrencyChip/CurrencyChip";
import { useFormContext } from "react-hook-form";
import { IFundraiseFormFields } from "../../../common/interfaces/form.interface";
import { useFormikContext } from "formik";
import createFundraiseFormConfig from "../../CreateFundraiseFormModal/createFundraiseFormConfig";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import { clubStore } from "../../../state/clubStore";
import clubAvatar from "../../../assets/unique_avatar.png";
import {
  findNearestDateForTokenUnlocking,
  formatDateWithoutTime,
} from "../../../utilities/helpers";
import FeeCard from "../../FeeCard/FeeCard";
import { CLIENT_CONFIGURATION } from "../../../client/configuration";

const FundraiseForm: FC<{
  deposit?: boolean;
  maxAmount?: number | undefined;
  mgmtFee?: number;
}> = ({ deposit, maxAmount, mgmtFee }) => {
  const { values, setFieldValue } = useFormikContext<IFundraiseFormFields>();
  const { activeTreasury, clubBasicInfo, fundraises } = clubStore();

  const maxDepositHandler = () => {
    setFieldValue(
      createFundraiseFormConfig.formFields.amount.name,
      maxAmount ?? EMPTY_STRING
    );
  };

  const nearestDate = useMemo(() => {
    return fundraises && findNearestDateForTokenUnlocking(fundraises);
  }, [fundraises]);

  const managementFeeAmount = useMemo(() => {
    return mgmtFee && (Number(values.amount) * mgmtFee) / 100;
  }, [values.amount, mgmtFee]);

  return (
    <div className="fundraise-form">
      <div className="fundraise-form__info">
        <img
          src={
            clubBasicInfo?.imgUrl && clubBasicInfo?.imgUrl !== EMPTY_STRING
              ? clubBasicInfo?.imgUrl
              : clubAvatar
          }
          alt="Deal"
        />
        <h2>{clubBasicInfo?.name}</h2>
        <p>Fundraise #{activeTreasury?.fundraiseCount}</p>
      </div>
      <div className="fundraise-form__fields">
        <div className="fundraise-form__fields-chips">
          <CurrencyChip currency={activeTreasury?.currencySymbol ?? "SOL"} />
          {maxAmount !== undefined && (
            <MainButton
              type="button"
              onClick={maxDepositHandler}
              padding="0.25em 0.75em"
              light
            >
              Max
            </MainButton>
          )}
        </div>
        <InputField
          name={fundraiseFormConfig.formFields.amount.name}
          type="text"
          labelTitle={fundraiseFormConfig.formFields.amount.label}
          placeholder={fundraiseFormConfig.formFields.amount.label}
        />
      </div>
      {deposit && nearestDate && (
        <div className="fundraise-form__results">
          <div className="fundraise-form__results-box">
            <div>
              <h4>You will get</h4>
              <p>{nearestDate?.tokenAmount}</p>
            </div>
            <div>
              <h4>{activeTreasury?.currencySymbol}</h4>
            </div>
          </div>
          <div className="fundraise-form__results-box--small">
            <h4>Unlock date</h4>
            <p>{formatDateWithoutTime(nearestDate?.date)}</p>
          </div>
        </div>
      )}
      {mgmtFee && (
        <FeeCard>
          <p>
            Management fee is <b>{mgmtFee}%</b> (
            {values.amount ? managementFeeAmount : 0}{" "}
            {activeTreasury?.currencySymbol}) on top of your Deposit amount.
          </p>
        </FeeCard>
      )}
      <div className="fundraise-form__chip">
        <Chip
          text={`${deposit ? "Deposit" : "Withdraw"} Fee ≈ 0.0215 SOL`}
          backgroundColor={YELLOW100}
          stretch
          fontSize="0.8"
          padding="14px"
          icon
          image={errorIcon}
        />
      </div>
    </div>
  );
};

export default FundraiseForm;
