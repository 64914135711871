import { useCallback, useEffect, useState } from "react";
import "./BrowseDeals.scss";
import BrowseDealsCard from "./BrowseDealsCard/BrowseDealsCard";
import NoData from "../../components/NoData/NoData";
import TabHeader from "../../components/TabHeader/TabHeader";
import { Sort, TabLabel } from "../../common/enums/common.enum";
import { DEALS_TABS } from "../../common/constants/navigation.constants";
import { GET_BROWSE_CLUBS_DATA, getBrowseClubsInfo } from "../../api/club.api";
import { IBasicClubData } from "../../common/interfaces/club.interface";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router";
import { CREATE_A_DEAL } from "../../common/constants/routes.constants";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import { IBasicFilters } from "../../common/interfaces/common.interface";
import { useLazyQuery } from "@apollo/client";
import { sortDealsOptions } from "../../common/constants/sort.constants";
import SortDropdown from "../../components/SortDropdown/SortDropdown";
import SearchBar from "../../components/SearchBar/SearchBar";

const BrowseDeals = () => {
  const [tabOptions, setTabOptions] = useState([TabLabel.AllDeals]);
  const [filters, setFilters] = useState<IBasicFilters>({
    search: EMPTY_STRING,
    sort: Sort.DateAddedAsc,
    filter: TabLabel.AllDeals,
  });

  const [getClubs, clubsInfo] = useLazyQuery(GET_BROWSE_CLUBS_DATA);

  const wallet = useAnchorWallet();

  const navigate = useNavigate();

  useEffect(() => {
    void getAllClubs();
  }, [filters, wallet?.publicKey]);

  useEffect(() => {
    if (wallet?.publicKey) {
      setTabOptions(DEALS_TABS);
    } else setTabOptions([TabLabel.AllDeals]);
  }, [wallet?.publicKey]);

  const getAllClubs = async () => {
    try {
      getClubs({
        variables: {
          browseClubFilters: {
            search: filters.search,
            sort: filters.sort,
          },
          memberAddress:
            filters.filter === TabLabel.MyDeals
              ? wallet?.publicKey.toString()
              : EMPTY_STRING,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const navigateToCreateClub = useCallback(() => {
    navigate(CREATE_A_DEAL);
  }, []);

  return (
    <div className="browse-deals">
      <div className="browse-deals__wrapper">
        <TabHeader
          tabs={tabOptions}
          onClick={(tab: TabLabel) => {
            setFilters({
              ...filters,
              filter: tab,
            });
          }}
          activeTab={filters.filter ?? TabLabel.AllDeals}
        >
          <div className="browse-deals__search-sort">
            <SearchBar
              setSearchValue={(value) => {
                setFilters({
                  ...filters,
                  search: value,
                });
              }}
            />
            <SortDropdown
              sortOptions={sortDealsOptions}
              sortDeals={(sort) => {
                setFilters({
                  ...filters,
                  sort: sort,
                });
              }}
            />
          </div>
        </TabHeader>
        <div className="browse-deals__content">
          {clubsInfo.data?.getClubData &&
          clubsInfo.data?.getClubData.length < 1 ? (
            <NoData
              message={"You have no active deals"}
              mainButtonText={"Create a Deal"}
              height="70vh"
              noIcon
              mainButtonOnClick={navigateToCreateClub}
            />
          ) : (
            clubsInfo.data?.getClubData?.map(
              (deal: IBasicClubData, index: number) => (
                <BrowseDealsCard deal={deal} key={index} />
              )
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default BrowseDeals;
