import React, { useEffect, useMemo, useState } from "react";
import "./Navbar.scss";
import logo from "../../assets/logo.png";
import notificationsIcon from "../../assets/notifications.png";
import { BROWSE_DEALS, HOME } from "../../common/constants/routes.constants";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { NAVBAR_ITEMS } from "../../common/constants/navigation.constants";
import { useLocation } from "react-router";
import {
  EMPTY_STRING,
  MESSAGE_TYPE,
} from "../../common/constants/common.constants";
import { Link } from "react-router-dom";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import { getTrimmedPublicKey } from "../../utilities/helpers";
import WalletDropdown from "../WalletDropdown/WalletDropdown";
import walletIcon from "../../assets/account_balance_wallet.svg";
import NotificationModal from "../NotificationModal/NotificationModal";
import {
  GET_TREASURY_MEMBERS,
  GET_USER_NOTIFICATIONS,
} from "../../api/club.api";
import { useLazyQuery, useSubscription } from "@apollo/client";
import { userStore } from "../../state/userStore";
import { NEW_NOTIFICATION_SUBSCRIPTION } from "../../api/club-subscriptions.api";
import { INotification } from "../../common/interfaces/common.interface";
import { CLIENT_CONFIGURATION } from "../../client/configuration";

const Navbar = () => {
  const [showSettings, setShowSettings] = useState(false);
  const [isNotificationModalOpen, toggleNotificationModal] = useState(false);
  const location = useLocation();
  const { visible, setVisible } = useWalletModal();
  const { setNotifications, updateNotification, notifications } = userStore();

  const userWallet = useAnchorWallet();

  const [getUserNotifications, getUserNotificationsInfo] = useLazyQuery(
    GET_USER_NOTIFICATIONS,
    {
      onCompleted: (data) => {
        const notifications: INotification[] = data.getUserNotifications.map(
          (item: any) => {
            return {
              ...item,
              imageUrl: item.clubDataInfo?.imageUrl,
            };
          }
        );
        setNotifications(notifications);
      },
    }
  );

  useSubscription(NEW_NOTIFICATION_SUBSCRIPTION, {
    onData: ({ data }) => {
      console.log("NEW NOTIFICATION SUB", data);
      data.data.newNotification.forEach((notificaiton: any) => {
        updateNotification({
          ...notificaiton,
          imageUrl: notificaiton.clubDataInfo?.imageUrl,
        });
      });
    },
    variables: {
      userAddress: userWallet?.publicKey.toString(),
    },
  });

  useEffect(() => {
    getUserNotifications({
      variables: {
        userWallet: userWallet?.publicKey,
      },
    });
  }, [userWallet?.publicKey]);

  const unreadNotificationsLen = useMemo(() => {
    return notifications.filter((item) => !item.hasRead).length;
  }, [notifications]);

  const navbarItems = useMemo(() => {
    if (CLIENT_CONFIGURATION.hideBrowseClubs) {
      return NAVBAR_ITEMS.filter((item) => item.route !== BROWSE_DEALS);
    }
    return NAVBAR_ITEMS;
  }, []);

  return (
    <>
      <div className="navbar">
        <Link to={HOME}>
          <img src={logo} className="navbar__logo" alt="Unique logo" />
        </Link>
        <div className="navbar__items">
          <ul className="navbar__items-list">
            {navbarItems.map((item, index) => (
              <Link
                to={item.route}
                key={index}
                className={
                  location.pathname === item.route ? "active" : EMPTY_STRING
                }
              >
                <li className="navbar__items-label">{item.label}</li>
              </Link>
            ))}
          </ul>
          <div className="navbar__items-options">
            {userWallet && (
              <div className="navbar__notifications">
                <button
                  className="navbar__items__icon"
                  onClick={() =>
                    toggleNotificationModal(!isNotificationModalOpen)
                  }
                >
                  <img src={notificationsIcon} alt="Notifications" />
                  {unreadNotificationsLen > 0 && (
                    <div className="navbar__notification-flag"></div>
                  )}
                </button>
                {isNotificationModalOpen && (
                  <>
                    <div
                      className="navbar__notifications-wrapper"
                      onClick={() =>
                        toggleNotificationModal(!isNotificationModalOpen)
                      }
                    ></div>
                    <NotificationModal />
                  </>
                )}
              </div>
            )}
            {!userWallet && (
              <button
                onClick={() => setVisible(true)}
                className="navbar__wallet"
              >
                Select wallet
              </button>
            )}
            {userWallet && (
              <button
                onClick={() => setShowSettings(!showSettings)}
                className="navbar__wallet"
              >
                <img
                  src={walletIcon}
                  alt="wallet icon"
                  className="navbar__wallet-img"
                />
                {getTrimmedPublicKey(userWallet?.publicKey)}
              </button>
            )}

            {showSettings && (
              <WalletDropdown closeModal={() => setShowSettings(false)} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
