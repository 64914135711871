import React, { FC } from "react";
import "./ProgressBarCircle.scss";
import { getCirclePercentage, getPercentage } from "../../utilities/helpers";

export const ProgressBarCircle: FC<{
  approved: number;
  rejected: number;
  maxVoterWeight: number;
  stats?: boolean;
}> = ({ approved, rejected, maxVoterWeight, stats }) => {
  return (
    <div className="progress-bar-circle">
      <div>
        <div
          className="progress-bar-circle__line"
          style={{
            justifyContent: approved === 0 ? "flex-end" : "flex-start",
          }}
        >
          {approved !== 0 && (
            <div
              style={{
                width: getPercentage(approved, 100) + "%",
              }}
              className="progress-bar-circle__line--green"
            />
          )}
          {rejected !== 0 && (
            <div
              className="progress-bar-circle__line--red"
              style={{
                width: getPercentage(rejected, 100) + "%",
                alignSelf: "flex-end",
              }}
            />
          )}
        </div>
        <div className="progress-bar-circle__line-circle">
          {approved != 0 && (
            <span
              style={{
                left: `${approved}% `,
              }}
            >
              {approved.toFixed(0)}%
            </span>
          )}

          {rejected != 0 && (
            <span
              style={{
                right: `${rejected}% `,
              }}
            >
              {rejected.toFixed(0)}%
            </span>
          )}
        </div>
      </div>

      {stats && (
        <div className="progress-bar-circle__line-info">
          <p>{approved}% Voted to Accept</p>
          <p>{rejected}% Voted to Reject</p>
        </div>
      )}
    </div>
  );
};
