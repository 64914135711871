import { FC, useMemo, useState } from "react";
import "./OverviewFundraiseCard.scss";
import fundraise from "../../../assets/fundraiseoverview.png";
import copy from "../../../assets/content_copy.png";
import MainButton from "../../../components/MainButton/MainButton";
import FundraiseInfo from "../../Treasury/FundraiseInfo/FundraiseInfo";
import add from "../../../assets/add_circle__dark.jpg";
import addIconFull from "../../../assets/add_circle_full.png";
import CreateFundraiseModal from "../../../components/CreateFundraiseFormModal/CreateFundraiseModal";
import HoverIcon from "../../../components/HoverIcon/HoverIcon";
import {
  checkIfUserHasTreasuryPermissionForAction,
  handleCopyToClipboard,
} from "../../../utilities/helpers";
import { clubStore } from "../../../state/clubStore";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import CreateProposalModal from "../../../components/CreateProposal/CreateProposalModal";
import { ProposalType } from "../../../common/enums/proposal.enum";
import { TreasuryAction } from "../../../common/enums/clubs.enum";

const OverviewFundraiseCard: FC = () => {
  const [createFundraiseModal, toggleCreateFundraiseModal] = useState(false);
  const [createFundraiseProposal, toggleFundraiseProposal] = useState(false);

  const { fundraises, activeTreasury, memberTreasuryInfo } = clubStore();

  const fundraiseInProgress = useMemo(
    () => fundraises?.find((item) => item.isActive),
    [fundraises]
  );

  const fundraisesLength = fundraises && fundraises.length;

  return (
    <div className="overview-fundraise">
      {createFundraiseModal && (
        <CreateFundraiseModal
          onClose={() => toggleCreateFundraiseModal(false)}
          edit={false}
        />
      )}
      {createFundraiseProposal && (
        <CreateProposalModal
          proposalType={ProposalType.Fundraise}
          closeModal={() => toggleFundraiseProposal(false)}
        />
      )}
      <div className="overview-fundraise__header">
        <img src={fundraise} alt="Treasury" />
        <h3>Fundraise</h3>
        <HoverIcon
          src={copy}
          alt="Paperclip"
          onClick={() => handleCopyToClipboard(EMPTY_STRING)}
        />
      </div>
      {fundraiseInProgress ? (
        <FundraiseInfo expanded={false} fundraise={fundraiseInProgress} />
      ) : (
        <div className="overview-fundraise__no-data">
          <div className="overview-fundraise__no-data-info">
            <img
              src={add}
              alt="No active fundraise"
              className="overview-fundraise__no-data-add"
            />
            <p className="overview-fundraise__no-data-description">
              No active fundraises
            </p>
          </div>
          <MainButton
            light
            type="button"
            onClick={
              fundraisesLength && fundraisesLength > 0
                ? () => toggleFundraiseProposal(true)
                : () => {
                    toggleCreateFundraiseModal(true);
                  }
            }
            disabled={
              !checkIfUserHasTreasuryPermissionForAction(
                memberTreasuryInfo,
                activeTreasury?.treasuryRoleConfig,
                TreasuryAction.Fundraise
              )
            }
          >
            <img
              className="tokens__show-more-icon"
              src={addIconFull}
              alt="Create fundraise"
            />
            Create fundraise
          </MainButton>
        </div>
      )}
    </div>
  );
};

export default OverviewFundraiseCard;
