import React, { useMemo, useState } from "react";
import telegramIcon from "../../assets/telegram dark.svg";
import emailIcon from "../../assets/mail black.svg";
import discordIcon from "../../assets/discord black.svg";
import twitterIcon from "../../assets/twitter.svg";
import linkedinIcon from "../../assets/linkedin.svg";
import LabelCard from "../LabelCard/LabelCard";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import userAccountSettingsFormConfig from "./userAccountSettingsFormConfig";
import SocialNetworkInput from "../../pages/Overview/ClubApplicationModal/SocialNetworkInput/SocialNetworkInput";
import InputField from "../InputFields/InputField";
import { useFormikContext } from "formik";
import { IUserAccountSettingsFormFields } from "../../common/interfaces/form.interface";
import { IMemberInfo } from "../../common/interfaces/club.interface";
import { SocialNetworkType } from "../../common/enums/clubs.enum";
import UploadPhoto from "../UploadPhoto/UploadPhoto";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import "./UserAccountSettingsForm.scss";
import SocialNetworksFields from "../SocialNetworksFields/SocialNetworksFields";

const UserAccountSettingsForm: React.FC<{
  member: IMemberInfo | undefined;
}> = ({ member }) => {
  const { values, errors, setFieldValue } =
    useFormikContext<IUserAccountSettingsFormFields>();
  const wallet = useAnchorWallet();
  const formFields = userAccountSettingsFormConfig.formFields;

  const removeImage = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setFieldValue(formFields.imageUrl.name, EMPTY_STRING);
  };

  return (
    <div className="user-account-settings">
      <UploadPhoto
        imageFormField={userAccountSettingsFormConfig.formFields.imageUrl.name}
        imageText="Upload your photo"
        imageUrl={values.imageUrl}
        removeImage={removeImage}
        setFieldValue={setFieldValue}
      />
      <LabelCard label="Wallet" description={wallet?.publicKey.toString()} />
      <InputField
        name={formFields.name.name}
        type="text"
        labelTitle={formFields.name.label}
        placeholder={formFields.name.label}
      />
      <SocialNetworksFields formFields={formFields} member={member} />
    </div>
  );
};

export default UserAccountSettingsForm;
