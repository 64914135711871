import { FC, useEffect, useMemo, useState } from "react";
import "./OTCDeal.scss";
import MainButton from "../../components/MainButton/MainButton";
import NoData from "../../components/NoData/NoData";
import OTCCard from "./OTCCard/OTCCard";
import { FetchingDataState, TabLabel } from "../../common/enums/common.enum";
import TabHeader from "../../components/TabHeader/TabHeader";
import { OTC_TABS } from "../../common/constants/navigation.constants";
import ClubDetailsLayout from "../../components/ClubDetailsWrapper/ClubDetailsWrapper";
import { clubStore } from "../../state/clubStore";
import { useLazyQuery, useSubscription } from "@apollo/client";
import {
  GET_MEMBER_ACTIVE_TREASURY_INFO,
  GET_OTC_DEALS_FOR_TREASURY,
  GET_OTC_LEDGER_FOR_TREASURY,
} from "../../api/club.api";
import { IOTCDeal, IOTCLedger } from "../../common/interfaces/otc.interface";
import SharesModal from "./SharesModal/SharesModal";
import { createNotification } from "../../utilities/notifications";
import {
  ITEMS_PER_PAGE,
  MESSAGE_TYPE,
} from "../../common/constants/common.constants";
import { IBasicFilters } from "../../common/interfaces/common.interface";
import { sortOTCOptions } from "../../common/constants/sort.constants";
import SortDropdown from "../../components/SortDropdown/SortDropdown";
import Pagination from "../../components/Pagination/Pagination";
import {
  OTC_DEAL_DELETED_SUBSCRIPTION,
  OTC_DEAL_SUBSCRIPTION,
} from "../../api/club-subscriptions.api";
import { apolloClient } from "../../api/config.api";
import useTransactionStore from "../../state/transactionStore";
import OTCLedgerCard from "./OTCLedgerCard/OTCLedgerCard";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { checkIfUserHasTreasuryPermissionForAction } from "../../utilities/helpers";
import { TreasuryAction } from "../../common/enums/clubs.enum";

const OTCDeal: FC<{}> = ({}) => {
  const [otcDeals, setOCTDeals] = useState<IOTCDeal[]>([]);
  const [otcLedgers, setOtcLedgers] = useState<IOTCLedger[]>([]);
  const [sellOfferModalActive, toggleSellOfferModalActive] = useState(false);
  const [totalItems, setTotalItems] = useState();
  const wallet = useAnchorWallet();

  const [filters, setFilters] = useState<IBasicFilters>({
    page: 0,
    filter: TabLabel.AllOffers,
  });

  const { activeTreasury, memberTreasuryInfo } = clubStore();

  const renderOTCContent = useMemo(() => {
    switch (filters.filter) {
      case TabLabel.AllOffers: {
        return otcDeals.map((otc, index) => (
          <OTCCard otc={otc} key={index} offered />
        ));
      }
      case TabLabel.MyOffers: {
        return otcDeals.map((otc, index) => (
          <OTCCard otc={otc} key={index} offered />
        ));
      }
      case TabLabel.History: {
        return otcLedgers.map((otc, index) => {
          return <OTCLedgerCard otc={otc} key={index} />;
        });
      }
      default: {
        return (
          <NoData
            message={"This club has no current OTC offers"}
            mainButtonText={"Create an offer"}
            mainButtonOnClick={handleSellClick}
            disabled={
              !checkIfUserHasTreasuryPermissionForAction(
                memberTreasuryInfo,
                activeTreasury?.treasuryRoleConfig,
                TreasuryAction.CreateFinancialOffer
              )
            }
          />
        );
      }
    }
  }, [filters, otcDeals, otcLedgers]);

  const { updateFetchingData } = useTransactionStore();

  const [getTreasuryOTCDeals, treasuryOTCDeals] = useLazyQuery(
    GET_OTC_DEALS_FOR_TREASURY,
    {
      onCompleted: (data) => {
        setOCTDeals(data.getOtcDealsForTreasury.otcDeals);
        setTotalItems(data.getOtcDealsForTreasury.count);
      },
    }
  );

  const [getTreasuryOTCLedger, treasuryOTCLedger] = useLazyQuery(
    GET_OTC_LEDGER_FOR_TREASURY,
    {
      onCompleted: (data) => {
        console.log("LEDGERS" + data);
        setOtcLedgers(data.getOtcDealsLedgerPerTreasury.otcDeals);
        setTotalItems(data.getOtcDealsLedgerPerTreasury.count);
      },
      variables: {
        treasuryAddress: activeTreasury?.treasuryAddress,
        page: filters.page ? filters.page + 1 : 1,
      },
    }
  );

  useSubscription(OTC_DEAL_SUBSCRIPTION, {
    onData: ({ data }) => {
      console.log("OTC DEAL SUB", data);
      apolloClient.refetchQueries({
        include: [GET_MEMBER_ACTIVE_TREASURY_INFO],
      });
      const updatedOtcDeals = [...otcDeals];
      const updatedOtcDealIndex = updatedOtcDeals.findIndex(
        (item) => item.address === data.data?.otcDealsChanged.address
      );
      if (updatedOtcDealIndex >= 0) {
        updatedOtcDeals[updatedOtcDealIndex] = data.data?.otcDealsChanged;
      } else {
        updatedOtcDeals.unshift(data.data?.otcDealsChanged);
      }
      setOCTDeals(updatedOtcDeals);
      toggleSellOfferModalActive(false);
      updateFetchingData(FetchingDataState.Succeeded);
    },
    variables: {
      treasuryAddress: activeTreasury?.treasuryAddress,
    },
  });

  useSubscription(OTC_DEAL_DELETED_SUBSCRIPTION, {
    onData: ({ data }) => {
      console.log("OTC DELETED DEAL SUB", data);
      apolloClient.refetchQueries({
        include: [GET_MEMBER_ACTIVE_TREASURY_INFO],
      });

      setOCTDeals((prevValue) => [
        ...prevValue.filter(
          (item) => item.address !== data.data.otcDealDeleted.offerAddress
        ),
      ]);
      updateFetchingData(FetchingDataState.Succeeded);
    },
    variables: {
      treasuryAddress: activeTreasury?.treasuryAddress,
    },
  });

  const handleSellClick = () => {
    if (memberTreasuryInfo) {
      toggleSellOfferModalActive(true);
      return;
    }
    createNotification(MESSAGE_TYPE.ERROR, "No shares to sell");
  };

  useEffect(() => {
    getFilteredOTCDeals();
  }, [activeTreasury?.treasuryAddress, filters]);

  useEffect(() => {
    if (filters.filter === TabLabel.History) {
      getTreasuryOTCLedger();
    }
  }, [filters]);

  const getFilteredOTCDeals = () => {
    try {
      getTreasuryOTCDeals({
        variables: {
          treasuryAddress: activeTreasury?.treasuryAddress,
          otcFilters: {
            ...filters,
            page: filters.page ? filters.page + 1 : 1,
          },
          userAddress:
            filters.filter === TabLabel.MyOffers
              ? wallet?.publicKey.toString()
              : null,
        },
      });
    } catch (error) {
      createNotification(MESSAGE_TYPE.ERROR, "Failed to load otc deals");
    }
  };

  return (
    <ClubDetailsLayout>
      {sellOfferModalActive && (
        <SharesModal
          closeModal={() => toggleSellOfferModalActive(false)}
          selling
        />
      )}
      <div className="otc-deal">
        <TabHeader
          tabs={OTC_TABS}
          onClick={(tab: TabLabel) => {
            setFilters({
              ...filters,
              filter: tab,
              page: 0,
            });
          }}
          activeTab={filters.filter ?? TabLabel.AllOffers}
        >
          <div className="otc-deal-flex">
            <MainButton
              type="button"
              light
              onClick={handleSellClick}
              disabled={
                !checkIfUserHasTreasuryPermissionForAction(
                  memberTreasuryInfo,
                  activeTreasury?.treasuryRoleConfig,
                  TreasuryAction.CreateFinancialOffer
                )
              }
            >
              Sell Offer
            </MainButton>
            {otcDeals.length > 0 && (
              <SortDropdown
                sortOptions={sortOTCOptions}
                sortDeals={(sort) => {
                  setFilters({
                    ...filters,
                    sort: sort,
                    page: 0,
                  });
                }}
              />
            )}
          </div>
        </TabHeader>
        {otcDeals.length < 1 ? (
          <NoData
            message={"This club has no current OTC offers"}
            mainButtonText={"Create an offer"}
            mainButtonOnClick={handleSellClick}
            disabled={
              !checkIfUserHasTreasuryPermissionForAction(
                memberTreasuryInfo,
                activeTreasury?.treasuryRoleConfig,
                TreasuryAction.CreateFinancialOffer
              )
            }
          />
        ) : (
          <div className="otc-deal-cards">
            {renderOTCContent}
            {totalItems && (
              <Pagination
                totalPages={Math.ceil(totalItems / ITEMS_PER_PAGE)}
                handlePageChange={({ selected }) => {
                  setFilters({ ...filters, page: selected });
                }}
                currentPage={filters.page ?? 0}
              />
            )}
          </div>
        )}
      </div>
    </ClubDetailsLayout>
  );
};

export default OTCDeal;
