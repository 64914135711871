import React, { FC } from "react";
import "./ChainItems.scss";
import { CHAINS } from "../../common/constants/common.constants";
import { Chain } from "../../common/enums/common.enum";

const ChainItems: FC<{ chain: Chain }> = ({ chain }) => {
  return (
    <>
      {CHAINS.filter((i) => i.chain === chain).map((i, index) => (
        <div className="chain-items" key={index}>
          <img src={i.image} alt={i.alt} />
          <p>{i.chain}</p>
        </div>
      ))}
    </>
  );
};

export default ChainItems;
