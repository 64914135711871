export const governanceSeed = Buffer.from("governance");
export const treasurySeed = Buffer.from("treasury");
export const unqClubSeed = Buffer.from("unq-club");
export const realmConfigSeed = Buffer.from("realm-config");
export const unqClubMemberSeed = Buffer.from("member");
export const voterWeightSeed = Buffer.from("voter-weight-record");
export const accountGovernanceSeed = Buffer.from("account-governance");
export const treasuryDataSeed = Buffer.from("treasury-data");
export const vaultSeed = Buffer.from("vault");
export const vaultDataSeed = Buffer.from("vault-data");
export const fundraiseCfgSeed = Buffer.from("fundraise-cfg");
export const depositRecordSeed = Buffer.from("deposit-record");
export const maxVoterWeightSeed = Buffer.from("max-voter-weight-record");
export const offerSeed = Buffer.from("offer");
export const escrowSeed = Buffer.from("escrowed-tokens");
export const proposalMetadataSeed = Buffer.from("proposal-metadata");
export const sellPermissionGovernanceSeed = Buffer.from("spc-governance");
export const profitSeed = Buffer.from("profit");
export const transferProfitSeed = Buffer.from("transfer-profit");
export const acceptOfferSeed = Buffer.from("accepted-offer");
export const nftVoteRecordSeed = Buffer.from("nft-vote-record");
export const tokenLedgerSeed = Buffer.from("token-ledger");
export const withdrawalSeed = Buffer.from("withdrawal");
export const withdrawalDataSeed = Buffer.from("withdrawal-data");
export const withdrawalRecordSeed = Buffer.from("withdrawal-record");
export const financialRecordSeed = Buffer.from("financial-record");
export const stakeConfigSeed = Buffer.from("stake-config");
export const stakedTokensSeed = Buffer.from("staked-tokens");
export const stakeRecordSeed = Buffer.from("stake-record");
export const unqTokensSeed = Buffer.from("club-program-unq-tokens");
export const metadataSeed = Buffer.from("metadata");
export const buyNowMagicEdenAccounts = Buffer.from("buy-now-accounts");
export const stakedNftSeed = Buffer.from("staked-nft");
export const universeMetadataSeed = Buffer.from("universe");
export const magicEdenSeed = Buffer.from("magic-eden");
export const denominatedTokenSeed = Buffer.from("denominated-token");
export const financialOfferSeed = Buffer.from("financial-offer");

export const adminSeed = Buffer.from("admin");
export const transferGovernanceSeed = Buffer.from("transfer-governance");
export const governanceChangeGovernanceSeed = Buffer.from(
  "governance-change-governance"
);
export const syndicateAuthoritySeed = Buffer.from("admin-authority");
