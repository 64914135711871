import { FC, useState } from "react";
import flag from "../../../assets/flag.svg";
import MainButton from "../../../components/MainButton/MainButton";
import "./ActionsBeforeFundraise.scss";
import SetReservedRightsModal from "../../../components/SetReservedRightsModal/SetReservedRightsModal";

const ReservedRights: FC = () => {
  const [reservedRightsModal, toggleReservedRightsModal] = useState(false);

  return (
    <div className="actions-before-fundraise">
      {reservedRightsModal && (
        <SetReservedRightsModal
          onClose={() => {
            toggleReservedRightsModal(false);
          }}
        />
      )}
      <div className="actions-before-fundraise__info">
        <img
          src={flag}
          alt="flag icon"
          className="actions-before-fundraise__flag-icon"
        />
        <p className="actions-before-fundraise__description">
          Reserved rights are not set for this treasury, you can do that before
          the first fundraise
        </p>
      </div>
      <div className="actions-before-fundraise__actions">
        <MainButton
          type="button"
          light
          squared
          onClick={() => {
            toggleReservedRightsModal(true);
          }}
        >
          Set reserved rights
        </MainButton>
      </div>
    </div>
  );
};

export default ReservedRights;
