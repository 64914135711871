import { EMPTY_STRING } from "../../common/constants/common.constants";

export default {
  formId: "fundraise",
  formFields: {
    amount: {
      name: "amount",
      label: "Amount",
      value: EMPTY_STRING,
    },
  },
};
