import React, { useCallback, useEffect, useMemo } from "react";
import useTransactionStore from "../../state/transactionStore";
import Modal from "../Modal/Modal";
import TransactionItem from "./TransactionItem/TransactionItem";
import {
  FetchingDataState,
  TransactionState,
} from "../../common/enums/common.enum";
import { Oval } from "react-loader-spinner";
import successIcon from "../../assets/transactionSucceeded.svg";

//TODO@milica: Update fetching data logic
const TransactionModal = () => {
  const {
    transactions,
    isProcessing,
    closeTransactionProcess,
    isFetchingData,
    fetchingStatus,
  } = useTransactionStore();

  const closeModalIfNeeded = useCallback(() => {
    if (
      !transactions.find(
        (item) => item.transactionState === TransactionState.Loading
      )
    ) {
      closeTransactionProcess();
    }
  }, [transactions, closeTransactionProcess]);

  useEffect(() => {
    void closeIfAllTransactionPassed();
  }, [transactions, isFetchingData]);

  const closeIfAllTransactionPassed = async () => {
    if (
      transactions.filter(
        (item) => item.transactionState === TransactionState.Succeeded
      ).length === transactions.length &&
      !isFetchingData
    ) {
      closeTransactionProcess();
    }
  };

  return (
    <>
      {isProcessing && (
        <Modal
          closeModal={closeModalIfNeeded}
          title={"Transaction list"}
          zIndex={60}
        >
          <div className="bulk-modal__transactions">
            {transactions.map((item) => (
              <TransactionItem networkItem={item} key={item.number} />
            ))}
          </div>
          {isFetchingData && (
            <div className="transactions-item">
              <div className="transactions-item__text-icon">
                <div className="transactions-item__state-text">
                  {fetchingStatus === FetchingDataState.Loading ? (
                    <div className="transactions-item__spinner">
                      <Oval
                        color="#06d7a0"
                        height={26}
                        width={26}
                        strokeWidth={5}
                        secondaryColor="#b6b9be"
                      />
                    </div>
                  ) : (
                    <div
                      className={`transactions-item__state transactions-item__state--${fetchingStatus}`}
                    >
                      {fetchingStatus === FetchingDataState.Succeeded && (
                        <img src={successIcon} alt="Success" />
                      )}
                    </div>
                  )}
                  <p className="transactions-item__label">Updating data</p>
                </div>
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default TransactionModal;
