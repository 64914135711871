import React, { FC, useState } from "react";
import "./SortDropdown.scss";
import arrow from "../../assets/arrow_down.svg";
import { Sort } from "../../common/enums/common.enum";
import { ISortOption } from "../../common/interfaces/common.interface";

const SortDropdown: FC<{
  sortOptions: ISortOption[];
  sortDeals: (option: Sort) => void;
}> = ({ sortOptions, sortDeals }) => {
  const [sort, toggleSort] = useState(false);
  return (
    <div className="sort">
      <p onClick={() => toggleSort(!sort)}>
        Sort by <img src={arrow} alt="Arrow down" />
      </p>
      {sort && (
        <ul>
          {sortOptions.map((option, index) => (
            <li
              key={index}
              onClick={() => {
                sortDeals(option.value);
                toggleSort(false);
              }}
            >
              {option.label}
            </li>
          ))}
          <div className="backdrop" onClick={() => toggleSort(false)}></div>
        </ul>
      )}
    </div>
  );
};

export default SortDropdown;
