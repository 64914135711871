import { Form, Formik } from "formik";
import FormActions from "../../../components/FormActions/FormActions";
import Modal from "../../../components/Modal/Modal";
import { useMemo } from "react";
import ClubApplicationFormConfig from "./ClubApplicationFormConfig";
import ClubApplicationForm from "./ClubApplicationForm/ClubApplicationForm";
import { validateClubApplicationForm } from "./ClubApplicationFormValidate";
import { ISocialNetwork } from "../../../common/interfaces/club.interface";
import { IClubApplicationFormFields } from "../../../common/interfaces/form.interface";
import {
  MESSAGE_TYPE,
  allSocials,
} from "../../../common/constants/common.constants";
import {
  GET_APPLICATIONS_FOR_CLUB,
  GET_USER_APPLICATION,
  storeApplication,
} from "../../../api/club.api";
import { clubStore } from "../../../state/clubStore";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { apolloClient } from "../../../api/config.api";
import { createNotification } from "../../../utilities/notifications";
import { updateSocialNetworkApiArray } from "../../../utilities/helpers";

const ClubApplicationModal: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const { clubBasicInfo } = clubStore();
  const wallet = useAnchorWallet();
  const initialValues = useMemo(
    () =>
      (() => {
        const values: any = {};
        Object.keys(ClubApplicationFormConfig.formFields).map(
          (key) =>
            (values[key] = (ClubApplicationFormConfig.formFields as any)[
              key
            ].value)
        );
        return values;
      })(),
    []
  );

  const handleSubmitForm = async (values: IClubApplicationFormFields) => {
    try {
      if (!wallet || !clubBasicInfo) return;
      const networks: ISocialNetwork[] = [];

      allSocials.forEach((item) => {
        updateSocialNetworkApiArray(
          values[item.toLowerCase() as keyof IClubApplicationFormFields],
          item,
          networks
        );
      });

      await storeApplication(
        clubBasicInfo?.address,
        wallet?.publicKey.toString(),
        values.motivationLetter,
        networks
      );
      apolloClient.refetchQueries({
        include: [GET_APPLICATIONS_FOR_CLUB, GET_USER_APPLICATION],
      });
      createNotification(MESSAGE_TYPE.SUCCESS, "Application sent");
      onClose();
    } catch (error) {
      console.log(error);
      createNotification(MESSAGE_TYPE.SUCCESS, "Failed to send application");
    }
  };
  return (
    <Modal title="Join club application" closeModal={onClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validate={(values) => validateClubApplicationForm(values, {})}
        validateOnBlur
      >
        <Form>
          <ClubApplicationForm />
          <FormActions
            cancelAction={onClose}
            buttonText="Send application"
            buttonAction={() => handleSubmitForm}
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default ClubApplicationModal;
