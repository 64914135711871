import { FC, Fragment, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import "./BasicDealInfo.scss";
import { useFormikContext } from "formik";
import { IVertical } from "../../../common/interfaces/club.interface";
import InputField from "../../../components/InputFields/InputField";
import { VERTICALS } from "../../../utilities/data/deal.data";
import {
  EMPTY_STRING,
  MAX_IMAGE_SIZE,
} from "../../../common/constants/common.constants";
import Tag from "../../../components/Tag/Tag";
import createDealFormModel from "../createDealFormModel";
import { IBasicClubDataForm } from "../../../common/interfaces/form.interface";
import {
  toggleClubVerticalHelper,
  uploadImage,
} from "../../../utilities/helpers";
import UploadPhoto from "../../../components/UploadPhoto/UploadPhoto";

const formFields = createDealFormModel.formFields;

const BasicDealInfo: FC = () => {
  const { values, setFieldValue } = useFormikContext<IBasicClubDataForm>();

  const reader = new FileReader();

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/*": [],
    },
    maxSize: MAX_IMAGE_SIZE,
    onDrop: (acceptedFiles) => {
      uploadImage(
        acceptedFiles,
        reader,
        formFields.imageUrl.name,
        setFieldValue
      );
    },
  });

  const toggleVerical = useCallback(
    (v: IVertical) => {
      setFieldValue(
        formFields.verticals.name,
        toggleClubVerticalHelper(v, values.verticals)
      );
    },
    [values]
  );
  const renderVerticals = useMemo(() => {
    return VERTICALS.map((v) => {
      return (
        <Tag
          handleClick={() => toggleVerical(v)}
          isActive={
            !!values.verticals.find((vertical) => v.slug === vertical.slug)
          }
          label={v.label}
          value={v.slug}
        />
      );
    });
  }, [values]);

  const removeImage = async (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setFieldValue(formFields.imageUrl.name, EMPTY_STRING);
  };

  return (
    <div className="basic-deal-info">
      <UploadPhoto
        imageFormField={formFields.imageUrl.name}
        imageText="Upload syndicate Avatar"
        imageUrl={values.imageUrl}
        removeImage={removeImage}
        setFieldValue={setFieldValue}
      />
      <div className="basic-deal-info__data">
        <div className="basic-deal-info__name-description">
          <p className="basic-deal-info__label">Basic data</p>
          <InputField
            labelTitle={formFields.name.label}
            name={formFields.name.name}
            type="text"
            placeholder="Enter your syndicate name"
          />
          <InputField
            labelTitle={formFields.description.label}
            name={formFields.description.name}
            type="text"
            placeholder="Share your syndicate details & background"
          />
        </div>
        <div className="basic-deal-info__verticals">
          <p className="basic-deal-info__label">Verticals</p>
          <div>{renderVerticals}</div>
        </div>
      </div>
    </div>
  );
};

export default BasicDealInfo;
