import { Form, Formik } from "formik";
import { FC, useMemo, useState } from "react";
import formModel from "./treasuryFormModel";
import "./TreasuryModal.scss";
import { useNavigate } from "react-router";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { ITreasuryFormFields } from "../../common/interfaces/form.interface";
import RolesConfiguration from "../../components/RolesConfiguration/RolesConfiguration";
import DealTreasury from "../../components/DealTreasury/DealTreasury";
import Modal from "../Modal/Modal";
import FormActions from "../FormActions/FormActions";
import Ownership from "../Ownership/Ownership";
import { validateTreasury } from "./treasuryValidation";
import { createClubTreasury } from "../../program/methods/clubs";
import { clubStore } from "../../state/clubStore";
import { PublicKey } from "@metaplex-foundation/js";
import { createNotification } from "../../utilities/notifications";
import { MESSAGE_TYPE } from "../../common/constants/common.constants";
import { TreasuryStep } from "../../common/enums/clubs.enum";
import { useMutation } from "@apollo/client";
import { SAVE_FUNDRAISE_MODEL } from "../../api/club.api";

const TreasuryModal: FC<{ closeModal: () => void }> = ({ closeModal }) => {
  const [activeStep, setActiveStep] = useState(
    TreasuryStep.TreasuryConfiguration
  );
  const navigate = useNavigate();
  const wallet = useAnchorWallet();
  const { formFields } = formModel;

  const { clubBasicInfo, memberData } = clubStore();

  const [saveFundraiseModel] = useMutation(SAVE_FUNDRAISE_MODEL);

  const renderDealContent = (values: ITreasuryFormFields) => {
    switch (activeStep) {
      case TreasuryStep.TreasuryConfiguration: {
        return <DealTreasury />;
      }
      case TreasuryStep.TreasuryRoles: {
        return (
          <RolesConfiguration
            rolesArray={formFields.treasuryRoles.name}
            rolesLabel="Treasury roles"
          />
        );
      }
      case TreasuryStep.TreasuryOwnership: {
        return <Ownership members={values.members} />;
      }
    }
  };

  const initialValues = useMemo(
    () =>
      (() => {
        const values: any = {};
        Object.keys(formModel.formFields).map(
          (key) => (values[key] = (formModel.formFields as any)[key].value)
        );
        return values;
      })(),
    []
  );

  const handleSubmit = async (values: ITreasuryFormFields) => {
    try {
      if (!wallet || !clubBasicInfo || !memberData) {
        createNotification(MESSAGE_TYPE.ERROR, "Missing data");
        return;
      }

      const treasuryAddress = await createClubTreasury(
        values.treasuryRoles,
        clubBasicInfo?.treasuryCount,
        new PublicKey(clubBasicInfo?.address),
        new PublicKey(memberData?.address),
        values.chain,
        wallet,
        values.treasuryName,
        clubBasicInfo?.name,
        Number(values.approvalQuorum),
        Number(values.maxVotingTime) * 86400,
        values.denominatedCurrency,
        values.members
      );
      await saveFundraiseModel({
        variables: {
          treasuryAddress: treasuryAddress,
          fundraiseModel: values.fundraiseModel,
        },
      });
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal closeModal={closeModal}>
      <Formik
        validateOnBlur
        initialValues={initialValues}
        onSubmit={
          activeStep === TreasuryStep.TreasuryOwnership
            ? (values) => {
                handleSubmit(values);
              }
            : () => setActiveStep(activeStep + 1)
        }
        validate={(values) => validateTreasury(values, activeStep)}
      >
        {({ values }) => (
          <Form id={formModel.formId}>
            <div className="treasury-modal">
              <div className="treasury-modal__content">
                <div className="treasury-modal__content-form">
                  {renderDealContent(values)}
                </div>
                <FormActions
                  buttonText={
                    activeStep === TreasuryStep.TreasuryOwnership
                      ? "Create treasury"
                      : "Continue"
                  }
                  buttonAction={() => {}}
                  cancelAction={
                    activeStep === TreasuryStep.TreasuryConfiguration
                      ? closeModal
                      : () => setActiveStep(activeStep - 1)
                  }
                  cancelBtnText={
                    activeStep === TreasuryStep.TreasuryConfiguration
                      ? "Cancel"
                      : "Back"
                  }
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default TreasuryModal;
