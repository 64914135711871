import { useEffect, useState } from "react";
import "./Vault.scss";
import { Sort, TabLabel } from "../../common/enums/common.enum";
import NoData from "../../components/NoData/NoData";
import SortDropdown from "../../components/SortDropdown/SortDropdown";
import TabHeader from "../../components/TabHeader/TabHeader";
import { VAULT_TABS } from "../../common/constants/navigation.constants";
import { sortVaultOptions } from "../../common/constants/sort.constants";
import NftCard from "./NftCard/NftCard";
import { NFTMarketOption } from "../../common/enums/vault.enum";
import ClubDetailsLayout from "../../components/ClubDetailsWrapper/ClubDetailsWrapper";
import { clubStore } from "../../state/clubStore";
import { IDetailNftData } from "../../common/interfaces/common.interface";

const Vault = () => {
  const { vault } = clubStore();
  const [activeTab, setActiveTab] = useState(TabLabel.ClubNfts);
  const [sort, toggleSort] = useState(false);
  const [sortedNfts, setSortedNfts] = useState<IDetailNftData[]>();

  useEffect(() => {
    if (vault?.nfts.length !== sortedNfts?.length) {
      setSortedNfts(vault?.nfts);
    }
  }, [vault?.nfts]);

  const getNftsByTab = (tab: TabLabel) => {
    if (sortedNfts) {
      switch (tab) {
        case TabLabel.ClubNfts:
          return sortedNfts;
        case TabLabel.Buying:
          return sortedNfts.filter(
            (nft) => nft.offerType === NFTMarketOption.Buying
          );
        case TabLabel.Selling:
          return sortedNfts.filter(
            (nft) => nft.offerType === NFTMarketOption.Selling
          );
        default:
          return [];
      }
    }
  };

  const nftsByTab = getNftsByTab(activeTab);

  const sortNfts = (sortType: Sort) => {
    if (nftsByTab) {
      switch (sortType) {
        case Sort.Collection:
          setSortedNfts(
            nftsByTab.sort((a, b) =>
              (a.collection?.name || "").localeCompare(b.collection?.name || "")
            )
          );
          break;
        case Sort.PurchasePrice:
          setSortedNfts(
            nftsByTab.sort(
              (a, b) => (a.purchasePrice || 0) - (b.purchasePrice || 0)
            )
          );
          break;
      }
    }
  };

  return (
    <ClubDetailsLayout>
      <div className="vault">
        <TabHeader
          tabs={VAULT_TABS}
          onClick={(tab: TabLabel) => {
            setActiveTab(tab);
          }}
          activeTab={activeTab}
        >
          {/* {sortNfts.length > 0 && (
            <SortDropdown
              array={sortVaultOptions}
              sort={sort}
              toggleSort={() => toggleSort(!sort)}
              sortDeals={sortNfts}
            />
          )} */}
        </TabHeader>
        {nftsByTab && (
          <>
            <div>
              {nftsByTab.length > 0 ? (
                <div className="vault__cards">
                  {nftsByTab.map((nft, index) => (
                    <NftCard key={index} card={nft} noActions />
                  ))}
                </div>
              ) : (
                <NoData
                  message={"You have no NFTs in the vault"}
                  height="70vh"
                  noIcon
                  mainButtonOnClick={() => {}}
                />
              )}
            </div>
          </>
        )}
      </div>
    </ClubDetailsLayout>
  );
};

export default Vault;
