import { EMPTY_STRING } from "../../common/constants/common.constants";

export default {
  formId: "userAccountSettings",
  formFields: {
    wallet: {
      name: "wallet",
      label: "wallet",
      value: EMPTY_STRING,
    },
    name: {
      name: "name",
      label: "Name",
      value: EMPTY_STRING,
    },
    imageUrl: {
      name: "imageUrl",
      label: "image",
      value: EMPTY_STRING,
    },
    telegram: {
      name: "telegram",
      label: "Telegram",
      value: EMPTY_STRING,
    },
    mail: {
      name: "mail",
      label: "Email",
      value: EMPTY_STRING,
    },
    discord: {
      name: "discord",
      label: "Discord",
      value: EMPTY_STRING,
    },
    twitter: {
      name: "twitter",
      label: "Twitter",
      value: EMPTY_STRING,
    },
    linkedin: {
      name: "linkedin",
      label: "Linkedin",
      value: EMPTY_STRING,
    },
  },
};
