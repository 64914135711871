import { ClubAction, TreasuryAction } from "../enums/clubs.enum";

export const MAX_VAULT_NFTS_FOR_OVERVIEW_PAGE = 5;

export const DEFAULT_MEMBER_CLUB_PERMISSIONS = [
  ClubAction.SupportClub,
  ClubAction.StakeTokens,
  ClubAction.AcceptFinancialOffer,
  ClubAction.CanLeave,
];

export const DEFAULT_FOUNDER_CLUB_PERMISSIONS = [
  ClubAction.CreateTreasuryGovernance,
  ClubAction.CreateWithdrawalGovernance,
  ClubAction.CreateTransferGovernance,
  ClubAction.AllowMember,
  ClubAction.CancelInvitation,
  ClubAction.SupportClub,
  ClubAction.UpdateMember,
  ClubAction.InitializeStaking,
  ClubAction.StakeTokens,
  ClubAction.AcceptFinancialOffer,
  ClubAction.CreateChangeClubConfigGovernance,
];

export const DEFAULT_MANAGER_TREASURY_PERMISSIONS = [
  TreasuryAction.CastVote,
  TreasuryAction.CreateDiscussionProposal,
  TreasuryAction.CreateP2PProposal,
  TreasuryAction.CreateWithdrawalProposal,
  TreasuryAction.CreateTransferProposal,
  TreasuryAction.CreateMeProposal,
  TreasuryAction.SignOffProposal,
  TreasuryAction.CancelP2POffer,
  TreasuryAction.Fundraise,
  TreasuryAction.Distribute,
  TreasuryAction.CreateFinancialOffer,
  TreasuryAction.AcceptFinancialOffer,
  TreasuryAction.CreateSolseaProposal,
  TreasuryAction.UpdateGovernanceConfig,
  TreasuryAction.UpdateRoleConfig,
];

export const DEFAULT_FOUNDER_TREASURY_PERMISSIONS = [
  TreasuryAction.CastVote,
  TreasuryAction.CreateDiscussionProposal,
  TreasuryAction.CreateP2PProposal,
  TreasuryAction.CreateWithdrawalProposal,
  TreasuryAction.CreateTransferProposal,
  TreasuryAction.CreateMeProposal,
  TreasuryAction.SignOffProposal,
  TreasuryAction.CancelProposal,
  TreasuryAction.CancelP2POffer,
  TreasuryAction.Fundraise,
  TreasuryAction.Distribute,
  TreasuryAction.CreateFinancialOffer,
  TreasuryAction.AcceptFinancialOffer,
  TreasuryAction.CreateSolseaProposal,
  TreasuryAction.UpdateGovernanceConfig,
  TreasuryAction.UpdateRoleConfig,
  TreasuryAction.AddReservedRights,
  TreasuryAction.UpdateAllocation,
  TreasuryAction.AddReservedRightsToSelf,
];

export const DEFAULT_MEMBER_TREASURY_PERMISSIONS = [
  TreasuryAction.CastVote,
  TreasuryAction.Distribute,
  TreasuryAction.CreateFinancialOffer,
  TreasuryAction.AcceptFinancialOffer,
];

export const clubActionToCamelCase = {
  [ClubAction.CreateTreasuryGovernance]: "createTreasuryGovernance",
  [ClubAction.CreateWithdrawalGovernance]: "createWithdrawalGovernance",
  [ClubAction.CreateTransferGovernance]: "createTransferGovernance",
  [ClubAction.AllowMember]: "allowMember",
  [ClubAction.CancelInvitation]: "cancelInvitation",
  [ClubAction.SupportClub]: "supportClub",
  [ClubAction.UpdateMember]: "updateMember",
  [ClubAction.InitializeStaking]: "initializeStaking",
  [ClubAction.StakeTokens]: "stakeTokens",
  [ClubAction.AcceptFinancialOffer]: "acceptFinancialOffer",
  [ClubAction.CreateChangeClubConfigGovernance]:
    "createChangeClubConfigGovernance",
  [ClubAction.CanLeave]: "canLeave",
};

export const treasuryActionToCamelCase = {
  [TreasuryAction.CastVote]: "castVote",
  [TreasuryAction.CreateDiscussionProposal]: "createDiscussionProposal",
  [TreasuryAction.CreateP2PProposal]: "createP2PProposal",
  [TreasuryAction.CreateWithdrawalProposal]: "createWithdrawalProposal",
  [TreasuryAction.CreateTransferProposal]: "createTransferProposal",
  [TreasuryAction.CreateMeProposal]: "createMeProposal",
  [TreasuryAction.SignOffProposal]: "signOffProposal",
  [TreasuryAction.CancelProposal]: "cancelProposal",
  [TreasuryAction.CancelP2POffer]: "cancelP2POffer",
  [TreasuryAction.Fundraise]: "fundraise",
  [TreasuryAction.Distribute]: "distribute",
  [TreasuryAction.CreateFinancialOffer]: "createFinancialOffer",
  [TreasuryAction.AcceptFinancialOffer]: "acceptFinancialOffer",
  [TreasuryAction.CreateSolseaProposal]: "createSolseaProposal",
  [TreasuryAction.UpdateGovernanceConfig]: "updateGovernanceConfig",
  [TreasuryAction.UpdateRoleConfig]: "updateRoleConfig",
  [TreasuryAction.AddReservedRights]: "addReservedRights",
  [TreasuryAction.UpdateAllocation]: "updateAllocation",
  [TreasuryAction.AddReservedRightsToSelf]: "addReservedRightsToSelf",
};
