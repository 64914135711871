import React, { FC } from "react";
import "./ProposalCardHeader.scss";
import Chip from "../../Chip/Chip";
import { getProposalChipColor } from "../../../utilities/chipColor";
import {
  getProposalStatusTitle,
  getProposalTypeTitle,
  handleCopyToClipboard,
  setupVotingTime,
} from "../../../utilities/helpers";
import linkIcon from "../../../assets/link.png";
import HoverIcon from "../../HoverIcon/HoverIcon";
import { ProposalTypeProgram } from "../../../common/enums/proposal.enum";
import { ProposalState } from "@solana/spl-governance";

export const ProposalCardHeader: FC<{
  proposalType: ProposalTypeProgram;
  maxVotingTime: number;
  votingStartAt?: number;
  proposalStatus: ProposalState;
  link: string;
}> = ({ proposalStatus, proposalType, maxVotingTime, link }) => {
  const propsalMaxVotingTime = setupVotingTime(maxVotingTime);
  return (
    <div className="proposal-card-header">
      <div className="proposal-card-header-flex">
        <Chip text={getProposalTypeTitle(proposalType)} fontSize="0.75" />
        <span>
          {propsalMaxVotingTime?.days}d {propsalMaxVotingTime?.hours}h{" "}
          {propsalMaxVotingTime?.minutes}m
        </span>
      </div>
      <div className="proposal-card-header-flex">
        <Chip
          text={getProposalStatusTitle(proposalStatus)}
          backgroundColor={getProposalChipColor(proposalStatus)}
          fontSize="0.75"
        />
        <HoverIcon
          onClick={(e) => {
            e.stopPropagation();
            handleCopyToClipboard(link);
          }}
          src={linkIcon}
          alt="Link"
        />
      </div>
    </div>
  );
};
