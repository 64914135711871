import { FC, useCallback, useEffect, useMemo, useState } from "react";
import "./ClubConfigurationModal.scss";
import Modal from "../Modal/Modal";
import MainButton from "../MainButton/MainButton";
import LabelCard from "../LabelCard/LabelCard";
import { GREY500 } from "../../common/constants/layout.constants";
import Chip from "../Chip/Chip";
import { KycType } from "../../common/enums/deals.enum";
import { clubStore } from "../../state/clubStore";
import { useLazyQuery } from "@apollo/client";
import { GET_MY_CLUB_TREASURIES } from "../../api/club.api";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { createNotification } from "../../utilities/notifications";
import {
  EMPTY_STRING,
  MESSAGE_TYPE,
} from "../../common/constants/common.constants";
import { leaveClub } from "../../program/methods/clubs";
import { PublicKey } from "@metaplex-foundation/js";
import avatar from "../../assets/unique_avatar.png";

const ClubConfigurationModal: FC<{
  closeModal: () => void;
}> = ({ closeModal }) => {
  const [canLeaveClub, toggleCanLeaveClub] = useState(false);
  const { clubBasicInfo, memberData, allTreasuryDataAddresses } = clubStore();
  const wallet = useAnchorWallet();

  useEffect(() => {
    getMyClubTreasuries();
  }, [clubBasicInfo?.address, wallet?.publicKey]);

  const [getMyClubTreasuries] = useLazyQuery(GET_MY_CLUB_TREASURIES, {
    onCompleted: (data) => {
      console.log(data);
      toggleCanLeaveClub(
        data.getMyClubTreasuries && data.getMyClubTreasuries.length === 0
      );
    },
    variables: {
      clubAddress: clubBasicInfo?.address,
      userAddress: wallet?.publicKey.toString(),
    },
  });

  const roles = useMemo(
    () => clubBasicInfo?.roleConfig.map((role) => role.name),
    [clubBasicInfo]
  );

  const getKycConfig = useCallback((value: KycType | undefined) => {
    switch (value) {
      case KycType.All:
        return "Everyone";
      case KycType.ExceptUS:
        return "Non-US Citizens";
      case KycType.OnlyUS:
        return "Only US Citizens";
      default:
        return "Not Definied";
    }
  }, []);

  const leaveClubHandler = async () => {
    try {
      if (
        !clubBasicInfo ||
        !memberData ||
        !wallet ||
        !allTreasuryDataAddresses
      ) {
        createNotification(MESSAGE_TYPE.ERROR, "Missing data");
        return;
      }

      await leaveClub(
        clubBasicInfo.name,
        new PublicKey(memberData.address),
        new PublicKey(clubBasicInfo.address),
        wallet,
        allTreasuryDataAddresses
      );
      createNotification(MESSAGE_TYPE.SUCCESS, "Club left");
      closeModal();
    } catch (error) {
      console.log(error);
      createNotification(MESSAGE_TYPE.ERROR, "Failed to leave club");
    }
  };

  return (
    <Modal title="Club Configuration" closeModal={closeModal}>
      <div className="club-configuration">
        <div className="club-configuration__details">
          <img
            src={
              clubBasicInfo?.imgUrl && clubBasicInfo?.imgUrl !== EMPTY_STRING
                ? clubBasicInfo?.imgUrl
                : avatar
            }
            alt="Deal profile"
          />
          <h2>{clubBasicInfo?.name}</h2>
          {/* TODO: replace with email */}
          <p>email@email.com</p>
        </div>
        {clubBasicInfo?.verticals && clubBasicInfo?.verticals.length > 0 && (
          <div className="club-configuration__verticals">
            <h3>Verticals</h3>
            <div>
              {clubBasicInfo?.verticals.map((tag, index) => (
                <Chip
                  text={tag.label}
                  backgroundColor="transparent"
                  padding="0.75em"
                  borderRadius="100"
                  key={index}
                  fontSize="0.875"
                  color={GREY500}
                  borderColor={GREY500}
                />
              ))}
            </div>
          </div>
        )}
        <div className="club-configuration__fields">
          <LabelCard
            label={"Roles"}
            description={roles?.join(", ")}
            fontWeight={400}
          />
          {/* TODO: add parameters to the BE */}
          {/* <LabelCard label={"Fundraise Model"} description={clubInfo.} /> */}
          <LabelCard
            label={"KYC"}
            description={getKycConfig(clubBasicInfo?.kycConfig?.location)}
            fontWeight={400}
          />
          {/* <LabelCard label={"Allow Trading Shares"} description={clubInfo.} /> */}
        </div>
        <div className="club-configuration__action">
          <MainButton type="button" light onClick={closeModal}>
            Go Back
          </MainButton>
          {canLeaveClub && (
            <MainButton
              type="button"
              onClick={leaveClubHandler}
              color="var(--red-500)"
              light
              borderColor="var(--red-500)"
            >
              Leave club
            </MainButton>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ClubConfigurationModal;
