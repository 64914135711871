import React, { useMemo } from "react";
import InputField from "../../InputFields/InputField";
import proposalFormConfig from "../proposalFormConfig";
import "./TreasuryConfiguration.scss";
import { ProposalType } from "../../../common/enums/proposal.enum";
import { useFormikContext } from "formik";
import { IProposalFormFields } from "../../../common/interfaces/form.interface";
import { clubStore } from "../../../state/clubStore";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import FieldWithDescription from "../../FieldWithDescription/FieldWithDescription";
import LabelCard from "../../LabelCard/LabelCard";
import {
  parseSecondsToDays,
  showQuorumMaxVotingTimeForGovernance,
} from "../../../utilities/helpers";

const TreasuryConfiguration = () => {
  const formFields = proposalFormConfig.formFields;

  const { values } = useFormikContext<IProposalFormFields>();
  const { activeTreasury } = clubStore();

  const showQuorumMaxVotingTime = useMemo(
    () =>
      activeTreasury &&
      showQuorumMaxVotingTimeForGovernance(values.proposalType, activeTreasury),
    [activeTreasury, values.proposalType]
  );

  return (
    <div className="treasury-configuration">
      <h2 className="treasury-configuration__headline">
        Treasury configuration
      </h2>
      <div className="treasury-configuration__fields">
        <LabelCard
          label="Approval vote percentage"
          description={
            showQuorumMaxVotingTime &&
            showQuorumMaxVotingTime.approvalVotePercentage.toString()
          }
          fontWeight={400}
          backgroundColor="#FAFAFA"
          width="50%"
        />
        <LabelCard
          label="Max voting time"
          description={
            showQuorumMaxVotingTime &&
            parseSecondsToDays(showQuorumMaxVotingTime.maxVotingTime)
              ?.toFixed(0)
              .toString()
          }
          fontWeight={400}
          backgroundColor="#FAFAFA"
          width="50%"
        />
      </div>
    </div>
  );
};

export default TreasuryConfiguration;
