import { IDeposit } from "../../../common/interfaces/treasury.interface";
import "./Deposit.scss";
import {
  formatDateWithTime,
  getAmountWithDecimalsForCurrency,
  getTrimmedPublicKey,
} from "../../../utilities/helpers";
import React, { FC, useMemo, useState } from "react";
import openInNew from "../../../assets/open_in_new.png";
import cancelIcon from "../../../assets/cancel.png";
import { IHistoricalDeposit } from "../../../common/interfaces/club.interface";
import { SupportActionLabel } from "../../../common/enums/fundraise.enum";
import { clubStore } from "../../../state/clubStore";

const Deposit: FC<{ item: IHistoricalDeposit }> = ({ item }) => {
  const { activeTreasury } = clubStore();

  const depositedAt = useMemo(() => {
    return new Date(item.executedAt);
  }, [item]);
  return (
    <div
      className={`deposit-item
    ${
      item.action === SupportActionLabel.Withdraw && "deposit-item--withdrawn"
    }`}
    >
      <p
        className={`deposit-item__description
    ${
      item.action === SupportActionLabel.Withdraw &&
      "deposit-item__description--withdrawn"
    }`}
      >
        <span className="deposit-item__address">
          {getTrimmedPublicKey(item.memberAddress)}{" "}
        </span>
        <span className="deposit-item__action">
          {item.action === SupportActionLabel.Withdraw
            ? "withdrawn"
            : "deposited"}{" "}
        </span>
        <span
          className={`deposit-item__amount
            ${
              item.action === SupportActionLabel.Withdraw &&
              "deposit-item__amount--withdrawn"
            }`}
        >
          {item.action === SupportActionLabel.Withdraw ? "-" : "+"}
          {getAmountWithDecimalsForCurrency(
            activeTreasury?.currencyDecimals,
            item.amount
          )}
        </span>{" "}
        <span className="deposit-item__currency">
          {activeTreasury?.currencySymbol}{" "}
        </span>
      </p>
      <div className="deposit-item__options">
        <label className="deposit-item__date">
          {depositedAt.toLocaleTimeString()} {depositedAt.toLocaleDateString()}
        </label>
      </div>
    </div>
  );
};

export default Deposit;
